import { AxiosError } from 'axios';
import {
  commonError,
  createPostUpSell,
  removePostUpSell,
  searchPostUpSell,
  updatePostUpSell,
} from 'services/constants';
import { baseService } from 'services';
import {
  IPostRemove,
  IPostUpSellCreate,
  IPostUpsellResponse,
  IPostUpSellUpdate,
  IPreUpSellCreate,
  IUpSellBody,
} from 'interface/upSellInterface';

class postUpSellService {
  createupSell = async (body: IPostUpSellCreate) => {
    try {
      const response = await baseService.post<IPreUpSellCreate>(
        createPostUpSell,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchUpSell = async (body: IUpSellBody) => {
    try {
      const response = await baseService.post<{
        Result: IPostUpsellResponse[];
      }>(searchPostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  removeUpSell = async (body: IPostRemove) => {
    try {
      const response = await baseService.post(removePostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateUpSell = async (body: IPostUpSellUpdate) => {
    try {
      const response = await baseService.post(updatePostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class postUpSellMockService {
  createupSell = async (body: IPreUpSellCreate) => {
    try {
      const response = await baseService.post<IPreUpSellCreate>(
        createPostUpSell,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchUpSell = async (body: IUpSellBody) => {
    try {
      const response = await baseService.post(searchPostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  removeUpSell = async (body: IPostRemove) => {
    try {
      const response = await baseService.post(removePostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateUpSell = async (body: IPostUpSellUpdate) => {
    try {
      const response = await baseService.post(updatePostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalPostSellService: postUpSellService | postUpSellMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new postUpSellMockService()
    : new postUpSellService();

export default globalPostSellService;
