import { ComponentConfig, Data } from '@measured/puck';
import { MarginProps, marginFields } from '../../puck/reusable-props/margin';
import CustomTooltip from '../../../components/common/tooltip';
import { StepperFields } from '../../puck/reusable-props/Stepper';
import { useState, useEffect } from 'react';
import StepperUI from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import './style.scss';
import { DropZone } from '@measured/puck';
import { useLocation } from 'react-router-dom';
import { Path } from '../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import { PaddingProps, paddingFields } from '../../puck/reusable-props/padding';
import { fontField, FontProps } from '../../puck/reusable-props/font';
import { useFormContext } from 'react-hook-form';
import { ReduxSelectorProps } from '../../services/constants';
import { heightField, HeightProps } from '../../puck/reusable-props/height';
import {
  StepperCustomField,
  StepperFieldsProps,
} from '../../puck/reusable-props/stepperCustomFields';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';

export type IStepper = {
  id: string;
  formName: string;
  label?: string;
};

export type IForm = {
  form: IStepper[];
  selectedStepID?: string;
  selectedStepIndex?: number;
};

export type StepperProps = {
  forms: IForm;
  stepProgressColor: string;
  stepLineColor: string;
  labelTextColor: string;
} & MarginProps &
  PaddingProps &
  FontProps &
  HeightProps &
  StepperFieldsProps &
  showComponentProps;

export const Stepper = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<StepperProps> => {
  const getDefaultFormValue = () => {
    const id = uuidv4();
    const id2 = uuidv4();
    return {
      form: [
        {
          id: id,
          formName: `step-${id}`,
          label: 'Step',
          buttons: {
            previousText: 'Back',
            nextText: 'Next',
          },
        },
        {
          id: id2,
          formName: `step-${id2}`,
          label: 'Step',
          buttons: {
            nextText: 'Next',
            previousText: 'Back',
          },
        },
      ],
      selectedStepID: id,
      selectedStepIndex: 0,
      hideButtonIcons: false,
    };
  };

  return {
    label: (
      <CustomTooltip title="Stepper">
        <span>Stepper</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...StepperFields,
      ...marginFields,
      ...paddingFields,
      ...fontField,
      ...StepperCustomField,
      ...heightField,
      stepProgressColor: {
        type: 'custom',
        label: 'Step Progress Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Step Progress Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      stepLineColor: {
        type: 'custom',
        label: 'Step Line Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Step Line Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
      labelTextColor: {
        type: 'custom',
        label: 'Label Color',
        render: ({ value, onChange }) => (
          <FieldLabel label="Label Color">
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      height: 'h-auto',
      stepProgressColor: '#f90182',
      stepLineColor: '#e4e4e4',
      labelTextColor: '#000000',
      forms: {
        ...getDefaultFormValue(),
      },
      font: {
        size: 'text-md',
        weight: 'font-bold',
      },
      button: {
        backgroundColor: 'bg-transparent',
        textColor: 'text-white',
        font: {
          size: 'text-md',
          weight: 'font-bold',
        },
        hideButtonIcons: false,
        previousText: 'Back',
        nextText: 'Next',
        border: {
          border: false,
          borderRadious: 'rounded-none',
          borderColor: '#000000',
          borderWidth: 'border-0',
        },
        hover: {
          hoverColor: 'hover:bg-gray-100',
          hover: false,
          hoverBackgroundColor: 'hover:bg-transparent',
        },
      },
    },
    render: ({
      padding,
      margin,
      forms,
      font,
      labelTextColor,
      stepProgressColor,
      stepLineColor,
      height,
      button,
      showComponent,
    }) => {
      const [step, setStep] = useState<number>(0);
      const { pathname } = useLocation();
      const isEditModeOn = pathname.includes(Path.PAGE_BUILDER_EDIT);
      const { trigger, formState, clearErrors } = useFormContext();
      const { puckData } = useAppSelector(state => state.checkout);
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);

      const classNameValue = `${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
          ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
          ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} 
          ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
          ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
          ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}`;

      // This function gets the all the forms.
      const getStepperForm = (data: Data) => {
        const components: { [key: string]: string[] } = {};
        if (data?.zones) {
          Object.entries(data?.zones)?.forEach(([zoneKey, zoneArray]) => {
            components[zoneKey] = zoneArray?.map(component => {
              return component?.props?.id;
            });
          });
        }
        return components;
      };

      // to trigger the validation of the form
      const handleTriggerForm = (formsArray: string[]) => {
        const triggerFields: string[] = [];
        formsArray?.forEach(form => {
          if (form === 'ContactInfo') {
            Object?.entries(formState?.defaultValues?.CustomerData).forEach(
              ([key]) => {
                triggerFields.push(`CustomerData.${key}`);
              },
            );
          }
          if (form === 'ShippingForm') {
            Object?.entries(formState?.defaultValues?.Shipping).forEach(
              ([key]) => {
                triggerFields.push(`Shipping.${key}`);
              },
            );
          }
          if (form === 'PaymentRadio') {
            Object?.entries(formState?.defaultValues?.BillingAddress).forEach(
              ([key]) => {
                triggerFields.push(`BillingAddress.${key}`);
              },
            );
          }
          if (form === 'Payment' || form === 'PaymentRadio') {
            Object?.entries(formState?.defaultValues?.CardInfo).forEach(
              ([key]) => {
                triggerFields.push(`CardInfo.${key}`);
              },
            );
          }
          if (form === 'BillingForm') {
            Object?.entries(formState?.defaultValues?.BillingAddress).forEach(
              ([key]) => {
                triggerFields.push(`BillingAddress.${key}`);
              },
            );
          }
        });
        return trigger(triggerFields);
      };

      // This function gets the form that needs to be validated.
      const validateForm = (
        stepName: string,
        forms: { [key: string]: string[] },
      ) => {
        const validForms = [
          'ContactInfo',
          'ShippingForm',
          'BillingForm',
          'Payment',
          'PaymentRadio',
        ];
        let triggerComponents: string[] = [];
        Object.entries(forms).forEach(([zoneKey, zoneArray]) => {
          if (zoneKey?.includes(stepName)) {
            zoneArray.forEach(component => {
              if (validForms?.some(formName => component.includes(formName))) {
                const data = component.split('-')?.[0];
                triggerComponents.push(data);
              } else {
                const data = validateForm(component, forms) ?? [];
                triggerComponents = [...triggerComponents, ...data];
              }
            });
          }
        });
        return triggerComponents;
      };

      // initial call to validate the form
      const validate = (name: string) => {
        if (!puckData) return;
        const forms = getStepperForm(puckData);
        const validateForms = validateForm(name, forms);
        return handleTriggerForm(validateForms);
      };

      const handleNextButtonClick = async (form: IStepper) => {
        clearErrors();
        const isValid = await validate(form?.formName);
        if (isValid) setStep(step + 1);
      };

      const getHeight = (height: string) => {
        if (height === 'h-auto') return 'auto';
        if (height === 'h-full') return '100%';
        return `${Number(height.replace('h-', '')) * 32}px`;
      };
      useEffect(() => {
        if (isEditModeOn) setStep(forms?.selectedStepIndex || 0);
      }, [forms?.selectedStepIndex]);

      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={classNameValue}
              style={{
                background: 'white',
              }}>
              {forms?.form?.length > 1 && (
                <div className="stepper-section py-3">
                  <div className="stepper-wrapper-1">
                    <div
                      className="stepper-position"
                      style={
                        {
                          '--stepper-line-color': stepLineColor,
                          '--stepper-color': stepProgressColor,
                        } as { [key: string]: string }
                      }>
                      <StepperUI
                        className="stepper"
                        activeStep={step}
                        alternativeLabel>
                        {forms?.form?.map((_, index) => (
                          <Step className="step" key={index}>
                            <StepLabel className={`stepLabel`}>
                              <div
                                className={`${font?.size} ${font?.weight}`}
                                style={{ color: labelTextColor }}>
                                {forms?.form[index]?.label}
                              </div>
                            </StepLabel>
                          </Step>
                        ))}
                      </StepperUI>
                    </div>
                  </div>
                </div>
              )}
              {forms?.form?.map((form, index) => (
                <div
                  key={form?.id}
                  className={`${index !== step ? 'hidden' : 'block'}`}>
                  <div
                    style={{
                      height: getHeight(height),
                    }}>
                    <DropZone
                      zone={form?.formName}
                      disallow={['StepperForm']}
                      key={form?.id}
                      style={{
                        background: 'white',
                      }}
                    />
                  </div>

                  <div className="flex justify-between p-2">
                    {index !== 0 ? (
                      <button
                        type="button"
                        className={`p-2 flex items-center justify-center gap-2 ${button?.font?.size} ${button?.font?.weight} ${button?.border?.border ? `${button?.border?.borderWidth} ${button?.border?.borderRadious} !border-[${button?.border?.borderColor}]` : ''}`}
                        onClick={() => setStep(step - 1)}
                        style={{
                          backgroundColor: button?.backgroundColor,
                          color: button?.textColor,
                          transition: 'background-color 0.3s',
                        }}
                        onMouseOver={e => {
                          if (button?.hover?.hover) {
                            e.currentTarget.style.color =
                              button?.hover?.hoverColor;
                            e.currentTarget.style.backgroundColor =
                              button?.hover?.hoverBackgroundColor;
                          }
                        }}
                        onMouseOut={e => {
                          if (button?.hover?.hover) {
                            e.currentTarget.style.backgroundColor =
                              button?.backgroundColor;
                            e.currentTarget.style.color = button?.textColor;
                          }
                        }}>
                        {!button?.hideButtonIcons && (
                          <ArrowBack className="text-sm" />
                        )}
                        <p>{button?.previousText ?? 'Back'}</p>
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {index !== forms?.form?.length - 1 && (
                      <button
                        type="button"
                        className={`p-2 flex items-center justify-center gap-2 ${button?.font?.size} ${button?.font?.weight} ${button?.border?.border ? `${button?.border?.borderWidth} ${button?.border?.borderRadious} !border-[${button?.border?.borderColor}]` : ''}`}
                        style={{
                          backgroundColor: button?.backgroundColor,
                          color: button?.textColor,
                          transition: 'background-color 0.3s',
                        }}
                        onMouseOver={e => {
                          if (button?.hover?.hover) {
                            e.currentTarget.style.backgroundColor =
                              button?.hover?.hoverBackgroundColor;
                            e.currentTarget.style.color =
                              button?.hover?.hoverColor;
                          }
                        }}
                        onMouseOut={e => {
                          if (button?.hover?.hover) {
                            e.currentTarget.style.backgroundColor =
                              button?.backgroundColor;
                            e.currentTarget.style.color = button?.textColor;
                          }
                        }}
                        onClick={() => {
                          handleNextButtonClick(form);
                        }}>
                        <p>{button?.nextText ?? 'Next'}</p>
                        {!button?.hideButtonIcons && <ArrowForward />}
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      );
    },
  };
};
