import { Fields } from '@measured/puck';

export type JustifyContentProps = {
  justifyContent: string;
};

export const JustifyContentField: Fields<JustifyContentProps> = {
  justifyContent: {
    type: 'select',
    label: 'Justify-Content',
    options: [
      { label: 'center', value: 'justify-center' },
      { label: 'start', value: 'justify-start' },
      { label: 'end', value: 'justify-end' },
      { label: 'between', value: 'justify-between' },
      { label: 'around', value: 'justify-around' },
      { label: 'evenly', value: 'justify-evenly' },
    ],
  },
};
