import { FC, useState, useEffect, useRef, useCallback } from 'react';
import MultiSelectSearch from 'components/common/multiSelectSearchFilter';
import { getStores } from 'services/dashboardService';
import { IGetStores, IStore } from 'interface/storeInterface';

interface IProps {
  value: IStore[];
  onChange: (_: IStore[]) => void;
  label?: string;
  limitTags?: number;
}

const defaultValue: IGetStores = {
  Descending: false,
  Limit: 25,
  OrderBy: 'Name',
  Page: 0,
};
const MultiStoreSearch: FC<IProps> = ({ value, onChange, limitTags }) => {
  const positionRef = useRef<number>(0);
  const [storeSearch, setStoreSearch] = useState<IGetStores | null>(null);
  const [storeOptions, setStoreOptions] = useState<IStore[]>([]);
  const [storeLoading, setStoreLoading] = useState(false);
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);
  let isClose = false;
  const searchStore = async () => {
    setStoreLoading(true);
    const res = await getStores(storeSearch ? storeSearch : defaultValue);
    if (res?.data && res.data?.Result) {
      setStoreOptions(prev => [...prev, ...res.data.Result]);
      setIsNextAPIDisabled(res?.data?.Result?.length < defaultValue.Limit);
    } else {
      setStoreOptions([]);
    }
    setStoreLoading(false);
  };
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (observerRef?.current) observerRef.current?.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting && !isNextAPIDisabled) {
          setStoreSearch(prevPage => {
            if (prevPage === null) {
              return { ...defaultValue, Page: defaultValue.Page + 1 };
            } else {
              return { ...prevPage, Page: prevPage.Page + 1 };
            }
          });
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );
  const setPostionRef = (value: number) => {
    positionRef.current = value;
  };
  useEffect(() => {
    if (storeSearch) searchStore();
  }, [storeSearch]);

  return (
    <>
      <MultiSelectSearch<IStore>
        setPostionRef={setPostionRef}
        onInputChange={searchText => {
          setStoreSearch(pre => {
            if (isClose) return null;
            if (searchText.length > 0 && pre !== null)
              return { ...pre, Name: searchText, Page: 0 };
            return defaultValue;
          });
          setStoreOptions([]);
        }}
        value={value}
        options={storeOptions}
        labelKey="Name"
        valueKey="ID"
        placeholder="Select Stores"
        limitTags={limitTags || 1}
        onChange={(newValue: IStore[]) => {
          onChange(newValue);
        }}
        onOpen={() => setStoreSearch(defaultValue)}
        onClose={() => {
          isClose = true;
          setStoreOptions([]);
          setStoreSearch(null);
        }}
        loading={storeLoading}
        lastElementRef={lastElementRef}
        position={positionRef?.current}
      />
    </>
  );
};

export default MultiStoreSearch;
