import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  Filler,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { groupedLineChartoptions } from './constant';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

interface Iprops {
  data: ChartData<'line', (number | Point | null)[]>;
  varient?: 'filled' | 'cardChart';
  [_: string]: unknown;
  prefix?: string;
  suffix?: string;
  labelPrefix?: string;
  labelSuffix?: string;
  isLabelRequired?: boolean;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  options?: ChartOptions<'line'>;
  labels?: string[];
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
}

export const GroupedLineChart = ({
  data,
  prefix = '',
  suffix = '',
  labelPrefix = '',
  labelSuffix = '',
  isLabelRequired = false,
  hasPercentageValue = false,
  options = {},
  isPercentagedata = false,
  customPrefixSuffix,
  labels,
  ...rest
}: Iprops) => {
  return (
    <Line
      options={{
        ...groupedLineChartoptions({
          prefix,
          suffix,
          labelPrefix,
          labelSuffix,
          isLabelRequired,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
          labels: labels || [],
        }),
        ...options,
      }}
      data={data}
      {...rest}
    />
  );
};
