import { FC, useState } from 'react';
import MembershipPlan from './membershipPlanList';
import EditMembershipPlanComponent from './editMembershipPlan.tsx';

interface IProps {
  isSettingsFromPortal?: boolean;
}

const MembershipPlanComponent: FC<IProps> = ({ isSettingsFromPortal }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  return isEdit ? (
    <EditMembershipPlanComponent
      setIsEdit={setIsEdit}
      id={id}
      isSettingsFromPortal={isSettingsFromPortal}
    />
  ) : (
    <MembershipPlan
      setIsEdit={setIsEdit}
      setId={setId}
      isSettingsFromPortal={isSettingsFromPortal}
    />
  );
};

export default MembershipPlanComponent;
