import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  IErrorResponse,
  IResponse,
  ISuccessResponse,
} from 'interface/apiInterface';
import {
  IOrderDetailsResponse,
  IOrderTransactionHistoryList,
} from 'interface/orderDetailInterface';
import {
  ICartDetails,
  ICartList,
  ICommonUpdateOrder,
  IOrdersList,
  ISearchOrderBody,
  IUpdateOrder,
} from 'interface/orderInterface';
import { orderMockData, orderTransactionHistory } from 'utils/mockData';
import {
  cartListEP,
  commonError,
  exportCartEP,
  exportOrderEP,
  getCartDetailsByIdEP,
  getOrderByIdEP,
  getOrderHistoryByIdEP,
  ordersUpdateEp,
  searchOrderEP,
} from 'services/constants';
import { ICartsPayloadBody } from 'interface/processorAssignment';

class OrderService {
  searchOrders = async (body: ISearchOrderBody) => {
    try {
      const response = await baseService.post<IOrdersList>(searchOrderEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  listCarts = async (body: ICartsPayloadBody) => {
    try {
      const response = await baseService.post<ICartList>(cartListEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCartDetailsById = async (channelID: string, cartID: string) => {
    try {
      const response = await baseService.get<ICartDetails>(
        getCartDetailsByIdEP(channelID, cartID),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getOrderById = async (store_id: string, order_id: string) => {
    try {
      const response = await baseService.get<IOrderDetailsResponse>(
        getOrderByIdEP(store_id, order_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getOrderHistoryById = async (store_id: string, order_id: string) => {
    try {
      const response = await baseService.get<IOrderTransactionHistoryList>(
        getOrderHistoryByIdEP(store_id, order_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateOrder = async (
    body: IUpdateOrder,
  ): Promise<
    ISuccessResponse<ICommonUpdateOrder> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ICommonUpdateOrder>(
        ordersUpdateEp,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  exportTransaction = async (
    payload: ISearchOrderBody,
  ): Promise<IResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(exportOrderEP, payload);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  exportAbandonedCart = async (
    payload: ISearchOrderBody,
  ): Promise<IResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(exportCartEP, payload);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class OrderMockService {
  searchOrders = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: orderMockData,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  listCarts = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: orderMockData,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getOrderById = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: orderMockData.Result[0],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCartDetailsById = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: orderMockData.Result[0],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getOrderHistoryById = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: { events: orderTransactionHistory },
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateOrder = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: { events: orderTransactionHistory },
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  exportTransaction = async (): Promise<
    IResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<{
        data: string;
        status: number;
        message: string;
      }>(resolve => {
        setTimeout(() => {
          resolve({
            data: JSON.stringify(orderMockData),
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };
  exportAbandonedCart = async (): Promise<
    IResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<{
        data: string;
        status: number;
        message: string;
      }>(resolve => {
        setTimeout(() => {
          resolve({
            data: JSON.stringify([]),
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };
}
const globalOrderService: OrderMockService | OrderService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new OrderMockService()
    : new OrderService();

export default globalOrderService;
