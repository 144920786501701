import { useAppDispatch } from 'hooks/reduxHooks';
import { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { setShopifyToken } from 'redux-setup/slices/shopifySlice';

const CommonWrapper = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispach = useAppDispatch();
  const idToken = searchParams.get('id_token');

  const handleIframeMessages = async (e: MessageEvent) => {
    if (e.data?.path) {
      navigate(e.data.path);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleIframeMessages);
    return () => {
      window.removeEventListener('message', handleIframeMessages);
    };
  }, []);

  useEffect(() => {
    if (idToken) {
      dispach(setShopifyToken(idToken));
    }
  }, [idToken]);

  return <Outlet />;
};

export default CommonWrapper;
