import { forwardRef } from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioGroupProps,
} from '@mui/material';
import 'components/common/radioButton/style.scss';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface IOption {
  value: string;
  label: string;
}

interface IRadioButtonProps extends RadioGroupProps {
  label: string;
  className?: string;
  options?: IOption[];
}

const RadioButton = forwardRef<HTMLInputElement, IRadioButtonProps>(
  ({ label, className = '', options, ...rest }, ref) => {
    return (
      <FormControl className={`common_radio_button_wrapper ${className} `}>
        <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
        <RadioGroup
          {...rest}
          className={'custom_radio_group'}
          ref={ref}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group">
          {options?.map((option: IOption, index: number) => (
            <FormControlLabel
              className={'my_form_contol_label'}
              key={index}
              value={option.value}
              control={<Radio className={'custom_radio_button'} />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;

interface IControlledRadioButton extends IRadioButtonProps {
  name: string;
  onChangeHandler?: (_value: string) => string[];
  newValueHandler?: (_value: string[]) => string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledRadioButton = ({
  name,
  rules,
  onChangeHandler,
  newValueHandler,
  ...rest
}: IControlledRadioButton) => {
  return (
    <Controller
      name={name}
      rules={rules ? rules : {}}
      render={({ field: { onChange, value, ...restFields } }) => {
        return (
          <RadioButton
            onChange={e =>
              onChange(
                onChangeHandler
                  ? onChangeHandler(e.target.value)
                  : e.target.value,
              )
            }
            value={newValueHandler ? newValueHandler(value) : value}
            {...rest}
            {...restFields}
          />
        );
      }}
    />
  );
};
