import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Item,
  Variant,
} from '../../../page-builder/interface/postUpsellInterface';
import { initialVariant } from './postUpsellSlice';

export interface PostUpSellState {
  currentIndex: number;
  selectedVariant: Variant;
  selectedItems: Array<{ item: Item; variant: Variant }>;
  isLoading: boolean;
  showPopup: boolean;
  popupMessage: string;
  errorStatus: number;
}

const initialState: PostUpSellState = {
  currentIndex: 0,
  selectedVariant: initialVariant,
  isLoading: false,
  showPopup: false,
  popupMessage: '',
  errorStatus: 0,
  selectedItems: [],
};

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setCurrentIndex(state, action: PayloadAction<number>) {
      state.currentIndex = action.payload;
    },
    setSelectedVariant(state, action: PayloadAction<Variant>) {
      state.selectedVariant = action.payload;
    },
    setSelectedItems(
      state,
      action: PayloadAction<Array<{ item: Item; variant: Variant }>>,
    ) {
      state.selectedItems = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setShowPopup(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setPopupMessage(state, action: PayloadAction<string>) {
      state.popupMessage = action.payload;
    },
    setErrorStatus(state, action: PayloadAction<number>) {
      state.errorStatus = action.payload;
    },
    resetState(state) {
      state.currentIndex = initialState.currentIndex;
      state.selectedVariant = initialState.selectedVariant;
      state.isLoading = initialState.isLoading;
      state.showPopup = initialState.showPopup;
      state.popupMessage = initialState.popupMessage;
      state.errorStatus = initialState.errorStatus;
      state.selectedItems = initialState.selectedItems;
    },
  },
});

export const {
  setCurrentIndex,
  setSelectedVariant,
  setIsLoading,
  setShowPopup,
  setPopupMessage,
  setErrorStatus,
  setSelectedItems,
  resetState,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
