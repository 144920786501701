import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

interface UpSellPopUpProps {
  message: string;
  onDismiss: () => void;
}

export const UpSellPopUp: React.FC<UpSellPopUpProps> = ({
  message,
  onDismiss,
}) => {
  return (
    <div className="rounded-md bg-red-50 p-14">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Order failed</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{message}</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                onClick={() => onDismiss()}
                type="button"
                className="ml-3 rounded-md bg-red-100 px-4 py-2 text-sm font-medium text-red-800 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50">
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
