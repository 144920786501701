import { AxiosError } from 'axios';
import {
  commonError,
  reportsDisputeCodesEP,
  searchRiskEP,
} from 'services/constants';
import { baseService } from 'services';
import {
  IReportsDisputeResult,
  IRiskResponse,
  ISearchRiskBody,
} from 'interface/riskInterface';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';

class RiskService {
  searchRisk = async (body: ISearchRiskBody) => {
    try {
      const response = await baseService.post<{ Result: IRiskResponse[] }>(
        searchRiskEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getDisputeCodes = async (
    body: ISearchRiskBody,
  ): Promise<
    ISuccessResponse<IReportsDisputeResult> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ISearchRiskBody>(
        reportsDisputeCodesEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class RiskrMockService {
  searchRisk = async (body: ISearchRiskBody) => {
    try {
      const response = await baseService.post<ISearchRiskBody>(
        searchRiskEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getDisputeCodes = async (
    body: ISearchRiskBody,
  ): Promise<
    ISuccessResponse<IReportsDisputeResult> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<IReportsDisputeResult>(
        reportsDisputeCodesEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalRiskService: RiskrMockService | RiskService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new RiskrMockService()
    : new RiskService();

export default globalRiskService;
