export const NortonLogoIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x="0"
      y="0"
      viewBox="0 0 1000 1000">
      <style>{`.st2{fill:#a7a9ac}.st3{fill:#ffc20e}.st4{fill:#a30046}`}</style>
      <path
        d="M246 350.2c-68.7 0-124.6 55.9-124.6 124.6 0 68.7 55.9 124.6 124.6 124.6h519.3c68.7 0 124.6-55.9 124.6-124.6 0-68.7-55.9-124.6-124.6-124.6H246z"
        fill="#fff"
      />
      <path
        d="M765.3 610H246c-74.5 0-135.2-60.7-135.2-135.2S171.5 339.6 246 339.6h519.3c74.5 0 135.2 60.7 135.2 135.2S839.8 610 765.3 610M246 350.2c-68.7 0-124.6 55.9-124.6 124.6 0 68.7 55.9 124.6 124.6 124.6h519.3c68.7 0 124.6-55.9 124.6-124.6 0-68.7-55.9-124.6-124.6-124.6H246z"
        fill="#c7c8ca"
      />
      <path d="M703 399.1c-20.2 0-36.5 16.3-36.5 36.5s16.3 36.5 36.5 36.5 36.5-16.3 36.5-36.5-16.3-36.5-36.5-36.5m0 55.2c-10.3 0-18.7-8.4-18.7-18.7 0-10.4 8.4-18.7 18.7-18.7 10.3 0 18.7 8.4 18.7 18.7 0 10.4-8.4 18.7-18.7 18.7M535.6 399.1c-20.2 0-36.5 16.3-36.5 36.5s16.3 36.5 36.5 36.5 36.5-16.3 36.5-36.5-16.4-36.5-36.5-36.5m0 55.2c-10.3 0-18.7-8.4-18.7-18.7 0-10.4 8.4-18.7 18.7-18.7 10.4 0 18.7 8.4 18.7 18.7 0 10.4-8.4 18.7-18.7 18.7M664.2 455.4c-2.3.7-5.8 1.3-7.2 1.3-6.2 0-7.6-2.2-7.6-8.1v-33.7h14.9v-14.1h-14.9v-19.9h-16.8l-3.5 19.9h-12.7c-9.6 0-16.8 5.9-21.4 15l-5.8-15h-12v70.1h20.6v-30.5c0-15.4 9.1-25.2 19.8-25.5h11.3v34.8c0 16.4 8.4 22.5 22.1 22.5 7.1 0 12.5-1.5 15.7-2.6l-2.5-14.2zM764.5 422.9v47.9h-20.1v-70.1h12.2l4.1 10.7" />
      <path d="M760.6 411.5c6.1-7.1 14.4-12.4 24.2-12.4 16.7 0 23.5 13.3 23.5 25.6v46.1h-20.6v-45.5c0-6-5-9.3-10.4-9.3-5.8 0-9.5 3.3-12.7 6.8M418.8 375.4h22.9l31.5 60v-60h19v95.4h-20.8l-33.6-66v66h-19z" />
      <path
        d="m413 555.4 4.8-7.4c3.9 2.9 9.7 5.5 16.8 5.5 6.9 0 11.1-3.1 11.1-8.5 0-6.2-5.4-7.9-14.2-10.9-7.9-2.8-15.7-6.8-15.7-18 0-10.5 8.3-16.2 19.4-16.2 7.4 0 12.9 1.5 17.3 4l-3.8 7.9c-3.7-1.9-8.1-3.2-13.3-3.2-6.2 0-9.9 2.3-9.9 7.3 0 5.8 4.3 7.2 12.4 9.9 8.5 2.7 17.8 6.7 17.8 19.1 0 10.5-7.5 17.4-21 17.4-10-.1-17.3-3.6-21.7-6.9M473.7 500.8h36.1v8.3H484v16.6h20.4v8.2H484V553h27.4v8.4h-37.7zM526.1 531.1c0-17.7 8.5-31.3 25.4-31.3 7.6 0 13.6 2.4 17.8 7.3l-6 6.9c-3.6-3.5-6.7-5.3-11.6-5.3-9.9 0-14.5 8.1-14.5 23.1 0 14.1 4.8 21.2 14.6 21.2 5.4 0 9.2-2.8 12.6-6.4l6 6.7c-4.4 5.2-9.9 8.7-18.7 8.7-17.5.2-25.6-13.1-25.6-30.9M587 538.2v-37.4h10.3v39.1c0 8.3 2.1 13.8 10.9 13.8 8.7 0 10.9-5.5 10.9-13.8v-39.1h10.4v37.4c0 15-5.8 24-21.4 24-15.3 0-21.1-9-21.1-24M692.8 561.4h-11.2L670 535.7h-9.6v25.7h-10.3v-60.6h19.8c12.2 0 21.8 4.4 21.8 17.7 0 9.1-5.4 13.6-11.5 15.8l12.6 27.1zm-23.3-33.6c7.7 0 11.6-2.5 11.6-9.5 0-6.7-4-9.1-11-9.1h-9.7v18.6h9.1zM710.4 500.8h36v8.3h-25.8v16.6H741v8.2h-20.4V553H748v8.4h-37.6zM765.3 500.8h15.1c19.8 0 29.3 10.7 29.3 30.1 0 19.8-9.7 30.5-28.7 30.5h-15.7v-60.6zm14.6 52.2c14.2 0 19-6.5 19-22.2 0-13.7-3.8-21.7-17.7-21.7h-5.5V553h4.2z"
        className="st2"
      />
      <path
        d="M245.4 620.6c-80.4 0-145.9-65.5-145.9-145.9S165 328.8 245.4 328.8s145.9 65.4 145.9 145.9c-.1 80.5-65.5 145.9-145.9 145.9m0-245.6c-26.6 0-51.7 10.4-70.5 29.2-18.8 18.8-29.2 43.9-29.2 70.5s10.4 51.7 29.2 70.5c18.8 18.8 43.9 29.2 70.5 29.2s51.7-10.4 70.5-29.2c18.8-18.8 29.2-43.9 29.2-70.5s-10.4-51.7-29.2-70.5C297 385.4 272 375 245.4 375M404.3 306.4h11.4v11.4h-11.4zM404.3 283.7h11.4v11.4h-11.4zM372.3 317.7h10.6v11.1h-10.6z"
        className="st3"
      />
      <path d="M351.2 339.5h10.7v10.6h-10.7zM382.9 328.8h11.9v10.7h-11.9zM340.6 329.3h10.7v10.2h-10.7zM372.3 339.5h10.6v10.6h-10.6zM393 295.1h11.3v11.3H393zM372.3 306.4H393v11.3h-20.7zM361.9 317.7h10.3v21.8h-10.3zM361.9 350.1v10.7h-10.7v-10.7h-17.8v7.7h-9.5v9.5h10.6V378h-10.6v-10.6h-11.3V384h-10.7v10.6h-7.1v10.7H287c-8.8 13-30.7 53.7-37.4 70.2-23.9-33.3-42.5-46.5-57.1-51.3-7.9-2.4-17.4 6.2-4.3 19.7 30.1 33.1 39.6 60.5 48.7 82.4 4.8 11.5 26.5 13.3 30.9 1.6 9.3-24.8 22.7-52.7 38.6-75.6v-11.9h8.5v-10.7h8.9v-12.4h10.7v-12h-10.8v-10.9h10.8v10.9h10.6v-10.8h8.5v-13h9.5v-8.9h9.2v-22.5h-10.4z" />
      <path
        d="M156.2 670.6h8v5.8c2.6-3.6 6.2-6.5 11.8-6.5 8.2 0 16.2 6.5 16.2 18.1v.1c0 11.6-7.9 18.1-16.2 18.1-5.7 0-9.4-2.9-11.8-6.1v16h-8v-45.5zm27.9 17.5v-.1c0-6.7-4.6-11.2-10-11.2s-10.2 4.5-10.2 11.2v.1c0 6.7 4.8 11.2 10.2 11.2 5.5 0 10-4.3 10-11.2M198.7 688.3v-.1c0-10 7.9-18.2 18.6-18.2s18.5 8.1 18.5 18.1v.1c0 9.9-7.9 18.2-18.7 18.2-10.5-.1-18.4-8.2-18.4-18.1m29.2 0v-.1c0-6.1-4.4-11.2-10.7-11.2-6.4 0-10.5 5-10.5 11.1v.1c0 6.1 4.4 11.2 10.6 11.2 6.5 0 10.6-5.1 10.6-11.1M239.7 670.6h8.2l7 23.9 7.7-24h6.8l7.8 24 7.1-23.9h8l-11.4 35.2h-7.1l-7.8-23.9-7.9 23.9H251zM296.4 688.2v-.1c0-10 7.1-18.2 17-18.2 11.1 0 16.7 8.7 16.7 18.8 0 .7-.1 1.4-.1 2.2h-25.6c.9 5.7 4.9 8.8 10 8.8 3.9 0 6.7-1.4 9.4-4.2l4.7 4.2c-3.3 4-7.9 6.5-14.3 6.5-10 .1-17.8-7.2-17.8-18m25.8-2.6c-.5-5.1-3.6-9.2-8.8-9.2-4.9 0-8.3 3.8-9 9.2h17.8zM338.5 670.6h8v7.9c2.2-5.2 6.2-8.8 12.3-8.5v8.5h-.5c-7 0-11.9 4.6-11.9 13.8v13.3h-8v-35zM363.4 688.2v-.1c0-10 7.1-18.2 17-18.2 11.1 0 16.7 8.7 16.7 18.8 0 .7-.1 1.4-.1 2.2h-25.6c.9 5.7 4.9 8.8 10 8.8 3.9 0 6.7-1.4 9.4-4.2l4.7 4.2c-3.3 4-7.9 6.5-14.3 6.5-10 .1-17.8-7.2-17.8-18m25.8-2.6c-.5-5.1-3.6-9.2-8.8-9.2-4.9 0-8.3 3.8-9 9.2h17.8zM403.7 688.1v-.1c0-11.5 7.9-18.1 16.2-18.1 5.7 0 9.4 2.9 11.9 6.1v-18.7h8v48.2h-8v-5.8c-2.6 3.6-6.2 6.5-11.9 6.5-8.3 0-16.2-6.5-16.2-18.1m28.1 0v-.1c0-6.7-4.8-11.2-10.1-11.2-5.5 0-10 4.2-10 11.2v.1c0 6.7 4.6 11.2 10 11.2s10.1-4.5 10.1-11.2M477.4 700.1v5.4h-8v-48.2h8v19.1c2.6-3.6 6.2-6.5 11.8-6.5 8.2 0 16.2 6.5 16.2 18.1v.1c0 11.6-7.9 18.1-16.2 18.1-5.7 0-9.3-2.9-11.8-6.1m19.9-12v-.1c0-6.7-4.6-11.2-10-11.2s-10.2 4.5-10.2 11.2v.1c0 6.7 4.8 11.2 10.2 11.2 5.6 0 10-4.3 10-11.2M510.9 714.1l2.7-5.9c1.5.8 3 1.3 4.7 1.3 2.2 0 3.6-1 4.9-4l-14.5-34.9h8.5l9.9 25.9 9-25.9h8.3l-13.9 35.8c-2.8 7.1-5.9 9.8-11.5 9.8-3.2.1-5.6-.7-8.1-2.1"
        className="st2"
      />
      <path
        d="M568.1 659.3h9l13.9 35.5 13.9-35.5h8.7l-19.2 46.5h-7.1zM615.3 688.2v-.1c0-10 7.1-18.2 17-18.2 11.1 0 16.7 8.7 16.7 18.8 0 .7-.1 1.4-.1 2.2h-25.6c.9 5.7 4.9 8.8 10 8.8 3.9 0 6.7-1.4 9.4-4.2l4.7 4.2c-3.3 4-7.9 6.5-14.3 6.5-10 .1-17.8-7.2-17.8-18m25.8-2.6c-.5-5.1-3.6-9.2-8.8-9.2-4.9 0-8.3 3.8-9 9.2h17.8zM657.3 670.6h8v7.9c2.2-5.2 6.2-8.8 12.3-8.5v8.5h-.5c-7 0-11.9 4.6-11.9 13.8v13.3h-8v-35zM684.2 657.7h8.6v7.6h-8.6v-7.6zm.3 12.9h8v34.9h-8v-34.9zM700.8 698.8l4.9-5.8c4.4 3.8 8.8 6 14.6 6 5 0 8.2-2.3 8.2-5.8v-.2c0-3.3-1.8-5.1-10.4-7.1-9.8-2.4-15.4-5.3-15.4-13.8v-.1c0-7.9 6.6-13.4 15.8-13.4 6.7 0 12.1 2 16.8 5.8l-4.4 6.1c-4.2-3.1-8.3-4.8-12.5-4.8-4.8 0-7.5 2.4-7.5 5.5v.1c0 3.6 2.1 5.1 11 7.3 9.8 2.4 14.8 5.9 14.8 13.5v.1c0 8.7-6.8 13.8-16.5 13.8-7.2.1-13.9-2.3-19.4-7.2M745.4 657.7h8.6v7.6h-8.6v-7.6zm.3 12.9h8v34.9h-8v-34.9zM763.7 712l3-6c3.7 2.3 7.7 3.7 12.4 3.7 7.1 0 11-3.7 11-10.7v-2.7c-2.9 3.8-6.5 6.4-12.3 6.4-8.2 0-15.8-6.1-15.8-16.3v-.1c0-10.3 7.7-16.4 15.8-16.4 5.9 0 9.5 2.7 12.2 5.9v-5.2h8v27.7c0 5.9-1.5 10.2-4.4 13.1-3.2 3.2-8.1 4.7-14.3 4.7-5.7.1-11.1-1.4-15.6-4.1m26.5-25.6v-.1c0-5.7-4.7-9.6-10.2-9.6-5.5 0-9.9 3.8-9.9 9.6v.1c0 5.7 4.4 9.6 9.9 9.6s10.2-4 10.2-9.6M808.3 670.6h8v5.4c2.2-3.3 5.5-6.1 10.9-6.1 7.9 0 12.4 5.3 12.4 13.4v22.2h-8v-19.8c0-5.4-2.7-8.5-7.5-8.5-4.6 0-7.9 3.2-7.9 8.6v19.7h-8v-34.9z"
        className="st4"
      />
    </svg>
  );
};
