import CustomTooltip from '../../../components/common/tooltip';
import { toolTipInfo } from '../../../page-builder/utils/constants';
import { CustomField } from '@measured/puck';

interface InputProps {
  value: string | undefined;
  onChange: (_value: string) => void;
  field?: CustomField<string>;
  placeholder?: string;
}

const InputField = ({ value, onChange, field, placeholder }: InputProps) => {
  return (
    <>
      {field && (
        <CustomTooltip title={field?.label as keyof typeof toolTipInfo}>
          <label className="text-sm font-bold">{field?.label}</label>
        </CustomTooltip>
      )}
      <input
        type="text"
        className="w-full px-4 py-3 rounded-sm"
        value={value}
        placeholder={placeholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target?.value)
        }
      />
    </>
  );
};

export default InputField;
