import React, { useState } from 'react';
import { PopUpModal, showNotification, ToggleSwitch } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import userService from 'services/userService';
import './style.scss';
import { ICreateAdminUser } from 'interface/settingsInterface';
import { emailRegex } from 'utils/constants';
interface AddUserProps {
  setShowAddUsers: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => Promise<void>;
}

export default function AddUsers({ setShowAddUsers, onSuccess }: AddUserProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, control } = useForm<ICreateAdminUser>({
    defaultValues: {
      SuperAdmin: false,
    },
  });
  const onSubmit = async (data: ICreateAdminUser) => {
    setLoading(true);
    try {
      const res = await userService.createAdminUser(data);
      if (res.status === 200) {
        onSuccess();
        setShowAddUsers(false);
      }
    } catch (error) {
      showNotification('error', 'Failed to add Users');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PopUpModal
      open={true}
      handleClose={() => setShowAddUsers(false)}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => setShowAddUsers(false),
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'AddAdmin',
          type: 'submit',
          disabled: loading,
          loading,
        },
      ]}
      heading={'Add Users'}>
      <div className="m-auto popup-section">
        <form
          id="AddAdmin"
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4">
          <Controller
            name="Email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: emailRegex,
                message: 'Invalid Email',
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  label="Email"
                  placeholder="Enter Email"
                  value={value}
                  fixedSize="md"
                  onChange={e => onChange(e.target.value)}
                  errorString={error?.message}
                />
              );
            }}
          />
          <div className="flex items-center">
            <p className="common_input_label">Enable Super Admin</p>
            <Controller
              name="SuperAdmin"
              control={control}
              render={({ field: { ref, value, ...rest } }) => {
                return (
                  <ToggleSwitch
                    {...rest}
                    checked={value}
                    className="filter_form_input"
                    ref={ref}
                  />
                );
              }}
            />
          </div>
        </form>
      </div>
    </PopUpModal>
  );
}
