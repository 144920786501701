import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Checkbox } from 'shared-components';
import { Select } from 'shared-components';
import TextField from 'components/common/textField';
import { IRefundTransaction } from 'interface/transactionInterface';
import { getValueByPercentage } from 'utils/helper';
import { Controller, useForm } from 'react-hook-form';
import globalTransactionService from 'services/transactionService';
import { showNotification } from 'helper/common/commonFunctions';
import { transactionStatus } from 'components/dashboard/constant';

const availableActions = {
  refund: 'Refund',
  void: 'Void',
};

interface IRefundDetails {
  refundType: string;
  refundValue: string;
  cancelSubscription: boolean;
}

interface Iprops {
  status?: string;
  payload: IRefundTransaction;
  directAmount?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<number>>;
  onApiResponse: (_: boolean) => void;
  handleClose: () => void;
}

const RefundTransaction: FC<Iprops> = ({
  status,
  payload,
  directAmount,
  setLoading,
  onApiResponse,
  handleClose,
}) => {
  const refundtypes = {
    percentage: 'Percentage',
    directAmount: 'Direct amount',
    ...(status
      ? status !== transactionStatus.partialRefund
        ? { fullAmount: 'Full Amount' }
        : null
      : { fullAmount: 'Full Amount' }),
  };

  const refundOptions = Object.values(refundtypes).map(value => ({
    label: value,
    value,
  }));

  const refundInitialValues = {
    refundType: directAmount
      ? refundtypes.directAmount
      : refundtypes.fullAmount,
    refundValue: '',
    cancelSubscription: false,
  };

  const [actionLoading, setActionLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IRefundDetails>({
    defaultValues: refundInitialValues,
  });

  const refundTransaction = async (data: IRefundDetails) => {
    if (!payload) return;
    setActionLoading(true);
    setLoading && setLoading(pre => pre + 1);
    if (data.refundType === refundtypes.directAmount) {
      payload.Amount = data.refundValue;
    } else if (data.refundType === refundtypes.percentage) {
      payload.Amount = `${getValueByPercentage(
        Number(payload.Amount),
        Number(data.refundValue),
      )}`;
    }
    setLoading && handleClose();
    const res = await globalTransactionService.refundTransaction(payload);
    if (res.status === 200) {
      onApiResponse(true);
      showNotification('success', 'Transaction Refund Success');
    } else {
      onApiResponse(false);
    }
    handleClose();
    setLoading && setLoading(pre => pre - 1);
    setActionLoading(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={() => {
        handleClose();
      }}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          form: 'order-detail_refund',
          type: 'submit',
          disabled: actionLoading,
          loading: actionLoading,
        },
      ]}
      className="order-detail_refund_body"
      heading={availableActions.refund}>
      <form
        id="order-detail_refund"
        onSubmit={handleSubmit(refundTransaction)}
        className="order-detail_refund">
        {!directAmount && (
          <Controller
            name="refundType"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  label="Refund Type"
                  options={refundOptions}
                  value={value}
                  onChange={v => {
                    onChange(v);
                    setValue('refundValue', '');
                  }}
                />
              );
            }}
          />
        )}
        {(watch('refundType') === refundtypes.directAmount || directAmount) && (
          <div className="direct_amount_field">
            <Controller
              name="refundValue"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    label="Refund Amount"
                    placeholder="Enter Amount"
                    value={value}
                    fixedSize="md"
                    onChange={e => {
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      );
                    }}
                    errorString={error?.message}
                  />
                );
              }}
              rules={{
                required: 'Refund Amount is required',
                validate: (value: string) => {
                  if (isNaN(Number(value))) {
                    return 'Refund Amount should be a number';
                  }
                  if (Number(value) > Number(payload?.Amount)) {
                    return "Refund Amount can't be greater than transaction amount";
                  }
                },
              }}
            />
            <p
              className={`direct_amount_text ${errors.refundValue?.message ? 'additional_margin' : ''}`}>
              <span>/</span> ${payload?.Amount}
            </p>
          </div>
        )}
        {watch('refundType') === refundtypes.percentage && (
          <Controller
            name="refundValue"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <TextField
                  label="Refund Percentage"
                  placeholder="Enter Percentage"
                  value={value}
                  fixedSize="md"
                  onChange={e => {
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    );
                  }}
                  errorString={error?.message}
                />
              );
            }}
            rules={{
              required: 'Refund Percentage is required',
              validate: (value: string) => {
                if (isNaN(Number(value))) {
                  return 'Refund Percentage should be a number';
                }
                if (Number(value) > 100) {
                  return "Refund Percentage can't be greater than 100";
                }
              },
            }}
          />
        )}
        {!directAmount && (
          <div className="checkbox_wrapper">
            <Controller
              name="cancelSubscription"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    className="subscription_checkbox"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                );
              }}
            />
            <span className="checkbox_label">Cancel Subscription</span>
          </div>
        )}
      </form>
    </PopUpModal>
  );
};

export default RefundTransaction;
