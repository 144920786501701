import React, { useState } from 'react';
import CustomTooltip from '../../../components/common/tooltip';
import { urlRegExp } from '../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { DeleteIcon } from '../../../assets/icons/delete';
import Button from '../../../components/common/button';
import { EditIcon } from '../../../assets/icons/edit';
import { MonacoEditor } from '../monaco-editor/monaco-editor';

interface CssData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}

interface CustomCssProps {
  value: CssData[];
  onChange: (_value: CssData[]) => void;
}

const CustomCss: React.FC<CustomCssProps> = ({ value, onChange }) => {
  value = value || [];
  const [link, setLink] = useState<string>('');
  const [manualCss, setManualCss] = useState<string>(
    value.find((css: CssData) => css.name === 'Manual CSS')?.link ?? '',
  );
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const handleAddCss = (type?: string): void => {
    const newCss: CssData = {
      id: uuidv4(),
      name: '',
      link: '',
      added: true,
    };
    if (type === 'external_css') {
      const regExp = urlRegExp;
      if (link && !!regExp.test(link)) {
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
        return;
      }
      newCss.link = link;
      value.push(newCss);
      onChange([...value]);
      setLink('');
    } else {
      const index = value.findIndex(
        (css: CssData) => css.name === 'Manual CSS',
      );
      if (index !== -1) {
        value[index].link = manualCss;
        value[index].added = true;
        onChange([...value]);
      } else {
        newCss.name = 'Manual CSS';
        newCss.codeEditor = true;
        newCss.link = manualCss;
        value.push(newCss);
        onChange([...value]);
      }
    }
  };
  const handleEditCss = (newValue: string): void => {
    setLink(newValue);
  };

  const handleRemoveCss = (index: number): void => {
    const cssData = value[index];
    const RemoveCss = document.getElementById(`custom-css-${cssData.id}`);
    if (RemoveCss) {
      RemoveCss.remove();
    }
    onChange(value.filter((_: unknown, i: number) => i !== index));
  };

  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const handleEditorChange = (value: string | undefined) => {
    value && setManualCss(value);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <label className="flex justify-between text-sm font-medium text-gray-700 pagebuilder-theme">
          <CustomTooltip title={'Custom CSS'}>
            <span>Custom CSS</span>
          </CustomTooltip>
        </label>
        <Button
          label="Open Code Editor"
          className="!p-2 !text-[10px]"
          variant="phoenix_secondary"
          disabled={manualCss === '' ? false : true}
          onClick={() => setPopupOpen(!isPopupOpen)}
        />
      </div>
      {value.map(
        (css: CssData, index: number) =>
          css.name && (
            <div
              key={index}
              className="flex p-2 mt-2 justify-between items-center border rounded">
              <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                {css.name}
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setPopupOpen(true);
                  }}>
                  <EditIcon />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleRemoveCss(index);
                    setManualCss('');
                  }}>
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ),
      )}

      {value.map(
        (css: CssData, index: number) =>
          !css.name && (
            <div key={css.id} className="flex gap-3 p-2 mt-2 border top">
              <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                {css.link}
              </div>
              <button type="button" onClick={() => handleRemoveCss(index)}>
                <DeleteIcon />
              </button>
            </div>
          ),
      )}
      <div className="flex flex-col">
        <input
          className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
          placeholder="Place your external css link here"
          value={link}
          onChange={e => handleEditCss(e.target.value)}
        />
        {errorMessage && (
          <p className="text-red-500 text-xs">Invalid External URL</p>
        )}
        <Button
          label="+ Add"
          className="mt-4 w-full"
          variant="phoenix_secondary"
          onClick={() => handleAddCss('external_css')}
        />
      </div>
      <MonacoEditor
        isPopupOpen={isPopupOpen}
        setPopupOpen={setPopupOpen}
        value={manualCss}
        language={'scss'}
        handleEditorChange={handleEditorChange}
        handleSubmit={() => {
          handleAddCss('manual_css');
        }}
      />
    </div>
  );
};

export default CustomCss;
