import React, { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import './style.scss';
import { IFormProps } from '..';

interface IProps {
  selectedProduct?: IFormProps;
  handleClose: () => void;
  action: (_: IFormProps) => void;
  pageOptions: { label: string; value: string }[];
}

const AddCheckoutFlow: FC<IProps> = ({
  selectedProduct,
  handleClose,
  action,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IFormProps>({
    defaultValues: selectedProduct,
  });

  const onSubmit = async (data: IFormProps) => {
    setLoading(true);
    action(data);
    setLoading(false);
    handleClose();
  };

  return (
    <PopUpModal
      open={true}
      handleClose={() => handleClose()}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            !selectedProduct?.ID;
            handleClose();
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: selectedProduct?.ID ? 'Update' : 'Add',
          form: 'AddPostUpsellsForm',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      heading={selectedProduct?.ID ? 'Update' : 'Add'}
      className="flex-container">
      <div className="popup-section">
        <form id="AddPostUpsellsForm" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="Name"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={value => onChange(value)}
                label="Product"
                className="filter_form_input"
                errorString={error?.message}
                fixedSize="md"
                placeholder="Select product"
              />
            )}
          />
          <Controller
            name="Weight"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={value => onChange(value)}
                label="Status"
                className="filter_form_input"
                errorString={error?.message}
                fixedSize="md"
                placeholder="Select status"
              />
            )}
          />
          <Controller
            name="Page"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={value => onChange(value)}
                label="Discount Type"
                className="filter_form_input"
                errorString={error?.message}
                fixedSize="md"
                placeholder="Select discount type"
              />
            )}
          />
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddCheckoutFlow;
