import {
  ICustomerDetails,
  ICustomersSubscription,
} from 'interface/customerInterface';
import { IGetNotes } from 'interface/notesInterface';
import { IOrderTransactionHistory } from 'interface/orderDetailInterface';
import { IOrder, IOrdersList } from 'interface/orderInterface';
import {
  IMyStorePermission,
  ILegacyPaymentGateway,
  IStoreFront,
  ISubscriptionPlan,
  IUser,
  IUserPermission,
} from 'interface/settingsInterface';
import { IStore, IStoreFrontEventResponse } from 'interface/storeInterface';
import { ISubscriptionList } from 'interface/subscriptionManagementInterface';
import { ISubscriptionHistory } from 'interface/subscriptionInterface';
import { ITransactionsData } from 'interface/transactionInterface';
import { IInvitation } from 'interface/userInterface';
import { IMemberShipProducts } from 'interface/membershipOffersInterface';
import { IPaymentGateway } from 'shared-components/src/page-builder/interface/settingsInterface';

export const mockTransactionHistoryData = [
  {
    Amount: '100.00',
    Date: '2024-03-06',
    GatewayID: 'xyz123',
    Reason: 'Sample reason',
    Status: 'success',
    TransactionID: 'abc456',
    Type: 'purchase',
  },
  {
    Amount: '100.00',
    Date: '2024-03-06',
    GatewayID: 'xyz123',
    Reason: 'Sample reason',
    Status: 'success',
    TransactionID: 'abc456',
    Type: 'purchase',
  },
];

export const mockSubscriptionManagementData: ISubscriptionList = {
  subscriptions: [
    {
      Amount: '50',
      CreatedAt: '2024-03-20T10:45:00Z',
      CurrentCycle: 3,
      Customer: {
        Email: 'customer2@example.com',
        ID: 'cus_987654321',
        PhoneNumber: '+9876543210',
        FirstName: 'test',
        LastName: 'test',
      },
      Frequency: 'weekly',
      ID: 'sub_987654321',
      Interval: 0,
      NextBillingDate: '2024-03-27T10:45:00Z',
      PaymentProfileID: 'payment_987654321',
      PlanID: 'plan_987654321',
      AuthPeriod: 0,
      AuthPeriodUnit: '',
      Channel: {
        ID: 'channel456',
        Name: 'Channel 456',
      },
      Purchase: {
        CreatedAt: '2024-04-02T21:47:59.759427Z',
        ExternalOrderNumber: '',
        FirstName: 'Seena',
        LastName: 'Nadimi',
        PurchaseID: '',
      },
      SalvageAttempts: 2,
      Status: 'suspended',
      Store: {
        ID: 'store_987654321',
        Name: 'Another Store',
      },
      Version: 12,
    },
    {
      Amount: '50',
      CreatedAt: '2024-03-20T10:45:00Z',
      CurrentCycle: 3,
      AuthPeriod: 0,
      AuthPeriodUnit: '',
      Channel: {
        ID: 'channel456',
        Name: 'Channel 456',
      },
      Customer: {
        Email: 'customer2@example.com',
        ID: 'cus_987654321',
        PhoneNumber: '+9876543210',
        FirstName: 'test',
        LastName: 'test',
      },
      Frequency: 'weekly',
      ID: 'sub_987654321',
      Interval: 0,
      NextBillingDate: '2024-03-27T10:45:00Z',
      PaymentProfileID: 'payment_987654321',
      PlanID: 'plan_987654321',
      Purchase: {
        CreatedAt: '2024-04-02T21:47:59.759427Z',
        ExternalOrderNumber: '',
        FirstName: 'Seena',
        LastName: 'Nadimi',
        PurchaseID: '',
      },
      SalvageAttempts: 2,
      Status: 'suspended',
      Store: {
        ID: 'store_987654321',
        Name: 'Another Store',
      },
      Version: 12,
    },
  ],
};

export const ordersDataMock: IOrder[] = [
  {
    ChannelID: 'channel456',
    Channel: {
      ID: 'channel456',
      Name: 'Channel 456',
    },
    EstimatedTaxAmount: '0',
    DiscountAmount: '0',
    SubTotalAmount: '0',
    Customer: {
      Email: 'anothercustomer@example.com',
      ID: 'customer456',
      PhoneNumber: '9876543210',
    },
    Date: '2024-03-10',
    ExternalOrderID: 'external456',
    ID: 'order456',
    Items: [
      {
        ProductID: 'gid://shopify/Product/6978332655693',
        VariantID: 'gid://shopify/ProductVariant/40351935627341',
        Quantity: 3,
        OriginalPrice: '',
        UnitPrice: '',
        Name: '',
        Subtitle: 'Selling Plans Ski Wax',
        SalePrice: '25.99',
        DiscountAmount: '',
        ImageURL:
          'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
      },
      {
        ProductID: 'gid://shopify/Product/6978332655693',
        VariantID: 'gid://shopify/ProductVariant/40351935627341',
        Quantity: 3,
        OriginalPrice: '',
        DiscountAmount: '',
        UnitPrice: '',
        Name: '',
        Subtitle: 'Selling Plans Ski Wax',
        SalePrice: '25.99',
        ImageURL:
          'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
      },
    ],
    PaymentProfile: {
      ID: '',
      StoreID: '',
      CustomerID: '',
      CreatedAt: '',
      FirstName: 'Prudence',
      LastName: 'Kunde',
      Address1: '84910 Lake Mountainhaven',
      Address2: '',
      Organization: '',
      City: 'Jacyntheside',
      Zip: '82546',
      ProvinceCode: 'AK',
      CountryCode: 'US',
      CCLast4: '3325',
      CardExpiration: '2026-03-01T01:39:56.719863Z',
      CardBrand: '',
    },
    ShippingAddress: {
      Address1: '101 Pine St',
      Address2: '',
      City: 'Hamletsville',
      CountryCode: 'US',
      FirstName: 'Bob',
      LastName: 'Johnson',
      Organization: 'XYZ Corp.',
      ProvinceCode: 'FL',
      Zip: '54321',
    },
    Status: 'shipped',
    Store: {
      ID: 'store456',
      Name: 'Another Store',
    },
    Tags: ['tag3', 'tag4'],
    TotalAmount: '85.00',
    TrafficChannel: 'social media',
    Version: 2,
  },
  {
    ChannelID: 'channel456',
    Channel: {
      ID: 'channel456',
      Name: 'Channel 456',
    },
    EstimatedTaxAmount: '0',
    DiscountAmount: '0',
    SubTotalAmount: '0',
    Customer: {
      Email: 'anothercustomer@example.com',
      ID: 'customer456',
      PhoneNumber: '9876543210',
    },
    Date: '2024-03-10',
    ExternalOrderID: 'external456',
    ID: 'order456',
    Items: [
      {
        ProductID: 'gid://shopify/Product/6978332655693',
        VariantID: 'gid://shopify/ProductVariant/40351935627341',
        Quantity: 3,
        OriginalPrice: '',
        UnitPrice: '',
        Name: '',
        DiscountAmount: '',
        Subtitle: 'Selling Plans Ski Wax',
        SalePrice: '25.99',
        ImageURL:
          'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
      },
      {
        ProductID: 'gid://shopify/Product/6978332655693',
        VariantID: 'gid://shopify/ProductVariant/40351935627341',
        Quantity: 3,
        OriginalPrice: '',
        UnitPrice: '',
        DiscountAmount: '',
        Name: '',
        Subtitle: 'Selling Plans Ski Wax',
        SalePrice: '25.99',
        ImageURL:
          'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
      },
    ],
    PaymentProfile: {
      ID: '',
      StoreID: '',
      CustomerID: '',
      CreatedAt: '',
      FirstName: 'Prudence',
      LastName: 'Kunde',
      Address1: '84910 Lake Mountainhaven',
      Address2: '',
      Organization: '',
      City: 'Jacyntheside',
      Zip: '82546',
      ProvinceCode: 'AK',
      CountryCode: 'US',
      CCLast4: '3325',
      CardExpiration: '2026-03-01T01:39:56.719863Z',
      CardBrand: '',
    },
    ShippingAddress: {
      Address1: '101 Pine St',
      Address2: '',
      City: 'Hamletsville',
      CountryCode: 'US',
      FirstName: 'Bob',
      LastName: 'Johnson',
      Organization: 'XYZ Corp.',
      ProvinceCode: 'FL',
      Zip: '54321',
    },
    Status: 'shipped',
    Store: {
      ID: 'store456',
      Name: 'Another Store',
    },
    Tags: ['tag3', 'tag4'],
    TotalAmount: '85.00',
    TrafficChannel: 'social media',
    Version: 2,
  },
];

export const customersDataMock: ICustomerDetails[] = [
  {
    TrafficChannel: 'google',
    ID: 'caddbdb7-2890-44bf-994f-2c870e8a0bc1',
    CreatedAt: '2024-03-05T01:50:26.094688Z',
    Version: 1,
    Store: {
      ID: '06dfdd7f-2df4-48bf-9c49-06191df897c1',
      Name: 'Aufderhar Inc',
    },
    FirstName: '',
    LastName: '',
    PhoneNumber: '+12174882266',
    notes: [
      {
        author_id: '787',
        created_at: '2024-03-05T01:50:26.094688Z',
        text: 'Test data',
        updated_at: '2024-03-05T01:50:26.094688Z',
      },
    ],
    Email: 'guyweimann@deckow.info',
    RecentShippingAddress: {
      CustomerID: '',
      FirstName: 'Kameron',
      LastName: 'Eichmann',
      Address1: '810 Plainchester',
      Address2: '',
      Organization: '',
      City: 'Roobmouth',
      Zip: '75337',
      ProvinceCode: 'NV',
      CountryCode: 'US',
    },
    RecentPaymentProfile: {
      ID: '011ce0bd-d557-472a-a89c-9ab6d978af84',
      StoreID: '06dfdd7f-2df4-48bf-9c49-06191df897c1',
      CustomerID: 'caddbdb7-2890-44bf-994f-2c870e8a0bb1',
      FirstName: 'Kameron',
      LastName: 'Eichmann',
      Address1: '810 Plainchester',
      Address2: '',
      Organization: '',
      City: 'Roobmouth',
      Zip: '75337',
      ProvinceCode: 'NV',
      CountryCode: 'US',
      CCLast4: '5003',
      CardExpiration: '2026-03-04T20:50:26.094793-05:00',
      CreatedAt: '2024-03-04T20:50:26.094793-05:00',
      CardBrand: 'Amex',
    },
    Subscription: {
      Status: 'active',
      CurrentCycle: '1',
      NextBillingDate: '2024-03-22T01:50:26.094834Z',
      CreatedAt: '2024-03-05T01:50:26.094834Z',
      Amount: '0',
      Frequency: 'monthly',
      ID: 'subscription123',
      Interval: '1',
      Version: '1',
    },
    HasDisputed: false,
    IsBlacklisted: false,
    Tags: [],
  },
  {
    TrafficChannel: 'google',
    ID: 'caddbdb7-2890-44bf-994f-2c870e8a0bc1',
    CreatedAt: '2024-03-05T01:50:26.094688Z',
    Version: 1,
    Store: {
      ID: '06dfdd7f-2df4-48bf-9c49-06191df897c1',
      Name: 'Aufderhar Inc',
    },
    FirstName: '',
    LastName: '',
    PhoneNumber: '+12174882266',
    notes: [
      {
        author_id: '787',
        created_at: '2024-03-05T01:50:26.094688Z',
        text: 'Test data',
        updated_at: '2024-03-05T01:50:26.094688Z',
      },
    ],
    Email: 'guyweimann@deckow.info',
    RecentShippingAddress: {
      CustomerID: '',
      FirstName: 'Kameron',
      LastName: 'Eichmann',
      Address1: '810 Plainchester',
      Address2: '',
      Organization: '',
      City: 'Roobmouth',
      Zip: '75337',
      ProvinceCode: 'NV',
      CountryCode: 'US',
    },
    RecentPaymentProfile: {
      ID: '011ce0bd-d557-472a-a89c-9ab6d978af84',
      StoreID: '06dfdd7f-2df4-48bf-9c49-06191df897c1',
      CustomerID: 'caddbdb7-2890-44bf-994f-2c870e8a0bb1',
      FirstName: 'Kameron',
      LastName: 'Eichmann',
      Address1: '810 Plainchester',
      Address2: '',
      Organization: '',
      City: 'Roobmouth',
      Zip: '75337',
      ProvinceCode: 'NV',
      CountryCode: 'US',
      CCLast4: '5003',
      CardExpiration: '2026-03-04T20:50:26.094793-05:00',
      CreatedAt: '2024-03-04T20:50:26.094793-05:00',
      CardBrand: 'Amex',
    },
    Subscription: {
      Status: 'active',
      CurrentCycle: '1',
      NextBillingDate: '2024-03-22T01:50:26.094834Z',
      CreatedAt: '2024-03-05T01:50:26.094834Z',
      Amount: '0',
      Frequency: 'monthly',
      ID: 'subscription123',
      Interval: '1',
      Version: '1',
    },
    HasDisputed: false,
    IsBlacklisted: false,
    Tags: [],
  },
];

export const customerSubscriptionData: ICustomersSubscription = {
  Amount: 'string',
  AuthPeriod: 0,
  AuthPeriodUnit: 'string',
  ChannelID: 'string',
  CreatedAt: '2019-08-24T14:15:22Z',
  CurrentCycle: 0,
  CustomerID: 'string',
  Frequency: 'string',
  ID: 'string',
  IPAddress: 'string',
  Interval: 0,
  NextChargeDate: '2019-08-24T14:15:22Z',
  PaymentProfileID: 'string',
  PlanID: 'string',
  PurchaseID: 'string',
  SalvageAttempts: 0,
  Status: 'string',
  StoreID: 'string',
  UpdatedAt: '2019-08-24T14:15:22Z',
  Version: 0,
};

export const transactionsDataMock: ITransactionsData[] = [
  {
    ID: '026912be-bd25-46d9-a3c3-0bb4c890ccf1',
    Version: 1,
    CreatedAt: '0001-01-01T00:00:00Z',
    Store: {
      ID: '7381d17d-8c5e-4a86-a647-882432c98cac',
      Name: 'Collier, Wilderman and Auer',
    },
    CycleNumber: 122,
    TransactionKind: 'purchase',
    ChannelID: '1668fb1b-d93c-4c63-8adf-3b4dd04d44e5',
    Channel: {
      ID: 'channel456',
      Name: 'Channel 456',
    },
    Status: 'captured',
    ShopifyOrderNumber: '',
    TransactionID: '',
    ExternalOrderID: '1233232',
    Amount: '48',
    OrderID: '11111',
    Customer: {
      ID: '57d235d6-693d-4d4b-a7a6-cc2af1de0237',
      Email: 'stanborer@hilpert.name',
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
    },
    PaymentProfile: {
      ID: '',
      StoreID: '',
      CustomerID: '',
      CreatedAt: '',
      CardBin: '',
      FirstName: 'Prudence',
      LastName: 'Kunde',
      Address1: '84910 Lake Mountainhaven',
      Address2: '',
      Organization: '',
      City: 'Jacyntheside',
      Zip: '82546',
      ProvinceCode: 'AK',
      CountryCode: 'US',
      CCLast4: '3325',
      CardExpiration: '2026-03-01T01:39:56.719863Z',
      CardBrand: '',
    },
    DisputeAlerts: ['ETHOCA'],
    Tags: [],
    IPAddress: '',
    TrafficChannel: '',
    FailureReason: '',
    RefundedAmount: '',
    AVSCode: 'Y',
    AuthCode: '123456',
    CVVCode: 'M',
  },
  {
    ID: '026912be-bd25-46d9-a3c3-0bb4c890ccf1',
    Version: 1,
    CreatedAt: '0001-01-01T00:00:00Z',
    Store: {
      ID: '7381d17d-8c5e-4a86-a647-882432c98cac',
      Name: 'Collier, Wilderman and Auer',
    },
    CycleNumber: 122,
    TransactionKind: 'purchase',
    ChannelID: '1668fb1b-d93c-4c63-8adf-3b4dd04d44e5',
    Status: 'captured',
    ShopifyOrderNumber: '',
    TransactionID: '',
    ExternalOrderID: '1233232',
    Amount: '48',
    OrderID: '11111',
    Channel: {
      ID: 'channel456',
      Name: 'Channel 456',
    },
    Customer: {
      ID: '57d235d6-693d-4d4b-a7a6-cc2af1de0237',
      Email: 'stanborer@hilpert.name',
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
    },
    PaymentProfile: {
      ID: '',
      StoreID: '',
      CustomerID: '',
      CreatedAt: '',
      CardBin: '1234',
      FirstName: 'Prudence',
      LastName: 'Kunde',
      Address1: '84910 Lake Mountainhaven',
      Address2: '',
      Organization: '',
      City: 'Jacyntheside',
      Zip: '82546',
      ProvinceCode: 'AK',
      CountryCode: 'US',
      CCLast4: '3325',
      CardExpiration: '2026-03-01T01:39:56.719863Z',
      CardBrand: '',
    },
    DisputeAlerts: ['ETHOCA'],
    Tags: [],
    IPAddress: '',
    TrafficChannel: '',
    FailureReason: '',
    RefundedAmount: '',
    AVSCode: 'Y',
    AuthCode: '123456',
    CVVCode: 'M',
  },
];
export const orderMockData: IOrdersList = {
  Result: [
    {
      ID: '043f9c08-7ff2-43cc-af62-271bf38fecfb',
      Channel: {
        ID: 'channel456',
        Name: 'Channel 456',
      },
      EstimatedTaxAmount: '0',
      DiscountAmount: '0',
      SubTotalAmount: '0',
      Version: 2,
      Store: {
        ID: 'f6720d76-202f-4616-a877-eb9560f2fddc',
        Name: 'Goon  Store',
      },
      ChannelID: '874b9491-5a61-4f22-9239-2da77ded028e',
      TotalAmount: '4939.75',
      Items: [
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          OriginalPrice: '',
          UnitPrice: '',
          Name: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          DiscountAmount: '  ',
          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          OriginalPrice: '',
          UnitPrice: '',
          Name: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          DiscountAmount: '',
          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          OriginalPrice: '',
          UnitPrice: '',
          DiscountAmount: '',
          Name: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
      ],
      TrafficChannel: '',
      Date: '2024-03-13T00:36:12.244532Z',
      Customer: {
        ID: '02ce7f8b-add8-4b59-ae13-876cb4143c3a',
        Email: 'seenanadimi@gmail.com',
        PhoneNumber: '+1 5103671055',
      },
      Status: 'failed',
      ExternalOrderID: '',
      Tags: [],
      ShippingAddress: {
        FirstName: 'Seena',
        LastName: 'Nadimi',
        Address1: '6110 ROSALIND AVE',
        Address2: '',
        Organization: '',
        City: 'RICHMOND',
        Zip: '94805',
        ProvinceCode: 'CA',
        CountryCode: 'US',
      },
      PaymentProfile: {
        ID: '30b027fc-02b0-43fa-815a-edadb24aeac3',
        StoreID: '',
        CustomerID: '',
        CreatedAt: '0001-01-01T00:00:00Z',
        FirstName: 'Seena',
        LastName: 'Nadimi',
        Address1: '6110 ROSALIND AVE',
        Address2: '',
        Organization: '',
        City: 'RICHMOND',
        Zip: '94805',
        ProvinceCode: 'CA',
        CountryCode: 'US',
        CCLast4: '1111',
        CardExpiration: '2025-10-01T00:00:00Z',
        CardBrand: '',
      },
    },
    {
      ID: '043f9c08-7ff2-43cc-af62-271bf38fecfb',
      Version: 2,
      Channel: {
        ID: 'channel456',
        Name: 'Channel 456',
      },
      EstimatedTaxAmount: '0',
      DiscountAmount: '0',
      SubTotalAmount: '0',
      Store: {
        ID: 'f6720d76-202f-4616-a877-eb9560f2fddc',
        Name: 'Goon  Store',
      },
      ChannelID: '874b9491-5a61-4f22-9239-2da77ded028e',
      TotalAmount: '4939.75',
      Items: [
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          OriginalPrice: '',
          UnitPrice: '',
          Name: '',
          DiscountAmount: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          DiscountAmount: '',
          OriginalPrice: '',
          UnitPrice: '',
          Name: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
        {
          ProductID: 'gid://shopify/Product/6978332655693',
          VariantID: 'gid://shopify/ProductVariant/40351935627341',
          Quantity: 3,
          OriginalPrice: '',
          UnitPrice: '',
          Name: '',
          Subtitle: 'Selling Plans Ski Wax',
          SalePrice: '25.99',
          DiscountAmount: '',

          ImageURL:
            'https://cdn.shopify.com/s/files/1/0573/0729/9917/products/snowboard_wax.png?v=1703894696',
        },
      ],
      TrafficChannel: '',
      Date: '2024-03-13T00:36:12.244532Z',
      Customer: {
        ID: '02ce7f8b-add8-4b59-ae13-876cb4143c3a',
        Email: 'seenanadimi@gmail.com',
        PhoneNumber: '+1 5103671055',
      },
      Status: 'failed',
      ExternalOrderID: '',
      Tags: [],
      ShippingAddress: {
        FirstName: 'Seena',
        LastName: 'Nadimi',
        Address1: '6110 ROSALIND AVE',
        Address2: '',
        Organization: '',
        City: 'RICHMOND',
        Zip: '94805',
        ProvinceCode: 'CA',
        CountryCode: 'US',
      },
      PaymentProfile: {
        ID: '30b027fc-02b0-43fa-815a-edadb24aeac3',
        StoreID: '',
        CustomerID: '',
        CreatedAt: '0001-01-01T00:00:00Z',
        FirstName: 'Seena',
        LastName: 'Nadimi',
        Address1: '6110 ROSALIND AVE',
        Address2: '',
        Organization: '',
        City: 'RICHMOND',
        Zip: '94805',
        ProvinceCode: 'CA',
        CountryCode: 'US',
        CCLast4: '1111',
        CardExpiration: '2025-10-01T00:00:00Z',
        CardBrand: '',
      },
    },
  ],
};

export const orderTransactionHistory: IOrderTransactionHistory[] = [
  {
    Amount: '100.00',
    Date: '2024-03-15T08:00:00Z',
    GatewayID: 'GW123',
    Reason: 'Payment received',
    Status: 'success',
    TransactionID: 'TRX001',
    Type: 'payment',
  },
  {
    Amount: '100.00',
    Date: '2024-03-15T08:00:00Z',
    GatewayID: 'GW123',
    Reason: 'Payment received',
    Status: 'success',
    TransactionID: 'TRX001',
    Type: 'payment',
  },
];

export const mockNotesData: IGetNotes[] = [
  {
    ID: '4155d32d-74b2-454f-af4d-add356abc25f',
    Version: 0,
    StoreID: '632f4a37-61e2-46b8-9133-a410b4bdabae',
    User: {
      ID: '2bd31ae8-2d82-48e7-a10a-478c9329aca7',
      FirstName: '',
      LastName: '',
    },
    Note: 'test note 1',
    CreatedAt: '2024-04-12T12:08:32.257467Z',
  },
  {
    ID: '93042074-af5c-41ff-83be-5fc83560e2f6',
    Version: 0,
    StoreID: '632f4a37-61e2-46b8-9133-a410b4bdabae',
    User: {
      ID: '2bd31ae8-2d82-48e7-a10a-478c9329aca7',
      FirstName: '',
      LastName: '',
    },
    Note: 'test note 2',
    CreatedAt: '2024-04-12T12:08:40.264813Z',
  },
];

export const subscriptionHistoryMockData: ISubscriptionHistory = {
  TransactionEvents: [
    {
      Amount: '10.00',
      Date: '2024-03-17',
      GatewayId: '123456',
      Reason: 'Payment',
      Status: 'Success',
      TransactionId: 'ABC123',
      Type: 'Payment',
    },
    {
      Amount: '20.00',
      Date: '2024-03-16',
      GatewayId: '789012',
      Reason: 'Payment',
      Status: 'Success',
      TransactionId: 'DEF456',
      Type: 'Payment',
    },
  ],
  SubscriptionEvents: [
    {
      Date: '2024-03-15T10:30:00Z',
      Details: 'Subscription renewed',
      EventType: 'Renewal',
      User: {
        FirstName: 'John',
        ID: '123',
        LastName: 'Doe',
      },
    },
    {
      Date: '2024-03-10T09:45:00Z',
      Details: 'Subscription canceled',
      EventType: 'Cancellation',
      User: {
        FirstName: 'Jane',
        ID: '456',
        LastName: 'Smith',
      },
    },
  ],
};

// export const mockRetentionData: IRetention[] = [
//   {
//     approved: 1,
//     attempted: 100,
//     cancelled_before_billing: '123',
//     cb: '23%',
//     chargebacks: 22,
//     cycle_name: 'cycle 1',
//     overall_retention: '59%',
//     total_customers: 2000,
//   },
//   {
//     approved: 4,
//     attempted: 200,
//     cancelled_before_billing: '123',
//     cb: '23%',
//     chargebacks: 22,
//     cycle_name: 'cycle 2',
//     overall_retention: '58%',
//     total_customers: 12,
//   },
//   {
//     approved: 4,
//     attempted: 200,
//     cancelled_before_billing: '123',
//     cb: '23%',
//     chargebacks: 22,
//     cycle_name: 'cycle 3',
//     overall_retention: '58%',
//     total_customers: 12,
//   },
// ];
export const settingsSubsctriptionPlan: ISubscriptionPlan[] = [
  {
    Amount: '200',
    ChannelID: '2324',
    Description: 'Sample description',
    FreeTrialLength: 12,
    FreeTrialUnit: 'days',
    Frequency: 'monthly',
    ID: 'subscription_',
    Interval: 1,
    Name: 'Sample Subscription',
    StoreID: 'store_',
    Version: 1,
    AuthPeriodUnit: 'days',
    AuthPeriod: 1,
  },
];

export const settingsStores: IStore[] = [
  {
    CheckoutURL: 'www.url.com',
    ID: '3535',
    Name: 'Store',
    StoreDescription: 'some text',
    Version: 0,
  },
];

export const settingsStoreFronts: IStoreFront[] = [
  {
    ID: 'abc123',
    Name: 'MyShop',
    Platform: 'Shopify',
    ShopID: 'shop123',
    ShopURL: 'https://example.myshopify.com',
    Shopify: {
      AccessToken: 'abcdef123456',
      APIKey: 'xyz789',
      APISecret: '789xyz',
      ID: 'shop123',
      ShopID: 'shop123',
      StorefrontID: 'storefront123',
      StorefrontPublicAccessToken: 'publictoken123',
      MembershipType: 'basic',
      Version: 2,
    },
    StoreID: 'store456',
    Version: 1,
  },
];

export const settingsPaymentGateways: ILegacyPaymentGateway[] = [
  {
    ID: '11',
    Secret: 'fhbtjyjky',
    StoreID: '13',
    Version: 12,
  },
];
export const PaymentGateways: IPaymentGateway[] = [
  {
    ID: '11',
    StoreID: '13',
    Creds: {
      Secret: 'test',
    },
    Kind: 'stripe',
    Version: 12,
  },
];

export const settingsUsers: IUser[] = [
  {
    created_at: '2026-03-01T01:39:56.719863Z',
    email: 'email@email.com',
    first_name: 'John',
    id: '123',
    last_name: 'Doe',
    photo_url: 'www.null.com',
    super_admin: true,
    version: 0,
  },
];

export const permissions: IUserPermission[] = [
  {
    FirstName: 'Mark',
    LastName: 'antony',
    Email: 'mark@gmail.com',
    Permissions: ['view:orders', 'view:customers'],
    SuperAdmin: false,
  },
];

export const InvitesList: IInvitation[] = [
  {
    CreatedAt: '2024-04-05T09:15:00Z',
    Email: 'alice@example.com',
    ID: '111111111',
    Permissions: ['store view'],
    Status: 'active',
    Store: {
      ID: '999999999',
      Name: 'Online Store',
    },
    Version: 1,
  },
  {
    CreatedAt: '2024-04-05T10:30:00Z',
    Email: 'bob@example.com',
    ID: '222222222',
    Permissions: ['product manage'],
    Status: 'active',
    Store: {
      ID: '888888888',
      Name: 'Fashion Outlet',
    },
    Version: 2,
  },
  {
    CreatedAt: '2024-04-05T12:00:00Z',
    Email: 'diana@example.com',
    ID: '444444444',
    Permissions: ['store view', 'product manage'],
    Status: 'inactive',
    Store: {
      ID: '666666666',
      Name: 'Home Decor Haven',
    },
    Version: 3,
  },
  {
    CreatedAt: '2024-04-05T13:15:00Z',
    Email: 'emma@example.com',
    ID: '555555555',
    Permissions: ['order manage'],
    Status: 'active',
    Store: {
      ID: '555555555',
      Name: 'Bookstore',
    },
    Version: 1,
  },
  {
    CreatedAt: '2024-04-05T14:30:00Z',
    Email: 'frank@example.com',
    ID: '666666666',
    Permissions: ['store view', 'product manage', 'order manage'],
    Status: 'active',
    Store: {
      ID: '444444444',
      Name: 'Grocery Mart',
    },
    Version: 2,
  },
  {
    CreatedAt: '2024-04-05T15:45:00Z',
    Email: 'george@example.com',
    ID: '777777777',
    Permissions: ['store view'],
    Status: 'inactive',
    Store: {
      ID: '333333333',
      Name: 'Tech Store',
    },
    Version: 1,
  },
  {
    CreatedAt: '2024-04-05T16:00:00Z',
    Email: 'hannah@example.com',
    ID: '888888888',
    Permissions: ['order manage'],
    Status: 'active',
    Store: {
      ID: '222222222',
      Name: 'Sports Gear Shop',
    },
    Version: 1,
  },
  {
    CreatedAt: '2024-04-05T17:15:00Z',
    Email: 'ian@example.com',
    ID: '999999999',
    Permissions: ['store view', 'product manage'],
    Status: 'inactive',
    Store: {
      ID: '111111111',
      Name: 'Pet Supplies Store',
    },
    Version: 1,
  },
  {
    CreatedAt: '2024-04-05T18:30:00Z',
    Email: 'jane@example.com',
    ID: '101010101',
    Permissions: ['store view', 'order manage'],
    Status: 'inactive',
    Store: {
      ID: '101010101',
      Name: 'Furniture Outlet',
    },
    Version: 1,
  },
];

export const permissionsMockData: IMyStorePermission = {
  StorePermissions: [
    {
      ID: '10292e33-029c-4172-a3c6-74c55df46bb6',
      Version: 0,
      CreatedAt: '2024-04-11T12:24:14.732851Z',
      UserID: '2bd31ae8-2d82-48e7-a10a-478c9329aca7',
      Store: {
        ID: 'c2001dfe-de4e-4b92-982f-0e013caf237b',
        Name: '',
      },
      Permissions: [
        'view:customer',
        'view:transaction',
        'view:order',
        'manage:subscription',
      ],
    },
  ],
  SuperAdmin: true,
};

// export const mockRebillData: IReBilling[] = [
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '9',
//     DisputeCancelledBeforeBilling: '2',
//     Remaining: '93',
//     Pendings: '10',
//     Attempted: '80',
//     Approved: '65',
//     Decline: '15',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',
//   },
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '20',
//     DisputeCancelledBeforeBilling: '20',
//     Remaining: '93',
//     Pendings: '50',
//     Attempted: '80',
//     Approved: '90',
//     Decline: '50',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',
//   },
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '15',
//     DisputeCancelledBeforeBilling: '20',
//     Remaining: '93',
//     Pendings: '50',
//     Attempted: '80',
//     Approved: '100',
//     Decline: '28',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',
//   },
// ];
// export const mockRebillData: IReBilling[] = [
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '9',
//     DisputeCancelledBeforeBilling: '2',
//     Remaining: '93',
//     Pendings: '10',
//     Attempted: '80',
//     Approved: '65',
//     Decline: '15',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',
//   },
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '20',
//     DisputeCancelledBeforeBilling: '20',
//     Remaining: '93',
//     Pendings: '50',
//     Attempted: '80',
//     Approved: '90',
//     Decline: '50',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',
//   },
//   {
//     CycleName: 'April 2024',
//     Total: '100',
//     SupportCancelledBeforeBilling: '15',
//     DisputeCancelledBeforeBilling: '20',
//     Remaining: '93',
//     Pendings: '50',
//     Attempted: '80',
//     Approved: '100',
//     Decline: '28',
//     Revenue: '6500',
//     AverageMemberValue: '65',
//     FailedBillingCancelled: '3',

// export const sampleRiskData: IRiskManagement[] = [
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
//   {
//     CycleName: 'Sample Cycle',
//     TotalTransactions: '1000',
//     TotalDisputes: '50',
//     DisputeRatio: '5%',
//     ChargebackRatio: '2%',
//     VisaRdr: '10%',
//     VisaCdrn: '8%',
//     Ethoca: '3%',
//   },
// ];
export const errorStoreFrontEventResponse: IStoreFrontEventResponse[] = [
  {
    ChannelID: '12345',
    ChannelName: 'Online Store',
    CreatedAt: '2024-06-06T12:34:56Z',
    Data: {
      query: 'summer dresses',
    },
    EventName: 'SearchEvent',
    ID: 'abcde-12345-fghij-67890',
    Level: 'Info',
    Name: 'SearchQuery',
    Resolved: true,
    Store: {
      ID: '',
      Name: '',
    },
    Note: 'remarks1',
    Tags: ['search', 'user-interaction', 'ecommerce'],
    Version: 1,
    IPAddress: '',
    IsBlacklisted: true,
  },
  {
    ChannelID: '12345',
    ChannelName: 'Online Store',
    CreatedAt: '2024-06-06T12:34:56Z',
    Data: {
      query: 'summer dresses',
    },
    EventName: 'SearchEvent',
    ID: 'abcde-12345-fghij-67890',
    Level: 'Info',
    Name: 'SearchQuery',
    Resolved: true,
    Store: {
      ID: '',
      Name: '',
    },
    Note: 'remarks',
    Tags: ['search', 'user-interaction', 'ecommerce'],
    Version: 1,
    IPAddress: '',
    IsBlacklisted: false,
  },
  {
    ChannelID: '12345',
    ChannelName: 'Online Store',
    CreatedAt: '2024-06-06T12:34:56Z',
    Data: {
      query: 'summer dresses',
    },
    EventName: 'SearchEvent',
    ID: 'abcde-12345-fghij-67890',
    Level: 'Info',
    Name: 'SearchQuery',
    Resolved: true,
    Store: {
      ID: '',
      Name: '',
    },
    Note: 'remarks2',
    Tags: ['search', 'user-interaction', 'ecommerce'],
    Version: 1,
    IPAddress: '',
    IsBlacklisted: false,
  },
];

export const mockMemberShipProducts: IMemberShipProducts = {
  StartCursor: 'CUR123',
  EndCursor: 'CUR789',
  HasNextPage: true,
  HasPreviousPage: false,
  Result: [
    {
      Description: 'A high-quality, comfortable pair of running shoes.',
      Handle: 'running-shoes',
      ID: 'PROD001',
      ImageURL: 'https://example.com/images/prod001.jpg',
      MaxDiscountedPrice: '79.99',
      MaxOriginalPrice: '99.99',
      MembershipGroupsCount: 3,
      MinDiscountedPrice: '49.99',
      MinOriginalPrice: '79.99',
      Title: 'Running Shoes',
    },
    {
      Description: 'A stylish leather wallet with multiple card slots.',
      Handle: 'leather-wallet',
      ID: 'PROD002',
      ImageURL: 'https://example.com/images/prod002.jpg',
      MaxDiscountedPrice: null,
      MaxOriginalPrice: '49.99',
      MembershipGroupsCount: 5,
      MinDiscountedPrice: null,
      MinOriginalPrice: '39.99',
      Title: 'Leather Wallet',
    },
    {
      Description: 'An ergonomic office chair with lumbar support.',
      Handle: 'office-chair',
      ID: 'PROD003',
      ImageURL: 'https://example.com/images/prod003.jpg',
      MaxDiscountedPrice: '199.99',
      MaxOriginalPrice: '249.99',
      MembershipGroupsCount: 2,
      MinDiscountedPrice: '179.99',
      MinOriginalPrice: '199.99',
      Title: 'Ergonomic Office Chair',
    },
  ],
};
