import React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import 'components/common/Tabs/style.scss';

interface TabsProps {
  value: string;
  onChange: (_newValue: string) => void;
  labels: string[];
}

const Tabs: React.FC<TabsProps> = ({ value, onChange, labels }) => {
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    onChange(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        className="tabsContainer"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto">
        {labels.map((label, index) => {
          return (
            <Tab key={index} label={label} value={(index + 1).toString()} />
          );
        })}
        {/* <Tab label="Notes" value="2" /> */}
        {/* <Tab label="subscription history" value="3" /> */}
      </TabList>
    </TabContext>
  );
};

export default Tabs;
