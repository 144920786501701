import { AxiosError } from 'axios';
import {
  commonError,
  createAssetsEP,
  deleteAssetsEP,
  searchAssetsEP,
} from './constant';
import {
  IGetImages,
  IGetImagesResponse,
} from '../interface/apiServiceInterface';
import { IDeleteAsset } from '../interface/checkoutInterface';
import { AxiosInstance } from 'axios';

class PageBuilderService {
  getAssets = async (payload: IGetImages, baseService: AxiosInstance) => {
    try {
      const response = await baseService.post<IGetImagesResponse>(
        searchAssetsEP,
        payload,
      );
      return response;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  createAsset = async (payload: FormData[], baseService: AxiosInstance) => {
    try {
      // Create an array of promises for the API calls
      const requests = payload.map(formData =>
        baseService.post<{ ID: string }>(createAssetsEP, formData),
      );

      // Wait for all API calls to complete
      return await Promise.all(requests).then(responses => {
        return { status: 200, ...responses };
      });
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };

  deleteAsset = async (payload: IDeleteAsset, baseService: AxiosInstance) => {
    try {
      const responses = baseService.post<{ ID: string }>(
        deleteAssetsEP,
        payload,
      );
      return responses;
    } catch (error) {
      throw commonError(error as AxiosError, true);
    }
  };
}

const pageBuilderService = new PageBuilderService();
export default pageBuilderService;
