import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.scss';

export type QuillTextEditorProps = {
  content: string;
};

const Size = ReactQuill.Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large', 'extra-large'];
ReactQuill.Quill.register(Size, true);

const Font = ReactQuill.Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
  'times-new-roman',
  'verdana',
  'roboto',
  'poppins',
  'sans-serif',
  'serif',
  'monospace',
  'cursive',
  'fantasy',
  'system-ui',
  'ui-serif',
];
ReactQuill.Quill.register(Font, true);

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-font">
      <option value="arial" selected>
        Arial
      </option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
      <option value="times-new-roman">Times New Roman</option>
      <option value="verdana">Verdana</option>
      <option value="roboto">Roboto</option>
      <option value="poppins">Poppins</option>
      <option value="sans-serif">Sans Serif</option>
      <option value="serif">Serif</option>
      <option value="monospace">Monospace</option>
      <option value="cursive">Cursive</option>
      <option value="system-ui">System UI</option>
      <option value="ui-serif">UI Serif</option>
    </select>
    <select className="ql-align" />
    <select className="ql-color" />
    <select className="ql-background" />
    <select className="ql-size">
      <option value="extra-small">Extra Small</option>
      <option value="small">Small</option>
      <option value="medium">Medium</option>
      <option value="large">Large</option>
      <option value="extra-large">Extra Large</option>
    </select>
    <button className="ql-link" />
    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-clean" />
  </div>
);

const formats = [
  'list',
  'bullet',
  'bold',
  'italic',
  'underline',
  'color',
  'background',
  'link',
  'align',
  'font',
  'size',
];

type QuillEditorProps = {
  value: string;
  onChange: (_value: string) => void;
};

export const QuillEditor = ({ value, onChange }: QuillEditorProps) => {
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  return (
    <div className="text-editor">
      <CustomToolbar />
      <ReactQuill
        className="pagebuilder-theme"
        theme={'snow'}
        onChange={onChange}
        value={value}
        modules={modules}
        formats={formats}
        placeholder={'create content here...'}
      />
    </div>
  );
};
