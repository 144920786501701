import ReactDOM from 'react-dom/client';
import 'index.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://f6534b83d38c84be74dbb523b30f5ae1@o4506674966888448.ingest.sentry.io/4506741026193408',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT,
  // Enable correct env var for Sentry in prod/staging
  enabled:
    process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT === 'production' ||
    process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT === 'staging',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    //TODO: update prod and staging urls
    'https://softsuave-p.netlify.app',
    'https://softsuave-staging.netlify.app/',
    // A regex to match all subdomains of the api url
    new RegExp(`^${process.env.REACT_APP_BASE_URL}`),
  ],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.log('starting', process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT);
