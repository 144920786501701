import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  IDeleteInvites,
  IInvitation,
  IRegisterUser,
} from 'interface/userInterface';
import {
  commonError,
  getUsersEP,
  getInvitationsEP,
  registerUserEP,
  sendInviteEP,
  updateUsersPermissionEP,
  getAcceptInviteEP,
  getDeclineInviteEP,
  getUsersPermissionEP,
  updateInvitationPermissionEP,
  getGrantSuperAdminEP,
  listUserInvitations,
  deleteInvitesEP,
  listUserPermissions,
  removeUserPermissions,
  reSendInviteEP,
  getAllUsersEP,
  getRevokeSuperAdmin,
  getStorePermissionsEP,
  createAdminUsersEP,
  updateAdminUsersEP,
} from 'services/constants';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  ICreateUserPermission,
  IUser,
  IUserPermission,
  IInvitationPermission,
  IUserInvitation,
  IResendInvite,
  IAllUsers,
  IRemoveUserPermission,
  IUserPermissionRemove,
  IResponceGetStorePermissionByID,
  ICreateAdminUser,
  IUpdateAdminUser,
} from 'interface/settingsInterface';
import { InvitesList, permissions } from 'utils/mockData';
export interface IGetInvites {
  Descending: boolean;
  Limit: number;
  OrderBy: string;
  Page: number;
  SearchFields?: { [key: string]: string };
}
class UserMockService {
  commonResponse = async <T = null>(
    data: T | null = null,
  ): Promise<ISuccessResponse<T> | IErrorResponse<AxiosError>> => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: data ?? null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getMyPermissions = async () => {
    try {
      const response = await baseService.get(getUsersPermissionEP);
      return response;
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };
  registerUser = async () => {
    return this.commonResponse();
  };

  getUsers = async () => {
    return this.commonResponse();
  };
  getUsersPermission = async () => {
    return this.commonResponse(permissions);
  };
  getUsersPermissionByID = async () => {
    return this.commonResponse({
      Result: [],
      TotalCount: 0,
    });
  };
  removeUserPermission = async () => {
    return this.commonResponse(permissions);
  };
  sendInvite = async () => {
    return this.commonResponse();
  };
  updateUserPermission = async () => {
    return this.commonResponse<IUserPermission[]>(permissions);
  };
  getInvites = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  getStoreInvites = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  acceptInvite = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  declineInvite = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  deleteInvite = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  updateInvitationPermission = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  getAllUsers = async () => {
    return this.commonResponse();
  };
  grantSuperAdmin = async () => {
    return this.commonResponse();
  };
  revokeSuperAdmin = async () => {
    return this.commonResponse();
  };
  reSendInvite = async () => {
    return this.commonResponse<IInvitation[]>(InvitesList);
  };
  createAdminUser = async () => {
    return this.commonResponse();
  };
  updateAdminUser = async () => {
    return this.commonResponse();
  };
}
class UserService {
  userMockService = new UserMockService();
  registerUser = async (
    user: IRegisterUser,
  ): Promise<ISuccessResponse<IRegisterUser> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IRegisterUser>(registerUserEP, user);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getUsers = async (
    store: string,
  ): Promise<ISuccessResponse<IUser> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.get<IUser>(getUsersEP(store));
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getUsersPermission = async (payload: IUserInvitation) => {
    try {
      const response = await baseService.post<IUserPermission>(
        listUserPermissions,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getUsersPermissionByID = async (
    userId: string,
  ): Promise<
    | ISuccessResponse<IResponceGetStorePermissionByID>
    | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<IResponceGetStorePermissionByID>(
        getStorePermissionsEP(userId),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  removeUserPermission = async (payload: IRemoveUserPermission) => {
    try {
      const response = await baseService.post<IUserPermissionRemove>(
        removeUserPermissions,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  sendInvite = async (
    data: ICreateUserPermission,
  ): Promise<
    ISuccessResponse<IUserPermission> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<IUserPermission>(
        sendInviteEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateUserPermission = async (
    data: ICreateUserPermission,
  ): Promise<ISuccessResponse<IRegisterUser> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IUserPermission>(
        updateUsersPermissionEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateInvitationPermission = async (
    data: ICreateUserPermission,
  ): Promise<ISuccessResponse<IRegisterUser> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IInvitationPermission>(
        updateInvitationPermissionEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getInvites = async (body: IGetInvites) => {
    try {
      const response = await baseService.post<IUserPermission>(
        getInvitationsEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreInvites = async (payload: IUserInvitation) => {
    try {
      const response = await baseService.post<IUserPermission>(
        listUserInvitations,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  acceptInvite = async ({
    StoreID,
    InviteID,
  }: {
    StoreID: string;
    InviteID: string;
  }) => {
    try {
      const response = await baseService.post(
        getAcceptInviteEP(StoreID, InviteID),
        { InviteID, StoreID },
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  declineInvite = async ({
    StoreID,
    InviteID,
  }: {
    StoreID: string;
    InviteID: string;
  }) => {
    try {
      const response = await baseService.post(
        getDeclineInviteEP(StoreID, InviteID),
        { InviteID, StoreID },
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  deleteInvite = async (data: IDeleteInvites) => {
    try {
      const response = await baseService.post(deleteInvitesEP, data);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getMyPermissions = async () => {
    try {
      const response =
        await baseService.get<IUserPermission>(getUsersPermissionEP);
      return response;
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };

  getAllUsers = async (
    payload: IAllUsers,
  ): Promise<ISuccessResponse<IUser> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(getAllUsersEP, payload);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  grantSuperAdmin = async (UserID: string) => {
    try {
      const response = await baseService.post(getGrantSuperAdminEP(UserID), {
        UserID,
      });
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  revokeSuperAdmin = async (UserID: string) => {
    try {
      const response = await baseService.post(getRevokeSuperAdmin(UserID), {
        UserID,
      });
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  reSendInvite = async (
    data: IResendInvite,
  ): Promise<
    ISuccessResponse<IUserPermission> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<{ InviteID: string }>(
        reSendInviteEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createAdminUser = async (body: ICreateAdminUser) => {
    try {
      const response = await baseService.post(createAdminUsersEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateAdminUser = async (body: IUpdateAdminUser) => {
    try {
      const response = await baseService.post(updateAdminUsersEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalUserService: UserService | UserMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new UserMockService()
    : new UserService();

export default globalUserService;
