import { useLocation } from 'react-router-dom';
import { Fields, DefaultComponentProps, usePuck } from '@measured/puck';
import { mandatoryComponents, Path } from 'shared-components';
export const convertBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = err => {
      reject(err);
    };
  });
};

export const viewPorts = {
  mobile: 360,
  tablet: 768,
  desktop: 1280,
};

export const devices = {
  mobile: 'mobile' as const,
  tablet: 'tablet' as const,
  desktop: 'desktop' as const,
};

export const resolveShowComponentField = <Props extends DefaultComponentProps>(
  id: string,
  fields: Fields<Props>,
) => {
  const template = new URLSearchParams(window.location.search).get('template');
  const [componentName] = id.split('-');

  if (template && mandatoryComponents?.[template].includes(componentName)) {
    if (fields?.showComponent) {
      delete fields.showComponent;
    }
  }
  return fields;
};

export const getcurrentDeviceType = (windowWidth: number) => {
  const { pathname } = useLocation();
  const { appState } = usePuck();
  const width = pathname.includes(Path.PAGE_BUILDER_EDIT)
    ? appState?.ui?.viewports?.current?.width
    : windowWidth;

  if (width <= viewPorts.mobile) {
    return devices.mobile;
  } else if (width <= viewPorts.tablet) {
    return devices.tablet;
  } else {
    return devices.desktop;
  }
};
