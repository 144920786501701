export const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5001 8.75C11.9143 8.75 12.2501 9.08579 12.2501 9.5V13.75C12.2501 14.1642 11.9143 14.5 11.5001 14.5C11.0858 14.5 10.7501 14.1642 10.7501 13.75V9.5C10.7501 9.08579 11.0858 8.75 11.5001 8.75Z"
        fill="currentColor"
      />
      <path
        d="M9.25006 9.5C9.25006 9.08579 8.91427 8.75 8.50006 8.75C8.08585 8.75 7.75006 9.08579 7.75006 9.5V13.75C7.75006 14.1642 8.08585 14.5 8.50006 14.5C8.91427 14.5 9.25006 14.1642 9.25006 13.75V9.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 5.75C7.25 4.23122 8.48122 3 10 3C11.5188 3 12.75 4.23122 12.75 5.75H15.7501C16.1643 5.75 16.5001 6.08579 16.5001 6.5C16.5001 6.91421 16.1643 7.25 15.7501 7.25H15L14.9999 12.7001C14.9998 14.3802 14.9998 15.2203 14.6728 15.862C14.3852 16.4265 13.9262 16.8854 13.3618 17.173C12.72 17.5 11.88 17.5 10.1998 17.5H9.80005C8.11984 17.5 7.27974 17.5 6.63799 17.173C6.07349 16.8854 5.61455 16.4264 5.32693 15.8619C4.99995 15.2202 4.99997 14.3801 5 12.6999L5.00011 7.25H4.25006C3.83585 7.25 3.50006 6.91421 3.50006 6.5C3.50006 6.08579 3.83585 5.75 4.25006 5.75H7.25ZM8.75 5.75C8.75 5.05964 9.30964 4.5 10 4.5C10.6904 4.5 11.25 5.05964 11.25 5.75H8.75ZM6.50013 7.25H13.5L13.4998 12.7001C13.4998 13.5649 13.4986 14.1233 13.4639 14.5483C13.4306 14.9558 13.3741 15.1068 13.3363 15.181C13.1925 15.4632 12.963 15.6927 12.6808 15.8365C12.6066 15.8743 12.4555 15.9308 12.048 15.9641C11.623 15.9988 11.0646 16 10.1998 16H9.80005C8.93519 16 8.37677 15.9988 7.95175 15.9641C7.54424 15.9308 7.39316 15.8743 7.31899 15.8365C7.03674 15.6927 6.80727 15.4632 6.66346 15.181C6.62567 15.1068 6.56916 14.9557 6.53587 14.5482C6.50115 14.1232 6.5 13.5648 6.50002 12.6999L6.50013 7.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
