import { ButtonBaseProps } from '@mui/material';
import 'components/common/ButtonGroup/style.scss';
interface IButtonGropProps extends ButtonBaseProps {
  active: string;
  buttons: {
    name: string;
  }[];
  setActive: React.Dispatch<React.SetStateAction<string>>;
}
function ButtonGroup({ active, buttons, setActive }: IButtonGropProps) {
  return (
    <div className="button_group">
      {buttons.map((val, index) => {
        return (
          <button
            key={index}
            className={`custom_button ${val.name === active ? 'actives' : ''}`}
            onClick={() => setActive(val.name)}>
            {val.name}
          </button>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
