import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'App.scss';
import { persistor, store } from 'redux-setup/store';
import { Provider } from 'react-redux';
import AppRouter from 'router';
import { PersistGate } from 'redux-persist/integration/react';
import { Toaster } from 'shared-components';
import '@measured/puck/puck.css';
import { useState } from 'react';
import AppWrapper from 'components/AppWrapper';

function App() {
  const [theme, setTheme] = useState<boolean>(false);
  const darkTheme = createTheme({
    palette: {
      mode: theme ? 'light' : 'dark',
    },
    typography: {
      allVariants: {
        fontFamily:
          "work-sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
        textTransform: 'none',
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: theme ? '#FFFFFF' : '#141e2d',
          },
          tooltip: {
            color: theme ? '#333333' : '#a3aed0',
            backgroundColor: theme ? '#FFFFFF' : '#141e2d',
            padding: '10px',
            border: `1px solid ${theme ? '#f2f2f2' : '#b0bce133'}`,
            borderRadius: '7px',
          },
        },
      },
    },
  });
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={darkTheme}>
            {/* <Loader /> */}
            <AppWrapper>
              <AppRouter setTheme={setTheme} />
            </AppWrapper>
            <Toaster />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
