import React from 'react';
import { formatPrice } from '../../../../page-builder/utils/formatPrice';
import { ReduxSelectorProps } from '../../../services/constants';

export interface CartTotalProps extends ReduxSelectorProps {}

export const CartTotal: React.FC<CartTotalProps> = ({ useAppSelector }) => {
  const cartData = useAppSelector(state => state.cart.cartData);
  return (
    <>
      <div className="flex items-center font-semibold text-lg">
        {cartData?.LineItems === null || cartData?.LineItems?.length === 0
          ? formatPrice('0.0')
          : formatPrice(cartData?.TotalAmount)}
      </div>
    </>
  );
};
