/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
declare let fbq: any;
import { RawEvent } from '../../../interface/eventInterface';
import { Tracker } from '../trackers';

export class MetaTracker implements Tracker {
  private pixelIDs: string[];

  constructor(pixelIDs: string[]) {
    if (pixelIDs.length === 0) {
      throw new Error('MetaTracker requires at least one pixel ID.');
    }
    this.pixelIDs = pixelIDs;
  }

  Init(): void {
    this.initializePixel();
  }

  private initializePixel() {
    (function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
    );
    this.pixelIDs.forEach(pixelID => fbq('init', pixelID));
  }

  TrackEvent(_event: RawEvent): void {
    this.pixelIDs.forEach(pixelID => {
      const user = createMetaPixelUser({
        Email: _event?.cart?.Email || '',
        PhoneNumber: _event?.cart?.PhoneNumber || '',
        FirstName: _event?.cart?.ShippingAddress?.FirstName || '',
        LastName: _event?.cart?.ShippingAddress?.LastName || '',
        City: _event?.cart?.ShippingAddress?.City || '',
        ProvinceCode: _event?.cart?.ShippingAddress?.ProvinceCode || '',
        Zip: _event?.cart?.ShippingAddress?.Zip || '',
        CountryCode: _event?.cart?.ShippingAddress?.CountryCode || '',
      });
      fbq('init', pixelID, user);
      if (_event.eventName === 'Purchase') {
        fbq(
          'track',
          _event.eventName,
          { currency: 'USD', value: _event.cart?.TotalAmount },
          { eventID: _event.eventId },
        );
      } else {
        fbq('track', _event.eventName, null, { eventID: _event.eventId });
      }
    });
  }
}

interface MetaPixelUser {
  em?: string;
  ph?: string;
  fn?: string;
  ln?: string;
  ct?: string;
  st?: string;
  zp?: string;
  country?: string;
}

export const createMetaPixelUser = ({
  Email,
  PhoneNumber,
  FirstName,
  LastName,
  City,
  ProvinceCode,
  Zip,
  CountryCode,
}: {
  Email: string | null;
  PhoneNumber: string | null;
  FirstName: string | null;
  LastName: string | null;
  City: string | null;
  ProvinceCode: string | null;
  Zip: string | null;
  CountryCode: string | null;
}): MetaPixelUser => {
  const metaPixelUser: MetaPixelUser = {};

  if (Email) metaPixelUser.em = Email.toLowerCase();
  if (PhoneNumber) metaPixelUser.ph = PhoneNumber.replace(/\D/g, '');
  if (FirstName) metaPixelUser.fn = FirstName.toLowerCase();
  if (LastName) metaPixelUser.ln = LastName.toLowerCase();
  if (City) metaPixelUser.ct = City.toLowerCase().replace(/\s/g, '');
  if (ProvinceCode) metaPixelUser.st = ProvinceCode.toLowerCase();
  if (Zip) metaPixelUser.zp = Zip;
  if (CountryCode) metaPixelUser.country = CountryCode.toLowerCase();

  return metaPixelUser;
};
