import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import 'components/transactions/transactionNestedTable/style.scss';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'components/common/loader';
import { IInvitation } from 'interface/userInterface';
import globalUserService from 'services/userService';
import { IGetStorePermission } from 'interface/settingsInterface';
interface IProps {
  row: IInvitation;
}

const NestedTableComponent: React.FC<IProps> = ({ row }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<IGetStorePermission[]>([]);

  const getUserPermissions = async () => {
    setLoading(true);
    const response = await globalUserService.getUsersPermissionByID(row.ID);
    if (response?.status === 200 && response?.data?.Result?.length > 0) {
      setPermissions(response?.data?.Result);
    } else {
      setPermissions([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserPermissions();
  }, []);

  return (
    <div className="nestedRow">
      <Box sx={{ margin: 1 }}>
        <div className="transaction_container">
          <div className="transaction_top_container">
            {permissions?.length > 0 ? (
              <TableContainer className="transactions_table_container">
                <Table
                  className="transactions_table"
                  aria-labelledby="tableTitle"
                  stickyHeader>
                  <TableHead className="table_header">
                    <TableRow>
                      <TableCell className="table_header_cell">
                        Store Name
                      </TableCell>
                      <TableCell className="table_header_cell">
                        Permissions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body">
                    {permissions?.map(permision => (
                      <TableRow className="table_row" key={'date'}>
                        <TableCell className="table_cell">
                          {permision?.Name}
                        </TableCell>
                        <TableCell className="table_cell">
                          <div className="contant">
                            {permision.Permissions?.map((permission, index) => {
                              return (
                                <div className="value permission" key={index}>
                                  <p>{permission}</p>
                                </div>
                              );
                            })}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="flex-container">No data</div>
            )}
          </div>
        </div>
        <Spinner loading={loading} />
      </Box>
    </div>
  );
};

export default NestedTableComponent;
