import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  IConversionTracking,
  IConversionTrackingDelete,
} from 'interface/conversionTracking';
import {
  commonError,
  createConversionTracking,
  createTracking,
  deleteConversionTracking,
  deleteTracking,
  getConversionTrackingEP,
  getTrackingEP,
  updateConversionTracking,
  updateTracking,
} from 'services/constants';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import { ICreateTrackingBody } from 'components/store/storeDetails/shopify/shopifyDetails/trackingSetting';

class conversionMockService {
  getConversionTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createConversionTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateConversionTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  deleteConversionTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  // Fake API for getTracking
  getTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  // Fake API for Create Tracking
  createTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  // Fake API for Update Tracking
  updateTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  // Fake API for Delete Tracking
  deleteTracking = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class conversionService {
  storesMockService = new conversionMockService();

  getConversionTracking = async (
    channelID: string,
  ): Promise<
    ISuccessResponse<IConversionTracking> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<IConversionTracking>(
        getConversionTrackingEP(channelID),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, false);
    }
  };

  createConversionTracking = async (
    conversionTrackingDetails: IConversionTracking,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTracking>(
        createConversionTracking,
        conversionTrackingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateConversionTracking = async (
    conversionTrackingUpdate: IConversionTracking,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTracking>(
        updateConversionTracking,
        conversionTrackingUpdate,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  deleteConversionTracking = async (
    conversionTrackingDelete: IConversionTrackingDelete,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTrackingDelete>(
        deleteConversionTracking,
        conversionTrackingDelete,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getTracking = async (
    channelID: string,
  ): Promise<
    ISuccessResponse<IConversionTracking> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<IConversionTracking>(
        getTrackingEP(channelID),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, false);
    }
  };

  createTracking = async (
    conversionTrackingDetails: ICreateTrackingBody,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTracking>(
        createTracking,
        conversionTrackingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateTracking = async (
    conversionTrackingUpdate: ICreateTrackingBody,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTracking>(
        updateTracking,
        conversionTrackingUpdate,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  deleteTracking = async (
    channelId: string,
    trackingId: string,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IConversionTrackingDelete>(deleteTracking, {
        ChannelID: channelId,
        ID: trackingId,
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const conversionTrackingService: conversionService | conversionMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new conversionMockService()
    : new conversionService();

export default conversionTrackingService;
