import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'components/common/link/style.scss';

interface MyLinkProps {
  to: string;
  children: React.ReactNode;
}

const Link: React.FC<MyLinkProps> = ({ to, children }) => {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.ctrlKey || event.metaKey) {
      // If CTRL key is pressed, open link in new tab
      window.open(to, '_blank');
    } else {
      // Prevent default link behavior
      event.preventDefault();
      // Navigate programmatically
      navigate(to);
    }
  };

  return (
    <a className="text-as-link" href={to} onClick={handleClick}>
      {children}
    </a>
  );
};

export default Link;
