import React, { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import './style.scss';
import { IPostUpsellResponse } from 'interface/upSellInterface';

import ProductsSearch from '../productsSearch';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
const discountTypes = [
  {
    label: 'Percentage',
    value: 'percent',
  },
  {
    label: 'Fixed Amount',
    value: 'fixed',
  },
];
interface IProps {
  selectedProduct: IPostUpsellResponse;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  action: (_: IPostUpsellResponse) => void;
  setDefaultProduct: () => void;
  shopId: string;
  storeId: string;
  addedPostUpsells: IPostUpsellResponse[];
}

const AddPostUpsellsForm: FC<IProps> = ({
  selectedProduct,
  handleClose,
  action,
  setDefaultProduct,
  storeId,
  shopId,
  addedPostUpsells,
}) => {
  const [loading, setLoading] = useState(false);
  const [maxAmount, setMaxAmount] = useState<string | null>(null);
  const { control, handleSubmit, watch } = useForm<IPostUpsellResponse>({
    defaultValues: selectedProduct,
  });

  const onSubmit = async (data: IPostUpsellResponse) => {
    setLoading(true);
    action(data);
    setLoading(false);
    handleClose(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={() => handleClose(false)}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            !selectedProduct?.ID && setDefaultProduct();
            handleClose(false);
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: selectedProduct?.ID ? 'Update' : 'Add',
          form: 'AddPostUpsellsForm',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      heading={selectedProduct?.ID ? 'Update Post Upsells' : 'Add Post Upsells'}
      className="flex-container">
      <div className="popup-section">
        <form
          id="AddPostUpsellsForm"
          className="grid gap-3"
          onSubmit={handleSubmit(onSubmit)}>
          {/* future use */}
          {/* <Controller
            name="Status"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                value={value}
                onChange={value => onChange(value)}
                label="Status"
                className="filter_form_input"
                errorString={error?.message}
                fixedSize="md"
                placeholder="Select status"
              />
            )}
          /> */}
          <Controller
            name="ProductID"
            control={control}
            rules={{ required: `This field is required` }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <ProductsSearch
                  errorMessages={error?.message}
                  onChange={val => {
                    onChange(val?.value || null);
                    setMaxAmount(val?.amount || null);
                  }}
                  shopId={shopId}
                  storeId={storeId}
                  value={value}
                  addedPostUpsells={addedPostUpsells}
                />
              );
            }}
          />

          <Controller
            name="Type"
            control={control}
            rules={{ required: `This field is required` }}
            render={({
              field: { value, ref, onChange },
              fieldState: { error },
            }) => {
              return (
                <AutoCompleteSelect
                  options={discountTypes}
                  labelKey="label"
                  onChange={val => onChange(val?.value || null)}
                  ref={ref}
                  value={
                    discountTypes?.find(type => type.value === value) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                  label="Discount Type"
                />
              );
            }}
          />

          <Controller
            name="Amount"
            control={control}
            rules={{
              required: 'This field is required',
              ...(watch('Type') === discountTypes?.[0].value && {
                max: {
                  value: 100,
                  message: 'Maximum value is 100',
                },
              }),
              ...(watch('Type') === discountTypes?.[1].value && {
                min: {
                  value: 1,
                  message: 'Minimum value is 1',
                },
                ...(watch('Type') === discountTypes?.[1].value && {
                  max: {
                    value: Number(maxAmount),
                    message: `Maximum value should be less than product price ${maxAmount}`,
                  },
                }),
              }),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div
                className={`flex gap-3 w-full ${error?.message ? 'items-center' : 'items-end'}`}>
                <TextField
                  value={value}
                  onChange={e => {
                    onChange(e.target.value.replace(/[^0-9]/g, ''));
                  }}
                  label={
                    watch('Type') === discountTypes?.[0].value
                      ? 'Discount Percentage'
                      : 'Discount Amount'
                  }
                  className="filter_form_input w-full"
                  errorString={error?.message}
                  fixedSize="md"
                  placeholder={
                    watch('Type') === discountTypes?.[0].value
                      ? 'Enter Discount Percentage'
                      : 'Enter Discount Amount'
                  }
                />
                {watch('Type') === discountTypes?.[1].value && (
                  <p>{`${maxAmount ? '/' + maxAmount : ''} `}</p>
                )}
              </div>
            )}
          />
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddPostUpsellsForm;
