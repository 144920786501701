import {
  Chart,
  ChartType,
  TooltipModel,
  TooltipCallbacks,
  LineControllerDatasetOptions,
  ChartDatasetProperties,
  ScatterDataPoint,
} from 'chart.js';
import formatNumber from 'utils/helper';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import { revenueTypes } from 'components/risk/disputeOverTime';

interface TooltipProps {
  chart: Chart;
  tooltip: TooltipModel<ChartType>;
}

type CustomBarDataset = _DeepPartialObject<
  {
    type: 'line';
    amount: (number | [number, number] | null)[];
  } & LineControllerDatasetOptions
> &
  ChartDatasetProperties<'line', (ScatterDataPoint | number | null)[]>;

const tooltip = ({
  prefix = '',
  suffix = '',
  hasPercentageValue = false,
  isPercentagedata = false,
  isLabelRequired = false,
  customPrefixSuffix,
  disputeOverTime = '',
}: {
  prefix?: string;
  suffix?: string;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  isLabelRequired?: boolean;
  disputeOverTime?: string;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
} = {}) => {
  return {
    intersect: false,
    padding: 8,
    callbacks: {
      title: () => '',
      label: context => {
        const dataSets = context?.chart?.config?.data?.datasets;
        if (disputeOverTime && dataSets?.length > 0) {
          const label = context?.dataset?.label;
          const currentDataset: CustomBarDataset = context?.dataset;
          if (disputeOverTime === revenueTypes.ratio) {
            const value =
              (currentDataset?.amount
                ? currentDataset?.amount?.[context?.dataIndex]
                : currentDataset?.data?.[context?.dataIndex]) || 0;
            return `${label + ' : ' + prefix + value + ' / ' + context?.raw + '%'}`;
          }
          return `${context?.dataset?.label + ' : ' + prefix + currentDataset?.data?.[context?.dataIndex] + ' / ' + (currentDataset?.amount ? currentDataset?.amount?.[context?.dataIndex] : 0) + '%'}`;
        }

        if (hasPercentageValue && dataSets?.length > 0) {
          let percentage: string = '0';

          if (dataSets.length > 0) {
            const data = dataSets.reduce((value, eachValue) => {
              const eachData = eachValue?.data?.[context?.dataIndex] || 0;
              if (typeof eachData === 'number') {
                return value + eachData;
              }
              return value;
            }, 0);
            percentage =
              (+context.formattedValue / +data) * 100
                ? Number((+context.formattedValue / +data) * 100).toFixed(2)
                : '0';
          }

          return `${context.dataset.label + ': ' + prefix + context.formattedValue + suffix + ' / ' + percentage + '%'}`;
        }

        if (isPercentagedata && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const currentDataset: CustomBarDataset = context.dataset;
          const value =
            (currentDataset?.amount
              ? currentDataset?.amount[context?.dataIndex]
              : currentDataset?.data[context?.dataIndex]) || 0;
          return `${label + ' : ' + prefix + value + '/' + context.raw + '%'}`;
        }

        if (customPrefixSuffix && dataSets?.length > 0) {
          const label = context.dataset.label || '';
          const value = context.dataset.data[context?.dataIndex] || 0;
          let pre = '';
          let suf = '';
          if (customPrefixSuffix?.[label]) {
            pre = customPrefixSuffix?.[label].prefix ?? '';
            suf = customPrefixSuffix?.[label].suffix ?? '';
          }
          return `${isLabelRequired ? `${label}: ` : ''}${pre + value.toLocaleString() + suf}`;
        }

        const label = context.dataset.label || '';
        const value = context.parsed.y || 0;
        const labelText = `${isLabelRequired ? `${label}: ` : ''}${prefix + formatNumber(value) + suffix}`;
        return labelText;
      },
      beforeTitle: () => '',
      afterTitle: () => '',
      beforeBody: () => '',
      afterBody: () => '',
      beforeLabel: () => '',
      afterLabel: () => '',
      labelTextColor: () => '',
      beforeFooter: () => '',
      footer: () => '',
      afterFooter: () => '',
      labelColor: () => {},
      labelPointStyle: () => {},
    } as TooltipCallbacks<'line'>,
    displayColors: false,
    bodyColor: '#A3AED0',
    bodyFont: {
      size: 14,
      family: 'work-sans',
      weight: 500,
    },
    bodySpacing: 8,
    boxPadding: 4,
    backgroundColor: '#141E2D',
    borderColor: '#192739',
    borderWidth: 2,
    enabled: false,
  };
};

const externalTooltipHandler = (context: TooltipProps, type: string) => {
  const { chart, tooltip } = context;
  let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('tooltip-container');
    const tooltipElement = document.createElement('div');
    tooltipElement.classList.add('tooltip-wrapper');
    tooltipEl.appendChild(tooltipElement);
    chart?.canvas?.parentNode?.appendChild(tooltipEl);
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip?.body) {
    const isTooltipBelow = tooltip.caretY > chart.height / 2;
    if (tooltip.x < 80) {
      tooltipEl.className = `tooltip-container pointer-left`;
      tooltipEl.style.transform = `translate(20px, -50%)`;
    } else if (chart.width - tooltip.x - tooltip.width < 80) {
      tooltipEl.className = `tooltip-container pointer-right`;
      tooltipEl.style.transform = `translate(calc(-100% - 20px), -50%)`;
    } else {
      tooltipEl.className = `tooltip-container ${type && 'lineCharts'} ${isTooltipBelow && 'pointer-bottom'}`;
      tooltipEl.style.transform = `translate(-50%, ${isTooltipBelow ? 'calc(-100% - 20px)' : '18px'})`;
    }

    const tableBody = document.createElement('div');
    tooltip.body.forEach((body: { lines: string[] }, index: number) => {
      const tooltipRow = document.createElement('div');
      tooltipRow.classList.add('tooltip-row');
      if (tooltip.labelColors[index].backgroundColor) {
        tooltipRow.style.color = `${tooltip.labelColors[index].backgroundColor}`;
      }
      const tooltipData = body?.lines?.[0].split(':');
      tooltipRow.innerHTML = `${tooltipData?.[0] ? `<span>${tooltipData?.[0]}</span>` : ''}
      ${
        tooltipData?.[1]
          ? `<span class='tooltip-data ${tooltipData?.[0] === 'Net Subscribers' && 'active'}'>${tooltipData?.[1]}</span>`
          : ''
      }`;
      tableBody.appendChild(tooltipRow);
    });

    const tableRoot = tooltipEl?.querySelector('.tooltip-wrapper');
    while (tableRoot?.firstChild) {
      tableRoot?.firstChild.remove();
    }
    tableRoot?.appendChild(tableBody);
  }
  const { offsetLeft, offsetTop } = chart.canvas;
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = offsetLeft + tooltip.caretX + 'px';
  tooltipEl.style.top = offsetTop + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

const lineChartTooltip = {
  mode: 'index' as const,
  position: 'nearest' as const,
  intersect: false,
  padding: 8,
  callbacks: {
    title: () => '',
  },
  bodyColor: '#A3AED0',
  displayColors: false,
  bodyFont: {
    size: 14,
    family: 'work-sans',
    weight: 500,
  },
  bodySpacing: 8,
  boxPadding: 4,
  backgroundColor: '#141E2D',
  borderColor: '#192739',
  borderWidth: 2,
  enabled: false,
};

export const filledLineChartoptions = {
  interaction: {
    mode: 'nearest' as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  fill: true,
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        autoSkipPadding: 12,
        maxRotation: 0,
        minRotation: 0,
        align: 'inner' as const,
        fontSize: 14,
        color: '#A3AED0',
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value: number | string) => {
          return formatNumber(Number(value));
        },
        steps: 6,
        align: 'center' as const,
        padding: 8,
        fontSize: 14,
        color: '#A3AED0',
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        color: '#a3aed026',
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.5,
      borderWidth: 6,
      borderCapStyle: 'round' as const,
    },
    point: {
      radius: 0,
      hoverRadius: 8,
      hoverBorderWidth: 4,
      borderColor: 'white',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      ...lineChartTooltip,
      external: (e: TooltipProps) => {
        externalTooltipHandler(e, 'filledLine');
      },
    },
  },
};

export const lineChartoptions = {
  interaction: {
    mode: 'nearest' as const,
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        autoSkipPadding: 12,
        maxRotation: 0,
        minRotation: 0,
        align: 'inner' as const,
        fontSize: 14,
        color: '#A3AED0',
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value: number | string) => {
          return formatNumber(Number(value));
        },
        align: 'center' as const,
        padding: 8,
        fontSize: 14,
        color: '#A3AED0',
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        color: '#a3aed026',
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.5,
      borderWidth: 4,
      borderCapStyle: 'round' as const,
    },
    point: {
      radius: 0,
      hoverRadius: 8,
      hoverBorderWidth: 4,
      hoverBackgroundColor: 'white',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      ...lineChartTooltip,
      ...tooltip(),
      mode: 'index' as const,
      external: (e: TooltipProps) => {
        externalTooltipHandler(e, 'LineChart');
      },
    },
  },
};

export const cardLineChartoptions = {
  interaction: {
    mode: 'nearest' as const,
    intersect: false,
  },
  maintainAspectRatio: false,
  responsive: true,
  fill: true,
  scales: {
    x: {
      display: false,
      ticks: {
        autoSkip: true,
        align: 'inner' as const,
        color: '#A3AED0',
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      display: false,
      beginAtZero: true,
      ticks: {
        callback: (value: number | string) => {
          return formatNumber(Number(value));
        },
        align: 'center' as const,
        color: '#A3AED0',
        display: false,
        font: {
          family: 'work-sans',
        },
      },
      grid: {
        color: '#a3aed026',
        display: false,
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.5,
      borderWidth: 1.4,
      borderCapStyle: 'round' as const,
    },
    point: {
      radius: 0,
      hoverRadius: 1,
      hoverBorderWidth: 1,
      borderColor: 'white',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: lineChartTooltip,
  },
};

export const groupedLineChartoptions = ({
  prefix,
  suffix,
  maxYLabel,
  labelPrefix,
  labelSuffix,
  isLabelRequired,
  hasPercentageValue,
  isPercentagedata,
  customPrefixSuffix,
  disputeOverTime,
}: {
  prefix: string;
  maxYLabel?: number;
  suffix: string;
  disputeOverTime?: string;
  labelPrefix: string;
  labelSuffix: string;
  isLabelRequired?: boolean;
  hasPercentageValue: boolean;
  isPercentagedata: boolean;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
}) => {
  return {
    interaction: {
      mode: 'nearest' as const,
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 12,
          maxRotation: 0,
          minRotation: 0,
          align: 'inner' as const,
          fontSize: 14,
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ...(maxYLabel ? { suggestedMax: maxYLabel } : {}),
        ticks: {
          callback: (value: number | string) => {
            return `${labelPrefix + formatNumber(Number(value)) + labelSuffix}`;
          },
          align: 'center' as const,
          padding: 8,
          fontSize: 14,
          ...(maxYLabel ? { autoSkip: false } : {}),
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#a3aed026',
          drawTicks: false,
        },
        border: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.5,
        borderWidth: 4,
        borderCapStyle: 'round' as const,
      },
      point: {
        radius: 0,
        hoverRadius: 8,
        hoverBorderWidth: 4,
        hoverBackgroundColor: 'white',
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        ...tooltip({
          prefix,
          suffix,
          hasPercentageValue,
          isPercentagedata,
          customPrefixSuffix,
          disputeOverTime,
          isLabelRequired,
        }),
        mode: 'index' as const,
        position: 'nearest' as const,
        external: (e: TooltipProps) => {
          externalTooltipHandler(e, isLabelRequired ? 'LineChart' : '');
        },
      },
    },
  };
};
