import React from 'react';
import { RGBColor, SketchPicker } from 'react-color';
import CustomTooltip from '../../../components/common/tooltip';
import { FieldLabel } from '@measured/puck';

type ThemeColorProps = {
  value: RGBColor;
  onChange: (_value: RGBColor) => void;
};

const ThemeColor: React.FC<ThemeColorProps> = ({ value, onChange }) => {
  const handleChange = async (color: RGBColor) => {
    onChange(color);
    const iframe = document.getElementById(
      'preview-frame',
    ) as HTMLIFrameElement;

    if (iframe) {
      const iframeDocument = iframe.contentDocument as Document;
      const iframeBody = iframeDocument.body;

      if (iframeBody) {
        iframeBody.style.setProperty(
          '--color-page-builder-theme',
          `${color?.r} ${color?.g} ${color?.b}`,
        );
      }
    }
  };
  return (
    <FieldLabel
      label={
        (
          <CustomTooltip title={'Theme Color'}>
            <span>Theme Color</span>
          </CustomTooltip>
        ) as React.ReactNode as string
      }>
      <SketchPicker
        color={value}
        onChange={(color: { rgb: RGBColor }) => handleChange(color?.rgb)}
      />
    </FieldLabel>
  );
};

export default ThemeColor;
