import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { Loader } from 'components/common/loader';
import { HeadCell, Order } from 'interface/commonInterface';
import {
  ISubscriptionSalvage,
  ISalvageFormFilter,
  ISearchSalvageBody,
  ISubscriptionSalvageDetails,
  ISalvageStatics,
} from 'interface/subscriptionSalvage';
import { useState, useEffect } from 'react';
import { get } from 'react-hook-form';
import 'components/subcriptionSalvage/style.scss';
import { ReactComponent as QuestionSymbol } from 'assets/icons/question.svg';
// For future use
// import { ReactComponent as StreamUp } from 'assets/icons/stream-up.svg';
// import { ReactComponent as StreamDown } from 'assets/icons/stream-down.svg';
import { GroupedLineChart } from 'components/common/chart/lineChart';
import { OverlapedBarChart } from 'components/common/chart/barChart';
import { ChartOptions, ScriptableContext } from 'chart.js';
import SideFilter from 'components/subcriptionSalvage/salvageFilter/sideFilter';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import globalTransactionService from 'services/transactionService';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import formatNumber from 'utils/helper';
import { allDateRange } from 'components/dashboard/constant';
dayjs.extend(utc);

export const defaultOrderFilterValues: ISalvageFormFilter = {
  store_filter: {
    store_ids: [],
  },
  dateFilterBy: 'Acquired',
  dateRange: allDateRange.allTime,
  startDate: null,
  endDate: null,
  externalProcessorID: '',
};

const salvageStatics = {
  averageRevenueByStep: 0,
  totalRevenueByStep: 0,
  averageApprovalByStep: [],
  averageApprovalByTime: [],
  averageRevenueByTime: 0,
  totalRevenueByTime: 0,
  revenueListByTime: [],
  everyDate: [],
};

const SubscriptionSalvage = () => {
  const dispatch = useAppDispatch();
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const storeIds = useAppSelector(state => state.storeIds.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const headCells: readonly HeadCell<ISubscriptionSalvage>[] = [
    {
      id: 'salvage-step',
      label: 'Salvage Step',
      cellRender: row => {
        return (
          <div className="center-align">
            {row?.salvageStep}
            <Tooltip placement="top" title={row?.tooltip} arrow>
              <QuestionSymbol />
            </Tooltip>
          </div>
        );
      },
      hide: false,
    },
    {
      id: 'attempted',
      label: 'Attempted',
      cellRender: row => {
        return <p>{row?.attempts}</p>;
      },
      hide: false,
    },
    {
      id: 'approved',
      label: 'Approved',
      cellRender: row => {
        return <p>{row?.approved}</p>;
      },
      hide: false,
    },
    {
      id: 'declined',
      label: 'Declined',
      cellRender: row => {
        return <p>{row?.declined}</p>;
      },
      hide: false,
    },
    {
      id: 'revenue',
      label: 'Revenue',
      cellRender: row => {
        return <p>${row?.revenue.toLocaleString()}</p>;
      },
      hide: false,
    },
    {
      id: 'revenue-per-attempt',
      label: 'Revenue per Attempt',
      cellRender: row => {
        return <p>${row?.revenuePerAttempt.toLocaleString()}</p>;
      },
      hide: false,
    },
  ];

  const [headCellList] = useState<HeadCell<ISubscriptionSalvage>[]>([
    ...headCells,
  ]);
  const [order] = useState<Order>('asc');
  const [orderBy] = useState<keyof ISubscriptionSalvage>();
  const [salvageList, setSalvageList] = useState<ISubscriptionSalvageDetails[]>(
    [],
  );
  const [subscriptionSalvage, setSubscriptionSalvage] = useState<
    ISubscriptionSalvage[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [statics, setstatics] = useState<ISalvageStatics>(salvageStatics);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideFormFilter, setSideFormFilter] = useState<ISalvageFormFilter>(
    () => {
      const filterDetailsString = searchParams.get('filter-details');
      const filterDetails = {
        store_filter: {
          store_ids: defaultOrderFilterValues.store_filter.store_ids,
        },
        dateFilterBy: defaultOrderFilterValues.dateFilterBy,
        dateRange: defaultOrderFilterValues.dateRange,
        startDate: defaultOrderFilterValues.startDate,
        endDate: defaultOrderFilterValues.endDate,
        externalProcessorID: defaultOrderFilterValues.externalProcessorID,
      };
      if (typeof filterDetailsString === 'string') {
        const {
          store_filter: { store_ids },
          dateRange,
          startDate,
          endDate,
          dateFilterBy,
        } = JSON.parse(filterDetailsString);
        store_ids && (filterDetails.store_filter.store_ids = store_ids);
        dateRange && (filterDetails.dateRange = dateRange);
        startDate && (filterDetails.startDate = dayjs(startDate));
        endDate && (filterDetails.endDate = dayjs(endDate));
        dateFilterBy && (filterDetails.dateFilterBy = dateFilterBy);
      }
      return filterDetails;
    },
  );

  const lineChartData = {
    chart: {
      labels: statics.everyDate,
      datasets: [
        {
          label: 'Approval Ratio',
          borderColor: '#F90182',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 10, 0, 150);
            gradient.addColorStop(0, '#F90182');
            gradient.addColorStop(1, '#141E2D17');
            return gradient;
          },
          legend: 'Approval Ratio',
          yAxisID: 'approvalRatio',
          data: statics.averageApprovalByTime,
        },
        {
          label: 'Revenue',
          yAxisID: 'Subscription',
          borderColor: '#6AD2FF',
          backgroundColor: (context: ScriptableContext<'line'>) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 10, 0, 140);
            gradient.addColorStop(0, '#6AD2FF');
            gradient.addColorStop(1, '#141E2D17');
            return gradient;
          },
          legend: 'Revenue',
          data: statics.revenueListByTime,
        },
      ],
      scales: {
        Subscription: {
          position: 'right',
        },
      },
    },
  };

  const barChartData = {
    labels: [
      ...subscriptionSalvage.map(
        eachSalvage => `Step ${eachSalvage.salvageStep}`,
      ),
    ],
    datasets: [
      {
        label: 'Approval Ratio',
        borderColor: '#F90182',
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 140);
          gradient.addColorStop(0, '#F90182');
          gradient.addColorStop(1, '#141E2D');
          return gradient;
        },
        legend: 'Approval Ratio',
        yAxisID: 'approvalRatio',
        data: statics.averageApprovalByStep,
      },
      {
        label: 'Revenue',
        yAxisID: 'Subscription',
        borderColor: '#6AD2FF',
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 140);
          gradient.addColorStop(0, '#6AD2FF');
          gradient.addColorStop(1, '#141E2D');
          return gradient;
        },
        legend: 'Revenue',
        data: subscriptionSalvage.map(eachSalvage => eachSalvage.revenue),
      },
    ],
    scales: {
      Subscription: {
        position: 'right',
      },
    },
  };

  const options = {
    scales: {
      Subscription: {
        position: 'right',
        ticks: {
          callback: (value: number | string) => {
            return formatNumber(Number(value));
          },
          align: 'center' as const,
          font: {
            family: 'work-sans',
          },
          color: '#A3AED0',
        },
        grid: {
          display: false,
        },
      },
      approvalRatio: {
        suggestedMax: 100,
        ticks: {
          callback: (value: number | string) => {
            return `${value}%`;
          },
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          color: '#303b4d',
        },
      },
      x: {
        ticks: {
          color: '#A3AED0',
          font: {
            family: 'work-sans',
          },
        },
        grid: {
          display: false,
        },
      },
    },
    fill: true,
    datasets: {},
  };

  useEffect(() => {
    filterSubmission(sideFormFilter);
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, [storeIds, timeZone]);

  useEffect(() => {
    if (salvageList?.length) {
      getTabelData();
      getLineChartData();
    } else {
      setSubscriptionSalvage([]);
      setstatics(salvageStatics);
    }
  }, [salvageList]);

  const getTabelData = () => {
    const cycleDetails = salvageList.reduce(
      (result, obj) => {
        const { SalvageAttempt } = obj;
        if (!result[SalvageAttempt]) {
          result[SalvageAttempt] = [];
        }
        result[SalvageAttempt].push(obj);
        return result;
      },
      {} as Record<number, ISubscriptionSalvageDetails[]>,
    );
    if (Object.keys(cycleDetails)?.length) {
      let totalRevenue = 0;
      const averageApprovalByStep: number[] = [];
      let totalAttempted = 0;
      let totalApproved = 0;
      const tableData: ISubscriptionSalvage[] = Object.keys(cycleDetails).map(
        salvageStep => {
          let revenue = 0;
          let attempts = 0;
          let failedAttempt = 0;
          let approvedAttempt = 0;
          cycleDetails[+salvageStep].forEach(eachTranction => {
            attempts += eachTranction.Total;
            approvedAttempt += eachTranction.TotalApproved;
            failedAttempt += eachTranction.TotalDeclined;
            if (eachTranction.TotalApproved) {
              revenue += eachTranction.TotalAmount;
            }
          });
          totalRevenue += revenue;
          totalApproved += approvedAttempt;
          totalAttempted += attempts;
          const approvedAverage = +((approvedAttempt / attempts) * 100).toFixed(
            2,
          );
          averageApprovalByStep.push(approvedAverage);
          return {
            salvageStep,
            attempts,
            revenue,
            approved: `${approvedAttempt} of ${attempts} (${approvedAverage}%)`,
            declined: `${failedAttempt} of ${attempts} (${(100 - approvedAverage).toFixed(2)}%)`,
            revenuePerAttempt: +(revenue / attempts).toFixed(2),
            tooltip: getTooltip(+salvageStep),
          };
        },
      );
      setSubscriptionSalvage(tableData);
      setstatics(pre => ({
        ...pre,
        averageApprovalByStep,
        averageRevenueByStep: Math.ceil((totalApproved / totalAttempted) * 100),
        totalRevenueByStep: totalRevenue,
      }));
    } else {
      setSubscriptionSalvage([]);
      setstatics(salvageStatics);
    }
  };

  const getTooltip = (salvageStep: number) => {
    let content: string = '';
    switch (salvageStep) {
      case 0:
      case 1:
        content = '(Original transaction declines)';
        break;
      case 2:
        content = '3 days later. re-attempt';
        break;
      case 3:
        content = '14 days later, re-attempt';
        break;
      default:
        content = '30 days later, re-attempt';
    }
    return content;
  };

  const getLineChartData = () => {
    const sortedData = salvageList?.sort(
      (a, b) => new Date(a?.Date).getTime() - new Date(b?.Date).getTime(),
    );
    const salvageDetailsByDate = sortedData?.reduce(
      (result, obj) => {
        const { Date } = obj;
        const eachDate = dayjs(Date).tz().format('MM/DD/YYYY');
        if (!result[eachDate]) {
          result[eachDate] = [];
        }
        result[eachDate].push(obj);
        return result;
      },
      {} as Record<string, ISubscriptionSalvageDetails[]>,
    );
    if (Object.keys(salvageDetailsByDate)?.length) {
      let totalRevenue = 0;
      const averageApprovalByTime: number[] = [];
      const everyDate: string[] = [];
      let totalAttempted = 0;
      let totalApproved = 0;
      const revenueListByTime: number[] = Object.keys(salvageDetailsByDate).map(
        salvageStep => {
          let revenue = 0;
          let attempts = 0;
          let approvedAttempt = 0;
          salvageDetailsByDate[salvageStep].forEach(eachTranction => {
            attempts += eachTranction.Total;
            approvedAttempt += eachTranction.TotalApproved;
            if (eachTranction.TotalApproved) {
              revenue += eachTranction.TotalAmount;
            }
          });
          totalRevenue += revenue;
          const approvedAverage = +((approvedAttempt / attempts) * 100).toFixed(
            2,
          );
          totalAttempted += attempts;
          totalApproved += approvedAttempt;
          everyDate.push(dayjs(salvageStep).format('DD MMM'));
          averageApprovalByTime.push(approvedAverage);
          return revenue;
        },
      );
      setstatics(pre => ({
        ...pre,
        averageApprovalByTime,
        revenueListByTime,
        everyDate,
        averageRevenueByTime: Math.ceil((totalApproved / totalAttempted) * 100),
        totalRevenueByTime: totalRevenue,
      }));
    } else {
      setstatics(salvageStatics);
    }
  };

  const filterSubmission = async (formData: ISalvageFormFilter) => {
    setLoading(true);
    setSideFormFilter(formData);
    setSearchParams(
      {
        'filter-details': JSON.stringify(formData),
      },
      { replace: true },
    );

    const payload: ISearchSalvageBody = {};
    if (storeIds?.length) payload.StoreIDs = storeIds.map(store => store.ID);
    if (storefrontIds?.length) payload.StorefrontIDs = storefrontIds;
    if (formData?.externalProcessorID) {
      payload.ExternalProcessorID = formData.externalProcessorID;
    }
    if (formData.dateFilterBy === 'Acquired') {
      if (formData.startDate) {
        payload.AcquisitionStartTime = formData.startDate
          .tz()
          .startOf('day')
          .format();
      }
      if (formData.endDate) {
        payload.AcquisitionEndTime = formData.endDate
          .tz()
          .add(1, 'day')
          .startOf('day')
          .format();
      }
    } else {
      if (formData.startDate) {
        payload.StartTime = formData.startDate.tz().startOf('day').format();
      }
      if (formData.endDate) {
        payload.EndTime = formData.endDate
          .tz()
          .add(1, 'day')
          .startOf('day')
          .format();
      }
    }
    const response = await globalTransactionService.getSalvageSubscription({
      ...payload,
    });
    if (response?.status === 200) {
      if (response?.data?.Result?.length > 0) {
        setSalvageList(response?.data?.Result);
      } else {
        setSalvageList([]);
      }
    } else {
      setSalvageList([]);
    }
    setLoading(false);
  };

  return (
    <div className="salvage_wrapper">
      <div className="subscription_salvage">
        <div className="subscription_salvage_container">
          <div className="subscription_salvage_top_container">
            <TableContainer className="table-bordered table-center-align Common_Table">
              <Table className="subscription_salvage_table">
                <TableHead className="table_header">
                  <TableRow>
                    {headCellList?.map(headCell => {
                      if (headCell.hide) {
                        return null;
                      }
                      return (
                        <TableCell
                          className="table_header_cell header_text"
                          key={headCell.label}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }>
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {subscriptionSalvage.length > 0 ? (
                    subscriptionSalvage.map(row => {
                      return (
                        <TableRow
                          hover
                          className="table_row"
                          role="checkbox"
                          tabIndex={-1}
                          key={row?.salvageStep}>
                          {headCells.map(headCell => {
                            return (
                              <TableCell
                                className="table_cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                {headCell?.cellRender
                                  ? headCell.cellRender(row)
                                  : get(row, headCell.id)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <div className="no-data-row">No data found</div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="lg_card_container ">
          <div className="header">
            <p className="header_text">Approval Ratio & Revenue</p>
          </div>
          <div className="horizontal_legends !gap-5">
            {barChartData.datasets.map(data => {
              return (
                <div key={data.legend}>
                  <div className="legend">
                    <span
                      className="indicator"
                      style={{ background: data.borderColor }}
                    />
                    {data.legend}
                  </div>
                  <div>
                    <div className="each-ratio-data">
                      {data.legend === 'Approval Ratio'
                        ? `${statics.averageRevenueByStep}%`
                        : '$' + statics.totalRevenueByStep.toLocaleString()}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="grid 2xl:grid-cols-2 gap-4 approval-stats">
            <div className="w-full p-4 border rounded-xl grid gap-3">
              <p className="header_text font-medium">Approval by Step</p>
              <div className="subscription_chart">
                <OverlapedBarChart
                  data={barChartData}
                  options={options as ChartOptions<'bar'>}
                  customPrefixSuffix={{
                    'Approval Ratio': { suffix: '%' },
                    Revenue: { prefix: '$' },
                  }}
                  className="chart"
                />
              </div>
            </div>
            <div className="w-full p-4 border rounded-xl grid gap-3">
              <p className="header_text font-medium">Approval by Over Time</p>
              <div className="subscription_chart">
                <GroupedLineChart
                  data={lineChartData.chart}
                  options={options as ChartOptions<'line'>}
                  customPrefixSuffix={{
                    'Approval Ratio': { suffix: '%' },
                    Revenue: { prefix: '$' },
                  }}
                  className="chart"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loading} />
      <SideFilter
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
      />
    </div>
  );
};

export default SubscriptionSalvage;
