import { useAppDispatch } from 'hooks/reduxHooks';
import { useEffect } from 'react';
import { setIsMobile } from 'shared-components';
import { setCurrentWidth } from 'shared-components/src/page-builder/redux/slices/storeSlice';
import { viewPorts } from 'utils/constants';
type Props = {
  children: React.ReactNode;
};

const AppWrapper = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window?.innerWidth < viewPorts.tablet));
      dispatch(setCurrentWidth(window?.innerWidth));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window?.innerWidth]);

  return <>{children}</>;
};

export default AppWrapper;
