import { FC, useEffect } from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';

import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import {
  IStoreFrontEventResponse,
  IStoreFrontEventsFilter,
} from 'interface/storeInterface';
import FilterForm from 'components/storeFrontEvents/Filter/FilterForm/index';
import FilterAction from 'components/common/filterAction';
import { userPermissionKeys } from 'utils/constants';
import ActionFilter from './actionFilter';
interface ISideFilter {
  sideFilter: IStoreFrontEventsFilter;
  filterSubmission: (_data: IStoreFrontEventsFilter) => void;
  setPage: (_page: number) => void;
  selected: Map<string, IStoreFrontEventResponse>;
  searchAPI: () => void;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  resetSelected: () => void;
  filterList: string[];
  loading: number;
}

const SideFilter: FC<ISideFilter> = ({
  sideFilter,
  filterSubmission,
  setPage,
  selected,
  searchAPI,
  setLoading,
  resetSelected,
  filterList,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { order } = useAppSelector(state => state.permission);
  const filterAction = [
    { key: 'Filters', icon: FilterIcon },
    { key: 'Actions', icon: ActionIcon, permission: userPermissionKeys.manage },
  ];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({
        filterSource: 'storefrontEvents',
        currentFilter: filter,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);
  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFilter={sideFilter}
            setPage={setPage}
            filterSubmission={filterSubmission}
            resetSelected={resetSelected}
            loading={loading}
          />
        );
      case 'Actions':
        return (
          <ActionFilter
            searchAPI={searchAPI}
            selected={selected}
            setLoading={setLoading}
            resetSelected={resetSelected}
            filterList={filterList}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={order}
        setSelectedFilter={setSelectedFilter}
      />
      {!!currentFilter && filterSource === 'storefrontEvents' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
