import { useEffect, useState } from 'react';
import { IDisputeCodes, IReportsDisputeDetails } from 'interface/riskInterface';
import { Select } from 'shared-components';
import { allCardCodes } from 'utils/constants';
import { DoughnutBarChart } from 'components/common/chart/barChart';

interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    count: number[];
    borderColor: string[];
    backgroundColor: string[];
    borderWidth: number;
  }[];
}

const disputeReasonOptions = [
  { label: 'Disputes', value: 'Disputes' },
  { label: 'Chargebacks', value: 'Chargeback' },
  { label: 'VISA RDR', value: 'RDR' },
  { label: 'VISA CDRN', value: 'CDRN' },
  { label: 'MC Ethoca', value: 'Ethoca' },
];

const disputeReasonCode = ({
  disputeCodesData,
}: {
  disputeCodesData: IReportsDisputeDetails[];
}) => {
  const [disputeType, setDisputeType] = useState<string>('Disputes');
  const [disputeCodesDetais, setDisputeCodesDetais] = useState<IDisputeCodes[]>(
    [],
  );

  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        count: [],
        borderColor: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });
  useEffect(() => {
    setDisputeCodesDetails();
  }, [disputeCodesData, disputeType]);

  useEffect(() => {
    if (disputeCodesDetais.length) {
      const data = disputeCodesDetais.map(eachDispute => eachDispute.total);
      const borderColor: string[] = data.map(
        () => '#' + Math.floor(Math.random() * 16777215).toString(16),
      );
      setChartData({
        labels: disputeCodesDetais.map(eachDispute => eachDispute.label),
        datasets: [
          {
            data: data?.map(percentage => {
              return Number(
                ((percentage / data?.reduce((a, b) => a + b, 0)) * 100).toFixed(
                  2,
                ),
              );
            }),
            count: data,
            borderColor: borderColor,
            backgroundColor: borderColor?.map(color => color + '3b'),
            borderWidth: 1,
          },
        ],
      });
    }
  }, [disputeCodesDetais]);

  const setDisputeCodesDetails = () => {
    const isAllType = disputeType === 'Disputes';
    const totalCount = disputeCodesData.reduce(
      (accumulator: number, currentValue: IReportsDisputeDetails) => {
        let count = 0;
        if (isAllType) {
          count = currentValue.Count;
        } else {
          if (currentValue.DisputeAlerts?.length) {
            const selectedType = currentValue.DisputeAlerts.find(
              eachAlert => disputeType === eachAlert,
            );
            if (selectedType) {
              count = currentValue.Count;
            }
          }
        }
        return accumulator + count;
      },
      0,
    );
    const disputeDatas = new Map<string, IDisputeCodes>();
    let otherTranctionCounts = 0;
    disputeCodesData.forEach((eachCodeDetails: IReportsDisputeDetails) => {
      const condition =
        isAllType ||
        (eachCodeDetails?.DisputeAlerts?.length &&
          eachCodeDetails?.DisputeAlerts.includes(disputeType));
      if (eachCodeDetails.Code && condition) {
        let data = { value: '', label: '' };
        const transaction = Object.values(allCardCodes).find(eachCard => {
          const transactionType = eachCard.find(eachDisputeCode => {
            return (
              `${eachDisputeCode.value}_${eachDisputeCode.label}` ===
              eachCodeDetails.Code
            );
          });
          if (transactionType) {
            data = transactionType;
          }
          return transactionType;
        });
        if (transaction) {
          if (disputeDatas.has(data.label + data.value)) {
            const dispute: IDisputeCodes | undefined = disputeDatas.get(
              data.label + data.value,
            );
            if (dispute) {
              disputeDatas.set(data.label + data.value, {
                ...dispute,
                total: dispute.total + eachCodeDetails.Count,
                percentage: (
                  ((dispute.total + eachCodeDetails.Count) / totalCount) *
                  100
                ).toFixed(2),
              });
            }
          } else {
            disputeDatas.set(data.label + data.value, {
              code: data.value,
              label: data.label,
              total: eachCodeDetails.Count,
              percentage: ((eachCodeDetails.Count / totalCount) * 100).toFixed(
                2,
              ),
            });
          }
        } else {
          otherTranctionCounts += eachCodeDetails.Count;
        }
      } else if (condition) {
        otherTranctionCounts += eachCodeDetails.Count;
      }
    });
    if (otherTranctionCounts) {
      disputeDatas.set('', {
        code: '',
        label: 'Other',
        total: otherTranctionCounts,
        percentage: ((otherTranctionCounts / totalCount) * 100).toFixed(2),
      });
    }
    setDisputeCodesDetais([...disputeDatas.values()]);
  };

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Dispute Reason Code</p>
        <Select
          options={disputeReasonOptions}
          value={disputeType}
          onChange={e => {
            e.split('');
            setDisputeType(e);
          }}
        />
      </div>
      <div className="reason_code_wrapper">
        <div className="status_container">
          {disputeCodesDetais.map((dispute: IDisputeCodes, index: number) => (
            <div key={index} className="status_wrapper">
              <p
                className="status_color"
                style={{
                  backgroundColor: chartData.datasets[0].borderColor?.[index],
                }}></p>
              <div className="status_name">
                <div>{dispute.code}</div>
                <div className="card_name">{dispute.label}</div>
                <div className="card_Data">
                  {dispute.total}/{dispute.percentage}%
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="reason_code_chart_wrapper">
          <DoughnutBarChart data={chartData} className="chart" />
        </div>
      </div>
    </div>
  );
};

export default disputeReasonCode;
