import { FieldLabel, Fields } from '@measured/puck';
import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomTooltip from '../../../components/common/tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select } from '../../../page-builder/components/form-fields/Select';
import ToggleSwitch from '../../../components/common/toggleSwitch';
import { TextField } from '../../components/form-fields/TextField';

// Define the types for FontProps and Fields
type FontType = {
  size: string;
  weight: string;
};
export interface BorderType {
  border: boolean;
  borderRadious: string;
  borderColor: string;
  borderWidth: string;
}

export interface FontProps {
  font: FontType;
}

export interface HoverProps {
  hover: boolean;
  hoverColor: string;
  hoverBackgroundColor: string;
}

const fontSizeOptions = [
  { label: 'Small', value: 'text-sm' },
  { label: 'Medium', value: 'text-md' },
  { label: 'Large', value: 'text-lg' },
  { label: 'Extra Large', value: 'text-xl' },
  { label: 'Extra Larger', value: 'text-2xl' },
  { label: 'Largest', value: 'text-3xl' },
];

const fontWeightOptions = [
  { label: 'Normal', value: 'font-normal' },
  { label: 'Bold', value: 'font-bold' },
  { label: 'Bolder', value: 'font-extrabold' },
  { label: 'Light', value: 'font-light' },
];

const borderRadiusOptions = [
  { label: 'None', value: 'rounded-none' },
  { label: 'Small', value: 'rounded-sm' },
  { label: 'Medium', value: 'rounded-md' },
  { label: 'Large', value: 'rounded-lg' },
  { label: 'Full', value: 'rounded-full' },
];

const borderWidthOptions = [
  { label: 'None', value: 'border-0' },
  { label: '1', value: 'border-1' },
  { label: '2', value: 'border-2' },
  { label: '3', value: 'border-3' },
  { label: '4', value: 'border-4' },
  { label: '5', value: 'border-5' },
];

export type ColorSubProps = {
  backgroundColor: string;
  textColor: string;
  font: FontType;
  border: BorderType;
  hover: HoverProps;
  hideButtonIcons: boolean;
  nextText: string;
  previousText: string;
};
export type StepperFieldsProps = {
  button: ColorSubProps;
};

export const StepperCustomField: Fields<StepperFieldsProps> = {
  button: {
    type: 'custom',
    label: 'Color',
    render: ({ value, onChange }) => {
      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleColorChange = (
        colorKey: string,
        color: ColorResult,
        nestedField?: 'font' | 'border' | 'hover',
      ) => {
        if (!nestedField) {
          onChange({
            ...value,
            [colorKey]: color.hex,
          });
          return;
        }
        onChange({
          ...value,
          [nestedField]: {
            ...value?.[nestedField],
            [colorKey]: color.hex,
          },
        });
      };

      const handleChange = (
        key: string,
        option: React.ChangeEvent<HTMLSelectElement>,
        nestedField?: 'font' | 'border',
      ) => {
        if (!nestedField) {
          onChange({
            ...value,
            [key]: option.target.value,
          });
          return;
        }
        onChange({
          ...value,
          [nestedField]: {
            ...value?.[nestedField],
            [key]: option.target.value,
          },
        });
      };

      return (
        <div>
          <div
            className="flex justify-between font-extrabold cursor-pointer p-3 bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <h3>Customize Buttons</h3>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          <div className="flex flex-col gap-1  mt-3 items-center">
            {isOpen && (
              <div className="flex flex-col gap-4 w-full items-center border  border-gray-300 rounded p-2 pb-4">
                <div className="flex justify-between items-center w-full">
                  <p className="">Hide Icons for button</p>
                  <ToggleSwitch
                    checked={value?.hideButtonIcons}
                    className="filter_form_input"
                    onChange={e => {
                      onChange({
                        ...value,
                        hideButtonIcons: e.target.checked,
                      });
                    }}
                  />
                </div>
                <TextField
                  value={value?.nextText}
                  onChange={e => {
                    onChange({
                      ...value,
                      nextText: e.target.value,
                    });
                  }}
                  className={`filter_form_input stepper-item w-full`}
                  placeholder={`Next Button Text`}
                />
                <TextField
                  value={value?.previousText}
                  onChange={e => {
                    onChange({
                      ...value,
                      previousText: e.target.value,
                    });
                  }}
                  className={`filter_form_input stepper-item w-full`}
                  placeholder={`Previous Button Text`}
                />
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="backgroundColor">
                        <span>background Color</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <SketchPicker
                    color={value?.backgroundColor}
                    onChange={color =>
                      handleColorChange('backgroundColor', color)
                    }
                  />
                </FieldLabel>

                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="textColor">
                        <span>Text Color</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <SketchPicker
                    color={value?.textColor}
                    onChange={color => handleColorChange('textColor', color)}
                  />
                </FieldLabel>

                <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                  <FieldLabel
                    label={
                      (
                        <CustomTooltip title="fontSize">
                          <span>Font</span>
                        </CustomTooltip>
                      ) as React.ReactNode as string
                    }>
                    <Select
                      className="pagebuilder-theme py-2"
                      value={value?.font?.size}
                      onChange={e => handleChange('size', e, 'font')}
                      options={fontSizeOptions}
                      label="Size"
                    />
                    <Select
                      value={value?.font?.weight}
                      onChange={e => handleChange('weight', e, 'font')}
                      options={fontWeightOptions}
                      className="py-2"
                      label="Weight"
                    />
                  </FieldLabel>
                </div>

                <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                  <FieldLabel
                    label={
                      (
                        <CustomTooltip title="Border">
                          <span>Hover</span>
                        </CustomTooltip>
                      ) as React.ReactNode as string
                    }>
                    <div className="flex justify-between items-center">
                      <p className="">Hover</p>
                      <ToggleSwitch
                        checked={value?.hover?.hover}
                        className="filter_form_input default-theme-style"
                        onChange={e => {
                          onChange({
                            ...value,
                            hover: {
                              ...value?.hover,
                              hover: e.target.checked,
                            },
                          });
                        }}
                      />
                    </div>

                    {value?.hover?.hover && (
                      <>
                        <FieldLabel
                          label={
                            (
                              <CustomTooltip title="hoverColor">
                                <span className="py-2">Color</span>
                              </CustomTooltip>
                            ) as React.ReactNode as string
                          }>
                          <SketchPicker
                            color={value?.hover?.hoverColor}
                            className="default-theme-style"
                            onChange={color =>
                              handleColorChange('hoverColor', color, 'hover')
                            }
                          />
                        </FieldLabel>
                        <FieldLabel
                          label={
                            (
                              <CustomTooltip title="hoverColor">
                                <span className="py-2">Background color</span>
                              </CustomTooltip>
                            ) as React.ReactNode as string
                          }>
                          <SketchPicker
                            color={value?.hover?.hoverBackgroundColor}
                            className="default-theme-style"
                            onChange={color =>
                              handleColorChange(
                                'hoverBackgroundColor',
                                color,
                                'hover',
                              )
                            }
                          />
                        </FieldLabel>
                      </>
                    )}
                  </FieldLabel>
                </div>
                <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                  <FieldLabel
                    label={
                      (
                        <CustomTooltip title="Border">
                          <span>Border</span>
                        </CustomTooltip>
                      ) as React.ReactNode as string
                    }>
                    <div className="flex justify-between items-center">
                      <p className="">Border</p>
                      <ToggleSwitch
                        checked={value?.border?.border}
                        className="filter_form_input default-theme-style"
                        onChange={e => {
                          onChange({
                            ...value,
                            border: {
                              ...value?.border,
                              border: e.target.checked,
                            },
                          });
                        }}
                      />
                    </div>
                    {value?.border?.border && (
                      <>
                        <Select
                          value={value?.border?.borderRadious}
                          onChange={e =>
                            handleChange('borderRadious', e, 'border')
                          }
                          options={borderRadiusOptions}
                          label="Border Radius"
                          className="py-2"
                        />
                        <Select
                          value={value?.border?.borderWidth}
                          onChange={e =>
                            handleChange('borderWidth', e, 'border')
                          }
                          options={borderWidthOptions}
                          label="Border Width"
                          className="py-2"
                        />
                        {/* <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme"> */}
                        <FieldLabel
                          label={
                            (
                              <CustomTooltip title="textColor">
                                <span>Border Color</span>
                              </CustomTooltip>
                            ) as React.ReactNode as string
                          }>
                          <SketchPicker
                            color={value?.border?.borderColor}
                            className="default-theme-style"
                            onChange={color =>
                              handleColorChange('borderColor', color, 'border')
                            }
                          />
                        </FieldLabel>
                        {/* </div> */}
                      </>
                    )}
                  </FieldLabel>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
};
