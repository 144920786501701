import React from 'react';
import { Stars } from './Stars';

export interface ReviewProps {
  name: string;
  review: string;
}

export const Review: React.FC<ReviewProps> = ({ name, review }) => {
  return (
    <>
      <div className="flex items-start mt-10">
        <img
          alt="img"
          width={64}
          height={64}
          className="w-16 h-16 rounded-full"
          src="https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg"
        />
        <div className="ml-2">
          <div className="flex">
            <Stars />
            <div className="flex items-center">
              <p className="font-bold text-md ml-1">-{name}</p>
            </div>
          </div>
          <div className="flex items-center">
            <span className="mr-1">✔︎</span>
            <div className="font-bold text-sm">VERIFIED BUYER</div>
          </div>
          <dt className="text-gray-600 text-sm">&quot;{review}&quot;</dt>
        </div>
      </div>
    </>
  );
};
