import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import ShippingMethodForm from './ShippingMethodForm';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';

export type ShippingMethodSelectProps = MarginProps & showComponentProps;

export const ShippingMethodSelect = ({
  useAppDispatch,
  useAppSelector,
}: ReduxDispatchProps &
  ReduxSelectorProps): ComponentConfig<ShippingMethodSelectProps> => {
  return {
    label: (
      <CustomTooltip title="ShippingMethodSelect">
        <span>Shipping Method Select</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...marginFields,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-1',
          bottom: 'max-md:mb-1',
          right: 'max-md:mr-1',
          left: 'max-md:ml-1',
        },
        mobile: {
          top: 'max-sm:mt-1',
          bottom: 'max-sm:mb-1',
          right: 'max-sm:mr-1',
          left: 'max-sm:ml-1',
        },
      },
    },
    render: ({ margin, showComponent }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`flex-col justify-between 
              ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
              ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
              ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <ShippingMethodForm
                useAppDispatch={useAppDispatch}
                useAppSelector={useAppSelector}
              />
            </div>
          )}
        </>
      );
    },
  };
};
