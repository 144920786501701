import { Fields } from '@measured/puck';
import Button from '../../../components/common/button';
import { IForm, IStepper } from '../../components/stepper/Stepper';
import { CloseIcon } from 'shared-components/src/assets/icons/close-icon';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TextField } from '../../components/form-fields/TextField';

export type StepperProps = {
  forms: IForm;
};

export const StepperFields: Fields<StepperProps> = {
  forms: {
    type: 'custom',
    label: 'form',
    render: ({ value, onChange }) => {
      const isCloseClicked = useRef(false);

      const handleAddStep = () => {
        const id = uuidv4();
        onChange({
          ...value,
          form: [
            ...value.form,
            {
              id: id,
              formName: `step-${id}`,
              label: `Step`,
            },
          ],
        });
      };
      const removeStepHandler = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        data: IStepper,
        index: number,
      ) => {
        isCloseClicked.current = true;
        e.stopPropagation();
        onChange({
          ...value,
          form: value.form.filter(form => form.id !== data.id),
          selectedStepID:
            value.selectedStepID === data.id
              ? value.form[0].id
              : value.selectedStepID,
          selectedStepIndex:
            value.selectedStepIndex === index ? 0 : value.selectedStepIndex,
        });
      };

      const handleSelectStep = (data: IStepper, index: number) => {
        onChange({
          ...value,
          selectedStepID: data?.id,
          selectedStepIndex: index,
        });
      };

      return (
        <div className="grid gap-4">
          {
            <>
              <div className=" px-1 py-2 border border-gray-800 rounded-lg grid gap-2">
                {value?.form?.map((data: IStepper, index: number) => {
                  return (
                    <div
                      className={`flex items-center justify-between gap-2 border-t-1 cursor-pointer}`}
                      key={data?.id}
                      onClick={() => {
                        handleSelectStep(data, index);
                      }}>
                      <TextField
                        value={data?.label}
                        onChange={e =>
                          onChange({
                            ...value,
                            form: [
                              ...value.form.slice(0, index),
                              { ...value.form[index], label: e.target.value },
                              ...value.form.slice(index + 1),
                            ],
                          })
                        }
                        className={`filter_form_input stepper-item w-full ${data?.id === value?.selectedStepID ? 'active' : ''}`}
                        placeholder={`Step ${index + 1}`}
                      />
                      {value?.form?.length > 2 && (
                        <div
                          className="flex-none cursor-pointer pr-2"
                          onClick={e => {
                            removeStepHandler(e, data, index);
                          }}>
                          <CloseIcon height="15" width="15" />
                        </div>
                      )}
                    </div>
                  );
                })}
                <div className="flex justify-end">
                  <Button
                    type="button"
                    label="+Add Step"
                    variant="secondary"
                    onClick={() => {
                      handleAddStep();
                    }}
                  />
                </div>
              </div>
            </>
          }
        </div>
      );
    },
  },
};
