import React, { useState } from 'react';
import { Fields } from '@measured/puck';
import { Select } from '../../../page-builder/components/form-fields/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Radio } from '../../../page-builder/components/form-fields/Radio';

// Define the types for ImageProps and Fields
type ImageSubProps = {
  maxWidth: string;
  height: string;
  border: string;
  borderRadius: string;
  imageType: string;
  backgroundRepeat: string;
  backgroundSize: string;
  backgroundPosition: string;
};

export interface ImageProps {
  image: ImageSubProps;
}

interface Option {
  label: string;
  value: string;
}
interface RadioOption {
  id: string;
  name: string;
  value: boolean;
}

interface SelectField {
  type: 'select';
  label: string;
  options: Option[];
}

interface RadioField {
  type: 'radio';
  label: string;
  options: RadioOption[];
}

interface ImageField {
  maxWidth: SelectField;
  height: SelectField;
  border: SelectField;
  borderRadius: SelectField;
  imageType: RadioField;
  backgroundRepeat: SelectField;
  backgroundSize: SelectField;
  backgroundPosition: SelectField;
}

export const imageProps = (type: string[]) => {
  const imageField: ImageField = {
    maxWidth: {
      type: 'select',
      label: 'Max Width',
      options: [
        { label: '100px', value: '!max-w-[100px]' },
        { label: '200px', value: '!max-w-[200px]' },
        { label: '300px', value: '!max-w-[300px]' },
        { label: '400px', value: '!max-w-[400px]' },
        { label: '500px', value: '!max-w-[500px]' },
        { label: '600px', value: '!max-w-[600px]' },
        { label: '700px', value: '!max-w-[700px]' },
        { label: '800px', value: '!max-w-[800px]' },
        { label: '900px', value: '!max-w-[900px]' },
        { label: '1000px', value: '!max-w-[1000px]' },
        { label: 'auto', value: '!auto' },
      ],
    },
    height: {
      type: 'select',
      label: 'Height',
      options: [
        { label: '100px', value: 'h-[100px]' },
        { label: '150px', value: 'h-[150px]' },
        { label: '200px', value: 'h-[200px]' },
        { label: '300px', value: 'h-[300px]' },
        { label: '400px', value: 'h-[400px]' },
        { label: '500px', value: 'h-[500px]' },
        { label: '600px', value: 'h-[600px]' },
        { label: '700px', value: 'h-[700px]' },
        { label: '800px', value: 'h-[800px]' },
        { label: '900px', value: 'h-[900px]' },
        { label: '1000px', value: 'h-[1000px]' },
        { label: 'auto', value: 'h-auto' },
      ],
    },
    border: {
      type: 'select',
      label: 'Border',
      options: [
        { label: 'None', value: 'border-none' },
        { label: 'Solid', value: 'border-solid' },
        { label: 'Dashed', value: 'border-dashed' },
        { label: 'Dotted', value: 'border-dotted' },
        { label: 'Double', value: 'border-double' },
        { label: 'Groove', value: 'border-groove' },
        { label: 'Ridge', value: 'border-ridge' },
        { label: 'Inset', value: 'border-inset' },
        { label: 'Outset', value: 'border-outset' },
      ],
    },
    borderRadius: {
      type: 'select',
      label: 'Border Radius',
      options: [
        { label: '0', value: 'rounded-none' },
        { label: 'Small', value: 'rounded-sm' },
        { label: 'Medium', value: 'rounded-md' },
        { label: 'Large', value: 'rounded-lg' },
        { label: 'Full', value: 'rounded-full' },
        { label: 'T-Large', value: 'rounded-t-lg' },
      ],
    },
    imageType: {
      type: 'radio',
      label: 'Mode',
      options: [
        { id: '1', name: 'inline', value: true },
        { id: '2', name: 'background', value: true },
      ],
    },
    backgroundRepeat: {
      type: 'select',
      label: 'Background Repeat',
      options: [
        { label: 'No Repeat', value: 'bg-no-repeat' },
        { label: 'Repeat X', value: 'bg-repeat-x' },
        { label: 'Repeat Y', value: 'bg-repeat-y' },
        { label: 'Repeat', value: 'bg-repeat' },
      ],
    },
    backgroundSize: {
      type: 'select',
      label: 'Background Size',
      options: [
        { label: 'Auto', value: 'bg-auto' },
        { label: 'Cover', value: 'bg-cover' },
        { label: 'Contain', value: 'bg-contain' },
      ],
    },
    backgroundPosition: {
      type: 'select',
      label: 'Background Position',
      options: [
        { label: 'Center', value: 'bg-center' },
        { label: 'Top', value: 'bg-top' },
        { label: 'Right', value: 'bg-right' },
        { label: 'Bottom', value: 'bg-bottom' },
        { label: 'Left', value: 'bg-left' },
        { label: 'Top Right', value: 'bg-top-right' },
        { label: 'Top Left', value: 'bg-top-left' },
        { label: 'Bottom Right', value: 'bg-bottom-right' },
        { label: 'Bottom Left', value: 'bg-bottom-left' },
      ],
    },
  };

  if (type.length > 0) {
    type.forEach(key => {
      delete imageField[key as keyof ImageField];
    });
  }

  return {
    image: {
      type: 'custom',
      label: 'Image',
      render: ({ value, onChange }) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleAccordion = () => {
          setIsOpen(!isOpen);
        };

        const handleChange = (
          key: string,
          option: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
        ) => {
          onChange({
            ...value,
            [key]: option.target.value,
          });
        };

        return (
          <div>
            <div
              className="flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
              onClick={toggleAccordion}>
              <h3>Customize Image</h3>
              {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </div>
            {isOpen && (
              <div className="flex flex-col gap-4 mt-3 items-center border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                {Object.entries(imageField).map(([field, config]) => (
                  <div
                    key={field}
                    className="border border-gray-300 rounded p-2 w-full">
                    {config.type === 'select' ? (
                      <Select
                        value={value[field as keyof ImageSubProps]}
                        onChange={e => handleChange(field, e)}
                        options={config.options}
                        label={config.label}
                      />
                    ) : (
                      <Radio
                        value={value[field as keyof ImageSubProps]}
                        onChange={e => handleChange(field, e)}
                        options={config.options as RadioOption[]}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      },
      defaultItemProps: {
        imageType: 'inline',
        backgroundPosition: 'bg-center',
        backgroundRepeat: 'bg-no-repeat',
        backgroundSize: 'bg-cover',
        borderRadius: 'rounded-md',
        border: 'border-none',
        height: 'h-[200px]',
        maxWidth: '!max-w-[200px]',
      },
    },
  } as Fields<ImageProps>;
};
