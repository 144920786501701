import { AxiosError } from 'axios';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';

import { mockSubscriptionManagementData } from 'utils/mockData';
import {
  commonError,
  getSubscriptionMangementEP,
  getSubscriptionUpdateEp,
  subscriptionExportEP,
} from 'services/constants';
import { baseService } from 'services';
import {
  ISubscriptionList,
  ISubscriptionManagementUpdateBody,
  ISubscrptionManagementSearch,
} from 'interface/subscriptionManagementInterface';

class SubscriptionManagementMockService {
  getSubscriptionData = async () => {
    try {
      return await new Promise<ISuccessResponse<ISubscriptionList>>(resolve => {
        setTimeout(() => {
          resolve({
            data: mockSubscriptionManagementData,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateSubscription = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  exportSubscriptionData = async (
    payload: ISubscrptionManagementSearch,
  ): Promise<
    ISuccessResponse<ISubscriptionList> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(subscriptionExportEP, payload);

      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class SubscriptionManagementService {
  SubscriptionManagementMockService = new SubscriptionManagementMockService();
  getSubscriptionData = async (
    payload: ISubscrptionManagementSearch,
  ): Promise<
    ISuccessResponse<ISubscriptionList> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ISubscriptionList>(
        getSubscriptionMangementEP(),
        payload,
      );

      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateSubscription = async (
    body: ISubscriptionManagementUpdateBody,
  ): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(getSubscriptionUpdateEp(), body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  exportSubscriptionData = async (
    payload: ISubscrptionManagementSearch,
  ): Promise<
    | { data: string; status: number; message: string }
    | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(subscriptionExportEP, payload);

      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalSubscriptionManagementService:
  | SubscriptionManagementService
  | SubscriptionManagementMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new SubscriptionManagementMockService()
    : new SubscriptionManagementService();

export default globalSubscriptionManagementService;
