import React from 'react';
import { EditIcon } from 'shared-components';
import { DeleteIcon } from 'shared-components';
import { trackerConfig } from './trackerConfig';
import { Tracker } from '../../../interface/pixelInterface';

export interface PixelTrackerItemProps {
  pixelTracker: Tracker;
  openPopup: (_tracker: Tracker) => void;
  handleDeleteTracker(_id: string): void;
}

export const PixelTrackerItem: React.FC<PixelTrackerItemProps> = ({
  pixelTracker,
  openPopup,
  handleDeleteTracker,
}) => {
  return (
    <>
      <div key={pixelTracker.id}>
        <div>
          <div className="flex px-2 py-2 mt-3 mb-2 justify-between items-center border rounded --component-background">
            <span className="">{pixelTracker.trackerType}</span>
            <div className="flex gap-2">
              <button type="button" onClick={() => openPopup(pixelTracker)}>
                <EditIcon />
              </button>
              <button
                type="button"
                onClick={() => handleDeleteTracker(pixelTracker.id)}>
                <DeleteIcon />
              </button>
            </div>
          </div>
          {trackerConfig
            .find(tracker => tracker.name === pixelTracker.trackerType)
            ?.fields.map(field => (
              <div key={field.id}>
                {pixelTracker.fields[field.id]
                  ?.join(', ')
                  .split(',')
                  .map((item, index) => (
                    <span
                      key={index}
                      style={{
                        maxWidth: '100%',
                      }}
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-black mr-1">
                      <svg
                        viewBox="0 0 6 6"
                        aria-hidden="true"
                        className="h-1.5 w-1.5 fill-blue-500">
                        <circle r={3} cx={3} cy={3} />
                      </svg>
                      <div
                        style={{
                          whiteSpace: 'normal',
                          overflowWrap: 'break-word',
                          wordWrap: 'break-word',
                          wordBreak: 'break-word',
                        }}>
                        {item}
                      </div>
                    </span>
                  )) || ''}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
