// import { get } from 'lodash';
import React, { ReactNode, useState } from 'react';
import './style.scss';

import { ILegacyPaymentGateway } from 'interface/settingsInterface';

import Tabs from 'components/common/Tabs';
import Gateway from './gateways';
import MerchantAccount from './merchantAccount';
import PaymentRouting from 'components/store/storeDetails/PaymentRouting';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ILegacyPaymentGateway) => ReactNode;
  hide?: boolean;
}
const tabName = ['Gateways', 'Processors', 'Payment Routing'];
interface IProps {
  storeID: string;
}
export default function PaymentGateway({ storeID }: IProps) {
  const [tabValue, setTabValue] = useState('1');
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <>
      <div className="tab-content">
        <Tabs value={tabValue} labels={tabName} onChange={handleTabChange} />
        <div className="tab-content-wrapper">
          {tabValue === '1' && <Gateway storeID={storeID} />}
          {tabValue === '2' && <MerchantAccount storeID={storeID} />}
          {tabValue === '3' && <PaymentRouting storeId={storeID} />}
        </div>
      </div>
    </>
  );
}
