import { createSlice } from '@reduxjs/toolkit';
import { Shop } from '../../../page-builder/interface/storeInterface';

export interface StoreState {
  storeData: Shop;
  isMobile: boolean;
  currentWidth: number;
}

const initialState: StoreState = {
  storeData: {
    CheckoutURL: '',
    CustomerServiceNumber: '',
    CustomerServiceEmail: '',
    MetaPixelID: '',
    Name: '',
    PaymentGateway: '',
    PublicPaymentToken: '',
    ShopID: '',
    StoreID: '',
    ShopURL: '',
    StoreDescription: '',
    CustomerReviews: [
      {
        id: 0,
        name: '',
        review: '',
      },
    ],
    Timer: {
      interval: 0,
      saleText: '',
      bodyText: '',
    },
  },
  currentWidth: 0,
  isMobile: false,
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStoreData(state, action) {
      return { ...state, storeData: action.payload };
    },
    setIsMobile: (state, action) => {
      return { ...state, isMobile: action.payload };
    },
    setCurrentWidth: (state, action) => {
      return { ...state, currentWidth: action.payload };
    },
  },
});

export const { setStoreData, setIsMobile, setCurrentWidth } =
  storeSlice.actions;

export default storeSlice.reducer;
