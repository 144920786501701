import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import storeFrontService from 'services/storeFrontService';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import { urlPattern } from 'utils/constants';
import { IStoreFront } from 'interface/settingsInterface';
import { showNotification } from 'helper/common/commonFunctions';
import { Select } from 'shared-components';
import './style.scss';
import { isFeatureEnabled } from 'utils/helper';
import { pageNames } from 'router/constant';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
dayjs.extend(utc);

export const membershipTypeOptions = [
  {
    label: 'Single Membership Only',
    value: 'all_one_membership',
    message:
      'All your products are sold in a single membership. Can use with any theme',
  },
  {
    label: 'Legacy - Guest and Retail',
    value: 'legacy_variant',
    message:
      'Sell both retail and membership prices by setting Price Variants on each item. This limits you to a handful of pre-selected themes you must choose from.',
  },
];

export const themeOptions = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Theme 1',
    value: 'theme1',
  },
  {
    label: 'Theme 2',
    value: 'theme2',
  },
  {
    label: 'Theme 3',
    value: 'theme3',
  },
];

interface IProps {
  handleClose: () => void;
  isAdd: boolean;
  onSuccess: () => void;
  defaultStoreValues: IStoreFront;
}

const AddStore: FC<IProps> = ({
  handleClose,
  defaultStoreValues,
  onSuccess,
  isAdd,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch } = useForm<IStoreFront>({
    defaultValues: defaultStoreValues,
  });

  const membershipType = watch('Shopify.MembershipType');

  const addStoreFront = async (data: IStoreFront) => {
    setLoading(true);
    const payload = { ...data };
    payload.ID = uuidv4();
    payload.Shopify.ID = uuidv4();
    payload.StoreID = defaultStoreValues.StoreID;
    const res = await storeFrontService.createStoreFront(payload);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Added Store Successsfully');
      onSuccess();
    } else handleClose();
  };

  const updateStoreFront = async (data: IStoreFront) => {
    setLoading(true);

    const res = await storeFrontService.updateStoreFront({ ...data });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Store Front Updated Successsfully');
      onSuccess();
    } else handleClose();
  };

  const trimValue = (value: string, field: string) => {
    const trimmedValue = value.trim();
    setValue(field as keyof IStoreFront, trimmedValue);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: isAdd ? 'Add Storefront' : 'Update storefront',
            form: 'AddStoreFront',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${isAdd ? 'Add ' : 'Update'} Storefront`}
        className="flex-container">
        <div className="popup-section">
          <form
            className="storeFront-collapsed"
            id="AddStoreFront"
            onSubmit={
              isAdd
                ? handleSubmit(addStoreFront)
                : handleSubmit(updateStoreFront)
            }>
            <Controller
              name="Name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    onBlur={value => trimValue(value.target.value, 'Name')}
                    value={value}
                    label="Store name"
                    errorString={error?.message}
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter name"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="ShopID"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, onChange, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    onBlur={value => trimValue(value.target.value, 'ShopID')}
                    errorString={error?.message}
                    label="Shopify store domain id"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter store ID"
                    onChange={e => {
                      setValue('Shopify.ShopID', e.target.value);
                      onChange(e.target.value);
                    }}
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="ShopURL"
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: urlPattern,
                  message: 'Invalid URL',
                },
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    onBlur={value => trimValue(value.target.value, 'ShopURL')}
                    value={value}
                    label="Store URL"
                    errorString={error?.message}
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter url"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="Shopify.APIKey"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    onBlur={value =>
                      trimValue(value.target.value, 'Shopify.APIKey')
                    }
                    value={value}
                    label="Shopify api key"
                    errorString={error?.message}
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter api key"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="Shopify.APISecret"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    onBlur={value =>
                      trimValue(value.target.value, 'Shopify.APISecret')
                    }
                    value={value}
                    errorString={error?.message}
                    label="Shopify app secret"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter app secret"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="Shopify.AccessToken"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    onBlur={value =>
                      trimValue(value.target.value, 'Shopify.AccessToken')
                    }
                    errorString={error?.message}
                    label="Shopify access token"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter access token"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="Shopify.StorefrontPublicAccessToken"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    onBlur={value =>
                      trimValue(
                        value.target.value,
                        'Shopify.StorefrontPublicAccessToken',
                      )
                    }
                    errorString={error?.message}
                    label="Storefront Access Token"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter public access token"
                    ref={ref}></TextField>
                );
              }}
            />
            <Controller
              name="Shopify.MembershipType"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <AutoCompleteSelect
                      options={membershipTypeOptions}
                      labelKey="label"
                      onChange={val => onChange(val?.value || null)}
                      value={
                        membershipTypeOptions?.find(
                          type => type.value === value,
                        ) || null
                      }
                      placeholder="select membership type"
                      className="w-full dropdown-field"
                      label="Shopify Membership Type"
                    />
                    <p className="membership-type-text">
                      {
                        membershipTypeOptions.find(val => val.value === value)
                          ?.message
                      }
                    </p>
                  </>
                );
              }}
            />
            {isFeatureEnabled(pageNames.MEMBERSHIP_TYPE) && (
              <>
                {membershipType === 'legacy_variant' && (
                  <Controller
                    name="Shopify.ThemeSelector"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          value={value}
                          label="Theme"
                          options={themeOptions}
                          placeholder="Select Theme"
                          onChange={val => onChange(val)}
                        />
                      );
                    }}
                  />
                )}
                <Controller
                  name="Shopify.CheckoutBtnSelector"
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({
                    field: { ref, value, ...rest },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        {...rest}
                        value={value}
                        onBlur={value =>
                          trimValue(
                            value.target.value,
                            'Shopify.CheckoutBtnSelector',
                          )
                        }
                        errorString={error?.message}
                        label="Checkout Button Selector (comma-separated list of query selectors)"
                        className="filter_form_input"
                        fixedSize="md"
                        placeholder="Enter query selectors, separated by commas"
                        ref={ref}></TextField>
                    );
                  }}
                />
              </>
            )}
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddStore;
