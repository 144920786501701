import React from 'react';
import { ReduxSelectorProps } from '../../../page-builder/services/constants';

interface DeclinePopupProps extends ReduxSelectorProps {
  onClose: () => void;
  declineReasonCode: string;
}

const DeclinePopup: React.FC<DeclinePopupProps> = ({
  onClose,
  declineReasonCode,
  useAppSelector,
}) => {
  const storeData = useAppSelector(state => state.store.storeData);

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-50 py-4">
      <div className="bg-white rounded-lg w-full max-w-lg overflow-y-auto h-full">
        <div className="bg-gray-900 p-2 rounded-t-lg text-white flex items-center justify-center">
          <div className="font-bold text-xl">
            Uh oh! Your payment method didn&apos;t work.
          </div>
        </div>
        <div className="p-4 border border-gray-100 rounded-lg">
          <div className="bg-green-600 bg-opacity-75 text-white p-5 rounded-xl">
            <div className="font-bold text-lg text-center">
              CALL {storeData?.CustomerServiceNumber} FOR LIVE HUMAN HELP.
            </div>
            <div className="font-normal text-xs text-center">
              Our 24/7 team will help you reattempt the purchase.
            </div>
          </div>
          <div className="my-7 text-center text-lg font-normal tracking-wider leading-6">
            <strong>Decline reason code:</strong>{' '}
            {declineReasonCode || 'Invalid card information'}
          </div>
          <div className="bg-blue-100 bg-opacity-50 p-5 rounded-xl text-sm">
            <div className="tracking-wider mb-4 text-center leading-4">
              Please check that you provided accurate payment information. We
              cannot process your order until you have resolved this issue. Our
              system declined the transaction due to an incorrect card number,
              billing address, or CVV.
            </div>
            <div className="tracking-wider mb-4 text-center leading-4">
              Your payment provider may also decline a transaction if you
              haven&apos;t bought from a store before or if you are browsing the
              internet in a different location.
            </div>
          </div>
          <div className="mt-7 text-center text-lg font-normal tracking-wider leading-6">
            <strong>Here&apos;s what to do next:</strong>
          </div>
          <div className="shadow-md px-4 py-4 relative rounded-lg m-1 flex">
            <div className="w-14 h-14 bg-blue-600 rounded-full flex items-center justify-center">
              <img
                width={500}
                height={500}
                src="https://phoenixcrm.io/crm/shopify/assets/popup_3.png"
                alt="Popup"
                className="w-8 h-8 max-w-full"
              />
            </div>
            <div className="flex flex-col pl-4 max-w-[calc(100%-3.5rem)]">
              <div className="text-xs font-bold">
                Contact our LIVE Support team
              </div>
              <div className="text-sm tracking-wide mt-1 font-normal leading-4">
                Call our 24/7 Team at {storeData?.CustomerServiceNumber} for
                immediate assistance with your payment.
              </div>
            </div>
          </div>
          <div className="shadow-md px-4 py-4 relative rounded-lg m-1 flex">
            <div className="w-14 h-14 bg-blue-600 rounded-full flex items-center justify-center">
              <img
                width={500}
                height={500}
                src="https://phoenixcrm.io/crm/shopify/assets/popup_2.png"
                alt="Popup"
                className="w-8 h-8 max-w-full"
              />
            </div>
            <div className="flex flex-col pl-4 max-w-[calc(100%-3.5rem)]">
              <div className="text-xs font-bold">
                Check that you entered the correct CC details
              </div>
              <div className="text-sm tracking-wide mt-1 font-normal leading-4">
                Ensure that your card number, expiration date, CVV, and billing
                zip are entered correctly.
              </div>
            </div>
          </div>
          <div className="shadow-md px-4 py-4 relative rounded-lg m-1 flex justify-center items-center">
            <div className="w-14 h-14 bg-blue-600 rounded-full flex items-center justify-center">
              <img
                width={500}
                height={500}
                src="https://phoenixcrm.io/crm/shopify/assets/popup_1.png"
                alt="Popup"
                className="w-8 h-8 max-w-full"
              />
            </div>
            <div className="flex flex-col pl-4 max-w-[calc(100%-3.5rem)]">
              <div className="text-xs font-bold">
                Check your mobile phone for a text message from your bank
              </div>
              <div className="text-sm tracking-wide mt-1 font-normal leading-4 pb-2">
                • If you have a text or a call from your payment provider or
                bank, respond and approve the authorization.
              </div>
              <div className="text-sm tracking-wide mt-1 font-normal leading-4">
                • Once the authorization has been approved, please re-attempt
                the order.
              </div>
            </div>
          </div>
          <div className="shadow-md px-4 py-4 relative rounded-lg m-1 flex">
            <div className="w-14 h-14 bg-blue-600 rounded-full flex items-center justify-center">
              <img
                width={500}
                height={500}
                src="https://phoenixcrm.io/crm/shopify/assets/popup_4.png"
                alt="Popup"
                className="w-8 h-8 max-w-full"
              />
            </div>
            <div className="flex flex-col pl-4 max-w-[calc(100%-3.5rem)]">
              <div className="text-xs font-bold">
                Use a different payment method
              </div>
              <div className="text-base font-normal leading-4">
                Re-attempt the order with a different payment method.
              </div>
            </div>
          </div>
          <div className="pt-4 flex justify-center items-center">
            <button
              className="border text-xs text-white bg-black rounded-full px-4 py-2"
              onClick={onClose}>
              RETURN TO PAYMENT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclinePopup;
