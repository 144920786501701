import { Fields } from '@measured/puck';

export type GapProps = {
  gap: string;
};

export const gapField: Fields<GapProps> = {
  gap: {
    type: 'select',
    label: 'Gap',
    options: [
      { label: '0', value: 'gap-0' },
      { label: '1', value: 'gap-1' },
      { label: '2', value: 'gap-2' },
      { label: '4', value: 'gap-4' },
      { label: '8', value: 'gap-8' },
      { label: '16', value: 'gap-16' },
    ],
  },
};
