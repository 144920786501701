import { FC, useState } from 'react';
import 'components/customers/customerDetails/customerDetailsFilter/actionFilter/style.scss';
import {
  ICustomerDetails,
  ICustomersSubscription,
} from 'interface/customerInterface';
import CancelSubscriptionAction from 'components/Actions/cancelSubscription';
import ChangeSubscriptionFrequency from 'components/Actions/changeSubscriptionFrequency';
import ChangeSubscriptionValue from 'components/Actions/changeSubscriptionValue';
import ChangeBillingDate from 'components/Actions/changeBillingDate';
import ReactivateSubscription from 'components/Actions/reactivateSubscription';
import BlackList from 'components/Actions/blacklist';
import ChangeSubscription from 'components/Actions/changeSubscription';

interface IProps {
  filterList: string[];
  customer?: ICustomerDetails | null;
  customerSubscription?: ICustomersSubscription | null;
  refetch: () => void;
}

const ActionFilter: FC<IProps> = ({
  filterList,
  customer,
  customerSubscription,
  refetch,
}) => {
  const [isPopupOpen, setPopupOpen] = useState<{
    cancel_subscription: boolean;
    re_activate_subscription: boolean;
    change_next_billing_date: boolean;
    partial_refund: boolean;
    blacklist_customer: boolean;
    change_subscription_value: boolean;
    change_subscription_frequency: boolean;
    change_subscription: boolean;
  }>({
    cancel_subscription: false,
    re_activate_subscription: false,
    change_next_billing_date: false,
    partial_refund: false,
    blacklist_customer: false,
    change_subscription_value: false,
    change_subscription_frequency: false,
    change_subscription: false,
  });

  const handleClose = (value: string) => {
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };
  const handleOpen = (value: string) => {
    value = value.toLowerCase().replaceAll(/[ -]/g, '_');
    setPopupOpen(pre => ({ ...pre, [value]: true }));
  };

  const getSubscriptionPayload = () => {
    if (!customer) return null;
    const { Store } = customer || {};
    const {
      ID,
      Version,
      Amount,
      Frequency,
      Interval,
      NextChargeDate,
      Status,
      AuthPeriod,
      AuthPeriodUnit,
    } = customerSubscription || {};
    const { ID: storeID } = Store || {};
    return {
      Amount,
      Frequency,
      ID: ID || '',
      Status,
      Interval: Number(Interval),
      NextBillingDate: NextChargeDate,
      StoreID: storeID,
      Version: Number(Version),
      AuthPeriod,
      AuthPeriodUnit,
    };
  };

  return (
    <>
      {isPopupOpen?.cancel_subscription && (
        <CancelSubscriptionAction
          payload={getSubscriptionPayload()}
          handleClose={() => {
            handleClose('cancel_subscription');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('cancel_subscription');
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.change_next_billing_date && (
        <ChangeBillingDate
          payload={getSubscriptionPayload()}
          handleClose={() => {
            handleClose('change_next_billing_date');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_next_billing_date');
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.blacklist_customer && (
        <BlackList
          payload={
            customer
              ? {
                  Email: customer.Email,
                  HasDisputed: customer.HasDisputed,
                  ID: customer.ID,
                  IsBlacklisted: true,
                  PhoneNumber: customer.PhoneNumber,
                  StoreID: customer.Store?.ID,
                  Tags: customer?.Tags ?? [],
                  Version: customer.Version,
                }
              : null
          }
          handleClose={() => {
            handleClose('blacklist_customer');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.re_activate_subscription && (
        <ReactivateSubscription
          payload={getSubscriptionPayload()}
          handleClose={() => {
            handleClose('re_activate_subscription');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('re_activate_subscription');
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.change_subscription_frequency && (
        <ChangeSubscriptionFrequency
          payload={getSubscriptionPayload()}
          handleClose={() => {
            handleClose('change_subscription_frequency');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_subscription_frequency');
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.change_subscription_value && (
        <ChangeSubscriptionValue
          payload={getSubscriptionPayload()}
          handleClose={() => {
            handleClose('change_subscription_value');
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_subscription_value');
              refetch();
            }
          }}
        />
      )}
      {isPopupOpen?.change_subscription && (
        <ChangeSubscription
          handleClose={() => {
            setPopupOpen(pre => ({ ...pre, change_subscription: false }));
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              handleClose('change_subscription');
              refetch();
            }
          }}
          payload={getSubscriptionPayload()}
        />
      )}
      <div className="action_filter_container">
        {filterList?.map((cols: string, index: number) => (
          <div
            className="action_filter_columns"
            onClick={() => {
              handleOpen(cols);
            }}
            key={index}>
            {cols}
          </div>
        ))}
      </div>
    </>
  );
};

export default ActionFilter;
