import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import globalStoreFrontService from 'services/storeFrontService';

export default function HeaderStoreFrontDetails() {
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState(location.state?.name || '');

  const getStoreById = async (storeId: string, storeFrontId: string) => {
    const response = await globalStoreFrontService.getStoreFront(
      storeId,
      storeFrontId,
    );
    if (response.status === 200) {
      setData(response?.data?.Name);
    }
  };

  useEffect(() => {
    if (!data) {
      getStoreById(params?.storeId || '', params?.shopId || '');
    }
  }, []);

  return <div>Storefront: {data}</div>;
}
