import React from 'react';
import {
  Variant,
  Item,
  SelectedItem,
} from '../../../../page-builder/interface/postUpsellInterface';
import LoadingSpinner from '../../../../page-builder/components/shared/LoadingSpinner';
import { useDispatch } from 'react-redux';
import {
  setCurrentIndex,
  setIsLoading,
  setSelectedItems,
} from '../../../../page-builder/redux/slices/postUpsellStateSlice';
import { MoneyBackIcon } from '../../../../assets/icons/money-back';
import { FastDeliveryIcon } from '../../../../assets/icons/fast-delivery';
import { FreeShippingIcon } from '../../../../assets/icons/free-shipping';
import { NortonLogoIcon } from '../../../../assets/icons/norton-logo';
import { SafePurchaseIcon } from '../../../../assets/icons/safe-purchase';
import { McafeeLogoIcon } from '../../../../assets/icons/mcafee-logo';

export interface PostUpSellDealProps {
  selectedVariant: Variant;
  currentRecommendation: Item;
  postUpSellRecommendation: Item[];
  handleCompletePurchase: (_selectedItems: SelectedItem[]) => void;
  currentIndex: number;
  selectedItems: SelectedItem[];
  isLoading: boolean;
}

export const PostUpSellDeal: React.FC<PostUpSellDealProps> = ({
  selectedVariant,
  currentRecommendation,
  postUpSellRecommendation,
  handleCompletePurchase,
  currentIndex,
  selectedItems,
  isLoading,
}) => {
  const dispatch = useDispatch();
  async function handleClick() {
    dispatch(setIsLoading(true));
    const newSelectedItems = [
      ...selectedItems,
      { item: currentRecommendation, variant: selectedVariant },
    ];
    dispatch(setSelectedItems(newSelectedItems));
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (currentIndex + 1 >= postUpSellRecommendation.length) {
      try {
        await handleCompletePurchase(newSelectedItems);
        dispatch(setIsLoading(false));
      } catch (error) {
        dispatch(setIsLoading(false));
      }
    } else {
      dispatch(setCurrentIndex(currentIndex + 1));
      dispatch(setIsLoading(false));
    }
  }

  return (
    <>
      <div className="w-full">
        <div className="flex justify-evenly mb-4 text-xs text-[#686868]">
          <div className="flex items-center">
            <MoneyBackIcon />
            <div className="ml-3">Money Back Guarantee</div>
          </div>
          <div className="flex items-center">
            <FreeShippingIcon />
            <div className="ml-3">Free Shipping</div>
          </div>
          <div className="flex items-center">
            <FastDeliveryIcon />
            <div className="ml-3">Fast delivery</div>
          </div>
        </div>
        <button
          onClick={handleClick}
          className={`relative bg-black text-center rounded-2xl w-full ${isLoading ? 'text-gray-500' : 'text-white'}`}
          disabled={isLoading}>
          <div className="bg-[#686868] rounded-t-2xl font-bold text-2xl p-1">
            BEST DEAL
          </div>
          <div className="p-4">
            <div className="lg:text-4xl text-2xl font-bold my-3">
              GET 1 FOR ${selectedVariant?.Price}
            </div>
            <div>+ $3.99 for shipping</div>
            {isLoading && (
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-20">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </button>
        <div className="flex items-center justify-center ">
          <SafePurchaseIcon />
          <McafeeLogoIcon />
          <NortonLogoIcon />
        </div>
      </div>
    </>
  );
};

export default PostUpSellDeal;
