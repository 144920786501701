export const VectorIcon = () => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79541 7.04675C6.58448 7.25742 6.29854 7.37576 6.00041 7.37576C5.70229 7.37576 5.41635 7.25742 5.20541 7.04675L0.961914 2.80475C0.750967 2.5937 0.632498 2.3075 0.632568 2.00911C0.632639 1.71071 0.751242 1.42457 0.962288 1.21362C1.17333 1.00268 1.45953 0.884207 1.75793 0.884277C2.05632 0.884348 2.34247 1.00295 2.55341 1.214L6.00041 4.661L9.44741 1.214C9.65949 1.00897 9.94362 0.895438 10.2386 0.897862C10.5336 0.900285 10.8158 1.01847 11.0245 1.22695C11.2332 1.43544 11.3516 1.71755 11.3543 2.01252C11.357 2.30749 11.2437 2.59172 11.0389 2.804L6.79616 7.0475L6.79541 7.04675Z"
        fill="#A3AED0"
        fillOpacity="0.4"
      />
    </svg>
  );
};
