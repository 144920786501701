import { FC, useState } from 'react';
import 'components/orders/ordersFilter/actionFilter/style.scss';
import { showNotification } from 'helper/common/commonFunctions';
import { IOrder } from 'interface/orderInterface';
import globalOrderService from 'services/orderService';
import TagAction from 'components/Actions/tags';
import OrderRefund from './refundAction';
interface IProps {
  filterList: string[];
  selected: Map<string, IOrder>;
  searchOrderList: () => void;
  resetSelected: () => void;
}
export const ActionFilter: FC<IProps> = ({
  filterList,
  selected,
  searchOrderList,
  resetSelected,
}) => {
  const [isPopupOpen, setPopupOpen] = useState<{
    tag: boolean;
    refund: boolean;
  }>({
    tag: false,
    refund: false,
  });
  const [textValue, setTextValue] = useState<string[]>([]);
  const updateTag = async (textTag?: string | null) => {
    if (selected?.size) {
      await Promise.all(
        [...selected.values()]?.map(async data => {
          const res = await globalOrderService.updateOrder({
            ID: data.ID,
            StoreID: data?.Store?.ID,
            Tags: [...textValue, ...(textTag ? [textTag] : [])],
            Version: data?.Version,
          });
          if (res.status === 200) {
            searchOrderList();
            resetSelected();
          }
        }),
      );
    }
  };
  const handleClose = (value: string) => {
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };
  const handleOpen = (value: string) => {
    value = value.toLowerCase().replaceAll(' ', '_');
    if (selected?.size === 1) {
      setPopupOpen(pre => ({ ...pre, [value]: true }));
    } else if (selected?.size > 1) {
      showNotification('failed', 'This actions is not compatible');
    } else {
      showNotification('failed', 'Please select at least one Order');
    }
    if (selected && ['tag'].includes(value)) {
      const tags: string[] = [...selected.values()][0]?.Tags || [];
      setTextValue(tags);
    }
  };

  return (
    <div className="action_filter_container">
      {isPopupOpen.tag && selected?.size && (
        <TagAction
          open={isPopupOpen.tag}
          handleClose={() => {
            handleClose('tag');
          }}
          updateFunction={(textTag?: string | null) => {
            updateTag(textTag);
          }}
          textValue={textValue}
          setTextValue={setTextValue}
          tagAutoCompleteID="orderTag"
        />
      )}
      {isPopupOpen.refund && selected?.size && (
        <OrderRefund
          selected={selected}
          handleClose={handleClose}
          refetch={() => {
            setPopupOpen(pre => ({ ...pre, refund: false }));
            searchOrderList();
            resetSelected();
          }}
        />
      )}

      {filterList?.map((cols: string, index: number) => (
        <div
          className="action_filter_columns"
          key={index}
          onClick={() => {
            handleOpen(cols);
          }}>
          {cols}
        </div>
      ))}
    </div>
  );
};
