import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import './style.scss';
import React from 'react';
import { SearchProps } from 'components/transactions';
interface IProps {
  row: SearchProps | null;
}

const NestedTableComponent: React.FC<IProps> = ({ row }) => {
  return (
    <div className="nested_row_store_fron_events">
      <div className="transaction_container">
        <div className="transaction_top_container">
          {row ? (
            <TableContainer className="transactions_table_container">
              <Table
                className="transactions_table event_nested_table"
                aria-labelledby="tableTitle"
                stickyHeader>
                <TableHead className="table_header">
                  <TableRow>
                    {Object.entries(row).map(value => {
                      return (
                        <TableCell className="table_header_cell" key={value[0]}>
                          {value[0][0].toUpperCase() + value[0].slice(1)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {Object.entries(row).map(value => {
                    return (
                      <TableCell className="table_header_cell" key={value[0]}>
                        {value[1]}
                      </TableCell>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="flex-container">No data</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NestedTableComponent;
