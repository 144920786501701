import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  commonError,
  getShippingFrontEP,
  updateShippingEP,
  createShippingEP,
  removeShippingEP,
  searchShippingEP,
} from 'services/constants';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  IShippingDetails,
  IShippingRemove,
  SearchRequestShipping,
} from 'interface/shippingInterface';
import { IShippingUpdate } from 'components/Actions/addShipping';

class ShippingMockService {
  searchShipping = async (
    shippingDetails: SearchRequestShipping,
  ): Promise<
    ISuccessResponse<IShippingDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IShippingDetails>(
        searchShippingEP,
        shippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getShipping = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createShipping = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateShipping = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  removeShipping = async (
    removeShippingDetails: IShippingRemove,
  ): Promise<
    ISuccessResponse<{ message: string }> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IShippingRemove>(
        removeShippingEP,
        removeShippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class ShippingService {
  storesMockService = new ShippingMockService();

  getShipping = async (storeId: string, shippingMethodId: string) => {
    try {
      const response = await baseService.get<IShippingDetails[]>(
        getShippingFrontEP(storeId, shippingMethodId),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createShipping = async (
    shippingDetails: IShippingDetails,
  ): Promise<ISuccessResponse<{ ID: string }> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IShippingDetails>(
        createShippingEP,
        shippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateShipping = async (
    shippingDetails: IShippingUpdate,
  ): Promise<
    ISuccessResponse<IShippingDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IShippingDetails>(
        updateShippingEP,
        shippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchShipping = async (
    shippingDetails: SearchRequestShipping,
  ): Promise<
    ISuccessResponse<IShippingDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IShippingDetails>(
        searchShippingEP,
        shippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  removeShipping = async (
    removeShippingDetails: IShippingRemove,
  ): Promise<
    ISuccessResponse<{ message: string }> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IShippingRemove>(
        removeShippingEP,
        removeShippingDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalShippingService: ShippingService | ShippingMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new ShippingMockService()
    : new ShippingService();

export default globalShippingService;
