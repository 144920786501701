import { FC } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'shared-components';
import 'components/subscriptionProjection/projectionFilter/ActionFilter/style.scss';
import { IProjectioActionFilter } from 'components/subscriptionProjection';
import TextField from 'components/common/textField';

dayjs.extend(utc);

interface Props {
  sideFormFilterSubscription: IProjectioActionFilter;
}
const ActionFilter: FC<Props> = ({ sideFormFilterSubscription }) => {
  const { control } = useForm<IProjectioActionFilter>({
    defaultValues: sideFormFilterSubscription,
  });

  return (
    <div>
      <div className="projection-action-selection">
        <form className="subscription-filter-selection-form">
          <Controller
            name="subscriptionValue"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Subscription Value"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle1"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 1"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle2"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 2"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle3"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 3"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle4"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 4"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle4"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 4"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle5"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 5"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle6"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 6"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Controller
            name="cycle7"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Cycle 7 and Onward Churn"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />

          <Controller
            name="terminalRentention"
            control={control}
            render={({ field: { ref, onChange, ...rest } }) => {
              return (
                <TextField
                  {...rest}
                  label="Terminal Retention"
                  className="filter_form_input"
                  placeholder="Enter the Value"
                  fixedSize="md"
                  onChange={e =>
                    onChange(
                      e.target.value
                        .replace(/[^0-9.]/g, '')
                        .replace(/(\..*?)\..*/g, '$1'),
                    )
                  }
                  ref={ref}></TextField>
              );
            }}
          />
          <Button
            className="subscription_for_submit"
            type="submit"
            label="Apply Filter"
          />
        </form>
      </div>
    </div>
  );
};

export default ActionFilter;
