import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';
import StatusBadge from 'components/common/statusBadge';
import 'components/orders/orderDetails/header/style.scss';

const OrderDetailHeader = () => {
  const orderDetail = useAppSelector(
    (state: RootState) => state.order.orderDetail,
  );

  if (!orderDetail) {
    return null;
  }

  return (
    <div className="order_detail_header">
      {/* <div className="shopify_header">
        Shopify Order ID:{' '}
        <span className="shopify_value">#{orderDetail.external_order_id}</span>
      </div> */}
      <div className="order_header_wrapper">
        <p className="order_id">#{orderDetail.ExternalOrderID}</p>
        <StatusBadge status={orderDetail?.Status} />
        {orderDetail?.Subscription?.Status && (
          <div className="badge_wrapper">
            <p className="badge_label">Subscription:</p>
            <StatusBadge status={orderDetail?.Subscription?.Status} />
          </div>
        )}
        {orderDetail?.Tags?.length > 0 && (
          <div className="badge_wrapper">
            <p className="badge_label">Tags:</p>
            <div className="tags_wrapper">
              {orderDetail?.Tags?.map(tag => {
                return (
                  <span key={tag} className="tag">
                    {tag}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetailHeader;
