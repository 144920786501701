import Editor from '@monaco-editor/react';
import PopUpModal from '../../../components/common/popup';
import React from 'react';

interface MonacoEditorProps {
  handleEditorChange: (_value: string | undefined) => void;
  value: string;
  isPopupOpen: boolean;
  setPopupOpen: (_value: boolean) => void;
  language?: string;
  handleSubmit: () => void;
}
export const MonacoEditor = ({
  value,
  handleEditorChange,
  isPopupOpen,
  setPopupOpen,
  language,
  handleSubmit,
}: MonacoEditorProps) => {
  const [disabled, setDisabled] = React.useState(true);
  return (
    <PopUpModal
      heading="Code Editor"
      open={isPopupOpen}
      handleClose={() => {
        setPopupOpen(false);
      }}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Close',
          buttonFunction: () => {
            setPopupOpen(false);
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Save',
          disabled: disabled,
          buttonFunction: () => {
            setPopupOpen(false);
            handleSubmit();
          },
        },
      ]}>
      <div className="popup-section-preview-table">
        <div className="popup-table">
          <Editor
            height="40vh"
            width="60vw"
            theme="vs-dark"
            language={language ? language : 'javascript'}
            defaultValue={value}
            onChange={val => {
              if (val?.trim() == '') setDisabled(true);
              else {
                setDisabled(false);
                handleEditorChange(val);
              }
            }}
          />
        </div>
      </div>
    </PopUpModal>
  );
};
