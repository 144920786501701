import { ComponentConfig } from '@measured/puck';
import { spacingOptions } from '../../../../page-builder/puck/reusable-props/options';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import CustomTooltip from '../../../../components/common/tooltip';
import ShowComponent, {
  showComponentProps,
} from '../../../puck/reusable-props/showComponent';
import {
  getcurrentDeviceType,
  resolveShowComponentField,
} from '../../../utils/helper';
import { ReduxSelectorProps } from '../../../services/constants';

export type VerticalSpaceProps = {
  size: string;
  backgroundColor: string;
} & showComponentProps;

export const VerticalSpace = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<VerticalSpaceProps> => {
  return {
    label: (
      <CustomTooltip title="VerticalSpace">
        <span>Vertical Space</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...ShowComponent,
      size: {
        type: 'select',
        options: spacingOptions,
      },
      backgroundColor: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="backgroundColor">
                  <span>Background Color</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      size: '24px',
      backgroundColor: 'white',
    },

    resolveFields: ({ props }, { fields }) =>
      resolveShowComponentField(props.id, fields),

    render: ({ size, backgroundColor, showComponent }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div style={{ height: size, width: '100%', backgroundColor }} />
          )}
        </>
      );
    },
  };
};
