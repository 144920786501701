import { ButtonHTMLAttributes, FC } from 'react';
import './style.scss';
import { CircularProgress } from '@mui/material';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: 'phoenix' | 'secondary' | 'phoenix_secondary';
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

const Button: FC<IButtonProps> = ({
  variant,
  label,
  className = '',
  loading = false,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={`button_field_wrapper ${className} ${variant ? 'button_' + variant : ''} ${disabled ? 'button-disabled' : ''}`}
      disabled={disabled}
      {...rest}>
      {loading ? (
        <CircularProgress className="spinner" color="inherit" />
      ) : (
        label
      )}
    </button>
  );
};
export default Button;
