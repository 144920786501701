import { FC, useMemo, useState } from 'react';
import 'components/orders/orderDetails/orderDetailsFilter/actionFilter/style.scss';
import { showNotification } from 'helper/common/commonFunctions';
import { ITransactionHistory } from 'interface/transactionInterface';
import { RootState } from 'redux-setup/store';
import { useAppSelector } from 'hooks/reduxHooks';
// import VoidTransaction from 'components/Actions/voidTransaction';
import RefundTransaction from 'components/Actions/refundTransaction';
import { groupBy } from 'lodash';
import NewRefundTransaction from 'components/Actions/newRefundTransaction';
import { isFeatureEnabled } from 'utils/helper';
import { pageNames } from 'router/constant';

const availableActions = {
  refund: 'Refund',
};
const newRefund = 'newRefund';
interface Iprops {
  refetch: () => void;
  selectedTransaction: Map<number, ITransactionHistory>;
}

const ActionFilter: FC<Iprops> = ({ refetch, selectedTransaction }) => {
  const [isPopupOpen, setPopupOpen] = useState<string | null>(null);
  const [currentTransaction, setCurrentTransaction] =
    useState<ITransactionHistory | null>(null);
  const { orderDetail } = useAppSelector((state: RootState) => state.order);
  const orderTransactions = useAppSelector(
    (state: RootState) => state.order.orderTransactions,
  );

  const handleClose = () => {
    setPopupOpen(null);
    setCurrentTransaction(null);
  };

  const handleOpen = (value: string) => {
    if (selectedTransaction?.size === 1) {
      value = isFeatureEnabled(pageNames.NEW_REFUND) ? 'newRefund' : value;
      setPopupOpen(value);
      if (selectedTransaction) {
        setCurrentTransaction([...selectedTransaction.values()]?.[0]);
      }
    } else if (!selectedTransaction?.size) {
      showNotification('error', 'select a transaction');
    } else {
      showNotification(
        'error',
        'These Action does Not compatible for multiple Actions',
      );
    }
  };

  const checkActions = useMemo(() => {
    const groupedTransactions = groupBy(
      orderTransactions,
      (orderTransaction: ITransactionHistory) => orderTransaction.TransactionID,
    );
    for (const transactionHistory in groupedTransactions) {
      const check = groupedTransactions[transactionHistory].some(
        val => val.Status === 'voided',
      );
      if (check) {
        return true;
      }
    }
    return false;
  }, [orderTransactions]);

  return (
    <div className="action_filter_container">
      {isPopupOpen === availableActions.refund &&
        currentTransaction &&
        orderDetail && (
          <RefundTransaction
            payload={{
              Amount: currentTransaction?.Amount,
              CancelSubscription: false,
              CustomerID: orderDetail?.Customer?.ID,
              StoreID: orderDetail?.Store?.ID,
              TransactionID: currentTransaction?.TransactionID,
            }}
            status={currentTransaction?.Status}
            handleClose={handleClose}
            onApiResponse={isApiSucceeded => {
              if (isApiSucceeded) {
                refetch();
              }
            }}
          />
        )}

      {isPopupOpen === newRefund && currentTransaction && orderDetail && (
        <NewRefundTransaction
          payload={{
            Amount: currentTransaction?.Amount,
            CancelSubscription: false,
            CustomerID: orderDetail?.Customer?.ID,
            StoreID: orderDetail?.Store?.ID,
            TransactionID: currentTransaction?.TransactionID,
            OrderID: orderDetail?.ID,
            ChannelID: orderDetail?.ChannelID,
            ExternalOrderID: orderDetail?.ExternalOrderID,
            TransactionKind: '',
          }}
          handleClose={handleClose}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              refetch();
            }
          }}
        />
      )}
      {checkActions ? (
        <span className="no_actions">No Actions Available</span>
      ) : (
        Object.values(availableActions).map((cols: string, index: number) => (
          <div
            className="action_filter_columns"
            key={index}
            onClick={() => {
              handleOpen(cols);
            }}>
            {cols}
          </div>
        ))
      )}
    </div>
  );
};

export default ActionFilter;
