import 'components/onboarding/style.scss';
import { FormProvider, useForm } from 'react-hook-form';
import WelcomePage from 'components/onboarding/formComponents/welcomePage';
import { useCallback, useState } from 'react';
import StoreDetailsPage from 'components/onboarding/formComponents/storeDetailsPage';
import { IRegisterUser } from 'interface/userInterface';
import userService from 'services/userService';
import storesService from 'services/storeService';
import storefrontService from 'services/storeFrontService';
import { IStoreDetailsForm } from 'interface/storeInterface';
import { useNavigate } from 'react-router-dom';
import { Path } from 'router/constant';
import { setOnboarding } from 'redux-setup/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';
import { IStoreFront } from 'interface/settingsInterface';
import { listMyPermissions } from 'utils/helper';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { logOut } from 'services/authService';

const Onboarding = () => {
  const isOnboarding = useAppSelector(
    (state: RootState) => state.user.isOnboarding,
  );
  const defaultStore = {
    Name: '',
    StoreDescription: '',
    CheckoutURL: '',
  };

  const defaultPaymentGateway = {
    Secret: '',
  };

  const [step, setStep] = useState<number>(isOnboarding ? 0 : 1);
  const url = localStorage.getItem('invite_user_url');

  const welcomePageForm = useForm<IRegisterUser>({
    defaultValues: {
      FirstName: '',
      LastName: '',
      PhoneNumber: '',
    },
  });
  const storeDetailsForm = useForm<IStoreDetailsForm>({
    defaultValues: {
      store: defaultStore,
      storeFront: null,
      payment_gateway: defaultPaymentGateway,
      is_store_skipped: true,
      is_gateway_skipped: true,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token: shopifyToken } = useAppSelector(state => state.shopify);

  const welcomeFormSubmit = async (data: IRegisterUser) => {
    const res = await userService.registerUser(data);
    if (res.status === 200) {
      dispatch(setOnboarding(false));
      if (shopifyToken) navigate(Path.SHOPIFY_SETTINGS);
      if (url) navigate(url);
      else setStep(1);
    }
  };
  const storeDetailsFormSubmit = async (data: IStoreDetailsForm) => {
    // api call to store details
    const res = await storesService.createStore({
      ...data?.store,
    });
    if (res.status === 200) {
      if (res?.data?.ID && !data.is_store_skipped && data?.storeFront?.length) {
        const promise = data?.storeFront?.map(storeFront => {
          const payload: IStoreFront = {
            Name: storeFront?.Name,
            ShopID: storeFront?.ShopID,
            ShopURL: storeFront?.ShopURL,
            ID: res?.data?.ID,
            Version: 0,
            Platform: storeFront.Platform,
            Shopify: {
              ID: storeFront?.Shopify?.ID,
              StorefrontID: storeFront.ID,
              Version: 0,
              AccessToken: storeFront?.Shopify?.AccessToken,
              APIKey: storeFront?.Shopify?.APIKey,
              APISecret: storeFront?.Shopify?.APISecret,
              ShopID: storeFront?.Shopify?.ShopID,
              StorefrontPublicAccessToken:
                storeFront?.Shopify?.StorefrontPublicAccessToken,
            },
            StoreID: res?.data?.ID,
          };
          return storefrontService.createStoreFront(payload);
        });
        await Promise.allSettled(promise);
      }

      if (res?.data?.ID && !data.is_gateway_skipped) {
        await storesService.createLegacyPaymentGateway({
          ...data?.payment_gateway,
          StoreID: res?.data?.ID,
        });
      }
      listMyPermissions();
      navigate(Path.DASHBOARD);
    }
  };

  const renderStepper = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return (
            <form onSubmit={welcomePageForm.handleSubmit(welcomeFormSubmit)}>
              <FormProvider {...welcomePageForm}>
                {<WelcomePage />}
              </FormProvider>
            </form>
          );
        case 1:
          return (
            <form
              onSubmit={storeDetailsForm.handleSubmit(storeDetailsFormSubmit)}>
              <FormProvider {...storeDetailsForm}>
                {
                  <StoreDetailsPage
                    storeDetailsFormSubmit={storeDetailsFormSubmit}
                  />
                }
              </FormProvider>
            </form>
          );
        default:
          return <div>No Step</div>;
      }
    },
    [step],
  );

  const handleClick = () => {
    logOut();
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding-contant">
        <div className="form-group">{renderStepper(step)}</div>
        <p className="text-center my-3">or</p>
        <div
          className="logout_popup flex justify-center gap-3"
          onClick={handleClick}>
          <LogoutIcon />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
