import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  order: '',
  customer: '',
  transaction: '',
  subscription: '',
  events: '',
  superAdmin: false,
};

export const permissionConfigSlice = createSlice({
  name: 'permissionConfig',
  initialState,
  reducers: {
    setPagesPermission: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearPagePermisssion: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setPagesPermission, clearPagePermisssion } =
  permissionConfigSlice.actions;

export default permissionConfigSlice.reducer;
