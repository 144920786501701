import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import globalStoresService from 'services/storeService';

export default function HeaderStoreDetails() {
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState(location.state?.name || '');

  const getStoreById = async (id: string) => {
    const response = await globalStoresService.getStoreById(id);
    if (response.status === 200) {
      setData(response.data.Store.Name);
    }
  };

  useEffect(() => {
    if (!data) {
      getStoreById(params?.storeID || '');
    }
  }, []);

  return <div>Store: {data}</div>;
}
