import { Tooltip } from '@mui/material';
import { useAppSelector } from 'hooks/reduxHooks';
import React from 'react';
import 'components/common/filterAction/style.scss';
interface IAction {
  key: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  permission?: string;
  className?: string;
}

interface Iprops {
  filterAction: IAction[];
  permissionOfPage: string;
  setSelectedFilter: (_param: string) => void;
  onClick?: (_param: string) => void;
  className: string;
}

function FilterAction({
  filterAction,
  permissionOfPage,
  setSelectedFilter,
  className,
  onClick,
}: Iprops) {
  const { currentFilter } = useAppSelector(state => state.pathConfig);

  return (
    <>
      {filterAction.map((item, index) => {
        return (
          (!item?.permission ||
            (item?.permission && permissionOfPage === item.permission)) && (
            <div
              className={`${className} ${
                currentFilter === item.key ? 'active' : ''
              } ${item?.className ? item.className : ''}`}
              key={index}
              onClick={() => {
                setSelectedFilter(item.key);
                if (onClick) {
                  onClick(item.key);
                }
              }}>
              <Tooltip
                className="actions-tooltip"
                title={item.key}
                placement="left"
                arrow>
                <item.icon />
              </Tooltip>
            </div>
          )
        );
      })}
    </>
  );
}

export default FilterAction;
