// import { get } from 'lodash';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactNode, useEffect, useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import globalShippingService from 'services/shippingService';
import {
  IShippingDetails,
  IShippingRemove,
  SearchRequestShipping,
} from 'interface/shippingInterface';
import AddShipping from 'components/Actions/addShipping';
import { useAppSelector } from 'hooks/reduxHooks';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import { showNotification } from 'helper/common/commonFunctions';
import { Loader } from 'components/common/loader';
import CommonTable from 'components/common/TableCommon';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IShippingDetails) => ReactNode;
  hide?: boolean;
}

const defaultShipping: IShippingDetails = {
  ChannelID: '',
  Description: '',
  ID: '',
  Name: '',
  Price: '',
  Version: 0,
};

export interface IShopifyProps {
  storeId?: string;
  shopifyId?: string;
}

export default function Shipping({ shopifyId }: IShopifyProps) {
  const [shippingDetails, setShippingDetails] = useState<IShippingDetails[]>(
    [],
  );
  const [page, setPage] = useState<number>(1);
  const limit = 25;
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteShipping, setDeleteShipping] = useState<IShippingRemove>({
    ShippingMethodIDs: [],
    ChannelID: '',
  });
  const [selectedStore, setSelectedStore] =
    useState<IShippingDetails>(defaultShipping);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [showPopUp, setShowPopUp] = useState<{
    showPopUp: boolean;
    isDelete: boolean;
  }>({ showPopUp: false, isDelete: false });
  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Shipping Name',
      hide: false,
      cellRender: row => {
        return <>{row?.Name}</>;
      },
    },
    {
      id: 'Description',
      label: 'Description',
      hide: false,
      cellRender: row => {
        return <>{row?.Description}</>;
      },
    },
    {
      id: 'Price',
      label: 'Price',
      hide: false,
      cellRender: row => {
        return <>{row?.Price}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="flex gap-2">
              <DeleteIcon
                onClick={() => {
                  setDeleteShipping({
                    ShippingMethodIDs: [row.ID?.toString() || ''],
                    ChannelID: row.ChannelID,
                  });
                  setShowPopUp(() => ({ showPopUp: false, isDelete: true }));
                }}
              />
              <CreateIcon
                onClick={() => {
                  setShowPopUp(() => ({ showPopUp: true, isDelete: false }));
                  setSelectedStore(row);
                }}
              />
            </div>{' '}
          </>
        );
      },
    },
  ];
  const removeShipping = async () => {
    const payload: IShippingRemove = {
      ShippingMethodIDs: deleteShipping.ShippingMethodIDs,
      ChannelID: deleteShipping.ChannelID,
    };
    const res = await globalShippingService.removeShipping(payload);
    setLoading(false);
    if (res?.status === 200) {
      showNotification('success', 'Shipping deleted successfully');
      setShippingDetails([]);
      searchShippings();
    }
    setShowPopUp(val => ({ ...val, isDelete: false }));
  };
  const searchShippings = async () => {
    try {
      if (!shopifyId) return;
      setLoading(true);
      const payload: SearchRequestShipping = {
        ChannelID: shopifyId,
        Descending: false,
        OrderBy: 'Name',
        Limit: limit,
        Page: page - 1,
      };
      const res = await globalShippingService.searchShipping(payload);
      if (res?.data && res?.data?.Result) {
        setShippingDetails(prev => [...prev, ...res.data.Result]);
        setIsNextAPIDisabled(page * limit >= res.data.TotalCount);
      }
    } catch (error) {
      setShippingDetails([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    searchShippings();
  }, [storeIds, page]);

  return (
    <>
      <CommonTable<IShippingDetails>
        addLabelName="Add Shipping"
        isNextAPIDisabled={isNextAPIDisabled}
        headCellList={headCellList}
        data={shippingDetails}
        onAdd={() => {
          setShowPopUp(val => ({
            showPopUp: !val.showPopUp,
            isDelete: false,
          }));
          setSelectedStore({
            ...defaultShipping,
            ChannelID: storeIds?.[0]?.storeFronts?.[0]?.ID,
          });
        }}
        onEndReached={() => {
          setPage(prev => prev + 1);
        }}
      />
      <Loader loading={loading} />
      {showPopUp.showPopUp && (
        <AddShipping
          defaultsValues={{
            ...selectedStore,
            ChannelID: shopifyId ? shopifyId : '',
          }}
          handleClose={() =>
            setShowPopUp(val => ({ ...val, showPopUp: false }))
          }
          onSuccess={() => {
            setShippingDetails([]);
            setPage(1);
            searchShippings();
          }}
        />
      )}
      {showPopUp.isDelete && (
        <ConfirmationDialog
          handleClose={() =>
            setShowPopUp(val => ({ ...val, showPopUp: false }))
          }
          message="delete"
          onSubmit={removeShipping}
          open={showPopUp.isDelete}
          loading={loading}
        />
      )}
    </>
  );
}
