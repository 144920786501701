import { FieldLabel, Fields } from '@measured/puck';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select } from '../../../page-builder/components/form-fields/Select';
import CustomTooltip from '../../../components/common/tooltip';

// Define the types for FontProps and Fields
type FontType = {
  size: string;
  weight: string;
};

export interface FontProps {
  font: FontType;
}

const fontSizeOptions = [
  { label: 'Small', value: 'text-sm' },
  { label: 'Medium', value: 'text-md' },
  { label: 'Large', value: 'text-lg' },
  { label: 'Extra Large', value: 'text-xl' },
  { label: 'Extra Larger', value: 'text-2xl' },
  { label: 'Largest', value: 'text-3xl' },
];

const fontWeightOptions = [
  { label: 'Normal', value: 'font-normal' },
  { label: 'Bold', value: 'font-bold' },
  { label: 'Bolder', value: 'font-extrabold' },
  { label: 'Light', value: 'font-light' },
];

// Font field definition
export const fontField: Fields<FontProps> = {
  font: {
    type: 'custom',
    label: 'Font',
    render: ({ value, onChange }) => {
      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleChange = (
        key: string,
        option: React.ChangeEvent<HTMLSelectElement>,
      ) => {
        onChange({
          ...value,
          [key]: option.target.value,
        });
      };

      return (
        <div>
          <div
            className="flex justify-between font-extrabold cursor-pointer p-3 bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <h3>Customize Fonts</h3>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          {isOpen && (
            <div className="flex flex-col gap-4 mt-3 items-center border border-gray-300 rounded p-2 w-full pagebuilder-theme">
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="fontSize">
                        <span>Font Size</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <Select
                    className="pagebuilder-theme"
                    value={value?.size}
                    onChange={e => handleChange('size', e)}
                    options={fontSizeOptions}
                    label="Size"
                  />
                </FieldLabel>
              </div>
              <div className="border border-gray-300 rounded p-2 w-full pagebuilder-theme">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="fontWeight">
                        <span>Font Size</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <Select
                    value={value?.weight}
                    onChange={e => handleChange('weight', e)}
                    options={fontWeightOptions}
                    label="Weight"
                  />
                </FieldLabel>
              </div>
            </div>
          )}
        </div>
      );
    },
  },
};
