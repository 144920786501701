import NewRefundTransaction from 'components/Actions/newRefundTransaction';
import Transactions from 'components/orders/orderDetails/transactionHistory';
import { useAppDispatch } from 'hooks/reduxHooks';
import { IOrder } from 'interface/orderInterface';
import { ITransactionHistory } from 'interface/transactionInterface';
import React, { useEffect, useState } from 'react';
import { setOrderTransactions } from 'redux-setup/slices/OrderSlice';
import globalOrderService from 'services/orderService';
import { PopUpModal, showNotification } from 'shared-components';

interface IProps {
  selected: Map<string, IOrder>;
  handleClose: (_data: string) => void;
  refetch: () => void;
}

const OrderRefund: React.FC<IProps> = ({ selected, handleClose, refetch }) => {
  const [selectedTransaction, setSelectedTransaction] = useState<
    Map<number, ITransactionHistory>
  >(new Map());
  const [historyLoading, setHistoryLoading] = useState<boolean>(false);
  const [isTransactionPopupOpen, setTransactionPopupOpen] =
    useState<boolean>(false);
  const [orderDetailData, setOrderDetailData] = useState<ITransactionHistory[]>(
    [],
  );

  const orderDetails = [...selected.values()][0];
  const {
    ID: orderID,
    Store: { ID: storeID },
  } = orderDetails;
  const dispatch = useAppDispatch();

  const getOrderHistory = async (
    order_id: string = '',
    store_id: string = '',
  ) => {
    setHistoryLoading(true);
    const orderHistory = await globalOrderService.getOrderHistoryById(
      store_id,
      order_id,
    );

    setOrderDetailData(
      orderHistory?.data?.Result?.length > 0
        ? [...orderHistory.data.Result]
        : [],
    );
    dispatch(
      setOrderTransactions(
        orderHistory?.data?.Result?.length > 0
          ? [...orderHistory.data.Result]
          : [],
      ),
    );
    setHistoryLoading(false);
  };
  const currentTransaction = [...selectedTransaction.values()][0];

  useEffect(() => {
    if (orderID && storeID) {
      getOrderHistory(orderID, storeID);
    }
  }, []);

  return (
    <div>
      <PopUpModal
        open={true}
        handleClose={() => {
          handleClose('refund');
        }}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Okay',
            buttonFunction: () => {
              if (selectedTransaction?.size === 1) {
                setTransactionPopupOpen(true);
                return;
              }
              showNotification(
                'error',
                selectedTransaction?.size > 0
                  ? 'These Action does Not compatible for multiple Actions'
                  : 'select a transaction',
              );
            },
          },
        ]}
        className={'flex-container'}
        heading={'Select Transaction'}>
        <Transactions
          historyLoading={historyLoading}
          setSelectedTransaction={setSelectedTransaction}
          selectedTransaction={selectedTransaction}
          orderDetailData={orderDetailData}
        />
      </PopUpModal>
      <PopUpModal
        open={isTransactionPopupOpen}
        handleClose={() => {
          setTransactionPopupOpen(false);
        }}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Submit',
            buttonFunction: () => {
              handleClose('refund');
            },
          },
        ]}
        className={'flex-container'}
        heading={''}>
        <NewRefundTransaction
          payload={{
            Amount: currentTransaction?.Amount,
            CancelSubscription: false,
            CustomerID: orderDetails?.Customer?.ID,
            StoreID: orderDetails?.Store?.ID,
            TransactionID: currentTransaction?.TransactionID,
            OrderID: orderDetails?.ID,
            ChannelID: orderDetails?.ChannelID,
            ExternalOrderID: orderDetails?.ExternalOrderID,
            TransactionKind: '',
          }}
          handleClose={() => {
            setTransactionPopupOpen(false);
          }}
          onApiResponse={isApiSucceeded => {
            if (isApiSucceeded) {
              refetch();
            }
          }}
        />
      </PopUpModal>
    </div>
  );
};

export default OrderRefund;
