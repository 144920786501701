// import { get } from 'lodash';
import React, { ReactNode, useMemo, useState } from 'react';
import './style.scss';

import { ILegacyPaymentGateway } from 'interface/settingsInterface';

import Tabs from 'components/common/Tabs';
import Gateway from './gateWay';
import MerchantAccount from 'components/paymentGateway/merchantAccount';
import PaymentRouting from 'components/store/storeDetails/PaymentRouting';
import { isFeatureEnabled } from 'utils/helper';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ILegacyPaymentGateway) => ReactNode;
  hide?: boolean;
}
interface IProps {
  storeID: string;
}
export default function PaymentGateway2({ storeID }: IProps) {
  const [tabValue, setTabValue] = useState('1');
  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };

  const tabsList = [
    {
      tabName: 'Gateways',
      element: <Gateway storeID={storeID} />,
      pageName: 'PAYMENT_GATEWAY',
    },
    {
      tabName: 'Processors',
      element: <MerchantAccount storeID={storeID} />,
      pageName: 'PROCESSORS',
    },
    {
      tabName: 'Payment Routing',
      element: <PaymentRouting storeId={storeID} />,
      pageName: 'PAYMENT_ROUTING',
    },
  ];
  const labelsForTab = useMemo(() => {
    return tabsList.filter(val => isFeatureEnabled(val?.pageName));
  }, [tabsList]);
  return (
    <>
      <div className="tab-content">
        <Tabs
          value={tabValue}
          labels={labelsForTab?.map(value => value.tabName)}
          onChange={handleTabChange}
        />
        <div className="tab-content-wrapper">
          {labelsForTab?.map(
            (val, index) => +tabValue === index + 1 && val.element,
          )}
        </div>
      </div>
    </>
  );
}
