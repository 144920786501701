import { createSlice } from '@reduxjs/toolkit';

export interface IInvitationConfig {
  url: string;
}

const initialState: IInvitationConfig = {
  url: '',
};

export const inviteUser = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    setMyInviteUserUrl: (state, action) => {
      return { url: action.payload };
    },
  },
});

export const { setMyInviteUserUrl } = inviteUser.actions;

export default inviteUser.reducer;
