import { createSlice } from '@reduxjs/toolkit';
export interface themeSliceProps {
  theme: boolean;
}
const initialState: themeSliceProps = {
  theme: false,
};
export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      return { ...state, theme: action.payload };
    },
  },
});
// Action creators are generated for each case reducer function
export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
