import { State } from '../../page-builder/interface/dataInterface';

export const usaStates: State[] = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District Of Columbia', code: 'DC' },
  { name: 'Federated States Of Micronesia', code: 'FM' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Guam', code: 'GU' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Palau', code: 'PW' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virgin Islands', code: 'VI' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

export const canadaStates: State[] = [
  { name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland', code: 'NF' },
  { name: 'Northwest Territories', code: 'NT' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Nunavut', code: 'NU' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'PQ' },
  { name: 'Saskatchewan', code: 'SK' },
  { name: 'Yukon', code: 'YT' },
];

export const australiaStates: State[] = [
  { name: 'Australian Capital Territory', code: 'ACT' },
  { name: 'New South Wales', code: 'NSW' },
  { name: 'Northern Territory', code: 'NT' },
  { name: 'Queensland', code: 'QLD' },
  { name: 'South Australia', code: 'SA' },
  { name: 'Tasmania', code: 'TAS' },
  { name: 'Victoria', code: 'VIC' },
  { name: 'Western Australia', code: 'WA' },
];

export const chinaStates: State[] = [
  { name: 'Anhui Province', code: 'AH' },
  { name: 'Beijing Municipality', code: 'BJ' },
  { name: 'Chongqing Municipality', code: 'CQ' },
  { name: 'Fujian Province', code: 'FJ' },
  { name: 'Gansu Province', code: 'GS' },
  { name: 'Guangdong Province', code: 'GD' },
  { name: 'Guangxi Zhuang Autonomous Region', code: 'GX' },
  { name: 'Guizhou Province', code: 'GZ' },
  { name: 'Hainan Province', code: 'HI' },
  { name: 'Hebei Province', code: 'HE' },
  { name: 'Heilongjiang Province', code: 'HL' },
  { name: 'Henan Province', code: 'HA' },
  { name: 'Hong Kong Special Administrative Region', code: 'HK' },
  { name: 'Hubei Province', code: 'HB' },
  { name: 'Hunan Province', code: 'HN' },
  { name: 'Inner Mongolia Autonomous Region', code: 'NM' },
  { name: 'Jiangsu Province', code: 'JS' },
  { name: 'Jiangxi Province', code: 'JX' },
  { name: 'Jilin Province', code: 'JL' },
  { name: 'Liaoning Province', code: 'LN' },
  { name: 'Macau Special Administrative Region', code: 'MO' },
  { name: 'Ningxia Hui Autonomous Region', code: 'NX' },
  { name: 'Qinghai Province', code: 'QH' },
  { name: 'Shaanxi Province', code: 'SN' },
  { name: 'Shandong Province', code: 'SD' },
  { name: 'Shanghai Municipality', code: 'SH' },
  { name: 'Shanxi Province', code: 'SX' },
  { name: 'Sichuan Province', code: 'SC' },
  { name: 'Taiwan Province', code: 'TW' },
  { name: 'Tibet Autonomous Region', code: 'XZ' },
  { name: 'Tianjin Municipality', code: 'TJ' },
  { name: 'Xinjiang Uyghur Autonomous Region', code: 'XJ' },
  { name: 'Yunnan Province', code: 'YN' },
  { name: 'Zhejiang Province', code: 'ZJ' },
];

export const brazilStates: State[] = [
  { name: 'Acre', code: 'AC' },
  { name: 'Alagoas', code: 'AL' },
  { name: 'Amapá', code: 'AP' },
  { name: 'Amazonas', code: 'AM' },
  { name: 'Bahia', code: 'BA' },
  { name: 'Ceará', code: 'CE' },
  { name: 'Distrito Federal', code: 'DF' },
  { name: 'Espírito Santo', code: 'ES' },
  { name: 'Goiás', code: 'GO' },
  { name: 'Maranhão', code: 'MA' },
  { name: 'Mato Grosso', code: 'MT' },
  { name: 'Mato Grosso do Sul', code: 'MS' },
  { name: 'Minas Gerais', code: 'MG' },
  { name: 'Pará', code: 'PA' },
  { name: 'Paraíba', code: 'PB' },
  { name: 'Paraná', code: 'PR' },
  { name: 'Pernambuco', code: 'PE' },
  { name: 'Piauí', code: 'PI' },
  { name: 'Rio de Janeiro', code: 'RJ' },
  { name: 'Rio Grande do Norte', code: 'RN' },
  { name: 'Rio Grande do Sul', code: 'RS' },
  { name: 'Rondônia', code: 'RO' },
  { name: 'Roraima', code: 'RR' },
  { name: 'Santa Catarina', code: 'SC' },
  { name: 'São Paulo', code: 'SP' },
  { name: 'Sergipe', code: 'SE' },
  { name: 'Tocantins', code: 'TO' },
];

export const mexicoStates: State[] = [
  { name: 'Aguascalientes', code: 'AG' },
  { name: 'Baja California', code: 'BC' },
  { name: 'Baja California Sur', code: 'BS' },
  { name: 'Campeche', code: 'CM' },
  { name: 'Chiapas', code: 'CS' },
  { name: 'Chihuahua', code: 'CH' },
  { name: 'Coahuila', code: 'CO' },
  { name: 'Colima', code: 'CL' },
  { name: 'Durango', code: 'DG' },
  { name: 'Federal District', code: 'DF' },
  { name: 'Guanajuato', code: 'GT' },
  { name: 'Guerrero', code: 'GR' },
  { name: 'Hidalgo', code: 'HG' },
  { name: 'Jalisco', code: 'JA' },
  { name: 'México State', code: 'ME' },
  { name: 'Michoacán', code: 'MI' },
  { name: 'Morelos', code: 'MO' },
  { name: 'Nayarit', code: 'NA' },
  { name: 'Nuevo León', code: 'NL' },
  { name: 'Oaxaca', code: 'OA' },
  { name: 'Puebla', code: 'PB' },
  { name: 'Querétaro', code: 'QE' },
  { name: 'Quintana Roo', code: 'QR' },
  { name: 'San Luis Potosí', code: 'SL' },
  { name: 'Sinaloa', code: 'SI' },
  { name: 'Sonora', code: 'SO' },
  { name: 'Tabasco', code: 'TB' },
  { name: 'Tamaulipas', code: 'TM' },
  { name: 'Tlaxcala', code: 'TL' },
  { name: 'Veracruz', code: 'VE' },
  { name: 'Yucatán', code: 'YU' },
  { name: 'Zacatecas', code: 'ZA' },
];

export const malaysiaStates: State[] = [
  { name: "Johor Darul Ta'zim", code: 'JHR' },
  { name: 'Kedah Darul Aman', code: 'KDH' },
  { name: 'Kelantan Darul Naim', code: 'KTN' },
  { name: 'Kuala Lumpur', code: 'KUL' },
  { name: 'Labuan', code: 'LBN' },
  { name: 'Melaka', code: 'MLK' },
  { name: 'Negeri Sembilan Darul Khusus', code: 'NSN' },
  { name: 'Pahang Darul Makmur', code: 'PHG' },
  { name: 'Penang', code: 'PNG' },
  { name: 'Perak Darul Ridzuan', code: 'PRK' },
  { name: 'Perlis Indera Kayangan', code: 'PLS' },
  { name: 'Putrajaya', code: 'PJY' },
  { name: 'Sabah', code: 'SBH' },
  { name: 'Sarawak', code: 'SWK' },
  { name: 'Selangor Darul Ehsan', code: 'SGR' },
  { name: 'Terengganu Darul Iman', code: 'TRG' },
];

export const newZealandStates: State[] = [
  { name: 'Auckland', code: 'AUK' },
  { name: 'Bay of Plenty', code: 'BOP' },
  { name: 'Canterbury', code: 'CAN' },
  { name: 'Gisborne', code: 'GIS' },
  { name: "Hawke's Bay", code: 'HKB' },
  { name: 'Manawatu-Wanganui', code: 'MWT' },
  { name: 'Marlborough', code: 'MBH' },
  { name: 'Nelson', code: 'NSN' },
  { name: 'Northland', code: 'NTL' },
  { name: 'Otago', code: 'OTA' },
  { name: 'Southland', code: 'STL' },
  { name: 'Taranaki', code: 'TKI' },
  { name: 'Tasman', code: 'TAS' },
  { name: 'Waikato', code: 'WKO' },
  { name: 'Wellington', code: 'WGN' },
  { name: 'West Coast', code: 'WTC' },
];

export const southKoreaStates: State[] = [
  { name: 'Cheju-do', code: 'P0' },
  { name: 'Chollabuk-do', code: 'P1' },
  { name: 'Chollanam-do', code: 'P2' },
  { name: `Ch'ungchongbuk-do`, code: 'P3' },
  { name: `Ch'ungch'ongnam-do`, code: 'P4' },
  { name: 'Inchon', code: 'P5' },
  { name: 'Kangwon-do', code: 'P6' },
  { name: 'Kwangju', code: 'P9' },
  { name: 'Kyonggi-do', code: 'PB' },
  { name: 'Kyongsangnam-do', code: 'P7' },
  { name: 'Kyougsangbuk-do', code: 'P8' },
  { name: 'Pusan', code: 'PC' },
  { name: 'Seoul', code: 'PD' },
  { name: 'Taegu', code: 'PF' },
  { name: 'Taejon', code: 'PG' },
  { name: 'Ulsan', code: 'PH' },
];
