import { FC, useEffect } from 'react';
import { ISubscriptionHistory } from 'interface/subscriptionInterface';
import 'components/customers/customerDetails/tabs/subscriptionHistory/style.scss';
import { formattedDate } from 'utils/helper';
import { Loader } from 'components/common/loader';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setPageConfig } from 'redux-setup/slices/pageConfigSlice';

interface IProps {
  subscriptionHistoryData: ISubscriptionHistory | null;
  getSubscriptionsHistory: () => void;
  loading: number;
}

const SubscriptionHistory: FC<IProps> = ({
  subscriptionHistoryData,
  getSubscriptionsHistory,
  loading,
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    getSubscriptionsHistory();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);

  return (
    <div className="Subscription-history-container">
      {subscriptionHistoryData?.SubscriptionEvents?.length && (
        <div className="history-content">
          {subscriptionHistoryData?.SubscriptionEvents?.map(
            (eachHistory, index) => (
              <div className="history-wrap" key={`history-${index}`}>
                <span className="time-wrapper">
                  {formattedDate(eachHistory?.Date) || ''}
                  <div className="circle"></div>
                </span>
                <span className="history-wrapper">
                  <span className="heading-wrap">
                    {eachHistory?.EventType || ''}
                  </span>
                  <span>{eachHistory?.Details || ''}</span>
                </span>
              </div>
            ),
          )}
        </div>
      )}
      <Loader loading={!loading} />
    </div>
  );
};

export default SubscriptionHistory;
