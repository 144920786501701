import React, { useCallback } from 'react';
import { PlusIcon, MinusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import checkoutService from '../../../api/checkoutService';
import {
  LineItem,
  CheckoutUpdateItemsPayload,
  V2ShippingMethod,
} from '../../../interface/checkoutInterface';
import { formatPrice } from '../../../utils/formatPrice';
import { setIsCartLoading } from '../../../redux/slices/cartSlice';
import { setAvailableShippingMethods } from '../../../redux/slices/checkoutSlice';
import { setCartData } from '../../../redux/slices/cartSlice';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../../services/constants';

interface CartItemProps extends ReduxDispatchProps, ReduxSelectorProps {
  product: LineItem;
}

export const CartItem: React.FC<CartItemProps> = ({
  product,
  useAppDispatch,
  useAppSelector,
}) => {
  const dispatch = useAppDispatch();
  const storeData = useAppSelector(state => state.store.storeData);
  const cartData = useAppSelector(state => state.cart?.cartData);

  const updateCartItems = useCallback(
    async (quantity: number) => {
      const lineItems = [
        ...(cartData?.LineItems ?? []).map((item: LineItem) => ({
          ID: item.ID,
          Quantity: item.Quantity,
          VariantID: item.VariantID,
        })),
      ];
      const payload = {
        ID: cartData?.ID,
        LineItems: lineItems.map(item => {
          if (item.ID === product.ID) {
            return {
              ...item,
              Quantity: quantity,
            };
          }
          return item;
        }, []),
        ShopID: storeData?.ShopID,
        StoreID: storeData?.StoreID,
      };
      try {
        dispatch(setIsCartLoading(true));
        const res = await checkoutService.checkoutUpdateItems(
          payload as CheckoutUpdateItemsPayload,
        );
        dispatch(setCartData(res?.data.Cart));
        dispatch(
          setAvailableShippingMethods(
            res?.data?.Cart?.AvailableShippingMethods as V2ShippingMethod[],
          ),
        );
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsCartLoading(false));
      }
    },
    [cartData, product.ID, storeData?.ShopID, storeData?.StoreID, dispatch],
  );

  const handleDecrease = async () => {
    await updateCartItems(product.Quantity - 1);
  };

  const handleIncrease = async () => {
    await updateCartItems(product.Quantity + 1);
  };

  const handleRemove = async () => {
    await updateCartItems(0);
  };

  return (
    <li key={product.ProductID} className="flex items-start space-x-4 py-6">
      <div style={{ position: 'relative' }}>
        <img
          width={500}
          height={500}
          src={product.ImageURL}
          alt={product.Name}
          className="w-20 flex-none rounded-md object-cover object-center aspect-square"
        />
        <div className="absolute top-0 right-0 min-w-5 min-h-5 px-1 py-0.5 rounded-full bg-page_builder_theme text-white flex items-center justify-center text-xs -mt-2 -mr-2">
          {product.Quantity}
        </div>
      </div>
      <div className="flex-auto space-y-1">
        <h3>{product.Name}</h3>
        <p className="text-gray-500">{product.Subtitle}</p>
        <span className="isolate inline-flex rounded-md shadow-sm mt-4">
          <button
            type="button"
            onClick={handleDecrease}
            className="relative inline-flex items-center rounded-l-md bg-white px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <span className="sr-only">Previous</span>
            <MinusIcon className="h-6 w-10 lg:h-4 lg:w-6" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={handleIncrease}
            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-1 py-1 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            <span className="sr-only">Next</span>
            <PlusIcon className="h-6 w-10 lg:h-4 lg:w-6" aria-hidden="true" />
          </button>
        </span>
      </div>

      <div>
        {product.DiscountAmount && product?.DiscountAmount !== '0.00' ? (
          <>
            <p className="flex-none text-base font-medium">
              $
              {formatPrice(
                (parseFloat(product.UnitPrice) * product.Quantity).toString(),
              )}
            </p>
            <p className="flex-none text-base font-medium">
              <span style={{ textDecoration: 'line-through', color: 'gray' }}>
                $
                {formatPrice(
                  (
                    parseFloat(product.OriginalPrice) * product.Quantity
                  ).toString(),
                )}
              </span>
            </p>
          </>
        ) : (
          <p className="flex-none text-base font-medium">
            $
            {formatPrice(
              (parseFloat(product.UnitPrice) * product.Quantity).toString(),
            )}
          </p>
        )}
      </div>
      <button
        type="button"
        onClick={handleRemove}
        className="text-gray-400 hover:text-gray-500">
        <span className="sr-only">Remove</span>
        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </li>
  );
};
