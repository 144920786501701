function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
}

export function getFbValues() {
  const timestampUnix = new Date().getTime().toString();
  const _fbc = typeof window !== 'undefined' ? getCookie('_fbc') : null;
  const _fbp = typeof window !== 'undefined' ? getCookie('_fbp') : null;

  return { timestampUnix, _fbc, _fbp };
}
