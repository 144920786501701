import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as Phoenix } from 'assets/icons/phoenix_icon.svg';
import { Button } from 'shared-components';
import { useAppDispatch } from 'hooks/reduxHooks';
import {
  setEmail,
  setOnboarding,
  setToken,
  setIsDisclosed,
} from 'redux-setup/slices/userSlice';
import OTPInput from 'react-otp-input';
import { ReactComponent as BackArrow } from 'assets/icons/tabler_arrow-left.svg';
import 'components/loginVerification/style.scss';
import { FC, useState } from 'react';
import {
  login,
  loginVerification,
  setTokensInStorage,
} from 'services/authService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Path } from 'router/constant';
import { staticText } from 'layouts/constant';
import { listMyPermissions } from 'utils/helper';

interface ILoginForm {
  otpToken: string;
}

interface IProps {
  Email: string;
}

const LoginVerification: FC<IProps> = ({ Email }) => {
  const [loading, setLoading] = useState(false);
  const url = localStorage.getItem('invite_user_url');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<ILoginForm>({
    defaultValues: {
      otpToken: '',
    },
  });

  const onSubmit = async (data: ILoginForm) => {
    setLoading(true);
    const response = await loginVerification({
      Email,
      Token: data.otpToken,
    });
    setLoading(false);
    if (response?.status === 200) {
      const disclosure = localStorage.getItem('disclosure')
        ? JSON.parse(localStorage.getItem('disclosure')!)
        : null;
      if (
        !disclosure?.isDisclosed ||
        disclosure?.text !== btoa(staticText.join(' '))
      )
        dispatch(setIsDisclosed(true)); // to show disclaimer on login
      setTokensInStorage(response.data);
      if (!response.data.IsRegistered) {
        dispatch(setOnboarding(true));
        if (url) localStorage.setItem('navigate_url', url);
        navigate(Path.ON_BOARDING);
      } else {
        await listMyPermissions();
      }
      dispatch(setToken(response?.data?.RefreshToken));
      dispatch(setEmail(''));
      window.parent.postMessage(
        {
          loginData: response?.data?.RefreshToken,
        },
        '*',
      );
    } else {
      toast.error('Failed To Login');
    }
  };

  const handleClickBack = () => {
    dispatch(setEmail(''));
  };

  const handleResend = async () => {
    if (loading) return;
    setLoading(true);
    const response = await login({ Email });
    setLoading(false);
    if (response.status === 200) toast.success('OTP Sent Successfully');
    else toast.error('Retry Failed');
  };

  return (
    <div className="login-verification-container">
      <div className="title-login-container">
        <Phoenix className="title-icon" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-verification-form">
          <p className="form-text">Enter the 6-digit code sent to you at:</p>
          <p className="form-text">{Email}</p>
          <Controller
            name="otpToken"
            control={control}
            rules={{
              minLength: 6,
              required: true,
            }}
            render={({ field: { onChange, value } }) => {
              const handlechange = (e: string) => {
                onChange(e);
                // if the user enter all the otp number it will auto trigger the submit
                if (e.length === 6) {
                  handleSubmit(data => onSubmit(data))();
                }
              };
              return (
                <OTPInput
                  onChange={handlechange}
                  value={value}
                  numInputs={6}
                  shouldAutoFocus={true}
                  containerStyle="otp-container"
                  inputStyle="otp-input-styles"
                  renderInput={props => <input {...props} disabled={loading} />}
                />
              );
            }}
          />
          <p className="verification-tip">
            Tip: Make sure to check your inbox and spam folders
          </p>
          <Button
            disabled={loading}
            loading={loading}
            className="login-verify-submit"
            variant="phoenix"
            type="submit"
            label="VERIFY"
          />
          <div className="login-verify-footer">
            <div className="back-to-login" onClick={handleClickBack}>
              <BackArrow />
              <p>Back</p>
            </div>
            <div className="resend-otp">
              Didnt receive a code?&nbsp;
              <span className="resend-otp-link" onClick={handleResend}>
                Click here to resend
              </span>
            </div>
          </div>
        </div>
        <div className="animated-circle right-circle"></div>
        <div className="animated-circle top-circle"></div>
        <div className="animated-circle bottom-circle"></div>
      </form>
    </div>
  );
};

export default LoginVerification;
