import { FieldLabel, Fields } from '@measured/puck';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletIcon from '@mui/icons-material/Tablet';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import Tooltip from '@mui/material/Tooltip';
import CustomTooltip from '../../../components/common/tooltip';

export type showComponentProps = {
  showComponent?: string[];
};

type Props = {
  showComponent?: string[];
};

const showComponent: Fields<Props> = {
  showComponent: {
    type: 'custom',
    render: ({ value, onChange }) => {
      return (
        <FieldLabel
          label={
            (
              <CustomTooltip title="Show">
                <span>Display</span>
              </CustomTooltip>
            ) as React.ReactNode as string
          }
          el="div">
          <ToggleButtonGroup
            value={value}
            onChange={(_e, value) => onChange(value)}
            className="flex w-full"
            aria-label="text formatting">
            <ToggleButton
              value="mobile"
              size="small"
              className="flex-1"
              disableRipple>
              <Tooltip title="Mobile" arrow>
                <div
                  className={`${value?.includes('mobile') ? '' : 'icon-container'}`}>
                  <PhoneAndroidIcon />
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="tablet"
              size="small"
              className="flex-1"
              disableRipple>
              <Tooltip title="Tablet" arrow>
                <div
                  className={`${value?.includes('tablet') ? '' : 'icon-container'}`}>
                  <TabletIcon />
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="desktop"
              size="small"
              className="flex-1"
              disableRipple>
              <Tooltip title="Desktop" arrow>
                <div
                  className={`${value?.includes('desktop') ? '' : 'icon-container'}`}>
                  <PersonalVideoIcon />
                </div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </FieldLabel>
      );
    },
  },
};

export default showComponent;
