// termsAndSubSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TermsState {
  isTermsAccepted: boolean;
  isTermsAndSubscriptionInPublishPage: boolean;
}

const initialState: TermsState = {
  isTermsAccepted: false,
  isTermsAndSubscriptionInPublishPage: false,
};

const termsAndSubSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setIsTermsAccepted: (state, action: PayloadAction<boolean>) => {
      state.isTermsAccepted = action.payload;
    },
    setIsTermsAndSubscriptionInPublishPage: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isTermsAndSubscriptionInPublishPage = action.payload;
    },
  },
});

export const { setIsTermsAccepted, setIsTermsAndSubscriptionInPublishPage } =
  termsAndSubSlice.actions;

export default termsAndSubSlice.reducer;
