import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import DatePicker from 'components/common/datePicker';
import utc from 'dayjs/plugin/utc';
import dayjs, { Dayjs } from 'dayjs';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';
import { Controller, useForm } from 'react-hook-form';
dayjs.extend(utc);

interface IProps {
  payload: ISubscriptionManagementUpdateBody | null;
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
}

interface INextBillingDate {
  NextBillingDate: Dayjs;
}

const ReactivateSubscription: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<INextBillingDate>({
    defaultValues: { NextBillingDate: dayjs(payload?.NextBillingDate).tz() },
  });

  const reactivateSubscription = async (data: INextBillingDate) => {
    if (!payload) return;
    setLoading(true);
    payload.NextBillingDate = data.NextBillingDate.format();
    payload.Status = SubscriptionStatus?.subscriptionStatusActive;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification(
        'success',
        'Reactivated Subscription updated successfully',
      );
      handleClose();
      onApiResponse(true);
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Confirm',
            form: 'reactivateSubscription',
            disabled: loading,
            loading,
          },
        ]}
        heading={'Re-activate Subscription'}
        className="flex-container">
        <div className="popup-section">
          <form
            id="reactivateSubscription"
            onSubmit={handleSubmit(reactivateSubscription)}
            className="order-detail_refund">
            <div className="fields">
              <Controller
                name="NextBillingDate"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      label="Re-activate Date"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                rules={{
                  validate: value => {
                    if (value?.date()) {
                      return true;
                    }
                    return 'Invalid date';
                  },
                  required: 'Re-activate Date is required',
                }}
              />
            </div>
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default ReactivateSubscription;
