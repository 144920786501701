import { ReactNode, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { get } from 'lodash';
import 'components/orders/style.scss';
import { Checkbox } from 'shared-components';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import {
  IOrder,
  IOrderFormFilter,
  IOrderTableFilter,
  ISearchOrderBody,
} from 'interface/orderInterface';
import {
  formattedDate,
  getTotalPages,
  iterateHeadCellKeys,
  setPaginationCommon,
} from 'utils/helper';
import StatusBadge from 'components/common/statusBadge';
import dayjs from 'dayjs';
import { getCustomerDetailsPath, getOrderDetialsPath } from 'router/constant';
import SideFilter from 'components/orders/ordersFilter/sideFilter';
import Cart from 'components/orders/cart';
import orderService from 'services/orderService';
import { Loader } from 'components/common/loader';
import { useSearchParams } from 'react-router-dom';
import Link from 'components/common/link';
import SearchFilter from 'components/common/searchFilter';
import { SearchProps } from 'components/transactions';
import globalOrderService from 'services/orderService';
import PaginationCommon from 'components/common/PaginationCommon';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: IOrder) => ReactNode;
  hide?: boolean;
  valueGetter?: (_row: IOrder) => string | number;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

export const defaultOrderFilterValues: IOrderFormFilter = {
  channels: [],
  endTime: null,
  externalOrderID: '',
  maxAmount: '',
  minAmount: '',
  startTime: null,
  status: [],
  store_filter: {
    store_ids: [],
  },
  tags: [],
  customerEmails: [],
  PhoneNumbers: [],
  FirstName: '',
  LastName: '',
};

export default function Orders() {
  const dispatch = useAppDispatch();
  const { orderColumnFilter } = useAppSelector(state => state.coloumnFilter);
  const [search, setSearchValue] = useState<SearchProps>({});
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [paginationMap, setPaginationMap] = useState<Map<number, IOrder[]>>(
    new Map(),
  );
  const headCells: readonly HeadCell[] = [
    {
      id: 'Date',
      label: 'Date',
      key: 'date',
      hide: false,
      cellRender: row => {
        return (
          <Tooltip title={formattedDate(row?.Date)} placement="top" arrow>
            <span>{formattedDate(row?.Date, true)}</span>
          </Tooltip>
        );
      },
      showSortIcon: true,
    },
    {
      id: 'ExternalOrderID',
      label: 'Order ID',
      key: 'order_id',
      cellRender: row => {
        return (
          <Link to={getOrderDetialsPath(row.ID, row.Store.ID)}>
            {row?.ExternalOrderID ?? ''}
          </Link>
        );
      },
      hide: false,
      showSortIcon: false,
      showInSearch: true,
    },
    {
      id: 'Store.Name',
      label: 'Store',
      key: 'store',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'ShippingAddress.FirstName',
      label: 'Customer',
      key: 'customer',
      hide: false,
      cellRender: row => {
        return (
          <Link
            to={getCustomerDetailsPath(
              row?.Customer?.ID,
              row?.Store?.ID,
            )}>{`${row?.ShippingAddress.FirstName ?? ''} ${row?.ShippingAddress?.LastName ?? ''}`}</Link>
        );
      },
      valueGetter: row => {
        return `${row?.ShippingAddress.FirstName ?? ''} ${row?.ShippingAddress.LastName ?? ''}`;
      },
      showSortIcon: false,
    },
    {
      id: 'Channel.Name',
      label: 'Channel',
      key: 'channel',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'TotalAmount',
      label: 'Total',
      key: 'total',
      valueGetter: row => {
        return `$${Number(row?.TotalAmount).toFixed(2)}`;
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'Status',
      label: 'Payment Status',
      key: 'payment_status',
      cellRender: row => {
        return <StatusBadge status={row.Status} />;
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Items',
      label: 'Cart',
      key: 'cart',
      cellRender: row => {
        return <Cart row={row} />;
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'Status',
      label: 'Fulfillment Status',
      key: 'fulfillment_status',
      cellRender: row => {
        return (
          <StatusBadge
            status={row.Status === 'success' ? 'fulfilled' : 'unfulfilled'}
          />
        );
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'TrafficChannel',
      label: 'Traffic Channel',
      key: 'traffic_channel',
      cellRender: row => {
        return <p>{row?.TrafficChannel || '-'}</p>;
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'Tags',
      label: 'Tags',
      key: 'tags',
      cellRender: row => {
        return (
          <div className="tags_wrapper">
            {row?.Tags?.map(tag => {
              return (
                <span key={tag} className="tag">
                  {tag}
                </span>
              );
            })}
          </div>
        );
      },
      valueGetter: row => {
        return row?.Tags?.join(', ');
      },
      hide: false,
      showSortIcon: false,
    },
  ];
  const [loading, setLoading] = useState<number>(0);
  const [ordersList, setOrdersList] = useState<IOrder[]>([]);
  const [selected, setSelected] = useState<Map<string, IOrder>>(new Map());
  const rowsPerPage = 25;
  const [headCellList, setHeadCellList] = useState<HeadCell[]>([
    ...headCells.map(value => {
      return {
        ...value,
        hide: orderColumnFilter?.includes(value.label),
      };
    }),
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const [sideFormFilter, setSideFormFilter] = useState<IOrderFormFilter>({
    channels: searchParams.get('channels')
      ? JSON.parse(searchParams.get('channels') || '')
      : defaultOrderFilterValues.channels,
    customerEmails: searchParams?.get('customer_emails')
      ? JSON.parse(searchParams.get('customer_emails') || '')
      : defaultOrderFilterValues.customerEmails,
    PhoneNumbers: searchParams?.get('phone_numbers')
      ? JSON.parse(searchParams.get('phone_numbers') || '')
      : defaultOrderFilterValues.PhoneNumbers,
    FirstName: searchParams?.get('first_name')
      ? JSON.parse(searchParams.get('first_name') || '')
      : defaultOrderFilterValues.FirstName,
    LastName: searchParams?.get('last_name')
      ? JSON.parse(searchParams.get('last_name') || '')
      : defaultOrderFilterValues.LastName,
    endTime: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : defaultOrderFilterValues.endTime,
    externalOrderID:
      searchParams.get('external_order_id') ||
      defaultOrderFilterValues.externalOrderID,
    maxAmount:
      searchParams.get('max_amount') || defaultOrderFilterValues.maxAmount,
    minAmount:
      searchParams.get('min_amount') || defaultOrderFilterValues.minAmount,
    startTime: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : defaultOrderFilterValues.startTime,
    status: searchParams.get('status')
      ? JSON.parse(searchParams.get('status') || '')
      : defaultOrderFilterValues.status,
    store_filter: {
      store_ids: searchParams.get('store_ids')
        ? JSON.parse(searchParams.get('store_ids') || '')
        : defaultOrderFilterValues.store_filter.store_ids,
    },

    tags: [],
  });
  const filteredField = iterateHeadCellKeys(headCellList);
  const [page, setPage] = useState<number>(
    Number(searchParams.get('page_count') || 1),
  );
  const [filter, setFilter] = useState<IOrderTableFilter>({
    Descending: searchParams.get('descending') === 'false' ? false : true,
    OrderBy: searchParams.get('order_by') || 'Date',
    Limit: limit,
  });

  const selectAll = (checked: boolean) => {
    if (checked) {
      const allRows = new Map(
        ordersList.map(row => {
          return [row.ID, row];
        }),
      );
      setSelected(allRows);
    } else {
      setSelected(new Map());
    }
  };

  const handleRowChecked = (order: IOrder, checked: boolean) => {
    if (checked) {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(order.ID);
        return newSelected;
      });
    } else {
      setSelected(pre => {
        const newSelected = new Map(pre);
        newSelected.set(order.ID, order);
        return newSelected;
      });
    }
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      channels,
      status,
      store_filter: { store_ids },
      maxAmount,
      minAmount,
      externalOrderID,
      customerEmails,
      PhoneNumbers,
      FirstName,
      LastName,
      endTime,
      startTime,
      tags,
    } = sideFormFilter;
    const channelsValues = channels.map(channel => channel.value);
    const statusValues = status.map(temp => temp.value);
    const { Descending, OrderBy } = filter;
    const payload: ISearchOrderBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (isSetSearchParams) {
      setSearchParams(
        {
          store_ids: store_ids ? JSON.stringify(store_ids) : [],
          status: status ? JSON.stringify(status) : '',
          external_order_id: externalOrderID || '',
          channels: channels ? JSON.stringify(channels) : '',
          customer_emails: customerEmails ? JSON.stringify(customerEmails) : '',
          phone_numbers: PhoneNumbers ? JSON.stringify(PhoneNumbers) : '',
          first_name: FirstName ? JSON.stringify(FirstName) : '',
          last_name: LastName ? JSON.stringify(LastName) : '',
          start_time: startTime ? startTime.tz().format() : '',
          end_time: endTime ? endTime.tz().format() : '',
          min_amount: minAmount || '',
          max_amount: maxAmount || '',
          page_count: String(page),
          desending: String(Descending),
          order_by: OrderBy,
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    if (!payload.SearchFields) {
      payload.SearchFields = {};
    }

    if (statusValues.length > 0) {
      payload.Status = statusValues;
    }
    if (tags.length > 0) {
      payload.Tags = tags;
    }
    if (customerEmails.length > 0) {
      payload.CustomerEmails = customerEmails;
    }
    if (PhoneNumbers.length > 0) {
      payload.PhoneNumbers = PhoneNumbers;
    }
    if (FirstName) {
      payload.FirstName = FirstName;
    }
    if (LastName) {
      payload.LastName = LastName;
    }
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length > 0) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (maxAmount) {
      payload.MaxAmount = Number(maxAmount);
    }
    if (minAmount) {
      payload.MinAmount = Number(minAmount);
    }
    if (externalOrderID) {
      payload.ExternalOrderID = externalOrderID;
    }
    if (channelsValues.length > 0) {
      payload.TrafficChannels = channelsValues;
    }
    if (endTime) {
      payload.EndTime = endTime.tz().add(1, 'day').startOf('day').format();
    }
    if (startTime) {
      payload.StartTime = startTime.tz().startOf('day').format();
    }
    payload.SearchFields = {
      ...payload.SearchFields,
      ...(search || {}),
    };
    return payload;
  };

  const downloadCsvFile = async () => {
    setLoading(pre => pre + 1);
    const payload = generatePayload(false);
    payload.Page = 0;
    payload.Limit = 25;
    const response = await globalOrderService.exportTransaction(payload);
    if (response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders.csv');
      document.body.appendChild(link);
      link.click();
    }
    setLoading(pre => pre - 1);
  };

  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const searchOrderList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(pre => pre + 1);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload = generatePayload();
    const res = await orderService.searchOrders({
      ...payload,
      Page: page,
      Limit: limit * totalValueForPagination,
    });
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        // setOrdersList(res?.data?.Result);
        if (details?.reset ?? true) {
          const data = res?.data?.Result.slice(0, limit);
          setOrdersList(data);
        }
        setPaginationCommon(
          res?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setPaginationMap,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setOrdersList([]);
        setPaginationMap(new Map());
      }
    } else {
      setOrdersList([]);
      setPaginationMap(new Map());
    }
    setLoading(pre => pre - 1);
  };

  const filterSubmission = (formData: IOrderFormFilter) => {
    if (!loading) {
      setSideFormFilter(formData);
      setPage(1);
    }
  };

  useEffect(() => {
    searchOrderList();
  }, [sideFormFilter, filter, limit, search, storeIds, timeZone]);

  useEffect(() => {
    page !== 1 && searchOrderList({ reset: false, isPrevious: false });
  }, [page]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  return (
    <div className="orders_wrapper">
      <div className="order">
        <div className="order_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer className="orders_table_container Common_Table">
          <Table
            className="orders_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                <TableCell className="table_header_cell">
                  <div className="flex-row">
                    <Checkbox
                      checked={
                        ordersList?.length > 0 &&
                        selected.size === ordersList?.length
                      }
                      onChange={(_, checked) => selectAll(checked)}
                    />
                  </div>
                </TableCell>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {ordersList?.length > 0 ? (
                ordersList.map((row, index) => {
                  const isItemSelected = !!selected.get(row.ID);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      className="table_row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ID}
                      selected={isItemSelected}>
                      <TableCell padding="checkbox" className="table_cell">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleRowChecked(row, isItemSelected)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_cell"
                            key={headCell.label}
                            component="th"
                            id={headCell.key}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : headCell?.valueGetter
                                ? headCell?.valueGetter(row)
                                : get(row, headCell.id)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          active={active}
          setActive={setActive}
          loading={!!loading}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationMap}
          setPaginationData={setPaginationMap}
          searchApi={searchOrderList}
          setData={setOrdersList}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
      <SideFilter
        headCellList={headCellList}
        setHeadCellList={setHeadCellList}
        selected={selected}
        loading={loading}
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        searchOrderList={searchOrderList}
        resetSelected={() => setSelected(new Map())}
        exportList={downloadCsvFile}
      />
    </div>
  );
}
