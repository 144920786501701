import React, { FC, useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover, TextareaAutosize } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { IGetNotes } from 'interface/notesInterface';
import 'components/customers/customerDetails/tabs/notestab/style.scss';
import { Loader } from 'components/common/loader';
import { formattedDate } from 'utils/helper';

interface INotesTab {
  notes: IGetNotes[] | null;
  handleAddNotes: (_message: string) => void;
  handleEditNotes: (_editMessage: string, _data: IGetNotes) => void;
  getNotesDetails: () => void;
  deleteNote: (_id: string) => void;
  loading: number;
}

const NotesTab: FC<INotesTab> = ({
  notes,
  handleAddNotes,
  getNotesDetails,
  handleEditNotes,
  deleteNote,
  loading,
}) => {
  const [message, setMessage] = useState<string>('');
  const [editMessage, setEditMessage] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedNote, setSelectedNote] = useState(0);
  // Function to handle opening the popover
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor position to the clicked button
  };

  // Function to handle closing the popover
  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };

  useEffect(() => {
    if (!notes) {
      getNotesDetails();
    }
  }, []);

  return (
    <div className="notes-wrapper">
      <div className="addnotes-container">
        <textarea
          className="addnotes-box"
          placeholder="Add Note "
          value={message}
          onChange={e => setMessage(e.target.value)}></textarea>
        <button
          className="addnotes-button"
          type="button"
          onClick={() => {
            handleAddNotes(message);
            setMessage('');
          }}>
          <AddIcon />
          <span>Add Note</span>
        </button>
      </div>

      <div className="notes-body">
        {notes?.map((data: IGetNotes, index: number) => {
          return (
            <div className="notes-wrap" key={index}>
              <div className="title">
                <div className="notes-title">
                  {data.User.FirstName}{' '}
                  <span className="created-at-date">
                    {formattedDate(data.CreatedAt)}
                  </span>
                </div>

                <div>
                  <button
                    id={data.ID}
                    className="menu-button"
                    onClick={handleOpenPopover}>
                    <MoreVertIcon />
                  </button>
                  {anchorEl && data.ID === anchorEl.id && (
                    <Popover
                      anchorEl={anchorEl}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      className="carts-popover"
                      anchorPosition={{ top: 1000, left: 500 }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleClosePopover}>
                      <div className="notes-pop-content">
                        <div className="popover-child">
                          <span
                            className="child-elements"
                            onClick={() => {
                              setIsEdit(true);
                              setSelectedNote(index);
                              setIsFocused(true);
                              handleClosePopover();
                            }}>
                            Edit
                          </span>
                          <span
                            className="child-elements"
                            onClick={() => {
                              deleteNote(data.ID);
                              handleClosePopover();
                            }}>
                            Delete
                          </span>
                        </div>
                      </div>
                    </Popover>
                  )}
                </div>
              </div>
              {selectedNote === index && isEdit ? (
                <TextareaAutosize
                  className={`notes-para text-notes-para addnotes-box ${isFocused ? 'focused' : ''}`}
                  aria-label="empty textarea"
                  placeholder="Empty"
                  defaultValue={data.Note}
                  onFocus={() => setIsFocused(true)}
                  onChange={e => setEditMessage(e.target.value)}
                  onBlur={() => {
                    setIsFocused(false);
                    handleEditNotes(editMessage, data);
                    setIsEdit(false);
                  }}
                />
              ) : (
                <pre className="notes-para">{data.Note}</pre>
              )}
            </div>
          );
        })}
      </div>
      <Loader loading={!loading} />
    </div>
  );
};

export default NotesTab;
