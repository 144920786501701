import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  Filler,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  filledLineChartoptions,
  lineChartoptions,
  cardLineChartoptions,
  groupedLineChartoptions,
} from 'components/common/chart/lineChart/constant';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

interface Iprops {
  data: ChartData<'line', (number | Point | null)[]>;
  varient?: 'filled' | 'cardChart';
  [_: string]: unknown;
  prefix?: string;
  suffix?: string;
  labelPrefix?: string;
  labelSuffix?: string;
  isLabelRequired?: boolean;
  hasPercentageValue?: boolean;
  isPercentagedata?: boolean;
  disputeOverTime?: string;
  maxYLabel?: number;
  options?: ChartOptions<'line'>;
  customPrefixSuffix?: {
    [key: string]: {
      prefix?: string;
      suffix?: string;
    };
  };
}

const LineChart = ({ data, varient, options, ...rest }: Iprops) => {
  return (
    <Line
      options={(() => {
        let chattOption = {};
        switch (varient) {
          case 'filled':
            chattOption = filledLineChartoptions;
            break;
          case 'cardChart':
            chattOption = cardLineChartoptions;
            break;
          default:
            chattOption = lineChartoptions;
            break;
        }
        return {
          ...chattOption,
          ...options,
        };
      })()}
      data={data}
      {...rest}
    />
  );
};

export const GroupedLineChart = ({
  data,
  prefix = '',
  suffix = '',
  labelPrefix = '',
  labelSuffix = '',
  isLabelRequired = false,
  hasPercentageValue = false,
  options = {},
  isPercentagedata = false,
  disputeOverTime = '',
  customPrefixSuffix,
  maxYLabel,
  ...rest
}: Iprops) => {
  return (
    <Line
      options={{
        ...groupedLineChartoptions({
          prefix,
          suffix,
          labelPrefix,
          maxYLabel,
          labelSuffix,
          isLabelRequired,
          hasPercentageValue,
          isPercentagedata,
          disputeOverTime,
          customPrefixSuffix,
        }),
        ...options,
      }}
      data={data}
      {...rest}
    />
  );
};

export default LineChart;
