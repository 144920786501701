import { FormData } from '../interface/checkoutInterface';

export const Path = {
  // Page Builder routes
  PAGE_BUILDER_EDIT: '/page-builder/edit',
  PAGE_BUILDER_VIEW: '/page-builder/view',
  CHECKOUT: '/checkout',
};

export const Common = {
  UNSAVED_CHANGES: 'Are you sure you want to save your changes?',
  NO_CHANGES: 'No Changes are there to save.',
};

export const getPagePath = (query: string) => {
  return `?${query}`;
};

export const defaultValues: FormData = {
  ID: '',
  ShopID: '',
  HasSubscription: false,
  terms_of_service: false,
  Items: [],
  TotalAmount: '',
  CustomerData: {
    Email: '',
    PhoneNumber: '',
  },
  Shipping: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    CountryCode: '',
    ProvinceCode: '',
    Zip: '',
    Organization: '',
    PhoneNumber: '',
  },
  phone_code: '',
  billing_same_as_shipping: true,
  BillingAddress: {
    FirstName: '',
    LastName: '',
    Address1: '',
    Address2: '',
    City: '',
    CountryCode: '',
    ProvinceCode: '',
    Zip: '',
    Organization: '',
    PhoneNumber: '',
  },
  ShippingMethodID: '',
};
export const standardPostUpsellLayout = {
  content: [
    {
      type: 'Columns',
      props: {
        gap: 'gap-2',
        distribution: 'auto',
        columns: [{}],
        padding: {
          desktop: {
            top: 'pt-4',
            bottom: 'pb-4',
            right: 'pr-4',
            left: 'pl-4',
          },
          tablet: {
            top: 'max-md:pt-1',
            bottom: 'max-md:pb-1',
            right: 'max-md:pr-1',
            left: 'max-md:pl-1',
          },
          mobile: {
            top: 'max-sm:pt-1',
            bottom: 'max-sm:pb-1',
            right: 'max-sm:pr-1',
            left: 'max-sm:pl-1',
          },
        },
        direction: 'column',
        margin: {
          desktop: {
            top: 'mt-0',
            bottom: 'mb-0',
            right: 'mr-0',
            left: 'ml-0',
          },
          tablet: {
            top: 'max-md:mt-1',
            bottom: 'max-md:mb-1',
            right: 'max-md:mr-1',
            left: 'max-md:ml-1',
          },
          mobile: {
            top: 'max-sm:mt-1',
            bottom: 'max-sm:mb-1',
            right: 'max-sm:mr-1',
            left: 'max-sm:ml-1',
          },
        },
        id: 'Columns-fb52eccd-c886-40d2-a989-b17a7ebf216b',
      },
    },
    {
      type: 'NextButton',
      props: {
        NextButton: "NO, THANK YOU, I DON'T WANT THIS LIMITED TIME OFFER",
        nextPagePath: '',
        margin: {
          desktop: {
            top: 'mt-4',
            bottom: 'mb-4',
            right: 'mr-4',
            left: 'ml-4',
          },
          tablet: {
            top: 'max-md:mt-1',
            bottom: 'max-md:mb-1',
            right: 'max-md:mr-1',
            left: 'max-md:ml-1',
          },
          mobile: {
            top: 'max-sm:mt-1',
            bottom: 'max-sm:mb-1',
            right: 'max-sm:mr-1',
            left: 'max-sm:ml-1',
          },
        },
        padding: {
          desktop: {
            top: 'pt-0',
            bottom: 'pb-0',
            right: 'pr-0',
            left: 'pl-0',
          },
          tablet: {
            top: 'max-md:pt-1',
            bottom: 'max-md:pb-1',
            right: 'max-md:pr-1',
            left: 'max-md:pl-1',
          },
          mobile: {
            top: 'max-sm:pt-1',
            bottom: 'max-sm:pb-1',
            right: 'max-sm:pr-1',
            left: 'max-sm:pl-1',
          },
        },
        justifyContent: 'justify-center',
        font: {
          size: 'text-2xl',
          weight: 'font-bold',
        },
        alignItems: 'items-center',
        id: 'NextButton-1b37fd1d-c691-45b8-be46-6768a6a5ca5c',
        showComponent: ['mobile', 'tablet', 'desktop'],
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: {
        r: 79,
        g: 70,
        b: 229,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Columns-fb52eccd-c886-40d2-a989-b17a7ebf216b:column-0': [
      {
        type: 'TextField',
        props: {
          header: 'Final offer sale!',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'h-10',
          justifyContent: 'justify-start',
          color: {
            backgroundColor: 'bg-white',
            textColor: 'text-gray-900',
            hoverColor: 'hover:bg-gray-100',
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-b84f1bc4-ab6e-4204-af67-d5cad60eb490',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
      {
        type: 'Header',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: "WAIT! YOUR ORDER ISN'T COMPLETE",
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          id: 'Header-8646fc24-37d7-4e21-a018-4825e79bce99',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
      {
        type: 'Columns',
        props: {
          gap: 'gap-2',
          distribution: 'auto',
          columns: [{}, {}],
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          direction: 'column',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'Columns-8651dc36-753b-4c0e-95da-60da146c05a7',
        },
      },
    ],
    'Columns-8651dc36-753b-4c0e-95da-60da146c05a7:column-0': [
      {
        type: 'TimerUpSell',
        props: {
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: 'RESERVED FOR THE NEXT:',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          id: 'TimerUpSell-c63ba296-fdae-4f63-9e8c-0cf9a01b79c9',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
      {
        type: 'UpsellDeal',
        props: {
          nextPagePath: '',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          Description: "WAIT! YOUR ORDER ISN'T COMPLETE",
          Offer: 'SAVE 43.56 OFF OUR ',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          width: 'w-full',
          id: 'UpsellDeal-29732d47-b3b8-4f08-a22b-b9bb1338824f',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
    ],
    'Columns-8651dc36-753b-4c0e-95da-60da146c05a7:column-1': [
      {
        type: 'Product',
        props: {
          Product: 'Product',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-4',
              left: 'ml-4',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          justifyContent: 'justify-center',
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          alignItems: 'items-center',
          id: 'Product-7970cba2-dff4-4606-bbbf-c14ffae19783',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
    ],
  },
};
export const standardCheckoutLayout = {
  content: [
    {
      type: 'StandardLayout',
      props: {
        border: false,
        borderColor: '#E5E5E5',
        bgColor: '#FFFFFF',
        bgColorLeft: '#FFFFFF',
        bgColorRight: '#F9F9F9',
        orderSummaryCartTotal: false,
        orderSummaryTextColor: '#000',
        orderSummaryText: 'Show order summary',
        id: 'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'inter',
      themeColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:top': [
      {
        type: 'SimplePageHeader',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          backgroundImageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'background',
            BlobURL: '',
          },
          headerText:
            '<p class="ql-align-justify"><strong class="ql-size-large">My Store</strong></p>',
          showHeader: true,
          imageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'logo',
            BlobURL: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-6',
              bottom: 'pb-6',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-4',
              bottom: 'max-md:pb-4',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-2',
              bottom: 'max-sm:pb-2',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          image: {
            imageType: 'inline',
            backgroundPosition: 'bg-center',
            backgroundRepeat: 'bg-no-repeat',
            backgroundSize: 'bg-cover',
            borderRadius: 'rounded-md',
            border: 'border-none',
            maxWidth: 'w-auto',
            height: 'auto',
          },
          absolute: false,
          logoAlign: true,
          show: 'cart',
          id: 'SimplePageHeader-e6c1d3d6-a8d0-466a-a27b-e382e837ae0b',
        },
      },
    ],
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:left': [
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">STEP 1: INTERESTED IN ANYTHING ELSE?﻿</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-cb129936-f523-4e8f-8f5b-a7bbdd4422f2',
        },
      },
      {
        type: 'UpSell',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'UpSell-5fe855fd-5d68-4151-9998-247d5dbc8199',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">STEP 2: CONTACT INFORMATION</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-766ce4dd-207c-469b-95ab-118492b55c18',
        },
      },
      {
        type: 'ContactInfo',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ContactInfo-b8618f39-7fd7-4a8b-802c-90dc949d6b9e',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">STEP 3: SHIPPING ADDRESS</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-d0769bda-93c2-4454-8c9a-ae69ff3ce66c',
        },
      },
      {
        type: 'ShippingForm',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ShippingForm-6474d630-cf5d-4005-8ad5-d1e617b58204',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">STEP 4: BILLING ADDRESS</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-42c679bb-62f8-4e01-b735-7fcda2b54f46',
        },
      },
      {
        type: 'BillingForm',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'BillingForm-a15f689d-398f-480d-bba9-856ab311b653',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">STEP 5: SHIPPING METHODS</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-5f025b35-208e-451d-b9a4-b22d48205e27',
        },
      },
      {
        type: 'ShippingMethodSelect',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingMethodSelect-d846263c-6273-4599-83b4-6fdae25b3da3',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-large" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">STEP 6: PAYMENT METHODS﻿</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-c8e707d0-dd44-4f05-a533-406c94738490',
        },
      },
      {
        type: 'Payment',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          subText:
            '<p><strong class="ql-size-medium" style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">All transactions are secured and encrypted</strong></p>',
          isShowBillingAddress: false,
          showCardName: true,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Payment-5c03d21a-bb9c-4785-a238-0a615a185f5c',
        },
      },
      {
        type: 'ConfirmPurchase',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          nextPagePath: {
            label: 'ccea5164-b438-4c1f-be30-38db299c7d2e',
            value:
              '?tab=1&pageID=ccea5164-b438-4c1f-be30-38db299c7d2e&storeID=f6720d76-202f-4616-a877-eb9560f2fddc&store=sandbox-2024&cart=PHOENIX_CART_PREVIEW&template=other',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ConfirmPurchase-d9f47d74-ea4d-4ae2-ac9a-9175ed6d914c',
        },
      },
    ],
    'StandardLayout-daefc640-22a4-47f2-bf94-b039ecfeaa51:right': [
      {
        type: 'Cart',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Cart-9bca99a8-52db-4547-ba0a-3a359f3308e6',
        },
      },
      {
        type: 'PromoCode',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          placeholder: 'Promo Code (Case Sensitive)',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'PromoCode-d51576c2-bbf2-4a76-811a-16c4f0cf821f',
        },
      },
      {
        type: 'OrderDetails',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'OrderDetails-28923016-0f17-434a-bb39-fb08c377fb1c',
        },
      },
      {
        type: 'CustomerReviews',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          title: '<p>Customer Reviews</p>',
          border: true,
          reviews: [
            {
              id: 'e8e3a03a-d3cb-4d69-b108-3544e07b757f',
              name: 'John T',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style",
            },
            {
              id: '5f7f7561-290f-4c6f-85a8-48a35c0cc988',
              name: 'Kevin L',
              review:
                'I really like the Cross Skull Bead Bracelet! It’s weighty and the skull details add a cool, rugged look. It’s comfortable for all-day wear and perfect for enhancing a rugged style.',
            },
          ],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'CustomerReviews-dd908cec-6754-4d29-90bb-6fd0d2d6a39d',
        },
      },
      {
        type: 'CustomerGuarantees',
        props: {
          title: 'Why Us',
          showComponent: ['desktop', 'tablet', 'mobile'],
          border: true,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          guarantees: [
            {
              id: 'e379de06-bf33-45cb-aabd-9f752e52ad67',
              image: {
                Filename: '',
                BlobURL: '',
                StoreID: '',
                ID: '',
                Version: 0,
              },
              name: 'Items in your cart are in high demand. No Worries, we reserved your order.',
              description:
                "We ship out quality products, it's just one of the reasons we have so many happy repeat customers!",
            },
            {
              id: 'd0249c0e-3ccd-4d64-a7f3-b7af3d4427f0',
              image: {
                Filename: '',
                BlobURL: '',
                StoreID: '',
                ID: '',
                Version: 0,
              },
              name: "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
              description:
                "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
            },
          ],
          id: 'CustomerGuarantees-34258279-5614-4264-ba92-50232e9b9b6b',
        },
      },
      {
        type: 'Container',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          position: '',
          flexDirection: 'row',
          backgroundColor: 'none',
          border: false,
          borderRadius: 'rounded-none',
          borderWidth: 'border-0',
          borderColor: '#000000',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          gap: 'gap-2',
          id: 'Container-ac8d9707-a16b-4aad-b810-67a3d5c52913',
        },
      },
    ],
    'Container-bc553c16-a2d1-4c32-b379-f3ca85022d40:Container': [
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong>VIP Club</strong></p>',
          background: {
            backgroundColor: '#f9f9f9',
            hoverColor: '#f9f9f9',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-1fd00a38-40f4-430d-8538-0d72ffbc49e9',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-justify">By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">The VIP Club is an exclusive program that provides you with</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Access to VIP pricing on all website products.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Access to hundreds of trending products through the VIP Portal.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Lowest price guarantee on all VIP Portal orders.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Priority 24/7 customer support.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">You will be billed 29 dollars 99 cents monthly till cancellation. If you do not want to continue the VIP Club after the introductory period or if you wish to cancel the VIP Club at any time, you may do so by filling out the <a href="https://offer.enhanced-essence.com/pages/membership-cancellation" rel="noopener noreferrer" target="_blank">Cancel Membership </a>form on our Website, by emailing us or filling out the form on our website now at least 3 days before the next billing period with your full name and the email and physical address associated with your use of the Service.</p><p><br></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-bb1cd10d-f94b-40ab-8532-402b1a5b4bff',
        },
      },
    ],
    'Container-ac8d9707-a16b-4aad-b810-67a3d5c52913:Container': [
      {
        type: 'Container',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          position: '',
          flexDirection: 'row',
          backgroundColor: 'none',
          border: true,
          borderRadius: 'rounded-lg',
          borderWidth: 'border-0',
          borderColor: '#a3a3a3',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-4',
              bottom: 'max-md:pb-4',
              right: 'max-md:pr-4',
              left: 'max-md:pl-4',
            },
            mobile: {
              top: 'max-sm:pt-2',
              bottom: 'max-sm:pb-2',
              right: 'max-sm:pr-2',
              left: 'max-sm:pl-2',
            },
          },
          gap: 'gap-2',
          id: 'Container-bc553c16-a2d1-4c32-b379-f3ca85022d40',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><strong class="ql-size-medium">This offer is exclusively for paying members. Any orders from&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;non-paying customers will be refunded and not fulfilled.</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-c2722a4c-8ad3-4cbf-8c5a-8e008a143d42',
        },
      },
    ],
  },
};
export const initialThankYouData = {
  content: [
    {
      type: 'Columns',
      props: {
        gap: 'gap-0',
        distribution: 'manual',
        columns: [
          {
            span: 7,
          },
          {
            span: 5,
          },
        ],
        paddingLeft: 'pl-0',
        paddingRight: 'pr-0',
        paddingTop: 'pt-0',
        paddingBottom: 'pb-0',
        direction: 'column',
        id: 'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'sans-serif',
      themeColor: {
        r: 79,
        g: 70,
        b: 229,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9:column-0': [
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large" style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">Thank You For Your Purchase</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-1844d859-3660-413a-8821-1c7fed1a2c29',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><span style="background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);">Your order with&nbsp;Sandbox 2024&nbsp;is completed. Your order will ship within the next 24-48 business hours.</span></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-a36493d0-f976-4046-8f33-7810f87aa728',
        },
      },
      {
        type: 'VerticalSpace',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          size: '24px',
          backgroundColor: 'white',
          id: 'VerticalSpace-6e0527be-f2a6-4228-8d0b-8abf163f524d',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p><span style="color: rgb(75, 85, 99); background-color: rgb(255, 255, 255);">If you have any questions or concerns please contact us at&nbsp;</span><a href="mailto:support@goons.com" rel="noopener noreferrer" target="_blank" style="background-color: rgb(255, 255, 255);">support@goons.com</a><span style="background-color: rgb(255, 255, 255); color: rgb(75, 85, 99);">, or by calling us at&nbsp;800-555-7890.</span></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-01a8f61a-e5c1-4a46-a504-5abfb7731794',
        },
      },
      {
        type: 'TextEditor',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          content:
            '<p><span style="background-color: rgb(255, 255, 255); color: rgb(75, 85, 99);">Need help?&nbsp;</span><a href="https://google.com/" rel="noopener noreferrer" target="_blank" style="background-color: rgb(255, 255, 255); color: rgb(75, 85, 99);">Contact us</a></p>',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          borderRadius: 'rounded-md',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'TextEditor-0f604744-4112-4699-bd83-f1bb5a60ba28',
        },
      },
      {
        type: 'Divider',
        props: {
          showComponent: ['mobile', 'tablet', 'desktop'],
          size: '1px',
          direction: 'height',
          bgColor: 'black',
          id: 'Divider-e9032783-a5f9-4dea-852a-033626f70aef',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-medium" style="color: rgb(0, 0, 0); background-color: rgb(255, 255, 255);">All rights reserved Sandbox 2024.</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-7c8e5e1e-8270-4c49-a908-b1655e85a836',
        },
      },
    ],
    'Columns-1c0e2b4b-1528-45ea-9c38-755ac22bddf9:column-1': [
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">Email Address</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-eb35d6c8-cbe2-4efa-a69e-07cf223af9a9',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">mailto:abc@email.com</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-a329e0c5-ad0c-4767-806d-f39cf1d42dd9',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">Shipping Address</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-2d0c39c2-291a-4e04-b2b4-d79ebe082e9e',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">Shipping Address</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-fdf0b45b-1a77-47ad-8d2a-6af701cc982e',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">Test address line 1</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-53394ab9-8b63-4251-8d67-1ffa1528a7bf',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">Chennai Tami Nadu</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-0b77b398-d96c-47aa-9081-e1d7f46e31bd',
        },
      },
      {
        type: 'TextField',
        props: {
          showComponent: ['desktop', 'tablet', 'mobile'],
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-large">India﻿</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-2',
              bottom: 'pb-2',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-fd3c0af0-4f9f-4c3e-82db-d179ec09f4fb',
        },
      },
    ],
  },
};
export const initialFreeTemplateData = {
  content: [],
  root: {},
  zones: {},
};
export const urlRegExp =
  /^https?:\/\/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
export const emailRegExp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export const phoneRegExp = /^\(?\d{2,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;

export const navigationPathComponents = [
  'NextButton',
  'UpsellDeal',
  'ConfirmPurchase',
  'Button',
  'ButtonSection',
];

export const defaultComponentProps = {
  header: 'Header',
  //checkout
  contactInfo: 'ContactInfo',
  shippingForm: 'ShippingForm',
  shippingMethod: 'ShippingMethod',
  preUpsell: 'PreUpsell',
  billingForm: 'BillingForm',
  payment: 'Payment',
  confirmPurchase: 'ConfirmPurchase',
  cart: 'Cart',
  orderDetails: 'OrderDetails',
  customerReviews: 'CustomerReviews',
  customerGuarantees: 'CustomerGuarantees',
  promoCode: 'PromoCode',
  termsAndSubscription: 'TermsAndSubscription',
  //post-upsells
  dealConfirmButton: 'UpsellDeal',
  nextProductButton: 'NextButton',
  product: 'Product',
  timer: 'TimerUpSell',
  vip: 'Vip',
};

export const toolTipInfo = {
  SplitLayout:
    'Layout designed to split the page into two distinct sections, allowing for parallel content display.',
  StandardLayout:
    'A layout featuring a fixed header, suitable for pages requiring consistent navigation at the top.',
  Columns:
    'Component used to arrange elements in a series of vertical columns, providing a structured layout.',
  Flex: 'Component for organizing elements using a flexbox layout, offering flexible and responsive design options.',
  Items:
    'Component for organizing elements using a flexbox layout, offering flexible and responsive design options.',
  VerticalSpace:
    'Component to insert vertical spacing between elements, enhancing layout clarity and readability.',
  Divider:
    'Component for adding a horizontal line, used to separate sections or elements visually.',
  Container:
    'A wrapping component for grouping elements together, often used to apply common styles or layouts.',
  Accordion:
    'Component that provides expandable sections, useful for organizing content into collapsible panels.',
  TextEditor:
    'Component that allows users to add and format text content dynamically.',
  TextField:
    'Component for capturing user input through a simple text field, often used in forms.',
  Button:
    'Component to create interactive buttons, triggering actions or events when clicked.',
  ConfirmPurchase:
    'A specialized button component for confirming purchase actions, typically used at checkout.',
  SimplePageHeader:
    'Component for adding a straightforward page header, offering clear and concise navigation or branding.',
  ContactInfo:
    'Component designed to capture and display user contact information, typically used in forms.',
  ShippingForm:
    'Form component dedicated to collecting shipping details from users during checkout.',
  BillingForm:
    'Form component for gathering billing information, ensuring secure and accurate transaction processing.',
  ShippingMethodSelect:
    'Component for selecting available shipping methods, providing users with delivery options.',
  Payment:
    'Component for selecting payment options using credit card, offering a simple user interface with billing address.',
  PaymentRadio:
    'A comprehensive form component for processing payments, supporting various payment methods and with billing address.',
  Cart: 'Component representing a shopping cart, allowing users to view and manage items they intend to purchase.',
  PromoCode:
    'Component for entering promotional codes, enabling users to apply discounts to their orders.',
  OrderDetails:
    'Component displaying detailed order information, including itemized lists and totals.',
  UpSell:
    'Component designed to promote additional products or services, encouraging higher purchase values.',
  CustomerReviews:
    'Component for showcasing customer feedback and ratings, enhancing credibility and trust.',
  Timer:
    'Component that adds a countdown timer, often used to create urgency in promotional offers.',
  TermsAndSub:
    'Component for presenting terms and subscription agreements, ensuring user awareness and consent.',
  CustomerGuarantees:
    'Component highlighting guarantees offered to customers, building confidence in purchases.',
  ImageUploader:
    'Component that allows users to upload and manage images, often used in profiles or product listings.',
  SavingCalculator:
    'Component for calculating potential savings, helping users make informed financial decisions.',
  Stepper:
    'Component for guiding users through a series of steps, ensuring a structured and intuitive experience.',
  ReturnButton:
    'Component for navigating back to the store from the checkout page.',
  // Styles props
  backgroundColor:
    'Choose the background color for this section to set the overall look and feel.',
  'Background Color Left': 'Choose the background color for the left column.',
  'Error Color':
    'Choose the color of the text for the form and other error messages.',
  'Theme Color':
    'Choose the color of the entire theme to maintain a consistent look and feel.',
  'Background Color Right': 'Choose the background color for the right column.',
  hoverColor:
    'Select a color that appears when users hover over this section for a dynamic effect.',
  textColor:
    'Pick the color of the text in this section to ensure readability and style.',
  fontSize: 'Adjust the size of the text for better visibility and emphasis.',
  'Custom CSS':
    'Add custom CSS by manually through code editor or inject as many as stylesheets through link.',
  'Custom Scripts':
    'Add custom scripts to enhance the functionality of this checkout by manually through code editor or inject as many as scripts through link.',
  fontWeight: 'Set the thickness of the text to make it stand out or blend in.',
  desktopMargin:
    'Define the space around this section on desktop screens for layout control.',
  tabletMargin:
    'Specify the margin for this section on tablet devices for a responsive design.',
  mobileMargin:
    'Set the space around this section on mobile devices for optimal viewing.',
  desktopPadding:
    'Add padding inside this section on desktop screens to create space around content.',
  tabletPadding:
    'Specify padding for this section on tablet devices for a balanced layout.',
  mobilePadding:
    'Set the padding inside this section on mobile devices to enhance readability.',
  'Background Image':
    'Select an image from the media library to customize the visual appearance of background for this section.',
  Image:
    'Select an image from the media library to display the logo in the header section.',
  'Header Text':
    'This is the main title or heading displayed at the top of a section or page. It should convey the primary message or topic to the user.',
  Height:
    'This refers to the vertical dimension of a UI element. Adjusting the height can help fit the content within a specific area or create visual balance.',
  Width:
    'This is the horizontal dimension of a UI element. Setting the width allows you to control how much space an element occupies on the page.',
  'Justify-Content':
    'This property is used to align items within a container along the main axis. It can distribute space evenly or position items at the start, center, or end.',
  'align-items':
    'This property aligns items within a container along the cross-axis. It ensures consistent alignment of elements, such as centering them vertically.',
  'Border Radius':
    'This property rounds the corners of a UI element. It can be used to soften the appearance of elements, making them more visually appealing.',
  'Place Holder':
    'This is the text shown inside an input field before the user enters any data. It provides a hint or example of the expected input.',
  'Order Summary Text':
    "This is the text displayed in the order summary section in mobile and tablet devices, providing a clear overview of the user's purchase details.",
  'Mobile Summary Cart Total':
    'Enabling this will show the total amount of the cart in the mobile and tablet summary section.',
  Border:
    'This property adds a border around the element, creating a visual separation from other elements or sections.',
  'Border Color':
    'This property sets the color of the border around the element, allowing for customization and visual distinction.',
  'Text Color':
    'This property sets the color of the text through out the element, allowing for customization and visual distinction.',
  Distribution:
    'This property defines how much space should be distributed between each column in a Columns component. If the layout is automatic, each column will take an equal amount of space.',
  Gap: 'This property defines the space between each child element inside flex or columns layout.',
  Direction:
    'This property defines the direction of the flex or columns layout, such as reverse or straight.',
  direction:
    'This property defines the alignment of the divider, either horizontally or vertically.',
  'Minimum Item Width/Height':
    'This property sets the minimum width and height of the item in the flex layout.',
  size: 'This property sets the size of the vertical space between elements.',
  position:
    'Position: Defines how an element is positioned on the page. Sticky: Keeps the element fixed in place within its container as you scroll, but only within the container’s boundaries.',
  'Border Width':
    'This property sets the width of the border around the element.',
  'Accordion Open Text':
    'This is the text displayed when the accordion is open.',
  'Accordion Close Text':
    'This is the text displayed when the accordion is closed.',
  'Show Grand Total':
    'This property will show the grand total of the cart in the drop down header.',
  Name: 'This property will define the name of the button.',
  QuillEditor:
    'This editor allows you to format text with bold, italics, and underline. Easily insert images, links, and bullet lists.',
  'Next Page':
    'This property will define the next page to navigate to when the user clicks the button.',
  'Show Header': 'This toggle will show or hide the header of the component.',
  Show: 'Through this toggle, you can select which components to show on the right side of the header.',
  'Logo Align':
    'This property will define the alignment of the logo in the header whethere left or center.',
  Absolute:
    'This property will define the position of the header whether it is absolute or relative.',
  'Sub Text':
    'This property will define the text of the header for the payment.',
  'Show Billing Address':
    'Through this toggle, you can select whether to show the billing address or not.',
  Title:
    'This property will define the title of the VIP component which will be displayed on the top of the component.',
  Description:
    'This property will define the description of the VIP component which will be displayed below the title.',
  'Benefits Title':
    'This property will define the title of the benefits of the VIP component.',
  'Benefits Description':
    'This property will define the description of the benefits of the VIP component.',
  Interval:
    'This property will define the timer to be shown to the user in seconds.',
  'Sale Text':
    'This property will define the text to be shown in sale text header.',
  'Sale Text Color': 'This property will define the color of the sale text.',
  'Body Text Color': 'This property will define the color of the body text.',
  'Body Text':
    'This property will define the text to be shown in the body of the timer component.',
  'Font Family':
    'This property specifies the typeface used for text content in a UI element. It affects the overall look and readability of the text.',
  CustomPixel:
    'Add custom pixel code to track user behavior or target specific audiences.',
  'Divider Line':
    'Insert a horizontal line horizontally centered within the header section.',
  'Next Button':
    'Through this button, we can select the next page the user needs to navigate.',
  Text: 'This property will define the main text for this particular component to show.',
  'Upsell Deal':
    'This property will define the deal for the upsell product component.',
  Product:
    'This property will show the products that are configured for the post upsell.',
  device:
    'Customize the layout for different devices, such as desktop, tablet, and mobile, to ensure optimal user experience.',
};

export const mandatoryComponents: { [key: string]: string[] } = {
  checkout: [
    // defaultComponentProps.header,
    defaultComponentProps.contactInfo,
    defaultComponentProps.shippingForm,
    defaultComponentProps.billingForm,
    defaultComponentProps.payment,
    defaultComponentProps.confirmPurchase,
    // defaultComponentProps.cart,
    defaultComponentProps.orderDetails,
    // defaultComponentProps.customerReviews,
    // defaultComponentProps.customerGuarantees,
  ],
  upsell: [
    defaultComponentProps.header,
    defaultComponentProps.timer,
    defaultComponentProps.product,
    defaultComponentProps.dealConfirmButton,
    defaultComponentProps.nextProductButton,
  ],
  confirmation: ['TextField', 'TextEditor', 'VerticalSpace', 'Divider'],
};

export const simpleCheckoutLayout = {
  content: [
    {
      type: 'SplitLayout',
      props: {
        border: false,
        padding: {
          desktop: {
            top: 'pt-0',
            bottom: 'pb-0',
            right: 'pr-32',
            left: 'pl-32',
          },
          tablet: {
            top: 'max-md:pt-0',
            bottom: 'max-md:pb-0',
            right: 'max-md:pr-0',
            left: 'max-md:pl-0',
          },
          mobile: {
            top: 'max-sm:pt-0',
            bottom: 'max-sm:pb-0',
            right: 'max-sm:pr-0',
            left: 'max-sm:pl-0',
          },
        },
        borderColor: '#E5E5E5',
        bgColor: '#FFFFFF',
        bgColorLeft: '#FFFFFF',
        bgColorRight: '#F9F9F9',
        orderSummaryCartTotal: false,
        orderSummaryTextColor: '#000',
        orderSummaryText: 'Show order summary',
        id: 'SplitLayout-eb4424a4-7fcf-47aa-9ee7-4191c361c0ff',
        ennableSticky: 'auto',
      },
    },
  ],
  root: {
    props: {
      fontFamily: 'inter',
      themeColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      errorColor: '#FF5A50',
      customCss: [],
      customScripts: [],
    },
  },
  zones: {
    'SplitLayout-eb4424a4-7fcf-47aa-9ee7-4191c361c0ff:top': [
      {
        type: 'SimplePageHeader',
        props: {
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#f9f9f9',
          },
          backgroundImageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'background',
            BlobURL: '',
          },
          headerText:
            '<p class="ql-align-justify"><strong class="ql-size-large">My Store</strong></p>',
          showHeader: true,
          imageFile: {
            Version: 0,
            StoreID: '',
            Filename: 'logo',
            BlobURL: '',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-4',
              bottom: 'max-md:mb-4',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-32',
              left: 'pl-32',
            },
            tablet: {
              top: 'max-md:pt-4',
              bottom: 'max-md:pb-4',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-2',
              bottom: 'max-sm:pb-2',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          image: {
            imageType: 'inline',
            backgroundPosition: 'bg-center',
            backgroundRepeat: 'bg-no-repeat',
            backgroundSize: 'bg-cover',
            borderRadius: 'rounded-md',
            border: 'border-none',
            maxWidth: 'w-auto',
            height: 'h-auto',
          },
          color: {
            backgroundColor: 'bg-transparent',
            hoverColor: '#d4d5d6',
            textColor: '#000',
          },
          font: {
            size: 'text-2xl',
            weight: 'font-bold',
          },
          absolute: false,
          logoAlign: true,
          show: 'cart',
          id: 'SimplePageHeader-c37c0843-9151-4a66-aa8d-23b8036d9b6e',
          showComponent: ['tablet', 'desktop', 'mobile'],
        },
      },
    ],
    'SplitLayout-eb4424a4-7fcf-47aa-9ee7-4191c361c0ff:left': [
      {
        type: 'TextField',
        props: {
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 1: INTERESTED IN ANYTHING ELSE?﻿</strong></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-4',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-2',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-8b5c883f-da02-4542-802f-7720863d5428',
          showComponent: ['tablet', 'desktop', 'mobile'],
        },
      },
      {
        type: 'UpSell',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-2',
              bottom: 'max-sm:mb-4',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'UpSell-5f11f46c-ed4e-4f61-808c-7b43b372cff8',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-50469fa2-9d50-470d-b4d1-db386c5e7ad6',
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 2: CONTACT INFORMATION</strong></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'ContactInfo',
        props: {
          placeholder: 'Email Address',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ContactInfo-5164aeb9-64c2-44c2-a012-0a9fd4d4fcad',
          showComponent: ['mobile', 'desktop', 'tablet'],
        },
      },
      {
        type: 'TextField',
        props: {
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 3: SHIPPING ADDRESS</strong><span style="color: rgb(206, 145, 120);" class="ql-font-arial"><span class="ql-cursor">﻿</span></span></p>',
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-90f16790-4bba-4bb6-90c4-9cee44d61725',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'ShippingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'ShippingForm-e261583a-8a36-4bb5-94db-96ee76252a59',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-33309cc9-006a-40f6-bab7-e869da5d3988',
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 4: BILLING ADDRESS</strong></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'BillingForm',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'BillingForm-34f3f6c7-b6b6-4cc7-bcaf-6e5ef32be39f',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-e2b79936-8068-4ab2-9608-ac18e638a6f2',
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 5: SHIPPING METHODS</strong></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'ShippingMethodSelect',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-4',
              bottom: 'max-sm:mb-6',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ShippingMethodSelect-892c8c22-7125-4a9c-97f1-54c5e9ebcf4e',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#ffffff',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-9ee14cf0-63cc-43c5-9d1f-e1bf646dbfbb',
          fontQuillEditor:
            '<p><strong class="ql-size-large">STEP 6: PAYMENT METHODS﻿</strong><span class="ql-size-large">﻿</span></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'Payment',
        props: {
          subText:
            '<p><strong class="ql-size-medium">All transactions are secured and encrypted</strong></p>',
          isShowBillingAddress: false,
          showCardName: true,
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Payment-de176ec9-fb2a-45f6-9978-677f6833852d',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'ConfirmPurchase',
        props: {
          nextPagePath: '',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-1',
              bottom: 'max-md:mb-1',
              right: 'max-md:mr-1',
              left: 'max-md:ml-1',
            },
            mobile: {
              top: 'max-sm:mt-1',
              bottom: 'max-sm:mb-1',
              right: 'max-sm:mr-1',
              left: 'max-sm:ml-1',
            },
          },
          id: 'ConfirmPurchase-ec9b1e08-290e-4551-84f4-2ebbe336862a',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
    ],
    'Container-644d3605-ed9e-4fb4-9786-33dccc219b9a:Container': [
      {
        type: 'Container',
        props: {
          position: '',
          flexDirection: 'row',
          backgroundColor: 'none',
          border: true,
          borderRadius: 'rounded-lg',
          borderWidth: 'border-3',
          borderColor: '#cec0c0',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          gap: 'gap-2',
          id: 'Container-78250fec-2cb2-4ff1-a13c-f1e8a188a5ac',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#f9f9f9',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-none',
          height: 'full',
          justifyContent: 'justify-start',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-9eaaff1f-ea8a-4045-9ae4-79474b387e77',
          fontQuillEditor:
            '<p><strong class="ql-size-medium">This offer is exclusively for paying members. Any orders from&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;non-paying customers will be refunded and not fulfilled.</strong></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
    ],
    'SplitLayout-eb4424a4-7fcf-47aa-9ee7-4191c361c0ff:right': [
      {
        type: 'Cart',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'Cart-7d5ff87f-047a-472b-b53a-286d831a119c',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'PromoCode',
        props: {
          placeholder: 'Promo Code (Case Sensitive)',
          margin: {
            desktop: {
              top: 'mt-6',
              bottom: 'mb-6',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'PromoCode-7854f219-847c-4e27-ad9c-2790c59806f2',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'OrderDetails',
        props: {
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'OrderDetails-085c5413-61e0-45eb-affc-7188fe643712',
          showComponent: ['tablet', 'mobile', 'desktop'],
        },
      },
      {
        type: 'CustomerReviews',
        props: {
          border: true,
          placeHolder: {
            text: 'Customer Reviews',
            fontSize: '16',
            fontWeight: 'font-light',
            hoverColor: '#000000',
            textColor: '#000000',
          },
          reviews: [
            {
              id: '378cd437-1022-4092-a7fc-be1f18bc4ee7',
              name: 'Kevin L',
              review:
                'I really like the Cross Skull Bead Bracelet! It’s weighty and the skull details add a cool, rugged look. It’s comfortable for all-day wear and perfect for enhancing a rugged style.',
            },
            {
              id: '0a08200e-bfed-4656-a5a9-0ec5465ca3b6',
              name: 'John T',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
            {
              id: 'f2e179f2-3851-46d9-9ae6-d08dda25bac6',
              name: 'Aiden H',
              review:
                "I love the Thor's Hammer Celtic Knot Viking Ring! It beautifully combines Viking lore with elegance. The craftsmanship is top-notch, making it lightweight yet durable for everyday wear. Highly recommend for its superb quality and style.",
            },
          ],
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'CustomerReviews-a48dc3a2-de94-4a58-8a3a-c22b9ba1aee1',
          title:
            '<p><span class="ql-size-medium">Customer Reveiws<span class="ql-cursor">﻿</span></span></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'CustomerGuarantees',
        props: {
          border: true,
          placeHolder: {
            text: 'Why Us',
            fontSize: '16',
            fontWeight: 'font-medium',
            textColor: '#000000',
            hoverColor: '#000000',
          },
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          guarantees: [
            {
              id: '7d888755-f257-4727-b690-be7833a6b55b',
              img_src: 'calendar',
              name: "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
              description:
                "If you're not satisfied with your product(s), we'll make it right. That's our promise!",
            },
            {
              id: '4a44df8e-94b7-4250-8358-3dc84e5f0ede',
              img_src: 'delivery',
              name: 'Items in your cart are in high demand. No Worries, we reserved your order.',
              description:
                "We ship out quality products, it's just one of the reasons we have so many happy repeat customers!",
            },
          ],
          id: 'CustomerGuarantees-8eee1f9d-0644-4cd0-9be6-e2c91edbab53',
          title:
            '<p class="ql-align-justify"><span class="ql-size-medium">Why Us</span></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'Container',
        props: {
          position: '',
          flexDirection: 'row',
          backgroundColor: 'none',
          border: false,
          borderRadius: 'rounded-lg',
          borderWidth: 'border-3',
          borderColor: '#635f5f',
          margin: {
            desktop: {
              top: 'mt-0',
              bottom: 'mb-0',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          padding: {
            desktop: {
              top: 'pt-4',
              bottom: 'pb-4',
              right: 'pr-4',
              left: 'pl-4',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          gap: 'gap-2',
          id: 'Container-644d3605-ed9e-4fb4-9786-33dccc219b9a',
        },
      },
    ],
    'Container-78250fec-2cb2-4ff1-a13c-f1e8a188a5ac:Container': [
      {
        type: 'TextField',
        props: {
          background: {
            backgroundColor: '#f9f9f9',
            hoverColor: '#ffffff',
          },
          border: false,
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          alignItems: 'items-center',
          borderRadius: 'rounded-md',
          height: 'full',
          justifyContent: 'justify-center',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          width: 'w-full',
          id: 'TextField-785d8c5f-6bae-4734-a767-268cff9b66b9',
          fontQuillEditor:
            '<p class="ql-align-center"><strong class="ql-size-medium">VIP Club</strong></p>',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
      {
        type: 'TextEditor',
        props: {
          content:
            '<p class="ql-align-justify">By taking advantage of this offer, you will be signed up for our exclusive VIP Club program.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">The VIP Club is an exclusive program that provides you with</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Access to VIP pricing on all website products.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Access to hundreds of trending products through the VIP Portal.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Lowest price guarantee on all VIP Portal orders.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">Priority 24/7 customer support.</p><p class="ql-align-justify"><br></p><p class="ql-align-justify">You will be billed 29 dollars 99 cents monthly till cancellation. If you do not want to continue the VIP Club after the introductory period or if you wish to cancel the VIP Club at any time, you may do so by filling out the <a href="https://offer.enhanced-essence.com/pages/membership-cancellation" rel="noopener noreferrer" target="_blank">Cancel Membership </a>form on our Website, by emailing us or filling out the form on our website now at least 3 days before the next billing period with your full name and the email and physical address associated with your use of the Service.</p>',
          padding: {
            desktop: {
              top: 'pt-0',
              bottom: 'pb-0',
              right: 'pr-0',
              left: 'pl-0',
            },
            tablet: {
              top: 'max-md:pt-0',
              bottom: 'max-md:pb-0',
              right: 'max-md:pr-0',
              left: 'max-md:pl-0',
            },
            mobile: {
              top: 'max-sm:pt-0',
              bottom: 'max-sm:pb-0',
              right: 'max-sm:pr-0',
              left: 'max-sm:pl-0',
            },
          },
          borderRadius: 'rounded-md',
          margin: {
            desktop: {
              top: 'mt-4',
              bottom: 'mb-4',
              right: 'mr-0',
              left: 'ml-0',
            },
            tablet: {
              top: 'max-md:mt-0',
              bottom: 'max-md:mb-0',
              right: 'max-md:mr-0',
              left: 'max-md:ml-0',
            },
            mobile: {
              top: 'max-sm:mt-0',
              bottom: 'max-sm:mb-0',
              right: 'max-sm:mr-0',
              left: 'max-sm:ml-0',
            },
          },
          id: 'TextEditor-9e394d87-6c25-4dfc-a3a8-4a10215f0bf6',
          showComponent: ['mobile', 'tablet', 'desktop'],
        },
      },
    ],
  },
};
