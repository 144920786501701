import { ComponentConfig, DropZone } from '@measured/puck';
import { flexDirections } from '../../../../page-builder/puck/reusable-props/options';
import './styles.scss';
import {
  GapProps,
  gapField,
} from '../../../../page-builder/puck/reusable-props/gap';
import { FieldLabel } from '@measured/puck';
import { SketchPicker } from 'react-color';
import {
  paddingFields,
  PaddingProps,
} from '../../../../page-builder/puck/reusable-props/padding';
import {
  marginFields,
  MarginProps,
} from '../../../../page-builder/puck/reusable-props/margin';
import CustomTooltip from '../../../../components/common/tooltip';
import ShowComponent, {
  showComponentProps,
} from '../../../puck/reusable-props/showComponent';
import {
  getcurrentDeviceType,
  resolveShowComponentField,
} from '../../../utils/helper';
import { ReduxSelectorProps } from '../../../services/constants';

export type FlexProps = {
  items: { minItemSize?: number }[];
  minItemSize: number;
  backgroundColor: string;
  flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse';
} & PaddingProps &
  GapProps &
  MarginProps &
  showComponentProps;

export const Flex = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<FlexProps> => {
  return {
    label: (
      <CustomTooltip title="Flex">
        <span>Flex</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...ShowComponent,
      flexDirection: {
        label: 'Direction',
        type: 'select',
        options: flexDirections,
      },
      items: {
        label: 'Items',
        type: 'array',
        arrayFields: {
          minItemSize: {
            label: 'Minimum Item Width/Height',
            type: 'number',
            min: 0,
          },
        },
        getItemSummary: (_, id) => `Item ${Number(id) + 1}`,
      },
      ...gapField,
      ...paddingFields,
      ...marginFields,
      minItemSize: {
        label: 'Minimum Item Width/Height',
        type: 'number',
        min: 0,
      },
      backgroundColor: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => (
          <FieldLabel
            label={
              (
                <CustomTooltip title="backgroundColor">
                  <span>Background Color</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <SketchPicker
              color={value || '#000'}
              onChange={(color: { hex: string }) => onChange(color.hex)}
            />
          </FieldLabel>
        ),
      },
    },
    defaultProps: {
      items: [{}, {}],
      showComponent: ['desktop', 'tablet', 'mobile'],
      minItemSize: 0,
      flexDirection: 'row',
      gap: 'gap-2',
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      backgroundColor: 'white',
    },

    resolveFields: ({ props }, { fields }) =>
      resolveShowComponentField(props.id, fields),

    render: ({
      items,
      minItemSize,
      flexDirection,
      padding,
      gap,
      margin,
      backgroundColor,
      showComponent,
    }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`Flex ${gap} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
              ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
              ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
              ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
              ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}
              style={{
                flexDirection,
                backgroundColor,
              }}>
              {items.map((item, idx) => (
                <div
                  key={idx}
                  className={'Flex-item'}
                  style={
                    flexDirection === 'row' || flexDirection === 'row-reverse'
                      ? { minWidth: item.minItemSize || minItemSize }
                      : { minHeight: item.minItemSize || minItemSize }
                  }>
                  <DropZone zone={`item-${idx}`} />
                </div>
              ))}
            </div>
          )}
        </>
      );
    },
  };
};
