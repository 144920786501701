import { useForm, Controller } from 'react-hook-form';
import { Button } from 'shared-components';
import DatePicker from 'components/common/datePicker';
import { ISalvageFormFilter } from 'interface/subscriptionSalvage';
import { FC } from 'react';
import { defaultOrderFilterValues } from 'components/subcriptionSalvage';
import {
  allDateRange,
  dateRangeOptionsWithAllTime,
} from 'components/dashboard/constant';
import { getDateFilter } from 'utils/helper';
import dayjs from 'dayjs';
import 'components/subcriptionSalvage/style.scss';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import TextField from 'components/common/textField';

interface IProps {
  sideFormFilter: ISalvageFormFilter;
  filterSubmission: (_data: ISalvageFormFilter) => void;
}

const dateFilterOptions = [
  {
    label: 'Acquired',
    value: 'Acquired',
  },
  {
    label: 'Transaction',
    value: 'Transaction',
  },
];

const FilterForm: FC<IProps> = ({ sideFormFilter, filterSubmission }) => {
  const { handleSubmit, control, watch, reset, setValue } =
    useForm<ISalvageFormFilter>({
      defaultValues: sideFormFilter,
    });

  const onSubmit = (data: ISalvageFormFilter) => {
    filterSubmission(data);
  };

  return (
    <div className="order-filter-selection">
      <form onSubmit={handleSubmit(onSubmit)} className="filter-selection-form">
        <Controller
          name="dateFilterBy"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <AutoCompleteSelect
                options={dateFilterOptions}
                labelKey="label"
                onChange={val => onChange(val?.value || null)}
                value={
                  dateFilterOptions?.find(type => type.value === value) || null
                }
                className="w-full dropdown-field"
                label="Date filter by"
              />
            );
          }}
        />
        <Controller
          name="dateRange"
          control={control}
          render={({ field: { onChange, value } }) => {
            const handleChange = (e: string) => {
              if (e === 'All Time') {
                setValue('startDate', null);
                setValue('endDate', null);
              } else if (e !== 'Custom Range') {
                const { start_time, end_time } = getDateFilter(e);
                setValue('startDate', start_time);
                setValue('endDate', end_time);
              } else {
                setValue('startDate', null);
                setValue('endDate', null);
              }
              onChange(e);
            };
            return (
              <AutoCompleteSelect
                options={dateRangeOptionsWithAllTime}
                labelKey="label"
                onChange={val => handleChange(val?.value)}
                placeholder="Select"
                value={
                  dateRangeOptionsWithAllTime?.find(
                    type => type.value === value,
                  ) || null
                }
                className="w-full dropdown-field"
                label={
                  watch('dateFilterBy') === 'Acquired'
                    ? 'Acquisition Date Range'
                    : 'Transaction Date Range'
                }
              />
            );
          }}
        />
        <Controller
          name="startDate"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                label="Start Date"
                maxDate={dayjs(watch('endDate')).tz()}
                disableFuture={true}
                value={value}
                onChange={onChange}
                onAccept={() => {
                  setValue('dateRange', allDateRange.customRange);
                }}
              />
            );
          }}
        />
        <Controller
          name="endDate"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                label="End Date"
                minDate={dayjs(watch('startDate')).tz()}
                disableFuture={true}
                value={value}
                onChange={onChange}
                onAccept={() => {
                  setValue('dateRange', allDateRange.customRange);
                }}
              />
            );
          }}
        />
        <Controller
          name="externalProcessorID"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <TextField
                label="External Processor ID"
                placeholder="Type External Processor ID"
                value={value}
                fixedSize="md"
                onChange={e => {
                  onChange(
                    e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                  );
                }}
              />
            );
          }}
        />
        <div className="button_wrapper">
          <Button
            className="transaction_for_submit white-space-nowrap"
            type="submit"
            label="Apply Filter"
          />
          <Button
            variant="secondary"
            className="transaction_for_submit white-space-nowrap"
            onClick={() => {
              reset(defaultOrderFilterValues);
            }}
            label="Clear Filter"
          />
        </div>
      </form>
    </div>
  );
};
export default FilterForm;
