import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { ReactNode } from 'react';
import 'components/cascade/ActionLogsTable/style.scss';
export interface UserLogs {
  name: string;
  traffic: number;
  revenueLimit: number;
  transactionLimit: number;
}

interface Iprops {
  data: UserLogs[];
  paddingClassName: string;
}

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: UserLogs) => ReactNode;
  hide?: boolean;
}

const merchantLogHeadList: HeadCell[] = [
  {
    id: 'Merchant Name',
    label: 'Merchant Name',
  },
  {
    id: 'Traffic %',
    label: 'Traffic %',
  },
  {
    id: 'Revenue Limit',
    label: 'Revenue Limit',
  },
  {
    id: 'Transaction Limit',
    label: 'Transaction Limit',
  },
];

export default function ActionLogsTable({ data, paddingClassName }: Iprops) {
  return (
    <>
      <TableContainer className="action_log_table_container">
        <Table aria-label="merchant table" className="merchant-table">
          <TableHead className="table-head">
            <TableRow className="table-head-row">
              {merchantLogHeadList?.map(headCell => (
                <TableCell
                  className={`table-cell-head ${paddingClassName}`}
                  key={headCell.label}>
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.map(merchant => (
              <TableRow key={merchant.name} className="table-row ">
                <TableCell className={`table-cell ${paddingClassName}`}>
                  {merchant.name}
                </TableCell>
                <TableCell className={`table-cell ${paddingClassName}`}>
                  {merchant.traffic}
                </TableCell>
                <TableCell className={`table-cell ${paddingClassName}`}>
                  ${merchant.revenueLimit.toFixed(2)}
                </TableCell>
                <TableCell className={`table-cell ${paddingClassName}`}>
                  {merchant.transactionLimit}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
