import { useEffect, useMemo } from 'react';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as ColoumnIcon } from 'assets/icons/columnFilter-icon.svg';
import { ActionFilter } from 'components/SubscriptionManagement/ActionFilter';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  ISubscrptionManagement,
  SideFormFilterSubscription,
} from 'interface/subscriptionManagementInterface';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';
import FilterAction from 'components/common/filterAction';
import { FilterForm } from 'components/SubscriptionManagement/FilterForm';
import ColumnFilter from 'components/SubscriptionManagement/ColoumnFilter';
import { HeadCell } from 'components/SubscriptionManagement';
import { setMyColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import ExportFilter from '../ExportFilter';
import { userPermissionKeys } from 'utils/constants';

const filterAction = [
  { key: 'Filters', icon: FilterIcon, className: 'margin_filter' },
  { key: 'Actions', icon: ActionIcon, permission: userPermissionKeys.manage },
  { key: 'Column Filters', icon: ColoumnIcon },
  { key: 'Download', icon: DownloadIcon },
];
const filterActionList = {
  cancelled: ['Resume Subscription', 'Pause Subscription'],
  paused: ['Resume Subscription', 'Cancel Subscription'],

  active: [
    'Cancel Subscription',
    'Pause Subscription',
    'Move Subscription',
    'Change Next Billing Date',
    'Change Subscription Value',
  ],
  default: [
    'Resume Subscription',
    'Cancel Subscription',
    'Pause Subscription',
    'Move Subscription',
    'Change Next Billing Date',
    'Change Subscription Value',
  ],
};
interface Props {
  sideFormFilterSubscription: SideFormFilterSubscription;
  searchOrderList: () => void;
  exportList: () => void;
  filterSubmission: (_data: SideFormFilterSubscription) => void;
  selected: Map<string, ISubscrptionManagement>;
  setSubscriptionManagementData: React.Dispatch<
    React.SetStateAction<ISubscrptionManagement[]>
  >;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  loading: number;
  resetSelected: () => void;
  setHeadCell: React.Dispatch<React.SetStateAction<HeadCell[]>>;
  headCell: HeadCell[];
}
export default function SideFilter({
  sideFormFilterSubscription,
  filterSubmission,
  selected,
  setLoading,
  searchOrderList,
  setSubscriptionManagementData,
  resetSelected,
  headCell,
  setHeadCell,
  exportList,
  loading,
}: Props) {
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { subscription } = useAppSelector(state => state.permission);
  const { subscriptionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );
  const dispatch = useAppDispatch();
  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({
        filterSource: 'subscriptionManagement',
        currentFilter: filter,
      }),
    );
  };

  const handleChange = (val: string) => {
    if (subscriptionColumnFilter.indexOf(val) === -1) {
      dispatch(
        setMyColoumnDetails({
          subscriptionColumnFilter: [...subscriptionColumnFilter, val],
        }),
      );
    } else {
      dispatch(
        setMyColoumnDetails({
          subscriptionColumnFilter: [
            ...subscriptionColumnFilter.filter(each => each !== val),
          ],
        }),
      );
    }

    setHeadCell(
      headCell.map((each: HeadCell) =>
        each.label === val ? { ...each, hide: !each.hide } : each,
      ),
    );
  };

  const filteredActions = useMemo(() => {
    if (selected && selected.size > 0) {
      const statuses = [...selected.values()].reduce(
        (acc, value) => {
          if (value.Status === SubscriptionStatus.subscriptionStatusCanceled) {
            acc.cancelled = true;
          } else if (
            value.Status === SubscriptionStatus.subscriptionStatusPause
          ) {
            acc.paused = true;
          } else if (
            value.Status === SubscriptionStatus.subscriptionStatusActive
          ) {
            acc.active = true;
          }
          return acc;
        },
        { active: false, cancelled: false, paused: false },
      );
      if (statuses.cancelled) {
        return filterActionList.cancelled;
      }
      if (statuses.paused) {
        return filterActionList.paused;
      }
      return statuses.active
        ? filterActionList.active
        : filterActionList.default;
    }
    return filterActionList.default;
  }, [selected]);

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return (
          <FilterForm
            sideFormFilterSubscription={sideFormFilterSubscription}
            filterSubmission={filterSubmission}
            resetSelected={resetSelected}
            loading={loading}
          />
        );
      case 'Actions':
        return (
          <ActionFilter
            setLoading={setLoading}
            searchOrderList={searchOrderList}
            selected={selected}
            resetSelected={resetSelected}
            setSubscriptionManagementData={setSubscriptionManagementData}
            filterList={filteredActions}
          />
        );

      case 'Column Filters':
        return (
          <ColumnFilter
            setSelectedColumns={handleChange}
            filterList={headCell}
          />
        );

      case 'Download':
        return <ExportFilter exportList={exportList} />;

      default:
        break;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);

  return (
    <div className="subscription-filter">
      <div className="side_filter_container">
        <FilterAction
          className="transaction_side_filtericon"
          filterAction={filterAction}
          permissionOfPage={subscription}
          setSelectedFilter={setSelectedFilter}
        />
        {!!currentFilter && filterSource === 'subscriptionManagement' && (
          <div className="filter_selected_wrapper">
            <div className="filter_header">
              <p className="filter_section_Header">{currentFilter}</p>
              <CloseIcon
                className="close_ison"
                onClick={() => dispatch(setCurrentFilter(''))}
              />
            </div>
            <div className="filter_container">{renderComponent()}</div>
          </div>
        )}
      </div>
    </div>
  );
}
