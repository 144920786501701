import {
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from 'components/common/loader';
import './style.scss';
import { get } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
// import CreateIcon from '@mui/icons-material/Create';
import storeService from 'services/storeService';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddPages from 'components/Actions/addPages';
import {
  ConvertBase64ToJson,
  ConvertJsonToBase64,
  // ConvertJsonToBase64,
  PopUpModal,
  showNotification,
} from 'shared-components';
import {
  IAddCheckoutPage,
  ICheckoutPage,
} from 'interface/checkoutPagesInterface';
import { getPagePath } from 'router/constant';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ICheckoutPage) => ReactNode;
  hide?: boolean;
}
export interface IPagesProps {
  storeId?: string;
  shopifyId?: string;
  shopId?: string;
}

export default function Pages({ storeId, shopifyId, shopId }: IPagesProps) {
  const storeDetails: { StoreID: string; ChannelID: string } = {
    ChannelID: shopifyId ? shopifyId : '',
    StoreID: storeId ? storeId : '',
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<ICheckoutPage[]>([]);
  const search = {
    StoreID: storeDetails.StoreID,
    Descending: false,
    Limit: 25,
    OrderBy: 'Name',
    Page: 0,
    SearchFields: {},
  };
  const [pagesSearch, setPagesSearch] = useState(search);
  const [configurePage, setConfigurePage] = useState<{
    open: boolean;
    currentRow?: ICheckoutPage;
  }>({
    open: false,
  });
  const [deletePopup, setDeletePopup] = useState<{
    open: boolean;
    currentRow?: ICheckoutPage;
  }>({ open: false });
  const [pageCopyIsOpen, setPageCopyIsOpen] = useState<{
    open: boolean;
    currentRow?: ICheckoutPage;
  }>({ open: false });
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isNextAPIDisabled) {
          setPagesSearch(prevPage => ({
            ...prevPage,
            Page: prevPage.Page + 1,
          }));
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );

  const openEditor = (row: ICheckoutPage) => {
    const query = new URLSearchParams(location.search);
    query.set('pageID', row?.ID || '');
    query.set('storeID', storeId || '');
    query.set('store', shopId || '');
    query.set('cart', 'PHOENIX_CART_PREVIEW');
    query.set('template', row?.PageType);
    window.open(getPagePath(query));
  };

  const [currentRow, setCurrentRow] = useState<ICheckoutPage | undefined>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLSpanElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLSpanElement>,
    row: ICheckoutPage,
  ) => {
    setCurrentRow(row);
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = (name: string) => {
    if (name === 'duplicate') {
      setValue('name', '');
      setPageCopyIsOpen({ open: true, currentRow: currentRow });
    } else if (name === 'edit') {
      setConfigurePage({ open: true, currentRow: currentRow });
    } else if (name === 'delete') {
      setDeletePopup({ open: true, currentRow: currentRow });
    }
    setAnchorEl(null);
  };

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Name',
      hide: false,
      cellRender: row => {
        return <>{row.Name}</>;
      },
    },
    {
      id: 'PageType',
      label: 'PageType',
      hide: false,
      cellRender: row => {
        return <>{row.PageType}</>;
      },
    },
    //
    // {
    //   id: 'LastUpdate',
    //   label: 'Last Update',
    //   hide: false,
    //   cellRender: row => {
    //     return <>{row.Status}</>;
    //   },
    // },
    {
      id: 'tags',
      label: 'Tags',
      hide: false,
      cellRender: row => {
        return <>{row.Tags}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="actions-container flex flex-row justify-center gap-1.5  items-center ">
            <VisibilityRoundedIcon
              className="add_store cursor-pointer"
              sx={{
                height: 20,
                width: 20,
              }}
              onClick={() => {
                openEditor(row);
              }}
              titleAccess="view"
            />
            <span
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={event => handleClick(event, row)}
              style={{
                cursor: 'pointer',
              }}>
              <MoreVertIcon />
            </span>
            <Menu
              key={row.ID}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              className="custom-menu-page"
              onClose={() => {
                setAnchorEl(null);
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem
                onClick={() => {
                  handleEdit('duplicate');
                }}>
                Duplicate
              </MenuItem>
              <MenuItem onClick={() => handleEdit('edit')}>Edit</MenuItem>
              <MenuItem onClick={() => handleEdit('delete')}>Delete</MenuItem>
            </Menu>
          </div>
        );
      },
    },
  ];

  const getPages = async () => {
    setLoading(true);
    const res = await storeService.getCheckoutPage(pagesSearch);
    setLoading(false);
    if (res?.data && res?.data?.Result) {
      const pagesResult =
        pagesSearch.Page === 0
          ? res.data.Result
          : [...pages, ...res.data.Result];

      setPages(pagesResult);
      setIsNextAPIDisabled(
        res.data.TotalCount === pagesResult.length ||
          res.data.Result.length < pagesSearch.Limit,
      );
    } else {
      setPages([]);
    }
  };

  const deletePage = async () => {
    const res = await storeService.deleteCheckoutPage({
      PageID: deletePopup.currentRow?.ID || '',
      StoreID: deletePopup.currentRow?.StoreID || '',
    });
    if (res?.status === 200) {
      setPagesSearch({ ...search });
      setDeletePopup({ open: false });
    }
  };
  const addPages = async (data: ICheckoutPage, name: string) => {
    const json = ConvertBase64ToJson(data.PageData);
    const payload: IAddCheckoutPage = {
      Name: name,
      PageType: data.PageType,
      Tags: data.Tags,
      PageData: ConvertJsonToBase64(json),
      StoreID: data.StoreID,
    };
    setLoading(true);
    const res = await storeService.createCheckoutPage(payload);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Duplicated Page Added Successfully');
      setPagesSearch({ ...search });
    }
  };
  const handleDuplicatePage = (data: { name: string }) => {
    if (pageCopyIsOpen?.currentRow)
      addPages(pageCopyIsOpen.currentRow, data.name);
    setPageCopyIsOpen({ open: false, currentRow: undefined });
  };

  useEffect(() => {
    getPages();
  }, [pagesSearch]);

  // useEffect(() => {
  //   if (storeId && shopifyId) {
  //     setPagesSearch({ ...search });
  //   } else {
  //     setPages([]);
  //   }
  // }, [storeId, shopifyId]);
  return (
    <>
      <div className="h-full pages-container">
        <TableContainer className="pages-table Common_Table !h-fit max-h-full">
          <Table aria-labelledby="tableTitle" stickyHeader>
            <TableHead>
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell
                      className={
                        headCell.label == 'Actions' ? '!text-center' : ''
                      }
                      key={headCell.label}>
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {pages && pages?.length > 0 ? (
                pages?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      ref={index === pages.length - 1 ? lastElementRef : null}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={headCell.label}
                            component="th"
                            id={headCell.id}
                            scope="row">
                            <>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="!py-20" colSpan={15}>
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <button
                    className="sticky-button flex items-center"
                    onClick={() => setConfigurePage({ open: true })}>
                    <AddIcon /> Add Page
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Loader loading={loading} />
      {configurePage.open && (
        <AddPages
          storeDetails={storeDetails}
          currentRow={configurePage.currentRow}
          handleClose={refetch => {
            if (refetch) setPagesSearch({ ...search });
            setConfigurePage({ open: false });
          }}
          isAdd={!configurePage.currentRow?.ID}
        />
      )}
      <PopUpModal
        heading={`Delete Page`}
        open={deletePopup.open}
        handleClose={() => setDeletePopup({ open: false })}
        buttons={[
          {
            buttonLabel: 'Cancel',
            buttonType: 'negative',
            buttonFunction: () => setDeletePopup({ open: false }),
          },
          {
            buttonLabel: 'Delete page',
            buttonType: 'positive',
            buttonFunction: deletePage,
          },
        ]}>
        <p className="text-white">
          Are you sure you want to delete the page{' '}
          <span className="font-medium">{deletePopup?.currentRow?.Name}</span>?
        </p>
      </PopUpModal>
      <PopUpModal
        heading={`Duplicate Page`}
        open={pageCopyIsOpen.open}
        handleClose={() =>
          setPageCopyIsOpen({ open: false, currentRow: undefined })
        }
        buttons={[
          {
            buttonLabel: 'Cancel',
            buttonType: 'negative',
            buttonFunction: () =>
              setPageCopyIsOpen({ open: false, currentRow: undefined }),
          },
          {
            buttonLabel: 'Submit',
            buttonType: 'positive',
            form: 'AddDuplicatePage',
          },
        ]}>
        <div className="popup-section">
          <form
            id="AddDuplicatePage"
            onSubmit={handleSubmit(handleDuplicatePage)}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Duplicate page name"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter the page name"
                    ref={ref}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
}
