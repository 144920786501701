import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setTimeZone } from 'redux-setup/slices/pageConfigSlice';
import { RootState } from 'redux-setup/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import { useEffect } from 'react';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { ReactComponent as ArrowIcon } from 'assets/icons/Vector.svg';
import 'components/common/timeZoneSelect/style.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

interface IProp {
  label: string;
  value: string;
}

const TimeZoneSelect = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeZoneOptions = (Intl as any) // get all the available timezones
    .supportedValuesOf('timeZone')
    .map((tz: string) => {
      const timeZone = `${tz + '(' + dayjs().tz(tz).format('z') + ')'}`
        .split('_')
        .join(' ');
      return { label: timeZone, value: tz };
    });

  const timeZone = useAppSelector(
    (state: RootState) => state.pathConfig.timeZone,
  );
  const defaultTimeZone =
    timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dispatch = useAppDispatch();

  const setDefaultTimeZone = () => {
    dayjs.tz.setDefault(defaultTimeZone);
    dispatch(setTimeZone(defaultTimeZone));
  };

  useEffect(() => {
    setDefaultTimeZone();
  }, []);

  const handleChange = (tZdata: IProp) => {
    if (tZdata?.value) {
      dispatch(setTimeZone(tZdata.value));
      dayjs.tz.setDefault(tZdata.value);
    }
  };

  return (
    <div className={`common_multi_select_container`}>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        className="combo-textboxddd"
        options={timeZoneOptions}
        sx={{ border: 'none' }}
        popupIcon={<ArrowIcon />}
        value={
          timeZoneOptions.find(
            (option: IProp) => option?.value === defaultTimeZone,
          ) ?? null
        }
        onChange={(_, val) => {
          handleChange(val as IProp);
        }}
        PaperComponent={({ children }) => (
          <Paper className="timezone-lists">{children}</Paper>
        )}
        renderInput={params => (
          <TextField {...params} className="text_field_input_multiselect" />
        )}
      />
    </div>
  );
};

export default TimeZoneSelect;
