import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  ISubscriptionStatsBody,
  ISubscriptionStats,
  ITransactionStats,
} from 'interface/dashboardInterface';
import { IGetStores, IStoreList } from 'interface/storeInterface';
import {
  commonError,
  getStoresEp,
  subscriptionStatsEP,
  transactionStatsEP,
  uniqueTransactionStatsEP,
} from 'services/constants';

export const getSubscriptionStats = async (body: ISubscriptionStatsBody) => {
  try {
    const response = await baseService.post<ISubscriptionStats>(
      subscriptionStatsEP,
      body,
    );
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getTransactionStats = async (body: ISubscriptionStatsBody) => {
  try {
    const response = await baseService.post<ITransactionStats>(
      transactionStatsEP,
      body,
    );
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getStores = async (body: IGetStores) => {
  try {
    const response = await baseService.post<IStoreList>(getStoresEp, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};

export const getUniqueTransactionStats = async (
  body: ISubscriptionStatsBody,
) => {
  try {
    const response = await baseService.post<IStoreList>(
      uniqueTransactionStatsEP,
      body,
    );
    return response;
  } catch (error) {
    return commonError(error as AxiosError, true);
  }
};
