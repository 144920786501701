import 'components/Invites/style.scss';
import MyInvites from 'components/Invites/MyInvites';

export default function Invites() {
  return (
    <div className="invites_container">
      <MyInvites />
    </div>
  );
}
