import AddStore from 'components/Actions/addStore';
import { useAppSelector } from 'hooks/reduxHooks';
import { IStore } from 'interface/storeInterface';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { getShopifyStoreforntCreatePath, Path } from 'router/constant';

const CreateStore: FC = () => {
  const navigate = useNavigate();
  const shopURL =
    useAppSelector(state => state?.shopify?.shopFromSearchParam) || '';
  const shopName = shopURL?.split('.')?.[0];
  const defaultStore: IStore = {
    CheckoutURL: '',
    ID: '',
    Name: shopName,
    StoreDescription: '',
    Version: 0,
  };
  return (
    <AddStore
      defaultStoreValues={defaultStore}
      handleClose={() => {
        navigate(Path.SHOPIFY_SETTINGS_PAGE);
      }}
      onSuccess={(id: string | undefined) => {
        if (id) navigate(getShopifyStoreforntCreatePath(id));
        else navigate(Path.SHOPIFY_SETTINGS_PAGE);
      }}
      isAdd={true}
    />
  );
};

export default CreateStore;
