import { SelectControl } from 'shared-components';
import { Button } from 'shared-components';
import { FC, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldControl } from 'components/common/textField';
import membershipPlanService from 'services/membershipPlanService';
import { IFormPlan, IPlan } from 'interface/membershipPlanInterface';
import { subscription_frequency } from 'utils/helper';
import { useAppSelector } from 'hooks/reduxHooks';
import { Loader } from 'components/common/loader';
import { useNavigate } from 'react-router-dom';

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  p: 4,
  transform: 'translate(-50%, -50%)',
  maxHeight: '70vh',
  minWidth: '40vw',
  maxWidth: '50vw',
  borderRadius: '20px',
  border: '1px solid rgba(163, 174, 208, 0.20)',
  backgroundColor: '#141E2D',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  outline: 'unset',
};

const formDefaultvalues = {
  Amount: '',
  FreeTrialLength: 1,
  FreeTrialUnit: 'days',
  Frequency: '',
  Interval: 0,
  Name: '',
  StoreID: '',
};

interface IProps {
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  isSettingsFromPortal?: boolean;
}

const EditMembership: FC<IProps> = ({
  id,
  setIsEdit,
  isSettingsFromPortal,
}) => {
  const [iplanValues, setIplanValues] = useState<IPlan | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const method = useForm<IFormPlan>({
    defaultValues: formDefaultvalues,
  });
  const { storeFront } = useAppSelector(state => state.shopify);
  const navigate = useNavigate();

  const goBack = () => {
    if (setIsEdit) setIsEdit(false);
    else navigate(-1);
  };

  const addMembershipPlan = async (data: IPlan) => {
    // API call to save membership details
    setLoading(true);
    const res = await membershipPlanService.createMembershipPlan(data);
    if (res?.status === 200) {
      setIsEdit && setIsEdit(false);
    }
    setLoading(false);
  };

  const updateMembershipPlan = async (data: IPlan) => {
    // API call to update membership details
    setLoading(true);
    const res = await membershipPlanService.updateMembershipPlan(data);
    if (res?.status === 200) {
      setIsEdit && setIsEdit(false);
    }
    setLoading(false);
  };

  const onSubmit = (data: IFormPlan) => {
    if (!storeFront?.StoreID || !storeFront?.ID) return;
    if (!id) {
      const additionalData: IPlan = {
        ...data,
        StoreID: storeFront?.StoreID,
        ChannelID: storeFront?.ID,
        Description: 'description',
        Interval: parseInt(data.Interval.toString()),
      };
      addMembershipPlan(additionalData);
    } else {
      const additionalData = {
        ...data,
        ID: id,
        StoreID: storeFront?.StoreID,
        Version: iplanValues?.Version || 0,
        ChannelID: storeFront?.ID,
        Description: iplanValues?.Description || 'description',
        Interval: parseInt(data.Interval.toString()),
      };
      updateMembershipPlan(additionalData);
    }
  };

  const getOfferDetails = async (id: string) => {
    // API call to get offer details
    setLoading(true);
    const res = await membershipPlanService.getMembershipPlanById(
      storeFront?.StoreID || '',
      id,
    );
    if (res?.status === 200) {
      method.reset(res.data);
      setIplanValues(res.data);
    } else {
      setIplanValues(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) getOfferDetails(id);
  }, [id]);

  return (
    <div className="mx-auto w-full max-w-xl">
      <form onSubmit={method.handleSubmit(onSubmit)} id="membershipPlan">
        {!isSettingsFromPortal && (
          <div className="flex justify-between align-center py-6">
            <div className="flex gap-3">
              <ArrowBackIcon
                className="cursor-pointer"
                onClick={() => {
                  goBack();
                }}
              />
              <h2 className="text-xl font-semibold">{`${
                id ? 'Edit Membership' : 'Add Membership'
              }`}</h2>
            </div>
            <Button label={id ? 'Update' : 'Save'} variant="secondary" />
          </div>
        )}
        <div className="pb-3">
          <div className="card text-left p-4 text-xs">
            {!isSettingsFromPortal && (
              <div className="pb-4">
                <h2 className="block font-semibold text-sm">
                  Membership Details
                </h2>
              </div>
            )}
            <div className="flex flex-col gap-4">
              <FormProvider {...method}>
                <div className="">
                  <TextFieldControl
                    placeholder="Enter Membership name"
                    label="Enter Membership name"
                    fixedSize="md"
                    name="Name"
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                </div>
                <div className="flex gap-2">
                  <div className="w-full">
                    <SelectControl
                      name="Frequency"
                      label="Frequency"
                      options={subscription_frequency}
                      placeholder="Days"
                    />
                  </div>
                  <div className="w-full">
                    <TextFieldControl
                      placeholder="Enter Days"
                      label="Trial period"
                      fixedSize="md"
                      name="Interval"
                      isSufficText="Days"
                      onChange={event => {
                        const value = event.target.value as string;
                        event.target.value = value.replace(/[^0-9]/g, '');
                      }}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className="w-full">
                    <TextFieldControl
                      placeholder="Enter Pricing"
                      label="Pricing"
                      fixedSize="md"
                      name="Amount"
                      onChange={event => {
                        const value = event.target.value as string;
                        event.target.value = value.replace(/[^0-9]/g, '');
                      }}
                      rules={{
                        required: 'This field is required',
                      }}
                    />
                  </div>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </form>
      <Loader loading={loading} />
    </div>
  );
};

export default EditMembership;
