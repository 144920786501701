import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { get } from 'lodash';
import { useAppSelector } from 'hooks/reduxHooks';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import AddPreUpSell from './AddPreUpSell';
import globalUpSellService from 'services/upSellService';
import { showNotification } from 'helper/common/commonFunctions';
import membershipOfferService from 'services/membershipOfferService';
import { IMemberShipProductsDetails } from 'interface/membershipOffersInterface';
import { IPreUPSellResponse } from 'interface/upSellInterface';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IPreUpSellData) => ReactNode;
  hide?: boolean;
}

interface PreUpSellProps {
  channelId: string;
  storeId: string;
  shopId: string;
}

export interface IPreUpSellData {
  product: {
    amount: string;
    label: string;
    value: string;
  };
  productName: string;
  status: string;
  price: string;
  ID: string;
  Version: number;
}

export default function PreUpSell({
  channelId,
  storeId,
  shopId,
}: PreUpSellProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [preupSells, setPreUpSells] = useState<IPreUpSellData[]>([]);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const defaultStoreFront: IPreUpSellData = {
    price: '',
    product: {
      amount: '',
      label: '',
      value: '',
    },
    productName: '',
    status: '',
    ID: '',
    Version: 0,
  };
  const [selectedStore, setSelectedStore] =
    useState<IPreUpSellData>(defaultStoreFront);
  const [add, setAdd] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Products',
      hide: false,
      cellRender: row => {
        return <>{row.productName}</>;
      },
    },
    {
      id: 'StoreDescription',
      label: 'Price',
      hide: false,
      cellRender: row => {
        return <>{row.price}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex items-center justify-end gap-3">
            <CreateIcon
              onClick={() => {
                setAdd(true);
                setSelectedStore(row);
              }}
            />
            <DeleteIcon
              onClick={() => {
                setIsDelete(true);
                setSelectedStore(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  const getProductsOfStore = async (
    productIds: number[],
    preUpSellMap: Map<string, IPreUPSellResponse>,
  ) => {
    const productPayload = {
      ShopID: shopId,
      StoreID: storeId,
      ProductIDs: productIds?.filter(id => id > 0),
    };
    if (productPayload.ProductIDs?.length) {
      const res = await membershipOfferService.batchProducts(productPayload);
      if (res?.status === 200 && res?.data?.Result?.length) {
        const preUpSells: IPreUpSellData[] = [];
        res.data.Result.forEach((item: IMemberShipProductsDetails) => {
          if (preUpSellMap.get(item.ID)?.ID) {
            const preupSellData: IPreUpSellData = {
              ID: preUpSellMap.get(item.ID)?.ID || '',
              product: {
                amount: item.MaxOriginalPrice,
                label: item.Title,
                value: item.ID,
              },
              productName: item.Title,
              status: 'Active',
              price: item.MaxOriginalPrice,
              Version: preUpSellMap.get(item.ID)?.Version || 0,
            };
            preUpSells.push(preupSellData);
          }
        });
        setPreUpSells(preUpSells);
      }
    }
  };

  const getPreUpSell = async () => {
    setLoading(true);
    const response = await globalUpSellService.searchUpSell({
      ChannelID: channelId,
      Descending: true,
      Limit: 100,
      OrderBy: 'Version',
      Page: 0,
    });
    if (response?.data && response?.data?.Result?.length) {
      const preUpSellMap = new Map<string, IPreUPSellResponse>();
      const shopIds = (response?.data?.Result as IPreUPSellResponse[]).map(
        item => {
          const productId = item.ProductID.split('/');
          preUpSellMap.set(item.ProductID, item);
          return parseInt(
            productId.length ? productId[productId.length - 1] : item.ProductID,
          );
        },
      );
      if (shopIds.length) await getProductsOfStore(shopIds, preUpSellMap);
    } else setPreUpSells([]);
    setLoading(false);
  };

  const removePreUpSell = async () => {
    setLoading(true);
    const response = await globalUpSellService.removeUpSell({
      ChannelID: channelId,
      PreUpsellIDs: [selectedStore.ID],
    });
    setLoading(false);
    if (response.status === 200 || response.status === 204) {
      showNotification('success', 'Pre upsell removed successfully');
      getPreUpSell();
    }
    setIsDelete(false);
  };

  useEffect(() => {
    getPreUpSell();
  }, [storeIds]);

  return (
    <div className="pre-upsell">
      <div className="h-full">
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className={
                          headCell.label == 'Actions' ? '!text-right' : ''
                        }
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <>
                <TableBody className="table-body">
                  {preupSells?.length > 0 ? (
                    preupSells?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="!py-20" colSpan={15}>
                        <div className="no-data-row">No data found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={15}>
                      <button
                        className="sticky-button flex items-center"
                        onClick={() => {
                          setAdd(true);
                          setSelectedStore(defaultStoreFront);
                        }}>
                        <AddIcon /> Add Pre upsells
                      </button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </>
            </Table>
          </TableContainer>
        </div>
        <Loader loading={loading} />
        {add && (
          <AddPreUpSell
            selectedPreUpSells={preupSells}
            shopId={shopId}
            channelId={channelId}
            storeId={storeId}
            defaultStoreValues={selectedStore}
            handleClose={() => setAdd(false)}
            onSuccess={() => getPreUpSell()}
          />
        )}
        <ConfirmationDialog
          open={isDelete}
          handleClose={() => setIsDelete(false)}
          message="delete Pre upsells"
          onSubmit={() => {
            removePreUpSell();
          }}
        />
      </div>
    </div>
  );
}
