import { FC } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'shared-components';
import 'components/subscriptionProjection/projectionFilter/FilterForm/style.scss';
import MultiStoreSearch from 'components/common/multiSelectSearchFilter/MultiStoreSearch';
import { MultipleInputTag } from 'components/common/multiInput';
import { IProjectionFilter } from 'components/subscriptionProjection';
dayjs.extend(utc);

interface Props {
  sideFormFilterSubscription: IProjectionFilter;
}
export const FilterForm: FC<Props> = ({ sideFormFilterSubscription }) => {
  const { control } = useForm<IProjectionFilter>({
    defaultValues: sideFormFilterSubscription,
  });

  // const onSubmit = async (data: IProjectionFilter) => {

  //   // filterSubmission(data);
  // };

  return (
    <div>
      <div className="projection-filter-selection">
        <form
          // onSubmit={handleSubmit(onSubmit)}
          className="subscription-filter-selection-form">
          <Controller
            name="StoreIDs"
            control={control}
            render={({ field: { value, onChange } }) => (
              <MultiStoreSearch value={value || []} onChange={onChange} />
            )}
          />
          <Controller
            name="merchantAccount"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value ? value : []}
                  label="Merchant Accounts"
                  placeholder="Type Emails"
                  onChange={onChange}
                />
              );
            }}
          />
          <Button
            className="subscription_for_submit"
            type="submit"
            label="Apply Filter"
          />
        </form>
      </div>
    </div>
  );
};
