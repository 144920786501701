import { useDropzone } from 'react-dropzone';
import { IpageSearch } from './image-popup';
import { ICheckoutPage } from '../../../page-builder/interface/pageInterface';
import {
  showNotification,
  StatusCode,
  toasterMessage,
} from '../../../page-builder/api/constant';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { AxiosInstance } from 'axios';
import pageBuilderService from '../../../page-builder/api/pageBuilderService';

type ImageProps = {
  setImageList: React.Dispatch<React.SetStateAction<IAsset[]>>;
  setDefaultImageList: React.Dispatch<React.SetStateAction<IAsset[]>>;
  setPagesSearch: React.Dispatch<React.SetStateAction<IpageSearch>>;
  checkoutPage: ICheckoutPage | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  baseService: AxiosInstance;
};

export const ImageInput = ({
  setImageList,
  setDefaultImageList,
  setPagesSearch,
  checkoutPage,
  setLoading,
  baseService,
}: ImageProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
    },
    onDropRejected: fileRejections => {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach(({ code }) => {
          let errorMessage;
          switch (code) {
            case 'file-invalid-type':
              errorMessage =
                'Invalid format. Only JPEG, PNG, and WEBP files are allowed.';
              break;
            default:
              errorMessage = 'File not accepted.';
          }
          showNotification(
            StatusCode.failed,
            `File: ${file.name} - ${errorMessage}`,
          );
        });
      });
    },
    onDrop: async acceptedFiles => {
      try {
        if (acceptedFiles.length) {
          const files: FormData[] = [];
          for (const file of acceptedFiles) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', file.name);
            formData.append('filename', file.name);
            formData.append('store_id', checkoutPage?.StoreID || '');
            files.push(formData);
          }
          setLoading(true);
          const response = await pageBuilderService.createAsset(
            files,
            baseService,
          );
          setLoading(false);
          if (response.status === 200) {
            showNotification(
              StatusCode.success,
              toasterMessage.imageUploadSuccess,
            );
            setImageList([]);
            setDefaultImageList([]);
            setPagesSearch(prevPage => ({
              ...prevPage,
              Page: 0,
            }));
          }
        }
      } catch (error) {
        setLoading(false);
        console.error('ERROR IN onDropAccepted', error);
      }
    },
  });
  return (
    <section className="container flex justify-center">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className="pl-6">Upload Image</p>
        <p>Drag and drop images</p>
      </div>
    </section>
  );
};
