import React, { useEffect, useCallback } from 'react';
import eventEmitter from '../../utils/events';
import checkoutService from '../../api/checkoutService';
import { PaymentFields } from '../payment/PaymentFields';
import {
  setIsDeclinePopup,
  setDeclineReasonCode,
  setPurchaseToken,
  setCheckoutOrderID,
} from '../../redux/slices/checkoutSlice';
import {
  setPurchaseCompleted,
  setSubmitSuccess,
} from '../../redux/slices/commonSlice';
import {
  Address,
  CardInfo,
} from '../../../page-builder/interface/checkoutInterface';
import { IErrorResponse } from '../../../page-builder/interface/apiInterface';
import { resetState } from '../../../page-builder/redux/slices/postUpsellStateSlice';
import { PaymentProps } from './Payment';
import { showNotification, StatusCode } from '../../api/constant';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import { ExtractPhxRequestId } from '../../utils/extractPhoenixId';
import { EventPublisher } from '../../utils/publishEvent';
import { v4 as uuidv4 } from 'uuid';

type FormValues = {
  ID: string;
  CardInfo: CardInfo;
  BillingAddress: Address;
};

interface PaymentControllerProps {
  fields: PaymentProps;
  fromRadio?: boolean;
}

export const PaymentController: React.FC<
  PaymentControllerProps & ReduxSelectorProps & ReduxDispatchProps
> = ({ fields, fromRadio = false, useAppSelector, useAppDispatch }) => {
  const cartData = useAppSelector(state => state.cart?.cartData);
  const storeData = useAppSelector(state => state.store.storeData);
  const dispatch = useAppDispatch();
  const publish = EventPublisher.getInstance().publish;

  const handleReturnToPayment = () => {
    dispatch(setIsDeclinePopup(false));
  };

  const postCheckoutData = useCallback(
    async (input: FormValues) => {
      if (!cartData?.ID || !storeData?.StoreID || !storeData?.ShopID) return;
      publish('PurchaseAttempt', {
        eventId: uuidv4(),
        eventName: 'PurchaseAttempt',
        shopId: storeData?.ShopID,
        cart: cartData,
      });
      const payload = {
        BillingAddress: input.BillingAddress,
        CardInfo: input.CardInfo,
        ID: cartData.ID,
        IdempotencyKey: input.ID,
        ShopID: storeData.ShopID,
        StoreID: storeData.StoreID,
      };
      dispatch(setSubmitSuccess(true));
      try {
        const response = await checkoutService.checkoutComplete(payload);
        publish('Purchase', {
          eventId: ExtractPhxRequestId(response),
          eventName: 'Purchase',
          shopId: storeData?.ShopID,
          cart: cartData,
        });
        dispatch(setPurchaseToken(response.data?.PurchaseToken));
        dispatch(setCheckoutOrderID(response.data?.OrderID));
        dispatch(resetState());
        showNotification(StatusCode.success, 'Checkout completed successfully');
        dispatch(setPurchaseCompleted(true));
      } catch (e) {
        const error = e as IErrorResponse;
        if (error?.status === 402) {
          dispatch(setIsDeclinePopup(true));
          dispatch(
            setDeclineReasonCode(error?.message || 'Invalid card information'),
          );

          dispatch(setSubmitSuccess(false));
        }
        if (error.status) {
          showNotification(StatusCode.failed, 'Checkout Failed');
          dispatch(setSubmitSuccess(false));
        }
      } finally {
        dispatch(setSubmitSuccess(false));
      }
    },
    [dispatch, cartData?.ID, storeData?.StoreID, storeData?.ShopID],
  );

  useEffect(() => {
    eventEmitter.addListener('completePurchase', postCheckoutData);
    return () => {
      eventEmitter.removeListener('completePurchase', postCheckoutData);
    };
  }, [postCheckoutData]);

  return (
    <>
      <PaymentFields
        handleReturnToPayment={handleReturnToPayment}
        fields={fields}
        fromRadio={fromRadio}
        useAppSelector={useAppSelector}
      />
    </>
  );
};
