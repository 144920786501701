import { useState } from 'react';
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';
import dayjs, { Dayjs } from 'dayjs';
import 'components/common/customDatePicker/style.scss';
import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';
import { getDateFormat } from 'utils/helper';

interface ICustomDatePickerProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

interface ICustomDatePicker {
  dateValues: (_range: ICustomDatePickerProps) => void;
  selectedRange: ICustomDatePickerProps;
  maxDate?: Date | null;
}

const CustomDatePicker = ({
  dateValues,
  selectedRange,
  maxDate,
}: ICustomDatePicker) => {
  const [value, setValue] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: selectedRange?.startDate
      ? selectedRange.startDate.toDate()
      : null,
    endDate: selectedRange?.endDate ? selectedRange.endDate.toDate() : null,
  });
  const timeZone = useAppSelector(
    (state: RootState) => state.pathConfig.timeZone,
  );
  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue as { startDate: Date | null; endDate: Date | null });
    dateValues({
      startDate: newValue?.startDate
        ? dayjs.tz(newValue?.startDate, timeZone).startOf('day')
        : null,
      endDate: newValue?.endDate
        ? dayjs.tz(newValue?.endDate, timeZone).endOf('day')
        : null,
    });
  };

  const customShortcuts = {
    shortcuts: {
      todayss: {
        text: 'Today',
        period: {
          start: dayjs().startOf('day').toISOString(),
          end: dayjs().endOf('day').toISOString(),
        },
      },
      yesterday: 'Yesterday',
      last7Days: {
        text: 'Last 7 Days',
        period: {
          start: dayjs().subtract(7, 'day').toISOString(),
          end: dayjs().toISOString(),
        },
      },
      last30Days: {
        text: 'Last 30 Days',
        period: {
          start: dayjs().subtract(30, 'day').toISOString(),
          end: dayjs().toISOString(),
        },
      },
      last90Days: {
        text: 'Last 90 Days',
        period: {
          start: dayjs().subtract(90, 'day').toISOString(),
          end: dayjs().toISOString(),
        },
      },
      last365Days: {
        text: 'Last 365 Days',
        period: {
          start: dayjs().subtract(365, 'day').toISOString(),
          end: dayjs().toISOString(),
        },
      },
      lastMonth: {
        text: 'Last Month',
        period: {
          start: dayjs()
            .startOf('month')
            .subtract(1, 'day')
            .startOf('month')
            .toISOString(),
          end: dayjs().startOf('month').subtract(1, 'day').toISOString(),
        },
      },
      last12Month: {
        text: 'Last 12 Month',
        period: {
          start: dayjs()
            .startOf('month')
            .subtract(1, 'day')
            .startOf('month')
            .subtract(11, 'month')
            .toISOString(),
          end: dayjs().startOf('month').subtract(1, 'day').toISOString(),
        },
      },
      lastYear: {
        text: 'Last Year',
        period: {
          start: dayjs()
            .startOf('year')
            .subtract(1, 'day')
            .startOf('year')
            .toISOString(),
          end: dayjs().startOf('year').subtract(1, 'day').toISOString(),
        },
      },
    },
  };

  return (
    <div className="Dual-Datepicker-Container">
      <Datepicker
        showShortcuts={true}
        showFooter={true}
        configs={customShortcuts}
        value={value}
        displayFormat={getDateFormat(timeZone)}
        separator={'-'}
        onChange={handleValueChange}
        maxDate={maxDate}
      />
      <div
        onClick={() => {
          handleValueChange({ startDate: null, endDate: null });
        }}></div>
    </div>
  );
};

export default CustomDatePicker;
