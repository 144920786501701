import { PopUpModal } from 'shared-components';
import React, { FC } from 'react';
import EditMembershipOffers from './EditMembershipOffers';

interface IProps {
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  isSettingsFromPortal?: boolean;
}
const EditMembershipOffersComponent: FC<IProps> = ({
  isSettingsFromPortal,
  id,
  setIsEdit,
}) => {
  const handleClose = () => {
    setIsEdit && setIsEdit(false);
  };
  return isSettingsFromPortal ? (
    <PopUpModal
      open={isSettingsFromPortal}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: handleClose,
        },
        {
          buttonType: 'positive',
          buttonLabel: !id ? 'Add' : 'Update',
          form: 'editMembershipOfferForm',
          type: 'submit',
        },
      ]}
      heading={`${!id ? 'Add' : 'Update'} Membership Offer`}
      className="flex-container">
      <EditMembershipOffers
        setIsEdit={setIsEdit}
        id={id}
        isSettingsFromPortal={isSettingsFromPortal}
      />
    </PopUpModal>
  ) : (
    <EditMembershipOffers
      setIsEdit={setIsEdit}
      id={id}
      isSettingsFromPortal={isSettingsFromPortal}
    />
  );
};

export default EditMembershipOffersComponent;
