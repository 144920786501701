import { ComponentConfig } from '@measured/puck';
import './style.scss';
import { useState } from 'react';
import {
  marginFields,
  MarginProps,
} from '../../../page-builder/puck/reusable-props/margin';
import CustomTooltip from '../../../components/common/tooltip';
import { heightField, HeightProps } from '../../puck/reusable-props/height';
import { widthField, WidthProps } from '../../puck/reusable-props/width';
import {
  JustifyContentField,
  JustifyContentProps,
} from '../../puck/reusable-props/justify-content';
import {
  alignItemsField,
  AlignItemsProps,
} from '../../puck/reusable-props/align-items';
import {
  borderRadiusField,
  BorderRadiusProps,
} from '../../puck/reusable-props/border-radius';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { paddingFields, PaddingProps } from '../../puck/reusable-props/padding';
import ColorSelector from '../../puck/reusable-props/color-selector';

import { QuillTextEditor } from '../../puck/reusable-props/quill-editor';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';
import { ReduxSelectorProps } from '../../services/constants';

export type TextFieldProps = {
  fontQuillEditor: string;
  border: boolean;
  background: {
    backgroundColor: string;
    hoverColor: string;
  };
} & MarginProps &
  HeightProps &
  WidthProps &
  JustifyContentProps &
  AlignItemsProps &
  BorderRadiusProps &
  PaddingProps &
  showComponentProps;

export const TextField = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<TextFieldProps> => {
  return {
    label: (
      <CustomTooltip title="TextField">
        <span>Text Field</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      fontQuillEditor: {
        ...QuillTextEditor('Header Text').content,
      },
      background: {
        type: 'custom',
        label: 'Background Color',
        render: ({ value, onChange }) => {
          const [isOpen, setIsOpen] = useState(false);
          const toggleAccordion = () => {
            setIsOpen(!isOpen);
          };

          return (
            <>
              <div
                className="flex justify-between font-extrabold cursor-pointer  bg-slate-100 rounded-md pagebuilder-theme"
                onClick={toggleAccordion}>
                <h3>Customize Bg Color</h3>
                {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
              </div>
              <div className="flex flex-col gap-1  mt-3 items-center">
                {isOpen && (
                  <div className="flex flex-col gap-4 w-full items-center border rounded p-2 pb-4">
                    <ColorSelector
                      value={value?.backgroundColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          backgroundColor: val,
                        });
                      }}
                      label="Background color"
                      tooltip="backgroundColor"
                    />
                    <ColorSelector
                      value={value?.hoverColor}
                      onChange={val => {
                        onChange({
                          ...value,
                          hoverColor: val,
                        });
                      }}
                      label="Hover color"
                      tooltip="hoverColor"
                    />
                  </div>
                )}
              </div>
            </>
          );
        },
      },
      ...marginFields,
      ...paddingFields,
      border: {
        type: 'radio',
        label: 'Divider Line',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },

      ...heightField,
      ...widthField,
      ...JustifyContentField,
      ...alignItemsField,
      ...borderRadiusField,
    },

    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      fontQuillEditor: 'Text Field',
      background: {
        backgroundColor: '#ffffff',
        hoverColor: '#ffffff',
      },
      border: false,
      margin: {
        desktop: {
          top: 'mt-4',
          bottom: 'mb-4',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      alignItems: 'items-center',
      borderRadius: 'rounded-md',
      height: 'full',
      justifyContent: 'justify-start',
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      width: 'w-full',
    },
    render: ({
      margin,
      alignItems,
      borderRadius,
      height,
      justifyContent,
      padding,
      background,
      width,
      border,
      fontQuillEditor,
      showComponent,
    }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`flex   ${alignItems} ${borderRadius}  ${height} ${justifyContent} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}  ${width}
       ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}
              style={{
                transition: 'background-color 0.3s',
                backgroundColor: background.backgroundColor,
              }}>
              {border && (
                <div className="flex-grow border-b border-black mx-2"></div>
              )}
              <div className={`${!border ? 'w-full' : ''}`}>
                <div
                  onMouseOver={e =>
                    (e.currentTarget.style.backgroundColor =
                      background?.hoverColor)
                  }
                  onMouseOut={e => {
                    e.currentTarget.style.backgroundColor =
                      background?.backgroundColor;
                  }}
                  className="quill-text"
                  dangerouslySetInnerHTML={{ __html: fontQuillEditor }}
                />
              </div>
              {border && (
                <div className="flex-grow border-b border-black mx-2"></div>
              )}
            </div>
          )}
        </>
      );
    },
  };
};
