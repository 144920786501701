import { createSlice } from '@reduxjs/toolkit';

export interface IColoumnFiltereConfig {
  orderColumnFilter: string[];
  transactionColumnFilter: string[];
  subscriptionColumnFilter: string[];
  customerColumnFilter: string[];
}

const initialState: IColoumnFiltereConfig = {
  orderColumnFilter: [],
  transactionColumnFilter: [],
  subscriptionColumnFilter: [],
  customerColumnFilter: [],
};

export const ColoumnFilterSlice = createSlice({
  name: 'coloumnFilterSlice',
  initialState,
  reducers: {
    setMyColoumnDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    clearColoumnDetails: () => initialState,
  },
});

export const { setMyColoumnDetails, clearColoumnDetails } =
  ColoumnFilterSlice.actions;

export default ColoumnFilterSlice.reducer;
