export const FastDeliveryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 223 226">
      <path
        fill="#FDFDFD"
        d="M224 123v104c-18.02 0-36.041 0-54.395-.375.491-.465 1.32-.513 2.135-.65 31.36-5.271 53.226-37.252 47.106-68.889-6.209-32.097-38.248-53.67-69.5-46.798-27.283 6-46.255 28.517-47.305 56.144-.994 26.138 16.587 50.26 42.288 58.085 2.265.69 4.45 1.649 6.671 2.483H1c0-12.352 0-24.707.228-37.514.228-.45.671-.34.788.013 2.253 1.082 4.34 2.182 6.536 2.484 14.335 1.965 28.692 3.76 43.043 5.605 13.328 1.713 26.656 3.42 40.557 5.203-2.082-4.478-2.292-9.245-8.563-9.694-8.778-.629-17.503-2.031-26.243-3.165-15.782-2.049-31.558-4.144-47.43-6.232V45.544l38.928 5.276-2.69 65.547c6.7-3.755 12.86-6.957 18.738-10.61 2.826-1.756 4.808-1.726 7.49.248 5.93 4.363 12.13 8.362 19.036 13.067V56.58l64.233 8.737v25.52h8.357c0-7.718-.2-15.197.14-22.651.085-1.863 1.447-4.33 2.995-5.362 13.259-8.848 26.695-17.43 40.08-26.09 2.432-1.574 4.87-3.138 7.772-5.007 0 26.254-.045 51.726.031 77.199.02 6.467 4.021 10.63 8.974 14.074z"
      />
      <path
        fill="#040404"
        d="M224 122.531c-4.953-2.974-8.954-7.138-8.974-13.605-.076-25.473-.03-50.945-.03-77.2-2.903 1.87-5.341 3.434-7.774 5.008-13.384 8.66-26.82 17.242-40.08 26.09-1.547 1.033-2.91 3.499-2.994 5.362-.34 7.454-.14 14.933-.14 22.65h-8.357V65.318L91.418 56.58v62.492c-6.907-4.705-13.105-8.704-19.036-13.067-2.682-1.974-4.664-2.004-7.49-.248-5.878 3.653-12.037 6.855-18.738 10.61l2.69-65.547-38.929-5.276V183.7c15.873 2.088 31.65 4.183 47.431 6.232 8.74 1.134 17.465 2.536 26.243 3.165 6.27.449 6.48 5.216 8.563 9.694-13.9-1.783-27.23-3.49-40.557-5.203-14.35-1.846-28.708-3.64-43.043-5.605-2.196-.302-4.283-1.402-6.47-2.959a50690.29 50690.29 0 0 1-.077-150.493c2.098-1.454 4.032-2.718 6.109-3.669 16.677-7.629 33.408-15.14 50.064-22.813C65.853 8.512 73.397 4.692 81 1c1.714 0 3.429 0 5.74.38 18.676 2.782 36.759 5.156 54.833 7.592 25.305 3.411 50.6 6.898 75.91 10.27 3.167.422 5.5 1.586 6.517 4.758 0 4.69 0 9.379-.368 14.758-.433 14.677-.507 28.663-.522 42.65 0 .53.58 1.061.89 1.592v39.531m-71.46-103.53c-2.034.407-4.238.452-6.065 1.304-2.99 1.393-5.74 3.303-8.58 5.01-12.857 7.721-25.71 15.448-39.005 23.439 1.254.398 1.868.693 2.51.781 17.94 2.462 35.875 4.973 53.84 7.241 1.905.24 4.257-.537 5.954-1.575 14.297-8.737 28.494-17.639 42.705-26.516.9-.562 1.666-1.334 2.881-2.324-18.207-2.496-35.78-4.905-54.24-7.36M21.986 38.74c9.547 1.305 19.078 2.747 28.65 3.82 2.46.277 5.493.13 7.512-1.078 14.516-8.68 28.843-17.676 43.21-26.604.973-.604 1.783-1.47 2.963-2.46-2.671-.424-4.627-.857-6.604-1.027-5.098-.438-10.974-2.587-15.18-.82C62.122 19.15 42.153 28.79 21.987 38.74z"
      />
      <path
        fill="#020202"
        d="M151.468 227c-2.69-.834-4.874-1.793-7.14-2.483-25.7-7.826-43.28-31.947-42.287-58.085 1.05-27.627 20.022-50.144 47.304-56.144 31.253-6.873 63.292 14.7 69.501 46.798 6.12 31.637-15.745 63.618-47.106 68.89-.814.136-1.644.184-2.603.649-5.825.375-11.513.375-17.67.375m15.993-37.996c2.834.924 5.628 2.006 8.511 2.736 5.146 1.302 9.586-1.077 11.151-5.75 1.55-4.627-.718-9.063-5.806-11.004-2.934-1.12-5.92-2.191-8.973-2.884-3.05-.692-4.3-2.01-4.129-5.353.315-6.136.182-12.302.06-18.452-.118-5.849-3.485-9.475-8.58-9.554-5.338-.083-8.814 3.614-8.864 9.686-.076 9.145-.058 18.29-.006 27.436.036 6.25 1.665 8.318 7.846 10.352 2.667.878 5.33 1.773 8.79 2.787z"
      />
      <path
        fill="#FDFDFD"
        d="M80.531 1c-7.134 3.692-14.678 7.512-22.353 11.049-16.656 7.674-33.387 15.184-50.064 22.813-2.077.95-4.01 2.215-6.331 3.733C1.463 38.995 1 39 1 39V1h79.531z"
      />
      <path
        fill="#FCFCFC"
        d="M224 23.531c-1.017-2.703-3.35-3.867-6.516-4.289-25.31-3.372-50.606-6.859-75.91-10.27-18.075-2.436-36.158-4.81-54.406-7.592C132.667 1 178.333 1 224 1v22.531z"
      />
      <path
        fill="#474747"
        d="M1 39.469c0-.469.462-.475.684-.54.264 49.713.305 99.49.281 149.743-.066.474-.51.363-.737.346C1 139.312 1 89.625 1 39.468z"
      />
      <path
        fill="#7A7A7A"
        d="M224 82.531c-.31-.062-.89-.593-.89-1.124.015-13.986.09-27.972.522-42.183.368 14.13.368 28.484.368 43.307z"
      />
      <path
        fill="#FCFCFC"
        d="M152.983 19.024a26511.06 26511.06 0 0 1 53.797 7.337c-1.215.99-1.982 1.762-2.88 2.324-14.212 8.877-28.409 17.78-42.706 26.516-1.697 1.038-4.049 1.816-5.954 1.575-17.965-2.268-35.9-4.78-53.84-7.24-.642-.089-1.256-.384-2.51-.782 13.296-7.991 26.148-15.718 39.004-23.44 2.842-1.706 5.592-3.616 8.58-5.01 1.828-.851 4.032-.896 6.509-1.28z"
      />
      <path
        fill="#FAFAFA"
        d="M22.006 38.397c20.147-9.607 40.116-19.248 60.531-27.826 4.206-1.767 10.082.382 15.18.82 1.977.17 3.933.603 6.604 1.028-1.18.99-1.99 1.855-2.962 2.459-14.368 8.928-28.695 17.924-43.211 26.604-2.02 1.208-5.052 1.355-7.512 1.079-9.572-1.074-19.103-2.516-28.63-4.164z"
      />
      <path
        fill="#FCFCFC"
        d="M167.063 188.94c-3.063-.95-5.726-1.845-8.393-2.723-6.18-2.034-7.81-4.101-7.846-10.352-.052-9.145-.07-18.291.006-27.436.05-6.072 3.526-9.769 8.865-9.686 5.094.08 8.461 3.705 8.578 9.554.123 6.15.256 12.316-.059 18.452-.172 3.343 1.08 4.66 4.13 5.353 3.052.693 6.038 1.764 8.972 2.884 5.088 1.941 7.356 6.377 5.806 11.004-1.565 4.673-6.005 7.052-11.15 5.75-2.884-.73-5.678-1.812-8.91-2.8z"
      />
    </svg>
  );
};
