import React, { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import 'layouts/sideNavigation.scss';
import { ReactComponent as Phoenix1 } from 'assets/icons/phoenixWithoutName.svg';
import { ReactComponent as Phoenix } from 'assets/icons/phoenix_icon.svg';
import { ReactComponent as DoubleArrow } from 'assets/icons/arrow-left-double-fill.svg';
import { sideNavigationItems } from 'layouts/constant';
import { getStoreDetailsPath } from 'router/constant';
import { useAppDispatch } from 'hooks/reduxHooks';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import { TopNavigation } from 'layouts/TopNavigation';
import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import { currentEnvironment } from 'utils/constants';
import DisclosurePage from 'layouts/DisclosurePage';
import { isFeatureEnabled } from 'utils/helper';

interface routeTo {
  [key: string]: string;
}

export const SideNavigation = () => {
  const [expanded, setExpanded] = useState(false);
  const [childExpanded, setChildExpanded] = useState<string[]>([]);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [currentModule, setCurrentModule] = useState('');
  const dispatch = useAppDispatch();
  const filterConfig = useAppSelector(
    (state: RootState) => state.pathConfig.currentFilter,
  );
  const data = useAppSelector(state => state.permission);
  const getPages = (): string[] => {
    const newArray: string[] = [];
    for (const k in data) {
      if (!data[k as keyof typeof data]) {
        newArray.push(k);
      }
    }
    return newArray;
  };
  const [pages, setPages] = useState<string[]>(getPages());

  const sideNavigationFilteredItems = sideNavigationItems.reduce(
    (acc, item) => {
      const showItem = isFeatureEnabled(item.pageName);
      // Filter out items based on pages and showItem
      const shouldIncludeItem =
        !pages.includes(item.permissionKey.toLowerCase()) && showItem;

      if (!shouldIncludeItem) return acc;

      // Handle children if they exist
      if (item.children) {
        const filteredChildren = item.children.reduce(
          (childAcc, child) => {
            // Check if the child should be included
            const shouldIncludeChild = isFeatureEnabled(child.pageName);

            if (shouldIncludeChild) {
              childAcc.push(child);
            }
            return childAcc;
          },
          [] as typeof item.children,
        );

        // Add the item with filtered children if any
        if (filteredChildren.length > 0) {
          acc.push({ ...item, children: filteredChildren });
        } else {
          delete item.children;
          acc.push(item);
        }
      } else {
        // Add item without children
        acc.push(item);
      }

      return acc;
    },
    [] as typeof sideNavigationItems,
  );

  useEffect(() => {
    dispatch(setCurrentFilter(''));
  }, []);
  useEffect(() => {
    setPages(getPages());
  }, [data]);
  useEffect(() => {
    if (filterConfig) {
      setExpanded(true);
    }
  }, [filterConfig]);

  const routeFunctions: routeTo = {
    storeFront: getStoreDetailsPath(storeIds?.[0]?.ID || '', 1),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`app_layout ${filterConfig && 'app_shrink'}`}>
      <div
        className={`side_navigation ${expanded === true ? 'side_navigation_close' : ''}`}>
        <div className="side_nav_header" key={'side_nav_header'}>
          {expanded === false ? (
            <>
              <Phoenix className="side_nav_logo" />
              <DoubleArrow
                className="arrow_icon"
                style={{ cursor: 'pointer' }}
                onClick={() => setExpanded(true)}
              />
            </>
          ) : (
            <Phoenix1
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setExpanded(false);
              }}
            />
          )}
        </div>
        <div className="side_nav_body" key={'side_nav_body'}>
          {sideNavigationFilteredItems.map((navItem, key) => {
            return (
              <div key={key}>
                <div className="navLink-subLists">
                  <div
                    onClick={() => {
                      if (navItem.children?.length) {
                        setExpanded(false);
                      }
                      setChildExpanded([navItem.key]);
                    }}>
                    {navItem.path ? (
                      <NavLink
                        key={navItem.key}
                        to={navItem.path}
                        onClick={() => {
                          setCurrentModule(navItem.key);
                        }}
                        className={({ isActive }) =>
                          `nav_item ${isActive ? `active_item  ${expanded === true ? 'active_item_close' : ''}` : ''}`
                        }>
                        <navItem.icon className="nav_item_icon" />
                        <div className="nav_item_wrapper">
                          <p className={`${expanded != false ? 'op' : ''}`}>
                            {navItem.name}
                          </p>
                        </div>
                      </NavLink>
                    ) : (
                      <div
                        className={`nav_item ${currentModule === navItem.key ? `active_item  ${expanded === true ? 'active_item_close' : ''}` : ''}`}>
                        <navItem.icon className="nav_item_icon" />
                        <div className="nav_item_wrapper">
                          <p className={`${expanded != false ? 'op' : ''}`}>
                            {navItem.name}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {!expanded &&
                    navItem.children?.length &&
                    (childExpanded.includes(navItem.key) ? (
                      <KeyboardArrowDownIcon
                        className="navList-Subitems-icon"
                        onClick={() => {
                          setChildExpanded(
                            childExpanded.filter(item => item !== navItem.key),
                          );
                        }}
                      />
                    ) : (
                      <KeyboardArrowRightIcon
                        className="navList-Subitems-icon"
                        onClick={() => {
                          setChildExpanded([...childExpanded, navItem.key]);
                        }}
                      />
                    ))}
                </div>
                {navItem.children && navItem.children?.length && (
                  <div
                    className={`nav-child-wrapper ${!expanded && childExpanded.includes(navItem.key) ? '' : 'hidden-submenu'}`}>
                    <ul className={`accordion nav-sublist`}>
                      {navItem?.children?.map((child, index) => {
                        if (child.childrenNext) {
                          return (
                            <>
                              <li className="navLink-subLists">
                                <div
                                  className={`nav_item ${childExpanded.includes(child.key) ? `active_item  ${expanded === true ? 'active_item_close' : ''}` : ''}`}
                                  onClick={() => {
                                    if (child.childrenNext?.length) {
                                      setExpanded(false);
                                    }
                                    setChildExpanded([navItem.key, child.key]);
                                  }}>
                                  {/* <navItem.icon className="nav_item_icon" /> */}

                                  {child.path ? (
                                    <NavLink key={child.key} to={child.path}>
                                      <div className="nav_item_wrapper">
                                        <p
                                          className={`${expanded != false ? 'op' : ''}`}>
                                          {child.name}
                                        </p>
                                      </div>
                                    </NavLink>
                                  ) : (
                                    <div className="nav_item_wrapper">
                                      <p
                                        className={`${expanded != false ? 'op' : ''}`}>
                                        {child.name}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {!expanded &&
                                  child.childrenNext?.length &&
                                  (childExpanded.includes(child.key) ? (
                                    <KeyboardArrowDownIcon
                                      className="navList-Subitems-icon"
                                      onClick={() => {
                                        setChildExpanded([
                                          navItem.key,
                                          child.key,
                                        ]);
                                      }}
                                    />
                                  ) : (
                                    <KeyboardArrowRightIcon
                                      className="navList-Subitems-icon"
                                      onClick={() => {
                                        setChildExpanded([
                                          navItem.key,
                                          child.key,
                                        ]);
                                      }}
                                    />
                                  ))}
                              </li>
                              <div
                                className={`nav-child-wrapper ${
                                  !expanded && childExpanded.includes(child.key)
                                    ? ''
                                    : 'hidden-submenu'
                                }`}>
                                <ul className={`accordion nav-sublist`}>
                                  {child.childrenNext?.map((value, index) => {
                                    return (
                                      <li key={index}>
                                        <NavLink
                                          key={value.key}
                                          to={value.path}
                                          onClick={() => {
                                            setCurrentModule(navItem.key);
                                          }}
                                          className={({ isActive }) => {
                                            if (
                                              isActive &&
                                              currentModule !== navItem.key
                                            ) {
                                              setCurrentModule(navItem.key);
                                              setChildExpanded(
                                                childExpanded.includes(
                                                  value.key,
                                                )
                                                  ? childExpanded
                                                  : [
                                                      navItem.key,
                                                      child.key,
                                                      value.key,
                                                    ],
                                              );
                                            }
                                            return `nav_item ${isActive && currentModule === navItem.key ? 'active_item' : ''}`;
                                          }}>
                                          {value.icon &&
                                            React.createElement(value.icon, {
                                              className: 'nav_item_icon',
                                            })}
                                          {value.name}
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </>
                          );
                        } else
                          return (
                            <li key={index}>
                              <NavLink
                                key={child.key}
                                to={
                                  child.path
                                    ? child.path
                                    : child.routeTo
                                      ? routeFunctions[child.routeTo]
                                      : ''
                                }
                                onClick={() => {
                                  setCurrentModule(navItem.key);
                                  setChildExpanded([navItem.key, child.key]);
                                }}
                                className={({ isActive }) => {
                                  if (
                                    isActive &&
                                    currentModule !== navItem.key
                                  ) {
                                    setCurrentModule(navItem.key);
                                    setChildExpanded(
                                      childExpanded.includes(navItem.key)
                                        ? childExpanded
                                        : [...childExpanded, navItem.key],
                                    );
                                  }
                                  return `nav_item ${isActive && currentModule === navItem.key ? 'active_item' : ''}`;
                                }}>
                                {child.icon &&
                                  React.createElement(child.icon, {
                                    className: 'nav_item_icon',
                                  })}
                                {child.name}
                              </NavLink>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            );
            // }
          })}
        </div>
      </div>
      {!expanded && (
        <div className="sidenavOverlay" onClick={() => setExpanded(true)}></div>
      )}
      <div
        className={`right_container ${expanded === true ? 'right_container_close' : ''}`}>
        <TopNavigation />
        <div className="app_container">
          <Outlet />
        </div>
      </div>
      <DisclosurePage />
    </div>
  );
};
