import { Controller, useFormContext } from 'react-hook-form';
import 'components/onboarding/formComponents/storeDetailsPage/style.scss';
import { FC } from 'react';
import TextField from 'components/common/textField';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CommonStepper from 'components/common/stepper';
import { Button } from 'shared-components';
import { stepperSteps } from 'utils/constants';
import { IStoreDetailsForm } from 'interface/storeInterface';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  storeDetailsFormSubmit: (_: IStoreDetailsForm) => void;
}
const Step3: FC<IProps> = ({ setStep, storeDetailsFormSubmit }) => {
  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useFormContext<IStoreDetailsForm>();

  return (
    <div className="onboardingStep3">
      <div className="heading-text-area">
        <p className="text-bold">Phoenix Integration Made Easy</p>
        <p className="text">Seamless Payment Gateway Configuration</p>
      </div>
      <div className="stepper-section">
        <CommonStepper steps={stepperSteps} activeStep={2} />
      </div>
      <div className="onboarding-fields">
        <div className="onboarding-field">
          <Controller
            name="payment_gateway.Secret"
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => {
              return (
                <TextField
                  {...rest}
                  className="onboarding-input"
                  placeholder="Enter secret token"
                  errorString={error?.message}
                  label="Phoenix secret token"
                  fixedSize="md"
                  ref={ref}></TextField>
              );
            }}
          />
        </div>
      </div>
      <div className="button-field">
        <div className="button">
          <button
            type="button"
            className="wrapper"
            onClick={() => {
              setStep(1);
            }}>
            <ArrowBack />
            <p>Back</p>
          </button>
        </div>
        <div className="button">
          <Button
            variant="phoenix"
            className="submit"
            type="submit"
            onClick={() => {
              if (isValid) setValue('is_gateway_skipped', false);
            }}
            label="SUBMIT"
          />
        </div>
      </div>
      <div className="skip-section">
        <div className="field"></div>
        <button
          type="button"
          className="skip"
          onClick={() => {
            const data = getValues();
            storeDetailsFormSubmit(data as IStoreDetailsForm);
            setValue('is_gateway_skipped', true);
          }}>
          <p>Skip and Submit</p>
        </button>
      </div>
    </div>
  );
};

export default Step3;
