import { FC } from 'react';
import 'components/common/pagination/style.scss';
import { Select } from 'shared-components';
import { Pagination } from '@mui/material';

interface IProps {
  perPage: number;
  currentPage: number;
  onHandleChange: (_value: number) => void;
  onRowsPerChange: (_value: number) => void;
  isNextDisabled?: boolean;
  limit: number;
  totalData: number;
}
export const pagesCount = {
  TwentyFive: 25,
  Fifty: 50,
  Hundread: 100,
};
export const pageCountOptions = Object.values(pagesCount).map(range => ({
  label: range,
  value: range,
}));

const CustomPagination: FC<IProps> = ({
  onHandleChange,
  onRowsPerChange,
  totalData,
  limit,
  currentPage,
}) => {
  const pageStart = totalData ? (currentPage - 1) * limit + 1 : 0;
  const pageEnd =
    pageStart + limit < totalData
      ? pageStart + limit - 1
      : pageStart + (totalData - pageStart);
  return (
    <div className="pagination_container">
      <div className="common_pagination_container">
        <div className="row_content_decider">
          <span className="rows_page">Rows per Page</span>
          <Select
            value={limit}
            onChange={val => {
              onRowsPerChange(val);
              onHandleChange(1);
            }}
            options={pageCountOptions}
          />
        </div>
        <div className="pagination-details">
          {`${pageStart} - ${pageEnd} of ${totalData}`}
        </div>
        {totalData ? (
          <Pagination
            className="pagination-container"
            count={Math.ceil(totalData / limit)}
            page={currentPage}
            onChange={(e, page) => onHandleChange(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CustomPagination;
