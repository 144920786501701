import React from 'react';

interface PostUpSellRatingProps {
  ratingValue: number;
  totalRatings: number;
}

export const PostUpSellRating: React.FC<PostUpSellRatingProps> = ({
  ratingValue,
  totalRatings,
}) => {
  const filledStars = Math.round(ratingValue);
  return (
    <>
      <div className="flex items-center gap-2">
        <div className="flex justify-center">
          {[1, 2, 3, 4, 5].map(index => (
            <svg
              key={index}
              className={`h-8 w-auto fill-current ${
                index <= filledStars ? 'text-yellow-500' : 'text-gray-300'
              }`}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1.3l2.388 7.49H20l-6.194 4.51L15.622 21 10 16.59 4.377 21l1.813-7.7L0 8.79h7.611z" />
            </svg>
          ))}
        </div>
        <div className="text-xl font-medium text-center">
          {totalRatings} + Ratings
        </div>
      </div>
    </>
  );
};
