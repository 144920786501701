import { FC, useCallback, useState } from 'react';
import Step1 from 'components/onboarding/formComponents/storeDetailsPage/stepperForm/Step1';
import Step2 from 'components/onboarding/formComponents/storeDetailsPage/stepperForm/Step2';
import Step3 from 'components/onboarding/formComponents/storeDetailsPage/stepperForm/Step3';
import { IStoreDetailsForm } from 'interface/storeInterface';

interface IProps {
  storeDetailsFormSubmit: (_: IStoreDetailsForm) => void;
}

const StoreDetailsPage: FC<IProps> = ({ storeDetailsFormSubmit }) => {
  const [step, setStep] = useState<number>(0);

  const renderStepper = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return <Step1 setStep={setStep} />;
        case 1:
          return <Step2 setStep={setStep} />;
        case 2:
          return (
            <Step3
              setStep={setStep}
              storeDetailsFormSubmit={storeDetailsFormSubmit}
            />
          );
        default:
          return <div>No Step</div>;
      }
    },
    [step],
  );

  return <div>{renderStepper(step)}</div>;
};

export default StoreDetailsPage;
