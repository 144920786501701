import Store from 'components/store';

const ShopifyStoresPage = () => {
  return (
    <div className="h-full overflow-auto">
      <Store />
    </div>
  );
};

export default ShopifyStoresPage;
