import { AxiosError } from 'axios';
import { baseService } from 'services';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  commonError,
  createProcessrorAssigments,
  getProcessrorAccountsAssigments,
} from 'services/constants';

import {
  IProcessorAssignment,
  IProcessorBody,
} from 'interface/processorAssignment';

class ProcessorAssignmentMockService {
  commonFunction = async (): Promise<
    ISuccessResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createProcessorAccounts = async () => {
    return this.commonFunction();
  };

  getProcessorAccounts = async () => {
    return this.commonFunction();
  };
}
class ProcessorAssignmentService {
  storeFrontMockService = new ProcessorAssignmentMockService();

  createProcessorAccounts = async (body: IProcessorAssignment) => {
    try {
      const response = await baseService.post(createProcessrorAssigments, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getProcessorAccounts = async (body: IProcessorBody) => {
    try {
      const response = await baseService.post(
        getProcessrorAccountsAssigments,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalProcessorAssigmentService:
  | ProcessorAssignmentService
  | ProcessorAssignmentMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new ProcessorAssignmentMockService()
    : new ProcessorAssignmentService();

export default globalProcessorAssigmentService;
