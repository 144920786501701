import { Fields } from '@measured/puck';

export type AlignItemsProps = {
  alignItems: string;
};

export const alignItemsField: Fields<AlignItemsProps> = {
  alignItems: {
    type: 'select',
    label: 'align-items',
    options: [
      { label: 'center', value: 'items-center' },
      { label: 'start', value: 'items-start' },
      { label: 'end', value: 'items-end' },
      { label: 'baseline', value: 'items-baseline' },
      { label: 'stretch', value: 'items-stretch' },
    ],
  },
};
