import { AxiosError } from 'axios';
import { baseService } from 'services';
import { ISuccessResponse } from 'interface/apiInterface';
import { IDeteleNotes, IGetNotes, INotes } from 'interface/notesInterface';
import { mockNotesData } from 'utils/mockData';
import {
  commonError,
  getNotesEP,
  postNotesEP,
  updateNotesEP,
  deletesNotesEp,
} from 'services/constants';

class NotesMockService {
  getNotes = async () => {
    try {
      return await new Promise<ISuccessResponse<{ Notes: IGetNotes[] }>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: { Notes: mockNotesData },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  postNotes = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateNotes = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteNotes = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class NotesService {
  NotesMockService = new NotesMockService();

  getNotes = async (
    store_id: string,
    resource_type: string,
    resource_id: string,
  ) => {
    try {
      const response = await baseService.get<{ Notes: IGetNotes[] }>(
        getNotesEP(store_id, resource_type, resource_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  postNotes = async (body: INotes) => {
    try {
      const response = await baseService.post<{
        ID: string;
      }>(postNotesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateNotes = async (body: INotes) => {
    try {
      const response = await baseService.post<{
        ID: string;
      }>(updateNotesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteNotes = async (note: IDeteleNotes) => {
    try {
      const response = await baseService.post<INotes>(deletesNotesEp, note);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalnotesService: NotesService | NotesMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new NotesMockService()
    : new NotesService();

export default globalnotesService;
