import { FieldLabel } from '@measured/puck';
import CustomTooltip from '../../../components/common/tooltip';
import { toolTipInfo } from '../../utils/constants';

export interface ColorSelectorProps {
  value: string;
  tooltip: keyof typeof toolTipInfo;
  label: string;
  onChange: (_event: string) => void;
}

export default function ColorSelector({
  value,
  onChange,
  tooltip,
  label,
}: ColorSelectorProps) {
  return (
    <FieldLabel
      label={
        (
          <CustomTooltip title={tooltip}>
            <span>{label}</span>
          </CustomTooltip>
        ) as React.ReactNode as string
      }
      el="div"
      className="w-full">
      <div className="grid items-center gap-2 grid-cols-[max-content_80%]">
        <input
          type="color"
          name="colorPicker"
          value={value}
          className="w-10 h-full"
          onChange={event => onChange(event?.target?.value)}
        />
        <input
          type="text"
          name="colorPicker"
          value={value}
          onChange={event => onChange(event?.target?.value)}
        />
      </div>
    </FieldLabel>
  );
}
