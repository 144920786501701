import { GuaranteeData } from './customerGuarantees';

export const Guarantee = ({ id, image, name, description }: GuaranteeData) => {
  return (
    <div key={id} className="flex items-center mt-10">
      <div className="border-2 border-white p-2 bg-white">
        <img className="max-w-16" src={image?.BlobURL} alt={image?.Filename} />
      </div>
      <div className="ml-2">
        <div className="flex">
          <div className="flex items-center">
            <p className="font-bold text-sm">{name}</p>
          </div>
        </div>
        <dt className="text-gray-600 text-sm">{description}</dt>
      </div>
    </div>
  );
};
