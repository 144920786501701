import { FC, useState } from 'react';
import 'components/transactions/transactionFilter/actionFilter/style.scss';
import TagAction from 'components/Actions/tags';
import {
  IStoreFrontEventResponse,
  IStoreFrontEventUpdate,
} from 'interface/storeInterface';
import { showNotification } from 'shared-components';
import globalStoreFrontService from 'services/storeFrontService';

interface IProps {
  selected?: Map<string, IStoreFrontEventResponse>;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  resetSelected: () => void;
  searchAPI: () => void;
  filterList: string[];
}

const ActionFilter: FC<IProps> = ({
  filterList,
  selected,
  setLoading,
  resetSelected,
  searchAPI,
}) => {
  const [isPopupOpen, setPopupOpen] = useState<{
    tags: boolean;
  }>({
    tags: false,
  });
  const [textValue, setTextValue] = useState<string[]>([]);
  const handleClose = (value: string) => {
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };

  const handleOpen = (value: string) => {
    value = value.toLowerCase().replace(/[\s&()]/g, match => {
      if (match === ' ') return '_';
      return '';
    });
    if (selected && selected.size === 0) {
      showNotification(
        'error',
        'Please select at least one event to perform this action',
      );
      return;
    }
    if (selected && selected.size > 1) {
      showNotification(
        'error',
        'Please select only one event to perform this action',
      );
      return;
    }
    setTextValue(selected?.values().next().value?.Tags || []);

    setPopupOpen(pre => ({ ...pre, [value]: true }));
  };

  const updateTag = async (value?: string | null) => {
    setLoading(pre => pre + 1);
    if (selected?.size) {
      await Promise.all(
        [...selected.values()]?.map(async data => {
          const payload: IStoreFrontEventUpdate = {
            ID: data.ID,
            Resolved: data.Resolved || false,
            Version: data.Version || 0,
            Tags: value ? [value] : [],
          };
          if (textValue.length > 0 || value) {
            payload.Tags = [...textValue, ...(value ? [value] : [])];
          }
          const res = await globalStoreFrontService.updateStoreFrontEvents({
            ...payload,
          });
          if (res.status === 200) {
            data.Version = data?.Version + 1;
            showNotification('success', 'Tag updated successfully');
            handleClose('tags');
            searchAPI();
            resetSelected();
          }
        }),
      );
    }
    setLoading(pre => pre - 1);
  };

  return (
    <div className="action_filter_container">
      {isPopupOpen.tags && selected?.size && (
        <TagAction
          open={isPopupOpen.tags}
          handleClose={() => {
            handleClose('tags');
          }}
          updateFunction={(value?: string | null) => {
            updateTag(value);
          }}
          textValue={textValue}
          setTextValue={setTextValue}
          tagAutoCompleteID="transactionTag"
        />
      )}
      {filterList?.map((cols: string) => {
        return (
          <div
            className="action_filter_columns"
            key={cols}
            onClick={() => {
              handleOpen(cols);
            }}>
            {cols}
          </div>
        );
      })}
    </div>
  );
};

export default ActionFilter;
