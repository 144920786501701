import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import 'components/cascade/MerchantTable/style.scss';
import ActionLogsTable, { UserLogs } from 'components/cascade/ActionLogsTable';

interface Merchant {
  name: string;
  traffic: number;
  revenueLimit: number;
  transactionLimit: number;
}
const merchantsData: UserLogs[] = [
  {
    name: 'Benjamin Thompson',
    traffic: 5,
    revenueLimit: 2200.0,
    transactionLimit: 100,
  },
  {
    name: 'Sophia Rodriguez',
    traffic: 30,
    revenueLimit: 1200.0,
    transactionLimit: 80,
  },
  {
    name: 'Isabella Martinez',
    traffic: 10,
    revenueLimit: 1000.0,
    transactionLimit: 80,
  },
  {
    name: 'Jackson Williams',
    traffic: 2,
    revenueLimit: 500.0,
    transactionLimit: 50,
  },
  {
    name: 'Liam Smith',
    traffic: 8,
    revenueLimit: 850.0,
    transactionLimit: 60,
  },
];

interface Log {
  timestamp: string;
  merchants: Merchant[];
}

const logs: Log[] = [
  {
    timestamp: '08/04/2024 11:39 AM',
    merchants: [],
  },
  {
    timestamp: '06/04/2024 05:40 PM',
    merchants: [
      {
        name: 'Benjamin Thompson',
        traffic: 5,
        revenueLimit: 2200.0,
        transactionLimit: 100,
      },
      {
        name: 'Sophia Rodriguez',
        traffic: 30,
        revenueLimit: 1200.0,
        transactionLimit: 80,
      },
      {
        name: 'Isabella Martinez',
        traffic: 10,
        revenueLimit: 1000.0,
        transactionLimit: 80,
      },
      {
        name: 'Jackson Williams',
        traffic: 2,
        revenueLimit: 500.0,
        transactionLimit: 50,
      },
      {
        name: 'Liam Smith',
        traffic: 8,
        revenueLimit: 850.0,
        transactionLimit: 60,
      },
    ],
  },
  {
    timestamp: '06/04/2024 02:25 PM',
    merchants: [],
  },
];

const ActionLogs: React.FC = () => {
  return (
    <div className="action-logs">
      {logs.map((log, index) => (
        <Accordion key={index} className="accordion">
          <AccordionSummary
            className="accordion-summary"
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}>
            <Typography>{log.timestamp}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {merchantsData.length > 0 ? (
              <ActionLogsTable
                data={merchantsData}
                paddingClassName="table-cell-padding-2"
              />
            ) : (
              <Typography>No merchants found for this timestamp.</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ActionLogs;
