import { ClickAwayListener } from '@mui/material';
import { useRef, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/iconamoon_search-bold.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import 'components/common/searchFilter/style.scss';
import { SearchProps } from 'components/transactions';
import { Select } from 'shared-components';
import { IFilterOptions } from 'utils/helper';
import { emailRegex, phoneRegex } from 'utils/constants';

interface IFilterValues {
  Name: string;
  Value: string;
  ApiValue: string;
  Comparator: string;
  fieldType?: 'input' | 'select';
  options?: { label: string; value: string }[];
  inputType?: string;
}

const defaultValue = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

// Add custom status options
const statusOptions = [
  { label: 'Completed', value: 'completed' },
  { label: 'Pending', value: 'pending' },
  { label: 'Failed', value: 'failed' },
  { label: 'Voided', value: 'voided' },
  { label: 'Refunded', value: 'refunded' },
];

interface IProp {
  filteredField: { label: string; value: string }[];
  setSearchValue: React.Dispatch<React.SetStateAction<SearchProps>>;
  searchValue: SearchProps;
}

const SearchFilter = ({ filteredField, setSearchValue }: IProp) => {
  const [filterValue, setFilterValue] = useState<IFilterValues[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<IFilterValues | null>(
    null,
  );
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string | null;
  }>({});
  const selectedFilterRef = useRef<HTMLDivElement | null>(null);
  const availableOptions = filteredField.filter(
    field => !filterValue.some(filter => filter.Name === field.label),
  );

  const handleClickOutside = () => {
    setShowFilter(false);
  };

  const handleTextChange = (val: string) => {
    setSelectedFilter(pre => {
      const updatedState = pre && {
        ...pre,
        Value: val,
      };

      const newErrorMessages = { ...errorMessages };

      // Email validation
      if (selectedFilter?.Name?.toLowerCase() === 'email') {
        if (!emailRegex.test(val) && val?.length) {
          newErrorMessages.email = 'Invalid Email';
        } else {
          newErrorMessages.email = null;
        }
      }

      // Phone number validation
      if (
        selectedFilter?.Name?.toLowerCase().replace(/\s+/g, '') ===
        'phonenumber'
      ) {
        if (!phoneRegex.test(val) && val?.length) {
          newErrorMessages.phone = 'Invalid Phone Number';
        } else {
          newErrorMessages.phone = null;
        }
      }

      setErrorMessages(newErrorMessages);

      return updatedState;
    });
  };

  const handleOptionChange = (isOptionClicked = false) => {
    // Prevent the API call if there's a validation error
    if (errorMessages.email || errorMessages.phone) {
      return;
    }
    const search: SearchProps = {};
    setFilterValue(pre =>
      pre.map(data => {
        if (data.Name === selectedFilter?.Name) {
          search[data.ApiValue] = selectedFilter.Value;
          return selectedFilter;
        }
        search[data.ApiValue] = data.Value;
        return data;
      }),
    );
    setSearchValue(search);
    if (!isOptionClicked) setSelectedFilter(null);
    setShowFilter(false);
  };

  const handleCloseIcon = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    tag: IFilterValues,
  ) => {
    e.stopPropagation();
    setErrorMessages(prev => ({
      ...prev,
      ...(tag.Name?.toLowerCase() === 'email' && { email: null }),
      ...(tag.Name?.toLowerCase().replace(/\s+/g, '') === 'phonenumber' && {
        phone: null,
      }),
    }));
    const search: SearchProps = {};
    setFilterValue(
      filterValue.filter(data => {
        if (data.Name !== tag.Name) search[data.ApiValue] = data.Value;
        return data.Name != tag.Name;
      }),
    );
    setSearchValue(search);
    setShowFilter(false);
  };

  const handleOptionClick = (data: IFilterOptions) => {
    let value = '';
    if (data?.searchFiedtType === 'select') {
      value = data?.searchFieldOptions?.[0]?.value ?? '';
    } else if (data?.inputType === 'number') {
      value = '0';
    }
    // Check if the selected field is "status" and set custom options
    const isStatusField = data.label.toLowerCase() === 'status';
    setSelectedFilter({
      Name: data.label,
      Value: value,
      Comparator: 'Equal',
      ApiValue: data.value,
      fieldType: isStatusField ? 'select' : data?.searchFiedtType,
      options: isStatusField ? statusOptions : data?.searchFieldOptions,
      ...(data?.inputType && { inputType: data.inputType }),
    });
    setFilterValue(pre => [
      ...pre,
      {
        Name: data.label,
        Value: '',
        Comparator: 'Equal',
        ApiValue: data.value,
        fieldType: isStatusField ? 'select' : data?.searchFiedtType,
        options: isStatusField ? statusOptions : data?.searchFieldOptions,
      },
    ]);
  };

  return (
    <div className="common_search_container">
      <ClickAwayListener
        onClickAway={() => {
          if (selectedFilter?.Name) {
            handleClickOutside();
            handleOptionChange();
          } else if (showFilter) {
            handleClickOutside();
          }
        }}>
        <div
          className="search_container"
          onClick={() => {
            if (selectedFilter) {
              handleOptionChange(true);
            }
            setShowFilter(true);
            setSelectedFilter(null);
          }}>
          <SearchIcon />
          <p>Filter By:</p>
          <div className="search_input-tag-wrapper">
            {filterValue?.map((tag, index) => {
              return (
                <div
                  key={index}
                  className="tag"
                  ref={
                    tag.Name === selectedFilter?.Name ? selectedFilterRef : null
                  }
                  id={tag.Name}
                  onClick={e => {
                    selectedFilterRef.current = e.currentTarget;
                    e.stopPropagation();
                    if (selectedFilter?.Name === tag.Name) {
                      setSelectedFilter(selectedFilter);
                    } else setSelectedFilter(tag);
                    setShowFilter(false);
                  }}>
                  <div className="tag-name flex gap-2">
                    <span>{tag.Name}:</span>
                    {tag.Name !== selectedFilter?.Name ? (
                      tag?.fieldType === 'select' ? (
                        tag.options?.find(data => data.value === tag?.Value)
                          ?.label || tag?.Value
                      ) : (
                        tag.Value
                      )
                    ) : tag?.fieldType === 'select' ? (
                      <Select
                        className="h-[16px] font-[]"
                        options={tag?.options || defaultValue}
                        value={
                          tag?.options?.find(
                            data => data.value === selectedFilter?.Value,
                          )?.value || 'sss'
                        }
                        onChange={(e: string) => {
                          handleTextChange(e);
                        }}
                      />
                    ) : (
                      <>
                        <input
                          className="input_tag_value"
                          type={selectedFilter?.inputType || 'text'}
                          onChange={e => {
                            if (
                              selectedFilter?.inputType === 'number' &&
                              e?.target?.value === ''
                            ) {
                              handleTextChange('0');
                              return;
                            }
                            handleTextChange(e?.target?.value);
                          }}
                          autoFocus={true}
                          value={selectedFilter?.Value || ''}
                          onClick={() => {
                            setSelectedFilter(tag);
                          }}
                          onKeyDown={e =>
                            e.key === 'Enter' && handleOptionChange()
                          }
                        />
                        {/* Show error message based on the selected filter */}
                        {selectedFilter?.Name?.toLowerCase() === 'email' &&
                          errorMessages.email && (
                            <div className="error-message">
                              {errorMessages.email}
                            </div>
                          )}
                        {selectedFilter?.Name?.toLowerCase().replace(
                          /\s+/g,
                          '',
                        ) === 'phonenumber' &&
                          errorMessages.phone && (
                            <div className="error-message">
                              {errorMessages.phone}
                            </div>
                          )}
                      </>
                    )}
                  </div>

                  <CloseIcon
                    className="closeIcon"
                    onClick={e => {
                      handleCloseIcon(e, tag);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </ClickAwayListener>
      {showFilter && availableOptions?.length ? (
        <div className="filterContainer">
          <div className="filterFields">
            <span className="search-text">Search By:</span>
            <ul className="filterList">
              {availableOptions?.map((data, index) => {
                return (
                  <li
                    key={index}
                    onClick={e => {
                      handleOptionClick(data);
                      e.stopPropagation();
                      handleClickOutside();
                    }}>
                    {data.label}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchFilter;
