export const McafeeLogoIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x="5"
      y="0"
      viewBox="0 0 146.6 114.9">
      <style>{`.mcafeeLogoFill{fill=#fff}`}</style>
      <g id="McAfee_Secure">
        <linearGradient
          id="Path_29_1_"
          x1="-563.997"
          x2="-563.464"
          y1="117.487"
          y2="118.447"
          gradientTransform="matrix(34.917 0 0 36.1299 19730.238 -4202.025)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#646567" />
          <stop offset="0.097" stopColor="#717274" />
          <stop offset="0.317" stopColor="#898b8d" />
          <stop offset="0.54" stopColor="#9a9b9d" />
          <stop offset="0.766" stopColor="#a4a5a7" />
          <stop offset="1" stopColor="#a7a8aa" />
        </linearGradient>
        <path
          id="Path_29"
          d="M40.5 75.6S21 69 23.3 42c5.6-1.5 11.4-2.4 17.2-2.5 5.9.1 11.7.9 17.3 2.5 2.3 26.9-17.3 33.6-17.3 33.6z"
          fill="url(#Path_29_1_)"
        />
        <linearGradient
          id="Path_30_1_"
          x1="-565.134"
          x2="-565.826"
          y1="117.705"
          y2="118.146"
          gradientTransform="matrix(34.7402 0 0 36.1299 19677.041 -4202.025)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#c6c7c8" />
          <stop offset="0.092" stopColor="#dcdddd" />
          <stop offset="0.203" stopColor="#f0f0f0" />
          <stop offset="0.316" stopColor="#fbfbfb" />
          <stop offset="0.433" stopColor="#fff" />
          <stop offset="0.537" stopColor="#f6f7f7" />
          <stop offset="0.704" stopColor="#dfdfe0" />
          <stop offset="0.913" stopColor="#b9babb" />
          <stop offset="1" stopColor="#a7a8aa" />
        </linearGradient>
        <path
          id="Path_30"
          d="M40.5 75.6S21 69 23.3 42c5.6-1.5 11.4-2.4 17.2-2.5 5.9.1 11.7.9 17.3 2.5L56 43.6 40.5 73.9v1.7z"
          fill="url(#Path_30_1_)"
        />
        <path
          id="Path_31"
          d="M40.5 73.9s-17.6-6-15.6-30.5c5.1-1.4 10.3-2.2 15.6-2.3 5.3.1 10.6.9 15.7 2.3 2 24.4-15.7 30.5-15.7 30.5z"
          fill="#fff"
          stroke="#000"
          strokeWidth="0.515"
          strokeLinecap="round"
        />
        <g id="Group_9">
          <path
            id="Path_32"
            d="M40.5 73.9s-17.6-6-15.6-30.5c5.1-1.4 10.3-2.2 15.6-2.3 5.3.1 10.6.9 15.7 2.3 2 24.4-15.7 30.5-15.7 30.5z"
            className="mcafeeLogoFill"
          />
        </g>
        <linearGradient
          id="Path_33_1_"
          x1="-586.396"
          x2="-587.185"
          y1="100.553"
          y2="101.07"
          gradientTransform="matrix(32.1357 0 0 33.1899 18894.791 -3290.497)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#4b4b4d" />
          <stop offset="0.013" stopColor="#4d4d4f" />
          <stop offset="0.253" stopColor="#67686a" />
          <stop offset="0.497" stopColor="#797a7c" />
          <stop offset="0.744" stopColor="#838486" />
          <stop offset="1" stopColor="#87888a" />
        </linearGradient>
        <path
          id="Path_33"
          d="M24.8 43.1h-.2v.2c-1 12.8 3.3 20.5 7.2 24.7 2.4 2.7 5.4 4.8 8.7 6.1l.1.1h.1c3.3-1.3 6.3-3.4 8.7-6.1 3.8-4.2 8.2-11.9 7.1-24.6v-.3h-.2c-5.1-1.4-10.4-2.1-15.7-2.2-5.3.1-10.6.8-15.8 2.1zm16-1.9c5.1.1 10.3.9 15.3 2.2.1 1.2.1 2.7.1 3.8 0 10.3-3.8 16.8-7.2 20.5-2.3 2.6-5.2 4.7-8.5 6-3.2-1.3-6.1-3.4-8.5-6-3.3-3.7-7.1-10.1-7.1-20.5 0-1.1.1-2.6.2-3.8 1.2-.1 8.5-2.2 15.7-2.2z"
          fill="url(#Path_33_1_)"
        />
        <linearGradient
          id="Path_34_1_"
          x1="-647.465"
          x2="-646.646"
          y1="42.052"
          y2="42.65"
          gradientTransform="matrix(26.5312 0 0 25.9404 17211.217 -1042.708)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#8b1325" />
          <stop offset="0.607" stopColor="#cb0233" />
          <stop offset="0.708" stopColor="#cb0233" />
          <stop offset="1" stopColor="#8a193f" />
        </linearGradient>
        <path
          id="Path_34"
          d="M40.5 70.8s-14.8-5.4-13.1-25.9c0 0 6.5.5 13.1.5s13-.5 13.2-.5c1.7 20.5-13.2 25.9-13.2 25.9z"
          fill="url(#Path_34_1_)"
        />
        <radialGradient
          id="Path_35_1_"
          cx="-9796.056"
          cy="-6482.903"
          r="23.551"
          gradientTransform="matrix(.979 0 0 .979 -6.153 -546.24)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#cb0233" />
          <stop offset="0.461" stopColor="#cb0233" />
          <stop offset="1" stopColor="#8a193f" />
        </radialGradient>
        <path
          id="Path_35"
          d="M40.5 69.1s-12.8-4.7-11.3-22.4c0 0 5.6.4 11.3.4s11.2-.4 11.4-.4C53.4 64.3 40.5 69 40.5 69.1z"
          fill="url(#Path_35_1_)"
        />
        <path
          id="Path_36"
          d="M53.7 44.9c-.2 0-6.6.5-13.2.5s-13.1-.5-13.1-.5l1.8 1.8s5.6.4 11.3.4 11.2-.5 11.4-.5l1.8-1.7z"
          fill="#8b1325"
        />
        <linearGradient
          id="Path_37_1_"
          x1="-996.305"
          x2="-994.857"
          y1="42.678"
          y2="41.825"
          gradientTransform="matrix(13.2363 0 0 25.9199 13210.93 -1036.363)"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#8b1325" />
          <stop offset="0.607" stopColor="#cb0233" />
          <stop offset="0.708" stopColor="#cb0233" />
          <stop offset="1" stopColor="#8a193f" />
        </linearGradient>
        <path
          id="Path_37"
          d="M40.5 70.8s-14.8-5.4-13.1-25.9l1.8 1.8c-1.4 17.7 11.3 22.4 11.3 22.4v1.7z"
          fill="url(#Path_37_1_)"
        />
        <g id="Group_11">
          <g id="Group_10">
            <path
              id="Path_38"
              d="m32.6 62 2.1-13.8h3.5L42.6 62h-3.7L37 54.9 35.9 62h-3.3z"
              className="mcafeeLogoFill"
            />
          </g>
        </g>
        <g id="Group_13">
          <g id="Group_12">
            <path
              id="Path_39"
              d="M43.1 48.2h3.5l2 13.8h-3.3l-1.2-7.2-1.2 5.6-1.8-5.6 2-6.6z"
              className="mcafeeLogoFill"
            />
          </g>
        </g>
        <g id="Group_14">
          <path
            id="Path_40"
            d="M72.5 54.9h-2.6l-2.5-6.4-.9 6.4h-2.9L65.5 43h2.8l4.2 11.9zm-1.9-6.1 2.1-5.8h2.9l1.7 11.9h-2.9l-.8-6.4-1.7 3.8-1.3-3.5z"
          />
          <path
            id="Path_41"
            d="m88.1 52.8-.7 2h-2.9l4-11.9h3l3.9 11.9h-2.9l-.7-2h-3.7zm1.9-6.2-1.2 3.8h2.3L90 46.6z"
          />
          <path
            id="Path_42"
            d="M109.9 51.3h-5.6c0 1.2.6 1.8 1.6 1.8.5 0 1-.2 1.3-.7h2.5c-.2.9-.7 1.6-1.4 2.1s-1.5.7-2.4.7c-2.2.2-4.1-1.5-4.3-3.7v-.6c-.2-2.2 1.4-4.1 3.6-4.3h.5c2.6 0 4.1 1.8 4.1 4.5l.1.2zm-2.4-1.7c-.1-.7-.8-1.3-1.5-1.3-.8 0-1.4.5-1.6 1.3h3.1z"
          />
          <path
            id="Path_43"
            d="M119 51.3h-5.6c0 1.2.6 1.8 1.7 1.8.5 0 1-.2 1.3-.7h2.5c-.2.9-.7 1.6-1.4 2.1s-1.5.7-2.4.7c-2.2.2-4.2-1.5-4.3-3.7v-.6c-.2-2.2 1.4-4.1 3.6-4.3h.6c2.6 0 4.1 1.8 4.1 4.5l-.1.2zm-2.4-1.7c-.1-.7-.8-1.3-1.5-1.3-.8 0-1.4.5-1.6 1.3h3.1z"
          />
          <path
            id="Path_44"
            d="M119.4 45.2c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.8-2-2zm.3 0c0 .9.8 1.7 1.7 1.7.9 0 1.7-.8 1.7-1.7 0-.9-.8-1.7-1.7-1.7-.9 0-1.7.7-1.7 1.7zm2.7 1.1h-.4l-.6-1h-.4v1h-.3v-2.2h.9c.4 0 .8.1.8.6 0 .3-.2.6-.5.6h-.2l.7 1zm-1.4-1.9v.6h.4c.3 0 .7 0 .7-.3s-.2-.3-.5-.3h-.6z"
          />
        </g>
        <g id="Group_15">
          <path
            id="Path_45"
            d="M96.4 48.8h-1.5v-2h1.6v-.9c0-2.4 1.1-3.2 3.2-3.2.5 0 .9.1 1.3.1l-.1 1.9c-.3-.1-.5-.1-.8-.1-.7 0-.9.5-.9 1.3v.9h1.9v2h-2v6.1h-2.7v-6.1z"
          />
        </g>
        <g id="Group_16">
          <path
            id="Path_46"
            d="M69.5 60.6c-.7-.3-1.4-.5-2.1-.5-.9 0-2 .3-2 1.5 0 2.1 5 1.6 5 5.3 0 2.4-1.9 3.4-4.2 3.4-.8 0-1.6-.2-2.3-.5l.2-1.5c.8.3 1.6.5 2.4.6.9 0 2.2-.5 2.2-1.9 0-2.5-4.9-1.9-4.9-5.3 0-2 1.7-3 3.7-3 .8 0 1.7.2 2.5.4l-.5 1.5z"
          />
          <path
            id="Path_47"
            d="M72.6 58.9h6.5v1.4h-4.8v3.3h4.4V65h-4.4v3.7h4.8v1.4h-6.5V58.9z"
          />
          <path
            id="Path_48"
            d="M89 60.7c-.7-.4-1.5-.6-2.4-.6-2.4 0-4.4 1.9-4.4 4.3s1.9 4.4 4.3 4.4c.9 0 1.7-.2 2.5-.6l.1 1.5c-.8.3-1.7.5-2.6.5-3.6 0-6.1-2.3-6.1-5.9 0-3.4 2.6-5.8 6.1-5.8.9 0 1.8.1 2.6.5l-.1 1.7z"
          />
          <path
            id="Path_49"
            d="M99.5 65.4c0 3.2-1.7 4.8-4.5 4.8s-4.5-1.7-4.5-4.8v-6.5h1.6v6.4c0 2.1.7 3.6 2.8 3.6s2.8-1.5 2.8-3.6v-6.4h1.7l.1 6.5z"
          />
          <path
            id="Path_50"
            d="M102.1 58.9h2.8c2.3 0 4.5.4 4.5 3 0 1.3-1.1 2.4-2.4 2.5.5.2.7.4 1.1 1.1l2.2 4.5h-1.9l-1.8-3.9c-.5-1.1-1.1-1.1-1.9-1.1h-1v5h-1.6V58.9zm1.6 4.8h1.4c1.6 0 2.5-.7 2.5-1.8 0-1-.8-1.7-2.3-1.7h-1.6v3.5z"
          />
          <path
            id="Path_51"
            d="M112.2 58.9h6.5v1.4h-4.8v3.3h4.4V65h-4.4v3.7h4.8v1.4h-6.5V58.9z"
          />
        </g>
        <path
          id="Path_52"
          d="M83.9 49.3c-.5-.3-1-.5-1.6-.5-1.1 0-2 .9-2 2v.2c0 1.1.9 2.1 2.1 2.1h.1c.6 0 1.1-.1 1.7-.4v2c-.7.3-1.4.4-2.1.4-2.3.2-4.2-1.5-4.4-3.8v-.4c-.1-2.3 1.7-4.2 4-4.2h.4c.7 0 1.4.1 2 .5l-.2 2.1z"
        />
      </g>
    </svg>
  );
};
