import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import storeService from 'services/storeService';
import {
  ICombainedPaymentGateway,
  ILegacyPaymentGateway,
} from 'interface/settingsInterface';
import { showNotification } from 'helper/common/commonFunctions';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { IPaymentGateway } from 'shared-components/src/page-builder/interface/settingsInterface';
dayjs.extend(utc);

const payment_gateway_options = [
  {
    label: 'NMI',
    value: 'legacy',
  },
  {
    label: 'Spreedly',
    value: 'new_gateway',
  },
];

interface IProps {
  handleClose: () => void;
  paymentGatewayValue: ICombainedPaymentGateway;
  isLegacyPaymentGatewayAvaiable: boolean;
  onSuccess?: () => void;
}

const AddLegacyPaymentGateway: FC<IProps> = ({
  handleClose,
  paymentGatewayValue,
  isLegacyPaymentGatewayAvaiable,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<ICombainedPaymentGateway>({
    defaultValues: paymentGatewayValue,
  });

  const dynamicOptions = payment_gateway_options.filter(item => {
    if (paymentGatewayValue?.ID) {
      return Object.prototype.hasOwnProperty.call(paymentGatewayValue, 'Secret')
        ? item.value !== 'new_gateway'
        : item.value !== 'legacy';
    }
    if (isLegacyPaymentGatewayAvaiable) {
      return item.value !== 'legacy';
    }
    return item;
  });

  const [paymentGatewayType, setPaymentGatewayType] = useState(
    Object.prototype.hasOwnProperty.call(paymentGatewayValue, 'Secret')
      ? payment_gateway_options[0]
      : dynamicOptions[0],
  );
  const addPaymentGateway = async (data: ICombainedPaymentGateway) => {
    setLoading(true);
    let res;
    if (paymentGatewayType.value === 'legacy') {
      const payload: ILegacyPaymentGateway = {
        Secret: data.Secret || '',
        StoreID: data.StoreID,
      };
      res = await storeService.createLegacyPaymentGateway(payload);
    } else {
      const payload: IPaymentGateway = {
        Kind: data.Kind || '',
        Creds: {
          Secret: data.Creds || '',
        },
        StoreID: data.StoreID,
      };
      res = await storeService.createPaymentGateway(payload);
    }
    setLoading(false);
    if (res.status === 200) {
      onSuccess && onSuccess();
      showNotification('success', 'Payment Gateway Added Successfully');
    }
  };

  const updatePaymentGateway = async (data: ICombainedPaymentGateway) => {
    setLoading(true);
    let res;
    if (paymentGatewayType.value === 'legacy') {
      const payload: ILegacyPaymentGateway = {
        ID: data.ID,
        Secret: data.Secret || '',
        StoreID: data.StoreID,
        Version: data.Version,
      };
      res = await storeService.updateLegacyPaymentGateway(payload);
    } else {
      const payload: IPaymentGateway = {
        ID: data.ID,
        Kind: data.Kind || '',
        Creds: {
          Secret: data.Creds || '',
        },
        StoreID: data.StoreID,
        Version: data.Version,
      };
      res = await storeService.updatePaymentGateway(payload);
    }
    setLoading(false);
    if (res.status === 200) {
      onSuccess && onSuccess();
      showNotification('success', 'Payment Gateway Updated Successfully');
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: paymentGatewayValue?.ID ? 'Update' : 'Add',
            form: 'AddLegacyPaymentGateway',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={
          paymentGatewayValue?.ID
            ? 'Edit Payment Gateway'
            : 'Add Payment Gateway'
        }
        className="flex-container">
        <div className="popup-section">
          <AutoCompleteSelect
            options={dynamicOptions}
            labelKey="label"
            onChange={val => setPaymentGatewayType(val)}
            value={paymentGatewayType}
            placeholder="Select PageType"
            className="w-full dropdown-field mb-3"
            label="Payment Gateway Type"
          />
          <form
            className="shopify-collapsed"
            id="AddLegacyPaymentGateway"
            onSubmit={
              paymentGatewayValue?.ID
                ? handleSubmit(updatePaymentGateway)
                : handleSubmit(addPaymentGateway)
            }>
            {paymentGatewayType.value === 'legacy' ? (
              <Controller
                name="Secret"
                key={'secret'}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, value, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      value={value}
                      errorString={error?.message}
                      label="Phoenix Secret Token"
                      className="filter_form_input"
                      fixedSize="md"
                      placeholder="Enter Token"
                      ref={ref}
                    />
                  );
                }}
              />
            ) : (
              <>
                <Controller
                  name="Kind"
                  key={'kind'}
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({
                    field: { ref, value, ...rest },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        {...rest}
                        value={value}
                        errorString={error?.message}
                        label="Kind"
                        className="filter_form_input mb-2"
                        fixedSize="md"
                        placeholder="Enter Kind"
                        ref={ref}
                      />
                    );
                  }}
                />
                <Controller
                  name="Creds"
                  key={'creds'}
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  render={({
                    field: { ref, value, ...rest },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        {...rest}
                        value={value}
                        errorString={error?.message}
                        label="Creds"
                        className="filter_form_input mb-2"
                        fixedSize="md"
                        placeholder="Enter Creds"
                        ref={ref}
                      />
                    );
                  }}
                />
              </>
            )}
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddLegacyPaymentGateway;
