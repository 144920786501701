import React, { FC, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { IDeteleNotes, IGetNotes, INotes } from 'interface/notesInterface';
import { Popover } from '@mui/material';
import notesService from 'services/notesService';
import { resourceTypes } from 'utils/constants';
import { Spinner } from 'components/common/loader';
import { formattedDate } from 'utils/helper';

interface IOrderNotes {
  orderId?: string;
  storeId?: string;
}

const OrderNotes: FC<IOrderNotes> = ({ orderId, storeId }) => {
  const [notes, setNotes] = useState<IGetNotes[]>([]);
  const [message, setMessage] = useState<string>('');
  const [isAddNotes, setIsAddNotes] = useState<boolean>(false);
  const [isNoteEdit, setIsNoteEdit] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [notesLoading, setNotesLoading] = useState<number>(0);
  // Function to handle opening the popover
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Set the anchor position to the clicked button
  };

  // Function to handle closing the popover
  const handleClosePopover = () => {
    setAnchorEl(null); // Close the popover
  };

  const getNotesDetails = async (
    store_id: string,
    resource_type: string,
    resource_id: string,
  ) => {
    setNotesLoading(pre => pre + 1);
    const notesData = await notesService.getNotes(
      store_id,
      resource_type,
      resource_id,
    );

    if (notesData?.status === 200) {
      setNotes(notesData?.data.Notes || []);
    }
    setNotesLoading(pre => pre - 1);
  };

  const handleAddNotes = async (
    storeId: string,
    orderId: string,
    message: string,
  ) => {
    if (message) {
      const payload: INotes = {
        Text: message,
        StoreID: storeId,
        ResourceID: orderId,
        ResourceType: resourceTypes?.orders,
      };
      setNotesLoading(pre => pre + 1);
      const res = await notesService.postNotes(payload);
      setNotesLoading(pre => pre - 1);
      if (res?.status === 200) {
        setIsAddNotes(false);
        setMessage('');
        getNotesDetails(payload.StoreID, resourceTypes?.orders, orderId);
      }
    }
  };

  const handleEditNotes = async (
    id: string,
    storeId: string,
    orderId: string,
    message: string,
    version: number,
    oldmessage: string,
  ) => {
    if (message === oldmessage) {
      setIsNoteEdit('');
      return;
    }
    const payload: INotes = {
      Text: message,
      ID: id,
      StoreID: storeId,
      Version: version + 1,
    };
    setNotesLoading(pre => pre + 1);
    const res = await notesService.updateNotes(payload);
    setNotesLoading(pre => pre - 1);
    if (res?.status === 200) {
      setIsNoteEdit('');
      getNotesDetails(payload.StoreID, resourceTypes?.orders, orderId);
    }
  };

  const handleDeleteNotes = async (
    storeId: string,
    orderId: string,
    note: IGetNotes,
  ) => {
    const payload: IDeteleNotes = {
      NoteIDs: [note.ID],
      StoreID: storeId || '',
    };
    setNotesLoading(pre => pre + 1);
    const res = await notesService.deleteNotes(payload);
    setNotesLoading(pre => pre - 1);
    if (res?.status === 200 || res?.status === 204) {
      getNotesDetails(storeId, resourceTypes?.orders, orderId);
    }
  };

  useEffect(() => {
    if (storeId && orderId) {
      getNotesDetails(storeId, resourceTypes?.orders, orderId);
    }
  }, [orderId, storeId]);

  return (
    <>
      <div className="notesContainer">
        <Spinner loading={!!notesLoading} />
        <div className="notes-content">
          <div className="notes-wrapper">
            <div className="addnotes-container">
              <textarea
                className="addnotes-box"
                placeholder="Add Note "
                value={message}
                onChange={e => setMessage(e.target.value)}></textarea>
              <button
                className="addnotes-button"
                type="button"
                onClick={() => {
                  if (storeId && orderId)
                    handleAddNotes(storeId, orderId, message);
                }}>
                <AddIcon />
                <span>Add Note</span>
              </button>
            </div>

            <div className="notes-body">
              {notes?.map((data: IGetNotes, index: number) => {
                return (
                  <div className="notes-wrap" key={index}>
                    <div className="title">
                      <div className="notes-title">
                        {data?.User?.FirstName}{' '}
                        <span className="created-at-date">
                          {formattedDate(data.CreatedAt)}
                        </span>
                      </div>

                      <div>
                        <button
                          id={data.ID}
                          className="menu-button"
                          onClick={handleOpenPopover}>
                          <MoreVertIcon />
                        </button>
                        {anchorEl && data.ID === anchorEl.id && (
                          <Popover
                            anchorEl={anchorEl}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            className="carts-popover"
                            anchorPosition={{ top: 1000, left: 500 }}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClosePopover}>
                            <div className="notes-pop-content">
                              <div className="popover-child">
                                <span
                                  className="child-elements"
                                  onClick={() => {
                                    setIsNoteEdit(data?.ID);
                                    setIsAddNotes(true);
                                    handleClosePopover();
                                  }}>
                                  Edit
                                </span>
                                <span
                                  className="child-elements"
                                  onClick={() => {
                                    if (storeId && orderId)
                                      handleDeleteNotes(storeId, orderId, data);
                                    handleClosePopover();
                                  }}>
                                  Delete
                                </span>
                              </div>
                            </div>
                          </Popover>
                        )}
                      </div>
                    </div>
                    {isNoteEdit === data?.ID ? (
                      <TextareaAutosize
                        className={`notes-para text-notes-para addnotes-box ${isAddNotes ? 'focused' : ''}`}
                        defaultValue={data?.Note}
                        onBlur={e => {
                          if (storeId && orderId)
                            handleEditNotes(
                              data.ID,
                              storeId,
                              orderId,
                              e.target.value,
                              data.Version,
                              data?.Note,
                            );
                        }}
                      />
                    ) : (
                      <pre className="notes-para">{data.Note}</pre>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderNotes;
