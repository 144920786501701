import React, { useEffect, useState } from 'react';
import TextField from 'components/common/textField';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import LocalOfferSharpIcon from '@mui/icons-material/LocalOfferSharp';

interface Product {
  id: string;
  title: string;
  selling_plan_groups: string[];
  price: string;
  available: boolean;
  tags: string[];
}

const mockOProduct: Product[] = [
  {
    id: '1',
    title: 'Crusader Leather Jacket',
    selling_plan_groups: [],
    price: '4000',
    available: false,
    tags: ['jacket', 'Jackets', 'Kopy'],
  },
  {
    id: '2',
    title: 'Elliston Leather',
    selling_plan_groups: [],
    price: '4000',
    available: false,
    tags: ['jacket', 'Jackets', 'Kopy'],
  },
  {
    id: '3',
    title: 'Limited Edition',
    selling_plan_groups: [],
    price: '4000',
    available: false,
    tags: ['jacket', 'Jackets', 'Kopy'],
  },
  {
    id: '4',
    title: 'Elliston Leather',
    selling_plan_groups: ['Marketing Plan'],
    price: '4000',
    available: false,
    tags: ['jacket', 'Jackets', 'Kopy'],
  },
  {
    id: '5',
    title: 'Stock Clearance',
    selling_plan_groups: [],
    price: '4000',
    available: false,
    tags: ['jacket', 'Jackets', 'Kopy'],
  },
];

const Products: React.FC = () => {
  const [filterName, setFilterName] = useState<string>('');
  const [products] = useState<Product[]>(mockOProduct);
  useEffect(() => {}, [filterName]);
  return (
    <div className="m-auto max-w-5xl">
      <div className="flex justify-between align-center py-6">
        <h2 className="text-xl font-semibold">Products</h2>
      </div>
      <div className="">
        <div className="py-2 flex ">
          <TextField
            placeholder="Search by product name"
            fixedSize="md"
            value={filterName}
            onChange={e => setFilterName(e.target.value)}
            className="w-3/4 input-field"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </div>
        <div className="Common_Table">
          <Table className="table">
            <TableHead>
              <TableRow>
                <TableCell>title</TableCell>
                <TableCell>price</TableCell>
                <TableCell>available</TableCell>
                <TableCell>tags</TableCell>
                <TableCell>actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products && products.length ? (
                products.map(product => {
                  return (
                    <TableRow key={product.id}>
                      <TableCell>{product.title}</TableCell>
                      <TableCell>{product.price}</TableCell>
                      <TableCell>{product.available ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        {product.tags.map((val, index) =>
                          product?.tags?.length - 1 !== index ? val + ',' : val,
                        )}
                      </TableCell>
                      <TableCell>
                        {product.selling_plan_groups.length === 0 ? (
                          <LocalOfferSharpIcon />
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <span className="text-center block">No records found</span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Products;
