import { FC } from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import DatePicker from 'components/common/datePicker';
import { Button } from 'shared-components';
import 'components/SubscriptionManagement/FilterForm/style.scss';
import MultiSelect from 'components/common/multiSelect';
import { IDefaultOption } from 'interface/customerInterface';
import { SideFormFilterSubscription } from 'interface/subscriptionManagementInterface';
// import MultiStoreSearch from 'components/common/multiSelectSearchFilter/MultiStoreSearch';
import { defaultSubscriptionFilterValues } from 'components/SubscriptionManagement';
import { MultipleInputTag } from 'components/common/multiInput';
import { emailRegex, phoneRegex } from 'utils/constants';

dayjs.extend(utc);
const subscriptionStatus: IDefaultOption[] = [
  { label: 'Active', value: 'active' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Salvage', value: 'salvage' },
  { label: 'Paused', value: 'paused' },
];
interface Props {
  sideFormFilterSubscription: SideFormFilterSubscription;
  filterSubmission: (_data: SideFormFilterSubscription) => void;
  resetSelected: () => void;
  loading: number;
}
export const FilterForm: FC<Props> = ({
  sideFormFilterSubscription,
  filterSubmission,
  resetSelected,
  loading,
}) => {
  const { handleSubmit, control, reset, watch, setError } =
    useForm<SideFormFilterSubscription>({
      defaultValues: sideFormFilterSubscription,
    });

  const onSubmit = async (data: SideFormFilterSubscription) => {
    filterSubmission(data);
    resetSelected();
  };

  return (
    <div>
      <div className="subscription-filter-selection">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="subscription-filter-selection-form">
          <Controller
            name="CustomerEmails"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value ? value : []}
                  label="Customer Emails"
                  placeholder="Type Emails"
                  isLowerCase={true}
                  onChange={onChange}
                  validation={[
                    {
                      validationRule: emailRegex,
                      validationMessage: 'Enter a valid email address',
                    },
                  ]}
                  errorString={error?.message}
                  setError={(value: string) => {
                    setError('CustomerEmails', {
                      message: value,
                    });
                  }}
                />
              );
            }}
          />
          <Controller
            name="PhoneNumbers"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <MultipleInputTag
                  tagsValue={value ? value : []}
                  label="Phone Numbers"
                  placeholder="Type Phone Numbers"
                  validation={[
                    {
                      validationRule: phoneRegex,
                      validationMessage: 'Enter a valid phone number',
                    },
                  ]}
                  setError={(value: string) => {
                    setError('PhoneNumbers', {
                      type: 'custom',
                      message: value,
                    });
                  }}
                  errorString={error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
          <Controller
            name="FirstName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  label="First Name"
                  placeholder="Type First Name"
                  value={value}
                  fixedSize="md"
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                />
              );
            }}
          />
          <Controller
            name="LastName"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextField
                  label="Last Name"
                  placeholder="Type Last Name"
                  value={value}
                  fixedSize="md"
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^a-zA-Z\s]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                />
              );
            }}
          />
          <div className="common_box">
            <p className="common_input_label">Date Acquired</p>
            <div className="flexContainer duel-datepicker">
              <Controller
                name="StartTime"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      maxDate={dayjs(watch('EndTime')).tz()}
                      disableFuture={true}
                      error={error?.message}
                      ref={ref}
                      {...rest}
                    />
                  );
                }}
              />
              <Controller
                name="EndTime"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      minDate={dayjs(watch('StartTime')).tz()}
                      disableFuture={true}
                      error={error?.message}
                      ref={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="common_box">
            <p className="common_input_label">Next Billing Date</p>
            <div className="flexContainer duel-datepicker">
              <Controller
                name="NextBillingDateStart"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      maxDate={dayjs(watch('NextBillingDateEnd')).tz()}
                      error={error?.message}
                      ref={ref}
                      {...rest}
                    />
                  );
                }}
              />
              <Controller
                name="NextBillingDateEnd"
                control={control}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <DatePicker
                      minDate={dayjs(watch('NextBillingDateStart')).tz()}
                      error={error?.message}
                      ref={ref}
                      {...rest}
                    />
                  );
                }}
              />
            </div>
          </div>
          <Controller
            name="Status"
            control={control}
            render={({ field: { value, ref, onChange } }) => {
              return (
                <MultiSelect<IDefaultOption>
                  value={value ? value : []}
                  options={subscriptionStatus}
                  labelKey="label"
                  valueKey="value"
                  label="Subscription Status"
                  placeholder="Select Subscription Status"
                  ref={ref}
                  limitTags={1}
                  onChange={(newValue: IDefaultOption[]) => {
                    onChange(newValue);
                  }}
                />
              );
            }}
          />
          <div className="common_box">
            <p className="common_input_label">Cycles Completed</p>
            <div className="flexContainer">
              <Controller
                name="MinCycle"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      className="filter_form_input"
                      placeholder="Min value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(e.target.value.replace(/[^0-9]/g, ''))
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
              <Controller
                name="MaxCycle"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      className="filter_form_input"
                      placeholder="Max Value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(e.target.value.replace(/[^0-9]/g, ''))
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
          </div>
          <div className="common_box subscriptionAmount">
            <p className="common_input_label">Subscription Amount</p>
            <div className="flexContainer">
              <Controller
                name="MinAmount"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      isPrefixText="$"
                      className="filter_form_input"
                      placeholder="Min value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(
                          e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*?)\..*/g, '$1'),
                        )
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
              <Controller
                name="MaxAmount"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => {
                  return (
                    <TextField
                      {...rest}
                      isPrefixText="$"
                      className="filter_form_input"
                      placeholder="Max Value"
                      fixedSize="md"
                      onChange={e =>
                        onChange(
                          e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*?)\..*/g, '$1'),
                        )
                      }
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
          </div>
          <div className="button_wrapper">
            <Button
              className="subscription_for_submit"
              type="submit"
              label="Apply Filter"
            />
            <Button
              variant="secondary"
              className="subscription_for_submit clear_button"
              onClick={() => {
                if (!loading) reset(defaultSubscriptionFilterValues);
              }}
              label="Clear Filter"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
