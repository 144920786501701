import { useState } from 'react';
import './style.scss';
import ImagePopup from './image-popup';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { AxiosInstance } from 'axios';

interface ImageDrawerProps {
  handleSubmit: (_selectedImage: IAsset) => void;
  image: IAsset;
  baseService: AxiosInstance;
}

export const ImageDrawer = ({
  handleSubmit,
  image,
  baseService,
}: ImageDrawerProps) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openImageDrawer = () => {
    setPopupOpen(true);
  };
  const handleSelection = (selectedImage: IAsset) => {
    handleSubmit(selectedImage);
    setPopupOpen(false);
  };

  return (
    <div className="flex justify-center">
      <button
        className="bg-gray-300 hover:bg-gray-200  text-blue-500 border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
        type="button"
        onClick={openImageDrawer}>
        Select Image
      </button>
      {isPopupOpen && (
        <ImagePopup
          setPopupOpen={setPopupOpen}
          handleSubmit={handleSelection}
          image={image}
          isPopupOpen={isPopupOpen}
          baseService={baseService}
        />
      )}
    </div>
  );
};
