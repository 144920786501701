import { TextFieldControl } from 'components/common/textField';
import { Button } from 'shared-components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  ICreateMemberShipOffer,
  IMemberShipProductsDetails,
  IProductBody,
  IUpdateMembershipOffer,
} from 'interface/membershipOffersInterface';
import { FormProvider, useForm } from 'react-hook-form';
import MembershipOfferService from 'services/membershipOfferService';
import { showNotification } from 'helper/common/commonFunctions';
import membershipPlanService from 'services/membershipPlanService';
import { useAppSelector } from 'hooks/reduxHooks';
import AddProductPopup from './addProductsPopup';
import { Loader } from 'components/common/loader';
import { ControlledAutoCompleteSelect } from 'components/common/selectAutoComplete';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import { ISelectOptions } from 'interface/membershipPlanInterface';
import { Path } from 'router/constant';
import { useNavigate } from 'react-router-dom';

const discountTypes = [
  {
    label: 'Percentage',
    value: 'percent',
  },
  {
    label: 'Fixed Amount',
    value: 'fixed',
  },
];

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  p: 4,
  transform: 'translate(-50%, -50%)',
  maxHeight: '70vh',
  minWidth: '40vw',
  maxWidth: '50vw',
  borderRadius: '20px',
  border: '1px solid rgba(163, 174, 208, 0.20)',
  backgroundColor: '#141E2D',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  outline: 'unset',
};

const defaultValues: ICreateMemberShipOffer = {
  ChannelID: '',
  Name: '',
  Description: '',
  DiscountAmount: 0,
  DiscountType: '',
  SubscriptionPlanID: '',
};

const defaultProductSearch: IProductBody = {
  ChannelID: '',
  ID: '',
  Before: false,
  Limit: 25,
};

interface IProps {
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  isSettingsFromPortal?: boolean;
}

const EditMembershipOffers: FC<IProps> = ({
  setIsEdit,
  id,
  isSettingsFromPortal,
}) => {
  const [productsAdded, setProductsAdded] = useState<
    IMemberShipProductsDetails[]
  >([]);
  const [initialProducts, setInitialProducts] = useState<
    IMemberShipProductsDetails[]
  >([]);

  const [open, setOpen] = useState(false);

  const [membershipPlan, setmembershipPlan] = useState<ISelectOptions[]>([]);
  const [loading, setLoading] = useState<number>(0);

  const { storeFront } = useAppSelector(state => state.shopify);

  const [nextAPIDetails, setNextAPIDetails] = useState({
    Cursor: '',
    HasNextPage: false,
  });
  const [productSearch, setProductSearch] =
    useState<IProductBody>(defaultProductSearch);

  const navigate = useNavigate();

  const observerRef = useRef<IntersectionObserver | null>(null);

  const method = useForm<ICreateMemberShipOffer>({
    defaultValues,
  });

  const { handleSubmit, reset } = method;
  const goBack = () => {
    setIsEdit && setIsEdit(false);
  };

  const getMembershipOfferProducts = async () => {
    if (!productSearch?.ID || !productSearch?.ChannelID) return;
    setLoading(pre => pre + 1);
    const response =
      await MembershipOfferService.getMembershipOfferProducts(productSearch);

    if (response?.status == 200 && response?.data?.Result?.length) {
      const productsAdded = response?.data?.Result;
      setProductsAdded(prevData => [...prevData, ...productsAdded]);
      setInitialProducts(prevData => [...prevData, ...productsAdded]);
      setNextAPIDetails({
        Cursor: response.data.EndCursor,
        HasNextPage: response.data.HasNextPage,
      });
    } else {
      setProductsAdded([]);
      setInitialProducts([]);
    }

    setLoading(pre => pre - 1);
  };

  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && nextAPIDetails.HasNextPage) {
          setProductSearch(prevPage => ({
            ...prevPage,
            Cursor: nextAPIDetails.Cursor,
          }));
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [nextAPIDetails],
  );

  const getOfferDetails = async (id: string, storeFrontID: string) => {
    const response = await MembershipOfferService.getMembershipOfferByID(
      storeFrontID,
      id,
    );

    setLoading(pre => pre + 1);
    if (response?.status !== 200) {
      showNotification('error', 'Offer not found');
      reset(defaultValues);
      setLoading(pre => pre - 1);
      return;
    }

    reset({
      ChannelID: response?.data?.ChannelID,
      Description: '',
      DiscountAmount: response?.data?.DiscountAmount,
      DiscountType: response?.data?.DiscountType,
      Name: response?.data?.Name,
      SubscriptionPlanID: response?.data?.SubscriptionPlanID,
    });
    setLoading(pre => pre - 1);
  };

  const findProducts = () => {
    const initialProductIDs = new Set(
      initialProducts.map(product => product.ID),
    );
    const productsAddedIDs = new Set(productsAdded.map(product => product.ID));

    const ProductIDsToRemove = [...initialProductIDs].filter(
      id => !productsAddedIDs.has(id),
    );
    const ProductIDsToAdd = [...productsAddedIDs].filter(
      id => !initialProductIDs.has(id),
    );

    return { ProductIDsToRemove, ProductIDsToAdd };
  };

  const setOfferDetails = async (
    payload: ICreateMemberShipOffer | IUpdateMembershipOffer,
  ) => {
    if (!storeFront?.ID) return;
    if (!id) {
      const body: ICreateMemberShipOffer = {
        ...payload,
        DiscountAmount: parseInt(payload.DiscountAmount.toString()),
        ChannelID: storeFront?.ID,
        ProductIDs: productsAdded.map(item => item.ID),
      };
      const res = await MembershipOfferService.createMembershipOffer(body);
      if (res.status === 200) {
        showNotification('success', 'Offer created successfully');
        setIsEdit && setIsEdit(false);
      }
    } else {
      const { ProductIDsToRemove, ProductIDsToAdd } = findProducts();
      const body: IUpdateMembershipOffer = {
        DiscountAmount: parseInt(payload.DiscountAmount.toString()),
        ChannelID: storeFront?.ID,
        SubscriptionPlanID: payload.SubscriptionPlanID,
        ProductIDsToAdd,
        ProductIDsToRemove,
        ID: id,
        Name: payload.Name,
        Description: '',
        DiscountType: payload.DiscountType,
      };

      const res = await MembershipOfferService.updateMembershipOffer(body);

      if (res.status === 200) {
        showNotification('success', 'Offer updated successfully');
        setIsEdit && setIsEdit(false);
      }
    }
  };

  const handleRemoveProduct = (id: string) => {
    setProductsAdded(prev => prev.filter(item => item.ID !== id));
  };

  const getMembershipPlan = async () => {
    if (!storeFront?.StoreID) return;
    setLoading(pre => pre + 1);
    const data = await membershipPlanService.getMembershipPlan(
      storeFront?.StoreID,
    );
    setLoading(pre => pre - 1);
    if (data?.status === 200 && data?.data?.Result?.length) {
      setmembershipPlan(
        data?.data?.Result?.map(item => ({
          label: item.Name,
          value: item.ID || '',
        })),
      );
      return;
    }
    setmembershipPlan([]);
  };

  useEffect(() => {
    getMembershipPlan();
    if (id && storeFront?.ID) {
      setProductSearch(pre => ({
        ...pre,
        ChannelID: storeFront?.ID,
        ID: id,
      }));
      getOfferDetails(id, storeFront?.ID);
    }
  }, []);

  useEffect(() => {
    getMembershipOfferProducts();
  }, [productSearch]);

  return (
    <form
      className="h-full overflow-auto pb-5"
      id="editMembershipOfferForm"
      onSubmit={handleSubmit(setOfferDetails)}>
      <FormProvider {...method}>
        <div className="m-auto h-full max-w-xl grid grid-rows-[min-content_1fr]">
          {!isSettingsFromPortal && (
            <div className="flex justify-between align-center py-6">
              <div className="flex gap-3">
                <ArrowBackIcon
                  className="cursor-pointer"
                  onClick={() => {
                    goBack();
                  }}
                />
                <h2 className="text-xl font-semibold">{`${
                  id ? 'Edit offers' : 'Add offers'
                }`}</h2>
              </div>
              <Button
                type="submit"
                label="Save"
                variant="secondary"
                form="editMembershipOfferForm"
              />
            </div>
          )}
          <div className="overflow-auto h-full">
            <div className="mb-3">
              <div className="card text-left p-4 text-xs">
                {!isSettingsFromPortal && (
                  <h2 className="block font-semibold text-sm">Discount</h2>
                )}

                <div className="py-2">
                  <TextFieldControl
                    rules={{ required: 'This field is required' }}
                    name="Name"
                    fixedSize="md"
                    label="Discount group name"
                    placeholder="Enter group name"
                    className="w-full input-field text-xs"
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                  <ControlledAutoCompleteSelect
                    rules={{ required: 'This field is required' }}
                    options={discountTypes}
                    labelKey={'label'}
                    label="Discount Value"
                    placeholder="Select"
                    name="DiscountType"
                    className="w-full dropdown-field"
                    newValueHandler={e => {
                      return (
                        discountTypes.find(item => item.value === e) || null
                      );
                    }}
                    onChangeHandler={e => e?.value || null}
                  />
                  <TextFieldControl
                    rules={{ required: 'This field is required' }}
                    name="DiscountAmount"
                    fixedSize="md"
                    placeholder="$0.00"
                    label="Discount Amount"
                    className="w-full input-field text-xs"
                  />
                </div>
                <div className="py-2">
                  {!isSettingsFromPortal && (
                    <div className="flex justify-between items-center mb-2">
                      <h2 className="block text-sm">Membership</h2>
                      <Button
                        label="Create membership"
                        onClick={() => {
                          navigate(Path.ADD_MEMBERSHIP);
                        }}
                        type="button"
                      />
                    </div>
                  )}

                  <ControlledAutoCompleteSelect<ISelectOptions>
                    name="SubscriptionPlanID"
                    className="w-full dropdown-field"
                    newValueHandler={e => {
                      return (
                        membershipPlan.find(item => item.value === e) || null
                      );
                    }}
                    labelKey="label"
                    onChangeHandler={e => e?.value || null}
                    options={membershipPlan}
                    placeholder="Select"
                  />
                </div>
                {/* for future use */}
                {/* <div className="pt-2 ">
                  <CheckboxComponent />
                  <label className="text-sm">Default Offer</label>
                  <p className="ml-10 block">
                    Will automatically include all products that haven&apos;t
                    been manually placed into other specific offers.
                  </p>
                </div> */}
              </div>
            </div>
            <div className={`card text-left text-white`}>
              <div className="flex justify-between items-center p-4 products-container">
                <h2 className="block font-semibold">Products</h2>
                <Button
                  label="Add products"
                  type="button"
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </div>
              {productsAdded?.map((data, index) => (
                <div
                  ref={
                    index === productsAdded.length - 1 ? lastElementRef : null
                  }
                  className="flex items-center justify-between products-container px-4 py-2"
                  key={index}>
                  <div className="flex items-center space-x-4 grow-[10]">
                    <img
                      src={data?.ImageURL || PlaceholderIcon}
                      alt="Product"
                      className="w-10 h-10 rounded-md"
                    />
                    <span className="font-normal font-[500]">{data.Title}</span>
                  </div>
                  <div className="font-normal font-[500] text-right pe-4">
                    {data.MinOriginalPrice}
                  </div>
                  <CloseIcon
                    onClick={() => {
                      handleRemoveProduct(data.ID);
                    }}
                    className="cursor-pointer"
                  />
                </div>
              ))}
            </div>
            {open && (
              <AddProductPopup
                storeFront={storeFront}
                open={open}
                setOpen={setOpen}
                setProductsAdded={setProductsAdded}
                productsAdded={productsAdded}
              />
            )}
          </div>
        </div>
      </FormProvider>
      <Loader loading={!!loading} />
    </form>
  );
};

export default EditMembershipOffers;
