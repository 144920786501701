import { formatPrice } from '../../../../page-builder/utils/formatPrice';
import { PostUpSellRating } from '../rating/post-upsell-rating';
import {
  Item,
  Variant,
} from '../../../../page-builder/interface/postUpsellInterface';
import { Select } from '../../../../page-builder/components/form-fields/Select';
import { setSelectedVariant } from '../../../../page-builder/redux/slices/postUpsellStateSlice';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../../services/constants';

interface PostUpSellVarientProps {
  selectedVarient: Variant;
  currentRecommendation: Item;
}
export const PostUpSellVarient = ({
  currentRecommendation,
  selectedVarient,
  useAppDispatch,
  useAppSelector,
}: PostUpSellVarientProps & ReduxSelectorProps & ReduxDispatchProps) => {
  const dispatch = useAppDispatch();
  const postUpsellState = useAppSelector(state => state.postUpSellState);
  const handleVariantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newVariant = currentRecommendation.Variants.find(
      variant => variant.ID === event.target.value,
    );
    if (newVariant) {
      dispatch(setSelectedVariant(newVariant));
    }
  };
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl font-bold mb-2">
        {selectedVarient?.Title || currentRecommendation?.Title}
      </h1>
      <div className="flex  gap-4 mt-1 items-center text-2xl ml-2 ">
        {selectedVarient?.OriginalPrice &&
          Number(selectedVarient?.OriginalPrice) >
            Number(selectedVarient?.Price) && (
            <h1 className="text-red-500 line-through mb-1">
              ${formatPrice(selectedVarient?.OriginalPrice)}
            </h1>
          )}
        <h1 className="text-black  font-medium  mb-1">
          ${formatPrice(String(selectedVarient?.Price))}
        </h1>
      </div>
      <div className="flex items-center mt-1">
        <PostUpSellRating
          ratingValue={
            selectedVarient.rating ? Number(selectedVarient.rating) : 4
          }
          totalRatings={
            selectedVarient.ratingsCount
              ? Number(selectedVarient.ratingsCount)
              : 795
          }
        />
      </div>
      <img src={selectedVarient.ImageURL} alt="Product-Image" />
      {currentRecommendation?.Variants?.length > 1 && (
        <Select
          className="w-full py-2"
          additionalStyles="py-1"
          options={currentRecommendation?.Variants}
          onChange={handleVariantChange}
          value={postUpsellState?.selectedVariant?.ID}
          valueKey="ID"
          labelKey="Title"
        />
      )}
    </div>
  );
};
