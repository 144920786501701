export const spacingOptions = [
  { label: '8px', value: '8px' },
  { label: '16px', value: '16px' },
  { label: '24px', value: '24px' },
  { label: '32px', value: '32px' },
  { label: '40px', value: '40px' },
  { label: '48px', value: '48px' },
  { label: '56px', value: '56px' },
  { label: '64px', value: '64px' },
  { label: '72px', value: '72px' },
  { label: '80px', value: '80px' },
  { label: '88px', value: '88px' },
  { label: '96px', value: '96px' },
  { label: '104px', value: '104px' },
  { label: '112px', value: '112px' },
  { label: '120px', value: '120px' },
  { label: '128px', value: '128px' },
  { label: '136px', value: '136px' },
  { label: '144px', value: '144px' },
  { label: '152px', value: '152px' },
  { label: '160px', value: '160px' },
];

export const flexDirections = [
  { label: 'Row', value: 'row' },
  { label: 'Row Reverse', value: 'row-reverse' },
  { label: 'Column', value: 'column' },
  { label: 'Column Reverse', value: 'column-reverse' },
];

export const paddingAndMarginfields = {
  paddingHorizontal: {
    type: 'number',
  },
  paddingVertical: {
    type: 'number',
  },
  marginHorizontal: {
    type: 'number',
  },
  marginVertical: { type: 'number' },
};

export const fontFamilies = [
  {
    label: 'Sans-serif',
    value: 'sans-serif',
  },
  {
    label: 'Fantasy',
    value: 'fantasy',
  },
  {
    label: 'Monospace',
    value: 'monospace',
  },
  {
    label: 'Serif',
    value: 'serif',
  },
  {
    label: 'Times New Roman',
    value: 'times-new-roman',
  },
  {
    label: 'Georgia',
    value: 'georgia',
  },

  {
    label: 'Baskerville',
    value: 'baskerville',
  },
  {
    label: 'Merriweather',
    value: 'merriweather',
  },
  {
    label: 'Arial',
    value: 'arial',
  },
  {
    label: 'Helvetica',
    value: 'helvetica',
  },
  {
    label: 'Verdana',
    value: 'verdana',
  },
  {
    label: 'Tahoma',
    value: 'tahoma',
  },
  {
    label: 'Roboto',
    value: 'roboto',
  },
  {
    label: 'Consolas',
    value: 'consolas',
  },
  {
    label: 'Pacifico',
    value: 'pacifico',
  },
  {
    label: 'Lobster',
    value: 'lobster',
  },
  {
    label: 'Inter',
    value: 'inter',
  },
];
