import { ForwardedRef, SelectHTMLAttributes, forwardRef } from 'react';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { Variant } from '../../../page-builder/interface/checkoutInterface';
import { Country, State } from '../../../page-builder/interface/dataInterface';
import { TrackerConfig } from '../../interface/pixelInterface';

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  className?: string;
  options:
    | TrackerConfig[]
    | Country[]
    | State[]
    | Variant[]
    | { [key: string]: string }[];
  labelKey?: string;
  valueKey?: string;
  defaultOption?: string;
  error?: string;
  additionalStyles?: string;
}

export const Select = forwardRef(function TextFieldInternal(
  {
    label,
    className,
    options = [],
    labelKey = 'label',
    valueKey = 'value',
    defaultOption = 'Select',
    error,
    additionalStyles = '',
    ...rest
  }: ISelectProps,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  return (
    <div className={`${className}`}>
      {label && (
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="relative">
        <select
          ref={ref}
          className={`block w-full h-[42px] rounded-md border-gray-300 shadow-sm sm:text-sm active:ring-slate-300 focus:ring-slate-300 ring-slate-300 ${additionalStyles}`}
          {...rest}>
          <option value="" disabled>
            {defaultOption}
          </option>
          {options &&
            options.map((option, index) => (
              <option key={index} value={option?.[valueKey]}>
                {option?.[labelKey]}
              </option>
            ))}
        </select>
        {error && (
          <div
            className="error-message"
            style={{ color: 'var(--error-color)' }}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
});

interface ISelectControlProps extends ISelectProps {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}
export const SelectControl = ({
  name,
  rules,
  ...rest
}: ISelectControlProps) => {
  return (
    <Controller
      name={name}
      render={({
        field: { onChange, ...restField },
        fieldState: { error },
      }) => {
        return (
          <Select
            {...rest}
            onChange={e => onChange(e.target.value)}
            {...restField}
            error={error?.message}
          />
        );
      }}
      rules={rules}
    />
  );
};
