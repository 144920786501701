import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PixelTrackerItem } from './PixelTrackerItem';
import { Tracker, PixelTrackingProps } from '../../../interface/pixelInterface';
import { PixelModal } from './PixelModal';
import { PixelHeader } from './PixelHeader';

export const PixelTracking: React.FC<PixelTrackingProps> = ({
  value,
  onChange,
}) => {
  const [pixelTrackers, setPixelTrackers] = useState<Tracker[]>([]);
  const [, setEditingTrackerId] = useState<string | null>(null);
  const [currentTracker, setCurrentTracker] = useState<Tracker | null>(null);
  const [popup, setPopup] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (value && value.length > 0) {
      setPixelTrackers(value);
    }
  }, [value]);

  const handleDeleteTracker = (id: string) => {
    const updatedTrackers = pixelTrackers.filter(tracker => tracker.id !== id);
    setPixelTrackers(updatedTrackers);
    onChange(updatedTrackers);
  };

  const openPopup = (tracker?: Tracker) => {
    if (tracker) {
      setEditingTrackerId(tracker.id);
      setCurrentTracker(tracker);
    } else {
      setEditingTrackerId(null);
      setCurrentTracker({
        id: uuidv4(),
        trackerType: '',
        fields: {},
      });
    }
    setError('');
    setPopup(true);
  };

  return (
    <>
      <PixelHeader openPopup={openPopup} />
      <PixelModal
        popup={popup}
        error={error}
        currentTracker={currentTracker}
        setCurrentTracker={setCurrentTracker}
        setPopup={setPopup}
        pixelTrackers={pixelTrackers}
        setError={setError}
        onChange={onChange}
        setPixelTrackers={setPixelTrackers}
        setEditingTrackerId={setEditingTrackerId}
      />
      {pixelTrackers.map(pixelTracker => (
        <PixelTrackerItem
          key={pixelTracker.id}
          pixelTracker={pixelTracker}
          openPopup={openPopup}
          handleDeleteTracker={handleDeleteTracker}
        />
      ))}
    </>
  );
};
