import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  ICustomerDetails,
  ICustomerPaymentAPIDetails,
  ICustomerShippingAPIDetails,
  ICustomersList,
  IcusomerDetailsPayload,
  SearchCustomerBody,
  ICustomerUpdate,
  ICustomersSubscription,
} from 'interface/customerInterface';
import {
  commonError,
  exportCustomerEP,
  getCustomerDetailsEp,
  getCustomerShippingAddressEp,
  getCustomerSubscriptionDetailsEp,
  getCustomerpaymentProfilesEp,
  searchCustomerEP,
  updateCustomerEP,
} from 'services/constants';
import {
  IErrorResponse,
  IResponse,
  ISuccessResponse,
} from 'interface/apiInterface';
import { customersDataMock, customerSubscriptionData } from 'utils/mockData';

class CustomerService {
  searchCustomer = async (
    body: SearchCustomerBody,
  ): Promise<ISuccessResponse<ICustomersList> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<ICustomersList>(
        searchCustomerEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerById = async (
    params: IcusomerDetailsPayload,
  ): Promise<
    ISuccessResponse<ICustomerDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<ICustomerDetails>(
        getCustomerDetailsEp(params),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerpaymentProfiles = async (
    params: IcusomerDetailsPayload,
  ): Promise<
    ISuccessResponse<ICustomerPaymentAPIDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<ICustomerPaymentAPIDetails>(
        getCustomerpaymentProfilesEp(params),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getCustomerSubscriptionDetails = async (
    params: IcusomerDetailsPayload,
  ): Promise<
    ISuccessResponse<ICustomersSubscription> | IErrorResponse<AxiosError>
  > => {
    try {
      const res = await baseService.get<ICustomersSubscription>(
        getCustomerSubscriptionDetailsEp(params),
      );
      return res;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerShippingAddress = async (
    params: IcusomerDetailsPayload,
  ): Promise<
    ISuccessResponse<ICustomerShippingAPIDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.get<ICustomerShippingAPIDetails>(
        getCustomerShippingAddressEp(params),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  customerUpdate = async (
    body: ICustomerUpdate,
  ): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post(updateCustomerEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  downloadCustomerListCsv = async (
    body: SearchCustomerBody,
  ): Promise<IResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(exportCustomerEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

class CustomerMockService {
  searchCustomer = async (): Promise<
    ISuccessResponse<ICustomersList> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ICustomersList>>(resolve => {
        setTimeout(() => {
          resolve({
            data: { Customer: customersDataMock },
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerById = async (): Promise<
    ISuccessResponse<ICustomerDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: customersDataMock[0],
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerpaymentProfiles = async (): Promise<
    ISuccessResponse<ICustomerPaymentAPIDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ICustomerPaymentAPIDetails>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: {
                payment_profiles: [
                  customersDataMock[0].RecentPaymentProfile,
                  customersDataMock[1].RecentPaymentProfile,
                ],
              },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getCustomerSubscriptionDetails = async (): Promise<
    ISuccessResponse<ICustomersSubscription> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ICustomersSubscription>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: customerSubscriptionData,
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCustomerShippingAddress = async (): Promise<
    ISuccessResponse<ICustomerShippingAPIDetails> | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse<ICustomerShippingAPIDetails>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: {
                shipping_addresses: [
                  customersDataMock[0].RecentShippingAddress,
                  customersDataMock[1].RecentShippingAddress,
                ],
              },
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  customerUpdate = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  downloadCustomerListCsv = async (): Promise<
    IResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<IResponse>(resolve => {
        setTimeout(() => {
          resolve({
            status: 200,
            message: 'success',
            data: JSON.stringify(customersDataMock),
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalCustomersService: CustomerService | CustomerMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new CustomerMockService()
    : new CustomerService();

export default globalCustomersService;
