import { ReactNode, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import 'components/orders/orderDetails/transactionHistory/style.scss';
import { get } from 'lodash';
import { formattedDate } from 'utils/helper';
import { IOrderTransactionHistory } from 'interface/orderDetailInterface';
import { Loader } from 'components/common/loader';
import { Checkbox } from 'shared-components';
import { ITransactionHistory } from 'interface/transactionInterface';
import { transactionStatus } from 'components/dashboard/constant';
import StatusBadge from 'components/common/statusBadge';

type Order = 'asc' | 'desc';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IOrderTransactionHistory) => ReactNode;
  hide?: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    label: 'Date',
    cellRender: row => {
      if (!row?.Date) {
        return null;
      }
      return (
        <Tooltip placement="top" title={formattedDate(row?.Date)} arrow>
          <span>{formattedDate(row?.Date, true)}</span>
        </Tooltip>
      );
    },
    hide: false,
  },
  {
    id: 'GatewayID',
    label: 'Transaction ID',
    hide: false,
  },
  {
    id: 'Amount',
    label: 'Amount',
    hide: false,
    cellRender: row => (row?.Amount ? `$${row.Amount}` : ''),
  },
  {
    id: 'Status',
    label: 'Transaction Status',
    hide: false,
    cellRender: row => {
      return <StatusBadge status={row.Status} />;
    },
  },
  {
    id: 'Reason',
    label: 'Reason',
    hide: false,
  },
];

interface Iprops {
  historyLoading: boolean;
  selectedTransaction: Map<number, ITransactionHistory>;
  setSelectedTransaction: React.Dispatch<
    React.SetStateAction<Map<number, ITransactionHistory>>
  >;
  orderDetailData: ITransactionHistory[];
}

export default function Transactions({
  historyLoading,
  setSelectedTransaction,
  selectedTransaction,
  orderDetailData,
}: Iprops) {
  const [order] = useState<Order>('asc');
  const [orderBy] = useState<keyof IOrderTransactionHistory>();
  const [headCellList] = useState<HeadCell[]>([...headCells]);

  const handleClick = (
    id: number,
    checked: boolean,
    transaction: ITransactionHistory,
  ) => {
    if (checked) {
      setSelectedTransaction(pre => {
        const newSelected = new Map(pre);
        newSelected.delete(id);
        return newSelected;
      });
    } else {
      setSelectedTransaction(pre => {
        const newSelected = new Map(pre);
        newSelected.set(id, transaction);
        return newSelected;
      });
    }
  };

  const isSelected = (id: number) => selectedTransaction?.get(id) !== undefined;

  const isRefundVoided = orderDetailData.filter(transactionRow => {
    return ['voided'].includes(transactionRow.Status);
  });

  return (
    <div className="transaction_history_container">
      <div className="content-wrap">
        <TableContainer className="Common_Table">
          <Table className="transactions_table" aria-labelledby="tableTitle">
            <TableHead className="table_header">
              <TableRow>
                <TableCell className="table_header_cell "></TableCell>
                {headCellList?.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell
                      className="table_header_cell header_text"
                      key={headCell.label}
                      sortDirection={orderBy === headCell.id ? order : false}>
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {orderDetailData?.length > 0 &&
                orderDetailData.map((row, index) => {
                  const isItemSelected = isSelected(index);
                  const isItemRefundVoided = isRefundVoided.some(
                    transactionRow => {
                      return transactionRow.TransactionID == row.TransactionID;
                    },
                  );
                  return (
                    <TableRow
                      hover
                      className="table_row"
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.TransactionID}
                      selected={isItemSelected}>
                      <TableCell
                        padding="checkbox"
                        className="table_cell"
                        component="th"
                        scope="row">
                        {row.Status === transactionStatus.captured &&
                        !isItemRefundVoided ? (
                          <Checkbox
                            checked={isItemSelected}
                            onChange={() =>
                              handleClick(index, isItemSelected, row)
                            }
                          />
                        ) : null}
                      </TableCell>

                      {headCells.map(headCell => {
                        return (
                          <TableCell
                            className="table_cell"
                            key={headCell.label}
                            component="th"
                            id={headCell.label}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : get(row, headCell.id)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Loader loading={historyLoading} />
      </div>
    </div>
  );
}
