import { useEffect, useState } from 'react';
import { EventPublisher } from '../utils/publishEvent';
import { Tracker } from '../utils/trackers/trackers';
import { MetaTracker } from '../utils/trackers/meta/tracker';
import { TikTokTracker } from '../utils/trackers/tik-tok/tracker';

interface Pixel {
  trackerType: string;
  fields: {
    [key: string]: string[];
  };
}

export const extractAndUseTrackers = (data: Pixel[]) => {
  const [trackers, setTrackers] = useState<Tracker[]>([]);
  useEffect(() => {
    if (data) {
      const newTrackers = data
        .map((pixel: Pixel) => {
          if (pixel.trackerType === 'Meta' && pixel.fields['meta-pixel']) {
            const pixelIDs = pixel.fields['meta-pixel'].map(id => id.trim());
            return new MetaTracker(pixelIDs);
          }
          if (pixel.trackerType === 'TikTok' && pixel.fields['tiktok-pixel']) {
            const pixelIDs = pixel.fields['tiktok-pixel'].map(id => id.trim());
            return new TikTokTracker(pixelIDs);
          }
          return null;
        })
        .filter(
          (tracker): tracker is MetaTracker | TikTokTracker => tracker !== null,
        );
      setTrackers(newTrackers);
    }
  }, [data]);

  useEffect(() => {
    if (trackers.length === 0) return;
    const eventPublisher = EventPublisher.getInstance();
    trackers.forEach((tracker: Tracker) => {
      tracker.Init();
      eventPublisher.subscribeAll(event => {
        tracker.TrackEvent(event);
      });
    });
  }, [trackers]);
};
