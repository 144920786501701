import { AxiosError } from 'axios';
import {
  commonError,
  createCheckoutFlowEP,
  getCheckoutFlowEP,
} from 'services/constants';
import { baseService } from 'services';

import { ICheckoutBody, ICheckoutCreate } from 'interface/checkoutFlow';

class CheckoutFlowService {
  createCheckoutFlow = async (body: ICheckoutCreate) => {
    try {
      const response = await baseService.post(createCheckoutFlowEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getCheckoutFlow = async (body: ICheckoutBody) => {
    try {
      const response = await baseService.post(getCheckoutFlowEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class CheckoutFlowMockService {
  createCheckoutFlow = async (body: ICheckoutCreate) => {
    try {
      const response = await baseService.post(createCheckoutFlowEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getCheckoutFlow = async (body: ICheckoutBody) => {
    try {
      const response = await baseService.post(getCheckoutFlowEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalCheckoutFlowService: CheckoutFlowService | CheckoutFlowMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new CheckoutFlowMockService()
    : new CheckoutFlowService();

export default globalCheckoutFlowService;
