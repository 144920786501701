import { Review } from './Review';
import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/common/button';
import CustomTooltip from '../../../components/common/tooltip';
import { QuillTextEditor } from '../../puck/reusable-props/quill-editor';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';
import { ReduxSelectorProps } from '../../services/constants';

interface ReviewData {
  id: string;
  name: string;
  review: string;
}

export interface CustomerReviewsProps extends MarginProps, showComponentProps {
  reviews: ReviewData[];
  border: boolean;
  title: string;
}

export const CustomerReviews = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<CustomerReviewsProps> => {
  return {
    label: (
      <CustomTooltip title="CustomerReviews">
        <span>Customer Reviews</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      title: {
        ...QuillTextEditor('Title').content,
      },
      border: {
        type: 'radio',
        label: 'Divider Line',
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ],
      },
      reviews: {
        type: 'custom',
        label: 'Reviews',
        render: ({ value, onChange }) => {
          value = value || [];

          const handleAddReview = () => {
            const newReview: ReviewData = {
              id: uuidv4(),
              name: '',
              review: '',
            };
            onChange([newReview, ...value]);
          };

          const handleEditReview = (
            index: number,
            key: keyof ReviewData,
            newValue: string,
          ) => {
            onChange(
              value.map((review: ReviewData, i: number) =>
                i === index ? { ...review, [key]: newValue } : review,
              ),
            );
          };

          const handleRemoveReview = (index: number) => {
            onChange(value.filter((_: unknown, i: number) => i !== index));
          };

          return (
            <div>
              <Button
                label="+ Add new review"
                className="mt-4 w-full"
                variant="phoenix"
                onClick={handleAddReview}
              />
              {value.map((review: ReviewData, index: number) => (
                <div key={review.id}>
                  <input
                    className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                    placeholder="Name"
                    value={review.name}
                    onChange={e =>
                      handleEditReview(index, 'name', e.target.value)
                    }
                  />
                  <textarea
                    className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in pagebuilder-theme"
                    placeholder="Review"
                    value={review.review}
                    onChange={e =>
                      handleEditReview(index, 'review', e.target.value)
                    }
                  />
                  <Button
                    label="- Remove Review"
                    className="mt-4 w-full"
                    variant="phoenix_secondary"
                    onClick={() => handleRemoveReview(index)}
                  />
                </div>
              ))}
            </div>
          );
        },
      },
      ...marginFields,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      title: 'Customer Reviews',
      border: true,
      reviews: [],
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ reviews, margin, title, border, showComponent }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`pt-10 pb-5 ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <div
                className={`flex items-center customer-reviews`}
                style={{
                  width: '100%',
                }}>
                {border && (
                  <div className="flex-grow border-b border-black mx-2 left-divider"></div>
                )}

                <div
                  className={`quill-text ${!border ? 'w-full' : ''}`}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                {border && (
                  <div className="flex-grow border-b border-black mx-2 right-divider"></div>
                )}
              </div>
              {reviews && reviews.length === 0 && (
                <div className="mt-10 text-center text-lg text-gray-600">
                  No reviews yet
                </div>
              )}
              {reviews?.map(r => (
                <Review key={r.id} name={r.name} review={r.review} />
              ))}
            </div>
          )}
        </>
      );
    },
  };
};
