import { createSlice } from '@reduxjs/toolkit';
import {
  Item,
  UpSellData,
  Variant,
} from '../../../page-builder/interface/checkoutInterface';

export interface PostUpsellState {
  postUpsellData: UpSellData;
}

export const initialVariant: Variant = {
  ID: '',
  ImageURL: '',
  OriginalPrice: '',
  Price: '',
  Title: '',
};

export const initialItem: Item = {
  ID: '',
  ImageURL: '',
  Title: '',
  Variants: [initialVariant],
};

const initialState: PostUpsellState = {
  postUpsellData: [initialItem],
};

export const postUpsellSlice = createSlice({
  name: 'postUpsell',
  initialState,
  reducers: {
    setPostUpsellData(state, action) {
      return { ...state, postUpsellData: action.payload };
    },
  },
});

export const { setPostUpsellData } = postUpsellSlice.actions;

export default postUpsellSlice.reducer;
