import { createSlice } from '@reduxjs/toolkit';

export interface IPageConfig {
  filterSource: string;
  currentFilter: string;
  timeZone: string;
}

const initialState: IPageConfig = {
  filterSource: '',
  currentFilter: '',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const pageConfigSlice = createSlice({
  name: 'pageConfig',
  initialState,
  reducers: {
    setPageConfig: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCurrentFilter: (state, action) => {
      return { ...state, currentFilter: action.payload };
    },
    setTimeZone: (state, action) => {
      return { ...state, timeZone: action.payload };
    },
    clearPageConfig: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setPageConfig, setCurrentFilter, clearPageConfig, setTimeZone } =
  pageConfigSlice.actions;

export default pageConfigSlice.reducer;
