import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { CloseIcon } from '../../../assets/icons/close-icon';
import { ImageDrawer } from './image-drawer';
import { AxiosInstance } from 'axios';

export type ImageSelectorProps = {
  selectedImage: IAsset;
  handleSubmit: (_selectedImage: IAsset) => void;
  baseService: AxiosInstance;
};

export const defaultImage = {
  Filename: '',
  BlobURL: '',
  StoreID: '',
  ID: '',
  Version: 0,
};

export const ImageSelector = ({
  selectedImage,
  handleSubmit,
  baseService,
}: ImageSelectorProps) => {
  return (
    <>
      {selectedImage?.BlobURL && (
        <div className="p-2 flex justify-between align-center border border-dashed rounded-md border-gray-400 mb-2 shadow-xl ">
          <span>{selectedImage?.Filename}</span>
          <span
            className="cursor-pointer w-[15px] h-[15px]"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              handleSubmit(defaultImage);
            }}>
            <CloseIcon />
          </span>
        </div>
      )}
      <div className="flex justify-center p-[40px] border border-dashed rounded-md border-gray-400">
        <ImageDrawer
          handleSubmit={(selectedImage: IAsset) => {
            handleSubmit(selectedImage);
          }}
          image={selectedImage}
          baseService={baseService}
        />
      </div>
    </>
  );
};
