import { FieldLabel } from '@measured/puck';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Select } from '../../../page-builder/components/form-fields/Select';
import CustomTooltip from '../../../components/common/tooltip';
import { CustomizeProps } from '../../interface/commonInterface';
import InputField from '../../../components/common/input-field';
import ColorSelector from './color-selector';
import { fontFamilies } from './options';

export interface FontProps {
  font: string;
}

const fontWeightOptions = [
  { label: 'Light', value: 'font-light' },
  { label: 'Normal', value: 'font-normal' },
  { label: 'Bold', value: 'font-bold' },
  { label: 'Bolder', value: 'font-extrabold' },
];

const fontSizeOptions = [
  { label: '12', value: '12' },
  { label: '14', value: '14' },
  { label: '16', value: '16' },
  { label: '18', value: '18' },
  { label: '20', value: '20' },
  { label: '22', value: '22' },
  { label: '24', value: '24' },
  { label: '26', value: '26' },
  { label: '28', value: '28' },
  { label: '30', value: '30' },
  { label: '32', value: '32' },
];

interface TextCustomInputProps {
  value: CustomizeProps;
  onChange: (_value: CustomizeProps) => void;
  title: string;
  showHover?: boolean;
  showTitle?: boolean;
}

const TextCustomInput: React.FC<TextCustomInputProps> = ({
  value,
  onChange,
  title,
  showTitle = true,
  showHover = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div
        className="flex justify-between font-extrabold cursor-pointer bg-slate-100 rounded-md pagebuilder-theme"
        onClick={() => setIsOpen(!isOpen)}>
        <h3>{title}</h3>
        {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </div>
      {isOpen && (
        <>
          {showTitle && (
            <div className="flex flex-col gap-4 mt-3 items-center border border-gray-300 rounded p-2 w-full">
              <FieldLabel
                label={
                  (
                    <CustomTooltip title="Text">
                      <span>Text</span>
                    </CustomTooltip>
                  ) as React.ReactNode as string
                }
                el="div">
                <InputField
                  value={value?.text}
                  onChange={text => onChange({ ...value, text })}
                />
              </FieldLabel>
            </div>
          )}
          <div className="flex flex-col gap-4 mt-3 items-center border border-gray-300 rounded p-2 w-full">
            <div className="space-y-2 p-2 w-full flex flex-col gap-2">
              <FieldLabel
                label={
                  (
                    <CustomTooltip title="fontSize">
                      <span>Font Size</span>
                    </CustomTooltip>
                  ) as React.ReactNode as string
                }
                el="div">
                <Select
                  className="pagebuilder-theme"
                  value={value?.fontSize}
                  onChange={event =>
                    onChange({
                      ...value,
                      fontSize: event.target.value,
                    })
                  }
                  options={fontSizeOptions}
                />
              </FieldLabel>
              <FieldLabel
                label={
                  (
                    <CustomTooltip title="fontWeight">
                      <span>Font Weight</span>
                    </CustomTooltip>
                  ) as React.ReactNode as string
                }>
                <Select
                  value={value?.fontWeight}
                  onChange={event =>
                    onChange({
                      ...value,
                      fontWeight: event.target.value,
                    })
                  }
                  options={fontWeightOptions}
                />
              </FieldLabel>
              <FieldLabel
                label={
                  (
                    <CustomTooltip title="fontWeight">
                      <span>Font Family</span>
                    </CustomTooltip>
                  ) as React.ReactNode as string
                }>
                <Select
                  value={value?.fontFamily}
                  onChange={event =>
                    onChange({
                      ...value,
                      fontFamily: event.target.value,
                    })
                  }
                  options={fontFamilies}
                />
              </FieldLabel>

              <ColorSelector
                value={value?.textColor}
                onChange={val =>
                  onChange({
                    ...value,
                    textColor: val,
                  })
                }
                label="Text Color"
                tooltip="textColor"
              />
              {showHover && (
                <ColorSelector
                  value={value?.hoverColor}
                  onChange={val =>
                    onChange({
                      ...value,
                      hoverColor: val,
                    })
                  }
                  label="Text Hover Color"
                  tooltip="hoverColor"
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TextCustomInput;
