import { ComponentConfig, type Config } from '@measured/puck';
import {
  ColumnsProps,
  FlexProps,
  ContainerProps,
  VerticalSpaceProps,
  TextFieldProps,
  fontFamilies,
  AccordionProps,
  DividerProps,
  TextEditorProps,
  ButtonSectionProps,
  ImageUploaderProps,
  SplitLayoutProps,
  StandardLayoutProps,
  SimplePageHeaderProps,
  PuckRenderPage,
  createComponentConfigs,
  ThemeColorComponent,
  ErrorColor,
  CustomCss,
  CustomScripts,
} from 'shared-components';
import { baseService } from 'services';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';

type Props = {
  // Layouts
  SplitLayout: SplitLayoutProps;
  StandardLayout: StandardLayoutProps;
  // Blocks
  Columns: ColumnsProps;
  Accordion: AccordionProps;
  Flex: FlexProps;
  VerticalSpace: VerticalSpaceProps;
  Divider: DividerProps;
  Container: ContainerProps;
  // Text
  TextField: TextFieldProps;
  TextEditor: TextEditorProps;
  // Buttons
  Button: ButtonSectionProps;
  // Page Headers
  SimplePageHeader: SimplePageHeaderProps;
  // Other
  ImageUploader: ImageUploaderProps;
};

export const ThankYouConfig = (): Config<Props> => {
  const componentList = createComponentConfigs({
    useAppDispatch,
    useAppSelector,
    type: 'thankyou',
    baseService,
  });

  return {
    categories: {
      Layouts: {
        components: ['SplitLayout', 'StandardLayout'],
        defaultExpanded: false,
      },
      Blocks: {
        components: [
          'Columns',
          'Flex',
          'VerticalSpace',
          'Container',
          'Accordion',
          'Divider',
        ],
        defaultExpanded: false,
      },
      Text: {
        components: ['TextField', 'TextEditor'],
        defaultExpanded: false,
      },
      Buttons: {
        components: ['Button'],
        defaultExpanded: false,
      },
      'Page Headers': {
        components: ['SimplePageHeader'],
        defaultExpanded: false,
      },
      other: {
        defaultExpanded: false,
      },
    },
    components: componentList as {
      [ComponentName in keyof Props]: Omit<
        ComponentConfig<Props[ComponentName], Props[ComponentName]>,
        'type'
      >;
    },
    root: {
      fields: {
        fontFamily: {
          label: 'Font Family',
          type: 'select',
          options: fontFamilies,
        },
        themeColor: {
          type: 'custom',
          label: 'Theme Color',
          render: ({ value, onChange }) => (
            <ThemeColorComponent value={value} onChange={onChange} />
          ),
        },
        errorColor: {
          type: 'custom',
          label: 'Error Color',
          render: ({ value, onChange }) => (
            <ErrorColor value={value} onChange={onChange} />
          ),
        },
        customCss: {
          type: 'custom',
          label: 'Custom CSS',
          render: ({ value, onChange }) => (
            <CustomCss value={value} onChange={onChange} />
          ),
        },
        customScripts: {
          type: 'custom',
          label: 'Custom Scripts',
          render: ({ value, onChange }) => (
            <CustomScripts value={value} onChange={onChange} />
          ),
        },
      },
      defaultProps: {
        fontFamily: fontFamilies[0].value,
        themeColor: { r: 79, g: 70, b: 229 },
        errorColor: '#FF5A50',
        customCss: [],
        customScripts: [],
      },
      render: ({
        children,
        fontFamily,
        errorColor,
        customCss,
        customScripts,
      }) => (
        <PuckRenderPage
          fontFamily={fontFamily}
          errorColor={errorColor}
          customCss={customCss}
          customScripts={customScripts}
          useAppSelector={useAppSelector}>
          {children}
        </PuckRenderPage>
      ),
    },
  };
};

export default ThankYouConfig;
