import React from 'react';

export const Phoenix_loader = () => {
  return (
    <svg
      className="svg-loader"
      id="e8r5O4hIU6W1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1433.64 1438.54"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision">
      <style>
        {`
          #e8r5O4hIU6W4_tr {
            animation: e8r5O4hIU6W4_tr__tr 1000ms linear infinite normal forwards;
          }
          @keyframes e8r5O4hIU6W4_tr__tr {
            0% {
              transform: translate(823.612004px, 1319.99px) rotate(0deg);
            }
            50% {
              transform: translate(823.612004px, 1319.99px) rotate(-8.244097deg);
            }
            100% {
              transform: translate(823.612004px, 1319.99px) rotate(0deg);
            }
          }
          #e8r5O4hIU6W6_tr {
            animation: e8r5O4hIU6W6_tr__tr 1000ms linear infinite normal forwards;
          }
          @keyframes e8r5O4hIU6W6_tr__tr {
            0% {
              transform: translate(786.602336px, 1324.742811px) rotate(0deg);
            }
            50% {
              transform: translate(786.602336px, 1324.742811px) rotate(-11.363903deg);
            }
            100% {
              transform: translate(786.602336px, 1324.742811px) rotate(0deg);
            }
          }
          #e8r5O4hIU6W8_tr {
            animation: e8r5O4hIU6W8_tr__tr 1000ms linear infinite normal forwards;
          }
          @keyframes e8r5O4hIU6W8_tr__tr {
            0% {
              transform: translate(833.910001px, 1328.46px) rotate(0deg);
            }
            50% {
              transform: translate(833.910001px, 1328.46px) rotate(-5.844185deg);
            }
            100% {
              transform: translate(833.910001px, 1328.46px) rotate(0deg);
            }
          }
          #e8r5O4hIU6W10_tr {
            animation: e8r5O4hIU6W10_tr__tr 1000ms linear infinite normal forwards;
          }
          @keyframes e8r5O4hIU6W10_tr__tr {
            0% {
              transform: translate(786.602332px, 1371.147573px) rotate(-18.827073deg);
            }
            50% {
              transform: translate(786.602332px, 1371.147573px) rotate(-14.317009deg);
            }
            100% {
              transform: translate(786.602332px, 1371.147573px) rotate(-18.827073deg);
            }
          }
          #e8r5O4hIU6W15_tr {
            animation: e8r5O4hIU6W15_tr__tr 1000ms linear infinite normal forwards;
          }
          @keyframes e8r5O4hIU6W15_tr__tr {
            0% {
              transform: translate(-264.606854px, 324.618427px) rotate(0deg);
            }
            50% {
              transform: translate(-264.606854px, 324.618427px) rotate(-4.620732deg);
            }
            100% {
              transform: translate(-264.606854px, 324.618427px) rotate(0deg);
            }
          }
        `}
      </style>
      <defs>
        <linearGradient
          id="e8r5O4hIU6W5-fill"
          x1="24.73"
          y1="-176.19"
          x2="1316.61"
          y2="1366.67"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W5-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W5-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W5-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W5-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
        <linearGradient
          id="e8r5O4hIU6W7-fill"
          x1="-141.06"
          y1="-37.37"
          x2="1150.82"
          y2="1505.49"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W7-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W7-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W7-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W7-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
        <linearGradient
          id="e8r5O4hIU6W9-fill"
          x1="121.45"
          y1="-257.18"
          x2="1413.34"
          y2="1285.68"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W9-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W9-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W9-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W9-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
        <linearGradient
          id="e8r5O4hIU6W12-fill"
          x1="-486.19"
          y1="251.62"
          x2="805.69"
          y2="1794.48"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W12-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W12-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W12-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W12-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
        <linearGradient
          id="e8r5O4hIU6W13-fill"
          x1="-418.06"
          y1="194.57"
          x2="873.83"
          y2="1737.42"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W13-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W13-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W13-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W13-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
        <linearGradient
          id="e8r5O4hIU6W15-fill"
          x1="59.08"
          y1="-204.95"
          x2="1350.96"
          y2="1337.9"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0 0)">
          <stop id="e8r5O4hIU6W15-fill-0" offset="0%" stopColor="#fbda61" />
          <stop id="e8r5O4hIU6W15-fill-1" offset="14%" stopColor="#fbc671" />
          <stop id="e8r5O4hIU6W15-fill-2" offset="73%" stopColor="#fe78b2" />
          <stop id="e8r5O4hIU6W15-fill-3" offset="100%" stopColor="#ff5acd" />
        </linearGradient>
      </defs>
      <g transform="translate(-151.966 -14.6075)">
        <g transform="matrix(.539583 0 0 0.539583 490.035 298.273)">
          <g
            id="e8r5O4hIU6W4_tr"
            transform="translate(823.612004, 1319.99) rotate(0)">
            <g transform="translate(-60.732004, -652.405)">
              <path
                d="M657.44,0c-147.22,261-75.18,506.42,14.91,645.79c29.81,40.84,28.52,40.27,70.99,93.78c6.93,8.88,13.86,18,20.79,27.36c115.95,145.51,180.66,310.23,40.93,568.24C1184.9,924.71,719.16,480.43,657.44,0Z"
                transform="translate(-762.88 -667.585)"
                fill="url(#e8r5O4hIU6W5-fill)"
                fillRule="evenodd"
              />
            </g>
          </g>
          <g
            id="e8r5O4hIU6W6_tr"
            transform="translate(786.602336, 1324.742811) rotate(0)">
            <g transform="translate(-140.468336, -470.897811)">
              <path
                d="M424.97,370.51c43.91,384.23,125.94,392.71,281.22,526.51c153.02,128.72,126.18,295.3,79.21,440.16c272.76-490.12-210.71-638.37-360.42-966.67h-.01Z"
                transform="translate(-646.134 -853.845)"
                fill="url(#e8r5O4hIU6W7-fill)"
                fillRule="evenodd"
              />
            </g>
          </g>
          <g
            id="e8r5O4hIU6W8_tr"
            transform="translate(833.91, 1328.46) rotate(0)">
            <g transform="translate(113.803, -628.12)">
              <path
                d="M1012.55,72.23C875.49,223.07,793.62,305.95,882.01,606.49c78.64,200.95,128.84,395.29,41.66,584.05-17.57,41-41.9,81.11-74.29,120.01l-.4.48c-4.83,5.81-9.91,11.62-15.07,17.43c473.72-318.3,37.95-854.82,178.64-1256.24v.01Z"
                transform="translate(-947.713 -700.34)"
                fill="url(#e8r5O4hIU6W9-fill)"
                fillRule="evenodd"
              />
            </g>
          </g>
          <g
            id="e8r5O4hIU6W10_tr"
            transform="translate(786.602332, 1371.147573) rotate(-18.827073)">
            <g transform="translate(-330.651964, -166.786868)">
              <g transform="matrix(.95 0 0 0.95 -365.676 -1155.97)">
                <path
                  d="M498.86,1415.63C256.89,1384.32,95.33,1211.45,0,995.08c-1.93,375.11,435.52,522.88,738.82,402.39-32.55,9.85-67.04,16.87-102.17,20.58-45.85,4.92-92.83,4.36-137.79-2.42Z"
                  fill="url(#e8r5O4hIU6W12-fill)"
                  fillRule="evenodd"
                />
                <path
                  d="M246.9,1163.51c26.27,245.18,327.95,282.3,522.95,205.88-232.47,68.19-418.44-26.15-522.95-205.88Z"
                  fill="url(#e8r5O4hIU6W13-fill)"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
          <g transform="translate(1163.8308 986.4)">
            <g
              id="e8r5O4hIU6W15_tr"
              transform="translate(-264.606854, 324.618427) rotate(0)">
              <path
                d="M1279.73,765.54c-49.92-23.15-77.76-63.17-101.11-112.73-4.67,42.17-3.65,84.91,19.48,116.7-43.16,10.65-85.82,34.4-121.81,69.65c11.44,178.68-23.69,346.38-202.17,480.83c303.29-155.27,259.14-376.32,388.06-430.8c64.3-29.05,124.9-5.08,171.47,63.51-14.86-115.76-78.65-174.92-153.92-187.17v.01Z"
                transform="translate(-889.283146 -1311.018427)"
                fill="url(#e8r5O4hIU6W15-fill)"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
