import React from 'react';
import { ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { useDispatch } from 'react-redux';
import { setIsTermsAccepted } from '../../../redux/slices/termsAndSubSlice';

interface AgreeAndContinueButtonProps {
  showVip: boolean;
}

export const AgreeAndContinueButton: React.FC<AgreeAndContinueButtonProps> = ({
  showVip,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <button
        onClick={e => {
          e.preventDefault();
          dispatch(setIsTermsAccepted(true));
        }}
        name="agree-continue-button"
        type="button"
        className="w-full bg-page_builder_theme rounded-md border border-transparent px-4 py-4 mb-4 text-2xl lg:text-3xl font-bold !text-white shadow-sm sm:order-last">
        <div className="items-center justify-center flex">
          AGREE & CONTINUE <ArrowLongRightIcon className="h-9 w-9 ml-2" />
        </div>
        {showVip && (
          <p className="text-sm my-2 font-medium">
            TRY IT RISK FREE! 30-DAY MONEY-BACK GUARANTEE!
          </p>
        )}
      </button>
    </>
  );
};
