import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import { showNotification } from 'helper/common/commonFunctions';
import { IShippingDetails } from 'interface/shippingInterface';
import globalShippingService from 'services/shippingService';

export interface IShippingUpdate extends IShippingDetails {
  Version: number;
}
interface IProps {
  handleClose: () => void;
  defaultsValues: IShippingDetails;
  onSuccess: () => void;
}

const AddShipping: FC<IProps> = ({
  handleClose,
  defaultsValues,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<IShippingDetails>({
    defaultValues: defaultsValues,
  });

  const addShippings = async (data: IShippingDetails) => {
    setLoading(true);
    const payload: IShippingDetails = {
      Description: data.Description,
      Name: data.Name,
      Price: data.Price,
      ChannelID: defaultsValues.ChannelID,
    };
    const res = await globalShippingService.createShipping(payload);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Shipping Added Successfully');
      onSuccess();
    }
    handleClose();
  };

  const updateShippings = async (data: IShippingDetails) => {
    setLoading(true);
    const payload: IShippingUpdate = {
      ...data,
      Version: data?.Version ? data.Version : 0,
    };
    const res = await globalShippingService.updateShipping(payload);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Shipping Updated Successfully');
      onSuccess();
    }
    handleClose();
  };
  const handleFormSubmit = (data: IShippingDetails) => {
    if (data.ID) {
      updateShippings(data);
    } else {
      addShippings(data);
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: defaultsValues.ID ? 'Update' : 'Add',
            form: 'AddShipping',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Add'}
        className="flex-container">
        <div className="popup-section">
          <form
            className="grid gap-4"
            id="AddShipping"
            onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
              name="Name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Shipping Name"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter name"
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              name="Description"
              control={control}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Description"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter description"
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              name="Price"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Price"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter the price"
                    ref={ref}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddShipping;
