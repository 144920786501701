import { Fields } from '@measured/puck';

export type WidthProps = {
  width: string;
};

export const widthField: Fields<WidthProps> = {
  width: {
    type: 'select',
    label: 'Width',
    options: [
      { label: '0', value: 'w-0' },
      { label: '1', value: 'w-1' },
      { label: '2', value: 'w-2' },
      { label: '4', value: 'w-4' },
      { label: '8', value: 'w-8' },
      { label: '16', value: 'w-16' },
      { label: '24', value: 'w-24' },
      { label: '32', value: 'w-32' },
      { label: '40', value: 'w-40' },
      { label: 'full', value: 'w-full' },
      { label: 'auto', value: 'w-auto' },
    ],
  },
};
