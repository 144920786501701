import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import {
  setRegistered,
  setShopFromSearchParam,
  setShopifyShopDetails,
  setStoreFrontDetails,
} from 'redux-setup/slices/shopifySlice';
import { Path } from 'router/constant';
import globalStoreFrontService from 'services/storeFrontService';

type Props = {
  auth: {
    isAuthenticated: string;
  };
};

export const ShopifyWrapper = ({ auth: { isAuthenticated } }: Props) => {
  const [searchParams] = useSearchParams();
  const { shop, storeFront, token, isRegistered } = useAppSelector(state => ({
    shop: state.shopify.shop,
    storeFront: state.shopify.storeFront,
    token: state.user.token,
    isRegistered: state.shopify.isRegistered,
  }));
  const dispatch = useAppDispatch();
  const shopFromSearchParam = searchParams.get('shop');
  const id_token = searchParams.get('id_token');

  const setStoreFront = async (storeID: string, shopID: string) => {
    try {
      const storeFrontRes = await globalStoreFrontService.getStoreFront(
        storeID,
        shopID,
      );

      if (storeFrontRes.status === 200 && storeFrontRes?.data) {
        dispatch(setStoreFrontDetails(storeFrontRes.data));
      } else {
        dispatch(setStoreFrontDetails(null));
      }
    } catch (error) {
      console.error('Error fetching store front details:', error);
    }
  };

  const setShopDetails = async (shopID: string) => {
    if (shop?.ShopID === shopID) return;

    try {
      const storeDetails =
        await globalStoreFrontService.getStoreByShopId(shopID);
      if (storeDetails.status === 200 && storeDetails?.data) {
        dispatch(setShopifyShopDetails(storeDetails.data));
      } else {
        dispatch(setShopifyShopDetails(null));
      }
    } catch (error) {
      console.error('Error fetching shop details:', error);
    }
  };

  const registerStoreFront = async () => {
    const shopID = shop?.ShopID;
    if (shopID && id_token) {
      try {
        const res = await globalStoreFrontService.registerStoreFront(
          shopID,
          id_token,
        );
        if (res.status === 200) {
          dispatch(setRegistered(true));
        }
      } catch (error) {
        console.error('Error registering store front:', error);
      }
    }
  };

  useEffect(() => {
    if (shopFromSearchParam && shop?.ShopURL !== shopFromSearchParam) {
      const storeID = shopFromSearchParam.split('.')[0];
      dispatch(setShopFromSearchParam(shopFromSearchParam));
      setShopDetails(storeID);
    }
  }, []);

  useEffect(() => {
    if (
      shop?.StoreID &&
      storeFront?.StoreID !== shop.StoreID &&
      shop?.ShopID &&
      storeFront?.ShopID !== shop.ShopID
    ) {
      setStoreFront(shop.StoreID, shop?.ShopID);
    }
  }, [shop]);

  useEffect(() => {
    if (shop && id_token && !isRegistered) {
      registerStoreFront();
    }
  }, [id_token, shop]);

  useEffect(() => {
    window.parent.postMessage(
      {
        loginData: token,
      },
      '*',
    );
  }, [token]);

  return isAuthenticated ? <Outlet /> : <Navigate to={Path.LOGIN} />;
};
