import { MouseEvent, useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  matchPath,
  useParams,
  NavLink,
} from 'react-router-dom';
import Popover from '@mui/material/Popover';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { headerDetails } from 'layouts/constant';
import { logOut } from 'services/authService';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-back.svg';
import { ReactComponent as MoonIcon } from 'assets/icons/moon.svg';
import { ReactComponent as SunIcon } from 'assets/icons/sun.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification-bell.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-dots.svg';
import placeHolderImg from 'assets/icons/Avatar-Placeholder.png';
import { Path } from 'router/constant';
import OrderDetailHeader from 'components/orders/orderDetails/header';
import userService from 'services/userService';
import { IInvitation } from 'interface/userInterface';
import TimeZoneSelect from 'components/common/timeZoneSelect';
import { setMyInvitationDetails } from 'redux-setup/slices/InvitationSlice';
import HeaderStoreDetails from 'components/store/storeDetails/header';
import HeaderStoreFrontDetails from 'components/store/storeDetails/shopify/shopifyDetails/header';
import { setTheme } from 'redux-setup/slices/themeSlice';
import CustomMultiSelectComponent from 'components/customMultiSelectComponent';

// const paths = [Path.SHOPIFY_DETAILS, Path.STORE_DETAILS];
export const TopNavigation = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector(state => state.theme);
  const { myInvitesList } = useAppSelector(state => state.invitation);
  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // const useShowStoreFront = useMemo(() => {
  //   return paths.some(path => {
  //     return matchPath(path, pathname) != null ? true : false;
  //   });
  // }, [pathname]);

  const getHeaderForDynamicPath = () => {
    // this function returns the configured display content based on dynamic paths
    if (matchPath(Path.ORDER_DETAILS, pathname)) {
      return {
        display: <OrderDetailHeader />,
        goBack: true,
      };
    }
    if (matchPath(Path.CUSTOMER_DETAILS, pathname)) {
      return headerDetails[Path.CUSTOMER_DETAILS];
    }

    if (matchPath(Path.SHOPIFY_DETAILS, pathname)) {
      return {
        display: <HeaderStoreFrontDetails />,
        goBack: true,
      };
    }

    if (matchPath(Path.STORE_DETAILS, pathname)) {
      return {
        display: <HeaderStoreDetails />,
        goBack: true,
      };
    }
    return { display: '' };
  };

  const getUserInvites = async () => {
    const res = await userService.getInvites({
      Descending: false,
      Limit: 25,
      OrderBy: 'CreatedAt',
      Page: 0,
    });
    if (res.status === 200) {
      if (res?.data?.Result?.length > 0) {
        dispatch(
          setMyInvitationDetails({
            myInvitesList: res?.data?.Result,
          }),
        );
      }
    }
  };

  const isMyInvitePending = useMemo(
    () =>
      myInvitesList?.length &&
      myInvitesList.some((invites: IInvitation) => invites.Status == 'pending'),
    [myInvitesList],
  );

  const header: { display: string | JSX.Element; goBack?: boolean } =
    useMemo(() => {
      if (headerDetails?.[pathname]) {
        // configure header for static paths eg: "/app/order"
        return headerDetails?.[pathname];
      }
      if (Object.keys(params).length) {
        // configure header for dynamic paths eg: "/app/order/:orderId"
        return getHeaderForDynamicPath();
      }
      return { display: '' };
    }, [pathname]);

  const goBack = () => {
    navigate(-1);
  };

  const handleClick = (event: MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleTheme = () => {
    dispatch(setTheme(!theme));
  };

  useEffect(() => {
    getUserInvites();
  }, []);

  return (
    <div className={`top_navigation ${showHeaderMenu ? 'showHeaderMenu' : ''}`}>
      <div className="header_text">
        {header?.goBack && (
          <BackIcon
            className="back_button"
            onClick={() => {
              goBack();
            }}
          />
        )}
        {header.display}
      </div>
      <div
        className="moreHeaderMenu"
        onClick={() => setShowHeaderMenu(!showHeaderMenu)}>
        {showHeaderMenu ? <CloseIcon /> : <MenuIcon />}
      </div>
      <div className="filter_values" key={'side_multi_store'}>
        <CustomMultiSelectComponent />
      </div>
      <div className="right_side_header">
        {/* <div className="timezone-select-container store-front-dropdown-wrapper">
          <SelectStoreFrontComponent />
        </div> */}
        <div className="timezone-select-container">
          <TimeZoneSelect />
        </div>
        <div className="header-actions">
          <NavLink
            to={Path.INVITES}
            className={({ isActive }) =>
              `${isActive ? 'active_item' : ''} ${isMyInvitePending ? 'active-badge' : ''} notification-link`
            }>
            <NotificationIcon />
          </NavLink>
          <div className="cursor-pointer" onClick={toggleTheme}>
            {theme ? <MoonIcon /> : <SunIcon />}
          </div>
          <img
            src={placeHolderImg}
            className="logout_image"
            alt="image"
            aria-describedby={id}
            onClick={handleClick}
          />
          <Popover
            className="user-popover"
            open={open}
            id={id}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <div className="logout_popup" onClick={logOut}>
              <LogoutIcon />
              Logout
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};
