import { KeyboardEvent, forwardRef, useState } from 'react';
import {
  DatePicker as MUIDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'components/common/datePicker/style.scss';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar_today.svg';
import { StandardTextFieldProps } from '@mui/material';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps extends DatePickerProps<unknown> {
  placeholder?: string;
  error?: string;
  label?: string;
  textFieldProps?: StandardTextFieldProps;
}

const DatePicker = forwardRef<HTMLDivElement, IProps>(
  (
    {
      className = '',
      placeholder = 'MM/DD/YYYY',
      error,
      label,
      textFieldProps: { disabled, ...textFieldProps } = {},
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="common_date_picker">
          {label && <p className="common_label_text">{label}</p>}
          <MUIDatePicker
            ref={ref}
            className={`${error ? 'date_picker_error' : ''} ${className}`}
            open={open}
            onClose={() => setOpen(false)}
            slots={{ openPickerIcon: CalendarIcon }}
            slotProps={{
              // actionBar: {
              //   actions: ['today', 'clear'],
              // },
              popper: {
                className: 'date_picker_popper',
              },
              textField: {
                placeholder,
                inputProps: {
                  className: 'date_picker_input',
                },
                onKeyDown: (e: KeyboardEvent) => {
                  // to diable the manual edit in datepicker
                  if (disabled) e.preventDefault();
                },
                onClick: () => {
                  setOpen(true);
                },
                ...textFieldProps,
              },
              inputAdornment: {
                position: 'start',
              },
            }}
            {...rest}
          />
          {error ? <p className="common_error_text">{error}</p> : <></>}
        </div>
      </LocalizationProvider>
    );
  },
);
DatePicker.displayName = 'DatePicker';
export default DatePicker;

interface IControlledDatePicker extends IProps {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
  valueHandler?: (_value: string) => string | dayjs.Dayjs;
}

export const ControlledDatePicker = ({
  name,
  rules,
  valueHandler,
  ...rest
}: IControlledDatePicker) => {
  return (
    <Controller
      name={name}
      rules={rules ? rules : {}}
      render={({ field: { value, ...restFields }, fieldState: { error } }) => {
        return (
          <DatePicker
            {...rest}
            {...restFields}
            value={valueHandler ? valueHandler(value) : value}
            error={error?.message}
          />
        );
      }}
    />
  );
};
