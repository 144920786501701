import axios, { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getFbValues } from '../utils/getFbValues';
import { v4 as uuidv4 } from 'uuid';

type RequestOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers?: Record<string, string>;
  body?: unknown;
};
const retryStatusCodes = [409, 404, 408, 425, 429, 502, 503, 504];

const baseURL = process.env.REACT_APP_PHOENIX_STOREFRONT_URL;
const isLocalhost =
  typeof window !== 'undefined' && window.location.hostname === 'localhost';
const { timestampUnix, _fbc, _fbp } = getFbValues();

const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async config => {
      if (!config.timeout) {
        config.timeout = 10000;
      }
      config.headers['Content-Type'] = 'application/json';
      config.headers['x-phx-request-id'] = uuidv4();
      config.headers['x-phx-meta-timestamp'] = timestampUnix;
      if (_fbc) {
        config.headers['x-phx-meta-fbc'] = _fbc;
      }
      if (_fbp) {
        config.headers['x-phx-meta-fbp'] = _fbp;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status >= 500 && !isLocalhost) {
        const errorDetails = {
          message: error?.message,
          status: error?.response?.status,
          requestUrl: error?.config?.url,
        };
        console.log(new Error(JSON.stringify(errorDetails)));
      }
      return Promise.reject(error);
    },
  );
};

export const baseService = axios.create({
  baseURL,
});

setInterceptors(baseService);

axiosRetry(baseService, {
  retries: 2,
  retryCondition: (error: AxiosError) =>
    retryStatusCodes.includes(error?.response?.status as number),
  retryDelay: retryCount => retryCount * 100,
});

function apiClient() {
  async function get(endpoint: string, { headers = {} }: RequestOptions = {}) {
    const url = `${baseURL}${endpoint}`;

    try {
      const response = await baseService.get(url, {
        headers: { 'Content-Type': 'application/json', ...headers },
        timeout: 10000,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return error.message;
      }
    }
  }
  async function post(
    endpoint: string,
    body: unknown,
    { headers = {} }: RequestOptions = {},
  ) {
    const url = `${baseURL}${endpoint}`;

    try {
      const response = await baseService.post(url, body, {
        headers: { 'Content-Type': 'application/json', ...headers },
        timeout: 10000,
      });
      return response;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return error.message;
      }
    }
  }

  return { get, post };
}
export default apiClient;
