import { FC } from 'react';
import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';
import './style.scss';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

const Checkbox: FC<CheckboxProps> = ({ className = '', ...rest }) => {
  return <MUICheckbox className={`common_check_box ${className}`} {...rest} />;
};

export default Checkbox;

interface IControlledCheckbox {
  name: string;
  className?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledCheckbox = ({
  name,
  rules,
  ...rest
}: IControlledCheckbox) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, ...restFields } }) => {
        return <Checkbox {...rest} {...restFields} checked={value} />;
      }}
    />
  );
};
