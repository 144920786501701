import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
// import { get } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import { get } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import storeService from 'services/storeService';
import { ICombainedPaymentGateway } from 'interface/settingsInterface';
import AddPaymentGateway from './addPaymentGatewayPopup';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ICombainedPaymentGateway) => ReactNode;
  hide?: boolean;
}

interface IProps {
  storeID: string;
}

export default function Gateway({ storeID }: IProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentGateways, setPaymentGateways] = useState<
    ICombainedPaymentGateway[]
  >([]);
  const [isLegacyPaymentGatewayAvaiable, setIsLegacyPaymentGatewayAvaiable] =
    useState<boolean>(false);

  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState<ICombainedPaymentGateway>({
      StoreID: storeID,
    });
  const [open, setOpen] = useState<boolean>(false);

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Phoenix Secret token',
      hide: false,
      cellRender: row => {
        return <>{row.Name ?? '***********'}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div
            onClick={() => {
              setSelectedPaymentGateway(row);
              setOpen(true);
            }}>
            <CreateIcon className="cursor-pointer" />
          </div>
        );
      },
    },
  ];

  const getLeacyPaymentGateways = async () => {
    setLoading(true);
    const res = await storeService.getLegacyPaymentGateways(storeID);

    setLoading(false);
    if (res.status === 200 && res?.data) {
      setPaymentGateways(pre => [...pre, res.data]);
      setIsLegacyPaymentGatewayAvaiable(true);
    } else {
      setIsLegacyPaymentGatewayAvaiable(false);
    }
  };
  const getPaymentGateways = async () => {
    setLoading(true);
    const res = await storeService.getPaymentGatewayList(storeID);
    setLoading(false);
    if (res.status === 200 && res?.data?.Result) {
      setPaymentGateways(pre => [...pre, ...res.data.Result]);
    }
  };

  const handleSuccessCallback = () => {
    getLeacyPaymentGateways();
    getPaymentGateways();
    setOpen(false);
  };

  useEffect(() => {
    getLeacyPaymentGateways();
    getPaymentGateways();
    return () => {
      setPaymentGateways([]);
    };
  }, []);

  return (
    <>
      <div className="payment_gateway">
        <div className="payment_gateway_wrapper">
          <div className="store_management">
            <TableContainer className="Common_Table">
              <Table
                className="risk_management_table"
                aria-labelledby="tableTitle"
                stickyHeader>
                <TableHead className="table_header">
                  <TableRow>
                    {headCellList.map(headCell => {
                      if (headCell.hide) {
                        return null;
                      }
                      return (
                        <TableCell
                          className="table_header_cell header_text"
                          key={headCell.label}>
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody className="table_body">
                  {paymentGateways && paymentGateways?.length > 0 ? (
                    paymentGateways?.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          className="table_row"
                          role="checkbox"
                          tabIndex={-1}
                          key={index}>
                          {headCellList.map(headCell => {
                            if (headCell.hide) {
                              return null;
                            }
                            return (
                              <TableCell
                                className="table_cell"
                                key={headCell.label}
                                component="th"
                                id={headCell.id}
                                scope="row">
                                <>
                                  {headCell?.cellRender
                                    ? headCell.cellRender(row)
                                    : get(row, headCell.id)}
                                </>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="!py-20" colSpan={15}>
                        <div className="no-data-row">No data found</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <button
                        className="sticky-button flex items-center"
                        onClick={() => {
                          setSelectedPaymentGateway({ StoreID: storeID });
                          setOpen(true);
                        }}>
                        <AddIcon /> Add Payment Gateway
                      </button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <Loader loading={loading} />
        </div>
        {open && (
          <AddPaymentGateway
            paymentGatewayValue={selectedPaymentGateway}
            isLegacyPaymentGatewayAvaiable={isLegacyPaymentGatewayAvaiable}
            handleClose={() => setOpen(false)}
            onSuccess={() => {
              handleSuccessCallback();
            }}
          />
        )}
      </div>
    </>
  );
}
