import { createSlice } from '@reduxjs/toolkit';
import {
  Item,
  UpSellData,
  Variant,
} from '../../../page-builder/interface/checkoutInterface';

export interface PreUpsellState {
  preUpsellData: UpSellData;
}

const initialVariant: Variant = {
  ID: '',
  ImageURL: '',
  OriginalPrice: '',
  Price: '',
  Title: '',
};

const initialItem: Item = {
  ID: '',
  ImageURL: '',
  Title: '',
  Variants: [initialVariant],
};

const initialState: PreUpsellState = {
  preUpsellData: [initialItem],
};

export const preUpsellSlice = createSlice({
  name: 'preUpsell',
  initialState,
  reducers: {
    setPreUpsellData(state, action) {
      return { ...state, preUpsellData: action.payload };
    },
  },
});

export const { setPreUpsellData } = preUpsellSlice.actions;

export default preUpsellSlice.reducer;
