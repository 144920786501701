import { forwardRef } from 'react';
import {
  TextField as TextFieldComponent,
  StandardTextFieldProps,
  InputAdornment,
} from '@mui/material';
import 'components/common/textField/style.scss';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface ITextFieldProps extends StandardTextFieldProps {
  label?: string;
  fixedSize?: 'xs' | 'sm' | 'md' | 'lg';
  className?: string;
  errorString?: string;
  isPrefixText?: string;
  isSufficText?: string;
  type?: string;
}

const TextField = forwardRef<HTMLInputElement, ITextFieldProps>(
  (
    {
      className = '',
      errorString = '',
      label,
      fixedSize,
      isPrefixText,
      type = 'text',
      isSufficText,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={`text_field_wrapper ${className}`}>
        {label && <p className="common_input_label">{label}</p>}
        <TextFieldComponent
          {...rest}
          variant="outlined"
          error={errorString ? true : false}
          className={`text_field_input ${fixedSize && `size_${fixedSize}`}`}
          inputRef={ref}
          type={type}
          id="outlined-basic"
          autoComplete="off"
          InputProps={
            isPrefixText
              ? {
                  startAdornment: (
                    <InputAdornment position="start">
                      {isPrefixText}
                    </InputAdornment>
                  ),
                }
              : isSufficText
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        {isSufficText}
                      </InputAdornment>
                    ),
                  }
                : undefined
          }
        />
        {errorString ? <p className="error_message">{errorString}</p> : <></>}
      </div>
    );
  },
);

TextField.displayName = 'TextField';
export default TextField;

interface ITextFieldControlProps extends ITextFieldProps {
  name: string;
  onChangeHandler?: (_value: string) => string;
  newValueHandler?: (_value: string) => string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const TextFieldControl = ({
  name,
  rules,
  onChangeHandler,
  ...rest
}: ITextFieldControlProps) => {
  return (
    <Controller
      name={name}
      render={({
        field: { onChange, ...restField },
        fieldState: { error },
      }) => {
        return (
          <TextField
            {...rest}
            {...restField}
            errorString={error?.message}
            onChange={e =>
              onChange(
                onChangeHandler
                  ? onChangeHandler(e.target.value)
                  : e.target.value,
              )
            }
          />
        );
      }}
      rules={rules}
    />
  );
};
