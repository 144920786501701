import { Fields } from '@measured/puck';

export type BorderRadiusProps = {
  borderRadius: string;
};

export const borderRadiusField: Fields<BorderRadiusProps> = {
  borderRadius: {
    type: 'select',
    label: 'Border Radius',
    options: [
      { label: 'none', value: 'rounded-none' },
      { label: 'small', value: 'rounded-sm' },
      { label: 'medium', value: 'rounded-md' },
      { label: 'large', value: 'rounded-lg' },
      { label: 'full', value: 'rounded-full' },
    ],
  },
};
