import { Path as CheckoutPath } from 'shared-components';
export const Path = {
  LOGIN: '/login',
  APP: '/app',
  SHOPIFY_APP: '/shopify',
  DASHBOARD: '/app/dashboard',
  TRANSACTIONS: '/app/transactions',
  TRANSACTION_LOG: '/app/transactions_log',
  CUSTOMERS: '/app/customers',
  CUSTOMER_DETAILS: '/app/customers/:customer_id/:store_id',
  STORE_DETAILS: '/app/setting/store/:storeID?',
  SHOPIFY_DETAILS:
    '/app/setting/store/:storeId/shopify/:shopifyId/shopId/:shopId',
  SUBSCRIPTION: '/app/subscription',
  PROJECTIONS: '/app/subscription/projections',
  MANAGEMENT: '/app/subscription/management',
  WITHOUTNAV: '/wihtoutnav',
  ORDERS: '/app/orders',
  ABANDONED_CARTS: '/app/abandoned_carts',
  SETTINGS: '/app/settings',
  SETTING: '/app/setting',
  NEWSETTING: '/app/newsetting',
  STORE: '/app/newsetting/store',
  SHOPIFY: '/app/newsetting/shopify',
  PAYMENT_GATEWAY: '/app/newsetting/payment_gateway',
  PRE_UPSELL: '/app/newsetting/shopify/pre_up_sell',
  SUBSCRIPTION_PLAN: '/app/newsetting/subscription_plan',
  USERS_PERMISSION: '/app/newsetting/users_permission',
  POST_UPSELLS: '/app/newsetting/shopify/post_upsells',
  PAGES: '/app/newsetting/shopify/pages',
  SHIPPING: '/app/newsetting/shopify/shipping',
  PAYMENT_ROUTING: '/app/newsetting/payment_routing',
  INVITES: '/app/invites',
  ON_BOARDING: '/onboarding',
  ORDER_DETAILS: '/app/orders/:orderId/store/:storeId',
  CART_DETAILS: '/app/abandoned_carts/:channelID/:cartID',
  RISK: '/app/reporting/risk',
  REPORTING: '/app/reporting',
  RETENTION_REPORT: '/app/reporting/retention',
  SUBSCRIPTION_SALVAGE: '/app/reporting/subscription_salvage',
  MEMBERSHIP: '/shopify/membership',
  ADD_MEMBERSHIP: '/shopify/editMembership',
  EDIT_MEMBERSHIP: '/shopify/editMembership/:id',
  MEMBERSHIP_ADD_OFFER: '/shopify/editMembershipOffer',
  MEMBERSHIP_OFFER: '/shopify/membershipOffer',
  ADD_MEMBERSHIP_OFFER: '/shopify/editMembershipOffer/:id',
  SHOPIFY_SETTINGS: '/shopify/shopify_settings',
  SHOPIFY_SETTINGS_PAGE: '/shopify/settings',
  SHOPIFY_STORE_DETAILS: '/shopify/setting/store/:storeID',
  SHOPIFY_STORE_CREATE: '/shopify/setting/store/create',
  SHOPIFY_STOREFRONT_CREATE:
    '/shopify/setting/store/:storeID/storefront/create',
  NOT_FOUND_PAGE: '/not-found-page',
  CASCADING: '/app/cascade',
  STORE_FRONT_EVENTS: '/app/store-front-events',
  SUBSCRIPTION_EVENTS: '/app/subscription-events',
  ADMIN_USERS: '/app/admin-users',
  STORE_OVERVIEW: '/app/stores/overview',
  BILLING_SUMMARY: '/app/admin/billing-summary',
  PRODUCTS: '/shopify/products',
  INVITE_USER: '/app/store/:storeId/invite/:inviteId',
  // Page Builder routes
  PAGE_BUILDER: '/page-builder/**',
  PAGE_BUILDER_ROUTE: '/page-builder',
};
export const Permission = {
  ORDER: 'order',
  TRANSACTION: 'transaction',
  SUBSCRIPTION: 'subscription',
  CUSTOMER: 'customer',
  DASHBOARD: 'dashboard',
  SETTINGS: 'setting',
  REPORTING: 'reporting',
  RENTENTIONREPORT: 'retention',
  CASCADE: 'cascade',
  STOREFRONT: 'storeFront',
  EVENTS: 'events',
  STORE: 'store',
  PAYMENT: 'payment',
  PRODUCT: 'product',
  USER: 'user',
};
export const getCustomerDetailsPath = (customer_id: string, store_id: string) =>
  `/app/customers/${customer_id}/${store_id}`;

export const getOrderDetialsPath = (orderId: string, storeId: string) =>
  `/app/orders/${orderId}/store/${storeId}`;

export const getCartDetialsPath = (channelID: string, cartID: string) =>
  `/app/abandoned_carts/${channelID}/${cartID}`;

export const unsafe_experimental_featuers = 'unsafe_experimental_featuers';

export const getStoreDetailsPath = (storeId: string, tab: number) => {
  return `/app/setting/store/${storeId}?tab=${tab}`;
};
export const getShopifyStoreDetailsPath = (storeId: string, tab: number) => {
  return `/shopify/setting/store/${storeId}?tab=${tab}`;
};
export const getShopifyStoreforntCreatePath = (storeId: string) => {
  return `/shopify/setting/store/${storeId}/storefront/create`;
};

export const getShopifyeDetailsPath = (
  storeId: string,
  shopifyId: string,
  shopID: string,
  tab: number,
) => {
  return `/app/setting/store/${storeId}/shopify/${shopifyId}/shopId/${shopID}?tab=${tab}`;
};

export const getPagePath = (query: URLSearchParams) => {
  return `${CheckoutPath?.PAGE_BUILDER_EDIT}/?${query.toString()}`;
};

export type StoreFrontPageNames =
  | 'PAGE'
  | 'SHIPPING'
  | 'PRE_UPSELL'
  | 'POST_UPSELL'
  | 'CHECKOUT_FLOW'
  | 'PAYMENT_ROUTING'
  | 'CONVERSION_TRACKING'
  | 'MEMBERSHIP_OFFERS';

export type StorePageNames =
  | 'STOREFRONT'
  | 'SUBSCRIPTION_PLAN'
  | 'USER_PERMISSION'
  | 'PAYMENT_GATEWAY'
  | 'NEW_PAYMENT_GATEWAY';

export type RoutesPageNames =
  | 'DASHBOARD'
  | 'CUSTOMER'
  | 'ORDER'
  | 'TRANSACTION'
  | 'TRANSACTION_LOG'
  | 'ABANDONED_CARTS'
  | 'STOREFRONT_EVENTS'
  | 'SUBSCRIPTION'
  | 'ADMIN_USERS'
  | 'STORE_OVERVIEW'
  | 'BILLING_SUMMARY'
  | 'SETTING'
  | 'INVITES'
  | 'CUSTOMER_DETAILS'
  | 'SUBSCRIPTION_PROJECTION'
  | 'SUBSCRIPTION_MANAGEMENT'
  | 'STORE_DETAILS'
  | 'SHOPIFY_DETAILS'
  | 'ORDER_DETAILS'
  | 'CART_DETAILS'
  | 'RETENTION_REPORT'
  | 'RISK'
  | 'CASCADING'
  | 'SUBSCRIPTION_SALVAGE'
  | 'PAGE_BUILDER_EDIT'
  | 'PAGE_BUILDER_VIEW'
  | 'SUBSCRIPTION_EVENTS'
  | 'INVITE_USERS';

export type SideNavItem = 'REPORTING';

export type OtherFeatures = 'NEW_DASHBOARD' | 'MEMBERSHIP_TYPE' | 'NEW_REFUND';

export type PageNames =
  | RoutesPageNames
  | StoreFrontPageNames
  | StorePageNames
  | SideNavItem
  | OtherFeatures;

export const pageNames: Record<PageNames, PageNames> = {
  DASHBOARD: 'DASHBOARD',
  NEW_DASHBOARD: 'NEW_DASHBOARD',
  MEMBERSHIP_TYPE: 'MEMBERSHIP_TYPE',
  CUSTOMER: 'CUSTOMER',
  ORDER: 'ORDER',
  TRANSACTION: 'TRANSACTION',
  TRANSACTION_LOG: 'TRANSACTION_LOG',
  ABANDONED_CARTS: 'ABANDONED_CARTS',
  STOREFRONT_EVENTS: 'STOREFRONT_EVENTS',
  SUBSCRIPTION: 'SUBSCRIPTION',
  ADMIN_USERS: 'ADMIN_USERS',
  STORE_OVERVIEW: 'STORE_OVERVIEW',
  BILLING_SUMMARY: 'BILLING_SUMMARY',
  SETTING: 'SETTING',
  INVITES: 'INVITES',
  CUSTOMER_DETAILS: 'CUSTOMER_DETAILS',
  SUBSCRIPTION_PROJECTION: 'SUBSCRIPTION_PROJECTION',
  SUBSCRIPTION_MANAGEMENT: 'SUBSCRIPTION_MANAGEMENT',
  STORE_DETAILS: 'STORE_DETAILS',
  SHOPIFY_DETAILS: 'SHOPIFY_DETAILS',
  ORDER_DETAILS: 'ORDER_DETAILS',
  CART_DETAILS: 'CART_DETAILS',
  RETENTION_REPORT: 'RETENTION_REPORT',
  REPORTING: 'REPORTING',
  RISK: 'RISK',
  CASCADING: 'CASCADING',
  SUBSCRIPTION_SALVAGE: 'SUBSCRIPTION_SALVAGE',
  PAGE_BUILDER_EDIT: 'PAGE_BUILDER_EDIT',
  PAGE_BUILDER_VIEW: 'PAGE_BUILDER_VIEW',
  INVITE_USERS: 'INVITE_USERS',
  STOREFRONT: 'STOREFRONT',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
  USER_PERMISSION: 'USER_PERMISSION',
  PAYMENT_GATEWAY: 'PAYMENT_GATEWAY',
  NEW_PAYMENT_GATEWAY: 'NEW_PAYMENT_GATEWAY',
  PAGE: 'PAGE',
  SHIPPING: 'SHIPPING',
  PRE_UPSELL: 'PRE_UPSELL',
  POST_UPSELL: 'POST_UPSELL',
  CHECKOUT_FLOW: 'CHECKOUT_FLOW',
  PAYMENT_ROUTING: 'PAYMENT_ROUTING',
  CONVERSION_TRACKING: 'CONVERSION_TRACKING',
  MEMBERSHIP_OFFERS: 'MEMBERSHIP_OFFERS',
  SUBSCRIPTION_EVENTS: 'SUBSCRIPTION_EVENTS',
  NEW_REFUND: 'NEW_REFUND',
};
