export const MoonIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.957 22C15.733 22 18.2684 20.737 19.9481 18.6675C20.1966 18.3613 19.9256 17.9141 19.5416 17.9872C15.1751 18.8188 11.1652 15.4709 11.1652 11.063C11.1652 8.52398 12.5244 6.18914 14.7335 4.93199C15.074 4.73821 14.9884 4.22194 14.6015 4.15047C14.059 4.05045 13.5086 4.00008 12.957 4C7.98914 4 3.95703 8.02578 3.95703 13C3.95703 17.9679 7.98281 22 12.957 22Z"
        fill="currentColor"
      />
    </svg>
  );
};
