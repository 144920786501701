import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import { ReactComponent as PurchaseIcon } from 'assets/icons/purchase.svg';
import { ReactComponent as SubscriptionIcon } from 'assets/icons/Group.svg';
import { ReactComponent as Upsell } from 'assets/icons/upsell-icon.svg';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  formattedDate,
  getTotalPages,
  iterateHeadCellKeys,
  setPaginationCommon,
} from 'utils/helper';
import {
  ISearchTransactionLogBody,
  ITransactionLogData,
  ITransactionLogFormFilter,
  ITransactionLogTableFilter,
} from 'interface/transactionLog';
import globalTransactionService from 'services/transactionService';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import SearchFilter from 'components/common/searchFilter';
import { Loader } from 'components/common/loader';
import SideFilter from 'components/transactionLog/transactionFilter/sideFilter';
import dayjs from 'dayjs';
import { defaultTransactionFilterValues } from 'components/transactions';
import { get } from 'lodash';
import StatusBadge from 'components/common/statusBadge';
import './style.scss';
import PaginationCommon from 'components/common/PaginationCommon';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ITransactionLogData) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}
export interface SearchProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TransactionLog: React.FC = () => {
  const [search, setSearchValue] = useState<SearchProps>({});
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const headCells: readonly HeadCell[] = [
    {
      id: 'CreatedAt',
      label: 'Date',
      key: 'date',
      cellRender: row => {
        return (
          <Tooltip placement="top" title={formattedDate(row?.CreatedAt)} arrow>
            <span>{formattedDate(row?.CreatedAt, true)}</span>
          </Tooltip>
        );
      },
      hide: false,
      showSortIcon: true,
    },

    {
      id: 'Name',
      label: 'Action By',
      key: 'Action By',
      cellRender: row => {
        return (
          <p>
            {row?.FirstName} {row?.LastName}
          </p>
        );
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'Channel.Name',
      label: 'Channel',
      key: 'channel',
      hide: false,
      showSortIcon: true,
      showInSearch: true,
      cellRender: row => {
        return <p>{row?.Channel?.Name ? row?.Channel?.Name : '-'}</p>;
      },
    },
    {
      id: 'TransactionKind',
      label: 'Type',
      key: 'type',
      cellRender: row =>
        row.TransactionKind === 'purchase' ? (
          <>
            <Tooltip title={row.TransactionKind} arrow>
              <PurchaseIcon className="table-icons" />
            </Tooltip>
          </>
        ) : row.TransactionKind === 'subscription' ? (
          <Tooltip title={row.TransactionKind} arrow>
            <SubscriptionIcon className="table-icons" />
          </Tooltip>
        ) : (
          <Tooltip title={row.TransactionKind} arrow>
            <Upsell className="table-icons" />
          </Tooltip>
        ),
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },

    {
      id: 'Status',
      label: 'Status',
      key: 'status',
      cellRender: row => {
        return <StatusBadge status={row?.EventType} />;
      },
      hide: false,
      showSortIcon: true,
    },

    {
      id: 'Amount',
      label: 'Amount',
      key: 'amount',
      cellRender: row => {
        return <p>{row?.Amount}</p>;
      },
      hide: false,
      showSortIcon: true,
    },

    {
      id: 'Store.Name',
      label: 'Store',
      key: 'store',
      cellRender: row => {
        return <p>{`${row.Store.Name || 'N/A'}`}</p>;
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'occurenceDate',
      label: 'Occurence Date',
      key: 'occurence_date',
      cellRender: row => {
        return (
          <Tooltip
            placement="top"
            title={formattedDate(row?.OccurenceDate)}
            arrow>
            <span>{formattedDate(row?.OccurenceDate, true)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'FailureReason',
      label: 'Failure Reason',
      key: 'failure_reason',
      cellRender: row => {
        return (
          <Tooltip placement="top" title={row?.Details} arrow>
            <span>{row?.Details}</span>
          </Tooltip>
        );
      },
      hide: false,
      showSortIcon: false,
    },
    {
      id: 'Done By',
      label: 'Done By',
      key: 'Done By',
      cellRender: row => {
        return row.Trigger ? row.Trigger : '-';
      },
      hide: false,
      showSortIcon: false,
    },
  ];

  const filteredField = iterateHeadCellKeys([...headCells]);
  const rowsPerPage = 25;
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(0);
  const ref = useRef<HTMLInputElement>(null);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [paginationMap, setPaginationMap] = useState<
    Map<number, ITransactionLogData[]>
  >(new Map());

  const [transactionList, setTransactionList] = useState<ITransactionLogData[]>(
    [],
  );

  const [sideFormFilter, setSideFormFilter] =
    useState<ITransactionLogFormFilter>({
      DateRange:
        searchParams.get('DateRange') ||
        defaultTransactionFilterValues.DateRange,
      StoreIDs: searchParams.get('StoreIDs')
        ? JSON.parse(searchParams.get('StoreIDs') || '')
        : defaultTransactionFilterValues.StoreIDs,
      StorefrontIDs: searchParams.get('StorefrontIDs')
        ? JSON.parse(searchParams.get('StorefrontIDs') || '')
        : defaultTransactionFilterValues.StorefrontIDs,
      FirstName: searchParams.get('FirstName')
        ? JSON.parse(searchParams.get('FirstName') || '')
        : defaultTransactionFilterValues.FirstName,
      LastName: searchParams.get('LastName')
        ? JSON.parse(searchParams.get('LastName') || '')
        : defaultTransactionFilterValues.LastName,
      StartTime: searchParams.get('StartTime')
        ? dayjs(searchParams.get('StartTime')).tz()
        : defaultTransactionFilterValues.StartTime,
      EventTypes: searchParams.get('EventTypes')
        ? JSON.parse(searchParams.get('EventTypes') || '')
        : [],
      Triggers: searchParams.get('Triggers')
        ? JSON.parse(searchParams.get('Triggers') || '')
        : [],
      EndTime: searchParams.get('EndTime')
        ? dayjs(searchParams.get('EndTime')).tz()
        : defaultTransactionFilterValues.EndTime,
      DateRangeOrOccurence: searchParams.get('DateRangeOrOccurence')
        ? JSON.parse(searchParams.get('DateRangeOrOccurence') || '')
        : 'normal',
    });

  const { transactionColumnFilter } = useAppSelector(
    state => state.coloumnFilter,
  );

  const [page, setPage] = useState<number>(
    Number(searchParams.get('PageCount') || 1),
  );

  const { timeZone } = useAppSelector(state => state.pathConfig);

  const [selected, setSelected] = useState<Map<string, ITransactionLogData>>(
    new Map(),
  );

  const [headCellList, setHeadCellList] = useState<HeadCell[]>(() => [
    ...headCells.map(value => {
      return {
        ...value,
        hide: transactionColumnFilter?.includes(value.label),
      };
    }),
  ]);

  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const [filter, setFilter] = useState<ITransactionLogTableFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'CreatedAt',
  });

  const generatePayload = (isSetSearchParams = true) => {
    const {
      StoreIDs,
      StorefrontIDs,
      EndTime,
      StartTime,
      DateRange,
      EventTypes,
      Triggers,
      DateRangeOrOccurence,
    } = sideFormFilter;
    const { OrderBy, Descending } = filter;
    const payload: ISearchTransactionLogBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };
    if (EventTypes?.length) {
      payload.EventTypes = EventTypes.map(val => val.value);
    }
    if (Triggers?.length) {
      payload.Triggers = Triggers.map(val => val.value);
    }
    if (storeIds?.length) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (DateRangeOrOccurence === 'occurence') {
      if (StartTime) {
        payload.OccurenceStartTime = StartTime.tz().startOf('day').format();
      }
      if (EndTime) {
        payload.OccurenceEndTime = EndTime.tz()
          .add(1, 'day')
          .startOf('day')
          .format();
      }
    } else {
      if (StartTime) {
        payload.StartTime = StartTime.tz().startOf('day').format();
      }
      if (EndTime) {
        payload.EndTime = EndTime.tz().add(1, 'day').startOf('day').format();
      }
    }
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    if (isSetSearchParams) {
      setSearchParams(
        {
          StoreIDs: StoreIDs ? JSON.stringify(StoreIDs) : '',
          StorefrontIDs: StorefrontIDs ? JSON.stringify(StorefrontIDs) : '',
          StartTime: StartTime ? StartTime.tz().format() : '',
          EndTime: EndTime ? EndTime.tz().format() : '',
          OccurenceStartTime: StartTime ? StartTime.tz().format() : '',
          OccurenceEndTime: EndTime ? EndTime.tz().format() : '',
          Triggers: Triggers ? JSON.stringify(Triggers) : '',
          EventTypes: EventTypes ? JSON.stringify(EventTypes) : '',
          DateRange: DateRange || '',
          PageCount: String(page),
          Descending: String(Descending),
          OrderBy: OrderBy,
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };

  const filterSubmission = (formData: ITransactionLogFormFilter) => {
    if (!loading) {
      setSideFormFilter(formData);
      setPage(1);
    }
  };

  const downloadCsvFile = async () => {
    setLoading(pre => pre + 1);
    const payload = generatePayload(false);
    payload.Page = 0;
    payload.Limit = 25;
    const response = await globalTransactionService.transactionLog(payload);
    if (response?.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'transaction.csv');
      document.body.appendChild(link);
      link.click();
    }
    setLoading(pre => pre - 1);
  };

  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const searchTransactionList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(pre => pre + 1);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload = generatePayload();
    const response = await globalTransactionService.transactionLog({
      ...payload,
      Page: page,
      Limit: limit * totalValueForPagination,
    });
    if (response?.status === 200) {
      if (response?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = response?.data?.Result.slice(0, limit);
          setTransactionList(data);
        }
        setPaginationCommon(
          response?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setPaginationMap,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setTransactionList([]);
        setPaginationMap(new Map());
      }
    } else {
      setTransactionList([]);
      setPaginationMap(new Map());
    }
    setLoading(pre => pre - 1);
  };

  useEffect(() => {
    searchTransactionList();
  }, [sideFormFilter, filter, search, limit, storeIds, timeZone]);

  useEffect(() => {
    page !== 1 && searchTransactionList({ reset: false, isPrevious: false });
  }, [page]);

  return (
    <div className="transaction_container transaction_container_log">
      <div className="transaction_top_container">
        <div className="transaction_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer
          className="transactions_table_container Common_Table"
          ref={ref}>
          <Table
            className="transactions_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                {headCellList?.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {transactionList.length > 0 ? (
                transactionList.map(row => {
                  return (
                    <>
                      <TableRow
                        hover
                        className="table_row"
                        tabIndex={-1}
                        key={row.ID}>
                        {headCellList.map(headCell => {
                          if (headCell.hide === true) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationMap}
          loading={!!loading}
          setPaginationData={setPaginationMap}
          searchApi={searchTransactionList}
          setData={setTransactionList}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
            setSelected(new Map());
          }}
        />
        <Loader loading={!!loading} />
      </div>
      <SideFilter
        headCellList={headCellList}
        setHeadCellList={setHeadCellList}
        selected={selected}
        sideFormFilter={sideFormFilter}
        filterSubmission={filterSubmission}
        setLoading={setLoading}
        loading={loading}
        resetSelected={() => setSelected(new Map())}
        setTransactionList={setTransactionList}
        searchTransactionList={searchTransactionList}
        exportList={downloadCsvFile}
      />
    </div>
  );
};

export default TransactionLog;
