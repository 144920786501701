import { EventEmitter } from 'events';
import { RawEvent } from '../interface/eventInterface';

declare global {
  interface Window {
    phoenix: {
      analytics: EventPublisher;
    };
  }
}

const allEventsQueue = 'AllEvents';
export class EventPublisher {
  private static instance: EventPublisher;
  private eventEmitter: EventEmitter;

  private constructor() {
    this.eventEmitter = new EventEmitter();

    this.publish = this.publish.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  static getInstance(): EventPublisher {
    if (!EventPublisher.instance) {
      EventPublisher.instance = new EventPublisher();
    }
    return EventPublisher.instance;
  }

  publish(eventName: string, event: RawEvent) {
    console.log('Event published:', eventName, event);
    this.eventEmitter.emit(eventName, event);
    this.eventEmitter.emit(allEventsQueue, event);
  }

  subscribe(eventName: string, callback: (_event: RawEvent) => void) {
    this.eventEmitter.on(eventName, callback);
  }

  subscribeAll(callback: (_event: RawEvent) => void) {
    this.eventEmitter.on(allEventsQueue, callback);
  }

  unsubscribeAll(callback: (_event: RawEvent) => void) {
    this.eventEmitter.off(allEventsQueue, callback);
  }

  unsubscribe(eventName: string, callback: (_event: RawEvent) => void) {
    this.eventEmitter.off(eventName, callback);
  }
}

if (typeof window !== 'undefined') {
  if (!window.phoenix) {
    window.phoenix = { analytics: EventPublisher.getInstance() };
  } else {
    window.phoenix.analytics = EventPublisher.getInstance();
  }
}
