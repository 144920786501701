import { FC } from 'react';
import { MultipleInputTag } from 'components/common/multiInput';
import { PopUpModal } from 'shared-components';

interface IProps {
  open: boolean;
  handleClose: () => void;
  updateFunction: (_value?: string | null) => void;
  textValue: string[];
  setTextValue: React.Dispatch<React.SetStateAction<string[]>>;
  tagAutoCompleteID?: string;
}

const TagAction: FC<IProps> = ({
  open,
  handleClose,
  updateFunction,
  textValue,
  setTextValue,
  tagAutoCompleteID,
}) => {
  return (
    <PopUpModal
      open={open}
      handleClose={() => {
        handleClose();
      }}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Submit',
          buttonFunction: () => {
            const customElement = document.getElementById(
              tagAutoCompleteID ?? 'customInputInTag',
            ) as HTMLInputElement | null;
            updateFunction(customElement?.value ? customElement?.value : null);
            handleClose();
          },
        },
      ]}
      className={'flex-container'}
      heading={'Tag'}>
      <div className="popup-section">
        <MultipleInputTag
          tagsValue={textValue}
          label="Tag type"
          placeholder="Tag"
          onChange={setTextValue}
          tagAutoCompleteID={tagAutoCompleteID}
        />
      </div>
    </PopUpModal>
  );
};

export default TagAction;
