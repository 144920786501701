import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Loader } from 'components/common/loader';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Controller, get, useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import './style.scss';
import AddPaymentRounting from './AddPaymentRouting';
import { Checkbox, PopUpModal } from 'shared-components';
import { Button } from 'shared-components';
import TextField from 'components/common/textField';
import {
  IMerchantAccountBody,
  IMerchantResponse,
} from 'interface/paymentGatewayInterface';
import globalPaymentGateWayService from 'services/paymentGatewayService';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import globalProcessorAssigmentService from 'services/processorAssignmentService';
import {
  IProcessorAssignment,
  IProcessorAssignmentData,
  IProcessorBody,
} from 'interface/processorAssignment';

export interface IFormData {
  StoreID: string;
  ExternalID: string;
  ID: string;
  ProcessorAccountID: string;
  ProcessorAccountName: string;
  PinSubscribers: boolean;
  MonthlyLimitCents: number;
  MRRWeight: number;
  StraightSaleWeight: number;
}

export interface PaymentRoutingResponse {
  MerchantAccount: string;
  DirectSalePercentage: string;
  SubscriptionPercentage: string;
  RevenueLimit: string;
  TransactionLimit: string;
}

const merchantAccountlasel = (merchantAccount: IMerchantResponse[]) => {
  return merchantAccount.map(item => {
    return {
      label: item?.Name,
      value: item?.ID,
    };
  });
};

const samplePaymentRoutingResponses: IFormData[] = [];

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: IFormData, _index: number, _id: string) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
}

interface PreUpSellProps {
  storeId: string;
}

const PaymentRouting = ({ storeId }: PreUpSellProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openErrorMessage, setOpenErrorMessage] = useState<boolean>(false);
  const [merchantDeatails, setMerchantDeatils] = useState<IMerchantResponse[]>(
    [],
  );
  const defaultVaues: IFormData = {
    StoreID: storeId,
    ProcessorAccountID: '',
    ExternalID: '',
    ID: uuidv4(),
    ProcessorAccountName: '',
    MRRWeight: 0,
    StraightSaleWeight: 0,
    MonthlyLimitCents: 0,
    PinSubscribers: false,
  };
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      paymentRouting: samplePaymentRoutingResponses,
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'paymentRouting',
  });
  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      label: 'Merchant Account',
      key: 'merchantAccount',
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            name={`paymentRouting.${index}.ProcessorAccountID`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, value, onChange },
              fieldState: { error },
            }) => {
              return (
                <AutoCompleteSelect
                  disablePortal={false}
                  labelKey="label"
                  options={merchantAccountlasel(merchantDeatails)}
                  onChange={val => onChange(val?.value || null)}
                  ref={ref}
                  placeholder="Select Merchant Account"
                  value={
                    merchantAccountlasel(merchantDeatails)?.find(
                      type => type.value === value,
                    ) || null
                  }
                  className="w-full dropdown-field"
                  error={error?.message}
                />
              );
            }}
          />
        );
      },
      hide: false,
      showSortIcon: true,
    },
    {
      id: 'sale',
      label: 'Direct Sale %',
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            name={`paymentRouting.${index}.StraightSaleWeight`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  {...rest}
                  value={value}
                  errorString={error?.message}
                  className="filter_form_input"
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^0-9]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                  fixedSize="md"
                  placeholder="Enter direct sale %"
                  ref={ref}
                />
              );
            }}
          />
        );
      },
      key: 'directSale',
    },
    {
      id: 'subscription',
      label: 'Subscription %',
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            name={`paymentRouting.${index}.MRRWeight`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  {...rest}
                  value={value}
                  errorString={error?.message}
                  onChange={e => {
                    onChange(
                      e.target.value.replace(/[^0-9]/g, ''), // Restrict input to alphabetic characters and spaces
                    );
                  }}
                  className="filter_form_input"
                  fixedSize="md"
                  placeholder="Enter subscription %"
                  ref={ref}
                />
              );
            }}
          />
        );
      },
      key: 'subscription',
    },
    {
      id: 'monthlylimitcents',
      label: 'Monthly Limit',
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            name={`paymentRouting.${index}.MonthlyLimitCents`}
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  {...rest}
                  isPrefixText="$"
                  value={value ? (value / 100).toString() : ''}
                  errorString={error?.message}
                  onChange={e => {
                    const dollarValue = e.target.value.replace(/[^0-9.]/g, '');
                    const centsValue = Math.round(
                      parseFloat(dollarValue) * 100,
                    );
                    onChange(centsValue);
                  }}
                  className="filter_form_input"
                  fixedSize="md"
                  placeholder="Enter monthly limit $"
                  ref={ref}
                />
              );
            }}
          />
        );
      },
      key: 'monthlylimitcents',
    },
    {
      id: 'pinSubscribers',
      label: 'Pin Subscribers',
      hide: false,
      cellRender: (row, index, id) => {
        return (
          <Controller
            key={id}
            name={`paymentRouting.${index}.PinSubscribers`}
            control={control}
            render={({ field: { value, onChange, ...rest } }) => {
              return (
                <Checkbox
                  {...rest}
                  checked={value}
                  onChange={val => {
                    onChange(val.target.checked);
                  }}
                />
              );
            }}
          />
        );
      },
      key: 'pinSubscribers',
    },
    {
      id: 'action',
      label: '',
      hide: false,
      key: 'action',
      cellRender: (row, index) => {
        return (
          <div className="flex justify-end gap-3">
            <DeleteIcon
              onClick={() => {
                remove(index);
              }}
            />
          </div>
        );
      },
    },
  ];
  const ref = useRef<HTMLInputElement>(null);

  const addPercentage = (data: IFormData[]) => {
    let directSalePercentage: number = 0;
    let subscriptionPercentage: number = 0;
    const payload: IProcessorAssignmentData[] = [];
    data.forEach(data => {
      directSalePercentage += Number(data.StraightSaleWeight);
      subscriptionPercentage += Number(data.MRRWeight);
      payload.push({
        StoreID: data.StoreID,
        ProcessorAccountName: data.ProcessorAccountName,
        ProcessorAccountID: data.ProcessorAccountID,
        StraightSaleWeight: Number(data.StraightSaleWeight),
        MRRWeight: Number(data.MRRWeight),
        MonthlyLimitCents: Number(data.MonthlyLimitCents),
        PinSubscribers: data.PinSubscribers,
      });
    });
    if (directSalePercentage !== 100 || subscriptionPercentage !== 100) {
      setOpenErrorMessage(true);
      return;
    }
    createProcessorAssignments(payload);
  };

  const searchStoreFrontEvents = async () => {
    const payload: IMerchantAccountBody = {
      Descending: false,
      OrderBy: 'Name',
      Limit: 25,
      Page: 0,
      StoreIDs: [storeId],
    };
    setLoading(true);
    const res = await globalPaymentGateWayService.getProcessorAccounts(payload);
    setLoading(false);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        setMerchantDeatils(res?.data?.Result);
      } else {
        setMerchantDeatils([]);
      }
    } else {
      setMerchantDeatils([]);
    }
  };

  const createProcessorAssignments = async (
    data: IProcessorAssignmentData[],
  ) => {
    const payload: IProcessorAssignment = {
      Assignments: data,
      StoreID: storeId,
    };
    setLoading(true);
    const res =
      await globalProcessorAssigmentService.createProcessorAccounts(payload);
    setLoading(false);
    if (res?.status === 200) {
      getProcessorAssignments();
    }
  };

  const getProcessorAssignments = async () => {
    const payload: IProcessorBody = {
      StoreID: storeId,
      Descending: false,
      Limit: 25,
      OrderBy: 'ProcessorAccountName',
      Page: 0,
    };
    setLoading(true);
    const res =
      await globalProcessorAssigmentService.getProcessorAccounts(payload);
    setLoading(false);
    if (res?.status === 200 && res?.data?.Result?.length > 0) {
      reset({ paymentRouting: res.data.Result as IFormData[] });
    }
  };

  const handleFormSubmit = ({
    paymentRouting,
  }: {
    paymentRouting: IFormData[];
  }) => {
    addPercentage(paymentRouting);
  };

  useEffect(() => {
    searchStoreFrontEvents();
    getProcessorAssignments();
  }, [storeId]);

  return (
    <div className="payment-routing">
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full" ref={ref}>
            <Table className="table" aria-labelledby="tableTitle" stickyHeader>
              <TableHead className="table_header">
                <TableRow>
                  {headCells?.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className="table_header_cell"
                        key={headCell.key}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table_body">
                {fields.length > 0 ? (
                  fields.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        className="table_row"
                        role="checkbox"
                        tabIndex={-1}
                        key={index}>
                        {headCells.map(headCell => {
                          if (headCell.hide === true) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table-cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row, index, row.id)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} className="!h-40">
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={15}>
                    <button
                      className="sticky-button flex items-center"
                      type="button"
                      onClick={() => append(defaultVaues)}>
                      <AddIcon /> Add Payment Routing
                    </button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <Button
            className="transaction_for_submit"
            type="submit"
            label="Apply"
          />
          <Loader loading={loading} />
          {isUpdate && (
            <AddPaymentRounting
              handleClose={() => setIsUpdate(false)}
              onSuccess={() => setIsUpdate(false)}
            />
          )}
        </div>
      </form>
      <PopUpModal
        open={openErrorMessage}
        handleClose={() => {
          setOpenErrorMessage(false);
        }}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Close',
            disabled: loading,
            loading,
            buttonFunction: () => {
              setOpenErrorMessage(false);
            },
          },
        ]}
        heading={'Error-Could Not Update'}>
        <div className="popup-section-paymentRouting">
          <p className="popup-question">
            Please correct the errors below to process update.
          </p>
          <div className="popup-display-common">
            <p className="popup-display">. Percentage(%) doesn’t add up</p>
          </div>
        </div>
      </PopUpModal>
    </div>
  );
};

export default PaymentRouting;
