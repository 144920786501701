import { ReactNode, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { get } from 'lodash';
import 'components/subscriptionEvents/style.scss';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import StatusBadge from 'components/common/statusBadge';
import globalSubscriptionService from 'services/subscriptionService';
import { setCurrentFilter } from 'redux-setup/slices/pageConfigSlice';
import {
  ISubscriptionEvents,
  ISubscriptionEventsFilter,
  ISubscriptionEventsPayload,
} from 'interface/subscriptionInterface';
import {
  formattedDate,
  getTotalPages,
  iterateHeadCellKeys,
  setPaginationCommon,
} from 'utils/helper';
import SideFilter from 'components/subscriptionEvents/filter';
import { Loader } from 'components/common/loader';
import { useSearchParams } from 'react-router-dom';
import Link from 'components/common/link';
import SearchFilter from 'components/common/searchFilter';
import { SearchProps } from 'components/transactions';
import { getCustomerDetailsPath } from 'router/constant';
import dayjs from 'dayjs';
import PaginationCommon from 'components/common/PaginationCommon';

export interface HeadCell {
  id: string;
  label: string;
  key: string;
  cellRender?: (_row: ISubscriptionEvents) => ReactNode;
  hide?: boolean;
  valueGetter?: (_row: ISubscriptionEvents) => string | number;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
  inputType?: string;
}

export const defaultSubscriptionEventsFiltersValues: ISubscriptionEventsFilter =
  {
    CustomerEmails: [],
    PhoneNumbers: [],
    LastNames: [],
    FirstNames: [],
    EventTypes: [],
    Status: [],
    Reason: [],
    MinCycle: '',
    MaxCycle: '',
    selectedStartDate: null,
    selectedEndDate: null,
    selectedDate: 'acquisitionDate',
  };

export default function SubscriptionEvents() {
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(25);
  const [search, setSearchValue] = useState<SearchProps>({});
  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [paginationMap, setPaginationMap] = useState<
    Map<number, ISubscriptionEvents[]>
  >(new Map());

  const headCellList: readonly HeadCell[] = [
    {
      id: 'CreatedAt',
      label: 'Created At',
      key: 'CreatedAt',
      hide: false,
      cellRender: row =>
        row?.CreatedAt ? (
          <Tooltip placement="top" title={formattedDate(row.CreatedAt)} arrow>
            <span>{formattedDate(row.CreatedAt, true)}</span>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      id: 'Store.Name',
      label: 'Store Name',
      key: 'StoreName',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'Channel.Name',
      label: 'Channel',
      key: 'ChannelName',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },

    {
      id: 'Customer.Name',
      label: 'Name',
      key: 'CustomerName',
      hide: false,
      cellRender: row => (
        <Link
          to={getCustomerDetailsPath(
            row?.Customer?.ID,
            row?.Store?.ID,
          )}>{`${row?.Customer?.FirstName ?? ''} ${row?.Customer?.LastName ?? ''}`}</Link>
      ),
      showSortIcon: false,
    },
    {
      id: 'Customer.Email',
      label: 'Email',
      key: 'CustomerEmail',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'Customer.PhoneNumber',
      label: 'PhoneNumber',
      key: 'CustomerPhoneNumber',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'AcquisitionDate',
      label: 'Acquisition Date',
      key: 'AcquisitionDate',
      hide: false,
      cellRender: row => (
        <span>{formattedDate(row?.AcquisitionDate, true)}</span>
      ),
      showSortIcon: true,
    },
    {
      id: 'EventType',
      label: 'Event Type',
      cellRender: row => <StatusBadge status={row?.EventType} />,
      key: 'EventType',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'Status',
      label: 'Current Status',
      cellRender: row => <StatusBadge status={row?.Status} />,
      key: 'Status',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'Reason',
      label: 'Reason',
      key: 'Reason',
      hide: false,
      showInSearch: false,
      showSortIcon: true,
    },
    {
      id: 'CycleNumber',
      label: 'Cycle Number',
      key: 'CycleNumber',
      hide: false,
      inputType: 'number',
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'CurrentCycle',
      label: 'Current Cycle',
      key: 'CurrentCycle',
      inputType: 'number',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
    {
      id: 'SalvageAttempts',
      label: 'Salvage Attempts',
      inputType: 'number',
      key: 'SalvageAttempts',
      hide: false,
      showInSearch: true,
      showSortIcon: true,
    },
  ];
  const [loading, setLoading] = useState(0);
  const [eventsList, setEventsList] = useState<ISubscriptionEvents[]>([]);
  const rowsPerPage = 25;

  const [searchParams, setSearchParams] = useSearchParams();
  const [sideFormFilter, setSideFormFilter] =
    useState<ISubscriptionEventsFilter>({
      CustomerEmails: searchParams.get('CustomerEmails')
        ? JSON.parse(searchParams.get('CustomerEmails') || '')
        : defaultSubscriptionEventsFiltersValues.CustomerEmails,
      PhoneNumbers: searchParams.get('PhoneNumbers')
        ? JSON.parse(searchParams.get('PhoneNumbers') || '')
        : defaultSubscriptionEventsFiltersValues.PhoneNumbers,
      LastNames: searchParams.get('LastNames')
        ? JSON.parse(searchParams.get('LastNames') || '')
        : defaultSubscriptionEventsFiltersValues.LastNames,
      FirstNames: searchParams.get('FirstNames')
        ? JSON.parse(searchParams.get('FirstNames') || '')
        : defaultSubscriptionEventsFiltersValues.FirstNames,
      EventTypes: searchParams.get('EventTypes')
        ? JSON.parse(searchParams.get('EventTypes') || '')
        : defaultSubscriptionEventsFiltersValues.EventTypes,
      Status: searchParams.get('Status')
        ? JSON.parse(searchParams.get('Status') || '')
        : defaultSubscriptionEventsFiltersValues.Status,
      Reason: searchParams.get('Reason')
        ? JSON.parse(searchParams.get('Reason') || '')
        : defaultSubscriptionEventsFiltersValues.Reason,
      MinCycle: searchParams.get('MinCycle')
        ? JSON.parse(searchParams.get('MinCycle') || '')
        : defaultSubscriptionEventsFiltersValues.MinCycle,
      MaxCycle: searchParams.get('MaxCycle')
        ? JSON.parse(searchParams.get('MaxCycle') || '')
        : defaultSubscriptionEventsFiltersValues.MaxCycle,
      selectedStartDate: searchParams.get('selectedStartDate')
        ? dayjs(searchParams.get('selectedStartDate')).tz()
        : defaultSubscriptionEventsFiltersValues.selectedStartDate,
      selectedEndDate: searchParams.get('selectedEndDate')
        ? dayjs(searchParams.get('selectedEndDate')).tz()
        : defaultSubscriptionEventsFiltersValues.selectedEndDate,
      selectedDate: searchParams.get('selectedDate')
        ? JSON.parse(searchParams.get('selectedDate') || '')
        : defaultSubscriptionEventsFiltersValues.selectedDate,
    });
  const filteredField = iterateHeadCellKeys([...headCellList]);
  const [page, setPage] = useState<number>(
    Number(searchParams.get('page_count') || 1),
  );
  const [filter, setFilter] = useState<{
    Limit: number;
    Descending: boolean;
    OrderBy: string;
  }>({
    Descending: searchParams.get('descending') !== 'false',
    OrderBy: searchParams.get('order_by') || 'CreatedAt',
    Limit: rowsPerPage,
  });

  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const generatePayload = (isSetSearchParams = true) => {
    const {
      CustomerEmails,
      PhoneNumbers,
      LastNames,
      FirstNames,
      EventTypes,
      Status,
      Reason,
      MinCycle,
      MaxCycle,
      selectedDate,
      selectedStartDate,
      selectedEndDate,
    } = sideFormFilter;
    const { Descending, OrderBy } = filter;
    const payload: ISubscriptionEventsPayload = {
      ...filter,
      Page: page - 1,
      SearchFields: {},
      Limit: limit,
    };
    if (isSetSearchParams) {
      setSearchParams(
        {
          CustomerEmails: CustomerEmails ? JSON.stringify(CustomerEmails) : '',
          selectedDate: selectedDate ? JSON.stringify(selectedDate) : '',
          PhoneNumbers: PhoneNumbers ? JSON.stringify(PhoneNumbers) : '',
          FirstNames: FirstNames ? JSON.stringify(FirstNames) : '',
          LastNames: LastNames ? JSON.stringify(LastNames) : '',
          EventTypes: EventTypes ? JSON.stringify(EventTypes) : '',
          Status: Status ? JSON.stringify(Status) : '',
          Reason: Reason ? JSON.stringify(Reason) : '',
          MinCycle: MinCycle ? JSON.stringify(MinCycle) : '',
          MaxCycle: MaxCycle ? JSON.stringify(MaxCycle) : '',
          selectedStartDate: selectedStartDate
            ? selectedStartDate.tz().format()
            : '',
          selectedEndDate: selectedEndDate ? selectedEndDate.tz().format() : '',
          page_count: String(page),
          desending: String(Descending),
          order_by: OrderBy,
        },
        { replace: true },
      );
    }
    if (!payload.SearchFields) {
      payload.SearchFields = {};
    }
    if (MaxCycle) {
      payload.MaxCycle = Number(MaxCycle);
    }
    if (MinCycle) {
      payload.MinCycle = Number(MinCycle);
    }
    if (selectedStartDate) {
      payload[
        selectedDate === 'acquisitionDate'
          ? 'AcquisitionStartTime'
          : 'StartTime'
      ] = dayjs(selectedStartDate).tz().startOf('day').format();
    }
    if (selectedEndDate) {
      payload[
        selectedDate === 'acquisitionDate' ? 'AcquisitionEndTime' : 'EndTime'
      ] = dayjs(selectedEndDate).tz().add(1, 'day').startOf('day').format();
    }
    if (storeIds?.length) {
      payload.StoreIDs = storeIds.map(store => store?.ID);
    }
    if (storefrontIds?.length) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (CustomerEmails?.length) {
      payload.CustomerEmails = CustomerEmails;
    }
    if (EventTypes?.length) {
      payload.EventTypes = EventTypes.map(event => event?.value);
    }
    if (Status?.length) {
      payload.Status = Status.map(status => status?.value);
    }
    if (Reason?.length) {
      payload.Reason = Reason.map(reason => reason?.value);
    }
    if (LastNames?.length) {
      payload.LastNames = LastNames;
    }
    if (FirstNames?.length) {
      payload.FirstNames = FirstNames;
    }
    if (CustomerEmails?.length) {
      payload.CustomerEmails = CustomerEmails;
    }
    if (PhoneNumbers?.length) {
      payload.PhoneNumbers = PhoneNumbers;
    }
    payload.SearchFields = {
      ...search,
    };
    return payload;
  };

  const searchSubscriptionEventsList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(pre => pre + 1);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload = generatePayload();
    const res = await globalSubscriptionService.getSubscriptionsEvents({
      ...payload,
      Page: page,
      Limit: limit * totalValueForPagination,
    });
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = res?.data?.Result.slice(0, limit);
          setEventsList(data);
        }
        setPaginationCommon(
          res?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setPaginationMap,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setEventsList([]);
        setPaginationMap(new Map());
      }
    } else {
      setEventsList([]);
      setPaginationMap(new Map());
    }
    setLoading(pre => pre - 1);
  };

  useEffect(() => {
    searchSubscriptionEventsList();
  }, [sideFormFilter, filter, limit, search, storeIds, timeZone]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentFilter(''));
    };
  }, []);

  useEffect(() => {
    page !== 1 &&
      searchSubscriptionEventsList({ reset: false, isPrevious: false });
  }, [page]);

  return (
    <div className="subscription_events_wrapper">
      <div className="subscription_events">
        <div className="subscription_events_search_container">
          <SearchFilter
            filteredField={filteredField}
            setSearchValue={data => {
              setPage(1);
              setSearchValue(data);
            }}
            searchValue={search}
          />
        </div>
        <TableContainer className="Common_Table">
          <Table aria-labelledby="tableTitle" stickyHeader>
            <TableHead>
              <TableRow>
                {headCellList.map(headCell => {
                  if (!headCell.hide) {
                    return (
                      <TableCell key={headCell.key}>
                        {headCell?.showSortIcon ? (
                          <TableSortLabel
                            active={filter.OrderBy === headCell.id}
                            direction={
                              filter?.OrderBy === headCell.id
                                ? filter.Descending
                                  ? 'desc'
                                  : 'asc'
                                : 'asc'
                            }
                            onClick={() => sortHandler(headCell.id)}>
                            {headCell.label}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {eventsList?.length > 0 ? (
                eventsList.map(row => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.ID}>
                    {headCellList.map(headCell => {
                      if (!headCell.hide) {
                        return (
                          <TableCell
                            key={headCell.label}
                            component="th"
                            id={headCell.key}
                            title={headCell.key === 'Reason' ? row?.Reason : ''}
                            scope="row">
                            {headCell?.cellRender
                              ? headCell.cellRender(row)
                              : headCell?.valueGetter
                                ? headCell?.valueGetter(row)
                                : get(row, headCell.id)}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          active={active}
          loading={!!loading}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationMap}
          setPaginationData={setPaginationMap}
          searchApi={searchSubscriptionEventsList}
          setData={setEventsList}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
      <SideFilter
        sideFilter={sideFormFilter}
        loading={loading}
        setPage={setPage}
        filterSubmission={data => {
          if (!loading) {
            setSideFormFilter(data);
          }
        }}
      />
    </div>
  );
}
