import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAppSelector } from 'hooks/reduxHooks';
import {
  IBillingSummaryDetails,
  IBillingSummaryFilter,
  ISearchBillingSummaryBody,
} from 'interface/billingSummary';
import { get } from 'lodash';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import globalTransactionService from 'services/transactionService';
import { Loader } from 'shared-components';
import {
  demicalFormatter,
  getTotalPages,
  setPaginationCommon,
  UsDollarFormatter,
} from 'utils/helper';
import PaginationCommon from 'components/common/PaginationCommon';
import CustomDatePicker from 'components/common/customDatePicker';
import './style.scss';

export interface HeadCell {
  key?: string;
  id: string;
  label: string;
  cellRender?: (_row: IBillingSummaryDetails) => ReactNode;
  hide?: boolean;
  showSortIcon?: boolean;
  showInSearch?: boolean;
  searchFieldOptions?: { label: string; value: string }[];
  searchFiedtType?: 'input' | 'select';
  inputType?: string;
}
export interface IBillingFilter {
  start_time: Dayjs;
  end_time: Dayjs;
  date_range: string;
  start_month: Dayjs;
  end_month: Dayjs;
}

export interface SearchProps {
  [key: string]: string;
}

const Billing: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [search] = useState<SearchProps>({});
  const [limit, setLimit] = useState<number>(25);
  const [page] = useState<number>(1);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const { timeZone } = useAppSelector(state => state.pathConfig);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [paginationData, setPaginationData] = useState<
    Map<number, IBillingSummaryDetails[]>
  >(new Map());
  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const headCells: readonly HeadCell[] = [
    {
      id: 'Authorized',
      label: 'Authorized',
      key: 'Authorized',
      showSortIcon: true,
      cellRender: row => {
        return row?.Authorized !== null ? (
          <span>
            ${demicalFormatter(UsDollarFormatter(Number(row?.Authorized)))}{' '}
          </span>
        ) : (
          '-'
        );
      },
      hide: false,
      showInSearch: false,
    },
    {
      id: 'Captured',
      label: 'Captured',
      key: 'Captured',
      showSortIcon: true,
      cellRender: row => {
        return row?.Captured !== null ? (
          <span>
            ${demicalFormatter(UsDollarFormatter(Number(row?.Captured)))}
          </span>
        ) : (
          '-'
        );
      },
      hide: false,
      showInSearch: false,
    },
    {
      id: 'Chargebacks',
      label: 'Chargebacks',
      key: 'Chargebacks',
      showSortIcon: true,
      cellRender: row => {
        return row?.Chargebacks !== null ? (
          <span>
            ${demicalFormatter(UsDollarFormatter(Number(row?.Chargebacks)))}
          </span>
        ) : (
          '-'
        );
      },
      hide: false,
      showInSearch: false,
    },
    {
      id: 'Refunded',
      label: 'Refunded',
      key: 'Refunded',
      showSortIcon: true,
      cellRender: row => {
        return row?.Refunded !== null ? (
          <span>
            ${demicalFormatter(UsDollarFormatter(Number(row?.Refunded)))}
          </span>
        ) : (
          '-'
        );
      },
      hide: false,
      showInSearch: false,
    },
    {
      id: 'Voided',
      label: 'Voided',
      key: 'Voided',
      showSortIcon: true,
      cellRender: row => {
        return row?.Voided !== null ? (
          <span>
            ${demicalFormatter(UsDollarFormatter(Number(row?.Voided)))}
          </span>
        ) : (
          '-'
        );
      },
      hide: false,
      showInSearch: false,
    },
    {
      id: 'Store.Name',
      label: 'Store',
      key: 'Store.Name',
      showSortIcon: true,
      cellRender: row => {
        if (!row?.Store.Name) {
          return '-';
        }
        return <span>{row?.Store.Name}</span>;
      },
      hide: false,
      showInSearch: true,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef<HTMLInputElement>(null);

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const [filter, setFilter] = useState<IBillingSummaryFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'Store.Name',
  });

  const generatePayload = (isSetSearchParams = true) => {
    const { OrderBy, Descending } = filter;
    const payload: ISearchBillingSummaryBody = {
      ...filter,
      Page: page - 1,
      Limit: limit,
    };

    if (storeIds?.length) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length) {
      payload.StorefrontIDs = storefrontIds;
    }
    if (fil.start_time) {
      payload.StartTime = fil.start_time.tz().startOf('day').format();
    }
    if (fil.end_time) {
      payload.EndTime = fil.end_time.tz().add(1, 'day').startOf('day').format();
    }

    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    if (isSetSearchParams) {
      setSearchParams(
        {
          StoreIDs: storeIds ? JSON.stringify(storeIds) : '',
          StorefrontIDs: storefrontIds ? JSON.stringify(storefrontIds) : '',
          StartTime: fil.start_time ? fil.start_time.tz().format() : '',
          EndTime: fil.end_time ? fil.end_time.tz().format() : '',
          PageCount: String(page),
          Descending: String(Descending),
          OrderBy: OrderBy,
          Limit: String(limit),
        },
        { replace: true },
      );
    }
    return payload;
  };

  const handleFilter = (filterProptery: keyof typeof fil, value: unknown) => {
    setFil(pre => ({ ...pre, [filterProptery]: value }));
  };
  const [fil, setFil] = useState<IBillingFilter>({
    start_time: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : dayjs().tz().startOf('day'),
    end_time: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : dayjs().tz().endOf('day'),
    date_range: searchParams.get('date_range') || 'Today',
    end_month: dayjs().tz().endOf('month'),
    start_month: dayjs().tz().startOf('month'),
  });
  const [billingSummary, setBillingSummary] = useState<
    IBillingSummaryDetails[]
  >([]);

  const searchBillingSummaryList = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    setLoading(pre => pre + 1);
    let page = 0;
    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload = generatePayload();
    const response = await globalTransactionService.billingSummary({
      ...payload,
      Page: page,
      Limit: limit * totalValueForPagination,
    });
    if (response?.status === 200) {
      if (response?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = response?.data?.Result.slice(0, limit);
          setBillingSummary(data);
        }
        setPaginationCommon(
          response?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setPaginationData,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setBillingSummary([]);
        setPaginationData(new Map());
      }
    } else {
      setBillingSummary([]);
    }
    setLoading(pre => pre - 1);
  };

  useEffect(() => {
    searchBillingSummaryList();
  }, [fil, filter, search, limit, storeIds, timeZone]);
  useEffect(() => {
    page !== 1 && searchBillingSummaryList({ reset: false, isPrevious: false });
  }, [page]);

  return (
    <div className="billing_container billing_container_log">
      <div className="billing_filter row-full">
        <div className="dual-date-container">
          <div className="common_label_text">Date Range</div>
          <CustomDatePicker
            selectedRange={{
              startDate: fil?.start_time,
              endDate: fil?.end_time,
            }}
            dateValues={range => {
              handleFilter('start_time', range?.startDate);
              handleFilter('end_time', range?.endDate);
            }}
            maxDate={dayjs().tz().toDate()}
          />
        </div>
      </div>
      <div className="billing_top_container">
        <TableContainer
          className="billings_table_container Common_Table"
          ref={ref}>
          <Table
            className="billings_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                {headCells?.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {billingSummary.length > 0 ? (
                billingSummary.map(row => {
                  return (
                    <>
                      <TableRow hover className="table_row" tabIndex={-1}>
                        {headCells.map(headCell => {
                          if (headCell.hide === true) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.key}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          searchApi={searchBillingSummaryList}
          setData={setBillingSummary}
          loading={!!loading}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
    </div>
  );
};

export default Billing;
