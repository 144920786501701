import { FC, useState } from 'react';
import Transactions from 'components/transactions';
import { IDeteleNotes, IGetNotes, INotes } from 'interface/notesInterface';
import notesService from 'services/notesService';
import subscriptionService from 'services/subscriptionService';
import Tabs from 'components/common/Tabs';
import { IStateCustomers } from 'components/customers/customerDetails';
import SubscriptionHistory from 'components/customers/customerDetails/tabs/subscriptionHistory';
import NotesTab from 'components/customers/customerDetails/tabs/notestab';
import { ICustomerDetails } from 'interface/customerInterface';
import { ISubscriptionHistory } from 'interface/subscriptionInterface';
import { resourceTypes, tabNames } from 'utils/constants';

interface IProps {
  state: IStateCustomers;
  customer: ICustomerDetails | null;
}

const index: FC<IProps> = ({ state, customer }) => {
  const [tabValue, setTabValue] = useState('1');
  const tabName = customer
    ? customer?.Subscription?.ID
      ? [
          tabNames.transactionHistory,
          tabNames.notes,
          tabNames.subscriptionHistory,
        ]
      : [tabNames.transactionHistory, tabNames.notes]
    : [];

  const handleTabChange = (newValue: string) => {
    setTabValue(newValue);
  };
  const [notes, setNotes] = useState<IGetNotes[] | null>(null);
  const [subscriptionHistoryData, setSubscriptionHistoryData] =
    useState<ISubscriptionHistory | null>(null);
  const [loading, setLoading] = useState<number>(-1);

  const getNotesDetails = async () => {
    setLoading(pre => pre + 1);
    if (state?.StoreId && state?.CustomerId) {
      const res = await notesService.getNotes(
        state?.StoreId,
        resourceTypes.customers,
        state?.CustomerId,
      );
      if (res?.data?.Notes) setNotes(res?.data?.Notes);
      else setNotes([]);
    }
    setLoading(pre => pre - 1);
  };
  const deleteNote = async (data: string) => {
    setLoading(pre => pre + 1);
    if (state?.StoreId && state?.CustomerId) {
      const noteIds = data || '';
      const payload: IDeteleNotes = {
        NoteIDs: [noteIds],
        StoreID: state?.StoreId || '',
      };
      const res = await notesService.deleteNotes(payload);
      if (res?.status === 200 || res?.status === 204) getNotesDetails();
    }
    setLoading(pre => pre - 1);
  };

  const handleAddNotes = async (message: string) => {
    if (message) {
      const payload: INotes = {
        Text: message,
        StoreID: state?.StoreId,
        ResourceID: state?.CustomerId,
        ResourceType: resourceTypes.customers,
      };
      setLoading(pre => pre + 1);
      await notesService.postNotes(payload);
      setLoading(pre => pre - 1);
      getNotesDetails();
    }
  };
  const handleEditNotes = async (message: string, data: IGetNotes) => {
    if (message) {
      const payload: INotes = {
        Text: message,
        StoreID: state?.StoreId,
        Version: data.Version + 1,
        ID: data.ID,
      };
      setLoading(pre => pre + 1);
      await notesService.updateNotes(payload);
      setLoading(pre => pre - 1);
      getNotesDetails();
    }
  };

  const getSubscriptionsHistory = async () => {
    setLoading(pre => pre + 1);
    if (state.StoreId && customer?.Subscription?.ID) {
      const res = await subscriptionService.getSubscriptionsHistory(
        state.StoreId,
        customer?.Subscription?.ID,
      );
      const subscriptionData: ISubscriptionHistory = res?.data;
      subscriptionData.SubscriptionEvents.sort((a, b) => {
        return new Date(a?.Date).getTime() - new Date(b?.Date)?.getTime();
      });

      if (res?.data) setSubscriptionHistoryData(subscriptionData);
    }
    setLoading(pre => pre - 1);
  };

  return (
    <div className="tab-content">
      <div>
        <Tabs value={tabValue} labels={tabName} onChange={handleTabChange} />
      </div>
      <div className="tab-content-wrapper">
        {tabValue === '1' && <Transactions CustomerState={state} />}
        {tabValue === '2' && (
          <NotesTab
            notes={notes}
            handleEditNotes={handleEditNotes}
            handleAddNotes={handleAddNotes}
            getNotesDetails={getNotesDetails}
            loading={loading}
            deleteNote={deleteNote}
          />
        )}
        {tabValue === '3' && (
          <SubscriptionHistory
            subscriptionHistoryData={subscriptionHistoryData}
            getSubscriptionsHistory={getSubscriptionsHistory}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default index;
