import { useCallback, useState } from 'react';
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as ArrowIcon } from 'assets/icons/Vector.svg';
import { Paper } from '@mui/material';
import 'components/common/multiSelect/style.scss';
import { debounce } from 'lodash';

interface ISingleSelectProps<T> {
  options: T[];
  value: T;
  placeholder?: string;
  loading?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onInputChange: (_v: string) => void;
  onChange: (
    _value: T | null,
    _reason: AutocompleteChangeReason,
    _open: boolean,
  ) => void;
  disabled?: boolean;
  error?: string;
  className?: string;
  label?: string;
  limitTags?: number;
  labelKey: keyof T;
  valueKey: keyof T;
  ref?: React.Ref<HTMLInputElement> | null;
}

function SingleSelectSearch<T>({
  options,
  disabled = false,
  valueKey,
  placeholder = '',
  className = '',
  error = '',
  label = '',
  labelKey,
  value,
  loading,
  onOpen,
  onClose,
  onInputChange,
  onChange,
  ref,
}: ISingleSelectProps<T>) {
  const [open, setOpen] = useState<boolean>(false);

  const handleChangeWithDebounce = useCallback(
    debounce(e => {
      onInputChange(e);
    }, 500),
    [],
  );

  return (
    <div className={`common_multi_select_container ${className}`}>
      {label && <p className="common_label_text">{label}</p>}
      <Autocomplete
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
          if (onOpen) onOpen();
        }}
        onClose={(_, reason) => {
          if (reason === 'toggleInput') {
            return;
          }
          setOpen(false);
          if (onClose) onClose();
          onInputChange('');
        }}
        value={value}
        onChange={(_, val, reason) => {
          onChange(val, reason, open);
        }}
        sx={{ border: 'none' }}
        ref={ref}
        disabled={disabled}
        filterOptions={option => option}
        popupIcon={<ArrowIcon />}
        PaperComponent={({ children }) => (
          <Paper className="dropDownBackdrop">{children}</Paper>
        )}
        isOptionEqualToValue={(option, val) =>
          option[valueKey] === val[valueKey]
        }
        options={options}
        onInputChange={(_, v, reason) => {
          if (reason !== 'reset') {
            handleChangeWithDebounce(v);
          }
        }}
        getOptionLabel={option => option?.[labelKey] as string}
        renderInput={params => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              className="text_field_input_multiselect"
            />
          );
        }}
      />

      {error && <p className="common_error_text">{error}</p>}
    </div>
  );
}

export default SingleSelectSearch;
