import { FC, useState } from 'react';
import 'components/transactions/transactionFilter/actionFilter/style.scss';
import { PopUpModal } from 'shared-components';
import { ControlledMultiSelect } from 'components/common/multiSelect';
import { ControlledDatePicker } from 'components/common/datePicker';
import {
  ICaptureTransaction,
  IDisputeTransactionBody,
  IOption,
  ITransactionsData,
  IupdateTransactionBody,
} from 'interface/transactionInterface';
import { showNotification } from 'helper/common/commonFunctions';
import globalTransactionService from 'services/transactionService';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-tick-icon.svg';
import { ReactComponent as FailureIcon } from 'assets/icons/failure-cross-icon.svg';
import dayjs, { Dayjs } from 'dayjs';
import { TransactionStatus } from 'components/transactions/constant';
import TagAction from 'components/Actions/tags';
import RefundTransaction from 'components/Actions/refundTransaction';
import VoidTransaction from 'components/Actions/voidTransaction';
import { FormProvider, useForm } from 'react-hook-form';
import { DisputeCodes } from 'utils/constants';
import { ControlledCheckbox } from 'shared-components';
import { ControlledAutoCompleteSelect } from 'components/common/selectAutoComplete';
import NewRefundTransaction from 'components/Actions/newRefundTransaction';
import { isFeatureEnabled } from 'utils/helper';
import { pageNames } from 'router/constant';

interface IProps {
  filterList: string[] | undefined;
  selected?: Map<string, ITransactionsData>;
  setLoading: React.Dispatch<React.SetStateAction<number>>;
  setTransactionList?: React.Dispatch<
    React.SetStateAction<ITransactionsData[]>
  >;
  resetSelected: () => void;
  searchTransactionList: () => void;
}

interface IDisputeForm {
  DisputeType: IOption[];
  DisputeDate: Dayjs | null;
  DisputeCode: { label: string; value: string };
  CancelSubscription: boolean;
  Action: string;
}

const multiSelectOptions: IOption[] = [
  { title: 'Chargeback', label: 'Chargeback (No Refund)' },
  { title: 'RDR', label: 'RDR (No Refund)' },
  { title: 'Ethoca', label: 'Ethoca (Refunds)' },
  { title: 'CDRN', label: 'CDRN (Refunds)' },
];

const statusOptions = [
  { label: 'Chargeback', value: 'mark_chargeback' },
  { label: 'Refund ', value: 'refund' },
];

const ActionFilter: FC<IProps> = ({
  filterList,
  selected,
  setLoading,
  resetSelected,
  searchTransactionList,
}) => {
  const today = dayjs().tz();
  const [isPopupOpen, setPopupOpen] = useState<{
    void: boolean;
    refund: boolean;
    partial_refund: boolean;
    tag: boolean;
    update_status: boolean;
    capture: boolean;
    mark_chargeback: boolean;
    record_dispute: boolean;
    new_refund: boolean;
  }>({
    void: false,
    refund: false,
    partial_refund: false,
    tag: false,
    update_status: false,
    capture: false,
    mark_chargeback: false,
    record_dispute: false,
    new_refund: false,
  });
  const [textValue, setTextValue] = useState<string[]>([]);
  const selectedValue = selected ? selected.values().next().value : null;

  const [disputeLoading, setDisputeLoading] = useState(false);
  const method = useForm<IDisputeForm>({
    defaultValues: {
      DisputeType: [],
      DisputeDate: null,
      DisputeCode: { label: '', value: '' },
      Action: '',
      CancelSubscription: true,
    },
  });

  const { handleSubmit, setValue, reset } = method;

  const [successTransactions, setSuccessTransactions] = useState<
    ITransactionsData[]
  >([]);
  const [failureTransactions, setFailureTransactions] = useState<
    ITransactionsData[]
  >([]);

  const setValuesForForm = (value: string) => {
    if (
      !selectedValue ||
      (value !== 'tag' &&
        value !== 'mark_chargeback' &&
        value !== 'record_dispute')
    )
      return;
    if (value === 'tag') {
      const tags: string[] = selectedValue?.Tags || [];
      setTextValue(tags);
      return;
    }
    const disputeTypes = selectedValue?.DisputeAlerts?.map(
      (disputeType: string) => {
        return { title: disputeType, label: disputeType };
      },
    );
    setValue('DisputeType', disputeTypes);
    setValue(
      'Action',
      statusOptions?.find(
        data =>
          data.value ===
          (selectedValue?.Status === 'chargeback'
            ? 'mark_chargeback'
            : selectedValue?.Status),
      )?.value || '',
    );
    setValue(
      'DisputeDate',
      selectedValue?.DisputeDate
        ? dayjs(selectedValue?.DisputeDate).tz()
        : null,
    );
    if (selectedValue?.DisputeCode) {
      setValue('DisputeCode', {
        label: selectedValue?.DisputeCode,
        value: selectedValue?.DisputeCode,
      });
    }
    if (!selectedValue?.SubscriptionStatus) {
      setValue('CancelSubscription', false);
    }
  };

  const handleClose = (value: string) => {
    if (value === 'record_dispute') {
      reset();
    }
    setPopupOpen(pre => ({ ...pre, [value]: false }));
  };

  const handleOpen = (value: string) => {
    value = value.toLowerCase().replace(/[\s&()]/g, match => {
      if (match === ' ') return '_';
      return '';
    });
    if (!validateSelections(value)) {
      return;
    }
    if (value === 'refund') {
      value = isFeatureEnabled(pageNames.NEW_REFUND) ? 'new_refund' : value;
    }
    setValuesForForm(value);
    setPopupOpen(pre => ({ ...pre, [value]: true }));
  };

  const validateSelections = (value: string) => {
    if (selected && selected.size === 0) {
      showNotification('error', 'Please select at least one Transaction');
      return false;
    }
    if (
      selected &&
      selected.size > 1 &&
      [
        'tag',
        'refund',
        'record_dispute',
        'new_refund',
        'mark_chargeback',
      ].includes(value)
    ) {
      showNotification(
        'error',
        'This Action is not compatible for multiple rows',
      );
      return false;
    }
    return true;
  };
  const updateTag = async (textTag?: string | null) => {
    setLoading(pre => pre + 1);
    if (selected?.size) {
      await Promise.all(
        [...selected.values()]?.map(async data => {
          const payload: IupdateTransactionBody = {
            ID: data.ID,
            StoreID: data?.Store?.ID,
            Version: data?.Version,
          };
          if (textValue.length > 0 || textTag) {
            payload.Tags = [...textValue, ...(textTag ? [textTag] : [])];
          }
          const res = await globalTransactionService.updateTransaction({
            ...payload,
          });
          // updating the transaction manualy if the API is successful
          if (res.status === 200) {
            data.Version = data?.Version + 1;
            showNotification('success', 'Transaction Updated Successfully');
            handleClose('tag');
            searchTransactionList();
            resetSelected();
          }
        }),
      );
    }
    setLoading(pre => pre - 1);
  };

  const toCapture = async () => {
    setLoading(pre => pre + 1);
    if (selected?.size) {
      await Promise.all(
        [...selected.values()]?.map(async data => {
          const payload: ICaptureTransaction = {
            Amount: data.Amount,
            CustomerID: data.Customer.ID,
            StoreID: data.Store.ID,
            TransactionID: data.ID,
          };

          const res = await globalTransactionService.captureTransaction({
            ...payload,
          });
          if (res.status === 200) {
            handleClose('capture');
            searchTransactionList();
            resetSelected();
            showNotification('success', 'Transaction Updated Successfully');
          }
        }),
      );
    }
    setLoading(pre => pre - 1);
  };

  const submitTransactionCharge = async (
    data: IDisputeForm,
    isChargeBack = false,
  ) => {
    const payloadCharge: IDisputeTransactionBody = {
      CancelSubscription: data.CancelSubscription,
      CustomerID: selectedValue?.Customer?.ID,
    };
    const disputeAlerts: string[] = [];
    data.DisputeType.forEach(val => disputeAlerts.push(val.title));
    if (data.DisputeType.length > 0) {
      payloadCharge.DisputeAlerts = disputeAlerts;
    }
    if (data.DisputeDate) {
      payloadCharge.DisputeDate = data.DisputeDate?.format();
    }
    if (data.DisputeCode) {
      payloadCharge.DisputeCode = `${data.DisputeCode.value}_${data.DisputeCode.label}`;
    }
    setLoading(pre => pre + 1);
    setDisputeLoading(true);

    if (selected?.size) {
      await Promise.all(
        [...selected.values()]?.map(async data => {
          const payload: IupdateTransactionBody = {
            ID: data.ID,
            StoreID: data?.Store?.ID,
            Version: data?.Version,
          };

          const res = await globalTransactionService.transactionChargeDispute(
            {
              ...payload,
              ...payloadCharge,
            },
            isChargeBack,
          );
          // updating the transaction manualy if the API is successful
          if (res.status === 200) {
            data.Version = data?.Version + 1;
            handleClose(isChargeBack ? 'mark_chargeback' : 'record_dispute');
            searchTransactionList();
            resetSelected();
            showNotification('success', 'Transaction Updated Successfully');
          }
        }),
      );
    }
    setLoading(pre => pre - 1);
    setDisputeLoading(false);
  };

  return (
    <div className="action_filter_container">
      {isPopupOpen.tag && selected?.size && (
        <TagAction
          open={isPopupOpen.tag}
          handleClose={() => {
            handleClose('tag');
          }}
          updateFunction={(value?: string | null) => {
            updateTag(value);
          }}
          textValue={textValue}
          setTextValue={setTextValue}
          tagAutoCompleteID="transactionTag"
        />
      )}
      {isPopupOpen.void && selected?.size && (
        <VoidTransaction
          payload={(() => {
            const currentTransaction = [...selected.values()]?.[0];
            return {
              Amount: currentTransaction?.Amount,
              CancelSubscription: false,
              CustomerID: currentTransaction?.Customer?.ID,
              StoreID: currentTransaction?.Store?.ID,
              TransactionID: currentTransaction?.ID,
            };
          })()}
          setLoading={setLoading}
          handleClose={() => {
            handleClose('void');
          }}
          onApiResponse={isApiSucceeded => {
            const currentTransaction = selected.values().next().value;
            if (isApiSucceeded) {
              searchTransactionList();
              resetSelected();
              currentTransaction.Status =
                TransactionStatus.transactionStatusVoided;
            }
            setSuccessTransactions(isApiSucceeded ? [currentTransaction] : []);
            setFailureTransactions(isApiSucceeded ? [] : [currentTransaction]);
            handleOpen('update_status');
          }}
        />
      )}
      {isPopupOpen.refund && selected?.size && (
        <RefundTransaction
          directAmount={true}
          setLoading={setLoading}
          payload={(() => {
            const data = [...selected.values()]?.[0];
            return {
              Amount: data?.RefundedAmount
                ? String(
                    parseFloat(data?.Amount) - parseFloat(data?.RefundedAmount),
                  )
                : data?.Amount,
              CancelSubscription: false,
              CustomerID: data?.Customer?.ID,
              StoreID: data?.Store?.ID,
              TransactionID: data?.ID,
            };
          })()}
          handleClose={() => {
            handleClose('refund');
          }}
          onApiResponse={isApiSucceeded => {
            const currentTransaction = selected.values().next().value;
            if (isApiSucceeded) {
              searchTransactionList();
              resetSelected();
              currentTransaction.Status =
                TransactionStatus.transactionStatusVoided;
            }
            setSuccessTransactions(isApiSucceeded ? [currentTransaction] : []);
            setFailureTransactions(isApiSucceeded ? [] : [currentTransaction]);
            handleOpen('update_status');
          }}
        />
      )}
      {isPopupOpen.new_refund && selected?.size && (
        <NewRefundTransaction
          setLoading={setLoading}
          payload={(() => {
            const data = [...selected.values()]?.[0];
            return {
              Amount: data?.RefundedAmount
                ? String(
                    parseFloat(data?.Amount) - parseFloat(data?.RefundedAmount),
                  )
                : data?.Amount,
              CancelSubscription: false,
              CustomerID: data?.Customer?.ID,
              StoreID: data?.Store?.ID,
              TransactionID: data?.ID,
              OrderID: data?.OrderID,
              ChannelID: data?.ChannelID,
              ExternalOrderID: data?.ExternalOrderID,
              TransactionKind: data?.TransactionKind,
            };
          })()}
          handleClose={() => {
            handleClose('new_refund');
          }}
          onApiResponse={(isApiSucceeded, refundAmount) => {
            const currentTransaction = selected.values().next().value;
            if (isApiSucceeded) {
              searchTransactionList();
              resetSelected();
              currentTransaction.Status =
                TransactionStatus.transactionStatusVoided;
              currentTransaction.Amount = refundAmount;
            }
            setSuccessTransactions(isApiSucceeded ? [currentTransaction] : []);
            setFailureTransactions(isApiSucceeded ? [] : [currentTransaction]);
            handleOpen('update_status');
          }}
        />
      )}
      {isPopupOpen.capture && selected?.size && (
        <PopUpModal
          open={true}
          handleClose={() => handleClose('capture')}
          buttons={[
            {
              buttonType: 'negative',
              buttonLabel: 'Cancel',
              buttonFunction: () => {
                handleClose('capture');
              },
            },
            {
              buttonType: 'positive',
              buttonLabel: 'Confirm',
              buttonFunction: () => {
                toCapture();
              },
            },
          ]}
          heading={'Confirm'}>
          <div className="popup-section">
            <p className="popup-question">Are you sure you want to capture?</p>
          </div>
        </PopUpModal>
      )}

      {(isPopupOpen.mark_chargeback || isPopupOpen.record_dispute) && (
        <PopUpModal
          open={isPopupOpen.mark_chargeback || isPopupOpen.record_dispute}
          handleClose={() => {
            handleClose(
              isPopupOpen.mark_chargeback
                ? 'mark_chargeback'
                : 'record_dispute',
            );
            reset();
          }}
          buttons={[
            {
              buttonType: 'positive',
              buttonLabel: 'Submit',
              form: 'UpdateChargeBack',
              type: 'submit',
              disabled: disputeLoading,
              loading: disputeLoading,
            },
          ]}
          className={'flex-container'}
          heading={
            isPopupOpen.mark_chargeback ? 'Mark Chargeback' : 'Record Dispute'
          }>
          <div className="popup-section">
            <form
              id="UpdateChargeBack"
              onSubmit={handleSubmit(val =>
                submitTransactionCharge(val, isPopupOpen.mark_chargeback),
              )}
              className="order-detail_refund">
              <FormProvider {...method}>
                <div className="multiselect">
                  <ControlledMultiSelect<IOption>
                    name="DisputeType"
                    options={multiSelectOptions}
                    labelKey="label"
                    valueKey="title"
                    label="Dispute type"
                    placeholder="Select Dispute Type"
                    limitTags={3}
                    rules={{ required: 'Dispute type is required' }}
                  />
                </div>
                <div className="multiselect">
                  <ControlledDatePicker
                    name="DisputeDate"
                    label="Dispute Date"
                    maxDate={today.tz()}
                    valueHandler={(value: string) => {
                      return value ? dayjs(value).tz().endOf('day') : value;
                    }}
                    rules={{
                      required: 'Billing Date is required',
                      validate: value => {
                        if (value) {
                          const selectedDate = dayjs(value);

                          if (!selectedDate.isValid()) {
                            return 'Invalid date';
                          }

                          if (selectedDate.isAfter(today, 'day')) {
                            return 'Date cannot be in the future';
                          }

                          return true;
                        }
                        return 'Invalid date';
                      },
                    }}
                  />
                </div>
                <div className="multiselect">
                  {selectedValue?.PaymentProfile?.CardBrand && (
                    <ControlledAutoCompleteSelect
                      labelKey={'label'}
                      name="DisputeCode"
                      label="Dispute code"
                      options={DisputeCodes(
                        selectedValue?.PaymentProfile?.CardBrand,
                      )}
                      placeholder="code"
                    />
                  )}
                </div>
                {selectedValue?.SubscriptionStatus && (
                  <div className="multiselect">
                    <ControlledCheckbox
                      name="CancelSubscription"
                      className="cancelCheckbox"
                    />
                    <span className="checkbox_label">Cancel Subscription</span>
                  </div>
                )}
              </FormProvider>
            </form>
          </div>
        </PopUpModal>
      )}
      {isPopupOpen.update_status &&
        (successTransactions?.length > 0 ||
          failureTransactions?.length > 0) && (
          <PopUpModal
            open={true}
            handleClose={() => {
              handleClose('update_status');
            }}
            buttons={[]}
            heading={'Update Complete'}>
            <div className="popup-section">
              <>
                {successTransactions?.length > 0 && (
                  <div className="update">
                    <span className="updateText">Success:</span>
                    <div className="contentContainer">
                      <div className="content">
                        <div className="contentText">
                          {successTransactions?.map((data, index) => (
                            <div key={index} className="transactionItem">
                              {' '}
                              <SuccessIcon />{' '}
                              <p>
                                {`Transaction ID ${data?.TransactionID} with ${data?.Customer?.Email}, Amount: `}
                                <span className="amount">${data.Amount}</span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {failureTransactions?.length > 0 && (
                  <div className="update">
                    <span className="updateText">Failed:</span>
                    <div className="contentContainer">
                      <div className="content">
                        <div className="contentText">
                          {failureTransactions?.map((data, index) => (
                            <div key={index} className="transactionItem">
                              {' '}
                              <FailureIcon />{' '}
                              <p>
                                {`Transaction ID ${data?.TransactionID} with ${data?.Customer?.Email}, Amount:`}
                                <span className="amount">${data.Amount}</span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </PopUpModal>
        )}
      {filterList?.map((cols: string) => {
        return (
          <div
            className="action_filter_columns"
            key={cols}
            onClick={() => {
              handleOpen(cols);
            }}>
            {cols}
          </div>
        );
      })}
    </div>
  );
};

export default ActionFilter;
