import { ComponentConfig } from '@measured/puck';
import { ReduxSelectorProps } from '../../../services/constants';
import CustomTooltip from '../../../../components/common/tooltip';
import { marginFields, MarginProps } from '../../../puck/reusable-props/margin';
import {
  JustifyContentProps,
  JustifyContentField,
} from '../../../puck/reusable-props/justify-content';
import TextCustomInput from '../../../puck/reusable-props/TextCustomInput';
import { CustomizeProps } from '../../../interface/commonInterface';
import {
  paddingFields,
  PaddingProps,
} from '../../../puck/reusable-props/padding';
import showComponent, {
  showComponentProps,
} from '../../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../../utils/helper';

export type ReturnButtonProps = {
  CustomizeFonts: CustomizeProps;
} & MarginProps &
  PaddingProps &
  JustifyContentProps &
  showComponentProps;

export const ReturnButton = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<ReturnButtonProps> => {
  return {
    label: (
      <CustomTooltip title="ReturnButton">
        <span>Return Button</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      CustomizeFonts: {
        type: 'custom',
        render: ({ value, onChange }) => {
          return (
            <TextCustomInput
              value={value}
              onChange={onChange}
              showHover={true}
              title="Button Text"
            />
          );
        },
      },
      ...JustifyContentField,
      ...marginFields,
      ...paddingFields,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      CustomizeFonts: {
        fontSize: '12',
        fontWeight: 'font-medium',
        textColor: '#000000 ',
        hoverColor: '#000000',
        text: 'Return to Store',
      },
      justifyContent: 'justify-start',
      margin: {
        desktop: {
          top: 'mt-4',
          bottom: 'mb-4',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-1',
          bottom: 'max-md:mb-1',
          right: 'max-md:mr-1',
          left: 'max-md:ml-1',
        },
        mobile: {
          top: 'max-sm:mt-1',
          bottom: 'max-sm:mb-1',
          right: 'max-sm:mr-1',
          left: 'max-sm:ml-1',
        },
      },
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
    },
    render: ({
      margin,
      justifyContent,
      CustomizeFonts,
      padding,
      showComponent,
    }) => {
      const storeData = useAppSelector(state => state.store.storeData);
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);

      const shopUrl = storeData?.ShopURL;
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`flex  ${CustomizeFonts?.fontSize} ${CustomizeFonts?.fontWeight}  ${justifyContent} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} 
       ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <a
                style={{
                  fontSize: `${CustomizeFonts?.fontSize}px`,
                  color: CustomizeFonts?.textColor,
                }}
                href={shopUrl}
                type="button"
                className="text-xs text-page_builder_theme mt-4 sm:mt-0 sm:text-left text-center hover:text-indigo-500">
                {CustomizeFonts?.text}
              </a>
            </div>
          )}
        </>
      );
    },
  };
};
