import { ComponentConfig } from '@measured/puck';
import { ColorProps, colorField } from '../../puck/reusable-props/color';
import { fontField, FontProps } from '../../puck/reusable-props/font';
import { MarginProps, marginFields } from '../../puck/reusable-props/margin';
import { PaddingProps, paddingFields } from '../../puck/reusable-props/padding';
import { formatPrice } from '../../../page-builder/utils/formatPrice';
import SavingIcon from '../../../assets/svg/savings-logo';
import { Cart } from '../../../page-builder/interface/checkoutInterface';
import { ReduxSelectorProps } from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';

export type SavingCalculatorProps = ColorProps &
  FontProps &
  MarginProps &
  PaddingProps &
  showComponentProps;

export const SavingCalculator = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<SavingCalculatorProps> => {
  return {
    label: (
      <CustomTooltip title="SavingCalculator">
        <span>Saving Calculator</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...colorField,
      ...fontField,
      ...marginFields,
      ...paddingFields,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      margin: {
        desktop: {
          top: 'mt-4',
          bottom: 'mb-4',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-4',
          bottom: 'max-md:mb-4',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-2',
          bottom: 'max-sm:mb-2',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-1',
          bottom: 'pb-1',
          right: 'pr-0',
          left: 'pl-4',
        },
        tablet: {
          top: 'max-md:pt-1',
          bottom: 'max-md:pb-1',
          right: 'max-md:pr-0',
          left: 'max-md:pl-4',
        },
        mobile: {
          top: 'max-sm:pt-2',
          bottom: 'max-sm:pb-2',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-2',
        },
      },
      font: {
        size: 'text-md',
        weight: 'font-bold',
      },
      color: {
        backgroundColor: '#FFFFFF',
        textColor: '#111827',
        hoverColor: '#f3f4f6',
      },
    },
    render: ({ margin, font, padding, color, showComponent }) => {
      const cartData: Cart | null = useAppSelector(
        state => state.cart?.cartData,
      );
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);

      const calculateSaving = (
        subtotal: number,
        total: number,
        originalShippingCost: number,
        itemLevelDiscounts: number,
      ): number => {
        const expectedTotalBeforeDiscounts = subtotal + originalShippingCost;
        const savings =
          expectedTotalBeforeDiscounts - total + itemLevelDiscounts;
        return savings;
      };

      // Convert string to number and handle undefined
      const subtotal = parseFloat(cartData?.SubtotalAmount || '0');
      const total = parseFloat(cartData?.TotalAmount || '0');
      const originalShippingCost = parseFloat(
        cartData?.Fees?.shipping?.OriginalCost || '0',
      );

      const itemLevelDiscounts =
        cartData && cartData.LineItems
          ? cartData.LineItems.reduce((acc, item) => {
              const originalPrice = parseFloat(item.OriginalPrice) || 0; // Default to 0 if NaN or undefined
              const unitPrice = parseFloat(item.UnitPrice) || 0; // Default to 0 if NaN or undefined
              let discountAmount = 0;

              // Calculate discount amount only if original price is greater than unit price
              if (originalPrice > unitPrice) {
                discountAmount =
                  (originalPrice - unitPrice) * (item.Quantity || 0);
              }

              return acc + discountAmount;
            }, 0)
          : 0;

      const savings = calculateSaving(
        subtotal,
        total,
        originalShippingCost,
        itemLevelDiscounts,
      );

      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
      ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
      ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
      ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
      ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} flex items-center space-x-2`}>
              <SavingIcon className="h-6 w-6" />
              <div>
                <h3
                  className={`${font?.size} ${font?.weight} inline`}
                  style={{
                    backgroundColor: color?.backgroundColor,
                    color: color?.textColor,
                  }}>
                  Total Savings
                </h3>
                <p
                  style={{
                    backgroundColor: color?.backgroundColor,
                    color: color?.textColor,
                  }}
                  className={`inline ${font?.size} ${font?.weight} ml-2`}>
                  ${formatPrice(savings.toString())}
                </p>
              </div>
            </div>
          )}
        </>
      );
    },
  };
};
