import React, {
  LegacyRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as ArrowIcon } from 'assets/icons/Vector.svg';
import { Paper } from '@mui/material';
import 'components/common/multiSelect/style.scss';
import { debounce } from 'lodash';
import './style.scss';
interface IMultiSelectProps<T> {
  setPostionRef: (_: number) => void;
  lastElementRef: React.Ref<HTMLLIElement>;
  options: T[];
  value: T[];
  placeholder?: string;
  loading?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onInputChange: (_v: string) => void;
  onChange: (
    _value: T[],
    _reason: AutocompleteChangeReason,
    _open: boolean,
  ) => void;
  disabled?: boolean;
  error?: string;
  className?: string;
  label?: string;
  limitTags?: number;
  labelKey: keyof T;
  valueKey: keyof T;
  ref?: React.Ref<HTMLElement>;
  position: number;
}

function MultiSelectSearch<T>({
  options,
  disabled = false,
  valueKey,
  placeholder = '',
  className = '',
  error = '',
  setPostionRef,
  label = '',
  position,
  labelKey,
  limitTags = 3,
  value,
  loading,
  onOpen,
  onClose,
  onInputChange,
  onChange,
  ref,
  lastElementRef,
}: IMultiSelectProps<T>) {
  const listElem = useRef<Element>();
  const [open, setOpen] = useState<boolean>(false);
  const [inputSearchValue, setInputValue] = useState('');
  const handleChangeWithDebounce = useCallback(
    debounce(e => {
      onInputChange(e);
    }, 500),
    [],
  );
  useEffect(() => {
    if (
      position > 0 &&
      listElem?.current &&
      listElem?.current?.scrollTop !== position
    ) {
      listElem.current.scrollTop = position;
    }

    return () => {
      setPostionRef(0);
    };
  }, [options]);

  return (
    <div className={`common_multi_select_container ${className}`}>
      {label && <p className="common_label_text">{label}</p>}
      <Autocomplete
        ListboxProps={{
          ref: listElem as RefObject<Element>,
          className: loading ? 'loading-scroll-bar' : '',
          onScroll: ({ currentTarget }) => {
            const scrollPosition =
              currentTarget.scrollTop + currentTarget.clientHeight;
            setPostionRef(scrollPosition);
          },
        }}
        loading={loading}
        open={open}
        onOpen={() => {
          setOpen(true);
          if (onOpen) onOpen();
        }}
        onClose={(_, reason) => {
          if (reason === 'toggleInput') {
            return;
          }
          setOpen(false);
          if (onClose) onClose();
          onInputChange('');
          setInputValue('');
        }}
        value={value}
        onChange={(_, val, reason) => {
          onChange(val, reason, open);
        }}
        sx={{ border: 'none' }}
        ref={ref}
        multiple
        disableCloseOnSelect
        disabled={disabled}
        filterOptions={option => option}
        popupIcon={<ArrowIcon />}
        limitTags={limitTags}
        PaperComponent={({ children }) => {
          return <Paper className="dropDownBackdrop">{children}</Paper>;
        }}
        isOptionEqualToValue={(option, val) =>
          option[valueKey] === val[valueKey]
        }
        id="multiple-limit-tags"
        options={options}
        inputValue={inputSearchValue}
        onInputChange={(_, v, reason) => {
          if (reason !== 'reset') {
            setInputValue(v);
            handleChangeWithDebounce(v);
          }
        }}
        getOptionLabel={option => option[labelKey] as string}
        renderOption={(props, option, { index }) => {
          return (
            <li
              {...props}
              ref={
                index === options.length - 1
                  ? (lastElementRef as LegacyRef<HTMLLIElement>)
                  : null
              }>
              <div>{option[labelKey] as string}</div>
            </li>
          );
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={placeholder}
            className="text_field_input_multiselect"
          />
        )}
      />

      {error && <p className="common_error_text">{error}</p>}
    </div>
  );
}

export default MultiSelectSearch;
