import { FC } from 'react';
import { PopUpModal } from 'shared-components';
interface IProps {
  open: boolean;
  message: string;
  handleClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
}

export const ConfirmationDialog: FC<IProps> = ({
  open,
  message,
  handleClose,
  onSubmit,
  loading,
}) => {
  return (
    <PopUpModal
      open={open}
      handleClose={() => {
        handleClose();
      }}
      buttons={[
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          disabled: loading,
          loading,
          buttonFunction: () => {
            onSubmit();
          },
        },
      ]}
      heading={'Confirm'}>
      <div className="popup-section">
        <p className="popup-question">Are you sure want to {message}?</p>
      </div>
    </PopUpModal>
  );
};
