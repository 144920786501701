import { Controller, useFormContext } from 'react-hook-form';
import 'components/onboarding/formComponents/storeDetailsPage/style.scss';
import { FC } from 'react';
import TextField from 'components/common/textField';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CommonStepper from 'components/common/stepper/index';
import { stepperSteps, urlPattern } from 'utils/constants';
import { IStoreDetailsForm } from 'interface/storeInterface';
import { useNavigate } from 'react-router-dom';
import { Path } from 'router/constant';

interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const Step1: FC<IProps> = ({ setStep }) => {
  const navigate = useNavigate();

  const {
    control,
    trigger,
    formState: { isValid },
  } = useFormContext<IStoreDetailsForm>();

  return (
    <div className="onboardingStep1">
      <div className="heading-text-area">
        <p className="text-bold">{`Onboarding Made Easy`}</p>
        <p className="text">Add Your Store and Merchant Account to Get Going</p>
      </div>
      <div className="stepper-section">
        <CommonStepper steps={stepperSteps} activeStep={0} />
      </div>
      <div className="onboarding-fields">
        <div className="onboarding-field">
          <Controller
            name="store.Name"
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => {
              return (
                <TextField
                  {...rest}
                  className="onboarding-input"
                  placeholder="Enter the name"
                  errorString={error?.message}
                  label="Name of the store"
                  fixedSize="md"
                  ref={ref}
                />
              );
            }}
          />
        </div>
        <div className="onboarding-field">
          <Controller
            name="store.StoreDescription"
            control={control}
            rules={{
              required: 'This field is required',
            }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => {
              return (
                <TextField
                  {...rest}
                  className="onboarding-input"
                  placeholder="Enter the description"
                  errorString={error?.message}
                  label="Description of the store"
                  fixedSize="md"
                  ref={ref}
                />
              );
            }}
          />
        </div>
        <div className="onboarding-field">
          <Controller
            name="store.CheckoutURL"
            control={control}
            rules={{
              required: 'This field is required',
              pattern: {
                value: urlPattern,
                message: 'Invalid URL',
              },
            }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => {
              return (
                <TextField
                  {...rest}
                  className="onboarding-input"
                  placeholder="Enter the URl"
                  label="Checkout URL"
                  errorString={error?.message}
                  fixedSize="md"
                  ref={ref}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="button-field">
        <div className="button">
          <button
            type="button"
            className="wrapper"
            onClick={() => {
              navigate(Path.DASHBOARD);
            }}>
            <p>Skip All</p>
            <ArrowForward />
          </button>
        </div>
        <div className="button">
          <button
            type="button"
            className="wrapper"
            onClick={async () => {
              trigger();
              if (isValid) setStep(1);
            }}>
            <p>Next</p>
            <ArrowForward />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step1;
