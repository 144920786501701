import { ReactComponent as Phoenix } from 'assets/icons/phoenix_icon.svg';
import { Button } from 'shared-components';
import { useNavigate } from 'react-router-dom';
import { Path } from 'router/constant';

const ShopifyPopup = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-transparent rounded-lg p-8 text-center text-white font-sans mx-auto border border-phoenix-background">
      <div className="mb-5 flex items-center justify-center">
        <Phoenix className="w-24" />
      </div>
      <div className="mb-2 text-lg">
        It looks like you don’t have a store associated with your account.
        <div className="flex flex-col gap-4 mt-6">
          <Button
            className="bg-gray-500 hover:bg-gray-700 hover:text-white text-white font-bold py-2 px-4 rounded"
            variant="secondary"
            label="Create a New Store (recommended)"
            onClick={() => {
              navigate(Path.SHOPIFY_STORE_CREATE);
            }}
          />
          <Button
            className="bg-phoenix-background hover:bg-phoenix-hover text-white font-bold py-2 px-4 rounded"
            variant="phoenix"
            label="Install on an Existing Store"
            onClick={() => {
              navigate(Path.SHOPIFY_SETTINGS_PAGE);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopifyPopup;
