import { ComponentConfig } from '@measured/puck';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CommonProps,
  commonProps,
} from '../../../../page-builder/puck/reusable-props';
import {
  IDropdownOptions,
  navigationPathField,
} from '../../../../page-builder/puck/reusable-props/navigation-path-field';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../../components/common/tooltip';
import InputField from '../../../../components/common/input-field';
import showComponent, {
  showComponentProps,
} from '../../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../../utils/helper';
import { ReduxSelectorProps } from '../../../services/constants';

export type ButtonSectionProps = {
  buttonName: string;
  nextPagePath: IDropdownOptions;
} & CommonProps &
  showComponentProps;

export const ButtonComponent = ({
  useAppSelector,
  baseService,
}: ReduxSelectorProps & {
  baseService: AxiosInstance;
}): ComponentConfig<ButtonSectionProps> => {
  return {
    label: (
      <CustomTooltip title="Button">
        <span>Button</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...navigationPathField(baseService),
      buttonName: {
        type: 'custom',
        label: 'Name',
        render: ({ value, onChange, field }) => (
          <InputField value={value} field={field} onChange={onChange} />
        ),
      },
      ...commonProps,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      nextPagePath: { label: '', value: '' },
      buttonName: 'Button',
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      justifyContent: 'justify-center',
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      width: 'w-32',
      font: {
        size: 'text-2xl',
        weight: 'font-bold',
      },
      borderRadius: 'rounded-none',
      color: {
        backgroundColor: 'bg-white',
        textColor: 'text-gray-900',
        hoverColor: 'hover:bg-gray-100',
      },
      height: 'h-18',
      alignItems: 'items-center',
    },
    render: ({
      nextPagePath,
      buttonName,
      margin,
      justifyContent,
      padding,
      width,
      font,
      borderRadius,
      color,
      height,
      alignItems,
      showComponent,
    }) => {
      const navigate = useNavigate();
      const { pathname } = useLocation();
      const handleClick = (): void => {
        navigate(pathname + nextPagePath?.value);
      };
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`flex  ${alignItems} ${justifyContent} ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}`}>
              <button
                className={`flex  ${width} ${height} ${alignItems} ${justifyContent} 
           ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}  ${font?.size} ${font?.weight} ${borderRadius} `}
                style={{
                  backgroundColor: color?.backgroundColor,
                  color: color?.textColor,
                  transition: 'background-color 0.3s',
                }}
                onMouseOver={e =>
                  (e.currentTarget.style.backgroundColor = color?.hoverColor)
                }
                onMouseOut={e =>
                  (e.currentTarget.style.backgroundColor =
                    color?.backgroundColor)
                }
                onClick={handleClick}>
                {buttonName}
              </button>
            </div>
          )}
        </>
      );
    },
  };
};
