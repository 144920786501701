import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';

const ToggleSwitch: React.FC<SwitchProps> = ({ ...rest }) => {
  return (
    <Switch
      {...rest}
      sx={{
        '& .MuiSwitch-track': {
          backgroundColor: '#A3AED0',
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#F90182',
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#FFB4DB',
        },
      }}
    />
  );
};
export default ToggleSwitch;
