import { pageCountOptions } from 'components/common/pagination';
import React, { useEffect } from 'react';
import { Select } from 'shared-components';
import './style.scss';

interface searchListProps {
  currentPage: number;
  reset: boolean;
  isPrevious?: boolean;
}

interface IProps<T> {
  limit: number;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  searchApi: (_data: searchListProps) => void;
  totalValueForPagination: number;
  paginationData: Map<number, T[]>;
  isMoreData: boolean;
  setPaginationData: React.Dispatch<React.SetStateAction<Map<number, T[]>>>;
  setActive: React.Dispatch<React.SetStateAction<number>>;
  active: number;
  setIsLastPage: React.Dispatch<React.SetStateAction<boolean>>;
  isLastPage: boolean;
  onRowsPerChange: (_value: number) => void;
  loading?: boolean;
}
export default function PaginationCommon<T>({
  limit,
  setData,
  searchApi,
  setPaginationData,
  paginationData,
  isMoreData,
  setActive,
  active,
  isLastPage,
  loading,
  setIsLastPage,
  onRowsPerChange,
}: IProps<T>) {
  const handlePaginationValue = (value: number, isPrev = false) => {
    setActive(value);

    if (paginationData.get(value)) setData(paginationData.get(value) || []);
    if (
      !paginationData.has(value) ||
      (paginationData.has(value) &&
        !paginationData.has(value + 1) &&
        !((paginationData.get(value)?.length ?? 0) < limit))
    ) {
      if (isPrev) {
        setPaginationData(new Map());
        searchApi({ currentPage: value, reset: value === 1, isPrevious: true });
      } else {
        const map = new Map();
        map.set(value, paginationData.get(value) || []);
        searchApi({ currentPage: value, reset: value === 1 });
        setPaginationData(map);
      }
      setIsLastPage(false);
      return;
    }
    if (!isMoreData && !paginationData.has(value + 1)) {
      setIsLastPage(true);
      return;
    }
    setIsLastPage(false);
  };

  useEffect(() => {
    if (active !== 1) {
      setData(paginationData.get(active) || []);
    }
  }, [paginationData]);

  return (
    <>
      <>
        <div className="pagination_container  flex flex-row items-center md:justify-between justify-center flex-wrap gap-5">
          <div className="row_content_decider flex flex-row ">
            <span className="rows_page">Rows Per Page</span>
            <Select
              value={limit}
              onChange={val => {
                onRowsPerChange(val);
              }}
              options={pageCountOptions}
            />
          </div>
          {paginationData.size > 0 && !loading && (
            <>
              <div className="pagination-details">{`${(active - 1) * limit + 1} - ${
                (active - 1) * limit +
                (paginationData?.get(active)?.length ?? 0)
              } `}</div>

              <div className="flex flex-row md:gap-2 gap-1 justify-end align-end">
                <button
                  className={`pagination-button  ${active === 1 ? 'disabled' : ''} `}
                  onClick={() => {
                    if (active === 1) return;
                    handlePaginationValue(active - 1, true);
                  }}>
                  Prev
                </button>
                {!paginationData.get(1) ? (
                  <button
                    className="pagination-button"
                    onClick={() => {
                      handlePaginationValue(1);
                    }}>
                    1
                  </button>
                ) : (
                  <></>
                )}
                {!paginationData.get(1) && (
                  <span className="dotButton">...</span>
                )}
                {Array.from(paginationData.keys()).map((key, index) => (
                  <button
                    className={`pagination-button ${active === key ? 'active' : ''}`}
                    key={index}
                    onClick={() => {
                      handlePaginationValue(key);
                    }}>
                    {key}
                  </button>
                ))}

                {isMoreData && <span className="dotButton">...</span>}
                <button
                  className={`pagination-button ${isLastPage ? 'disabled' : ''}`}
                  onClick={() => {
                    if (isLastPage) return;
                    handlePaginationValue(active + 1);
                  }}>
                  Next
                </button>
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}
