import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader, Render, usePreload } from 'shared-components';
import { LoadingPage } from 'shared-components';
import { Config, Data } from '@measured/puck';
import HomeEdit from '../edit';
import { ICheckoutPage } from 'shared-components';
import { Path, setPuckData } from 'shared-components';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useAppSelector } from 'hooks/reduxHooks';
import { setIsTermsAndSubscriptionInPublishPage } from 'shared-components/src/page-builder/redux/slices/termsAndSubSlice';
import { getData } from '../editor';

interface IProps {
  data: Data;
  config: Config;
  checkoutPage: ICheckoutPage | null;
  onSuccessCallBack: () => void;
  pathname: string;
}

const HomePage = ({
  data,
  config,
  checkoutPage,
  onSuccessCallBack,
  pathname,
}: IProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const puckDataPayload = JSON.parse(JSON.stringify(data));
    if (data) {
      dispatch(setPuckData(puckDataPayload));
      dispatch(setIsTermsAndSubscriptionInPublishPage(getData(data)));
    }
  }, []);
  // render page
  const { isLoading, error } = usePreload({ useAppSelector });

  if (pathname.includes('/view') && isLoading) {
    return <LoadingPage />;
  }

  if (pathname.includes('/edit') && isLoading) {
    return <Loader loading={isLoading} />;
  }

  if (error) {
    return <p className="text-white">{error}</p>;
  }

  if (pathname.includes(Path.PAGE_BUILDER_EDIT)) {
    // if route is page-builder/edit, render the editor
    return (
      <HomeEdit
        data={data}
        config={config}
        checkoutPage={checkoutPage}
        onSuccessCallBack={onSuccessCallBack}
      />
    );
  }

  if (!data) {
    return (
      <>
        configure the components
        <button
          onClick={() => {
            navigate(`${Path.PAGE_BUILDER_EDIT}?${searchParams.toString()}`);
          }}>
          here
        </button>
      </>
    );
  }

  return (
    <>
      {pathname.includes(Path.PAGE_BUILDER_VIEW) && (
        <Render config={config} data={data} />
      )}
    </>
  );
};

export default HomePage;
