/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { InputHTMLAttributes, useEffect } from 'react';
import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-icon.svg';
import { FilterForm } from 'components/subscriptionProjection/projectionFilter/FilterForm/index';
import ActionFilter from 'components/subscriptionProjection/projectionFilter/ActionFilter/index';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import {
  IProjectioActionFilter,
  IProjectionFilter,
} from 'components/subscriptionProjection/index';
import FilterAction from 'components/common/filterAction';

interface ISideFilter extends InputHTMLAttributes<HTMLInputElement> {
  sideFormFilter: IProjectionFilter;
  sideActionFilter: IProjectioActionFilter;
}

const SideFilter: React.FC<ISideFilter> = ({
  sideFormFilter,
  sideActionFilter,
}) => {
  const dispatch = useAppDispatch();
  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const { transaction } = useAppSelector(state => state.permission);
  const filterAction = [
    {
      key: 'Filters',
      icon: FilterIcon,
      className: 'margin_filter',
      permission: 'manage',
    },
    { key: 'Actions', icon: ActionIcon, permission: 'manage' },
    { key: 'Download', icon: DownloadIcon, permission: 'manage' },
  ];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'projections', currentFilter: filter }),
    );
  };
  const renderComponent = () => {
    switch (currentFilter) {
      case 'Filters':
        return <FilterForm sideFormFilterSubscription={sideFormFilter} />;
      case 'Actions':
        return <ActionFilter sideFormFilterSubscription={sideActionFilter} />;
      default:
        break;
    }
  };
  useEffect(() => {
    return () => {
      dispatch(
        setPageConfig({
          filterSource: '',
          currentFilter: '',
        }),
      );
    };
  }, []);

  return (
    <div className="side_filter_container">
      <FilterAction
        className="transaction_side_filtericon"
        filterAction={filterAction}
        permissionOfPage={transaction}
        setSelectedFilter={setSelectedFilter}
      />

      {!!currentFilter && filterSource === 'projections' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_ison"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
