import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  ITokens,
  ILoginBody,
  ILoginVerificationBody,
} from 'interface/apiInterface';
import {
  commonError,
  loginEP,
  loginVerifyEP,
  refreshTokenEP,
} from 'services/constants';
import { store } from 'redux-setup/store';
import { setOnboarding, setToken } from 'redux-setup/slices/userSlice';
import { clearPagePermisssion } from 'redux-setup/slices/permissionSlice';
import { clearColoumnDetails } from 'redux-setup/slices/ColoumnFilterSlice';
import { clearStoreSlice } from 'redux-setup/slices/StoreIdSlice';

export const login = async (body: ILoginBody) => {
  try {
    const response = await baseService.post(loginEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError);
  }
};

export const loginVerification = async (body: ILoginVerificationBody) => {
  try {
    const response = await baseService.post(loginVerifyEP, body);
    return response;
  } catch (error) {
    return commonError(error as AxiosError);
  }
};

export const refreshAccessToken = async (RefreshToken: string) => {
  try {
    const response = await baseService.post(refreshTokenEP, { RefreshToken });
    return response;
  } catch (error) {
    logOut();
    return commonError(error as AxiosError);
  }
};

export const setTokensInStorage = (tokens: ITokens) => {
  if (tokens.RefreshToken) {
    localStorage.setItem('refresh_token', tokens.RefreshToken);
  }
  if (tokens.AccessToken) {
    localStorage.setItem('access_token', tokens.AccessToken);
  }
};

export const logOut = () => {
  store.dispatch(clearPagePermisssion());
  store.dispatch(clearColoumnDetails());
  store.dispatch(clearStoreSlice());
  store.dispatch(setToken(''));
  store.dispatch(setOnboarding(false));
  setTokensInStorage({ RefreshToken: '', AccessToken: '' });
  window.parent.postMessage({ logout: true }, '*');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('invite_user_url');
  window.parent.postMessage({ logout: true }, '*');
};
