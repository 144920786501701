import { Fields } from '@measured/puck';
import { BorderRadiusProps, borderRadiusField } from './border-radius';
import { ColorProps, colorField } from './color';
import { fontField, FontProps } from './font';
import { HeightProps, heightField } from './height';
import { JustifyContentField, JustifyContentProps } from './justify-content';
import { MarginProps, marginFields } from './margin';
import { PaddingProps, paddingFields } from './padding';
import { WidthProps, widthField } from './width';
import { AlignItemsProps, alignItemsField } from './align-items';

export type CommonProps = HeightProps &
  WidthProps &
  JustifyContentProps &
  AlignItemsProps &
  BorderRadiusProps &
  ColorProps &
  FontProps &
  MarginProps &
  PaddingProps;

export const commonProps: Fields<CommonProps> = {
  ...heightField,
  ...widthField,
  ...fontField,
  ...JustifyContentField,
  ...alignItemsField,
  ...borderRadiusField,
  ...colorField,
  ...marginFields,
  ...paddingFields,
};
