import { Config, Data, FieldLabel, Puck } from '@measured/puck';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PopUpModal, Select, ThemeColor } from 'shared-components';
import { setMandatoryComponents } from 'shared-components/src/page-builder/redux/slices/commonSlice';
import { ICheckoutPage } from 'shared-components';
import { viewPorts } from 'shared-components';
import { MoonIcon } from 'shared-components';
import { SunIcon } from 'shared-components';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Tooltip } from '@mui/material';
import { Button } from 'shared-components';
import { Path } from 'shared-components';
import './styles.scss';
import { setTheme } from 'redux-setup/slices/themeSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { CustomTooltip } from 'shared-components';
import { toolTipInfo } from 'shared-components';
import lodash from 'lodash';
import { setHasUnsavedChanges } from 'shared-components';
import {
  simpleCheckoutLayout,
  standardCheckoutLayout,
} from 'shared-components/src/page-builder/utils/constants';
import { setIsTermsAndSubscriptionInPublishPage } from 'shared-components/src/page-builder/redux/slices/termsAndSubSlice';

export function getData(data: Data) {
  let isTermsAndSub = false;
  if (data?.zones) {
    isTermsAndSub = Object.keys(data.zones).some(key => {
      if (data?.zones && data?.zones[key])
        return data?.zones[key].some(item => item?.type === 'TermsAndSub');
    });
  }
  return isTermsAndSub;
}
// const MyPlugin = {
//   overrides: {
//     componentItem: ({ name }: { name: string }) => (
//       <div
//         style={{
//           backgroundColor: '#d1d1d1',
//           padding: '12px 6px',
//           marginBottom: '6px',
//           display: 'flex',
//           justifyContent: 'space-between',
//         }}>
//         <span>{name}</span>
//         <span>:::</span>
//       </div>
//     ),
//   },
// };
const layoutOptions = [
  {
    label: 'Standard Layout',
    value: 'standardLayout',
  },
  {
    label: 'Simple Split Layout',
    value: 'splitLayout',
  },
];
type Props = {
  data: Data;
  config: Config;
  onPublish?: (_data: Data) => void;
  checkoutPage: ICheckoutPage | null;
  checkMandatoryComponents?: (_data: Data) => string[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

// Render Puck editor
const Editor = ({
  data,
  config,
  onPublish,
  checkoutPage,
  checkMandatoryComponents,
  search,
  setSearch,
}: Props) => {
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [iframecontent, setIframe] = useState<HTMLIFrameElement | null>(null);
  const { theme } = useAppSelector(state => state.theme);
  const { mandatoryComponents } = useAppSelector(state => state.common);
  const [layout, setLayout] = useState<string>('');
  const [isPoopupOpen, setIsPopupOpen] = useState<{
    open: boolean;
    value: string;
  }>({ open: false, value: '' });
  const dispatch = useAppDispatch();
  const toggleTheme = () => {
    dispatch(setTheme(!theme));
  };

  const handleClose = () => {
    setIsPopupOpen({ open: false, value: '' });
  };

  useEffect(() => {
    const iframe = document.getElementById(
      'preview-frame',
    ) as HTMLIFrameElement;

    setIframe(iframe);

    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.onload = () => {
        const color = data?.root?.props?.themeColor as ThemeColor;
        const iframeDocument = iframe.contentDocument as Document;
        const iframeBody = iframeDocument.body;

        if (iframeBody) {
          iframeBody.style.setProperty(
            '--color-page-builder-theme',
            `${color?.r} ${color?.g} ${color?.b}`,
          );
          iframeBody.style.setProperty('overflow', 'auto');
          iframeBody.style.setProperty('height', '100%');
        }
      };
    }
  }, [iframecontent, data, layout]);

  useEffect(() => {
    return () => {
      dispatch(setMandatoryComponents([]));
    };
  }, []);

  const HandleClick = () => {
    const page = document.querySelector(
      '[class*="_PuckCanvas-controls"]',
    ) as HTMLElement;
    if (page) {
      page.click();
    }
  };

  const onChange = (puckData: Data) => {
    const areEqual = lodash.isEqual(data, puckData);
    if (areEqual) {
      dispatch(setHasUnsavedChanges(false));
    } else {
      dispatch(setHasUnsavedChanges(true));
    }
  };
  // Handle input focus restoration
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    const parentDiv = document.getElementById('customComponent');

    if (parentDiv) {
      const childDivs = parentDiv.children;
      const isSearchEmpty = search === '';
      if (childDivs?.length) {
        Array.from(childDivs).forEach((child: Element) => {
          const innerDivs = child?.getElementsByTagName('div');

          if (isSearchEmpty) {
            // Remove the class if the search is empty
            if (
              child.classList.contains('_ComponentList--isExpanded_odh9d_5')
            ) {
              child.classList.remove('_ComponentList--isExpanded_odh9d_5');
            }
          } else if (innerDivs?.length > 4) {
            // Add the class if there are more than 4 inner divs
            child.classList.add('_ComponentList--isExpanded_odh9d_5');
          }
        });
      }
    }
  }, [search]);

  const getLayout = (layoutTemplate: string) => {
    switch (layoutTemplate) {
      case 'standardLayout':
        return standardCheckoutLayout;
      case 'splitLayout':
        return simpleCheckoutLayout;
      default:
        return data;
    }
  };

  return (
    <div className="puck-editor">
      <Puck
        // iframe={{ enabled: false }} // if we disable iframe, Select viewport will not work and editor will render without a iframe
        config={config}
        key={layout}
        data={getLayout(layout)}
        onPublish={(publishData: Data) => {
          if (onPublish) {
            onPublish(publishData);
          }
        }}
        onChange={(onChangeData: Data) => {
          dispatch(
            setIsTermsAndSubscriptionInPublishPage(getData(onChangeData)),
          );
          if (mandatoryComponents?.length > 0 && checkMandatoryComponents) {
            const mandatoryComponent = checkMandatoryComponents(onChangeData);

            // Check if mandatoryComponent differs from current mandatoryComponents
            const isDifferent = !lodash.isEqual(
              mandatoryComponent,
              mandatoryComponents,
            );

            //We are checking this to prevent unnecessary re-renders
            if (isDifferent && mandatoryComponent?.length) {
              dispatch(setMandatoryComponents(mandatoryComponent));
            } else if (!mandatoryComponent?.length) {
              dispatch(setMandatoryComponents([]));
            }
          }
          onChange(onChangeData);
        }}
        headerTitle={checkoutPage?.Name || ''}
        viewports={[
          {
            width: viewPorts.mobile,
            height: 'auto',
            icon: 'Smartphone',
            label: 'Small',
          },
          {
            width: viewPorts.tablet,
            height: 'auto',
            icon: 'Tablet',
            label: 'Medium',
          },
          {
            width: viewPorts.desktop,
            height: 'auto',
            icon: 'Monitor',
            label: 'Large',
          },
        ]}
        overrides={{
          header: ({ children }) => {
            // Check if children is a valid React element and has props
            if (React.isValidElement(children) && children.props.children) {
              const propsChildren: React.ReactPortal = children.props.children;
              // Check if propsChildren is a valid React element and has props
              if (
                React.isValidElement(propsChildren) &&
                propsChildren.props.children
              ) {
                const innerChildren = React.Children.toArray(
                  propsChildren.props.children,
                );
                if (
                  innerChildren.length > 1 &&
                  React.isValidElement(innerChildren[0])
                ) {
                  const firstChildContent = React.Children.toArray(
                    innerChildren[0].props.children,
                  );
                  const modifiedFirstChild = React.cloneElement(
                    innerChildren[0],
                    {},
                    [
                      ...firstChildContent,
                      checkoutPage?.PageType === 'checkout' && (
                        <Select
                          className="ml-4"
                          key={'layout'}
                          onChange={value => {
                            if (value !== layout) {
                              setIsPopupOpen({
                                open: true,
                                value: value,
                              });
                            }
                          }}
                          value={layout}
                          placeholder="Select Layout"
                          labelKey="label"
                          options={layoutOptions}
                        />
                      ),
                    ],
                  );
                  // Create a new children array with the modified first child
                  const newChildren = [
                    modifiedFirstChild,
                    innerChildren[1],
                    innerChildren[2],
                  ];
                  const modifiedPropsChildren = React.cloneElement(
                    propsChildren,
                    {},
                    newChildren,
                  );
                  return React.cloneElement(
                    children,
                    {},
                    modifiedPropsChildren,
                  );
                }
              }
            }
            return <>{children}</>;
          },
          headerActions: ({ children }) => (
            <>
              <div className="flex gap-4 items-center">
                <Button
                  onClick={HandleClick}
                  label="Global Settings"
                  variant="secondary"
                />
                <Tooltip className="actions-tooltip" title="Select Theme" arrow>
                  <div className="cursor-pointer" onClick={toggleTheme}>
                    {theme ? <MoonIcon /> : <SunIcon />}
                  </div>
                </Tooltip>
                <Tooltip className="actions-tooltip" title="View Page" arrow>
                  <div
                    className="eye-icon cursor-pointer"
                    onClick={() => {
                      const query = new URLSearchParams(location.search);
                      window.open(
                        `${Path.PAGE_BUILDER_VIEW}?${query.toString()}`,
                        '_blank',
                      );
                    }}>
                    <VisibilityOutlinedIcon />
                  </div>
                </Tooltip>
              </div>
              {children}
            </>
          ),
          components: ({ children }) => {
            useEffect(() => {
              if (document.querySelectorAll('.mandatoryComponent')?.length) {
                document
                  .querySelectorAll('.mandatoryComponent')
                  .forEach(element => {
                    const firstChild = element?.firstElementChild;
                    if (firstChild) {
                      firstChild?.classList?.add(
                        '_ComponentList--isExpanded_odh9d_5',
                      );
                      const path = firstChild?.querySelector('svg path');
                      const button = firstChild?.querySelector('button');
                      if (button) {
                        button?.setAttribute('title', 'Collapse Text');
                      }
                      if (path) {
                        path?.setAttribute('d', 'm18 15-6-6-6 6');
                      }
                    }
                  });
              }
            }, [mandatoryComponents]);
            // Convert children to an array
            const childrenArray = React.Children.toArray(children);

            const filteredChildren = childrenArray?.map((child, index) => {
              // Check if the child is a valid React element and has children
              if (React.isValidElement(child) && child?.props?.children) {
                const filtered = React.Children.toArray(
                  child.props.children,
                ).filter(
                  innerChild =>
                    React.isValidElement(innerChild) &&
                    typeof innerChild.key === 'string' &&
                    innerChild.key.toLowerCase().includes(search.toLowerCase()),
                );
                const isMandatoryComponent = filtered?.some(item => {
                  return (
                    React.isValidElement(item) &&
                    item?.props?.name &&
                    mandatoryComponents?.includes(item?.props?.name)
                  );
                });

                // Wrap the child in a div if it contains mandatory components
                if (isMandatoryComponent) {
                  return (
                    <div key={index} className="mandatoryComponent">
                      {child}
                    </div>
                  );
                }

                // Clone the element with filtered children
                return React.cloneElement(child as React.ReactElement, {
                  children: filtered,
                });
              }
              // If not a valid React element, return as it is
              return child;
            });

            return (
              <div id="customComponent">
                <input
                  className="bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in mb-4"
                  placeholder="Search components"
                  ref={inputRef}
                  value={search}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(event.target.value)
                  }
                />
                {filteredChildren}
              </div>
            );
          },
          fieldLabel: ({ children, label, className, el }) => (
            <FieldLabel
              label={
                (
                  <CustomTooltip title={label as keyof typeof toolTipInfo}>
                    <span>{label}</span>
                  </CustomTooltip>
                ) as React.ReactNode as string
              }
              el={el}
              className={className}>
              {children}
            </FieldLabel>
          ),
          componentItem: ({ children, name }) => {
            if (
              mandatoryComponents?.includes(name) &&
              React.isValidElement(children)
            ) {
              const existingClassName =
                (children.props as React.HTMLProps<HTMLElement>)?.className ||
                '';
              const newClassName = `${existingClassName} highlighted`.trim();
              children = React.cloneElement(children, {
                className: newClassName,
              } as React.HTMLProps<HTMLElement>);
            }
            return <div>{children}</div>;
          },
        }}
        // plugins={[MyPlugin]} //  Plugin to customize the editor UI
      />
      {isPoopupOpen.open && (
        <PopUpModal
          open={true}
          handleClose={() => handleClose()}
          buttons={[
            {
              buttonType: 'negative',
              buttonLabel: 'Cancel',
              buttonFunction: () => {
                handleClose();
              },
            },
            {
              buttonType: 'positive',
              buttonLabel: 'Change',
              type: 'submit',
              buttonFunction: () => {
                setLayout(isPoopupOpen.value);
                handleClose();
              },
            },
          ]}
          heading={`Warning Message`}
          className="flex-container">
          <div className="popup-section">
            <p style={{ color: '#a3aed0' }}>
              Changing the layout will reset the current layout. Are you
            </p>
            <span
              style={{
                color: '#a3aed0',
                textAlign: 'center',
                display: 'block',
              }}>
              sure you want to proceed?
            </span>
          </div>
        </PopUpModal>
      )}
    </div>
  );
};

export default Editor;
