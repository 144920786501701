import { AxiosError } from 'axios';
import { baseService } from 'services';
import {
  commonError,
  createStoreEP,
  getLegacyPaymentGatewaysEP,
  getSettingsStoresEp,
  getSubscriptionPlansEP,
  createLegacyPaymentGatewayEP,
  updateStoreEP,
  updateLegacyPaymentGatewayEP,
  createSubscriptionPlanEP,
  updateSubscriptionPlanEP,
  searchStoresEp,
  updatePagesEP,
  createPagesEP,
  getPagesEP,
  getCheckoutPagesEP,
  createCheckoutPagesEP,
  deleteCheckoutPagesEP,
  updateCheckoutPagesEP,
  getStoreById,
  getPaymentGatewaysEP,
  createPaymentGatewayEP,
  updatePaymentGatewayEP,
} from 'services/constants';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  ICreateStore,
  ISearchStore,
  IStore,
  IStoreList,
} from 'interface/storeInterface';
import {
  ILegacyPaymentGateway,
  ISubscriptionPlan,
} from 'interface/settingsInterface';
import { settingsPaymentGateways } from 'utils/mockData';
import { IGetPages, IPages } from 'interface/pagesInterface';
import {
  IAddCheckoutPage,
  IDeleteCheckoutPage,
  IGetCheckoutPages,
  IGetCheckoutPagesResponse,
  IUpdateCheckoutPage,
} from 'interface/checkoutPagesInterface';
import { IPaymentGateway } from 'shared-components/src/page-builder/interface/settingsInterface';

class StoresMockService {
  createStore = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createLegacyPaymentGateway = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createPaymentGateway = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateLegacyPaymentGateway = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updatePaymentGateway = async (): Promise<
    | ISuccessResponse<{ message: string; status: number }>
    | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStores = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  searchStores = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getLegacyPaymentGateways = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: settingsPaymentGateways,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getPaymentGatewayList = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: settingsPaymentGateways,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateStore = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getPages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createPages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updatePages = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getSubscriptionPlans = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateSubscriptionPlan = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createSubscriptionPlan = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteCheckoutPage = async () => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreById = async (storeId: string) => {
    try {
      const response = await baseService.get<IStore>(getStoreById(storeId));
      return response;
    } catch (error) {
      // return this.settingsMockService.updateStore();
      return commonError(error as AxiosError, true);
    }
  };
}
class StoresService {
  storesMockService = new StoresMockService();
  createStore = async (
    store: ICreateStore,
  ): Promise<ISuccessResponse<ICreateStore> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<ICreateStore>(createStoreEP, store);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createLegacyPaymentGateway = async (
    paymentDetails: ILegacyPaymentGateway,
  ): Promise<
    ISuccessResponse<ILegacyPaymentGateway> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<ILegacyPaymentGateway>(
        createLegacyPaymentGatewayEP,
        paymentDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createPaymentGateway = async (
    paymentDetails: IPaymentGateway,
  ): Promise<
    ISuccessResponse<IPaymentGateway> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IPaymentGateway>(
        createPaymentGatewayEP,
        paymentDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateLegacyPaymentGateway = async (
    paymentDetails: ILegacyPaymentGateway,
  ): Promise<
    ISuccessResponse<ILegacyPaymentGateway> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<ILegacyPaymentGateway>(
        updateLegacyPaymentGatewayEP,
        paymentDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updatePaymentGateway = async (
    paymentDetails: IPaymentGateway,
  ): Promise<
    ISuccessResponse<IPaymentGateway> | IErrorResponse<AxiosError>
  > => {
    try {
      return await baseService.post<IPaymentGateway>(
        updatePaymentGatewayEP,
        paymentDetails,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  searchStores = async (searchData: ISearchStore) => {
    try {
      const response = await baseService.get<IStoreList>(
        searchStoresEp(searchData),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStores = async (store: string) => {
    try {
      const response = await baseService.get<IStore[]>(
        getSettingsStoresEp(store),
      );
      return response;
    } catch (error) {
      // return this.settingsMockService.getStores();
      return commonError(error as AxiosError, true);
    }
  };

  getLegacyPaymentGateways = async (store: string) => {
    try {
      const response = await baseService.get<ILegacyPaymentGateway>(
        getLegacyPaymentGatewaysEP(store),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };
  getPaymentGatewayList = async (store: string) => {
    try {
      const response = await baseService.get<{ Result: IPaymentGateway }>(
        getPaymentGatewaysEP(store),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError);
    }
  };

  updateStore = async (body: IStore) => {
    try {
      const response = await baseService.post(updateStoreEP, body);
      return response;
    } catch (error) {
      // return this.settingsMockService.updateStore();
      return commonError(error as AxiosError, true);
    }
  };

  getPages = async (body: IGetPages) => {
    try {
      const response = await baseService.post(getPagesEP, body);
      return response;
    } catch (error) {
      // return this.settingsMockService.updateStore();
      return commonError(error as AxiosError, true);
    }
  };

  getStoreById = async (storeId: string) => {
    try {
      const response = await baseService.get<IStore>(getStoreById(storeId));
      return response;
    } catch (error) {
      // return this.settingsMockService.updateStore();
      return commonError(error as AxiosError, true);
    }
  };

  createPages = async (
    pageDetails: IPages,
  ): Promise<ISuccessResponse<IPages> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IPages>(createPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updatePages = async (
    pageDetails: IPages,
  ): Promise<ISuccessResponse<IPages> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IPages>(updatePagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getSubscriptionPlans = async (store: string) => {
    try {
      const response = await baseService.get<ISubscriptionPlan>(
        getSubscriptionPlansEP(store),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createSubscriptionPlan = async (
    data: ISubscriptionPlan,
  ): Promise<
    ISuccessResponse<ISubscriptionPlan> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ISubscriptionPlan>(
        createSubscriptionPlanEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateSubscriptionPlan = async (
    data: ISubscriptionPlan,
  ): Promise<
    ISuccessResponse<ISubscriptionPlan> | IErrorResponse<AxiosError>
  > => {
    try {
      const response = await baseService.post<ISubscriptionPlan>(
        updateSubscriptionPlanEP,
        data,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getCheckoutPage = async (body: IGetCheckoutPages) => {
    try {
      const response = await baseService.post<IGetCheckoutPagesResponse>(
        getCheckoutPagesEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  createCheckoutPage = async (pageDetails: IAddCheckoutPage) => {
    try {
      return await baseService.post<{
        ID: string;
      }>(createCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateCheckoutPage = async (pageDetails: IUpdateCheckoutPage) => {
    try {
      return await baseService.post(updateCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  deleteCheckoutPage = async (pageDetails: IDeleteCheckoutPage) => {
    try {
      return await baseService.post(deleteCheckoutPagesEP, pageDetails);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalStoresService: StoresService | StoresMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new StoresMockService()
    : new StoresService();

export default globalStoresService;
