import { Navigate, Outlet } from 'react-router-dom';
import { Path } from 'router/constant';
import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';

type Props = {
  auth: {
    isAuthenticated: string;
  };
};

export const PublicWrapper = ({ auth: { isAuthenticated } }: Props) => {
  const isOnboarding = useAppSelector(
    (state: RootState) => state.user.isOnboarding,
  );

  const url = localStorage.getItem('navigate_url');

  return !isAuthenticated ? (
    <Outlet />
  ) : isOnboarding ? (
    <Navigate to={Path.ON_BOARDING} />
  ) : (
    <Navigate to={url ? url : Path.DASHBOARD} />
  );
};
