/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
declare let ttq: any;
import { RawEvent } from '../../../interface/eventInterface';
import { Tracker } from '../trackers';

export class TikTokTracker implements Tracker {
  private pixelIDs: string[];

  constructor(pixelIDs: string[]) {
    if (pixelIDs.length === 0) {
      throw new Error('TikTokTracker requires at least one pixel ID.');
    }
    this.pixelIDs = pixelIDs;
  }

  Init(): void {
    this.initializePixels();
  }

  private initializePixels() {
    const self = this;
    (function (w: any, d: any, t: any) {
      w.TiktokAnalyticsObject = t;
      const ttq = (w[t] = w[t] || []);
      ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
      ];
      ttq.setAndDefer = function (t: any, e: any) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      for (let i = 0; i < ttq.methods.length; i++)
        ttq.setAndDefer(ttq, ttq.methods[i]);
      ttq.instance = function (t: any) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      };
      ttq.load = function (e: any, n: any) {
        const i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = i;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        n = d.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = i + '?sdkid=' + e + '&lib=' + t;
        e = d.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(n, e);
      };

      self.pixelIDs.forEach(pixelID => ttq.load(pixelID));
      ttq.page();
    })(window, document, 'ttq');
  }

  TrackEvent(event: RawEvent): void {
    this.pixelIDs.forEach(pixelID =>
      ttq.instance(pixelID).track(event.eventName, event.cart),
    );
  }
}
