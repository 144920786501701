export const actionEvents = {
  cancel_subscription: 'cancel_subscription',
  pause_subscription: 'pause_subscription',
  resume_subscription: 'resume_subscription',
  reactivate_subscription: 'reactivate_subscription',
  move_subscription: 'move_subscription',
  change_next_billing_date: 'change_next_billing_date',
  change_subscription_value: 'change_subscription_value',
  change_subscription_frequency: 'change_subscription_frequency',
};

export const SubscriptionStatus = {
  subscriptionStatusActive: 'active',
  subscriptionStatusCanceled: 'canceled',
  subscriptionStatusPause: 'paused',
  subscriptionStatusSalvage: 'salvage',
};
