import { FC, ReactNode, useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { PageNames, Path, unsafe_experimental_featuers } from 'router/constant';
import { isFeatureEnabled } from 'utils/helper';

interface HocProps {
  children: ReactNode;
  permission?: string;
  pageName: PageNames;
}

const RestrictRoute: FC<HocProps> = ({ children, permission, pageName }) => {
  const [searchParams] = useSearchParams();
  const isUnSafe = searchParams.get(unsafe_experimental_featuers) === 'true';

  const showRoute = useMemo(
    () => isFeatureEnabled(pageName, permission),
    [pageName, permission],
  );

  const isRouteAllowed = useMemo(() => {
    if (isUnSafe || showRoute) {
      // Allow if in unsafe experimental features mode
      return true;
    }
    // If showRoute is false, disallow access
    return false;
  }, [isUnSafe, showRoute]);

  if (!isRouteAllowed) {
    // Redirect to a 404 or NOT_FOUND_PAGE if the route is not allowed
    return <Navigate to={Path.NOT_FOUND_PAGE} replace={true} />;
  }

  return <>{children}</>;
};

export default RestrictRoute;
