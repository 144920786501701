import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { PaymentController } from './PaymentController';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import { QuillTextEditor } from '../../puck/reusable-props/quill-editor';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';
export type PaymentProps = {
  subText: string;
  isShowBillingAddress?: boolean;
  showCardName?: boolean;
} & MarginProps &
  showComponentProps;

export const Payment = ({
  useAppSelector,
  useAppDispatch,
}: ReduxSelectorProps & ReduxDispatchProps): ComponentConfig<PaymentProps> => {
  return {
    label: (
      <CustomTooltip title="Payment">
        <span>Payment</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      subText: {
        ...QuillTextEditor('Sub Text').content,
      },
      ...marginFields,
      isShowBillingAddress: {
        type: 'radio',
        label: 'Show Billing Address',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
      showCardName: {
        type: 'radio',
        label: 'Show Card Name',
        options: [
          { label: 'Yes', value: true },
          { label: 'No', value: false },
        ],
      },
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      subText: 'All transactions are secured and encrypted',
      isShowBillingAddress: true,
      showCardName: true,
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({
      margin,
      isShowBillingAddress,
      showCardName,
      subText,
      showComponent,
    }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);

      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <PaymentController
              fields={{ margin, isShowBillingAddress, showCardName, subText }}
              useAppSelector={useAppSelector}
              useAppDispatch={useAppDispatch}
            />
          )}
        </>
      );
    },
  };
};
