import React, { Fragment, useEffect } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { get } from 'lodash';
import AddPostUpsellsForm from 'components/store/storeDetails/shopify/shopifyDetails/postUpsells/addPostUpsells';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';
import {
  IPostUpSellCreate,
  IPostUpsellResponse,
} from 'interface/upSellInterface';
import globalPostSellService from 'services/postUpSellService';
import { showNotification } from 'helper/common/commonFunctions';
import membershipOfferService from 'services/membershipOfferService';
import { IMemberShipProductsDetails } from 'interface/membershipOffersInterface';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IPostUpsellResponse) => ReactNode;
  hide?: boolean;
}
const defaultProduct: IPostUpsellResponse = {
  Amount: '',
  ID: '',
  ChannelID: '',
  ProductID: '',
  Type: '',
  DiscountPrice: '',
  Name: '',
  Version: 0,
};
interface PostUpSellProps {
  channelId: string;
  shopId: string;
  storeId: string;
}

export default function PostUpsells({
  channelId,
  shopId,
  storeId,
}: PostUpSellProps) {
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [postUpsells, setPostUpSells] = useState<IPostUpsellResponse[]>([]);
  const [addedPostUpsells, setAddedPostUpsells] = useState<
    IPostUpsellResponse[]
  >([]);
  const [add, setAdd] = useState<boolean>(false);
  const [selected, setSelected] = useState<IPostUpsellResponse>(defaultProduct);
  const setDefaultProduct = () => {
    setSelected(defaultProduct);
  };

  const headCellList: HeadCell[] = [
    {
      id: 'Product',
      label: 'Product',
      hide: false,
      cellRender: row => {
        return <>{row?.Name}</>;
      },
    },
    {
      id: 'Type',
      label: 'Discount Type',
      hide: false,
      cellRender: row => {
        return <>{row?.Type === 'fixed' ? 'Fixed Amount' : 'Percentage'}</>;
      },
    },
    {
      id: 'DiscountPrice',
      label: 'Discount Amount',
      hide: false,
      cellRender: row => {
        return <>{row.Type === 'percent' ? `${row?.Amount}%` : row?.Amount}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="flex items-center justify-end gap-3">
              <CreateIcon
                onClick={() => {
                  setAdd(true);
                  setSelected(row);
                }}
              />
              <DeleteIcon
                className="h-15"
                onClick={() => {
                  setIsDelete(true);
                  setSelected(row);
                }}
              />
            </div>
          </>
        );
      },
    },
  ];

  const getProductsOfStore = async (
    productIds: number[],
    postUpSellData: Map<string, IPostUpsellResponse>,
  ) => {
    const productPayload = {
      ShopID: shopId,
      StoreID: storeId,
      ProductIDs: productIds?.filter(id => id > 0),
    };
    if (productPayload.ProductIDs?.length) {
      const res = await membershipOfferService.batchProducts(productPayload);
      if (res.status === 200) {
        res.data.Result.forEach((item: IMemberShipProductsDetails) => {
          if (postUpSellData.get(item.ID as string))
            postUpSellData.set(item.ID as string, {
              ...(postUpSellData.get(item.ID) as IPostUpsellResponse),
              ProductID: item.ID,
              DiscountPrice: item.MaxOriginalPrice,
              Name: item.Title,
            });
        });
        setPostUpSells(Array.from(postUpSellData.values()));
      } else setPostUpSells([]);
    }
  };

  const getPostUpSell = async () => {
    setLoading(true);
    const response = await globalPostSellService.searchUpSell({
      ChannelID: channelId,
      Descending: true,
      Limit: 100,
      OrderBy: 'Amount',
      Page: 0,
    });
    if (response?.status === 200 && response?.data?.Result?.length) {
      setAddedPostUpsells(response.data.Result);
      const postUpSellMap = new Map<string, IPostUpsellResponse>();
      const shopIds = response.data.Result.map((item: IPostUpsellResponse) => {
        postUpSellMap.set(item.ProductID, item);
        const productId = item.ProductID.split('/');
        return parseInt(productId[productId.length - 1]);
      });
      if (shopIds.length) await getProductsOfStore(shopIds, postUpSellMap);
    } else {
      setPostUpSells([]);
    }

    setLoading(false);
  };

  const addPostUpSell = async (data: IPostUpsellResponse) => {
    if (postUpsells.find(item => item.ProductID === data.ProductID)) {
      showNotification('error', 'Product already added');
      return;
    }
    setLoading(true);
    const payload: IPostUpSellCreate = {
      Amount: Number(data.Amount),
      ChannelID: channelId,
      ProductID: data.ProductID,
      Type: data.Type,
    };
    const res = await globalPostSellService.createupSell(payload);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Post Sell Added Successfully');
      getPostUpSell();
    }
  };

  const updatePostUpSell = async (data: IPostUpsellResponse) => {
    if (postUpsells.find(item => item.ProductID === data.ProductID)) {
      showNotification('error', 'Product already added');
      return;
    }
    setLoading(true);
    const res = await globalPostSellService.updateUpSell({
      ChannelID: channelId,
      ProductID: data.ProductID,
      ID: data.ID,
      Type: data.Type,
      Version: data.Version,
      Amount: Number(data.Amount),
    });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Post Sell Updated Successfully');
      getPostUpSell();
    }
    setAdd(false);
  };

  const handleRemove = async (postUpSellId: string) => {
    setLoading(true);
    const res = await globalPostSellService.removeUpSell({
      ChannelID: channelId,
      PostUpsellIDs: [postUpSellId],
    });
    setLoading(false);
    if (res?.status === 200 || res?.status === 204) {
      showNotification('success', 'Post Sell deleted Successfully');
      getPostUpSell();
    }
    setIsDelete(false);
  };

  const handleFormSubmit = (data: IPostUpsellResponse) => {
    if (!data.ID) {
      addPostUpSell(data);
    } else {
      updatePostUpSell(data);
    }
  };

  useEffect(() => {
    getPostUpSell();
  }, []);
  return (
    <>
      <div className="h-full">
        <TableContainer className="Common_Table !h-fit max-h-full">
          <Table aria-labelledby="tableTitle" className="table" stickyHeader>
            <TableHead>
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell
                      className={
                        headCell.label == 'Actions' ? '!text-right' : ''
                      }
                      key={headCell.label}>
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {postUpsells?.length > 0 ? (
                postUpsells?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {headCellList.map(headCell => {
                        if (headCell.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            key={headCell.label}
                            component="th"
                            id={headCell.id}
                            scope="row">
                            <>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <Fragment>
                  <TableRow>
                    <TableCell className="!py-20" colSpan={15}>
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                </Fragment>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <button
                    className="sticky-button flex items-center"
                    onClick={() => {
                      setAdd(true);
                      setSelected(defaultProduct);
                    }}>
                    <AddIcon /> Add Post upsells
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <Loader loading={loading} />
      {add && (
        <AddPostUpsellsForm
          shopId={shopId}
          storeId={storeId}
          selectedProduct={selected}
          handleClose={() => setAdd(false)}
          action={product => handleFormSubmit(product)}
          setDefaultProduct={setDefaultProduct}
          addedPostUpsells={addedPostUpsells}
        />
      )}
      <ConfirmationDialog
        open={isDelete}
        handleClose={() => setIsDelete(false)}
        message="Delete Post Upsells"
        onSubmit={() => {
          handleRemove(selected.ID);
        }}
      />
    </>
  );
}
