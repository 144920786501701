import { ComponentConfig } from '@measured/puck';
import {
  UpSell as UpSellComponent,
  UpSellProps,
} from '../components/upsell/Upsell';
import { ReduxDispatchProps, ReduxSelectorProps } from '../services/constants';
import {
  TermsAndSub as TermsAndSubComponent,
  TermsAndSubProps,
} from '../components/terms-and-subscription';
import {
  Header as HeaderComponent,
  HeaderProps,
} from '../components/post-upsell/header/Header';
import {
  Payment as PaymentComponent,
  PaymentProps,
} from '../components/payment/Payment';
import {
  Product as ProductComponent,
  ProductProps,
} from '../components/post-upsell/product-varient';
import {
  StandardLayout as StandardLayoutComponent,
  StandardLayoutProps,
} from '../components/blocks/StandardLayout';
import {
  ShippingMethodSelect as ShippingMethodSelectComponent,
  ShippingMethodSelectProps,
} from '../components/shipping-method/ShippingMethod';
import {
  SavingCalculatorProps,
  SavingCalculator as SavingCalculatorComponent,
} from '../components/savings-calculator';
import {
  PromoCodeProps,
  PromoCode as PromoCodeComponent,
} from '../components/promo-code/PromoCode';
import {
  PaymentRadioProps,
  PaymentRadio as PaymentRadioComponent,
} from '../components/payment-radio/PaymentRadio';
import {
  SimplePageHeaderProps,
  SimplePageHeader as SimplePageHeaderComponent,
} from '../components/page-headers/SimplePageHeader';
import {
  OrderDetailsProps,
  OrderDetails as OrderDetailsComponent,
} from '../components/order-details/OrderDetails';
import {
  ContactInfoProps,
  ContactInfo as ContactInfoComponent,
} from '../components/contact-info/ContactInfo';
import { MarginProps } from '../puck/reusable-props/margin';
import { Cart as CartComponent } from '../components/cart';
import {
  SingleStepButtonSectionProps,
  SingleStepButtonSection as ConfirmPurchaseComponent,
} from '../components/button-section/SingleStepButtonSection';
import {
  UpsellDealProps,
  UpsellDeal as UpsellDealComponent,
} from '../components/post-upsell/deal';
import {
  NextButtonProps,
  NextButton as NextButtonComponent,
} from '../components/post-upsell/next-button/next-button';

import { ShippingForm as ShippingFormComponent } from '../components/address/ShippingForm';
import {
  AccordionProps,
  Accordion as AccordionComponent,
} from '../components/blocks/Accordion';
import {
  SplitLayoutProps,
  SplitLayout as SplitLayoutComponent,
} from '../components/blocks/SplitLayout';
import {
  ButtonSectionProps,
  ButtonComponent as ButtonComponent,
} from '../components/blocks/button/button';
import { AxiosInstance } from 'axios';
import {
  CustomerGuarnteesProps,
  CustomerGuarantees as CustomerGuarnteesComponent,
} from '../components/customer-guarantees/customerGuarantees';
import {
  ImageUploaderProps,
  ImageUploader as ImageUploaderComponent,
} from '../components/image-uploader/image-uploader';
import {
  TimerProps as UpSellTimerProps,
  Timer as UpSellTimer,
} from '../components/post-upsell/timer';
import { Flex as FlexComponent, FlexProps } from '../components/blocks/Flex';
import {
  VerticalSpace as VerticalSpaceComponent,
  VerticalSpaceProps,
} from '../components/blocks/VerticalSpace';
import {
  Divider as DividerComponent,
  DividerProps,
} from '../components/blocks/Divider';
import {
  Container as ContainerEditor,
  ContainerProps,
} from '../components/blocks/Container';
import {
  TextEditor as TextEditorComponent,
  TextEditorProps,
} from '../components/text-editor/TextEditor';
import {
  TextField as TextFieldComponent,
  TextFieldProps,
} from '../components/text-field/TextField';
import {
  BillingForm as BillingFormComponent,
  BillingFormProps,
} from '../components/address/BillingForm';
import {
  CustomerReviews as CustomerReviewComponent,
  CustomerReviewsProps,
} from '../components/customer-reviews/CustomerReviews';
import { Timer as TimerComponent, TimerProps } from '../components/timer/Timer';
import { baseService as checkoutService } from '../api';
import {
  Stepper as stepperForm,
  StepperProps,
} from '../components/stepper/Stepper';
import {
  ReturnButton as ReturnButtonComponent,
  ReturnButtonProps,
} from '../components/shared/buttons/ReturnButton';
import {
  Columns as ColumEditor,
  ColumnsProps,
} from '../components/blocks/Columns';
import { showComponentProps } from '../puck/reusable-props/showComponent';

interface CreateComponentConfigsArgs
  extends ReduxDispatchProps,
    ReduxSelectorProps {
  type: 'checkout' | 'thankyou' | 'upsell' | 'freestyle' | 'all';
  baseService?: AxiosInstance;
}

export const createComponentConfigs = ({
  useAppDispatch,
  useAppSelector,
  type,
  baseService = checkoutService,
}: CreateComponentConfigsArgs) => {
  const UpSell: ComponentConfig<UpSellProps> = UpSellComponent({
    useAppSelector,
    useAppDispatch,
  });
  const Header: ComponentConfig<HeaderProps> = HeaderComponent({
    useAppSelector,
  });
  const Product: ComponentConfig<ProductProps> = ProductComponent({
    useAppSelector,
    useAppDispatch,
  });
  const TermsAndSub: ComponentConfig<TermsAndSubProps> = TermsAndSubComponent({
    useAppSelector,
    useAppDispatch,
  });
  const ShippingMethodSelect: ComponentConfig<ShippingMethodSelectProps> =
    ShippingMethodSelectComponent({
      useAppSelector,
      useAppDispatch,
    });
  const SavingCalculator: ComponentConfig<SavingCalculatorProps> =
    SavingCalculatorComponent({
      useAppSelector,
    });
  const PromoCode: ComponentConfig<PromoCodeProps> = PromoCodeComponent({
    useAppSelector,
    useAppDispatch,
  });
  const Payment: ComponentConfig<PaymentProps> = PaymentComponent({
    useAppSelector,
    useAppDispatch,
  });
  const PaymentRadio: ComponentConfig<PaymentRadioProps> =
    PaymentRadioComponent({
      useAppSelector,
      useAppDispatch,
    });
  const OrderDetails: ComponentConfig<OrderDetailsProps> =
    OrderDetailsComponent({
      useAppSelector,
    });
  const ContactInfo: ComponentConfig<ContactInfoProps> = ContactInfoComponent({
    useAppSelector,
    useAppDispatch,
  });
  const Cart: ComponentConfig<MarginProps & showComponentProps> = CartComponent(
    {
      useAppSelector,
      useAppDispatch,
    },
  );
  const SimplePageHeader: ComponentConfig<SimplePageHeaderProps> =
    SimplePageHeaderComponent({
      useAppSelector,
      baseService,
    });
  const ConfirmPurchase: ComponentConfig<SingleStepButtonSectionProps> =
    ConfirmPurchaseComponent({
      useAppSelector,
      useAppDispatch,
      baseService,
    });
  const UpsellDeal: ComponentConfig<UpsellDealProps> = UpsellDealComponent({
    useAppSelector,
    useAppDispatch,
    baseService,
  });
  const Button: ComponentConfig<ButtonSectionProps> = ButtonComponent({
    useAppSelector,
    baseService,
  });
  const CustomerGuarantees: ComponentConfig<CustomerGuarnteesProps> =
    CustomerGuarnteesComponent({ useAppSelector, baseService });
  const ImageUploader: ComponentConfig<ImageUploaderProps> =
    ImageUploaderComponent({ useAppSelector, baseService });
  const NextButton: ComponentConfig<NextButtonProps> = NextButtonComponent({
    useAppSelector,
    useAppDispatch,
    baseService,
  });
  const TimerUpSell: ComponentConfig<UpSellTimerProps> = UpSellTimer({
    useAppSelector,
  });

  const ShippingForm: ComponentConfig<MarginProps & showComponentProps> =
    ShippingFormComponent({
      useAppSelector,
      useAppDispatch,
    });
  const Accordion: ComponentConfig<AccordionProps> = AccordionComponent({
    useAppSelector,
  });
  const StandardLayout: ComponentConfig<StandardLayoutProps> =
    StandardLayoutComponent({
      useAppSelector,
    });
  const SplitLayout: ComponentConfig<SplitLayoutProps> = SplitLayoutComponent({
    useAppSelector,
  });
  const Stepper: ComponentConfig<StepperProps> = stepperForm({
    useAppSelector,
  });

  const TextEditor: ComponentConfig<TextEditorProps> = TextEditorComponent({
    useAppSelector,
  });

  const ReturnButton: ComponentConfig<ReturnButtonProps> =
    ReturnButtonComponent({
      useAppSelector,
    });
  const Columns: ComponentConfig<ColumnsProps> = ColumEditor({
    useAppSelector,
  });

  const Container: ComponentConfig<ContainerProps> = ContainerEditor({
    useAppSelector,
  });

  const Divider: ComponentConfig<DividerProps> = DividerComponent({
    useAppSelector,
  });

  const Flex: ComponentConfig<FlexProps> = FlexComponent({
    useAppSelector,
  });

  const VerticalSpace: ComponentConfig<VerticalSpaceProps> =
    VerticalSpaceComponent({
      useAppSelector,
    });

  const TextField: ComponentConfig<TextFieldProps> = TextFieldComponent({
    useAppSelector,
  });

  const CustomerReviews: ComponentConfig<CustomerReviewsProps> =
    CustomerReviewComponent({
      useAppSelector,
    });

  const BillingForm: ComponentConfig<BillingFormProps> = BillingFormComponent({
    useAppSelector,
  });

  const Timer: ComponentConfig<TimerProps> = TimerComponent({
    useAppSelector,
  });

  const configComponents = {
    checkout: {
      BillingForm,
      CustomerReviews,
      Timer,
      UpSell,
      ConfirmPurchase,
      StandardLayout,
      CustomerGuarantees,
      Payment,
      TermsAndSub,
      ShippingMethodSelect,
      SplitLayout,
      ShippingForm,
      SavingCalculator,
      SimplePageHeader,
      PaymentRadio,
      OrderDetails,
      Cart,
      PromoCode,
      ContactInfo,
      Stepper,
      ReturnButton,
    },
    thankyou: {
      SimplePageHeader,
      StandardLayout,
      SplitLayout,
    },
    upsell: {
      Header,
      Product,
      UpsellDeal,
      TimerUpSell,
      NextButton,
    },
    common: {
      // These are the common components that are used in all pages
      Columns,
      Flex,
      VerticalSpace,
      Divider,
      Container,
      ImageUploader,
      Accordion,
      Button,
      TextEditor,
      TextField,
    },
  };
  let config = {};

  if (type === 'checkout') {
    config = configComponents.checkout;
  } else if (type === 'thankyou') {
    config = configComponents.thankyou;
  } else if (type === 'upsell') {
    config = configComponents.upsell;
  } else if (type === 'all') {
    config = {
      ...configComponents.checkout,
      ...configComponents.upsell,
      ...configComponents.thankyou,
    };
  }
  config = {
    ...config,
    ...configComponents.common,
  };
  return config;
};
