import * as React from 'react';
import { SVGProps } from 'react';

const CreditCardLogos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Layer_1__x28_1:1_x29_"
    x={0}
    y={0}
    viewBox="0 0 304 74"
    {...props}>
    <style>
      {
        '.st0{fill:#fff}.st2{fill:#10357f}.st4{fill:#231f20}.st9{fill:#f79e1b}.st10{fill:#0071ce}'
      }
    </style>
    <path d="M0 0h304v74H0z" className="st0" />
    <path d="M159.4 19H216v36h-56.6z" className="st0" />
    <path
      d="M160.4 49.1h54.7V54h-54.7z"
      style={{
        fill: '#fcb316',
      }}
    />
    <path
      d="M160.4 20h54.7v4.9h-54.7zM182.3 30.3l-5.7 13.5h-3.7L170.1 33c0-.5-.4-1-.8-1.2-1.1-.5-2.3-.9-3.4-1.1l.1-.4h5.9c.8 0 1.5.6 1.6 1.4l1.5 7.8 3.6-9.2h3.7zm14.4 9.1c0-3.6-4.9-3.8-4.9-5.3 0-.5.5-1 1.5-1.1 1.2-.1 2.4.1 3.4.6l.6-2.9c-1-.4-2.2-.6-3.3-.6-3.4 0-5.9 1.8-5.9 4.5 0 1.9 1.7 3 3.1 3.7s1.8 1.1 1.8 1.7c0 .9-1.1 1.3-2.1 1.3-1.2 0-2.5-.3-3.6-.8l-.6 3c1.2.5 2.6.7 3.9.7 3.7-.2 6.1-2 6.1-4.8m9.2 4.4h3.2l-2.8-13.5h-3c-.7 0-1.2.4-1.5 1l-5.2 12.5h3.7l.7-2h4.5l.4 2zM202 39l1.8-5.1 1.1 5.1H202zm-14.7-8.7-2.9 13.5h-3.5l2.9-13.5h3.5z"
      className="st2"
    />
    <path
      d="M285 38.9s-14.8 10.5-42 15.2h42V38.9z"
      style={{
        fill: '#ef7622',
      }}
    />
    <path
      d="M285.2 19.5h-54.5v35h54.7v-35h-.2zm-.2.4v34.2h-53.9V19.9H285z"
      className="st4"
    />
    <path
      d="M235.5 30.2h-2.3v7.9h2.2c1 .1 2.1-.3 2.9-.9.9-.7 1.4-1.8 1.4-3 0-2.2-1.7-4-3.8-4h-.4zm1.8 6c-.6.5-1.4.7-2.1.6h-.4v-5.3h.4c.8-.1 1.5.2 2.1.6.6.5.9 1.2.9 2 0 .9-.3 1.6-.9 2.1zM240.4 30.2h1.5v7.9h-1.5zM245.7 33.2c-.9-.3-1.2-.6-1.2-1s.5-.9 1.1-.9c.5 0 .9.2 1.2.6l.8-1.1c-.6-.6-1.5-.9-2.3-.9-1.3-.1-2.4.9-2.5 2.2v.1c0 1.1.5 1.7 1.9 2.2.4.1.7.3 1.1.5.3.2.5.5.5.8 0 .6-.5 1.1-1.1 1.1h-.1c-.7 0-1.4-.4-1.7-1.1l-1 1c.6 1 1.6 1.5 2.8 1.5 1.4.1 2.6-1 2.7-2.4v-.2c.1-1.1-.4-1.7-2.2-2.4zM248.5 34.2c0 2.3 1.8 4.1 4.1 4.1h.1c.7 0 1.3-.2 1.9-.5V36c-.5.5-1.1.9-1.9.9-1.4 0-2.6-1.1-2.7-2.5v-.2c0-1.5 1.1-2.7 2.5-2.7h.1c.7 0 1.5.3 1.9.9v-1.9c-.6-.3-1.2-.5-1.9-.5-2.2 0-4.1 1.8-4.1 4.2zM266.9 35.5l-2.1-5.3h-1.7l3.3 8.1h.9l3.4-8.1H269zM271.4 38.1h4.4v-1.3h-2.9v-2.1h2.8v-1.4h-2.8v-1.7h2.9v-1.4h-4.4zM281.9 32.5c0-1.5-1-2.3-2.8-2.3h-2.3v7.9h1.5v-3.2h.2l2.1 3.2h1.9l-2.5-3.3c1.2-.2 2-1.1 1.9-2.3zm-3.1 1.4h-.5v-2.4h.5c1 0 1.5.4 1.5 1.2s-.5 1.2-1.5 1.2zM283 30.6c0-.1-.1-.2-.3-.2h-.2v.7h.2v-.3l.2.3h.2l-.2-.3s.1-.1.1-.2zm-.3.1v-.2c.1 0 .1 0 .1.1s0 .1-.1.1z"
      className="st4"
    />
    <path
      d="M282.7 30.1c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.2-.6-.6-.6zm0 1.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5z"
      className="st4"
    />
    <defs>
      <path
        id="SVGID_1_"
        d="M255.2 34.3c0 2.3 1.9 4.2 4.2 4.2s4.2-1.9 4.2-4.2-1.9-4.2-4.2-4.2c-2.4 0-4.2 1.9-4.2 4.2"
      />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <linearGradient
      id="SVGID_3_"
      x1={-2398.878}
      x2={-2398.434}
      y1={118.331}
      y2={118.331}
      gradientTransform="rotate(-122.566 -14756.145 -7284.252) scale(12.0933)"
      gradientUnits="userSpaceOnUse">
      <stop
        offset={0}
        style={{
          stopColor: '#f59f00',
        }}
      />
      <stop
        offset={0.234}
        style={{
          stopColor: '#f49a01',
        }}
      />
      <stop
        offset={0.499}
        style={{
          stopColor: '#f28d04',
        }}
      />
      <stop
        offset={0.62}
        style={{
          stopColor: '#f18406',
        }}
      />
      <stop
        offset={0.755}
        style={{
          stopColor: '#ef7b09',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ed6f0d',
        }}
      />
    </linearGradient>
    <path
      d="m267.3 36-6.2-9.7-9.7 6.2 6.2 9.7z"
      style={{
        clipPath: 'url(#SVGID_2_)',
        fill: 'url(#SVGID_3_)',
      }}
    />
    <path
      d="M110.2 24.8h14.9v24.4h-14.9z"
      style={{
        fill: '#ff5f00',
      }}
    />
    <path
      d="M111.7 37c0-4.8 2.2-9.3 5.9-12.2-6.7-5.3-16.5-4.1-21.8 2.6s-4.1 16.5 2.6 21.8c5.6 4.4 13.5 4.4 19.2 0-3.7-3-5.9-7.5-5.9-12.2z"
      style={{
        fill: '#eb001b',
      }}
    />
    <path
      d="M141.3 46.6v-.5h.2V46h-.5v.1h.2l.1.5zm1 0V46h-.2l-.2.4-.2-.4h-.2v.6h.1v-.5l.2.4h.1l.2-.4v.5h.2zM142.7 37c0 8.6-6.9 15.5-15.5 15.5-3.5 0-6.9-1.2-9.6-3.3 6.7-5.3 7.9-15 2.6-21.8-.8-1-1.6-1.8-2.6-2.6 6.7-5.3 16.5-4.1 21.8 2.6 2.2 2.7 3.3 6.1 3.3 9.6z"
      className="st9"
    />
    <path
      d="M68.6 50.7 66.1 48l-2.5 2.7H48.2V38.2h-5l6.2-14.1h6l2.2 4.8v-4.8H65l1.3 3.7 1.3-3.7h5.7v-2.2c0-1.3-1.1-2.3-2.3-2.3H21c-1.3 0-2.3 1.1-2.3 2.3v30.3c0 1.3 1.1 2.3 2.3 2.3h50c1.3 0 2.3-1.1 2.3-2.3v-1.5h-4.7z"
      className="st10"
    />
    <path
      d="M69.3 49.3h4l-5.2-5.5 5.2-5.5h-3.9l-3.2 3.5-3.2-3.5h-3.9l5.2 5.5-5.2 5.5h3.8l3.2-3.5z"
      className="st10"
    />
    <path
      d="M52.8 46.8v-1.7H59v-2.5h-6.2v-1.8h6.3v-2.5h-9.3v11h9.3v-2.5zM70.3 36.8h2.9v-11h-4.5l-2.5 6.8-2.4-6.8h-4.7v11h3v-7.7l2.8 7.7h2.6l2.8-7.7z"
      className="st10"
    />
    <path
      d="M54.3 25.8h-3.8l-4.8 10.9H49l.9-2.2h5l.9 2.2h3.4l-4.9-10.9zm-3.5 6.3 1.5-3.6 1.5 3.6h-3zM70.5 43.5l2.8 3v-6z"
      className="st10"
    />
  </svg>
);
export default CreditCardLogos;
