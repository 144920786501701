import 'components/common/stepper/style.scss';
import { FC } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface IProps {
  steps: string[];
  activeStep: number;
}

const CommonStepper: FC<IProps> = ({ steps, activeStep }) => {
  return (
    <div className="stepper-wrapper">
      <div className="stepper-position">
        <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
          {steps.map((label: string, index: number) => (
            <Step className="step" key={index}>
              <StepLabel className="stepLabel">{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default CommonStepper;
