import { useNavigate, useParams } from 'react-router-dom';
import './style.scss';
import { ReactComponent as Phoenix } from 'assets/icons/phoenix_icon.svg';
import globalUserService from 'services/userService';
import { useEffect, useState } from 'react';
import { showNotification } from 'helper/common/commonFunctions';
import { IInvitation } from 'interface/userInterface';
import { Path } from 'router/constant';
import { listMyPermissions } from 'utils/helper';
import { inviteUserStatus } from 'utils/constants';
export default function InviteUser() {
  const searchParams = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<IInvitation | null>(null);
  const invite = (isAccept: boolean) => {
    if (!searchParams?.storeId || !searchParams?.inviteId) return;
    if (isAccept) {
      acceptInvite(searchParams?.storeId, searchParams?.inviteId);
    } else {
      declineInvite(searchParams?.storeId, searchParams?.inviteId);
    }
    localStorage.removeItem('invite_user_url');
  };
  const declineInvite = async (storeId: string, inviteId: string) => {
    const response = await globalUserService.declineInvite({
      StoreID: storeId,
      InviteID: inviteId,
    });
    if (response.status === 200) showNotification('success', 'Invite declined');
    navigate(Path.DASHBOARD);
  };

  const acceptInvite = async (storeId: string, inviteId: string) => {
    const response = await globalUserService.acceptInvite({
      StoreID: storeId,
      InviteID: inviteId,
    });
    if (response.status === 200) {
      showNotification('success', 'Invite accepted');
      listMyPermissions();
    }
    navigate(Path.DASHBOARD);
  };
  const getUserInvites = async () => {
    if (!searchParams?.storeId || !searchParams?.inviteId) return;
    const res = await globalUserService.getInvites({
      Descending: false,
      Limit: 10,
      OrderBy: 'CreatedAt',
      Page: 0,
      SearchFields: {
        ID: searchParams?.inviteId,
        'Store.ID': searchParams?.storeId,
      },
    });
    if (res.status !== 200) return;
    if (res.data?.Result?.length > 0) {
      if (res.data.Result[0]?.Status === inviteUserStatus.pending) {
        setUser(res.data.Result[0]);
        return;
      }
      showNotification(
        'error',
        `Invite is already ${res.data.Result[0]?.Status}`,
      );
      navigate(Path.INVITES);
    } else {
      localStorage.removeItem('invite_user_url');
      showNotification('error', 'Invalid invite');
      navigate(Path.INVITES);
    }
  };
  useEffect(() => {
    getUserInvites();
  }, []);
  return (
    user && (
      <div className="invite-card">
        <div className="invite-header">
          <Phoenix />
        </div>
        <div className="invite-message">
          You have been invited to join
          <span className="invite-store">
            {user?.Store?.Name && ` ${user?.Store?.Name}`}
          </span>
        </div>
        <div className="invite-buttons">
          <button
            className="invite-button reject"
            onClick={() => invite(false)}
            disabled={user ? false : true}>
            REJECT
          </button>
          <button
            className="invite-button accept"
            onClick={() => invite(true)}
            disabled={user ? false : true}>
            ACCEPT
          </button>
        </div>
      </div>
    )
  );
}
