import { FieldLabel, Fields } from '@measured/puck';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { useState } from 'react';
import {
  defaultImage,
  ImageSelector,
} from '../../../components/common/image-selector/ImageSelector';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../components/common/tooltip';

export type ImageProps = {
  file: IAsset;
};

export const imageField = (
  isBackground: boolean,
  baseService: AxiosInstance,
): Fields<ImageProps> => {
  return {
    file: {
      type: 'custom',
      render: ({ value, onChange }) => {
        const [selectedImage, setSelectedImage] = useState<IAsset>(
          value ? value : defaultImage,
        );
        const handleSubmit = (image: IAsset) => {
          setSelectedImage(image);
          onChange(image);
        };

        return (
          <FieldLabel
            label={
              (
                <CustomTooltip
                  title={isBackground ? 'Image' : 'Background Image'}>
                  <span>{isBackground ? 'Image' : 'Background Image'}</span>
                </CustomTooltip>
              ) as React.ReactNode as string
            }>
            <ImageSelector
              selectedImage={selectedImage}
              handleSubmit={handleSubmit}
              baseService={baseService}
            />
          </FieldLabel>
        );
      },
    },
  };
};
