const cardBrand = {
  all: 'All',
  visa: 'Visa',
  masterCard: 'Mastercard',
  amex: 'Amex',
  discover: 'Discover',
};

export const cardBrandOptions = Object.values(cardBrand).map(range => ({
  label: range,
  value: range,
}));
