import { Select } from 'shared-components';
import { Button } from 'shared-components';
import { useEffect, useState } from 'react';
import RadioButton from 'components/common/radioButton';
import { useNavigate } from 'react-router-dom';
import { Path } from 'router/constant';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import { ISelectOptions } from 'interface/membershipPlanInterface';
import membershipPlanService from 'services/membershipPlanService';
import { useAppSelector } from 'hooks/reduxHooks';
import ShopifyPopup from 'components/shopify_app_components/shopifyPopUp';

const membershipTypes = [
  { label: 'All', value: '' },
  { label: 'VIP', value: 'VIP' },
  { label: 'Platinum', value: 'Platinum' },
  { label: 'Silver', value: 'Silver' },
];

const data1 = [
  { label: 'Sell all products under single membership', value: 'single' },
  { label: 'Offer both standard and membership pricing', value: 'both' },
];
const Settings = () => {
  const [filterMembership, setFilterMembership] = useState<string>('');
  const [isSingle, setIsSingle] = useState<boolean>(true);
  const [membershipPlanList, setmembershipPlanList] = useState<
    ISelectOptions[]
  >([]);
  const [membershipPlan, setmembershipPlan] = useState<ISelectOptions | null>(
    null,
  );
  const navigate = useNavigate();
  const { storeFront, shop } = useAppSelector(state => state.shopify);

  const getMembershipPlan = async (storeID: string) => {
    const data = await membershipPlanService.getMembershipPlan(storeID);
    if (data?.status === 200 && data?.data?.Result?.length) {
      setmembershipPlanList(
        data?.data?.Result?.map(item => ({
          label: item.Name,
          value: item.ID || '',
        })),
      );
      return;
    }
    setmembershipPlanList([]);
  };

  useEffect(() => {
    if (storeFront?.StoreID) {
      getMembershipPlan(storeFront?.StoreID);
    }
  }, []);

  return shop ? (
    <div className="mx-auto max-w-xl text-left">
      <div className="flex justify-between align-center py-6">
        <h2
          className="text-xl font-semibold"
          onClick={() => {
            navigate(Path.SHOPIFY_SETTINGS_PAGE);
          }}>
          Settings
        </h2>
        <Button variant="secondary" label="Update" onClick={() => {}} />
      </div>
      <div className="flex flex-col gap-4 ">
        <div className="card">
          <div className="p-4">
            <h2 className="font-semibold text-sm pb-3">Offer type</h2>
            <RadioButton
              label=""
              value={isSingle ? 'single' : 'both'}
              onChange={e => {
                window.parent.postMessage(
                  {
                    routes: {
                      showMembershipOffer: e.target.value !== 'single',
                    },
                  },
                  '*',
                );
                setIsSingle(e.target.value === 'single');
              }}
              options={data1}
            />
          </div>
        </div>
        {isSingle ? (
          <div className="card">
            <div className="p-4">
              <div className="flex justify-between align-center pb-2">
                <h2>Membership</h2>
                <Button
                  label="+ Create membership"
                  onClick={() => {
                    navigate(Path.ADD_MEMBERSHIP);
                  }}
                />
              </div>
              <div className="pb-2">
                <AutoCompleteSelect<ISelectOptions>
                  className="w-full dropdown-field"
                  value={
                    membershipPlanList.find(
                      item => item.value === membershipPlan?.value,
                    ) || null
                  }
                  labelKey="label"
                  onChange={e => setmembershipPlan(e || null)}
                  options={membershipPlanList}
                  placeholder="Select"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <div className="p-4">
              <div className="flex justify-between align-center pb-2">
                <h2 className="font-semibold text-sm">Theme Selection</h2>
              </div>
              <div className="pb-3">
                <Select
                  className="w-full dropdown-field"
                  options={membershipTypes}
                  value={filterMembership}
                  onChange={e => setFilterMembership(e)}
                />
              </div>
              <div>
                <Button
                  label="+ Create membership"
                  onClick={() => {
                    navigate(Path.ADD_MEMBERSHIP);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <ShopifyPopup />
  );
};

export default Settings;
