import { useCallback, useRef, useEffect } from 'react';
const useDebouncedCallback = <T>(
  callback: (_args?: T) => void,
  delay: number,
): ((_args?: T) => void) => {
  const timeout = useRef<number | undefined>(undefined);
  useEffect(() => {
    return () => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return useCallback(
    args => {
      const later = () => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current);
        }
        callback(args);
      };

      if (timeout.current !== undefined) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(later, delay);
    },
    [callback, delay],
  );
};

export default useDebouncedCallback;
