import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as Phoenix } from 'assets/icons/phoenix_icon.svg';
import { Button } from 'shared-components';
import { useAppDispatch } from 'hooks/reduxHooks';
import { emailRegex } from 'utils/constants';
import { TextFieldControl } from 'components/common/textField';
import 'components/login/style.scss';
import { login } from 'services/authService';
import { setEmail } from 'redux-setup/slices/userSlice';
import { toast } from 'react-toastify';
import { useState } from 'react';

interface ILoginForm {
  Email: string;
}
const Login = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const method = useForm<ILoginForm>({
    defaultValues: {
      Email: '',
    },
  });

  const onSubmit = async (data: ILoginForm) => {
    setLoading(true);
    const res = await login(data);
    setLoading(false);
    if (res.status === 200) {
      dispatch(setEmail(data.Email));
      toast.success('OTP Sent Successfully');
    } else toast.error('Failed To Login');
  };
  return (
    <div className="loginContainer">
      <div className="title_login_container">
        <Phoenix className="title_icon" />
      </div>
      <form onSubmit={method.handleSubmit(onSubmit)}>
        <div className="login_form">
          <p className="form_text">Enter your email address</p>
          <FormProvider {...method}>
            <TextFieldControl
              name="Email"
              rules={{
                pattern: {
                  value: emailRegex,
                  message: 'Enter a valid address',
                },
                required: 'This field is required',
              }}
              className="login_input"
              placeholder="Email Address"
            />
          </FormProvider>

          <Button
            disabled={loading}
            loading={loading}
            variant="phoenix"
            type="submit"
            label="CONTINUE"
          />
        </div>
        <div className="animated-circle right-circle"></div>
        <div className="animated-circle top-circle"></div>
        <div className="animated-circle bottom-circle"></div>
      </form>
    </div>
  );
};
export default Login;
