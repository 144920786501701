import { useState } from 'react';
import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';
import 'layouts/discloserPage.scss';
import { setIsDisclosed } from 'redux-setup/slices/userSlice';
import { useDispatch } from 'react-redux';
import { staticText } from 'layouts/constant';
import { ReactComponent as Phoenix } from 'assets/icons/phoenixWithoutName.svg';
import { Box, Modal } from '@mui/material';

import { Checkbox } from 'shared-components';
import { Button } from 'shared-components';
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  p: 4,
  transform: 'translate(-50%, -50%)',
  borderRadius: '20px',
  border: '1px solid rgba(163, 174, 208, 0.20)',
  backgroundColor: '#141E2D',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  outline: 'unset',
  width: 'calc(100% - 40px)',
  maxWidth: '600px',
};
const DisclosurePage = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const isPopupOpen = useAppSelector(
    (state: RootState) => state.user.isDisclosed,
  );

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setIsDisclosed(false));
    if (checked) {
      localStorage.setItem(
        'disclosure',
        JSON.stringify({ isDisclosed: true, text: btoa(staticText.join(' ')) }),
      );
    }
  };

  return (
    <Modal className="disclosure_modal" open={isPopupOpen}>
      <Box sx={style}>
        <div className="disclosure_container">
          <div className="disclosure_header">
            <div className="heading-container">
              <span className="heading">Welcome to Phoenix</span>
              <span className="sub_heading"> - Important Reminder</span>
            </div>
            <Phoenix className="phoenix_icon" />
          </div>
          <div className="disclosure">
            {staticText?.map((data, index) => {
              return (
                <p className="disclosure-text" key={index}>
                  {data}
                </p>
              );
            })}
            <div className="div-wrapper">
              <Checkbox
                checked={checked}
                onChange={(_, checked) => {
                  setChecked(checked);
                }}
              />
              <p>{`Do not show this message for future logins.`}</p>
            </div>
          </div>
          <div className="button_box">
            <Button
              onClick={handleClick}
              label="I Understand"
              className="button"></Button>
          </div>
        </div>
      </Box>
    </Modal>
    // open={isPopupOpen}
    // buttons={[
    //   {
    //     buttonType: 'positive',
    //     buttonLabel: 'I Understand',
    //     buttonFunction: () => {
    //       handleClick();
    //     },
    //   },
    // ]}
    // heading={'Disclosure'}>
    // <div className="disclosure popup-section">
    //   {staticText?.map((data, index) => {
    //     return (
    //       <p className="disclosure-text" key={index}>
    //         {data}
    //       </p>
    //     );
    //   })}
    //   <div className="div-wrapper">
    //     <Checkbox
    //       checked={checked}
    //       onChange={(_, checked) => {
    //         setChecked(checked);
    //       }}
    //     />
    //     <p>{`Do not show this message for future logins.`}</p>
    //   </div>
    // </div>
  );
};

export default DisclosurePage;
