import { useEffect } from 'react';

const useUnsavedChangesWarning = (message: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (message) {
        event.preventDefault();
        return;
      }
    };
    if (!message) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      return;
    }
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [message]);
};

export default useUnsavedChangesWarning;
