import { FC, useState } from 'react';
import { PopUpModal, Select } from 'shared-components';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import TextField from 'components/common/textField';
import { Controller, useForm } from 'react-hook-form';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';
import { auth_period_unit, subscription_frequency } from 'utils/helper';

interface IProps {
  payload: ISubscriptionManagementUpdateBody | null;
  handleClose: () => void;
  onApiResponse: (_isSuccess: boolean) => void;
}

interface ISubscriptionValue {
  subscriptionAmount: string;
  Frequency: string;
  AuthPeriod: number;
  AuthPeriodUnit: string;
}

const ChangeSubscriptionValue: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<ISubscriptionValue>({
    defaultValues: {
      subscriptionAmount: payload?.Amount || '',
      Frequency: payload?.Frequency || '',
      AuthPeriod: payload?.AuthPeriod || 0,
      AuthPeriodUnit: payload?.AuthPeriodUnit || '',
    },
  });

  const changeSubscription = async (data: ISubscriptionValue) => {
    if (!payload) return;
    setLoading(true);
    payload.Amount = data.subscriptionAmount;
    payload.Frequency = data.Frequency;
    payload.AuthPeriod = Number(data.AuthPeriod) || 0;
    payload.AuthPeriodUnit = data.AuthPeriodUnit;
    payload.Status = SubscriptionStatus.subscriptionStatusActive;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification('success', 'Subscription Value updated successfully');
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Confirm',
            form: 'ChangeSubscriptionValue',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Change Subscription Value'}
        className="flex-container">
        <div className="popup-section">
          <form
            id="ChangeSubscriptionValue"
            onSubmit={handleSubmit(changeSubscription)}
            className="order-detail_refund">
            <Controller
              name="subscriptionAmount"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    fixedSize="md"
                    label="Subscription Value"
                    value={value}
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '') // remove non-numeric characters
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }
                    className="filter_form_input"
                    placeholder="Enter Subscription Value"
                    errorString={error?.message}
                  />
                );
              }}
              rules={{
                required: 'Subscription value is required',
              }}
            />
            <Controller
              name="Frequency"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    label="Subscription Frequency"
                    placeholder="Select Subscription Frequency"
                    className="filter_form_input pt-3"
                    value={value}
                    onChange={onChange}
                    options={subscription_frequency}
                    error={error?.message}
                  />
                );
              }}
              rules={{
                required: 'Subscription Frequency is required',
              }}
            />

            <Controller
              name="AuthPeriod"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, onChange, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Auth period"
                    className="w-full pt-2"
                    fixedSize="md"
                    placeholder="Enter Auth period"
                    ref={ref}
                    onChange={e =>
                      onChange(
                        e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*?)\..*/g, '$1'),
                      )
                    }
                  />
                );
              }}
            />
            <Controller
              name="AuthPeriodUnit"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({ field: { ...rest }, fieldState: { error } }) => {
                return (
                  <Select
                    {...rest}
                    error={error?.message}
                    className="w-full pt-2"
                    label="Auth period unit"
                    options={auth_period_unit}
                    placeholder="Select Unit"
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default ChangeSubscriptionValue;
