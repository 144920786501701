import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import './style.scss';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import storeFrontService from 'services/storeFrontService';
import { IStoreFront } from 'interface/settingsInterface';
import { get } from 'lodash';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AddStore, {
  membershipTypeOptions,
  themeOptions,
} from 'components/Actions/addStoreFront';
import { platform } from 'components/settings/constant';
import { getShopifyeDetailsPath, Path } from 'router/constant';
import { Link, matchPath } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { setStoreFrontDetails } from 'redux-setup/slices/shopifySlice';
import { useDispatch } from 'react-redux';
import { setStore } from 'redux-setup/slices/StoreIdSlice';
export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IStoreFront) => ReactNode;
  hide?: boolean;
}
type SecretKey = 'AccessToken' | 'APISecret' | 'StorefrontPublicAccessToken';

interface ISeceretKeyVisible {
  [key: string]: boolean;
}

// const defaultSeceretKeyVisible: ISeceretKeyVisible = {
//   AccessToken: false,
//   APISecret: false,
//   StorefrontPublicAccessToken: false,
// };
interface ShopifyProps {
  storeId: string;
}

export default function Shopify({ storeId }: ShopifyProps) {
  const dispatch = useDispatch();
  const [seceretKeyVisible, setSeceretKeyVisible] = useState<
    Map<string, ISeceretKeyVisible>
  >(new Map());

  const handleSeceretKeyVisiblity = (key: SecretKey, id: string) => () => {
    setSeceretKeyVisible(prevState => {
      const newState = new Map(prevState);
      const currentVisibility = newState.get(id) || {};
      newState.set(id, {
        ...currentVisibility,
        [key]: !currentVisibility[key],
      });
      return newState;
    });
  };
  const [loading, setLoading] = useState<boolean>(false);
  const { storeIds } = useAppSelector(state => state.storeIds);
  const [storeFronts, setStoreFronts] = useState<IStoreFront[]>([]);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const defaultStoreFront: IStoreFront = {
    Name: '',
    Platform: platform,
    ShopID: '',
    ID: '',
    Version: 0,
    ShopURL: '',
    Shopify: {
      ID: '',
      StorefrontID: '',
      Version: 0,
      AccessToken: '',
      APIKey: '',
      MembershipType: membershipTypeOptions[0].value,
      APISecret: '',
      ShopID: '',
      StorefrontPublicAccessToken: '',
      ThemeSelector: themeOptions[0].value,
    },
    StoreID: storeId ? storeId : '',
  };
  const [selectedStore, setSelectedStore] =
    useState<IStoreFront>(defaultStoreFront);
  const [add, setAdd] = useState<boolean>(false);
  const dispach = useAppDispatch();

  const handleSelectedStore = (store: IStoreFront) => {
    setIsUpdate(true);
    const storefrontDetail = {
      ...store,
      Shopify: {
        ...store.Shopify,
        MembershipType:
          store.Shopify.MembershipType || membershipTypeOptions[0].value,
      },
    };
    setSelectedStore(storefrontDetail);
    dispach(setStoreFrontDetails(storefrontDetail));
  };

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Store Name',
      hide: false,
      cellRender: row => {
        return !matchPath(Path.SHOPIFY_STORE_DETAILS, location.pathname) ? (
          <Link
            to={getShopifyeDetailsPath(storeId, row.ID, row.ShopID, 1)}
            state={{ name: row.Name }}>
            <span className="text-as-link">{`${row?.Name}`}</span>
          </Link>
        ) : (
          <span className="text">{`${row?.Name}`}</span>
        );
      },
    },
    {
      id: 'CheckoutURL',
      label: 'URL',
      hide: false,
      cellRender: row => {
        return <>{row.ShopURL}</>;
      },
    },
    {
      id: 'StoreDescription',
      label: 'Shopify ID',
      hide: false,
      cellRender: row => {
        return <>{row.ShopID}</>;
      },
    },
    {
      id: 'StoreDescription',
      label: 'Shopify Api key',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="flex items-baseline gap-3">
              <p>
                {seceretKeyVisible.get(row.ID)?.APISecret
                  ? row?.Shopify?.APISecret
                  : row?.Shopify?.APISecret?.replace(/./g, '*')}
              </p>
              <div
                className="eye-icon"
                onClick={handleSeceretKeyVisiblity('APISecret', row.ID)}>
                {seceretKeyVisible.get(row.ID)?.APISecret ? (
                  <VisibilityOffOutlinedIcon className="!text-sm" />
                ) : (
                  <VisibilityOutlinedIcon className="!text-sm" />
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: 'StoreDescription',
      label: 'Shopify Access token',
      hide: false,
      cellRender: row => {
        return (
          <>
            <div className="flex items-baseline gap-3">
              <div>
                {seceretKeyVisible.get(row.ID)?.AccessToken
                  ? row?.Shopify?.AccessToken
                  : row?.Shopify?.AccessToken?.replace(/./g, '*')}
              </div>
              <div
                className="eye-icon"
                onClick={handleSeceretKeyVisiblity('AccessToken', row.ID)}>
                {seceretKeyVisible.get(row.ID)?.AccessToken ? (
                  <VisibilityOffOutlinedIcon className="!text-sm" />
                ) : (
                  <VisibilityOutlinedIcon className="!text-sm" />
                )}
              </div>
            </div>
          </>
        );
      },
    },
    {
      id: 'StoreDescription',
      label: 'Storefront Access Token',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex items-baseline gap-3">
            <div>
              {seceretKeyVisible.get(row.ID)?.StorefrontPublicAccessToken
                ? row?.Shopify?.StorefrontPublicAccessToken
                : row?.Shopify?.StorefrontPublicAccessToken?.replace(/./g, '*')}
            </div>
            <div
              className="eye-icon"
              onClick={handleSeceretKeyVisiblity(
                'StorefrontPublicAccessToken',
                row.ID,
              )}>
              {seceretKeyVisible.get(row.ID)?.StorefrontPublicAccessToken ? (
                <VisibilityOffOutlinedIcon className="!text-sm" />
              ) : (
                <VisibilityOutlinedIcon className="!text-sm" />
              )}
            </div>
          </div>
        );
      },
    },
    {
      id: 'Shopify.MemberShipType',
      label: 'MemberShip Type',
      hide: false,
      cellRender: row => {
        return (
          <>
            {row?.Shopify?.MembershipType
              ? membershipTypeOptions?.find(
                  item => item.value === row?.Shopify?.MembershipType,
                )?.label
              : '-'}
          </>
        );
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex gap-3 items-center justify-end">
            <button
              className="create_store"
              onClick={() => {
                handleSelectedStore(row);
              }}>
              <CreateIcon />
            </button>{' '}
          </div>
        );
      },
    },
  ];

  const getStoreFronts = async () => {
    setLoading(true);
    const res = await storeFrontService.getStoreFronts(
      storeId || storeIds?.[0]?.ID,
    );
    setLoading(false);
    if (res?.data?.Result?.length) {
      let storefront = [];
      if (
        storeIds?.length &&
        storeIds?.some(store =>
          store?.storeFronts?.some(value => value.StoreID === storeId),
        )
      ) {
        const storeFrontsMap = new Map<string, IStoreFront>();
        storeIds?.forEach(store => {
          store?.storeFronts?.forEach(value => {
            storeFrontsMap.set(value.ID, value);
          });
        });

        storefront = res?.data?.Result.filter((item: IStoreFront) =>
          storeFrontsMap.has(item?.ID),
        );
      } else {
        storefront = res?.data?.Result;
      }
      setStoreFronts(storefront);
    } else {
      setStoreFronts([]);
    }
  };

  useEffect(() => {
    getStoreFronts();
  }, [storeId, storeIds]);

  return (
    <>
      <div className="h-full">
        <div className="h-full">
          <TableContainer className="Common_Table settingsTable !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCellList.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className={
                          headCell.label == 'Actions' ? '!text-right' : ''
                        }
                        key={headCell.label}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>{' '}
              <TableBody className="table-body">
                {storeFronts?.length > 0 ? (
                  storeFronts?.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {headCellList.map(headCell => {
                          if (headCell.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.id}
                              scope="row">
                              <>
                                {headCell?.cellRender
                                  ? headCell.cellRender(row)
                                  : get(row, headCell.id)}
                              </>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} className="!h-40">
                      <div className="no-data-row">No data found</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={15}>
                    <button
                      className="sticky-button flex items-center"
                      onClick={() => setAdd(true)}>
                      <AddIcon /> Add Storefront
                    </button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Loader loading={loading} />
        {add && (
          <AddStore
            defaultStoreValues={defaultStoreFront}
            handleClose={() => setAdd(false)}
            isAdd={true}
            onSuccess={() => {
              getStoreFronts();
              setAdd(false);
            }}
          />
        )}
        {isUpdate && (
          <AddStore
            defaultStoreValues={selectedStore}
            handleClose={() => setIsUpdate(false)}
            isAdd={false}
            onSuccess={() => {
              getStoreFronts();
              dispatch(setStore([]));
              setIsUpdate(false);
            }}
          />
        )}
      </div>
    </>
  );
}
