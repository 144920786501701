import 'components/orders/orderDetails/style.scss';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import orderService from 'services/orderService';
import { Loader } from 'components/common/loader';
import { Cart as ICart } from 'shared-components/src/page-builder/interface/checkoutInterface';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';

const defaultAmount = '0.00';

export default function CartDetails() {
  const { channelID, cartID } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [cartDetails, setCartDetails] = useState<ICart>();

  /**
   * @description Get cart details by cart ID and channel ID
   * @param cartID Current cart ID
   * @param channelID Current channel ID
   */
  const getCartDetails = async (
    cartID: string = '',
    channelID: string = '',
  ) => {
    setLoading(true);
    const res = await orderService.getCartDetailsById(channelID, cartID);
    setCartDetails(res?.data);
    setLoading(false);
  };

  useEffect(() => {
    if (cartID && channelID) {
      getCartDetails(cartID, channelID);
    }
    return () => {
      setCartDetails({} as ICart);
    };
  }, [cartID, channelID]);

  const { subTotal, vipSavings } = useMemo(() => {
    let subTotal = 0; // sum of item's total amount
    let vipSavings = 0; //  each item's unit_amount and original_unit_amount
    if (cartDetails?.LineItems?.length) {
      cartDetails?.LineItems.forEach(item => {
        subTotal += Number(
          Number(item?.UnitPrice) * item.Quantity ||
            Number(item?.SalePrice) * item.Quantity,
        );
        if (item?.OriginalPrice) {
          vipSavings +=
            (Number(item?.OriginalPrice) -
              Number(item?.UnitPrice || item?.SalePrice)) *
            item?.Quantity;
        }
      });
    }
    return { subTotal: subTotal.toFixed(2), vipSavings: vipSavings.toFixed(2) };
  }, [cartDetails]);

  return (
    <div className="order-details-page">
      <div className="left-container">
        <div className="order-details-container">
          <div className="products-container">
            <div className="product-header">
              <p>Products</p>
            </div>
            <div className="products">
              {cartDetails?.LineItems &&
                cartDetails?.LineItems.map((item, key) => {
                  const totalSaving =
                    (Number(item?.OriginalPrice) -
                      Number(item?.UnitPrice || item?.SalePrice)) *
                    item?.Quantity;
                  return (
                    <>
                      <div className="item" key={key}>
                        <div className="product-wrap">
                          <img
                            src={item?.ImageURL || PlaceholderIcon}
                            alt="Product"
                            className="product-image"
                          />
                          <div className="item-content">
                            <p className="item-name">
                              {item ? item?.Name : ''}
                            </p>
                            <p className="qty-wrap">
                              Quantity: {item?.Quantity}&nbsp;
                              <CloseIcon fontSize="small" /> &nbsp;
                              <span>
                                $
                                {item?.UnitPrice ||
                                  item?.SalePrice ||
                                  defaultAmount}
                              </span>
                            </p>
                            <p className="price-varient">
                              {item.OriginalPrice ? (
                                <span>
                                  <span className="offer">Retail Price:</span>
                                  <span className="offer">
                                    ${item?.OriginalPrice || defaultAmount}
                                  </span>
                                </span>
                              ) : (
                                ''
                              )}
                              {totalSaving > 0 && (
                                <span>
                                  <span className="vip-price">
                                    Total Savings:
                                  </span>
                                  <span className="final-amt">
                                    ${totalSaving.toFixed(2)}
                                  </span>
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        <div>
                          $
                          {(
                            Number(item?.UnitPrice) * item?.Quantity ||
                            Number(item?.SalePrice) * item?.Quantity ||
                            0
                          ).toFixed(2) || defaultAmount}
                        </div>
                      </div>
                      {key !== cartDetails?.LineItems.length - 1 && (
                        <hr className="divider" />
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <div className="summary-content">
            <div className="summary-container">
              <div className="sum-header">Order Summary</div>
              <div className="sum-content">
                <p>
                  Subtotal <span>${subTotal || defaultAmount}</span>
                </p>
                {Number(vipSavings) > 0 && (
                  <p className="vip_savings">
                    VIP Savings
                    <span>
                      ${Math.abs(Number(vipSavings || defaultAmount))}
                    </span>
                  </p>
                )}
                <p className="total">
                  Total
                  <span>
                    ${cartDetails?.CostSummary?.TotalAmount || defaultAmount}
                  </span>
                </p>
                <p>
                  Paid{' '}
                  <span>
                    ${cartDetails?.CostSummary?.TotalAmount || defaultAmount}
                  </span>
                </p>
              </div>
            </div>
            {Number(vipSavings) > 0 && (
              <div className="saved-amt">
                Saved&nbsp;
                <span>${Math.abs(Number(vipSavings || defaultAmount))}</span>
                &nbsp;by using Subscription
              </div>
            )}
          </div>
        </div>
        <div className="customer-details-container">
          <div className="customer">
            <div className="cus-title">Customer</div>
            <div className="cus-body">
              <p>{cartDetails?.Email}</p>
              <p>{cartDetails?.PhoneNumber}</p>
            </div>
          </div>
          <div className="customer">
            <div className="cus-title">Shipping Address</div>
            <div className="cus-body">
              <p>
                {cartDetails?.ShippingAddress?.Address1} &nbsp;
                {cartDetails?.ShippingAddress?.Address2}
              </p>
              <p>
                {cartDetails?.ShippingAddress?.City} &nbsp;
                {cartDetails?.ShippingAddress?.Zip}
              </p>
              <p>{cartDetails?.ShippingAddress?.CountryCode || null}</p>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={!!loading} />
    </div>
  );
}
