export const loadGoogleAutoComplete = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const apiKey = process.env.REACT_APP_PHOENIX_GOOGLE_AUTO_API;
    if (!apiKey) {
      console.error('API key is undefined');
      reject(new Error('API key for Google Maps is undefined'));
      return;
    }
    if (document.querySelector('script[data-google-maps-loader="true"]')) {
      console.log('Google Maps script already loaded');
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&v=weekly`;
    script.async = true;
    script.defer = true;
    script.setAttribute('data-google-maps-loader', 'true');
    script.onload = () => {
      console.log('Google Maps script loaded successfully');
      resolve();
    };
    script.onerror = () => {
      console.error('Failed to load the Google Maps JavaScript API script');
      reject(new Error('Failed to load the Google Maps JavaScript API script'));
    };
    document.head.appendChild(script);
  });
};
