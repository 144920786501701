import { useEffect, useCallback } from 'react';
import { setCheckoutErrorMessage } from '../../redux/slices/commonSlice';
import { useFormContext } from 'react-hook-form';
import { setApiLoading } from '../../redux/slices/checkoutSlice';
import { setAvailableShippingMethods } from '../../redux/slices/checkoutSlice';
import useDebounce from '../../hooks/useDebounce';
import checkoutService from '../../api/checkoutService';
import { ContactInformationFormType } from '../../../page-builder/interface/formInterface';
import { V2ShippingMethod } from '../../../page-builder/interface/checkoutInterface';
import { emailRegExp } from '../../../page-builder/utils/constants';
import { TextFieldControl } from '../form-fields/TextField';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';

const ContactForm = ({
  useAppDispatch,
  useAppSelector,
}: ReduxSelectorProps & ReduxDispatchProps) => {
  const dispatch = useAppDispatch();
  const {
    watch,
    formState: { errors },
  } = useFormContext<ContactInformationFormType>();

  const storeData = useAppSelector(state => state.store.storeData);
  const cartData = useAppSelector(state => state.cart.cartData);
  const { Email, PhoneNumber } = watch('CustomerData');
  const PhoneCode = watch('phone_code');

  const isCustomerDetailsValid = !!Email && !errors?.CustomerData?.Email;

  const updateCustomerDetails = useCallback(async () => {
    if (
      isCustomerDetailsValid &&
      cartData?.ID &&
      storeData?.ShopID &&
      storeData?.StoreID
    ) {
      const payload = {
        Email,
        PhoneNumber: PhoneNumber ? `${PhoneCode}${PhoneNumber}` : null,
        ID: cartData.ID,
        ShopID: storeData.ShopID,
        StoreID: storeData.StoreID,
      };

      dispatch(setApiLoading(true));

      try {
        const res = await checkoutService.setContactInfo(payload);
        dispatch(
          setAvailableShippingMethods(
            res.data.AvailableShippingMethods as [V2ShippingMethod],
          ),
        );
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setCheckoutErrorMessage(error.message));
          return;
        }

        dispatch(setCheckoutErrorMessage('An unknown error occurred'));
      }
    }
    dispatch(setApiLoading(false));
  }, [
    dispatch,
    isCustomerDetailsValid,
    Email,
    PhoneNumber,
    PhoneCode,
    cartData?.ID,
    storeData?.ShopID,
    storeData?.StoreID,
  ]);

  const updateCustomerDetailsDebounce = useDebounce(updateCustomerDetails, 500);

  useEffect(() => {
    updateCustomerDetailsDebounce();
  }, [
    isCustomerDetailsValid,
    Email,
    errors.CustomerData?.Email,
    updateCustomerDetailsDebounce,
  ]);
  return (
    <TextFieldControl
      name="CustomerData.Email"
      placeholder="Email"
      rules={{
        required: 'Please enter your email address',
        pattern: {
          value: emailRegExp,
          message: 'Please enter a valid email address',
        },
      }}
    />
  );
};

export default ContactForm;
