import { ComponentConfig } from '@measured/puck';
import { Item } from '../../../page-builder/interface/checkoutInterface';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import { UpSellVariant } from './UpSellVariant';
import {
  ReduxDispatchProps,
  ReduxSelectorProps,
} from '../../services/constants';
import CustomTooltip from '../../../components/common/tooltip';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';

export type UpSellProps = MarginProps & showComponentProps;

export const UpSell = ({
  useAppSelector,
  useAppDispatch,
}: ReduxSelectorProps & ReduxDispatchProps): ComponentConfig<UpSellProps> => {
  return {
    label: (
      <CustomTooltip title="UpSell">
        <span>Up Sell</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      ...marginFields,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ margin, showComponent }) => {
      const preUpSellProducts = useAppSelector(
        state => state.preUpSell.preUpsellData,
      );
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      if (!preUpSellProducts || !preUpSellProducts.length) return <></>;
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={`overflow-hidden transition-all ease-in-out ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} `}>
              <ul className="flex flex-col gap-y-1">
                {preUpSellProducts.map((upSell: Item, index: number) => (
                  <div
                    key={index}
                    className="!rounded-lg !mb-4 !border-dashed border !border-black !shadow-lg">
                    <UpSellVariant
                      variant={upSell}
                      useAppSelector={useAppSelector}
                      useAppDispatch={useAppDispatch}
                    />
                  </div>
                ))}
              </ul>
            </div>
          )}
        </>
      );
    },
  };
};
