import React from 'react';
import { PopUpModal, FormFieldSelect } from 'shared-components';
import { trackerConfig } from './trackerConfig';
import { Tracker } from '../../../interface/pixelInterface';
import { Button } from 'shared-components';

export interface PixelModalProps {
  currentTracker: Tracker | null;
  pixelTrackers: Tracker[];
  setCurrentTracker: (_tracker: Tracker | null) => void;
  setError: (_error: string) => void;
  popup: boolean;
  setPopup: (_popup: boolean) => void;
  error: string;
  onChange: (_trackers: Tracker[]) => void;
  setPixelTrackers: (_trackers: Tracker[]) => void;
  setEditingTrackerId: (_id: string | null) => void;
}

export const PixelModal: React.FC<PixelModalProps> = ({
  currentTracker,
  pixelTrackers,
  setCurrentTracker,
  setError,
  popup,
  setPopup,
  error,
  onChange,
  setPixelTrackers,
  setEditingTrackerId,
}) => {
  const combineTrackers = (trackers: Tracker[]): Tracker[] => {
    const trackerMap: { [key: string]: Tracker } = {};

    trackers.forEach(tracker => {
      if (trackerMap[tracker.trackerType]) {
        const existingFields = trackerMap[tracker.trackerType].fields;
        const newFields = tracker.fields;

        for (const key in newFields) {
          if (existingFields[key]) {
            existingFields[key] = [
              ...new Set([...existingFields[key], ...newFields[key]]),
            ];
          } else {
            existingFields[key] = newFields[key];
          }
        }
      } else {
        trackerMap[tracker.trackerType] = { ...tracker };
      }
    });

    Object.values(trackerMap).forEach(tracker => {
      for (const key in tracker.fields) {
        tracker.fields[key] = [
          ...new Set(
            tracker.fields[key].flatMap(value =>
              value.split(',').map(v => v.trim()),
            ),
          ),
        ];
      }
    });

    return Object.values(trackerMap);
  };

  const handleTrackerTypeChange = (trackerType: string) => {
    if (currentTracker) {
      const existingTracker = pixelTrackers.find(
        tracker => tracker.trackerType === trackerType,
      );
      if (existingTracker) {
        setCurrentTracker(existingTracker);
      } else {
        setCurrentTracker({ ...currentTracker, trackerType });
      }
      setError('');
    }
  };

  const handleFieldChange = (fieldId: string, index: number, value: string) => {
    if (currentTracker) {
      const updatedFields = [...currentTracker.fields[fieldId]];
      updatedFields[index] = value;
      setCurrentTracker({
        ...currentTracker,
        fields: {
          ...currentTracker.fields,
          [fieldId]: updatedFields,
        },
      });
      setError('');
    }
  };

  const handleAddField = (fieldId: string) => {
    if (currentTracker) {
      setCurrentTracker({
        ...currentTracker,
        fields: {
          ...currentTracker.fields,
          [fieldId]: [...(currentTracker.fields[fieldId] || []), ''],
        },
      });
    }
  };

  const handleRemoveField = (fieldId: string, index: number) => {
    if (currentTracker) {
      const updatedFields = currentTracker.fields[fieldId].filter(
        (_, i) => i !== index,
      );
      setCurrentTracker({
        ...currentTracker,
        fields: {
          ...currentTracker.fields,
          [fieldId]: updatedFields,
        },
      });
    }
  };

  const handleSave = () => {
    if (!currentTracker?.trackerType) {
      setError('Please select a tracker type');
      return;
    }

    if (Object.keys(currentTracker.fields).length === 0) {
      setError('Please fill in all fields.');
      return;
    }

    for (const fieldId in currentTracker.fields) {
      if (
        Array.isArray(currentTracker.fields[fieldId]) &&
        (currentTracker.fields[fieldId].length === 0 ||
          currentTracker.fields[fieldId].some(value => value.trim() === ''))
      ) {
        setError('Please fill in all fields.');
        return;
      }
    }

    if (currentTracker) {
      const existingTrackerIndex = pixelTrackers.findIndex(
        tracker => tracker.trackerType === currentTracker.trackerType,
      );

      let updatedTrackers;
      if (existingTrackerIndex !== -1) {
        updatedTrackers = pixelTrackers.map((tracker, index) =>
          index === existingTrackerIndex ? currentTracker : tracker,
        );
      } else {
        updatedTrackers = [currentTracker, ...pixelTrackers];
      }

      const combinedTrackers = combineTrackers(updatedTrackers);
      combinedTrackers.sort((a, b) =>
        a.trackerType.localeCompare(b.trackerType),
      );

      setPixelTrackers(combinedTrackers);
      onChange(combinedTrackers);
      setEditingTrackerId(null);
      setCurrentTracker(null);
      setPopup(false);
    }
  };

  const closePopup = () => {
    setPopup(false);
    setCurrentTracker(null);
    setError('');
  };

  return (
    <>
      <PopUpModal
        heading="Pixel PopUp"
        open={popup}
        handleClose={closePopup}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Close',
            buttonFunction: closePopup,
          },
          {
            buttonType: 'positive',
            buttonLabel: 'Save',
            buttonFunction: handleSave,
          },
        ]}>
        {currentTracker && (
          <>
            <div className="w-full mt-4">
              <FormFieldSelect
                options={trackerConfig
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(tracker => ({
                    label: tracker.name,
                    value: tracker.name,
                  }))}
                defaultOption="Select a tracker"
                value={currentTracker.trackerType}
                onChange={e => handleTrackerTypeChange(e.target.value)}
              />
            </div>
            {trackerConfig
              .find(tracker => tracker.name === currentTracker.trackerType)
              ?.fields.map(field => (
                <div key={field.id} className="text-wrap">
                  {currentTracker.fields[field.id]?.map((value, index) => (
                    <div key={index} className="flex items-center mt-2">
                      <input
                        type={field.type}
                        value={value}
                        onChange={e =>
                          handleFieldChange(field.id, index, e.target.value)
                        }
                        className="border border-gray-300 rounded px-2 py-1 w-full text-wrap"
                        style={{
                          overflow: 'auto',
                          whiteSpace: 'normal',
                          textOverflow: 'ellipsis',
                          overflowWrap: 'break-word',
                        }}
                        placeholder={field.placeholder}
                      />
                      <Button
                        label="-"
                        type="button"
                        variant="phoenix_secondary"
                        className="flex justify-center items-center"
                        style={{
                          marginTop: 0,
                          height: '38px',
                          marginLeft: '8px',
                        }}
                        onClick={() => handleRemoveField(field.id, index)}
                      />
                    </div>
                  ))}

                  <small className="text-gray-500">
                    {' '}
                    {field.secondaryText}
                  </small>
                  <Button
                    label={`Add ${field.placeholder}`}
                    type="button"
                    variant="phoenix_secondary"
                    className="mt-2 text-blue-500"
                    onClick={() => handleAddField(field.id)}
                  />
                </div>
              ))}
            {error && <div className="text-red-500 text-sm">{error}</div>}
          </>
        )}
      </PopUpModal>
    </>
  );
};
