export const CloseIcon = ({
  height = '21',
  width = '20',
}: {
  height?: string;
  width?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9995 13.1525L16.6283 19.7813C16.98 20.133 17.4571 20.3306 17.9545 20.3306C18.4519 20.3306 18.929 20.133 19.2808 19.7813C19.6325 19.4295 19.8301 18.9525 19.8301 18.455C19.8301 17.9576 19.6325 17.4805 19.2808 17.1288L12.6495 10.5L19.2795 3.87127C19.4536 3.6971 19.5917 3.49035 19.6858 3.26282C19.78 3.0353 19.8285 2.79145 19.8284 2.5452C19.8284 2.29895 19.7798 2.05512 19.6855 1.82764C19.5912 1.60016 19.453 1.39347 19.2789 1.21939C19.1047 1.04531 18.898 0.907233 18.6704 0.813051C18.4429 0.718869 18.1991 0.670424 17.9528 0.670482C17.7066 0.67054 17.4627 0.7191 17.2353 0.813389C17.0078 0.907678 16.8011 1.04585 16.627 1.22002L9.9995 7.84877L3.37075 1.22002C3.19788 1.04085 2.99105 0.897917 2.76234 0.799544C2.53363 0.701171 2.28761 0.649331 2.03865 0.647051C1.7897 0.644771 1.54278 0.692096 1.3123 0.786264C1.08183 0.880432 0.872415 1.01956 0.696284 1.19552C0.520153 1.37149 0.38083 1.58077 0.286445 1.81115C0.19206 2.04154 0.144502 2.28841 0.146548 2.53738C0.148593 2.78634 0.2002 3.0324 0.298357 3.2612C0.396515 3.49 0.539257 3.69697 0.718255 3.87002L7.3495 10.5L0.719506 17.13C0.540507 17.3031 0.397765 17.51 0.299608 17.7388C0.20145 17.9676 0.149843 18.2137 0.147798 18.4627C0.145752 18.7116 0.19331 18.9585 0.287695 19.1889C0.38208 19.4193 0.521403 19.6285 0.697534 19.8045C0.873665 19.9805 1.08308 20.1196 1.31355 20.2138C1.54402 20.3079 1.79095 20.3553 2.0399 20.353C2.28886 20.3507 2.53488 20.2989 2.76359 20.2005C2.9923 20.1021 3.19913 19.9592 3.372 19.78L9.9995 13.1525Z"
        fill="currentColor"
      />
    </svg>
  );
};
