import React from 'react';
import { SketchPicker } from 'react-color';
import CustomTooltip from '../../../components/common/tooltip';
import { FieldLabel } from '@measured/puck';

interface ErrorColorProps {
  value: string;
  onChange: (_value: string) => void;
}

const ErrorColor: React.FC<ErrorColorProps> = ({ value, onChange }) => {
  return (
    <FieldLabel
      label={
        (
          <CustomTooltip title={'Error Color'}>
            <span>Error Color</span>
          </CustomTooltip>
        ) as React.ReactNode as string
      }>
      <SketchPicker
        color={value}
        onChange={(color: { hex: string }) => onChange(color?.hex)}
      />
    </FieldLabel>
  );
};

export default ErrorColor;
