import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PopOver from 'components/common/popOver';
import React, { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PlaceholderIcon from 'assets/icons/placeholder_image.svg';
import 'components/abandonedCarts/cart/style.scss';
import { Cart as ICart } from 'shared-components/src/page-builder/interface/checkoutInterface';

const defaultAmount = '0.00';

interface IProps {
  row: ICart;
}
const Cart: React.FC<IProps> = ({ row }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const subTotal = useMemo(() => {
    let subTotal = 0;
    if (row?.LineItems?.length) {
      row?.LineItems.forEach(item => {
        subTotal += Number(item?.UnitPrice || item?.SalePrice) * item?.Quantity;
      });
    }
    return subTotal;
  }, [row]);

  return (
    <>
      <div className="order-cart">
        {row.LineItems?.length}
        <button onClick={e => handleOpenPopover(e)}>
          <KeyboardArrowDownIcon />
        </button>
      </div>
      <PopOver
        child={
          <div>
            <div className="cart-items">
              <div className="cart-title">Cart Items</div>
              <div className="carts-list">
                {row?.LineItems?.length > 0 &&
                  row.LineItems.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <div className="product-wrap">
                          <div>
                            <img
                              src={item?.ImageURL || PlaceholderIcon}
                              alt="Product"
                              className="product-image"
                            />
                          </div>
                          <div className="item-content">
                            <p className="item-name">{item?.Name}</p>
                            <p className="qty-wrap">
                              Quantity: {item?.Quantity}&nbsp;
                              <CloseIcon fontSize="small" /> &nbsp;
                              <span>
                                $
                                {item?.UnitPrice ||
                                  item?.SalePrice ||
                                  defaultAmount}
                              </span>
                              <div className="total-product-amount">
                                $
                                {Number(item?.UnitPrice) * item?.Quantity ||
                                  Number(item?.SalePrice) * item?.Quantity ||
                                  defaultAmount}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="summary-content">
                <div className="summary-container">
                  <div className="sum-content">
                    <p>
                      Total <span>${subTotal || defaultAmount}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        onClose={handleClosePopover}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default Cart;
