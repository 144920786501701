import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import './style.scss';
import React from 'react';
import Button from '../button';
import { CloseIcon } from '../../../assets/icons/close-icon';

interface Props {
  children: React.ReactNode;
  heading: string;
  open: boolean;
  handleClose?: () => void;
  className?: string;
  buttons:
    | {
        buttonLabel: string;
        buttonType: 'positive' | 'negative';
        buttonFunction?: () => void;
        disabled?: boolean;
        [_: string]: unknown;
      }[]
    | [];
}
export default function PopUpModal({
  open,
  handleClose,
  heading,
  children,
  buttons = [],
  className = '',
}: Props) {
  const childrenArray = React.Children.toArray(children);
  return (
    <div>
      <Modal
        className="custom_modal"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className="content-style">
          <div className="model-header popup">
            <span className="heading">{heading}</span>
            {handleClose && (
              <div onClick={handleClose} className="cursor-pointer">
                <CloseIcon />
              </div>
            )}
          </div>
          <div className={`model-content ${className}`} id="scrollableDiv">
            {childrenArray.map(val => val)}
          </div>
          <div className="model-footer">
            <div className="bottom">
              {buttons?.map((button, index) => {
                return (
                  <Button
                    key={index}
                    onClick={button?.buttonFunction}
                    label={button?.buttonLabel}
                    disabled={button?.disabled}
                    className={`button_1 ${button?.buttonType}`}
                    {...button}></Button>
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
