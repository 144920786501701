import { InternalAxiosRequestConfig } from 'axios';
import { refreshAccessToken, setTokensInStorage } from 'services/authService';
import { publicEndpoints } from 'services/constants';
import { listMyPermissions } from 'utils/helper';

export const decodeToken = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    const parsedPayload = JSON.parse(jsonPayload);
    return { expiresAt: parsedPayload.exp * 1000 ?? 0 }; // the epoch is in seconds, so converting it to milliseconds.
  } catch (e) {
    return {
      expiresAt: 0,
    };
  }
};

export const decodeUser = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    const parsedPayload = JSON.parse(jsonPayload);
    return parsedPayload;
  } catch (e) {
    return {};
  }
};

let pendingRefresh = false;

export const fetchNewAccessToken = async (refresh_token: string) => {
  if (!pendingRefresh) {
    pendingRefresh = true;
    const response = await refreshAccessToken(refresh_token);
    pendingRefresh = false;
    if (!response.data?.AccessToken || response.status !== 200) {
      return '';
    }
    setTokensInStorage(response.data);
    listMyPermissions();
    return response.data?.AccessToken;
  }

  let sleepSetTimeout_ctrl: ReturnType<typeof setTimeout>;

  function sleep(ms: number) {
    clearTimeout(sleepSetTimeout_ctrl);
    return new Promise(
      resolve => (sleepSetTimeout_ctrl = setTimeout(resolve, ms)),
    );
  }

  while (pendingRefresh) {
    // wait for token to be set
    await sleep(10); // sleep 10 milliseconds
  }
  return localStorage.getItem('access_token');
};

export const authRequired = (config: InternalAxiosRequestConfig) => {
  if (publicEndpoints.includes(config.url as string)) {
    return false;
  }
  return true;
};

export const expiresWithin = (token: string, milliseconds: number) => {
  const decodedToken = decodeToken(token);
  const { expiresAt } = decodedToken;
  return expiresAt < Date.now() + milliseconds;
};
