import { AxiosError } from 'axios';
import { baseService } from 'services';
import { ISuccessResponse } from 'interface/apiInterface';
import { subscriptionHistoryMockData } from 'utils/mockData';
import {
  commonError,
  getSubscriptionsHistoryEP,
  subscriptionEventsEP,
} from 'services/constants';
import {
  ISubscriptionEventsPayload,
  ISubscriptionEventsResponse,
  ISubscriptionHistory,
} from 'interface/subscriptionInterface';

class SubscriptionMockService {
  getSubscriptionsHistory = async () => {
    try {
      return await new Promise<ISuccessResponse<ISubscriptionHistory>>(
        resolve => {
          setTimeout(() => {
            resolve({
              data: subscriptionHistoryMockData,
              status: 200,
              message: 'success',
            });
          }, 1000);
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getSubscriptionsEvents = async (payload: ISubscriptionEventsPayload) => {
    try {
      const response = await baseService.post<ISubscriptionEventsResponse>(
        subscriptionEventsEP,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class SubscriptionService {
  SubscriptionMockService = new SubscriptionMockService();

  getSubscriptionsHistory = async (
    store_id: string,
    subscription_id: string,
  ) => {
    try {
      const response = await baseService.get<ISubscriptionHistory>(
        getSubscriptionsHistoryEP(store_id, subscription_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getSubscriptionsEvents = async (payload: ISubscriptionEventsPayload) => {
    try {
      const response = await baseService.post<ISubscriptionEventsResponse>(
        subscriptionEventsEP,
        payload,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalSubscriptionService: SubscriptionService | SubscriptionMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new SubscriptionMockService()
    : new SubscriptionService();

export default globalSubscriptionService;
