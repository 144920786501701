import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from '@mui/material';
import React, { ReactNode, useCallback, useRef } from 'react';
import { get } from 'lodash';
import AddIcon from '@mui/icons-material/Add';

export interface HeadCell<T> {
  id: string;
  label: string;
  cellRender?: (_row: T) => ReactNode;
  hide?: boolean;
}

interface ICommonTableProps<T> {
  headCellList: HeadCell<T>[];
  data: T[];
  isNextAPIDisabled: boolean;
  onEndReached?: () => void;
  onAdd: () => void;
  addLabelName: string;
}
export default function CommonTable<T>({
  headCellList,
  isNextAPIDisabled,
  data,
  onEndReached,
  onAdd,
  addLabelName,
}: ICommonTableProps<T>) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLTableRowElement) => {
      if (observerRef?.current) observerRef.current?.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0]?.isIntersecting && !isNextAPIDisabled) {
          onEndReached && onEndReached();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isNextAPIDisabled],
  );

  return (
    <div className="h-full relative">
      <div className="h-full">
        <TableContainer className="Common_Table !h-fit max-h-full ">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell key={headCell?.label}>
                      {headCell?.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      ref={index === data.length - 1 ? lastElementRef : null}>
                      {headCellList.map(headCell => {
                        if (headCell?.hide) {
                          return null;
                        }
                        return (
                          <TableCell
                            className="table_cell"
                            key={headCell?.label}
                            component="th"
                            id={headCell.id}
                            scope="row">
                            <>
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="!py-20" colSpan={15}>
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <button
                    className="sticky-button"
                    onClick={() => {
                      onAdd();
                    }}>
                    <AddIcon />
                    {addLabelName}
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
