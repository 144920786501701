import { useMemo, FC } from 'react';
import { cardBrand } from 'components/dashboard/constant';
import { Loader } from 'shared-components';
import { LifeTimetransactionStats } from 'interface/dashboardNewIterface';

interface IProps {
  loading: boolean;
  aggregatedTransactionStats: LifeTimetransactionStats[];
}
const ChargeBackSummary: FC<IProps> = ({
  loading,
  aggregatedTransactionStats,
}) => {
  const chargebackSummary = useMemo(() => {
    const chargeOption = aggregatedTransactionStats?.reduce(
      (prev, curr) => {
        prev.Total += curr?.Total;
        if (curr?.CardBrand === cardBrand.master) {
          prev.mcApprovedTransactions += curr.Approved;
          prev.mcChargebacks += curr.Chargebacks;
        }
        if (curr?.CardBrand === cardBrand.visa) {
          prev.visaApprovedTransactions += curr.Approved;
          prev.visaChargebacks += curr.Chargebacks;
        }
        if (cardBrand.discover.includes(curr?.CardBrand)) {
          prev.discoverApprovedTransactions += curr.Approved;
          prev.discoverChargebacks += curr.Chargebacks;
        }
        if (curr?.CardBrand === cardBrand.amex) {
          prev.amexApprovedTransactions += curr.Approved;
          prev.amexChargebacks += curr.Chargebacks;
        }
        prev.overallApprovedTransactions += curr.Approved;
        prev.overallChargebacks += curr.Chargebacks;
        return prev;
      },
      {
        overallApprovedTransactions: 0,
        overallChargebacks: 0,
        visaApprovedTransactions: 0,
        visaChargebacks: 0,
        mcApprovedTransactions: 0,
        mcChargebacks: 0,
        discoverApprovedTransactions: 0,
        discoverChargebacks: 0,
        amexApprovedTransactions: 0,
        amexChargebacks: 0,
        Total: 0,
        mcChargebackRatio: '0',
        visaChargebackRatio: '0',
        discoverChargebackRatio: '0',
        amexChargebackRatio: '0',
        overallChargebackRatio: '0',
      },
    );
    chargeOption.mcChargebackRatio =
      chargeOption.mcChargebacks && chargeOption.mcApprovedTransactions
        ? (
            (chargeOption.mcChargebacks * 100) /
            chargeOption.mcApprovedTransactions
          ).toFixed(2)
        : '0';
    chargeOption.overallChargebackRatio =
      chargeOption.overallChargebacks &&
      chargeOption.overallApprovedTransactions
        ? (
            (chargeOption.overallChargebacks * 100) /
            chargeOption.overallApprovedTransactions
          ).toFixed(2)
        : '0';
    chargeOption.visaChargebackRatio =
      chargeOption.visaChargebacks && chargeOption.visaApprovedTransactions
        ? (
            (chargeOption.visaChargebacks * 100) /
            chargeOption.visaApprovedTransactions
          ).toFixed(2)
        : '0';
    chargeOption.discoverChargebackRatio =
      chargeOption.discoverChargebacks &&
      chargeOption.discoverApprovedTransactions
        ? (
            (chargeOption.discoverChargebacks * 100) /
            chargeOption.discoverApprovedTransactions
          ).toFixed(2)
        : '0';
    chargeOption.amexChargebackRatio =
      chargeOption.amexChargebacks && chargeOption.amexApprovedTransactions
        ? (
            (chargeOption.amexChargebacks * 100) /
            chargeOption.amexApprovedTransactions
          ).toFixed(2)
        : '0';
    return chargeOption;
  }, [aggregatedTransactionStats]);

  return (
    <div className="md_card_container">
      <div className="header">
        <p className="header_text extend_sm">Chargeback Summary</p>
      </div>
      <div className="chargeback_summary">
        <div className="content_wrapper">
          <p className="label">Overall Approved Transactions</p>
          <p className="value">
            {chargebackSummary?.overallApprovedTransactions}
          </p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Overall Chargebacks</p>
          <p className="value">{chargebackSummary?.overallChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Overall Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.overallChargebackRatio}
            {chargebackSummary?.overallChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">VISA Approved Transactions</p>
          <p className="value">{chargebackSummary?.visaApprovedTransactions}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">VISA Chargebacks</p>
          <p className="value">{chargebackSummary?.visaChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">VISA Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.visaChargebackRatio}
            {chargebackSummary?.visaChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">MC Approved Transactions</p>
          <p className="value">{chargebackSummary?.mcApprovedTransactions}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">MC Chargebacks</p>
          <p className="value">{chargebackSummary?.mcChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">MC Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.mcChargebackRatio}
            {chargebackSummary?.mcChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">Discover Approved Transactions</p>
          <p className="value">
            {chargebackSummary?.discoverApprovedTransactions}
          </p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Discover Chargebacks</p>
          <p className="value">{chargebackSummary?.discoverChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Discover Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.discoverChargebackRatio}
            {chargebackSummary?.discoverChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
        <hr className="divider" />
        <div className="content_wrapper">
          <p className="label">Amex Approved Transactions</p>
          <p className="value">{chargebackSummary?.amexApprovedTransactions}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Amex Chargebacks</p>
          <p className="value">{chargebackSummary?.amexChargebacks}</p>
        </div>
        <hr className="divider faded" />
        <div className="content_wrapper">
          <p className="label">Amex Chargeback Ratio</p>
          <p className="value">
            {chargebackSummary?.amexChargebackRatio}
            {chargebackSummary?.amexChargebackRatio !== '0' ? '%' : ''}
          </p>
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default ChargeBackSummary;
