import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  IStoreOverview,
  IStoreOverviewFilter,
  IStoreOverviewResponse,
  IStoreSideFilter,
} from 'interface/storeOverview';
import { get } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { Loader } from 'components/common/loader';
import dayjs, { Dayjs } from 'dayjs';
import { defaultStoreFrontEventValues } from 'components/storeFrontEvents';
import { useAppSelector } from 'hooks/reduxHooks';
import globalStoreFrontService from 'services/storeFrontService';
import { useSearchParams } from 'react-router-dom';
import {
  demicalFormatter,
  getTotalPages,
  setPaginationCommon,
  UsDollarFormatter,
} from 'utils/helper';
import './style.scss';
import PaginationCommon from 'components/common/PaginationCommon';
import CustomDatePicker from 'components/common/customDatePicker';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IStoreOverviewResponse) => ReactNode;
  hide?: boolean;
  key: string;
  showInSearch?: boolean;
  showSortIcon?: boolean;
  inputType?: string;
  searchFiedtType?: 'input' | 'select';
  searchFieldOptions?: { label: string; value: string }[];
}
export interface SearchProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface IOverviewFilter {
  start_time: Dayjs;
  end_time: Dayjs;
  date_range: string;
  start_month: Dayjs;
  end_month: Dayjs;
}

export default function StoreOverview() {
  const rowsPerPage = 25;
  const [searchParams, setSearchParams] = useSearchParams();
  const { timeZone } = useAppSelector(state => state.pathConfig);

  const { storeIds } = useAppSelector(state => state.storeIds);
  const storefrontIds = useAppSelector(state => {
    const arr: string[] = [];
    state.storeIds?.storeIds?.forEach(val => {
      val?.storeFronts?.map(val => {
        arr.push(val?.ID);
      });
    });
    return arr;
  });
  const [storeOverview, setStoreOverview] = useState<IStoreOverviewResponse[]>(
    [],
  );

  const [page] = useState<number>(Number(searchParams.get('pageCount') || 1));

  const [sideFormFilter] = useState<IStoreSideFilter>({
    StartTime: searchParams.get('StartTime')
      ? dayjs(searchParams.get('StartTime')).tz()
      : defaultStoreFrontEventValues.StartTime,
    EndTime: searchParams.get('EndTime')
      ? dayjs(searchParams.get('EndTime')).tz()
      : defaultStoreFrontEventValues.EndTime,
  });

  const handleFilter = (filterProptery: keyof typeof fil, value: unknown) => {
    setFil(pre => ({ ...pre, [filterProptery]: value }));
  };

  const [fil, setFil] = useState<IOverviewFilter>({
    start_time: searchParams.get('start_time')
      ? dayjs(searchParams.get('start_time')).tz()
      : dayjs().tz().startOf('day'),
    end_time: searchParams.get('end_time')
      ? dayjs(searchParams.get('end_time')).tz()
      : dayjs().tz().startOf('day'),
    date_range: searchParams.get('date_range') || 'Today',
    end_month: dayjs().tz().endOf('month'),
    start_month: dayjs().tz().startOf('month'),
  });

  const [loading, setLoading] = useState<number>(0);

  const [selected] = useState<Map<string, IStoreOverviewResponse>>(new Map());

  const [isLastPage, setIsLastPage] = useState(false);
  const [totalValueForPagination, setTotalValueForPagination] =
    useState<number>(4);
  const [limit, setLimit] = useState<number>(
    searchParams.get('Limit')
      ? Number(searchParams.get('Limit')) || rowsPerPage
      : rowsPerPage,
  );
  const [isMoreData, setIsMoreData] = useState<boolean>(false);
  const [active, setActive] = useState<number>(1);

  const [paginationMap, setPaginationMap] = useState<
    Map<number, IStoreOverviewResponse[]>
  >(new Map());

  const [search] = useState<SearchProps>({});

  const [filter, setFilter] = useState<IStoreOverviewFilter>({
    Descending: searchParams.get('Descending') === 'false' ? false : true,
    Limit: limit,
    OrderBy: searchParams.get('OrderBy') || 'Name',
  });

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Store Name',
      key: 'Name',
      cellRender: row => {
        return row.Name ? row.Name : '-';
      },
      hide: false,
      showSortIcon: true,
      showInSearch: true,
    },
    {
      id: 'PurchaseCount',
      label: 'Purchase Count',
      key: 'PurchaseCount',
      cellRender: row => {
        return row.PurchaseCount !== null ? row.PurchaseCount : '-';
      },
      showSortIcon: true,
    },
    {
      id: 'SubscriptionLosses',
      label: 'Subscription Losses',
      key: 'SubscriptionLosses',
      cellRender: row => {
        return row.SubscriptionLosses !== null
          ? `$${demicalFormatter(UsDollarFormatter(row.SubscriptionLosses))}`
          : '-';
      },
      showSortIcon: true,
    },
    {
      id: 'SubscriptionRevenue',
      label: 'Subscription Revenue',
      key: 'SubscriptionRevenue',
      cellRender: row => {
        return row.SubscriptionRevenue !== null
          ? `$${demicalFormatter(UsDollarFormatter(row.SubscriptionRevenue))}`
          : '-';
      },
      showSortIcon: true,
    },
    {
      id: 'Channel.Name',
      label: 'Channel',
      key: 'Channel.Name',
      cellRender: row => {
        return row.Channel.Name ? row.Channel.Name : '-';
      },
      showSortIcon: true,
      showInSearch: true,
    },
  ];

  const searchStoreOverview = async (details?: {
    currentPage?: number;
    reset?: boolean;
    isPrevious?: boolean;
  }) => {
    let page = 0;

    if (details?.isPrevious) {
      const currentPage = details?.currentPage ?? 0;
      page =
        currentPage % totalValueForPagination === 0
          ? currentPage / totalValueForPagination - 1
          : Math.floor(currentPage / totalValueForPagination);
    } else {
      page = details?.currentPage
        ? Math.floor(details?.currentPage / totalValueForPagination)
        : 0;
    }
    const payload: IStoreOverview = {
      ...filter,
      Page: page,
      Limit: limit * totalValueForPagination,
    };
    if (storeIds?.length > 0) {
      payload.StoreIDs = storeIds.map(store => store.ID);
    }
    if (storefrontIds?.length > 0) {
      payload.StorefrontIDs = storefrontIds;
    }

    if (fil?.start_time) {
      payload.StartTime = dayjs(fil?.start_time).tz().startOf('day').format();
    }

    if (fil?.end_time) {
      payload.EndTime = dayjs(fil?.end_time)
        .tz()
        .add(1, 'day')
        .startOf('day')
        .format();
    }
    if (Object.values(search).length > 0) {
      payload.SearchFields = search;
    }

    setSearchParams(
      {
        descending: JSON.stringify(payload.Descending),
        orderBy: JSON.stringify(payload.OrderBy),
        pageCount: JSON.stringify(page),
        StartTime: sideFormFilter.StartTime
          ? sideFormFilter.StartTime.tz().format()
          : '',
        EndTime: sideFormFilter.EndTime
          ? sideFormFilter.EndTime.tz().format()
          : '',
      },
      { replace: true },
    );
    setLoading(pre => pre + 1);
    const res = await globalStoreFrontService.getStoreOverview(payload);
    setLoading(pre => pre - 1);
    if (res?.status === 200) {
      if (res?.data?.Result?.length > 0) {
        if (details?.reset ?? true) {
          const data = res?.data?.Result.slice(0, limit);
          setStoreOverview(data);
        }

        setPaginationCommon(
          res?.data?.Result,
          details?.currentPage || 1,
          details?.reset ?? true,
          limit,
          totalValueForPagination,
          setIsMoreData,
          setPaginationMap,
          setActive,
          setIsLastPage,
          page,
        );
      } else {
        setStoreOverview([]);
      }
    } else {
      setStoreOverview([]);
    }
  };

  const sortHandler = (orderBy: string) => {
    setFilter(pre => {
      return {
        ...pre,
        OrderBy: orderBy,
        Descending: pre.OrderBy === orderBy ? !pre.Descending : true,
      };
    });
  };

  const handlePaginationValueChange = (val: number) => {
    setLimit(val);
    setTotalValueForPagination(getTotalPages(val));
  };

  useEffect(() => {
    searchStoreOverview();
  }, [sideFormFilter, page, limit, storeIds, timeZone, filter, search, fil]);
  return (
    <div className="store_overview_container">
      <div className="Store_filter row-full">
        <div className="dual-date-container">
          <div className="common_label_text">Date Range</div>
          <CustomDatePicker
            selectedRange={{
              startDate: fil?.start_time,
              endDate: fil?.end_time,
            }}
            dateValues={range => {
              if (range.startDate && range.endDate) {
                handleFilter('start_time', range?.startDate);
                handleFilter('end_time', range?.endDate);
              }
            }}
            maxDate={dayjs().tz().toDate()}
          />
        </div>
      </div>
      <div className="store_overview_top_container">
        <TableContainer className="Common_Table store_overview_table_container ">
          <Table
            className="store_overview_table"
            aria-labelledby="tableTitle"
            stickyHeader>
            <TableHead className="table_header">
              <TableRow>
                {headCellList.map(headCell => {
                  if (headCell.hide) {
                    return null;
                  }
                  return (
                    <TableCell className="table_header_cell" key={headCell.key}>
                      {headCell?.showSortIcon ? (
                        <TableSortLabel
                          className="header_text"
                          active={filter.OrderBy === headCell.id}
                          direction={
                            filter?.OrderBy === headCell.id
                              ? filter.Descending
                                ? 'desc'
                                : 'asc'
                              : 'asc'
                          }
                          onClick={() => sortHandler(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody className="table_body">
              {storeOverview.length > 0 ? (
                storeOverview.map(row => {
                  const isItemSelected = !!selected.get(`${row.ID}`);
                  return (
                    <>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.ID}
                        aria-checked={isItemSelected}
                        selected={isItemSelected}>
                        {headCellList.map(headCell => {
                          if (headCell.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.id}
                              scope="row">
                              <>
                                {headCell?.cellRender
                                  ? headCell.cellRender(row)
                                  : get(row, headCell.id)}
                              </>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <div className="no-data-row">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationCommon
          isLastPage={isLastPage}
          setIsLastPage={setIsLastPage}
          loading={!!loading}
          active={active}
          setActive={setActive}
          isMoreData={isMoreData}
          totalValueForPagination={totalValueForPagination}
          limit={limit}
          paginationData={paginationMap}
          setPaginationData={setPaginationMap}
          searchApi={searchStoreOverview}
          setData={setStoreOverview}
          onRowsPerChange={val => {
            handlePaginationValueChange(val);
          }}
        />
        <Loader loading={!!loading} />
      </div>
    </div>
  );
}
