import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ReactComponent as ArrowIcon } from 'assets/icons/Vector.svg';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { Paper } from '@mui/material';
import 'components/common/multiSelect/style.scss';
import {
  useEffect,
  LegacyRef,
  useRef,
  RefObject,
  useCallback,
  useState,
  ReactNode,
} from 'react';
import { debounce } from 'lodash';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface IMultiSelectProps<T> {
  setPostionRef?: (_: number) => void;
  lastElementRef?: React.Ref<HTMLLIElement>;
  options: T[];
  value: T[];
  selectAll?: boolean;
  placeholder?: string;
  onChange: (_value: T[]) => void;
  renderTags?: (
    _value: Array<T>,
    _getTagProps: AutocompleteRenderGetTagProps,
  ) => ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onInputChange?: (_v: string) => void;
  inputFunction?: () => void;
  disabled?: boolean;
  error?: string;
  loading?: boolean;
  className?: string;
  label?: string;
  limitTags?: number;
  labelKey: keyof T;
  valueKey: keyof T;
  ref?: React.Ref<HTMLInputElement> | null;
  position?: number;
  rendererClassName?: string;
  onOpen?: () => void;
  onclick?: () => void;
  multiple?: boolean;
}

function MultiSelect<T>({
  selectAll = false,
  options,
  disabled = false,
  valueKey,
  renderTags,
  open,
  setOpen,
  placeholder = '',
  inputFunction,
  className = '',
  error = '',
  loading,
  setPostionRef,
  label = '',
  position,
  onInputChange,
  labelKey,
  limitTags = 3,
  value,
  onChange,
  ref,
  lastElementRef,
  rendererClassName,
}: IMultiSelectProps<T>) {
  const listElem = useRef<Element>();
  const [inputSearchValue, setInputValue] = useState('');
  const handleChangeWithDebounce = useCallback(
    debounce(e => {
      if (onInputChange) onInputChange(e);
    }, 500),
    [],
  );
  useEffect(() => {
    return () => {
      onChange([]);
    };
  }, []);

  useEffect(() => {
    if (
      position &&
      position > 0 &&
      listElem?.current &&
      listElem?.current?.scrollTop !== position
    ) {
      listElem.current.scrollTop = position;
    }
  }, [options]);

  return (
    <div className={`common_multi_select_container ${className}`}>
      {label && <p className="common_label_text">{label}</p>}
      <Autocomplete
        value={value}
        inputValue={inputSearchValue}
        onInputChange={(_, v, reason) => {
          if (reason !== 'reset') {
            setInputValue(v);
            handleChangeWithDebounce(v);
          }
        }}
        ListboxProps={{
          ref: listElem as RefObject<Element>,
          className: loading ? 'loading-scroll-bar' : '',
          onScroll: ({ currentTarget }) => {
            const scrollPosition =
              currentTarget.scrollTop + currentTarget.clientHeight;
            if (setPostionRef) {
              setPostionRef(scrollPosition);
            }
          },
        }}
        onChange={(_, val) => {
          if (selectAll) {
            if (val.some(x => x?.[valueKey] === 'all')) {
              if (options.length === value.length) onChange([]);
              else onChange(options);
              return;
            }
          }
          onChange(val);
        }}
        sx={{ border: 'none' }}
        renderTags={renderTags}
        onOpen={() => setOpen && setOpen(true)}
        onClose={() => setOpen && setOpen(false)}
        open={open}
        ref={ref}
        multiple
        disabled={disabled}
        popupIcon={<ArrowIcon />}
        renderOption={(props, option, { index }) => {
          return (
            <li
              {...props}
              ref={
                index === options.length - 1
                  ? (lastElementRef as LegacyRef<HTMLLIElement>)
                  : null
              }>
              <div>{option[labelKey] as string}</div>
            </li>
          );
        }}
        limitTags={limitTags}
        PaperComponent={({ children }) => (
          <Paper className={`dropDownBackdrop ${rendererClassName}`}>
            {children}
          </Paper>
        )}
        isOptionEqualToValue={(option, val) => {
          return option[valueKey] === val[valueKey];
        }}
        id="multiple-limit-tags"
        options={[
          ...(selectAll ? [{ [labelKey]: 'All', [valueKey]: 'all' } as T] : []),
          ...options,
        ]}
        getOptionLabel={option => option[labelKey] as string}
        renderInput={params => (
          <TextField
            placeholder={!value?.length ? placeholder : ''}
            onClick={event => {
              // Check if the click is on the input and not on a chip
              if ((event.target as HTMLElement).tagName === 'INPUT') {
                if (inputFunction && setPostionRef) {
                  inputFunction();
                  setPostionRef(0);
                }
              }
            }}
            className="text_field_input_multiselect"
            {...params}
          />
        )}
      />

      {error && <p className="common_error_text">{error}</p>}
    </div>
  );
}
// const MultiSelect = forwardRef(MultiSelectInternal);
export default MultiSelect;

interface IControlledMultiSelect<T>
  extends Omit<IMultiSelectProps<T>, 'value' | 'onChange' | 'ref'> {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledMultiSelect = <T,>({
  name,
  rules,
  ...rest
}: IControlledMultiSelect<T>) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, ref, onChange }, fieldState: { error } }) => {
        return (
          <MultiSelect<T>
            {...rest}
            value={value ? value : []}
            ref={ref}
            onChange={(newValue: T[]) => {
              onChange(newValue);
            }}
            error={error?.message}
          />
        );
      }}
    />
  );
};
