import { FC } from 'react';
import 'components/transactions/transactionFilter/columnFilter/style.scss';
import { Checkbox } from 'shared-components';
import { HeadCell } from 'components/transactions';

interface IProps {
  filterList: HeadCell[] | undefined;
  setSelectedColumns: (_string: string) => void;
}

const ColumnFilter: FC<IProps> = ({ filterList, setSelectedColumns }) => {
  return (
    <div className="column_filter_container">
      {filterList?.map((cols: HeadCell, index: number) => (
        <div
          className="column_filter_columns"
          key={index}
          onClick={() => setSelectedColumns(cols.label)}>
          <Checkbox checked={!cols.hide} />
          <p className="column_filter_names">{cols.label}</p>
        </div>
      ))}
    </div>
  );
};

export default ColumnFilter;
