// import MultiSelectSearch from 'components/common/multiSelectSearchFilter';
import AutoCompleteSelect from 'components/common/selectAutoComplete';
import {
  IMemberShipProductsDetails,
  IProductsOfStore,
} from 'interface/membershipOffersInterface';
import { debounce } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import membershipOfferService from 'services/membershipOfferService';
import { IPostUpsellResponse } from 'interface/upSellInterface';

interface IProps {
  shopId: string;
  storeId: string;
  onChange: (
    _: {
      label: string;
      value: string;
      amount?: string;
    } | null,
  ) => void;
  value: string;
  errorMessages?: string;
  addedPostUpsells?: IPostUpsellResponse[];
}

export const getProductsLabels = (products: IMemberShipProductsDetails[]) => {
  return products?.map(product => ({
    label: product.Title,
    value: product.ID,
    amount: product?.MaxOriginalPrice,
  }));
};

export default function ProductsSearch({
  shopId,
  storeId,
  onChange,
  errorMessages,
  value,
  addedPostUpsells,
}: IProps) {
  const position = useRef<number>();
  const [loading, setLoading] = useState(false);
  const previousName = useRef<string | null>(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [cursor, setCursor] = useState<string | null>(null);
  const [productsOptions, setProductsOptions] = useState<
    {
      label: string;
      value: string;
      amount?: string;
    }[]
  >([]);

  const observerRef = useRef<IntersectionObserver | null>(null);

  const getProducts = async (
    name?: string,
    isFromPagination?: boolean,
    isopen = false,
  ) => {
    setLoading(true);
    const payload: IProductsOfStore = {
      Limit: 50,
      ShopID: shopId,
      StoreID: storeId,
    };
    if (!isFromPagination) {
      position.current = 0;
    }

    if (!isopen && hasNextPage && cursor) {
      payload.Cursor = cursor;
    }
    if (name) {
      payload.Filter = name;
    }
    const res = await membershipOfferService.getAllProducts(payload);
    if (res?.status === 200 && res?.data?.Result?.length) {
      let result = res?.data?.Result;
      if (result?.length && addedPostUpsells?.length) {
        result = result.filter(
          (item: IMemberShipProductsDetails) =>
            !addedPostUpsells.some(upSell => upSell.ProductID === item.ID),
        );
      }
      setHasNextPage(res.data?.HasNextPage);
      setCursor(res.data?.EndCursor);
      if (!previousName.current && !name) {
        setProductsOptions(prev => [...prev, ...getProductsLabels(result)]);
      } else setProductsOptions([...getProductsLabels(result)]);
    }
    previousName.current = name || null;
    setLoading(false);
  };

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasNextPage) {
          getProducts('', true);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [cursor],
  );

  const handleChangeDropdownWithDebounce = useCallback(
    debounce(val => {
      getProducts(val);
    }, 500),
    [],
  );

  const setRef = (value: number) => {
    position.current = value;
  };

  return (
    <AutoCompleteSelect
      lastElementRef={lastElementRef}
      position={position.current}
      setPostionRef={value => setRef(value)}
      loading={loading}
      options={!loading ? productsOptions : []}
      labelKey="label"
      onChange={val => {
        onChange(val);
      }}
      onOpen={() => {
        getProducts('', false, true);
      }}
      onInputChange={val => {
        handleChangeDropdownWithDebounce(val);
      }}
      placeholder="Select products"
      value={productsOptions?.find(type => type.value === value) || null}
      className="w-full dropdown-field"
      label="Products"
      error={errorMessages}
    />
  );
}
