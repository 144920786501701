import { FieldLabel, Fields } from '@measured/puck';
import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomTooltip from '../../../components/common/tooltip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export type ColorSubProps = {
  backgroundColor: string;
  hoverColor: string;
  textColor: string;
};
export type ColorProps = {
  color: ColorSubProps;
};

export const colorField: Fields<ColorProps> = {
  color: {
    type: 'custom',
    label: 'Color',
    render: ({ value, onChange }) => {
      const [isOpen, setIsOpen] = useState(false);

      const toggleAccordion = () => {
        setIsOpen(!isOpen);
      };

      const handleColorChange = (colorKey: string, color: ColorResult) => {
        onChange({
          ...value,
          [colorKey]: color.hex,
        });
      };

      return (
        <div>
          <div
            className="flex justify-between font-extrabold cursor-pointer p-3 bg-slate-100 rounded-md pagebuilder-theme"
            onClick={toggleAccordion}>
            <h3>Customize Colors</h3>
            {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </div>
          <div className="flex flex-col gap-1  mt-3 items-center">
            {isOpen && (
              <div className="flex flex-col gap-4 w-full items-center border  border-gray-300 rounded p-2 pb-4">
                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="backgroundColor">
                        <span>background Color</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <SketchPicker
                    color={value.backgroundColor}
                    onChange={color =>
                      handleColorChange('backgroundColor', color)
                    }
                  />
                </FieldLabel>

                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="hoverColor">
                        <span>Hover Color</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <SketchPicker
                    color={value.hoverColor}
                    onChange={color => handleColorChange('hoverColor', color)}
                  />
                </FieldLabel>

                <FieldLabel
                  label={
                    (
                      <CustomTooltip title="textColor">
                        <span>Text Color</span>
                      </CustomTooltip>
                    ) as React.ReactNode as string
                  }>
                  <SketchPicker
                    color={value.textColor}
                    onChange={color => handleColorChange('textColor', color)}
                  />
                </FieldLabel>
              </div>
            )}
          </div>
        </div>
      );
    },
  },
};
