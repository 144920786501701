import React from 'react';
import { useLocation } from 'react-router-dom';

export const StagingBanner: React.FC = () => {
  const location = useLocation();

  if (
    process.env.REACT_APP_PUBLIC_SENTRY_ENVIRONMENT === 'production' ||
    location.pathname.includes('page-builder')
  ) {
    return null;
  }

  return (
    <div
      className="staging-banner"
      style={{
        padding: '10px',
        textAlign: 'center',
        backgroundColor: '#F80282',
      }}>
      * Staging Environment *
    </div>
  );
};
