// import { get } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { Loader } from 'components/common/loader';
import { getStores } from 'services/dashboardService';
import { IGetStores, IStore } from 'interface/storeInterface';
import CreateIcon from '@mui/icons-material/Create';
import './style.scss';
import { setStore } from 'redux-setup/slices/StoreIdSlice';
import AddStore from 'components/Actions/addStore';
import {
  getShopifyStoreDetailsPath,
  getStoreDetailsPath,
  Path,
} from 'router/constant';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import TableCommon from '../common/TableCommon';
import { useDispatch } from 'react-redux';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IStore) => ReactNode;
  hide?: boolean;
}

const defaultStore: IStore = {
  CheckoutURL: '',
  ID: '',
  Name: '',
  StoreDescription: '',
  Version: 0,
};
const defaultValue = {
  Descending: false,
  Limit: 25,
  OrderBy: 'Name',
  Page: 0,
};
export default function Store() {
  const storeIdfromRedux = useAppSelector(root => root.storeIds.storeIds);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStore, setSelectedStore] = useState<IStore>(defaultStore);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [stores, setStores] = useState<IStore[]>([]);
  const [storeSearch, setStoreSearch] = useState<IGetStores>(defaultValue);
  const [add, setAdd] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleNavigate = (ID: string) => {
    return matchPath(Path.SHOPIFY_SETTINGS_PAGE, location.pathname)
      ? getShopifyStoreDetailsPath(ID, 1)
      : getStoreDetailsPath(ID, 1);
  };

  const headCellList: HeadCell[] = [
    {
      id: 'Name',
      label: 'Store Name',
      hide: false,
      cellRender: row => {
        return (
          <>
            {' '}
            <Link to={handleNavigate(row?.ID)} state={{ name: row?.Name }}>
              <span className="text-as-link"> {`${row?.Name}`}</span>
            </Link>
          </>
        );
      },
    },
    {
      id: 'CheckoutURL',
      label: 'Checkout URL',
      hide: false,
      cellRender: row => {
        return <p className="max-w-sm text-wrap">{row?.CheckoutURL}</p>;
      },
    },
    {
      id: 'StoreDescription',
      label: 'Store Descriptions',
      hide: false,
      cellRender: row => {
        return <>{row?.StoreDescription}</>;
      },
    },
    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <div className="flex justify-end gap-3">
            <button
              onClick={() => {
                setIsUpdate(true);
                setSelectedStore(row);
              }}>
              <CreateIcon />
            </button>{' '}
          </div>
        );
      },
    },
  ];

  const searchStoreList = async () => {
    setLoading(true);
    if (storeSearch.Page === 0) setStores([]);
    const res = await getStores(storeSearch);
    setLoading(false);
    if (res.status !== 200) {
      setStores([]);
      return;
    }
    if (res?.data && res?.data?.Result) {
      setStores(prevData => [...prevData, ...res.data.Result]);
      if (!selectedStore?.ID) setSelectedStore(res?.data?.Result[0]);
      setIsNextAPIDisabled(res?.data?.Result?.length < storeSearch.Limit);
    } else if (!stores.length) setStores([]);
  };
  const [isNextAPIDisabled, setIsNextAPIDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (!storeIdfromRedux.length) searchStoreList();
    else {
      setStoreSearch(defaultValue);
      setStores(storeIdfromRedux);
    }
  }, [storeSearch, storeIdfromRedux]);

  return (
    <>
      <TableCommon<IStore>
        addLabelName="Add Store"
        isNextAPIDisabled={isNextAPIDisabled}
        headCellList={headCellList}
        data={stores}
        onEndReached={() => {
          setStoreSearch(prevPage => ({
            ...prevPage,
            Page: prevPage.Page + 1,
          }));
        }}
        onAdd={() => {
          setAdd(true);
        }}
      />
      <Loader loading={loading} />
      {add && (
        <AddStore
          defaultStoreValues={defaultStore}
          handleClose={() => setAdd(false)}
          onSuccess={() => {
            setStoreSearch({ ...defaultValue });
            setAdd(false);
          }}
          isAdd={add}
        />
      )}
      {isUpdate && (
        <AddStore
          defaultStoreValues={selectedStore}
          handleClose={() => setIsUpdate(false)}
          onSuccess={() => {
            setStoreSearch({ ...defaultValue });
            setIsUpdate(false);
            dispatch(setStore([]));
          }}
          isAdd={add}
        />
      )}
    </>
  );
}
