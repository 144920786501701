import { FC, useState } from 'react';
import MembershipOffers from './membershipOffersList';
import EditMembershipOffersComponent from './editMembershipOffers';

interface IProps {
  isSettingsFromPortal?: boolean;
}

const MembershipOffersComponent: FC<IProps> = ({ isSettingsFromPortal }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [id, setId] = useState<string>('');
  return isEdit ? (
    <EditMembershipOffersComponent
      setIsEdit={setIsEdit}
      id={id}
      isSettingsFromPortal={isSettingsFromPortal}
    />
  ) : (
    <MembershipOffers
      setIsEdit={setIsEdit}
      setId={setId}
      isSettingsFromPortal={isSettingsFromPortal}
    />
  );
};

export default MembershipOffersComponent;
