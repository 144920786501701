import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { get } from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import conversionTrackingService from 'services/conversionTracking';
import AddOrEditTracking, { trackingtTypes } from './addTrackingSetting';
import { Loader } from 'shared-components';
import { showNotification } from 'helper/common/commonFunctions';
import { ConfirmationDialog } from 'components/Actions/confirmationDialog';

export interface ICreateTrackingBody {
  ID?: string;
  ChannelID: string;
  Creds: {
    PixelID: string;
    AccessToken: string;
  };
  Type: string;
  Version?: number;
}

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: ICreateTrackingBody) => ReactNode;
  hide?: boolean;
}

const defaultData: ICreateTrackingBody = {
  ChannelID: '',
  Creds: {
    AccessToken: '',
    PixelID: '',
  },
  Type: 'meta',
  ID: '',
  Version: 0,
};

interface IProps {
  channelId: string;
  storeId: string;
  shopId: string;
}
const TrackingSetting: React.FC<IProps> = ({ channelId }) => {
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<ICreateTrackingBody>(defaultData);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const headCellList: HeadCell[] = [
    {
      id: 'type',
      label: 'Type',
      hide: false,
      cellRender: row => {
        return (
          <>{trackingtTypes?.find(value => value.value === row?.Type)?.label}</>
        );
      },
    },

    {
      id: 'PixelID',
      label: 'Pixel ID',
      hide: false,
      cellRender: row => {
        return <>{row?.Creds?.PixelID}</>;
      },
    },

    {
      id: 'action',
      label: 'Actions',
      hide: false,
      cellRender: row => {
        return (
          <span className="flex justify-center gap-3">
            <CreateIcon
              onClick={() => {
                setSelected(row);
                setIsPopUpOpen(true);
              }}
            />
            <DeleteIcon
              onClick={() => {
                setSelected(row);
                setIsDelete(true);
              }}
            />
          </span>
        );
      },
    },
  ];

  const [conversionTracking, setConversionTracking] = useState<
    ICreateTrackingBody[]
  >([]);
  const handleRemove = async (trackingId: string) => {
    setLoading(true);
    const res = await conversionTrackingService.deleteTracking(
      channelId,
      trackingId,
    );
    setLoading(false);
    if (res?.status === 200 || res?.status === 204) {
      showNotification('success', 'Deleted Successfully');
      getConversionTracking();
    }
    setIsDelete(false);
  };
  const getConversionTracking = async () => {
    setLoading(true);
    const response = await conversionTrackingService.getTracking(channelId);
    setLoading(false);
    if (response?.status === 200 && response?.data?.Result?.length) {
      setConversionTracking(response?.data?.Result);
    } else {
      setConversionTracking([]);
    }
  };

  useEffect(() => {
    getConversionTracking();
  }, []);

  return (
    <div className="h-full">
      <TableContainer className="Common_Table settingsTable !h-fit max-h-full">
        <Table aria-labelledby="tableTitle" className="table" stickyHeader>
          <TableHead>
            <TableRow>
              {headCellList.map(headCell => {
                if (headCell.hide) {
                  return null;
                }
                return (
                  <TableCell className="!text-center" key={headCell.label}>
                    {headCell.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <>
            <TableBody className="table-body">
              {conversionTracking?.length > 0 ? (
                conversionTracking?.map(
                  (row: ICreateTrackingBody, index: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {headCellList.map(headCell => {
                          if (headCell.hide) {
                            return null;
                          }
                          return (
                            <TableCell
                              key={headCell.label}
                              component="th"
                              id={headCell.id}
                              scope="row"
                              className="!text-center">
                              <>
                                {headCell?.cellRender
                                  ? headCell.cellRender(row)
                                  : get(row, headCell.id)}
                              </>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  },
                )
              ) : (
                <TableRow>
                  <TableCell className="!py-20" colSpan={15}>
                    <div className="no-data-row">No data found</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={15}>
                  <button
                    className="sticky-button flex items-center"
                    onClick={() => {
                      setSelected(defaultData);
                      setIsPopUpOpen(true);
                    }}>
                    <AddIcon /> Add Tracking
                  </button>
                </TableCell>
              </TableRow>
            </TableFooter>
          </>
        </Table>
      </TableContainer>
      <Loader loading={loading} />
      {isPopUpOpen && (
        <AddOrEditTracking
          channelId={channelId}
          selectedValue={selected}
          onSuccess={() => {
            getConversionTracking();
          }}
          handleClose={() => {
            setIsPopUpOpen(false);
          }}
        />
      )}

      {isDelete && (
        <ConfirmationDialog
          open={isDelete}
          handleClose={() => setIsDelete(false)}
          message="Delete"
          onSubmit={() => {
            if (selected?.ID) handleRemove(selected?.ID);
          }}
        />
      )}
    </div>
  );
};

export default TrackingSetting;
