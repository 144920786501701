import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Spinner } from 'components/common/loader';
import React, { useState } from 'react';
import 'components/subscriptionProjection/projectionNestedTable/style.scss';

interface ISubscriptionProjectionHistoryData {
  cycleName: string;
  activeSubscribers: string;
  subscriptionInSalvage: string;
  total: number;
}

const mockData: ISubscriptionProjectionHistoryData[] = [
  {
    cycleName: 'June Cycle',
    activeSubscribers: 'John Doe',
    subscriptionInSalvage: 'ORD12345',
    total: 250.0,
  },
  {
    cycleName: 'June Cycle',
    activeSubscribers: 'Jane Smith',
    subscriptionInSalvage: 'ORD12346',
    total: 150.0,
  },
  {
    cycleName: 'June Cycle',
    activeSubscribers: 'Emily Johnson',
    subscriptionInSalvage: 'ORD12347',
    total: 100.0,
  },
  {
    cycleName: 'June Cycle',
    activeSubscribers: 'Michael Brown',
    subscriptionInSalvage: 'ORD12348',
    total: 200.0,
  },
  {
    cycleName: 'June Cycle',
    activeSubscribers: 'Linda Davis',
    subscriptionInSalvage: 'ORD12349',
    total: 300.0,
  },
];

export default function ProjectionNestedTable() {
  const [loading] = useState<boolean>(false);
  return (
    <div className="nestedRow_projection">
      <Box sx={{ margin: 1 }}>
        <div className="transaction_container">
          <div className="transaction_top_container">
            {mockData ? (
              <TableContainer className="transactions_table_container">
                <Table
                  className="transactions_table"
                  aria-labelledby="tableTitle"
                  stickyHeader>
                  <TableHead className="table_header">
                    <TableRow>
                      <TableCell className="table_header_cell">
                        Cycle Name
                      </TableCell>
                      <TableCell className="table_header_cell">
                        Active Subscribers
                      </TableCell>
                      <TableCell className="table_header_cell">
                        Subscriber In Salvage
                      </TableCell>
                      <TableCell className="table_header_cell">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body">
                    {mockData?.map(history => (
                      <TableRow className="table_row" key={'date'}>
                        <TableCell
                          className="table_cell"
                          component="th"
                          scope="row">
                          <span>{history?.cycleName}</span>
                        </TableCell>
                        <TableCell className="table_cell">
                          {history?.activeSubscribers ?? '-'}
                        </TableCell>
                        <TableCell className="table_cell">
                          <span className="reason-column">
                            <span>{history?.subscriptionInSalvage}</span>
                          </span>
                        </TableCell>
                        <TableCell className="table_cell">
                          {history?.total}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="flex-container">No data</div>
            )}
          </div>
        </div>
        <Spinner loading={loading} />
      </Box>
    </div>
  );
}
