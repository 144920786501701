import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import { useForm } from 'react-hook-form';
import SingleSelectSearch from 'components/common/singleSelectSearch';

interface IProps {
  payload: ISubscriptionManagementUpdateBody | null;
  handleClose: () => void;
  onApiResponse: (_isSuccess: boolean) => void;
}

interface ISubscriptionStatus {
  Status: string;
}

const defaultSubscriptionActions = [
  {
    label: 'Paused',
    value: 'paused',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Canceled',
    value: 'canceled',
  },
  {
    label: 'Salvage',
    value: 'salvage',
  },
];

const ChangeSubscription: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, setValue, watch } = useForm<ISubscriptionStatus>({
    defaultValues: { Status: payload?.Status || '' },
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    defaultSubscriptionActions,
  );
  const [subscriptionValue, setSubscriptionValue] = useState(
    payload?.Status || '',
  );
  const [error, setError] = useState<string>('');

  const changeSubscription = async (data: ISubscriptionStatus) => {
    if (!payload) return;
    setLoading(true);
    payload.Status = data?.Status;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification('success', 'Subscription Status updated successfully');
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  const handleOptions = () => {
    const status = watch('Status');

    switch (status) {
      case 'active':
      case 'paused':
        return subscriptionStatus?.filter(data => data.value !== 'salvage');

      case 'canceled':
      case 'salvage':
        return subscriptionStatus?.filter(data => data.value !== 'paused');

      default:
        return subscriptionStatus;
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Confirm',
            buttonFunction: () => {
              if (!subscriptionValue) {
                setError('Please select Subscription Status');
                return;
              }
              setValue('Status', subscriptionValue);
              handleSubmit(changeSubscription)();
            },
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Change Subscription'}
        className="flex-container">
        <div className="popup-section">
          <form
            id="ChangeSubscription"
            onSubmit={handleSubmit(changeSubscription)}
            className="order-detail_refund">
            <SingleSelectSearch
              onInputChange={searchText => {
                setSubscriptionStatus(
                  defaultSubscriptionActions.filter(data =>
                    data.label.toLowerCase().includes(searchText.toLowerCase()),
                  ),
                );
              }}
              value={
                defaultSubscriptionActions?.find(
                  data => data.value === subscriptionValue,
                ) || {
                  label: '',
                  value: '',
                }
              }
              options={handleOptions()}
              labelKey="label"
              valueKey="value"
              label="Subscription Status"
              placeholder="Select Subscription Status"
              onChange={newValue => {
                setError('');
                setSubscriptionValue(newValue?.value || '');
              }}
              onClose={() => setSubscriptionStatus([])}
            />
            {error && <p className="common_error_text p-1">{error}</p>}
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default ChangeSubscription;
