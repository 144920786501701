import { FieldLabel, Fields } from '@measured/puck';
import { QuillEditor } from '../../../components/common/quill-editor/quill-editor';
import CustomTooltip from '../../../components/common/tooltip';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export type QuillTextEditorProps = {
  content: string;
};

export const QuillTextEditor = (type: string): Fields<QuillTextEditorProps> => {
  return {
    content: {
      type: 'custom',
      render: ({ value, onChange }) => {
        const [isOpen, setIsOpen] = useState(false);
        return (
          <div>
            <div
              className="flex justify-between font-extrabold cursor-pointer py-2 bg-slate-100 rounded-md pagebuilder-theme"
              onClick={() => setIsOpen(!isOpen)}>
              <h3>{type}</h3>
              {!isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </div>
            {isOpen && (
              <>
                <div className="flex flex-col gap-4 mt-3 items-center border border-gray-300 rounded p-2 w-full">
                  <FieldLabel
                    label={
                      (
                        <CustomTooltip title={'QuillEditor'}>
                          <span>Text</span>
                        </CustomTooltip>
                      ) as React.ReactNode as string
                    }
                    el="div">
                    <QuillEditor value={value} onChange={onChange} />
                  </FieldLabel>
                </div>
              </>
            )}
          </div>
        );
      },
    },
  };
};
