import { useLocation, useSearchParams } from 'react-router-dom';
import HomePage from '../pages/page/index';
import { useEffect, useState } from 'react';
import { Config, Data } from '@measured/puck';
import { PostUpsellConfig } from '../../../puck/post-upsell-puck-config';
import { config } from '../../../puck/puck.config';
import { baseService } from 'services';
import { FreeTemplateconfig } from '../../../puck/free-template-puck-config';
import { ThankYouConfig } from '../../../puck/thank-you-puck-config';
import { ConvertBase64ToJson } from 'shared-components';
import { globalPageService } from 'shared-components';
import lodash from 'lodash';
import { ICheckoutPage } from 'shared-components';
import { useUnsavedChangesWarning } from 'shared-components';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { setHasUnsavedChanges } from 'shared-components';
import { extractAndUseTrackers } from 'shared-components/src/page-builder/hooks/extractAndUseTrackers';

const Home = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const query = new URLSearchParams(location.search).get('template') || '';
  const [searchParams] = useSearchParams();
  const storeID = searchParams.get('storeID') || '';
  const pageID = searchParams.get('pageID') || '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkoutPage, setCheckoutPage] = useState<ICheckoutPage | null>(null);
  const [puckConfig, setPuckConfig] = useState<Config | null>();
  const [puckData, setPuckData] = useState<Data>();
  const { hasUnsavedChanges } = useAppSelector(state => state.checkout);
  useUnsavedChangesWarning(hasUnsavedChanges);

  const getPageDetails = async () => {
    setIsLoading(true);
    const res = await globalPageService.getPage(storeID, pageID, baseService);
    if (res?.status === 200 && res?.data) {
      setCheckoutPage(res?.data);
      dispatch(setHasUnsavedChanges(false));
      const configData = ConvertBase64ToJson(res?.data?.PageData);
      if (!lodash.isEmpty(configData)) {
        setPuckData(configData);
        let puckConfig;
        if (query === 'upsell') {
          puckConfig = PostUpsellConfig();
        } else if (query === 'checkout') {
          puckConfig = config();
        } else if (query === 'confirmation') {
          puckConfig = ThankYouConfig();
        } else if (query === 'other') {
          puckConfig = FreeTemplateconfig();
        }
        setPuckConfig(puckConfig as Config);
      }
    }
    setIsLoading(false);
  };
  const onSuccessCallBack = () => {
    getPageDetails();
  };

  useEffect(() => {
    getPageDetails();
  }, [pathname, searchParams]);

  extractAndUseTrackers(puckData?.root?.props?.pixelTracking);

  return (
    <>
      {!isLoading && puckConfig && puckData && (
        <HomePage
          config={puckConfig}
          data={puckData}
          checkoutPage={checkoutPage}
          onSuccessCallBack={onSuccessCallBack}
          pathname={pathname}
        />
      )}
    </>
  );
};

export default Home;
