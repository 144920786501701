import { AxiosError } from 'axios';
import {
  commonError,
  createPreUpSell,
  removePreUpSell,
  searchPostUpSell,
  searchPreUpSell,
  searchRiskEP,
  updatePreUpSell,
} from 'services/constants';
import { baseService } from 'services';
import {
  IPreUpRemove,
  IPreUpSellCreate,
  IPreUPSellResponse,
  IUpSellBody,
} from 'interface/upSellInterface';

class UpSellService {
  createupSell = async (body: IPreUpSellCreate) => {
    try {
      const response = await baseService.post<IPreUpSellCreate>(
        createPreUpSell,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchUpSell = async (body: IUpSellBody) => {
    try {
      const response = await baseService.post(searchPreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  removeUpSell = async (body: IPreUpRemove) => {
    try {
      const response = await baseService.post(removePreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateUpSell = async (body: IPreUPSellResponse) => {
    try {
      const response = await baseService.post(updatePreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class UpSellMockService {
  createupSell = async (body: IPreUpSellCreate) => {
    try {
      const response = await baseService.post<IPreUpSellCreate>(
        searchRiskEP,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  searchUpSell = async (body: IUpSellBody) => {
    try {
      const response = await baseService.post(searchPostUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  removeUpSell = async (body: IPreUpRemove) => {
    try {
      const response = await baseService.post(removePreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateUpSell = async (body: IPreUPSellResponse) => {
    try {
      const response = await baseService.post(updatePreUpSell, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalUpSellService: UpSellService | UpSellMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new UpSellMockService()
    : new UpSellService();

export default globalUpSellService;
