import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import storeService from 'services/storeService';
import { showNotification } from 'helper/common/commonFunctions';
import { MultipleInputTag } from 'components/common/multiInput';
import { ICheckoutPage } from 'interface/checkoutPagesInterface';
import {
  initialFreeTemplateData,
  standardPostUpsellLayout,
  initialThankYouData,
  standardCheckoutLayout,
} from 'shared-components';
import { Data } from '@measured/puck';
import { ConvertJsonToBase64 } from 'shared-components';
import AutoCompleteSelect from 'components/common/selectAutoComplete';

interface IProps {
  handleClose: (_refetch?: boolean) => void;
  isAdd: boolean;
  storeDetails: { StoreID: string; ChannelID: string };
  currentRow?: ICheckoutPage;
}

const AvailablePagesTypes = [
  { label: 'Free Style', value: 'other' },
  { label: 'Checkout', value: 'checkout' },
  { label: 'Thankyou | Confirmation', value: 'confirmation' },
  { label: 'Post Upsells', value: 'upsell' },
];

const AddPages: FC<IProps> = ({
  handleClose,
  isAdd,
  storeDetails,
  currentRow,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<ICheckoutPage>({
    defaultValues: isAdd
      ? {
          Name: '',
          PageType: '',
          Tags: [],
          PageData: '',
        }
      : {
          ID: currentRow?.ID ?? '',
          Name: currentRow?.Name ?? '',
          PageType: currentRow?.PageType ?? '',
          Tags: currentRow?.Tags ?? [],
          PageData: currentRow?.PageData ?? '',
          StoreID: currentRow?.StoreID ?? '',
          Version: currentRow?.Version ?? 0,
        },
  });

  const addPages = async (data: ICheckoutPage) => {
    setLoading(true);
    data.PageData = ConvertJsonToBase64(getDefaultConfigData(data));
    data.StoreID = storeDetails.StoreID;
    const res = await storeService.createCheckoutPage(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Page Added Successfully');
      handleClose(true);
    }
  };
  const getDefaultConfigData = (page: ICheckoutPage): Data | object => {
    let defaultLayoutData;
    switch (page.PageType) {
      case 'upsell':
        defaultLayoutData = standardPostUpsellLayout;
        break;
      case 'checkout':
        defaultLayoutData = standardCheckoutLayout;
        break;
      case 'confirmation':
        defaultLayoutData = initialThankYouData;
        break;
      case 'other':
        defaultLayoutData = initialFreeTemplateData;
        break;
      default:
        defaultLayoutData = {};
        break;
    }
    return defaultLayoutData;
  };

  const updatePages = async (data: ICheckoutPage) => {
    setLoading(true);
    const res = await storeService.updateCheckoutPage(data);
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Page Updated Successfully');
      handleClose(true);
    }
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={() => handleClose()}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: isAdd ? 'Add' : 'Update',
            form: 'AddPages',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${isAdd ? 'Add' : 'Update'} Page`}
        className="flex-container">
        <div className="popup-section">
          <form
            className="grid gap-4"
            id="AddPages"
            onSubmit={
              isAdd ? handleSubmit(addPages) : handleSubmit(updatePages)
            }>
            <Controller
              name="Name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { ref, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    {...rest}
                    value={value}
                    errorString={error?.message}
                    label="Name"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter name"
                    ref={ref}
                  />
                );
              }}
            />
            <Controller
              name="PageType"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { onChange, value, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <AutoCompleteSelect
                    {...rest}
                    options={AvailablePagesTypes}
                    labelKey="label"
                    onChange={val => onChange(val?.value || null)}
                    value={
                      AvailablePagesTypes?.find(type => type.value === value) ||
                      null
                    }
                    placeholder="Select PageType"
                    className="w-full dropdown-field"
                    label="PageType"
                    error={error?.message}
                  />
                );
              }}
            />

            <Controller
              name="Tags"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <MultipleInputTag
                    tagsValue={value || []}
                    label="Tags"
                    placeholder="Enter Tag"
                    onChange={onChange}
                  />
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};
export default AddPages;
