import { Data } from '@measured/puck';

export const ConvertJsonToBase64 = (data: object): string => {
  try {
    const jsonString = JSON.stringify(data);
    const utf8Bytes = new TextEncoder().encode(jsonString);
    const base64String = btoa(String.fromCharCode(...utf8Bytes));
    return base64String;
  } catch (error) {
    console.error('Error while converting JSON to Base64', error);
    return '';
  }
};

// converting base64 to bytearray and then to json
export const ConvertBase64ToJson = (base64String: string): Data => {
  try {
    const byteArray = new Uint8Array(
      atob(base64String)
        .split('')
        .map(char => char.charCodeAt(0)),
    );
    const jsonString = new TextDecoder()
      .decode(byteArray)
      .replaceAll('{', '[')
      .replaceAll('}', ']');

    const jsonObject = JSON.parse(jsonString);
    const byte = new Uint8Array([...jsonObject]);
    const data = new TextDecoder().decode(byte);
    return JSON.parse(data);
  } catch (error) {
    console.error('Error while converting Base64 to JSON', error);
    return {
      content: [],
      root: {},
      zones: {},
    };
  }
};
