import { createSlice } from '@reduxjs/toolkit';
import { IStoreFront } from 'interface/settingsInterface';

export interface IStoreIDConfig {
  storeIds: IStores[];
}

export interface IStores {
  CheckoutURL: string;
  ID: string;
  Name: string;
  StoreDescription: string;
  Version: number;
  storeFronts: IStoreFront[];
}

const initialState: IStoreIDConfig = {
  storeIds: [],
};

export const storeSlice = createSlice({
  name: 'storesConfig',
  initialState,
  reducers: {
    setStore: (state, action) => {
      return { ...state, storeIds: action.payload };
    },
    clearStoreSlice: () => initialState,
  },
});

export const { setStore, clearStoreSlice } = storeSlice.actions;

export default storeSlice.reducer;
