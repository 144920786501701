import { baseService } from 'services';
import {
  getCreatePlanEP,
  getDeletePlanEP,
  getMembershipPlanByIDEP,
  getMembershipPlanEP,
  getUpdatePlanEP,
} from 'services/constants';
import { IMembershipPlan, IPlan } from 'interface/membershipPlanInterface';

class MembershipPlanService {
  createMembershipPlan = async (data: IPlan) => {
    try {
      return await baseService.post<{ Plan: IPlan }>(getCreatePlanEP, data);
    } catch (err) {
      console.log(err);
    }
  };

  getMembershipPlan = async (store_id: string) => {
    try {
      return await baseService.get<IMembershipPlan>(
        getMembershipPlanEP(store_id),
      );
    } catch (err) {
      console.log(err);
    }
  };

  getMembershipPlanById = async (store_id: string, plan_id: string) => {
    try {
      return await baseService.get<IPlan>(
        `${getMembershipPlanByIDEP(store_id, plan_id)}`,
      );
    } catch (err) {
      console.log(err);
    }
  };

  updateMembershipPlan = async (data: IPlan) => {
    try {
      return await baseService.post<{ Plan: IPlan }>(getUpdatePlanEP, data);
    } catch (err) {
      console.log(err);
    }
  };

  deleteMembershipPlan = async (store_id: string, plan_id: string) => {
    try {
      return await baseService.delete(getDeletePlanEP(store_id, plan_id));
    } catch (err) {
      console.log(err);
    }
  };
}

export default new MembershipPlanService();
