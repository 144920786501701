import { Config, Data, Render as PuckRender } from '@measured/puck';

type Props = {
  data: Data;
  config: Config;
};

export type ThemeColor = {
  r: number;
  g: number;
  b: number;
};
export function Render({ data, config }: Props) {
  const page = document.getElementsByClassName(
    'puck-pages-wrapper',
  )[0] as HTMLDivElement;
  const color = data?.root?.props?.themeColor as ThemeColor;

  if (page && color) {
    page.style.setProperty(
      '--color-page-builder-theme',
      `${color?.r} ${color?.g} ${color?.b}`,
    );
  }
  return <PuckRender config={config} data={data} />;
}
