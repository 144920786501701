import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';

interface IProps {
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
  payload: ISubscriptionManagementUpdateBody | null;
}

const pauseSubscriptionAction: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);

  const pauseSubscription = async () => {
    if (!payload) return;
    setLoading(true);
    payload.Status = SubscriptionStatus.subscriptionStatusPause;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification('success', 'Subscription Updated Successfully');
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            handleClose();
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Confirm',
          buttonFunction: () => {
            pauseSubscription();
          },
          disabled: loading,
          loading,
        },
      ]}
      heading={'Confirm'}>
      <div className="popup-section">
        <p className="popup-question">
          Are you sure you want to Pause subscription?
        </p>
      </div>
    </PopUpModal>
  );
};

export default pauseSubscriptionAction;
