import ButtonGroup from 'components/common/ButtonGroup';
import { GroupedLineChart } from 'components/common/chart/lineChart';
import { IRiskManagement } from 'interface/riskInterface';

import React, { useMemo, useState } from 'react';
import { percentage } from 'utils/helper';

interface IDatasets {
  chargeback: number[];
  dispute: number[];
  ethoca: number[];
  rdr: number[];
  total: number[];
}
interface IProps {
  label: string;
  total: string;
  totalPercentage?: string;
  groupedRiskDatas: [string, IRiskManagement[]][] | null;
}
export const revenueTypes = {
  ratio: 'Ratio',
  count: 'Count',
};
const filterButtons = Object.values(revenueTypes).map(types => ({
  name: types,
}));
function disputeOverTime({ groupedRiskDatas, label }: IProps) {
  const [disputeType, setDisputeType] = useState(revenueTypes.ratio);
  let maxYLabel = 0;
  let dataSet = {
    label: '',
    borderColor: '',
    backgroundColor: '',
    legend: '',
    data: [0],
    total: 0,
  };
  const salesRevenueData = useMemo(() => {
    const values: IDatasets = {
      dispute: [],
      chargeback: [],
      ethoca: [],
      rdr: [],
      total: [],
    };
    if (label === 'ALL') {
      groupedRiskDatas?.forEach(([, statsData]) => {
        const { chargeback, dispute, total } = statsData.reduce(
          ({ chargeback, dispute, total }, stat) => {
            chargeback += stat.Chargebacks;
            total += stat.Total;
            dispute += stat.Disputes ? stat.Disputes : 0;
            return {
              total,
              chargeback,
              dispute,
            };
          },
          { total: 0, chargeback: 0, dispute: 0 },
        );
        values.total.push(total);
        values.chargeback.push(chargeback);
        values.dispute.push(dispute);
      });
    } else if (label === 'MASTERCARD') {
      groupedRiskDatas?.forEach(([, statsData]) => {
        const { chargeback, dispute, ethoca, total } = statsData.reduce(
          ({ chargeback, ethoca, dispute, total }, stat) => {
            ethoca += stat.Ethoca;
            chargeback += stat.Chargebacks;
            total += stat.Total;
            dispute += stat.Disputes;
            return {
              total,
              ethoca,
              chargeback,
              dispute,
            };
          },
          {
            total: 0,
            chargeback: 0,
            dispute: 0,
            ethoca: 0,
          },
        );
        values.total.push(total);
        values.chargeback.push(chargeback);
        values.dispute.push(dispute);
        values.ethoca.push(ethoca);
      });
      dataSet = {
        label: 'Ethoca',
        borderColor: '#C237F3',
        backgroundColor: '#C237F3',
        legend: 'MCEthoca',
        ...(disputeType === revenueTypes.ratio
          ? {
              data: values.ethoca.map(val =>
                percentage(
                  val,
                  values.total.reduce((prev, current) => prev + current, 0),
                  true,
                ),
              ),
              amount: values.ethoca,
            }
          : {
              data: values.ethoca,
              amount: values.ethoca.map(val =>
                percentage(
                  val,
                  values.total.reduce((prev, current) => prev + current, 0),
                  true,
                ),
              ),
            }),
        total: values.ethoca.reduce((prev, current) => prev + current, 0),
      };
    } else {
      groupedRiskDatas?.forEach(([, statsData]) => {
        const { chargeback, dispute, rdr, total } = statsData.reduce(
          ({ chargeback, rdr, dispute, total }, stat) => {
            rdr += stat.RDR + stat.CDRN;
            chargeback += stat.Chargebacks;
            total += stat.Total;
            dispute += stat.Disputes ? stat.Disputes : 0;
            return {
              total,
              rdr,
              chargeback,
              dispute,
            };
          },
          {
            total: 0,
            chargeback: 0,
            dispute: 0,
            rdr: 0,
          },
        );
        values.total.push(total);
        values.chargeback.push(chargeback);
        values.dispute.push(dispute);
        values.rdr.push(rdr);
      });
      dataSet = {
        label: 'Rdr/Cdrn',
        borderColor: '#C237F3',
        backgroundColor: '#C237F3',
        legend: 'VISA RDR/CDRN',
        ...(disputeType === revenueTypes.ratio
          ? {
              data: values.rdr.map(val =>
                percentage(
                  val,
                  values.total.reduce((prev, current) => prev + current, 0),
                  true,
                ),
              ),
              amount: values.rdr,
            }
          : {
              data: values.rdr,
              amount: values.rdr.map(val =>
                percentage(
                  val,
                  values.total.reduce((prev, current) => prev + current, 0),
                  true,
                ),
              ),
            }),
        total: values.rdr.reduce((prev, current) => prev + current, 0),
      };
    }

    const totalDispute = values.dispute.reduce(
      (prev, current) => prev + current,
      0,
    );
    const totalChargeback = values.chargeback.reduce(
      (prev, current) => prev + current,
      0,
    );

    const totalAmount = values.total.reduce(
      (prev, current) => prev + current,
      0,
    );

    return {
      chart: {
        labels: groupedRiskDatas?.map(([date]) => date),
        datasets: [
          {
            label: 'Dispute',
            borderColor: '#F90182',
            backgroundColor: '#F90182',
            legend: 'Disputes',
            ...(disputeType === revenueTypes.ratio
              ? {
                  data: values?.dispute?.map(val => {
                    const percent = percentage(val, totalAmount);
                    maxYLabel = Math.max(maxYLabel, percent);
                    return percent;
                  }),
                  amount: values.dispute,
                }
              : {
                  data: values?.dispute?.map(value => {
                    maxYLabel = Math.max(maxYLabel, value);
                    return value;
                  }),
                  amount: values.dispute.map(val =>
                    percentage(val, totalAmount),
                  ),
                }),
            total: totalDispute,
          },
          {
            label: 'ChargeBacks',
            borderColor: '#6AD2FF',
            backgroundColor: '#6AD2FF',
            legend: 'ChargeBacks',
            ...(disputeType === revenueTypes.ratio
              ? {
                  data: values.chargeback.map(val => {
                    const percent = percentage(val, totalAmount);
                    maxYLabel = Math.max(maxYLabel, percent);
                    return percent;
                  }),
                  amount: values.chargeback,
                }
              : {
                  data: values.chargeback.map(value => {
                    maxYLabel = Math.max(maxYLabel, value);
                    return value;
                  }),
                  amount: values.chargeback.map(val =>
                    percentage(val, totalAmount),
                  ),
                }),

            total: totalChargeback,
          },
          ...(dataSet && dataSet.label ? [dataSet] : []),
        ],
        totalAmount,
      },
    };
  }, [groupedRiskDatas, disputeType]);

  return (
    <div className="lg_card_container row-span-2">
      <div className="header">
        <p className="header_text">Dispute Over Time</p>
        <ButtonGroup
          active={disputeType}
          setActive={setDisputeType}
          buttons={filterButtons}
        />
      </div>
      <div className="values_detail">
        <span className="value">
          {salesRevenueData?.chart?.datasets?.[0]?.total}
        </span>
        <span className="percentage">
          {percentage(
            salesRevenueData?.chart?.datasets?.[0]?.total,
            salesRevenueData?.chart?.totalAmount,
          )}
          %
        </span>
      </div>
      <div className="status_container">
        {salesRevenueData.chart.datasets.map((data, index) => {
          return (
            <div className="status" key={index}>
              <p
                className="status_color"
                style={{ background: data.backgroundColor }}></p>
              <span className="status_name"> {data.legend}</span>
            </div>
          );
        })}
      </div>
      <div className="body">
        <div className="chart_wrapper">
          <GroupedLineChart
            data={salesRevenueData.chart}
            maxYLabel={maxYLabel}
            isPercentagedata={disputeType === revenueTypes.ratio}
            disputeOverTime={disputeType}
            suffix={disputeType === revenueTypes.ratio ? '%' : ''}
            hasPercentageValue={disputeType !== revenueTypes.ratio}
            className="chart"
          />
        </div>
      </div>
    </div>
  );
}

export default disputeOverTime;
