export const addEditName = {
  store: 'store',
  subscription: 'subscription',
  storeFront: 'storeFront',
  paymentGateway: 'paymentGateway',
  user: 'user',
};

export const defaultUserPermission = {
  Email: '',
  Permissions: ['view:all'],
  SuperAdmin: false,
};

export const tabName = [
  'Shopify',
  'Payment Gateway',
  'Subscription Plan',
  'User Permissions',
];

export const platform = 'shopify';

export const superAdmin = 'super_admin';
