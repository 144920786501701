import * as React from 'react';
import { SVGProps } from 'react';

const Savings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={222.667}
    height={198.667}
    viewBox="0 0 167 149"
    {...props}>
    <path d="M38 39C16.9 60.1 0 77.8 0 78.8 0 80.6 66.7 148 68.5 148c.6 0 18.5-17.5 39.8-38.8L147 70.4l-.2-34.4-.3-34.5-35.2-.3L76.1 1 38 39zm97 .2v27.3l-32.7 32.7C84.2 117.3 69.1 132 68.5 132c-.6 0-12.9-11.9-27.4-26.4C22.3 86.8 15 78.8 15.6 77.9c.4-.8 15.2-15.9 32.8-33.6l32.1-32.2 27.3-.1H135v27.2z" />
    <path d="M98.5 26.5C91.9 30.1 89 34.8 89 42c0 8.9 7.3 16 16.5 16 16.4 0 22.7-20.4 9.3-30.3-3.7-2.7-12.3-3.3-16.3-1.2zM156.2 11.2c-.9.9-1.2 10-1.2 34.6v33.4l-28.2 28.2c-15.6 15.5-29.1 29.2-30.1 30.3-1.8 2.1-1.8 2.2 1.9 5.8l3.7 3.7 31.8-31.8L166 83.5V47.4c0-32.1-.2-36.3-1.6-36.8-2.6-1-6.9-.7-8.2.6z" />
  </svg>
);
export default Savings;
