export const chargeType = {
  subscription: 'subscription',
  purchase: 'purchase',
  upsell: 'upsell',
};

export const cardBrand = {
  master: 'mastercard',
  visa: 'visa',
  others: 'others',
};

export const disputeAlert = {
  RDR: 'RDR',
  ETHOCA: 'Ethoca',
  Chargeback: 'Chargeback',
  CDRN: 'CDRN',
};

export const transactionStatus = {
  chargeback: 'chargeback',
  refunded: 'refunded',
  failcapture: 'fail_capture',
  captured: 'captured',
  failAuth: 'fail_authorization',
  authorized: 'authorized',
  voided: 'voided',
  partialRefund: 'partial_refund',
};

export const totalRevenueFields = [
  transactionStatus.captured,
  transactionStatus.authorized,
  transactionStatus.refunded,
  transactionStatus.partialRefund,
];
export const netRevenueFields = [
  transactionStatus.captured,
  transactionStatus.authorized,
];
export const transactionType = {
  all: 'ALL',
};
export const transactionTypeOptions = Object.values(transactionType).map(
  range => ({
    label: range,
    value: range,
  }),
);

export const dateRange = {
  today: 'Today',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  thisYear: 'This Year',
  lastYear: 'Last Year',
  customRange: 'Custom Range',
};
export const dateRangeOptions = Object.values(dateRange).map(range => ({
  label: range,
  value: range,
}));

export const allDateRange = {
  allTime: 'All Time',
  today: 'Today',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  thisYear: 'This Year',
  lastYear: 'Last Year',
  customRange: 'Custom Range',
};

export const dateRangeOptionsWithAllTime = Object.values(allDateRange).map(
  range => ({
    label: range,
    value: range,
  }),
);
