import { AxiosError } from 'axios';
import { baseService } from 'services';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  commonError,
  createProcessrorAccounts,
  deleteProcessorAccounts,
  getProcessrorAccounts,
  updateProcessrorAccounts,
} from 'services/constants';
import { IStoreFront } from 'interface/settingsInterface';

import {
  IDeleteMerchantAccount,
  IMerchant,
  IMerchantAccountBody,
  IMerchantResponse,
  IMerchantUpadate,
} from 'interface/paymentGatewayInterface';

class PaymentGatewayMockService {
  commonFunction = async (): Promise<
    ISuccessResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getProcessorAccounts = async () => {
    return this.commonFunction();
  };
  updateProcessorAccounts = async () => {
    return this.commonFunction();
  };
  deleteProcessorAccounts = async () => {
    return this.commonFunction();
  };
  createProcessorAccounts = async () => {
    return this.commonFunction();
  };
}
class PaymentGatewayService {
  storeFrontMockService = new PaymentGatewayMockService();

  createProcessorAccounts = async (
    body: IMerchant,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IMerchantResponse>(
        createProcessrorAccounts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getProcessorAccounts = async (
    body: IMerchantAccountBody,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IMerchantResponse>(
        getProcessrorAccounts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateProcessorAccounts = async (
    body: IMerchantUpadate,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IMerchantResponse>(
        updateProcessrorAccounts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  deleteProcessorAccounts = async (
    body: IDeleteMerchantAccount,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IMerchantResponse>(
        deleteProcessorAccounts,
        body,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalPaymentGateWayService:
  | PaymentGatewayService
  | PaymentGatewayMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new PaymentGatewayMockService()
    : new PaymentGatewayService();

export default globalPaymentGateWayService;
