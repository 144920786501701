import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import 'components/onboarding/formComponents/storeDetailsPage/style.scss';
import { FC, useState } from 'react';
import TextField from 'components/common/textField';
import AddIcon from '@mui/icons-material/Add';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import CommonStepper from 'components/common/stepper';
import { stepperSteps, urlPattern } from 'utils/constants';
import { IStoreFront } from 'interface/settingsInterface';
import { IStoreDetailsForm } from 'interface/storeInterface';
interface IProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

type previousStateType = {
  type: 'ADD' | 'UPDATE';
  value: IStoreFront;
};

const defaultStoreFront: IStoreFront = {
  Name: '',
  Platform: 'portal',
  ShopID: '',
  ShopURL: '',
  ID: '',
  Version: 0,
  Shopify: {
    ID: '',
    StorefrontID: '',
    Version: 0,
    AccessToken: '',
    APIKey: '',
    APISecret: '',
    ShopID: '',
    StorefrontPublicAccessToken: '',
  },
  StoreID: '',
};

const Step2: FC<IProps> = ({ setStep }) => {
  const [indexValue, setIndexValue] = useState<number>(-1);
  const [previousValue, setPreviousValue] = useState<previousStateType>({
    type: 'ADD',
    value: defaultStoreFront,
  });
  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
  } = useFormContext<IStoreDetailsForm>();
  const { fields, append, remove, update } = useFieldArray({
    name: 'storeFront',
  });

  const store_details = useWatch({ control, name: 'storeFront' });

  const triggerCancel = () => {
    if (previousValue?.type === 'UPDATE')
      update(indexValue, previousValue.value);
    else remove(indexValue);
  };

  return (
    <div className="onboardingStep2">
      <div className="heading-text-area">
        <p className="text-bold">Shopify Made Simple</p>
        <p className="text">
          Add your shopify stores, or skip for now and come back later
        </p>
      </div>
      <div className="stepper-section">
        <CommonStepper steps={stepperSteps} activeStep={1} />
      </div>
      {indexValue < 0 && (
        <>
          <div className="button-wrapper">
            {store_details?.map((store, key: number) => {
              return (
                <div className="store-button" key={store?.ID}>
                  <button className="btn-store d-flex" type="button">
                    <p>{store?.Name}</p>
                    <div className="icon-Wrapper">
                      <CreateIcon
                        onClick={() => {
                          setPreviousValue({
                            type: 'UPDATE',
                            value: store,
                          });
                          setIndexValue(key);
                        }}
                      />
                      <DeleteIcon
                        onClick={() => {
                          remove(key);
                        }}
                      />
                    </div>
                  </button>
                </div>
              );
            })}
            <div className="add-button">
              <button
                className="btn-add-store d-flex"
                onClick={() => {
                  setIndexValue(fields.length);
                  setPreviousValue({
                    type: 'ADD',
                    value: defaultStoreFront,
                  });
                  append(defaultStoreFront);
                }}>
                Add Storefront
                <AddIcon />
              </button>
            </div>
          </div>
          <div className="button-field">
            <div className="button">
              <button
                type="button"
                className="wrapper"
                onClick={() => {
                  setStep(0);
                }}>
                <ArrowBack />
                <p>Back</p>
              </button>
            </div>
            <div className="button">
              <button
                type="submit"
                className="wrapper"
                onClick={() => {
                  setValue('is_store_skipped', !fields?.length);
                  setStep(2);
                }}>
                <p>{fields?.length ? 'Next' : 'Skip'}</p>
                <ArrowForward />
              </button>
            </div>
          </div>
        </>
      )}
      {indexValue >= 0 && (
        <>
          <div className="onboarding-fields">
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Name`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      placeholder="Enter the name"
                      label="Store Name"
                      errorString={error?.message}
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Shopify.ShopID`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, onChange, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      errorString={error?.message}
                      className="onboarding-input"
                      placeholder="Enter domain ID"
                      label="Shopify store domain id"
                      fixedSize="md"
                      ref={ref}
                      onChange={e => {
                        onChange(e);
                        setValue(
                          `storeFront.${indexValue}.ShopID`,
                          e.target.value,
                        );
                      }}
                    />
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.ShopURL`}
                control={control}
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: urlPattern,
                    message: 'Invalid URL',
                  },
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      errorString={error?.message}
                      placeholder="Enter URl"
                      label="Store URL"
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Shopify.APIKey`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      placeholder="Enter api key"
                      errorString={error?.message}
                      label="Shopify api key"
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Shopify.APISecret`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      placeholder="Enter app secret"
                      errorString={error?.message}
                      label="Shopify api secret"
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Shopify.AccessToken`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      errorString={error?.message}
                      placeholder="Enter shopify access token"
                      label="Shopify access token"
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
            <div className="onboarding-field">
              <Controller
                name={`storeFront.${indexValue}.Shopify.StorefrontPublicAccessToken`}
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <TextField
                      {...rest}
                      className="onboarding-input"
                      placeholder="Enter storefront access token"
                      errorString={error?.message}
                      label="Storefront Access Token"
                      fixedSize="md"
                      ref={ref}></TextField>
                  );
                }}
              />
            </div>
          </div>
          <div className="button-field">
            <div className="button">
              <button
                type="button"
                className="wrapper"
                onClick={() => {
                  triggerCancel();
                  setIndexValue(-1);
                }}>
                <ArrowBack />
                <p>Cancel</p>
              </button>
            </div>
            <div className="button">
              <button
                type="submit"
                className="wrapper"
                onClick={() => {
                  trigger();
                  if (isValid) setIndexValue(-1);
                }}>
                <p>Save</p>
                <ArrowForward />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Step2;
