import React, { useMemo } from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { useEffect } from 'react';
import 'components/common/multiInput/style.scss';
import { ClickAwayListener } from '@mui/material';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import { ReactComponent as DeleteIcon } from 'assets/icons/close-icon.svg';

interface ICUstomizedProps {
  limits?: number;
  label: string;
  tagsValue: string[];
  placeholder?: string;
  errorString?: string;
  onChange: (_value: string[]) => void;
  onClick?: () => void;
  onDelete?: (_index: number) => void;
  isLowerCase?: boolean;
  onInputChange?: (_value: string) => void;
  ishandleClickOutside?: boolean;
  onKeyDown?: boolean;
  refForClearInput?: React.MutableRefObject<string>;
}
export function MultipleInputStoreSearch({
  onInputChange,
  limits = 1,
  label,
  tagsValue,
  placeholder = '',
  onChange,
  errorString,
  onClick,
  onDelete,
  isLowerCase = false,
  ishandleClickOutside = true,
  onKeyDown = true,
  refForClearInput = { current: '' },
}: ICUstomizedProps) {
  const [limit, setLimit] = React.useState<boolean>(false);
  const [tag, setTag] = React.useState('');
  const { getRootProps, getInputProps, focused, setAnchorEl } = useAutocomplete(
    {
      id: 'customInputInTag',
      defaultValue: [],
      multiple: true,
      options: [],
    },
  );
  const deleteTag = (indexs: number) => {
    onChange(tagsValue.filter((_, index) => index !== indexs));
  };
  function handleClickOutside() {
    if (ishandleClickOutside && tag.trim().length != 0) {
      onChange([...tagsValue, tag]);
      setTag('');
    }
    setLimit(true);
  }
  useEffect(() => {
    return () => {
      onChange([]);
    };
  }, []);

  useMemo(() => {
    if (refForClearInput.current === 'clear') {
      setTag('');
      refForClearInput.current = '';
    }
  }, [refForClearInput.current]);

  return (
    <div {...getRootProps()} className="common-multi-select-input">
      <label className="label">{label}</label>
      <ClickAwayListener
        onClickAway={() => {
          handleClickOutside();
        }}>
        <div
          ref={setAnchorEl}
          className={`inputWrapper ${focused ? 'focused' : ''}`}>
          {limit === false ? (
            <>
              {tagsValue?.map((option: string, index: number) => (
                <div className="custom_styled_tag" key={index}>
                  <p className="search_content_tag">
                    <span>{option.split('>')[0]}</span>
                    {option.split('>')[1] && '>'}
                    <span>{option.split('>')[1]}</span>
                  </p>
                  <span
                    className="delete_tag"
                    onClick={() => {
                      deleteTag(index);
                      onDelete && onDelete(index);
                    }}>
                    {<DeleteIcon className="input_del_icon" />}
                  </span>
                </div>
              ))}
            </>
          ) : (
            <>
              {tagsValue?.map((option: string, index: number) =>
                index < limits ? (
                  <div className="custom_styled_tag" key={index}>
                    <p className="search_content_tag">
                      <span>{option.split('>')[0]}</span>
                      {option.split('>')[1] && '>'}
                      <span>{option.split('>')[1]}</span>
                    </p>
                    <span
                      className="delete_tag"
                      onClick={() => {
                        deleteTag(index);
                        onDelete && onDelete(index);
                      }}>
                      {<DeleteIcon className="input_del_icon" />}
                    </span>
                  </div>
                ) : (
                  <></>
                ),
              )}
              {tagsValue?.length - limits > 0 ? (
                <div className="addButton" onClick={() => setLimit(false)}>
                  +{tagsValue.length - limits}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          <input
            onClick={onClick}
            className="text_field_input_multiInput"
            placeholder={placeholder}
            {...getInputProps()}
            value={tag}
            {...(onKeyDown && {
              onKeyDown: e => {
                if (e.key === 'Enter' && tag.trim().length != 0) {
                  e.preventDefault();
                  onChange([...tagsValue, tag]);
                  setTag('');
                }
              },
            })}
            onChange={e => {
              setLimit(false);
              setTag(
                isLowerCase ? e.target?.value?.toLowerCase() : e.target?.value,
              );
              onInputChange && onInputChange(e.target.value);
            }}
          />
        </div>
      </ClickAwayListener>
      {errorString ? <p className="error_message">{errorString}</p> : <></>}
    </div>
  );
}

interface IControlledMultiInputTags
  extends Omit<ICUstomizedProps, 'tagsValue' | 'errorString' | 'onChange'> {
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
  >;
}

export const ControlledMultipleInputStoreSearch = ({
  name,
  rules,
  ...rest
}: IControlledMultiInputTags) => {
  return (
    <Controller
      name={name}
      rules={rules}
      render={({ field: { value, ...restFields }, fieldState: { error } }) => {
        return (
          <MultipleInputStoreSearch
            {...rest}
            {...restFields}
            tagsValue={value}
            errorString={error?.message}
          />
        );
      }}
    />
  );
};
