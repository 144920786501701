interface Props {
  exportList: () => void;
}

const ExportFilter = ({ exportList }: Props) => {
  return (
    <div className="action_filter_container">
      <div className="action_filter_columns" onClick={exportList}>
        Export All
      </div>
    </div>
  );
};

export default ExportFilter;
