import Axios, { AxiosError, AxiosInstance } from 'axios';
import { logOut } from 'services/authService';
import {
  authRequired,
  expiresWithin,
  fetchNewAccessToken,
} from 'services/tokenService';

const nonBlockingLazyFetchWindow = 300000; // 5 mins in milliseconds
const blockingLazyFetchWindow = 10000; // 10 seconds
const timeout = 15000; // 15 seconds in milliseconds
const retryableStatusCodes = [408, 425, 429, 502, 503, 504];

import axiosRetry from 'axios-retry';
import axios from 'axios';

const pendingRequests = new Map();

export const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    async config => {
      const requestIdentifier = `${config.url}_${config.method}`;
      if (pendingRequests.has(requestIdentifier)) {
        const cancelTokenSource = pendingRequests.get(requestIdentifier);
        cancelTokenSource.cancel();
      }
      const newCancelTokenSource = axios.CancelToken.source();
      config.cancelToken = newCancelTokenSource.token;

      pendingRequests.set(requestIdentifier, newCancelTokenSource);

      // Add timeout configuration to the request
      config.timeout = timeout;
      config.headers['X-Request-Start-Time'] = new Date().toISOString();
      let access_token = localStorage.getItem('access_token') as string;
      const refresh_token = localStorage.getItem('refresh_token') as string;
      if (!authRequired(config)) {
        return config;
      }

      if (expiresWithin(refresh_token, 0)) {
        logOut();
        throw new Error('refresh_token expired');
      }

      if (expiresWithin(access_token, blockingLazyFetchWindow)) {
        // blocking call to refetch access_token
        access_token = await fetchNewAccessToken(refresh_token);
        if (access_token) {
          config.headers['Authorization'] = `Bearer ${access_token}`;
          return config;
        }
        logOut();
        throw new Error('failed to refetch access_token');
      }

      if (expiresWithin(access_token, nonBlockingLazyFetchWindow)) {
        // non blocking call to refetch access_token
        fetchNewAccessToken(refresh_token);
      }

      config.headers['Authorization'] = `Bearer ${access_token}`;
      return config;
    },
    (error: AxiosError) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    response => {
      const requestIdentifier = `${response.config.url}_${response.config.method}`;
      pendingRequests.delete(requestIdentifier);
      return response;
    },
    async error => {
      if (error.config) {
        const requestIdentifier = `${error.config.url}_${error.config.method}`;
        pendingRequests.delete(requestIdentifier);
      }
      const requestStartTime = error?.config?.headers?.['X-Request-Start-Time']
        ? new Date(error.config.headers['X-Request-Start-Time']).getTime()
        : new Date().getTime();
      const currentTime = new Date().getTime();
      if (
        requestStartTime - currentTime < currentTime &&
        error.code === 'ECONNABORTED'
      ) {
        return Promise.reject(
          new Error('The request timed out. Please try again later.'),
        );
      }
      return Promise.reject(error);
    },
  );
};

export const baseService = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosRetry(baseService, {
  retries: 2,
  retryCondition: error => {
    if (error.response?.status)
      return retryableStatusCodes.includes(error?.response?.status);
    else return false;
  },
  retryDelay: retryCount => {
    return retryCount * 100;
  },
});

setInterceptors(baseService);
