import { useMemo } from 'react';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as SubscriptionRatio } from 'assets/icons/SubscriptionRatio.svg';
import { ReactComponent as RefundIcon } from 'assets/icons/refund-icon.svg';
import { ReactComponent as StraightRatio } from 'assets/icons/StraightRatio.svg';
import { ReactComponent as UpsellIcon } from 'assets/icons/upsell-icon.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import {
  RefundAmountStats,
  UniqueAprrovalStats,
} from 'interface/dashboardNewIterface';
import { chargeType, transactionStatus } from './constant';
import { ISubscriptionStats } from 'interface/dashboardInterface';
import { Tooltip } from '@mui/material';
import { formattedDate, getPercentageByValue, percentage } from 'utils/helper';
import dayjs from 'dayjs';
import { IDashboardFilter } from '.';
import { formatPrice } from 'shared-components/src/page-builder/utils/formatPrice';

interface IStatsCardProps {
  uniqueTransactionStats: UniqueAprrovalStats[] | null;
  subscriptionStats: ISubscriptionStats | null;
  refundAmountStats: RefundAmountStats | null;
  filter?: IDashboardFilter;
}
export const approvedTransactionStatus = [
  transactionStatus.captured,
  transactionStatus.authorized,
];

export default function StatsCards({
  uniqueTransactionStats,
  subscriptionStats,
  refundAmountStats,
  filter,
}: IStatsCardProps) {
  const cardStats = useMemo(() => {
    let saleApproved = 0;
    let saleTotal = 0;
    let subApproved = 0;
    let subTotal = 0;
    let subCycleInitial = 0;
    let subCycleRecurring = 0;
    let initialTotal = 0;
    let recurringTotal = 0;
    let purchaseTotal = 0;
    let purchaseApproved = 0;
    let upSellTotal = 0;
    let upSellApproved = 0;
    const totalSubscriptionOrderCount = 0;

    if (!uniqueTransactionStats || !uniqueTransactionStats?.length) {
      return {
        recurringTotal,
        initialTotal,
        purchaseTotal,
        upSellTotal,
        saleTotal,
        purchaseApproved,
        subTotal,
        totalStraightApprovalPercentage: 0,
        totalSubscriptionApprovalPercentage: 0,
        totalSubCycleIntitalPercentage: 0,
        totalSubCycleRecurringPercentage: 0,
        totalSubscriptionOrderCount,
        totalPurchasePercentage: 0,
        totalUpSellPercentage: 0,
        upSellApproved,
      };
    }
    uniqueTransactionStats.forEach(stat => {
      if (stat.ChargeType === chargeType.subscription) {
        subTotal += stat.Attempted;
        subApproved += stat.Approved;
        if (stat.Initial) {
          initialTotal += stat.Attempted;
          subCycleInitial += stat.Approved;
          return;
        }
        recurringTotal += stat.Attempted;
        subCycleRecurring += stat.Approved;
        return;
      } else if (stat.ChargeType === chargeType.upsell) {
        saleTotal += stat.Attempted;
        saleApproved += stat.Approved;
        upSellTotal += stat.Attempted;
        upSellApproved += stat.Approved;
        return;
      }
      saleTotal += stat.Attempted;
      saleApproved += stat.Approved;
      purchaseTotal += stat.Attempted;
      purchaseApproved += stat.Approved;
    });

    return {
      recurringTotal,
      initialTotal,
      purchaseTotal,
      upSellTotal,
      saleTotal,
      purchaseApproved,
      subTotal,
      totalStraightApprovalPercentage: getPercentageByValue(
        saleApproved,
        saleTotal,
      ),
      totalSubscriptionApprovalPercentage: getPercentageByValue(
        subApproved,
        subTotal,
      ),
      totalSubCycleIntitalPercentage: getPercentageByValue(
        subCycleInitial,
        initialTotal,
      ),
      totalSubCycleRecurringPercentage: getPercentageByValue(
        subCycleRecurring,
        recurringTotal,
      ),
      totalUpSellPercentage: getPercentageByValue(upSellApproved, upSellTotal),
      totalPurchasePercentage: getPercentageByValue(
        purchaseApproved,
        purchaseTotal,
      ),
      totalSubscriptionOrderCount: subApproved,
      upSellApproved,
    };
  }, [uniqueTransactionStats]);

  const percentagePlaceholder = (percentage: number, total: number) => {
    return percentage ? `${percentage}%` : total ? '0' : '-';
  };

  const refundAmountStatsPlaceholder = () => {
    if (!refundAmountStats) return '-';
    return formatRefundAmount(
      refundAmountStats?.PurchaseRefundedAmount +
        refundAmountStats?.SubscriptionRefundedAmount +
        refundAmountStats?.UpsellRefundedAmount,
    );
  };

  const formatRefundAmount = (amount: number | undefined) => {
    if (!amount) return '0.00';
    return `$${formatPrice(String(amount))}`;
  };

  return (
    <div className="statistics_cards row-full">
      <div className="card-wrapper">
        <p className="date">
          {formattedDate(dayjs(filter?.start_time).tz().format(), true) +
            ' - ' +
            formattedDate(dayjs(filter?.end_time).tz().format(), true)}
        </p>
        <div className="cards">
          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Direct</p>
                  <p className="title">
                    {' '}
                    {percentagePlaceholder(
                      cardStats.totalPurchasePercentage,
                      cardStats.purchaseTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">UpSell</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalUpSellPercentage,
                      cardStats.upSellTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <StraightRatio className="net_revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Straight Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalStraightApprovalPercentage,
                    cardStats.saleTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>

          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Initial</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleIntitalPercentage,
                      cardStats.initialTotal,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">Recurring</p>
                  <p className="title">
                    {percentagePlaceholder(
                      cardStats.totalSubCycleRecurringPercentage,
                      cardStats.recurringTotal,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <SubscriptionRatio className="revenue" />
              </div>
              <div className="content_wrapper">
                <p className="label">Subscription Unique Approval</p>
                <p className="sub_text">
                  {percentagePlaceholder(
                    cardStats.totalSubscriptionApprovalPercentage,
                    cardStats.subTotal,
                  )}
                </p>
              </div>
            </div>
          </Tooltip>
          <Tooltip
            placement="bottom"
            PopperProps={{ className: 'cycle-tooltip' }}
            title={
              <div className="cycle-wrapper">
                <div className="cycle divider">
                  <p className="title">Purchase</p>
                  <p className="title">
                    {formatRefundAmount(
                      refundAmountStats?.PurchaseRefundedAmount,
                    )}
                  </p>
                </div>
                <div className="cycle divider">
                  <p className="title">subscription</p>
                  <p className="title">
                    {formatRefundAmount(
                      refundAmountStats?.SubscriptionRefundedAmount,
                    )}
                  </p>
                </div>
                <div className="cycle">
                  <p className="title">Upsell</p>
                  <p className="title">
                    {formatRefundAmount(
                      refundAmountStats?.UpsellRefundedAmount,
                    )}
                  </p>
                </div>
              </div>
            }>
            <div className="card">
              <div className="icon_wrapper">
                <RefundIcon className="refund" />
              </div>
              <div className="content_wrapper">
                <p className="label">Refunded Amount</p>
                <p className="sub_text">{refundAmountStatsPlaceholder()}</p>
              </div>
            </div>
          </Tooltip>
          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Purchase Orders</p>
              <p className="sub_text">{cardStats.purchaseApproved}</p>
            </div>
          </div>

          <div className="card">
            <div className="icon_wrapper">
              <BoxIcon className="orders" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscription Orders</p>
              <p className="sub_text">
                {cardStats.totalSubscriptionOrderCount}
              </p>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper">
              <UpsellIcon className="upsell" />
            </div>
            <div className="content_wrapper">
              <p className="label">Upsell Take Rate</p>
              <p className="sub_text">
                {percentage(
                  cardStats.upSellApproved,
                  cardStats.purchaseApproved,
                  true,
                )}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper">
        <p className="date">Lifetime</p>
        <div className="cards">
          <div className="card">
            <div className="icon_wrapper subs_revenue">
              <UserIcon className="active_subscribers" />
            </div>
            <div className="content_wrapper">
              <p className="label">Active Subscribers</p>
              <p className="sub_text">
                {
                  subscriptionStats?.LifetimeSubscriptionStats
                    ?.ActiveSubscriptions
                }
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper str_sale ">
              <UserIcon className="salvage" />
            </div>
            <div className="content_wrapper">
              <p className="label">Subscribers in Salvage</p>
              <p className="sub_text">
                {' '}
                {subscriptionStats?.LifetimeSubscriptionStats?.InSalvage}
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
          <div className="card">
            <div className="icon_wrapper str_sale ">
              <UserIcon className="salvage" />
            </div>
            <div className="content_wrapper">
              <p className="label">Cancelled Subscribers</p>
              <p className="sub_text">
                {' '}
                {
                  subscriptionStats?.LifetimeSubscriptionStats
                    ?.CanceledSubscriptions
                }
              </p>
              <div className="helper_text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
