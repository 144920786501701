import { ComponentConfig } from '@measured/puck';
import {
  MarginProps,
  marginFields,
} from '../../../page-builder/puck/reusable-props/margin';
import {
  imageProps,
  ImageProps,
} from '../../../page-builder/puck/reusable-props/image-props';
import {
  paddingFields,
  PaddingProps,
} from '../../../page-builder/puck/reusable-props/padding';
import { imageField } from '../../../page-builder/puck/reusable-props/image';
import { IAsset } from '../../../page-builder/interface/apiServiceInterface';
import { AxiosInstance } from 'axios';
import CustomTooltip from '../../../components/common/tooltip';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';
import { ReduxSelectorProps } from '../../services/constants';

export type ImageUploaderProps = {
  imageFile: IAsset;
} & MarginProps &
  PaddingProps &
  ImageProps &
  showComponentProps;

export const ImageUploader = ({
  useAppSelector,
  baseService,
}: ReduxSelectorProps & {
  baseService: AxiosInstance;
}): ComponentConfig<ImageUploaderProps> => {
  return {
    label: (
      <CustomTooltip title="ImageUploader">
        <span>Image Uploader</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      imageFile: { ...imageField(false, baseService).file },
      ...marginFields,
      ...paddingFields,
      ...imageProps([]),
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      imageFile: {
        ID: '',
        BlobURL: '',
        Filename: '',
        StoreID: '',
        Version: 0,
      },
      margin: {
        desktop: {
          top: 'mt-0',
          bottom: 'mb-0',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      image: {
        imageType: 'inline',
        backgroundPosition: 'bg-center',
        backgroundRepeat: 'bg-no-repeat',
        backgroundSize: 'bg-cover',
        borderRadius: 'rounded-md',
        border: 'border-none',
        height: 'h-[200]',
        maxWidth: 'max-w-[200px]',
      },
    },
    render: ({ margin, padding, imageFile, image, showComponent }) => {
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);
      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div className="w-full">
              <div
                className={` ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right}
           ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right}
         ${image?.backgroundPosition}
        ${image?.backgroundRepeat}
        ${image?.backgroundSize}
        ${image?.border}
        ${image?.maxWidth}
        ${image?.borderRadius}
        ${image?.height}`}
                style={{
                  backgroundImage: `url(${imageFile?.BlobURL})`,
                }}>
                {image?.imageType === 'inline' && (
                  <img src={imageFile?.BlobURL} alt={imageFile?.Filename} />
                )}
              </div>
            </div>
          )}
        </>
      );
    },
  };
};
