import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';

import { PaymentRoutingResponse } from '..';

dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  onSuccess: () => void;
  defaultStoreValues?: PaymentRoutingResponse;
}

const AddPaymentRounting: FC<IProps> = ({
  handleClose,
  defaultStoreValues,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<PaymentRoutingResponse>({
    defaultValues: defaultStoreValues,
  });

  const updateStore = async () => {
    setLoading(true);
    setLoading(false);
    handleClose();
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: 'Update',
            form: 'AddStore',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={'Update'}
        className="flex-container">
        <div className="popup-section">
          <form
            onSubmit={handleSubmit(updateStore)}
            id="AddStore"
            className="shopify-collapsed">
            <Controller
              name="DirectSalePercentage"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    value={value}
                    onChange={value => onChange(value)}
                    label="Direct Sale %"
                    className="filter_form_input"
                    errorString={error?.message}
                    fixedSize="md"
                    placeholder="Enter direct sale %"></TextField>
                );
              }}
            />

            <Controller
              name="RevenueLimit"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    value={value}
                    onChange={value => onChange(value)}
                    errorString={error?.message}
                    label="Revenue Limit"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter revenue limit"></TextField>
                );
              }}
            />
            <Controller
              name="TransactionLimit"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    value={value}
                    onChange={value => onChange(value)}
                    errorString={error?.message}
                    label="Transaction Limit"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter transaction limit"></TextField>
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddPaymentRounting;
