import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import 'components/transactions/transactionNestedTable/style.scss';
import { formattedDate } from 'utils/helper';
import {
  ITransactionHistory,
  ITransactionsData,
} from 'interface/transactionInterface';
import React, { useEffect, useState } from 'react';
import globalTransactionService from 'services/transactionService';
import { Spinner } from 'components/common/loader';
interface IProps {
  row: ITransactionsData;
}

const NestedTableComponent: React.FC<IProps> = ({ row }) => {
  const [transactionHistoryData, setTransactionHistoryData] =
    useState<ITransactionHistory[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const getTransactionHistoryDetails = async () => {
    setLoading(true);
    const transactionHistory =
      await globalTransactionService?.getTransactionHistory(
        row?.Store?.ID,
        row?.ID,
      );
    setLoading(false);
    if (transactionHistory?.status === 200) {
      setTransactionHistoryData(transactionHistory.data?.Result);
    }
  };

  useEffect(() => {
    getTransactionHistoryDetails();
  }, []);

  return (
    <div className="nestedRow">
      <Box sx={{ margin: 1 }}>
        <div className="transaction_container">
          <div className="transaction_top_container">
            {transactionHistoryData ? (
              <TableContainer className="transactions_table_container">
                <Table
                  className="transactions_table"
                  aria-labelledby="tableTitle"
                  stickyHeader>
                  <TableHead className="table_header">
                    <TableRow>
                      <TableCell className="table_header_cell">Date</TableCell>
                      <TableCell className="table_header_cell">
                        Gateway ID
                      </TableCell>
                      <TableCell className="table_header_cell">
                        Details
                      </TableCell>
                      <TableCell className="table_header_cell">Event</TableCell>
                      <TableCell className="table_header_cell">
                        Trigger
                      </TableCell>
                      <TableCell className="table_header_cell">
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="table_body">
                    {transactionHistoryData?.map(history => (
                      <TableRow className="table_row" key={'date'}>
                        <TableCell
                          className="table_cell"
                          component="th"
                          scope="row">
                          <Tooltip
                            placement="top"
                            title={formattedDate(history?.Date)}
                            arrow>
                            <span>{formattedDate(history?.Date, true)}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell className="table_cell">
                          {history?.GatewayID ?? '-'}
                        </TableCell>
                        <TableCell className="table_cell">
                          <span className="reason-column">
                            <Tooltip
                              placement="top"
                              title={history?.Reason}
                              arrow>
                              <span>{history?.Reason}</span>
                            </Tooltip>
                          </span>
                        </TableCell>
                        <TableCell className="table_cell">
                          {history?.Status}
                        </TableCell>
                        <TableCell className="table_cell">
                          {history?.Type}
                        </TableCell>
                        <TableCell className="table_cell">
                          ${history?.Amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div className="flex-container">No data</div>
            )}
          </div>
        </div>
        <Spinner loading={loading} />
      </Box>
    </div>
  );
};

export default NestedTableComponent;
