import { Button } from 'shared-components';
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
// import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { PopUpModal } from 'shared-components';
import { IFormOffers } from 'interface/membershipOffersInterface';
import membershipOfferService from 'services/membershipOfferService';
import { useAppSelector } from 'hooks/reduxHooks';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';

interface IProps {
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setId?: React.Dispatch<React.SetStateAction<string>>;
  isSettingsFromPortal?: boolean;
}

const MembershipOffers: React.FC<IProps> = ({
  setIsEdit,
  setId,
  isSettingsFromPortal,
}) => {
  const [offers, setOffers] = useState<IFormOffers[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedOffer, setselectedOffer] = useState<IFormOffers>();
  const limit = 25;
  const { storeFront } = useAppSelector(state => state.shopify);
  const [loading, setLoading] = useState<number>(0);

  const removeOffer = async () => {
    setOpenDialog(false);
    if (selectedOffer?.ID && storeFront?.ID) {
      setLoading(pre => pre + 1);
      const res = await membershipOfferService.deleteMembershipOffers(
        storeFront?.ID,
        selectedOffer?.ID,
      );

      if (res?.status === 200 || res?.status === 204)
        await getMembershipOffer();

      setLoading(pre => pre - 1);
    }
  };

  const getMembershipOffer = async () => {
    if (storeFront?.ID) {
      setLoading(pre => pre + 1);
      const res = await membershipOfferService.getMembershipOffers({
        ChannelID: storeFront?.ID,
        Descending: true,
        Limit: limit,
        OrderBy: 'CreatedAt',
        Page: 0,
      });
      if (res?.data?.Result?.length > 0) setOffers(res.data.Result);
      else setOffers([]);
      setLoading(pre => pre - 1);
    }
  };

  const handleEdit = (row: IFormOffers) => {
    if (setIsEdit && setId && row?.ID) {
      setId(row?.ID);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    getMembershipOffer();
  }, [storeFront]);

  return (
    <div
      className={`${!isSettingsFromPortal && 'mx-auto max-w-5xl'} w-full h-full grid grid-rows-[min-content_1fr] overflow-auto`}>
      {!isSettingsFromPortal ? (
        <div className="flex justify-between align-center py-6">
          <h2 className="text-xl font-semibold">Membership Offers</h2>
          <Button
            label="Add Offer"
            variant="secondary"
            onClick={() => {
              setIsEdit && setIsEdit(true);
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
      <div className="h-full overflow-auto">
        {/* for future use */}
        {/* <div className="py-2 flex gap-2">
          <TextField
            placeholder="Search by discount name"
            fixedSize="md"
            value={filterName}
            onChange={e => setFilterName(e.target.value)}
            className="w-full input-field"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          <Select
            className="dropdown-field"
            value={filterMembership}
            onChange={e => setFilterMembership(e)}
            options={membershipTypes}
            placeholder="Select Membership"
          />
        </div> */}
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Discount Name</TableCell>
                  <TableCell>Membership</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {offers && offers.length ? (
                  offers.map(offer => {
                    return (
                      <TableRow key={offer.ID}>
                        <TableCell>{offer.Name}</TableCell>
                        <TableCell>{offer.DiscountType}</TableCell>
                        <TableCell>{offer.ProductCount}</TableCell>
                        <TableCell>{offer.DiscountAmount}</TableCell>
                        <TableCell>
                          <div className="flex align-center gap-4">
                            <EditIcon
                              className="cursor-pointer"
                              onClick={() => {
                                handleEdit(offer);
                              }}
                            />
                            <DeleteIcon
                              className="cursor-pointer"
                              onClick={() => {
                                setselectedOffer(offer);
                                setOpenDialog(true);
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <span className="text-center block">
                        No records found
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {isSettingsFromPortal && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={15}>
                      <button
                        className="sticky-button flex items-center"
                        onClick={() => {
                          setIsEdit && setIsEdit(true);
                          setId && setId('');
                        }}>
                        <AddIcon /> Add Membership Offers
                      </button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </div>
        <PopUpModal
          heading={`Delete ${selectedOffer?.Name}`}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          buttons={[
            {
              buttonLabel: 'Cancel',
              buttonType: 'negative',
              buttonFunction: () => setOpenDialog(false),
            },
            {
              buttonLabel: 'Delete offer',
              buttonType: 'positive',
              buttonFunction: removeOffer,
            },
          ]}>
          <p className="text-white">
            Are you sure you want to delete the offer{' '}
            <span className="font-medium">{selectedOffer?.Name}</span>?
          </p>
        </PopUpModal>
      </div>
      <Loader loading={!!loading} />
    </div>
  );
};

export default MembershipOffers;
