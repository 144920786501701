import React from 'react';
import { Button as CommonButton } from 'shared-components';
import { CustomTooltip } from 'shared-components';

export interface PixelHeaderProps {
  openPopup: () => void;
}

export const PixelHeader: React.FC<PixelHeaderProps> = ({ openPopup }) => {
  return (
    <>
      <div className="flex justify-between items-start">
        <div>
          <CustomTooltip title="CustomPixel">
            <span>Custom Pixels</span>
          </CustomTooltip>
        </div>
        <CommonButton
          label="+ Add Pixel"
          className="!m-0 !p-2 !text-[10px]"
          variant="phoenix_secondary"
          onClick={() => openPopup()}
        />
      </div>
    </>
  );
};
