import { Fields } from '@measured/puck';

export type HeightProps = {
  height: string;
};

export const heightField: Fields<HeightProps> = {
  height: {
    type: 'select',
    label: 'Height',
    options: [
      { label: '0', value: 'h-0' },
      { label: '1', value: 'h-1' },
      { label: '2', value: 'h-2' },
      { label: '4', value: 'h-4' },
      { label: '8', value: 'h-8' },
      { label: '10', value: 'h-10' },
      { label: '12', value: 'h-12' },
      { label: '14', value: 'h-14' },
      { label: '16', value: 'h-16' },
      { label: '20', value: 'h-20' },
      { label: '24', value: 'h-24' },
      { label: '28', value: 'h-28' },
      { label: '32', value: 'h-32' },
      { label: '36', value: 'h-36' },
      { label: '40', value: 'h-40' },
      { label: 'full', value: 'h-full' },
      { label: 'auto', value: 'h-auto' },
    ],
  },
};
