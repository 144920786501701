import { useFormContext } from 'react-hook-form';
import {
  RadioControl,
  RadioOptions,
} from '../../../../page-builder/components/form-fields/Radio';
import { AddressForm } from '.././AddressForm';

const BillingForm = () => {
  const addressOptions: RadioOptions[] = [
    { id: '1', name: 'Same as shipping address', value: true },
    { id: '2', name: 'Use a different billing address', value: false },
  ];

  const { watch } = useFormContext();
  return (
    <>
      <RadioControl name="billing_same_as_shipping" options={addressOptions} />

      {!watch('billing_same_as_shipping') && (
        <div className="mt-10">
          <AddressForm fieldPrefix="BillingAddress" />
        </div>
      )}
    </>
  );
};

export default BillingForm;
