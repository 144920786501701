import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export interface ICommonError {
  Msg: string;
  StatusCode: number;
}

export type IErrorResponse<T> = {
  error?: T;
  data?: ICommonError;
  status: number;
  message?: string;
};

const errorMessages: {
  [_: number]: {
    status: number;
    message: string;
  };
} = {
  400: {
    status: 400,
    message:
      'The server could not understand the request due to invalid syntax.',
  },
  401: {
    status: 401,
    message:
      'You are not authorized to access this resource. Please authenticate yourself.',
  },
  403: {
    status: 403,
    message: 'You do not have permission to access this resource.',
  },
  404: {
    status: 404,
    message: 'The page you requested could not be found.',
  },
  500: {
    status: 500,
    message:
      'An unexpected error occurred on the server. Please try again later.',
  },
  502: {
    status: 502,
    message: 'The server received an invalid response from an upstream server.',
  },
  503: {
    status: 503,
    message:
      'The server is currently unable to handle the request due to temporary overloading or maintenance of the server. Please try again later',
  },
  504: {
    status: 504,
    message:
      'The server did not receive a timely response from an upstream server.',
  },
  408: {
    status: 408,
    message:
      'The server did not receive a complete request within the time that it was prepared to wait.',
  },
  425: {
    status: 425,
    message:
      'The server is unwilling to risk processing a request that might be replayed.',
  },
  429: {
    status: 429,
    message:
      'You have made too many requests in a given amount of time. Please try again later.',
  },
};

export const StatusCode = {
  success: 'success',
  failed: 'failed',
};

export const toasterMessage = {
  pagePublishSuccess: 'Page published successfully',
  pageUpdateSuccess: 'Page updated successfully',
  imageUploadSuccess: 'Image uploaded successfully',
};
export const showNotification = (status: string, message: string) => {
  if (status === 'success') toast.success(message);
  else toast.error(message ?? 'Error');
};

export const createAssetsEP = '/pages/assets/create';
export const deleteAssetsEP = '/pages/assets/remove';
export const searchAssetsEP = '/pages/assets/search';
export const searchRiskEP = '/transactions/reports/risk';

export const getCheckoutPagesEP = 'pages/search';
export const createCheckoutPagesEP = 'pages/create';
export const updateCheckoutPagesEP = 'pages/update';
export const deleteCheckoutPagesEP = 'pages/remove';
export const createPreUpSell = '/pre_upsells/create';

export const getPage = (store_id: string, page_id: string) => {
  return `/store/${store_id}/pages/${page_id}`;
};

export const commonError = (
  error: AxiosError,
  showErrorNotification: boolean = false,
): IErrorResponse<AxiosError> => {
  if (error.isAxiosError && error.response) {
    if (showErrorNotification) {
      const response = error?.response?.data as {
        Msg: string;
        StatusCode: number;
      };
      const message =
        response?.Msg ||
        errorMessages?.[error.response?.status]?.message ||
        'Internal Server Error';
      showNotification(StatusCode.failed, message);
    }
    return { status: error?.status as number, message: error.message, error };
  } else {
    if (showErrorNotification)
      showNotification(StatusCode.failed, error.message);
    return { status: error?.status as number, message: error.message, error };
  }
};
