import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import { ISubscriptionManagementUpdateBody } from 'interface/subscriptionManagementInterface';
import globalSubscriptionManagementService from 'services/subscriptionManagementService';
import { showNotification } from 'helper/common/commonFunctions';
import { Select } from 'shared-components';
import { subscription_frequency } from 'utils/helper';
import { SubscriptionStatus } from 'components/SubscriptionManagement/constant';

interface IProps {
  handleClose: () => void;
  onApiResponse: (_: boolean) => void;
  payload: ISubscriptionManagementUpdateBody | null;
}

const ChangeSubscriptionFrequency: FC<IProps> = ({
  payload,
  handleClose,
  onApiResponse,
}) => {
  const [loading, setLoading] = useState(false);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState<string>(
    payload?.Frequency || '',
  );

  const changeSubscription = async () => {
    if (!payload) return;
    setLoading(true);
    payload.Frequency = subscriptionFrequency;
    payload.Status = SubscriptionStatus.subscriptionStatusActive;
    const response =
      await globalSubscriptionManagementService.updateSubscription(payload);
    if (response?.status === 200) {
      showNotification(
        'success',
        'Subscription Frequency updated successfully',
      );
      onApiResponse(true);
      handleClose();
    } else {
      onApiResponse(false);
    }
    setLoading(false);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'positive',
            buttonLabel: 'Confirm',
            buttonFunction: () => {
              changeSubscription();
            },
            disabled: loading,
            loading,
          },
        ]}
        heading={'Change Subscription Frequency'}
        className="flex-container">
        <div className="popup-section">
          <Select
            label="Subscription Frequency"
            placeholder="Select Subscription Frequency"
            className="filter_form_input"
            value={subscriptionFrequency}
            onChange={e => setSubscriptionFrequency(e)}
            options={subscription_frequency}
          />
        </div>
      </PopUpModal>
    </>
  );
};

export default ChangeSubscriptionFrequency;
