import { FC, useState } from 'react';
import { PopUpModal } from 'shared-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import storesService from 'services/storeService';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import { ICreateStore } from 'interface/storeInterface';
import { showNotification } from 'helper/common/commonFunctions';
import { urlPattern } from 'utils/constants';
dayjs.extend(utc);

interface IProps {
  handleClose: () => void;
  isAdd: boolean;
  onSuccess: (_id?: string) => void;
  defaultStoreValues: ICreateStore;
}

const AddStore: FC<IProps> = ({
  handleClose,
  isAdd,
  defaultStoreValues,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue } = useForm<ICreateStore>({
    defaultValues: defaultStoreValues,
  });

  const addStore = async (data: ICreateStore) => {
    setLoading(true);
    const payload: ICreateStore = {
      Name: data.Name,
      CheckoutURL: data.CheckoutURL,
      StoreDescription: data.StoreDescription,
    };
    const res = await storesService.createStore(payload);
    if (res?.status === 200) {
      showNotification('success', 'Added Store Successsfully');
      onSuccess(res?.data?.ID);
    } else handleClose();
    setLoading(false);
  };

  const updateStore = async (data: ICreateStore) => {
    setLoading(true);
    const res = await storesService.updateStore({
      ...data,
      ID: data.ID as string,
      Version: data.Version as number,
    });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'Updated Store Successsfully');
      onSuccess(res?.data?.ID);
    } else handleClose();
  };

  const trimValue = (value: string, field: keyof ICreateStore) => {
    const trimmedValue = value.trim();
    setValue(field, trimmedValue);
  };

  return (
    <>
      <PopUpModal
        open={true}
        handleClose={handleClose}
        buttons={[
          {
            buttonType: 'negative',
            buttonLabel: 'Cancel',
            buttonFunction: () => {
              handleClose();
            },
          },
          {
            buttonType: 'positive',
            buttonLabel: isAdd ? 'Add Store' : 'Update Store',
            form: 'AddStore',
            type: 'submit',
            disabled: loading,
            loading: loading,
          },
        ]}
        heading={`${isAdd ? 'Add' : 'Update'} Store`}
        className="flex-container">
        <div className="popup-section">
          <form
            onSubmit={
              isAdd ? handleSubmit(addStore) : handleSubmit(updateStore)
            }
            id="AddStore"
            className="shopify-collapsed grid gap-2.5">
            <Controller
              name="Name"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    onBlur={value => trimValue(value.target.value, 'Name')}
                    value={value}
                    onChange={value => onChange(value)}
                    label="Store Name"
                    className="filter_form_input"
                    errorString={error?.message}
                    fixedSize="md"
                    placeholder="Enter name of the store"></TextField>
                );
              }}
            />

            <Controller
              name="CheckoutURL"
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: urlPattern,
                  message: 'Invalid URL',
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    value={value}
                    onChange={value => onChange(value)}
                    label="Checkout URL"
                    errorString={error?.message}
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter the url"></TextField>
                );
              }}
            />
            <Controller
              name="StoreDescription"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextField
                    onBlur={value =>
                      trimValue(value.target.value, 'StoreDescription')
                    }
                    value={value}
                    onChange={value => onChange(value)}
                    errorString={error?.message}
                    label="Store Description"
                    className="filter_form_input"
                    fixedSize="md"
                    placeholder="Enter the description"></TextField>
                );
              }}
            />
          </form>
        </div>
      </PopUpModal>
    </>
  );
};

export default AddStore;
