import * as React from 'react';
import { SVGProps } from 'react';

const SezzleLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={-873.05}
        x2={-873.28}
        y1={610.8}
        y2={611.11}
        gradientTransform="matrix(82.4 0 0 -95.33 72006.75 58328.69)"
        gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#ce5dcb" />
        <stop offset={0.21} stopColor="#c558cc" />
        <stop offset={0.55} stopColor="#ac4acf" />
        <stop offset={0.98} stopColor="#8534d4" />
        <stop offset={1} stopColor="#8333d4" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={-873.9}
        x2={-873.28}
        y1={610.95}
        y2={610.95}
        gradientTransform="matrix(82.4 0 0 -95.32 72084.11 58305.2)"
        gradientUnits="userSpaceOnUse">
        <stop offset={0.02} stopColor="#ff5667" />
        <stop offset={0.66} stopColor="#fc8b82" />
        <stop offset={1} stopColor="#fba28e" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-876.36}
        x2={-875.73}
        y1={612.62}
        y2={612.62}
        gradientTransform="matrix(113.4 0 0 -158.67 99397.84 97254.59)"
        gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#00b874" />
        <stop offset={0.51} stopColor="#29d3a2" />
        <stop offset={0.68} stopColor="#53dfb6" />
        <stop offset={1} stopColor="#9ff4d9" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-876.61}
        x2={-875.99}
        y1={612.8}
        y2={612.8}
        gradientTransform="matrix(113.4 0 0 -158.67 99475.11 97331.87)"
        gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#fcd77e" />
        <stop offset={0.52} stopColor="#fea500" />
        <stop offset={1} stopColor="#ff5b00" />
      </linearGradient>
    </defs>
    <path
      d="M0 0h150v150H0V0Z"
      style={{
        fill: 'none',
      }}
    />
    <path
      d="M25.7 100.35c13.27 13.27 34.78 13.27 48.05 0l1.27-1.27c8.89-6.68-4.66-41.46 0-48.05-20.64 13.15-36.86 29.76-49.32 49.32Z"
      style={{
        fill: 'url(#a)',
      }}
    />
    <path
      d="m76.3 49.75-1.27 1.27c-6.63 6.63 6.63 41.42 0 48.05 20.45-13.55 37.47-29.57 49.32-49.32a33.894 33.894 0 0 0-24.02-9.95c-8.7 0-17.39 3.32-24.02 9.95"
      style={{
        fill: 'url(#b)',
      }}
    />
    <path
      d="M25.7 52.3c-13.27 13.27-13.27 34.78 0 48.05l50.61-50.61c13.27-13.27 13.27-34.78 0-48.05L25.7 52.3Z"
      style={{
        fill: 'url(#c)',
      }}
    />
    <path
      d="M73.75 100.35c-13.27 13.27-13.27 34.78 0 48.05l50.61-50.61c13.27-13.27 13.27-34.78 0-48.05l-50.61 50.61Z"
      style={{
        fill: 'url(#d)',
      }}
    />
  </svg>
);
export default SezzleLogo;
