import React, { useEffect, useState } from 'react';

export const PostUpSellTimer = () => {
  const [timeLeft, setTimeLeft] = useState(10 * 60 * 1000); // 10 minutes in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime === 0) {
          clearInterval(interval);
          return 0;
        } else {
          return prevTime - 10; // Subtract 10 milliseconds
        }
      });
    }, 10); // Update every 10 milliseconds

    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, []);

  //format the time to minutes, seconds and milliseconds
  const formatTime = (time: number) => {
    const minutes = Math.floor((time / (1000 * 60)) % 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor((time / 1000) % 60)
      .toString()
      .padStart(2, '0');
    const milliseconds = Math.floor((time % 1000) / 10)
      .toString()
      .padStart(2, '0');
    return { minutes, seconds, milliseconds };
  };

  const { minutes, seconds, milliseconds } = formatTime(timeLeft);

  return (
    <div className="flex flex-col lg:items-start items-center">
      <div className="flex items-center mb-2">
        <div className="text-4xl font-bold">{minutes} :</div>
        <div className="ml-2 text-4xl font-bold">{seconds} :</div>
        <div className="ml-2 text-4xl font-bold">{milliseconds}</div>
      </div>
      <div className="flex items-center text-md">
        <div>Minutes</div>
        <div className="ml-4">Seconds</div>
        <div className="ml-4">M/Seconds</div>
      </div>
    </div>
  );
};
