import { AxiosError } from 'axios';
import { getCheckoutPagesEP, getPage } from '../api/constant';
import { IPageSearchBody } from '../interface/pageInterface';
import { AxiosInstance } from 'axios';
import { commonError } from '../api/constant';

class PageService {
  searchPages = async (body: IPageSearchBody, baseService: AxiosInstance) => {
    try {
      const response = await baseService.post(getCheckoutPagesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getPage = async (
    store_id: string,
    checkout_page_id: string,
    baseService: AxiosInstance,
  ) => {
    try {
      const response = await baseService.get(
        getPage(store_id, checkout_page_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}
class PageMockService {
  searchPages = async (body: IPageSearchBody, baseService: AxiosInstance) => {
    try {
      const response = await baseService.post(getCheckoutPagesEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getPage = async (
    store_id: string,
    checkout_page_id: string,
    baseService: AxiosInstance,
  ) => {
    try {
      const response = await baseService.get(
        getPage(store_id, checkout_page_id),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalPageService: PageService | PageMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new PageMockService()
    : new PageService();

export default globalPageService;
