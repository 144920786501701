import React, { ReactNode } from 'react';
import Popover from '@mui/material/Popover';
import 'components/common/popOver/style.scss';

interface PopOverProps {
  onClose: () => void;
  anchorEl: Element | null;
  child: ReactNode;
}
const PopOver: React.FC<PopOverProps> = ({ onClose, anchorEl, child }) => {
  const open = Boolean(anchorEl);
  return (
    <div className="page">
      <Popover
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className="carts-popover"
        anchorPosition={{ top: 1000, left: 500 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => onClose()}>
        <div className="pop-content">{child}</div>
      </Popover>
    </div>
  );
};

export default PopOver;
