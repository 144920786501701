import { FC, useRef, useState } from 'react';
import Dropdown from 'react-multilevel-dropdown';
import userService from 'services/userService';
import { PopUpModal } from 'shared-components';
import { Controller, useForm } from 'react-hook-form';
import TextField from 'components/common/textField';
import './style.scss';
import { MultipleInputTag } from 'components/common/multiInput';
import { ICreateUserPermission } from 'interface/settingsInterface';
import { showNotification } from 'helper/common/commonFunctions';
import { Permission } from 'router/constant';
import { emailRegex, userPermissionKeys } from 'utils/constants';

interface IMultiLevelField {
  toggle: () => void;
}
interface IProps {
  handleClose: () => void;
  onSuccess: () => void;
  defaultUserPermissionValues: ICreateUserPermission;
}

const AddUserPermissionsForm: FC<IProps> = ({
  handleClose,
  defaultUserPermissionValues,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, setValue, watch } =
    useForm<ICreateUserPermission>({
      defaultValues: defaultUserPermissionValues,
    });

  const multiLevelField = useRef<IMultiLevelField>(null);

  const permission = watch('Permissions');

  const handleCustomPermissionChange = (type: string, value: string) => {
    let newPermission =
      permission?.filter(
        perm =>
          !(
            perm.includes(
              `${userPermissionKeys.manage}:${userPermissionKeys.all}`,
            ) ||
            perm.includes(
              `${userPermissionKeys.view}:${userPermissionKeys.all}`,
            )
          ),
      ) || [];
    if (permission?.find(perm => perm.includes(type))) {
      newPermission = newPermission?.filter(perm => !perm.includes(type));
      setValue('Permissions', [...newPermission, `${value}:${type}`]);
    } else {
      setValue('Permissions', [...newPermission, `${value}:${type}`]);
    }
  };

  const sendInvite = async (data: ICreateUserPermission) => {
    setLoading(true);
    const res = await userService.sendInvite({ ...data });
    setLoading(false);
    if (res.status === 200) {
      showNotification('success', 'User Invite Sent Successfully');
      onSuccess();
    }
  };

  const updateInvitationPermission = async (data: ICreateUserPermission) => {
    setLoading(true);
    const payload = {
      [data.currentRow === 'users' ? 'UserID' : 'InviteID']: data?.ID,
      StoreID: data?.StoreID,
      Permissions: data.Permissions,
      Version: data?.Version,
      SuperAdmin: data?.SuperAdmin,
    };
    let res;
    if (data.currentRow === 'users') {
      res = await userService.updateUserPermission(payload);
    } else {
      res = await userService.updateInvitationPermission(payload);
    }
    if (res?.status === 200) {
      showNotification('success', 'User Permission Updated Successfully');
      onSuccess();
    }
    setLoading(false);
  };

  const handleFormSubmit = (data: ICreateUserPermission) => {
    if (data?.ID) {
      updateInvitationPermission(data);
    } else {
      sendInvite(data);
    }
  };

  return (
    <PopUpModal
      open={true}
      handleClose={handleClose}
      buttons={[
        {
          buttonType: 'negative',
          buttonLabel: 'Cancel',
          buttonFunction: () => {
            handleClose();
          },
        },
        {
          buttonType: 'positive',
          buttonLabel: 'Add',
          form: 'AddUserPermissionsForm',
          type: 'submit',
          disabled: loading,
          loading: loading,
        },
      ]}
      heading={`${defaultUserPermissionValues?.ID ? 'Update' : 'Add'} User Permissions`}
      className="flex-container !overflow-visible">
      <div className="popup-section userPermissions">
        <form
          id="AddUserPermissionsForm"
          onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            name="Email"
            control={control}
            disabled={Boolean(defaultUserPermissionValues?.currentRow)}
            rules={{
              pattern: {
                value: emailRegex,
                message: 'Enter a valid address',
              },
              required: 'This field is required',
            }}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                {...rest}
                errorString={error?.message}
                label="User Email"
                className="filter_form_input"
                fixedSize="md"
                placeholder="Enter Email"
                ref={ref}
              />
            )}
          />
          <Controller
            name="Permissions"
            control={control}
            rules={{ required: 'This field is required' }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <div className="grid multiLevelSelect">
                <label className="input-label-permission">Permissions</label>
                <MultipleInputTag
                  limits={5}
                  tagsValue={value || []}
                  label=""
                  placeholder="Select permission"
                  onChange={onChange}
                  errorString={error?.message}
                  onClick={() => {
                    multiLevelField.current?.toggle();
                  }}
                />
                <Dropdown
                  {...rest}
                  title={permission}
                  buttonClassName="dropdown-input w-full !justify-start"
                  ref={multiLevelField}
                  menuClassName="dropdown-list">
                  <Dropdown.Item
                    onClick={() => {
                      setValue('Permissions', [
                        `${userPermissionKeys.view}:${userPermissionKeys.all}`,
                      ]);
                      multiLevelField.current?.toggle();
                    }}>
                    View all
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setValue('Permissions', [
                        `${userPermissionKeys.manage}:${userPermissionKeys.all}`,
                      ]);
                      multiLevelField.current?.toggle();
                    }}>
                    Manage all
                  </Dropdown.Item>
                  <Dropdown.Item>
                    Custom permission
                    <Dropdown.Submenu
                      className="nested-submenu main-submenu"
                      position="right">
                      {[
                        Permission.ORDER,
                        Permission.TRANSACTION,
                        Permission.CUSTOMER,
                        Permission.SUBSCRIPTION,
                        Permission.STORE,
                        Permission.PRODUCT,
                        Permission.USER,
                        Permission.PAYMENT,
                      ].map(type => (
                        <Dropdown.Item key={type} className="nested-menu-item">
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                          <Dropdown.Submenu
                            position="right"
                            className="nested-submenu">
                            {[
                              userPermissionKeys.view,
                              userPermissionKeys.manage,
                            ].map(access => (
                              <Dropdown.Item
                                key={access}
                                onClick={() =>
                                  handleCustomPermissionChange(type, access)
                                }>
                                {access}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Submenu>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            )}
          />
        </form>
      </div>
    </PopUpModal>
  );
};

export default AddUserPermissionsForm;
