import { ReactComponent as ActionIcon } from 'assets/icons/action-icon.svg';
import 'components/orders/orderDetails/orderDetailsFilter/style.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/mingcute_close-fill.svg';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  setCurrentFilter,
  setPageConfig,
} from 'redux-setup/slices/pageConfigSlice';
import ActionFilter from 'components/orders/orderDetails/orderDetailsFilter/actionFilter';
import { ITransactionHistory } from 'interface/transactionInterface';
import FilterAction from 'components/common/filterAction';

interface Iprops {
  refetch: () => void;
  selectedTransaction: Map<number, ITransactionHistory>;
}

const SideFilter = ({ refetch, selectedTransaction }: Iprops) => {
  const dispatch = useAppDispatch();

  const { filterSource, currentFilter } = useAppSelector(
    state => state.pathConfig,
  );
  const filterAction = [{ key: 'Actions', icon: ActionIcon }];

  const setSelectedFilter = (filter: string) => {
    dispatch(
      setPageConfig({ filterSource: 'orderDetails', currentFilter: filter }),
    );
  };

  const renderComponent = () => {
    switch (currentFilter) {
      case 'Actions':
        return (
          <ActionFilter
            refetch={refetch}
            selectedTransaction={selectedTransaction}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="order-detail_side_filter_container">
      <FilterAction
        className="order-detail_side_filtericon"
        filterAction={filterAction}
        setSelectedFilter={setSelectedFilter}
        permissionOfPage="manage"
      />
      {!!currentFilter && filterSource === 'orderDetails' && (
        <div className="filter_selected_wrapper">
          <div className="filter_header">
            <p className="filter_section_Header">{currentFilter}</p>
            <CloseIcon
              className="close_icon"
              onClick={() => dispatch(setCurrentFilter(''))}
            />
          </div>
          <div className="filter_container">{renderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default SideFilter;
