export const EditIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6553 4.84427C14.603 3.79197 12.8969 3.79198 11.8446 4.8443L11.2461 5.44281L11.2374 5.43406L10.1767 6.49472L10.1855 6.50349L4.30543 12.3837C3.78972 12.8995 3.5 13.5989 3.5 14.3283V16.2498C3.5 16.4488 3.57902 16.6395 3.71967 16.7802C3.86032 16.9208 4.05109 16.9998 4.25 16.9998L6.17155 16.9998C6.90091 16.9998 7.6004 16.7101 8.11613 16.1944L15.6554 8.65489C16.7076 7.6026 16.7076 5.89654 15.6553 4.84427ZM11.2462 7.56415L5.3661 13.4444C5.13169 13.6788 5 13.9967 5 14.3283L5 15.4998H6.17155C6.50308 15.4998 6.82103 15.3681 7.05545 15.1337L12.9355 9.25348L11.2462 7.56415ZM13.9961 8.1928L14.5947 7.59425C15.0612 7.12775 15.0612 6.37142 14.5947 5.90493C14.1282 5.43842 13.3718 5.43843 12.9053 5.90494L12.3068 6.50347L13.9961 8.1928Z"
        fill="currentColor"
      />
    </svg>
  );
};
