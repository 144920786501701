import Login from 'components/login';
import LoginVerification from 'components/loginVerification';
import { useAppSelector } from 'hooks/reduxHooks';
import { RootState } from 'redux-setup/store';

const LoginPage = () => {
  const userEmail = useAppSelector((state: RootState) => state.user.email);

  return <>{userEmail ? <LoginVerification Email={userEmail} /> : <Login />}</>;
};

export default LoginPage;
