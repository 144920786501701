import { Button } from 'shared-components';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import membershipPlanService from 'services/membershipPlanService';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
// import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { PopUpModal } from 'shared-components';
import { get } from 'lodash';
import { IPlan } from 'interface/membershipPlanInterface';
import { useAppSelector } from 'hooks/reduxHooks';
import { Loader } from 'components/common/loader';
import AddIcon from '@mui/icons-material/Add';

export interface HeadCell {
  id: string;
  label: string;
  cellRender?: (_row: IPlan) => ReactNode;
  hide?: boolean;
}

interface IProps {
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setId?: React.Dispatch<React.SetStateAction<string>>;
  isSettingsFromPortal?: boolean;
}

const MembershipPlan: FC<IProps> = ({
  setIsEdit,
  setId,
  isSettingsFromPortal,
}) => {
  const headCells: readonly HeadCell[] = [
    {
      id: 'Name',
      label: 'Membership Name',
    },
    {
      id: 'Frequency',
      label: 'Frequency',
    },
    {
      id: 'FreeTrialLength',
      label: 'Trial period',
    },
    {
      id: 'Amount',
      label: 'Pricing',
    },
    {
      id: 'Actions',
      label: 'Actions',
      cellRender: (row: IPlan) => {
        return (
          <div className="flex align-center gap-4">
            <EditIcon
              className="cursor-pointer"
              onClick={() => {
                handleEdit(row);
              }}
            />
            <DeleteIcon
              className="cursor-pointer"
              onClick={() => {
                setselectedOffer(row);
                setOpenDialog(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedOffer, setselectedOffer] = useState<IPlan>();
  const [membershipPlan, setmembershipPlan] = useState<IPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { storeFront } = useAppSelector(state => state.shopify);

  const getMembershipPlan = async () => {
    if (!storeFront?.StoreID) return;
    setLoading(true);
    const data = await membershipPlanService.getMembershipPlan(
      storeFront?.StoreID || '',
    );
    setLoading(false);
    if (data?.status === 200 && data?.data?.Result?.length) {
      setmembershipPlan(data.data.Result);
      return;
    }
    setmembershipPlan([]);
  };

  const deleteMembershipOffer = async () => {
    setOpenDialog(false);
    if (!selectedOffer?.ID || !storeFront?.StoreID) return;
    setLoading(true);
    const res = await membershipPlanService.deleteMembershipPlan(
      storeFront?.StoreID,
      selectedOffer?.ID,
    );
    if (res?.status === 204 || res?.status === 200) {
      getMembershipPlan();
    }
    setLoading(false);
  };

  const handleEdit = (row: IPlan) => {
    if (setIsEdit && setId && row.ID) {
      setIsEdit(true);
      setId(row.ID);
    }
  };

  useEffect(() => {
    getMembershipPlan();
  }, [storeFront]);

  return (
    <div
      className={`${!isSettingsFromPortal && 'mx-auto max-w-5xl'} w-full h-full grid grid-rows-[min-content_1fr] overflow-auto`}>
      {!isSettingsFromPortal ? (
        <div className="flex justify-between align-center py-6">
          <h2 className="text-xl font-semibold">Membership</h2>
          {!membershipPlan?.length && (
            <Button
              label="Add Membership"
              variant="secondary"
              onClick={() => {
                setIsEdit && setIsEdit(true);
              }}
            />
          )}
        </div>
      ) : (
        <div></div>
      )}
      <div className="h-full overflow-auto">
        {/* for future use */}
        {/* <div className="py-2 flex gap-2">
          <TextField
            fixedSize="md"
            placeholder="Search by discount name"
            value={filterName}
            onChange={e => setFilterName(e.target.value)}
            className="w-full"
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
          <Select
            className="dropdown-field"
            value={filterMembership}
            onChange={e => setFilterMembership(e)}
            options={membershipTypes}
          />
        </div> */}
        <div className="h-full">
          <TableContainer className="Common_Table !h-fit max-h-full">
            <Table aria-labelledby="tableTitle" className="table" stickyHeader>
              <TableHead>
                <TableRow>
                  {headCells?.map(headCell => {
                    if (headCell.hide) {
                      return null;
                    }
                    return (
                      <TableCell
                        className="table_header_cell"
                        key={headCell.id}>
                        {headCell.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {membershipPlan.length ? (
                  membershipPlan.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        {headCells.map(headCell => {
                          return (
                            <TableCell
                              className="table_cell"
                              key={headCell.label}
                              component="th"
                              id={headCell.id}
                              scope="row">
                              {headCell?.cellRender
                                ? headCell.cellRender(row)
                                : get(row, headCell.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <span className="text-center block">
                        No records found
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {isSettingsFromPortal && !membershipPlan?.length && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={15}>
                      <button
                        className="sticky-button flex items-center"
                        onClick={() => {
                          setIsEdit && setIsEdit(true);
                          setId && setId('');
                        }}>
                        <AddIcon /> Add Membership Plan
                      </button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </div>
        <PopUpModal
          heading={`Delete ${selectedOffer?.Name}`}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          buttons={[
            {
              buttonLabel: 'Cancel',
              buttonType: 'negative',
              buttonFunction: () => setOpenDialog(false),
            },
            {
              buttonLabel: 'Delete offer',
              buttonType: 'positive',
              buttonFunction: deleteMembershipOffer,
            },
          ]}>
          <p className="text-white">
            Are you sure you want to delete the offer{' '}
            <span className="font-bold">{selectedOffer?.Name}</span>?
          </p>
        </PopUpModal>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default MembershipPlan;
