import React, { useState } from 'react';
import Button from '../../../components/common/button';
import CustomTooltip from '../../../components/common/tooltip';
import { EditIcon } from '../../../assets/icons/edit';
import { v4 as uuidv4 } from 'uuid';
import { DeleteIcon } from '../../../assets/icons/delete';
import { MonacoEditor } from '../monaco-editor/monaco-editor';
import { urlRegExp } from '../../utils/constants';

interface ScriptData {
  id: string;
  name?: string;
  link: string;
  added?: boolean;
  codeEditor?: boolean;
}

interface CustomScriptsProps {
  value: ScriptData[];
  onChange: (_value: ScriptData[]) => void;
}

const CustomScripts: React.FC<CustomScriptsProps> = ({ value, onChange }) => {
  value = value || [];
  const [link, setLink] = useState<string>('');
  const [manualScript, setManualScript] = useState<string>(
    value.find((script: ScriptData) => script.name === 'Manual Script')?.link ??
      '',
  );
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const handleAddScript = (type?: string): void => {
    const newScript: ScriptData = {
      id: uuidv4(),
      name: '',
      link: '',
      added: true,
    };
    if (type === 'external_script') {
      const regExp = urlRegExp;
      if (link && !!regExp.test(link)) {
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
        return;
      }
      newScript.link = link;
      value.push(newScript);
      onChange([...value]);
      setLink('');
    } else {
      const index = value.findIndex(
        (script: ScriptData) => script.name === 'Manual Script',
      );
      if (index !== -1) {
        value[index].link = manualScript;
        value[index].added = true;
        onChange([...value]);
      } else {
        newScript.name = 'Manual Script';
        newScript.codeEditor = true;
        newScript.link = manualScript;
        value.push(newScript);
        onChange([...value]);
      }
    }
  };
  const handleEditScript = (newValue: string): void => {
    setLink(newValue);
  };

  const handleRemoveScript = (index: number): void => {
    const scriptData = value[index];
    const RemoveScript = document.getElementById(
      `custom-script-${scriptData.id}`,
    );
    if (RemoveScript) {
      RemoveScript.remove();
    }
    onChange(value.filter((_: unknown, i: number) => i !== index));
  };

  const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
  const handleEditorChange = (value: string | undefined) => {
    value && setManualScript(value);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <label className="text-sm font-medium text-gray-700 pagebuilder-theme">
          <CustomTooltip title={'Custom Scripts'}>
            <span>Custom Scripts</span>
          </CustomTooltip>
        </label>
        <Button
          label="Open Code Editor"
          className="!p-2 !text-[10px]"
          variant="phoenix_secondary"
          disabled={manualScript === '' ? false : true}
          onClick={() => setPopupOpen(!isPopupOpen)}
        />
      </div>
      {value.map(
        (script: ScriptData, index: number) =>
          script.name && (
            <div
              key={index}
              className="flex p-2 mt-2 justify-between items-center border rounded">
              <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                {script.name}
              </div>
              <div className="flex gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setPopupOpen(true);
                  }}>
                  <EditIcon />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleRemoveScript(index);
                    setManualScript('');
                  }}>
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ),
      )}

      {value.map(
        (script: ScriptData, index: number) =>
          !script.name && (
            <div key={script.id} className="flex gap-3 p-2 mt-2 border rounded">
              <div className="block gap-1 text-ellipsis overflow-hidden whitespace-nowrap">
                {script.link}
              </div>
              <button type="button" onClick={() => handleRemoveScript(index)}>
                <DeleteIcon />
              </button>
            </div>
          ),
      )}
      <div className="flex flex-col">
        <input
          className="mt-5 bg-white border border-gray-300 rounded px-4 py-3 text-sm w-full transition-colors duration-50 ease-in"
          placeholder="Place your external script link here"
          value={link}
          onChange={e => handleEditScript(e.target.value)}
        />
        {errorMessage && (
          <p className="text-red-500 text-xs">Invalid External URL</p>
        )}
        <Button
          label="+ Add"
          className="mt-4 w-full"
          variant="phoenix_secondary"
          onClick={() => handleAddScript('external_script')}
        />
      </div>
      <MonacoEditor
        isPopupOpen={isPopupOpen}
        setPopupOpen={setPopupOpen}
        value={manualScript}
        language={'javascript'}
        handleEditorChange={handleEditorChange}
        handleSubmit={() => {
          handleAddScript('manual_script');
        }}
      />
    </div>
  );
};

export default CustomScripts;
