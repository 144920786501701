import { TrackerConfig } from '../../../interface/pixelInterface';

export const trackerConfig: TrackerConfig[] = [
  {
    name: 'Meta Pixel',
    fields: [
      {
        type: 'text',
        id: 'meta',
        placeholder: 'Pixel ID',
        secondaryText: 'Enter single ID per line',
      },
    ],
  },
  {
    name: 'TikTok Pixel',
    fields: [
      {
        type: 'text',
        id: 'tiktok',
        placeholder: 'Pixel ID',
        secondaryText: 'Enter single ID per line',
      },
    ],
  },
];
