import { ComponentConfig } from '@measured/puck';
import 'react-quill/dist/quill.snow.css';
import { QuillTextEditor } from '../../../page-builder/puck/reusable-props/quill-editor';
import CustomTooltip from '../../../components/common/tooltip';
import { useSearchParams } from 'react-router-dom';
import { ReduxSelectorProps } from '../../services/constants';
import {
  marginFields,
  MarginProps,
} from '../../../page-builder/puck/reusable-props/margin';
import { paddingFields, PaddingProps } from '../../puck/reusable-props/padding';
import {
  borderRadiusField,
  BorderRadiusProps,
} from '../../puck/reusable-props/border-radius';
import './style.scss';
import showComponent, {
  showComponentProps,
} from '../../puck/reusable-props/showComponent';
import { getcurrentDeviceType } from '../../utils/helper';

export type TextEditorProps = {
  content: string;
} & PaddingProps &
  MarginProps &
  BorderRadiusProps &
  showComponentProps;

export const TextEditor = ({
  useAppSelector,
}: ReduxSelectorProps): ComponentConfig<TextEditorProps> => {
  return {
    label: (
      <CustomTooltip title="TextEditor">
        <span>Text Editor</span>
      </CustomTooltip>
    ) as React.ReactNode as string,
    fields: {
      ...showComponent,
      content: {
        ...QuillTextEditor('Content').content,
      },
      ...marginFields,
      ...paddingFields,
      ...borderRadiusField,
    },
    defaultProps: {
      showComponent: ['desktop', 'tablet', 'mobile'],
      content: 'Text Editor',
      padding: {
        desktop: {
          top: 'pt-0',
          bottom: 'pb-0',
          right: 'pr-0',
          left: 'pl-0',
        },
        tablet: {
          top: 'max-md:pt-0',
          bottom: 'max-md:pb-0',
          right: 'max-md:pr-0',
          left: 'max-md:pl-0',
        },
        mobile: {
          top: 'max-sm:pt-0',
          bottom: 'max-sm:pb-0',
          right: 'max-sm:pr-0',
          left: 'max-sm:pl-0',
        },
      },
      borderRadius: 'rounded-md',
      margin: {
        desktop: {
          top: 'mt-4',
          bottom: 'mb-4',
          right: 'mr-0',
          left: 'ml-0',
        },
        tablet: {
          top: 'max-md:mt-0',
          bottom: 'max-md:mb-0',
          right: 'max-md:mr-0',
          left: 'max-md:ml-0',
        },
        mobile: {
          top: 'max-sm:mt-0',
          bottom: 'max-sm:mb-0',
          right: 'max-sm:mr-0',
          left: 'max-sm:ml-0',
        },
      },
    },
    render: ({ content, borderRadius, margin, padding, showComponent }) => {
      const cartData = useAppSelector(state => state.cart?.cartData);
      const [searchParams] = useSearchParams();
      const windowWidth = useAppSelector(state => state.store.currentWidth);
      const currentDeviceType = getcurrentDeviceType(windowWidth);

      const processContent = (text: string) => {
        // Regular expression to match the content within {isVIP && ...}
        const vipContentPattern = /<p>{isVIP\s*&amp;&amp;\s*([^}]+)}<\/p>/g;
        const showVIPContent =
          cartData?.HasSubscription ||
          searchParams.get('cart') == 'PHOENIX_CART_PREVIEW';
        // If VIP, replace the placeholder with the actual content
        if (showVIPContent) {
          return text.replace(
            vipContentPattern,
            (_, content) => `<p>${content.trim()}</p>`,
          );
        }

        // If not VIP, remove the content within {isVIP && ...}
        return text.replace(vipContentPattern, '');
      };

      return (
        <>
          {showComponent?.includes(currentDeviceType) && (
            <div
              className={` ${borderRadius} ${padding?.desktop?.top} ${padding?.desktop?.bottom} ${padding?.desktop?.left} ${padding?.desktop?.right}
            ${padding?.tablet?.top} ${padding?.tablet?.bottom} ${padding?.tablet?.left} ${padding?.tablet?.right}
            ${padding?.mobile?.top} ${padding?.mobile?.bottom} ${padding?.mobile?.left} ${padding?.mobile?.right} 
       ${margin?.desktop?.top} ${margin?.desktop?.bottom} ${margin?.desktop?.left} ${margin?.desktop?.right}
            ${margin?.tablet?.top} ${margin?.tablet?.bottom} ${margin?.tablet?.left} ${margin?.tablet?.right}
            ${margin?.mobile?.top} ${margin?.mobile?.bottom} ${margin?.mobile?.left} ${margin?.mobile?.right} text-editor-content`}
              dangerouslySetInnerHTML={{ __html: processContent(content) }}
            />
          )}
        </>
      );
    },
  };
};
