import axios, { AxiosError } from 'axios';
import { baseService } from 'services';
import { IErrorResponse, ISuccessResponse } from 'interface/apiInterface';
import {
  blockIPEP,
  commonError,
  createStoreFrontEP,
  getRegisterAppEP,
  getStoreByIDEP,
  getStoreFrontEP,
  getStoreFrontsEP,
  getStoreOverviewEP,
  getStoresEventEP,
  unblockIPEP,
  updateStoreFrontEP,
  updateStoreFrontEventsEP,
} from 'services/constants';
import { IStoreFront } from 'interface/settingsInterface';
import {
  IBlackListIP,
  IStoreFrontEvent,
  IStoreFrontEventResponse,
  IStoreFrontEventUpdate,
} from 'interface/storeInterface';
import { IStoreOverview } from 'interface/storeOverview';

class StoreFrontMockService {
  commonFunction = async (): Promise<
    ISuccessResponse | IErrorResponse<AxiosError>
  > => {
    try {
      return await new Promise<ISuccessResponse>(resolve => {
        setTimeout(() => {
          resolve({
            data: null,
            status: 200,
            message: 'success',
          });
        }, 1000);
      });
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  createStoreFront = async (): Promise<
    ISuccessResponse | IErrorResponse<AxiosError>
  > => {
    return this.commonFunction();
  };
  updateStoreFront = async (): Promise<
    ISuccessResponse | IErrorResponse<AxiosError>
  > => {
    return this.commonFunction();
  };

  getStoreFronts = async () => {
    return this.commonFunction();
  };

  getStoreFront = async () => {
    return this.commonFunction();
  };

  getStoreFrontEvents = async () => {
    return this.commonFunction();
  };
  getStoreOverview = async (storeOverview: IStoreOverview) => {
    try {
      return await baseService.post(getStoreOverviewEP, storeOverview);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  blockIP = async () => {
    return this.commonFunction();
  };
  unBlockIP = async () => {
    return this.commonFunction();
  };
  updateStoreFrontEvents = async () => {
    return this.commonFunction();
  };
  getStoreByShopId = async () => {
    return this.commonFunction();
  };
  registerStoreFront = async () => {
    return this.commonFunction();
  };
}
class StoreFrontService {
  storeFrontMockService = new StoreFrontMockService();
  createStoreFront = async (
    storeFront: IStoreFront,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IStoreFront>(
        createStoreFrontEP,
        storeFront,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  updateStoreFront = async (
    storeFront: IStoreFront,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post<IStoreFront>(
        updateStoreFrontEP,
        storeFront,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreFronts = async (store: string) => {
    try {
      const response = await baseService.get<IStoreFront[]>(
        getStoreFrontsEP(store),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
  getStoreFront = async (store: string, storefront: string) => {
    try {
      const response = await baseService.get<IStoreFront[]>(
        getStoreFrontEP(store, storefront),
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreFrontEvents = async (
    storeFrontEvent: IStoreFrontEvent,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post<IStoreFrontEventResponse>(
        getStoresEventEP,
        storeFrontEvent,
      );
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreOverview = async (storeOverview: IStoreOverview) => {
    try {
      return await baseService.post(getStoreOverviewEP, storeOverview);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  blockIP = async (
    body: IBlackListIP,
  ): Promise<ISuccessResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(blockIPEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  unBlockIP = async (
    body: IBlackListIP,
  ): Promise<ISuccessResponse | IErrorResponse<AxiosError>> => {
    try {
      const response = await baseService.post(unblockIPEP, body);
      return response;
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  updateStoreFrontEvents = async (
    storeFront: IStoreFrontEventUpdate,
  ): Promise<ISuccessResponse<IStoreFront> | IErrorResponse<AxiosError>> => {
    try {
      return await baseService.post(updateStoreFrontEventsEP, storeFront);
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  getStoreByShopId = async (shopId: string) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_PHOENIX_STOREFRONT_URL}${getStoreByIDEP(shopId)}`,
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };

  registerStoreFront = async (ShopID: string, SubjectToken: string) => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_PHOENIX_STOREFRONT_URL}${getRegisterAppEP}`,
        {
          ShopID,
          SubjectToken,
        },
      );
    } catch (error) {
      return commonError(error as AxiosError, true);
    }
  };
}

const globalStoreFrontService: StoreFrontService | StoreFrontMockService =
  process.env.REACT_APP_USE_MOCK_API === 'true'
    ? new StoreFrontMockService()
    : new StoreFrontService();

export default globalStoreFrontService;
