export const MoneyBackIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 240 210">
      <path
        fill="#FDFDFD"
        d="M110 211H1c0-6.103 0-12.082.453-18.66 13.028-.599 25.603-.599 38.095-.599v-90.333c-2.403 0-4.535.022-6.666-.003-10.627-.128-21.255-.269-31.882-.405V1.046h240c0 43.641 0 87.329-.284 131.274-3.649-10.992-15.137-15.948-26.237-10.681-11.093 5.264-21.976 10.975-33.106 16.158-3.57 1.663-5.918 3.806-7.188 7.519-2.973 8.698-9.28 14.683-17.923 16.503-9.986 2.102-20.342 2.58-30.569 3.339-3.55.263-6.088-1.98-5.762-5.949.323-3.93 3.082-5.264 6.646-5.154 4.325.134 8.653.698 12.968.594 8.613-.209 16.952-1.604 23.444-8.039 5.746-5.696 4.56-13.277-2.773-16.67-2.477-1.145-5.406-1.691-8.155-1.795-6.655-.252-13.34.172-19.985-.198-2.969-.165-6.365-.88-8.722-2.543-22.11-15.601-46.692-19.625-73.212-15.865-.022.242-.131.88-.131 1.516-.01 23.16.059 46.32-.096 69.48-.02 2.868 1.12 4.087 3.4 5.245 10.193 5.18 20.214 10.707 30.506 15.676 6.078 2.935 12.518 5.135 18.85 7.52 2.363.889 4.882 1.361 7.329 2.024m87.745-128.805 8.202-28.825L78.87 15.15 59.763 81.6c42.444 12.77 84.599 25.45 127.078 38.23 3.542-12.387 7.047-24.645 10.904-37.635M99.352 12.21c35.147 10.766 70.293 21.533 105.441 32.296 7.614 2.331 9.548 5.807 7.426 13.43-4.34 15.591-8.688 31.18-13.1 47.015l8.737 1.386 19.098-66.386L99.187 1.476c-.22 3.89-.409 7.23.165 10.735z"
      />
      <path
        fill="#010101"
        d="M110.444 211c-2.891-.663-5.41-1.135-7.773-2.025-6.332-2.384-12.772-4.584-18.85-7.519-10.292-4.97-20.313-10.496-30.506-15.676-2.28-1.158-3.42-2.377-3.4-5.245.155-23.16.086-46.32.096-69.48 0-.637.109-1.274.13-1.516 26.52-3.76 51.103.264 73.213 15.865 2.357 1.663 5.753 2.378 8.722 2.543 6.644.37 13.33-.054 19.985.198 2.75.104 5.678.65 8.155 1.795 7.333 3.393 8.52 10.974 2.773 16.67-6.492 6.435-14.831 7.83-23.444 8.039-4.315.104-8.643-.46-12.968-.594-3.564-.11-6.323 1.224-6.646 5.154-.326 3.97 2.213 6.212 5.762 5.949 10.227-.759 20.583-1.237 30.57-3.34 8.643-1.819 14.949-7.804 17.922-16.502 1.27-3.713 3.617-5.856 7.188-7.52 11.13-5.182 22.013-10.893 33.106-16.157 11.1-5.267 22.588-.31 26.237 11.15.284 1.925.284 3.64.013 6.015-1.39 5.827-4.523 9.464-8.748 12.482-21.771 15.554-44.523 29.503-68.482 41.4-11.249 5.586-22.779 10.628-34.325 15.581-3.476 1.491-7.436 1.854-11.174 2.733h-7.556z"
      />
      <path
        fill="#040404"
        d="M1 101.469c10.627-.333 21.255-.192 31.882-.064 2.131.025 4.263.003 6.666.003v90.333c-12.492 0-25.067 0-38.095.13C1 161.978 1 131.957 1 101.468z"
      />
      <path
        fill="#FEFEFE"
        d="M118.469 211c3.27-.88 7.23-1.242 10.705-2.733 11.546-4.953 23.076-9.995 34.325-15.58 23.959-11.898 46.71-25.847 68.482-41.4 4.225-3.019 7.358-6.656 8.748-12.054.271 23.713.271 47.66.271 71.767H118.469z"
      />
      <path
        fill="#040404"
        d="M197.57 82.56c-3.682 12.625-7.187 24.883-10.729 37.27-42.479-12.78-84.634-25.46-127.078-38.23L78.87 15.15l127.077 38.22c-2.776 9.755-5.489 19.29-8.378 29.19m-33.983 19.367c1.78.04 4.736.745 5.153.01 2.834-4.992 7.353-6.187 12.526-6.149l7.228-25.729c-5.642-2.567-7.878-6.787-7.236-12.89l-83.56-25.842c-3.908 6.234-5.845 7.291-13.162 7.41l-7.41 25.802c5.768 2.372 7.947 6.808 7.142 12.814 26.373 8.193 52.455 16.295 79.32 24.574z"
      />
      <path
        fill="#050505"
        d="M98.97 12.129c-.192-3.423-.003-6.764.217-10.653l127.767 38.476-19.098 66.386-8.737-1.386c4.412-15.835 8.76-31.424 13.1-47.014 2.122-7.624.188-11.1-7.426-13.431C169.645 33.744 134.5 22.977 98.97 12.129z"
      />
      <path
        fill="#FCFCFC"
        d="M163.195 101.839a73033.802 73033.802 0 0 1-78.928-24.486c.805-6.006-1.374-10.442-7.142-12.814l7.41-25.803c7.317-.118 9.254-1.175 13.163-7.409l83.559 25.842c-.642 6.103 1.594 10.323 7.236 12.89l-7.228 25.729c-5.173-.038-9.692 1.157-12.526 6.15-.417.734-3.372.029-5.544-.1m-20.05-46.767c-6.413-3.632-12.767-3.508-18.5 1.129-4.876 3.945-6.707 9.396-5.274 15.589 1.562 6.75 7.287 11.545 14.066 12.156 6.311.569 13.021-3.492 15.563-9.418 2.982-6.95 1.21-13.415-5.855-19.456z"
      />
      <path
        fill="#030303"
        d="M143.465 55.28c6.746 5.832 8.517 12.297 5.535 19.247-2.542 5.926-9.252 9.987-15.563 9.418-6.779-.61-12.504-5.406-14.066-12.156-1.433-6.193.398-11.644 5.275-15.59 5.732-4.636 12.086-4.76 18.82-.919z"
      />
    </svg>
  );
};
