import { FC, useMemo } from 'react';
import 'components/common/statusBadge/style.scss';
interface Props {
  status: string;
}

const availableVarients = {
  dispute: 'dispute_status',
  failed: 'failed_status',
  success: 'success_status',
  pending: 'pending_status',
  authorized: 'authorized_status',
  partial: 'partial_status',
  voided: 'voided_status',
  active: 'active_status',
  chargeback: 'chargeback_status',
  refunded: 'refund_status',
  recovered: 'recovered_status',
  created: 'created_status',
};

const getStatusClassName = (status: string) => {
  switch (status.toLowerCase()) {
    case 'refunded':
    case 'renewed':
      return availableVarients.refunded;
    case 'chargeback':
      return availableVarients.chargeback;
    case 'voided':
    case 'dispute':
    case 'pending':
      return availableVarients.voided;
    case 'authorized':
      return availableVarients.authorized;
    case 'paid':
    case 'success':
    case 'captured':
    case 'completed':
    case 'fulfilled':
    case 'active':
    case 'accepted':
    case 'super_admin':
    case 'updated':
      return availableVarients.success;
    case 'canceled':
    case 'failed':
    case 'fail_capture':
    case 'declined':
    case 'fail_authorization':
      return availableVarients.failed;
    case 'unfulfilled':
    case 'paused':
      return availableVarients.pending;
    case 'in salvage':
    case 'salvaging':
      return availableVarients.dispute;
    case 'recovered':
      return availableVarients.recovered;
    case 'created':
      return availableVarients.created;
    case 'salvage':
    case 'partial_refund':
      return availableVarients.partial;
    default:
      return '';
  }
};

const StatusBadge: FC<Props> = ({ status = '' }) => {
  if (!status) {
    return null;
  }

  // Remove underscores from status
  const cleanStatus = status.replace(/_/g, ' ');

  const statusClassName = useMemo(() => {
    return getStatusClassName(status);
  }, [status]);

  return <div className={`status_badge ${statusClassName}`}>{cleanStatus}</div>;
};

export default StatusBadge;
